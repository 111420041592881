import { TranslationType } from "../types"

const translations: TranslationType = {
    "tc-title": {
        en: "General Terms and Conditions for the Glickon Services",
        it: "Termini e condizioni generali per i servizi Glickon"
    },
    "tc-pre-list-text": {
        en: `
        These general terms and conditions for Glickon Services (the <strong>"General Terms and Conditions"</strong>)
        regulate the supply of products or services by Glickon S.r.l. (<strong>"Glickon"</strong>) to each customer (<strong>"Customer"</strong>),
        which has placed an order for the supply of Glickon Services (<strong>"Order"</strong>).
        As specified in the Order, the General Terms and Conditions shall constitute an integral part of each
        Order and be incorporated into such. The Customer and Glickon are each a <strong>"Party"</strong> and together the <strong>"Parties"</strong>.
        Where not otherwise defined, capitalised terms, including those used on the Order, shall have the meaning set out below. These General Terms and Conditions are stipulated
        by and between Glickon and the Customer, as defined and indicated below, and they must be fully accepted in order to allow for use of the Glickon Platform
        and the Services supplied by Glickon, as better defined below.
        `,
        it: `
        I presenti termini e condizioni generali per i servizi Glickon (<strong>"Termini e Condizioni 
        Generali"</strong>) disciplinano la fornitura di prodotti o servizi da parte di Glickon S.r.l. 
        (<strong>"Glickon"</strong>) a ciascun cliente (<strong>"Cliente"</strong>) che ha sottoscritto un ordine 
        per la fornitura di servizi Glickon (<strong>"Ordine"</strong>). Come riportato nell’Ordine, i Termini e 
        Condizioni Generali costituiscono una parte integrante di ciascun Ordine e sono nello stesso incorporati. 
        Il Cliente e Glickon sono ciascuno una <strong>"Parte"</strong> e insieme costituiscono le 
        <strong>"Parti"</strong>. I termini in maiuscolo, inclusi quelli riportati nell’Ordine laddove non siano in 
        esso diversamente definiti, hanno il significato qui di seguito specificato. Questi Termini e Condizioni 
        Generali sono stipulati tra Glickon e il Cliente, come definiti e identificati di seguito, e la loro 
        integrale accettazione è condizione necessaria per l’utilizzo della Piattaforma Glickon e dei Servizi 
        forniti da Glickon, come meglio definiti di seguito.
        `
    },
    "tc-last-modified": {
        en: "Milan, 31 March 2019",
        it: "Milano, 31 Marzo 2019"
    },

    "tc-1": {
        en: "Definitions",
        it: "Definizioni"
    },
    "tc-1-1": {
        en: `
        The expressions listed below have the meanings given alongside each. It is, moreover, specified that each term defined shall apply to both its singular and plural form. Any other expression defined elsewhere in the General Terms and Conditions has the meaning assigned it there.
        `,
        it: `
                Le espressioni qui di seguito elencate hanno il significato indicato a fianco di ciascuna di esse, 
                essendo peraltro precisato che ogni termine definito varrà sia al singolare sia al plurale. Qualsiasi 
                altra espressione definita in altre parti dei Termini e Condizioni Generali ha il significato ivi 
                attribuitole.
            `
    },
    "tc-1-2": {
        en: `
        <strong>"Account"</strong> means one or more holders of personalised credentials by which to access the Dashboard;
        `,
        it: `
                <strong>"Account"</strong> indica uno o più titolari di credenziali personalizzate per l’accesso
                 alla Dashboard;
            `
    },
    "tc-1-3": {
        en: `
        <strong>"Challenge"</strong> means the challenge, based on Glickon Tests, inserted onto the Platform directly by Glickon, in which all Users can take part, alone or in competition, aimed at assessing knowledge of a given subject matter;
        `,
        it: `
                <strong>"Challenge"</strong> indica la sfida, basata su Test Glickon, inserita sulla Piattaforma 
                direttamente da Glickon, alla quale possono partecipare tutti gli Utenti, da soli o in competizione 
                tra di loro, volta a valutare la conoscenza di una determinata materia;
            `
    },
    "tc-1-4": {
        en: `
        <strong>"Business Challenge"</strong> means a section of the Platform, organised by the Customer, which may include Contents and Tests, so as to engage Users in fulfilling a Business Purpose;
        `,
        it: `
                <strong>"Challenge Aziendale"</strong> indica una sezione della Piattaforma, organizzata dal Cliente 
                che può includere Contenuto e Test, al fine di ingaggiare gli Utenti nello svolgimento di uno Scopo Aziendale;
            `
    },
    "tc-1-5": {
        en: `
        <strong>"Customer"</strong> means the company or legal entity accepting these General Terms and Conditions at the time of placing the Order;
        `,
        it: `
                <strong>"Cliente"</strong> indica la società o entità legale che accetta questi Termini e Condizioni 
                Generali al momento della sottoscrizione dell’Ordine;
            `
    },
    "tc-1-6": {
        en: `
        <strong>"Company Page"</strong> means the Customer profile page onto which the Contents can be inserted, aimed at fulfilling the Business Purpose on the Platform;
        `,
        it: `
                <strong>"Company Page"</strong> indica la pagina profilo del Cliente in cui lo stesso può inserire il 
                Contenuto finalizzato a svolgere lo Scopo Aziendale sulla Piattaforma;
            `
    },
    "tc-1-7": {
        en: `
        <strong>"Conditions for Use of the Platform"</strong> means the terms and conditions governing use of the Platform by the User, as described at the following
        URL <a href="/en/platform/terms-and-conditions" 
        target="_blank" rel="noopener noreferrer">https://www.glickon.com/en/platform/terms-and-conditions</a>;
        `,
        it: `
                <strong>"Condizioni d’Uso della Piattaforma"</strong> indicano i termini e condizioni per l’utilizzo 
                della Piattaforma da parte dell’Utente, così come riportati al seguente URL 
                <a href="/it/piattaforma/termini-e-condizioni" 
                target="_blank" rel="noopener noreferrer">https://www.glickon.com/it/piattaforma/termini-e-condizioni</a>;
            `
    },
    "tc-1-8": {
        en: `
        <strong>"Contents"</strong> means, merely by way of example, text, images, graphics, video, audio uploaded onto the Platform
        and used by the Customer to engage Users and/or assess their competences during the fulfilment 
        of a Business Purpose. These Contents can be created by the Customer (<strong>"Customer Contents"</strong>)
        or by Glickon (<strong>"Glickon Contents"</strong>);
        `,
        it: `
                <strong>"Contenuto"</strong> indica, a titolo esemplificativo e non esaustivo, testo, immagini, 
                grafiche, video, audio caricati sulla Piattaforma ed utilizzati dal Cliente per ingaggiare gli 
                Utenti e/o valutarne le competenze durante lo svolgimento di uno Scopo Aziendale. Detto 
                Contenuto può essere creato dal Cliente (<strong>"Contenuto Cliente"</strong>) o da Glickon 
                (<strong>"Contenuto Glickon"</strong>);
            `
    },
    "tc-1-9": {
        en: `
        <strong>"Price"</strong> means the amount paid by the Customer to access the Platform or use the Services supplied by Glickon in accordance with the General Terms and Conditions and the Order;
        `,
        it: `
                <strong>"Corrispettivo"</strong> indica l’importo pagato dal Cliente per accedere alla Piattaforma e 
                usufruire dei Servizi forniti da Glickon ai sensi dei Termini e Condizioni Generali e dell’Ordine;
            `
    },
    "tc-1-10": {
        en: `
        <strong>"Dashboard"</strong> means the management platform that allows the Customer to modify the Company Page, autonomously create/insert Contents and display the Results;
        `,
        it: `
                <strong>"Dashboard"</strong> indica la piattaforma gestionale che permette al Cliente la modifica della 
                Company Page, la creazione/inserimento in autonomia di Contenuto e la visualizzazione dei Risultati;
            `
    },
    "tc-1-11": {
        en: `<strong>"Glickon"</strong> means Glickon S.r.l., Italian company with registered office in Milan, at via Nino Bixio 7, 20129 Milan, VAT no./tax code/registration no. with Milan Companies House 08573820969;
    `,
        it: `
                <strong>"Glickon"</strong> indica Glickon S.r.l., società di diritto italiano con sede legale in Milano, 
                via Nino Bixio 7, 20129 Milano, P.IVA/C.F./n. di iscrizione al registro delle imprese di Milano 08573820969;
            `
    },
    "tc-1-12": {
        en: `<strong>"API Integration"</strong> means a set of programming standards and instructions for accessing and integrating the Glickon Platform and Dashboard into the Customer’s management system/database;
       `,
        it: `
                <strong>"Integrazione API"</strong> indica un set di istruzioni e standard di programmazione per 
                l'accesso e l'integrazione della Piattaforma Glickon e della Dashboard nel sistema gestionale/database 
                del Cliente;
            `
    },
    "tc-1-13": {
        en: `<strong>"LIVE Game"</strong> means a quiz game in which the LIVE User is invited to take part using the Platform at the following URL: live.glickon.com;
        `,
        it: `
                <strong>"LIVE Game"</strong> indica il gioco a quiz a cui l’Utente LIVE è invitato a partecipare 
                utilizzando la Piattaforma al seguente URL: live.glickon.com;
            `
    },
    "tc-1-14": {
        en: `<strong>"White Label Mode"</strong> allows the Customer to benefit from Glickon Services through a reserved version of the Platform, which is therefore intended for use only by Private Users;
       `,
        it: `
                <strong>"Modalità White Label"</strong> indica la possibilità per il Cliente di usufruire di Servizi 
                Glickon attraverso una versione riservata della Piattaforma e, pertanto, destinati esclusivamente ad 
                Utenti Privati;
            `
    },
    "tc-1-15": {
        en: `<strong>"Nickname"</strong> is a fictitious name chosen by a LIVE User that allows him/her to take part in the LIVE Game without needing to provide any personal data;
        `,
        it: `
                <strong>"Nickname"</strong> nome fittizio scelto da un Utente LIVE che ne permette la partecipazione al 
                LIVE Game senza dover fornire dati personali;
            `
    },
    "tc-1-16": {
        en: `<strong>"Order"</strong> is any order form for the supply of Glickon Services signed by the Parties. Each Order contains the commercial agreement between Glickon and the Customer and established, by way of example: the type of Services, duration and Price of the related supply, as well as all and any related special conditions. Each Order shall incorporate the General Terms and Conditions and is subject to the provisions set out therein;
       `,
        it: `
                <strong>"Ordine"</strong> indica qualsiasi modulo d’ordine per la fornitura di servizi Glickon 
                sottoscritto dalle Parti. Ciascun Ordine contiene l’accordo commerciale tra Glickon e il Cliente e 
                stabilisce, a titolo esemplificativo: il tipo di Servizi, la durata e il Corrispettivo oggetto della 
                relativa fornitura, nonché tutte le condizioni particolari eventualmente connesse alla medesima. 
                Ciascun Ordine incorpora i Termini e Condizioni Generali ed è soggetto alle disposizioni ivi contenute;
            `
    },
    "tc-1-17": {
        en: `<strong>"Platform"</strong> means the <strong>"Glickon"</strong> platform accessible from the URL 
    https://www.glickon.com, on which a community of Users/LIVE Users is engaged by Glickon or the Customer on specific Contents and where they are assessed through entertaining activities. Users can carry out Tests for fun only or to take part in Business Challenges launched by the Customer. The Platform is available in a desktop version, which can be accessed via the most common web browsers (Google Chrome v24 or better, Safari v9 or better, Firefox v23 or better, Internet Explorer v11 or better);
        `,
        it: `
                <strong>"Piattaforma"</strong> indica la piattaforma denominata "Glickon", accessibile al seguente URL 
                https://www.glickon.com, sulla quale una comunità di Utenti / Utenti LIVE viene ingaggiata da Glickon o 
                dal Cliente su uno specifico Contenuto e in cui gli stessi vengono valutati attraverso attività ludiche. 
                Gli Utenti possono svolgere Test per puro divertimento o partecipare a Challenge Aziendali lanciate dal 
                Cliente. La Piattaforma è disponibile in versione desktop accessibile attraverso i più comuni 
                web-browser (Google Chrome v24 o superiore, Safari v9 o superiore, Firefox v23 o superiore, 
                Internet Explorer v11 o superiore);
            `
    },
    "tc-1-18": {
        en: `<strong>"Privacy Policy"</strong> means the Glickon Privacy Policy available at the following URL:
    <a href="/en/platform/privacy" rel="noopener noreferrer" target="_blank">https://www.glickon.com/en/platform/privacy</a>;
    `,
        it: `
                <strong>"Privacy Policy"</strong> indica la privacy policy di Glickon, disponibile al seguente URL: 
                <a href="/it/piattaforma/privacy" rel="noopener noreferrer" target="_blank">https://www.glickon.com/it/piattaforma/privacy</a>;
            `
    },
    "tc-1-19": {
        en: `<strong>"Reference Person"</strong> means the natural person appointed by the Customer through the Order to receive the access credentials to the Customer’s Accounts and to whom all official communications shall be addressed;
       `,
        it: `
                <strong>"Referente"</strong> indica la persona fisica intitolata dal Cliente tramite l’Ordine, a 
                ricevere le credenziali di accesso degli Account del Cliente stesso e a cui saranno indirizzate tutte 
                le comunicazioni ufficiali;
            `
    },
    "tc-1-20": {
        en: `<strong>"Reports"</strong> are the output of the analyses, management and archiving of data aimed at providing a description and prediction on the basis of the information collected during the User’s activities on the Platform;
      `,
        it: `
                <strong>"Reportistiche"</strong> indica l’output delle attività di analisi, gestione ed archiviazione 
                dati finalizzate alla descrizione e predizione sulla base delle informazioni raccolte durante le 
                attività degli Utenti in Piattaforma;
            `
    },
    "tc-1-21": {
        en: `<strong>"Results"</strong> means the following information of each User, to the extent supplied by said User: score obtained in the Challenge/Business Challenge, electronic documents, audio/video files and personal information such as: name, surname, date of birth, place of residence, qualification, e-mail address, telephone number and CV;
       `,
        it: `
                <strong>"Risultati"</strong> indica le seguenti informazioni di ciascun Utente, nella misura fornita 
                dall’Utente medesimo: punteggio ottenuto nelle Challenge/Challenge Aziendale, documenti elettronici, 
                file audio/video e informazioni personali quali: nome, cognome, data di nascita, luogo di residenza, 
                titolo di studio, e-mail, numero di telefono e curriculum vitae;
            `
    },
    "tc-1-22": {
        en: `<strong>"LIVE Game Results"</strong> means the information on the game and personal data of each LIVE User, including name, surname, e-mail address and telephone number;
       `,
        it: `
                <strong>"Risultati</strong> LIVE Game" indica le informazioni relative al gioco e i dati personali di 
                ciascun Utente LIVE tra cui, nome, cognome, e-mail, numero di telefono;
            `
    },
    "tc-1-23": {
        en: `<strong>"Business Purpose"</strong> means the activities for which the Platform can be used by the Customer, including, merely by way of example, employer branding, talent acquisition, onboarding, training & development, internal communication and events;
        `,
        it: `
                <strong>"Scopo Aziendale"</strong> indica le attività per cui la Piattaforma può essere utilizzata dal 
                Cliente, tra cui, in maniera non esaustiva, employer branding, talent acquisition, onboarding, training 
                & development, comunicazione interna, eventi;
            `
    },
    "tc-1-24": {
        en: `<strong>"Services"</strong> means the services included in the Customer’s Order, of those described in the
    Description of the Glickon Services, an updated list of which is made available by Glickon at the
    URL <a href="/en/documents/terms-of-services" target="_blank" rel="noopener noreferrer">http://www.glickon.com/en/documents/terms-of-services</a>;
        `,
        it: `
                <strong>"Servizi"</strong> indica i servizi inclusi nell’Ordine del Cliente tra quelli descritti nella 
                Descrizione dei Servizi Glickon, il cui elenco aggiornato è reso accessibile da Glickon all’URL 
                <a href="/it/documenti/termini-dei-servizi" target="_blank" rel="noopener noreferrer">
                http://www.glickon.com/it/documenti/termini-dei-servizi</a>;
            `
    },
    "tc-1-25": {
        en: `<strong>"Target Audience"</strong> means a Service offered and managed by Glickon, at the request of the Customer, consisting of the promotion of the Corporate Page and Business Challenges of the Customer on social networks and/or other promotional channels chosen by the Customer;
       `,
        it: `
                <strong>"Target Audience"</strong> indica un Servizio offerto e gestito da Glickon, a richiesta del 
                Cliente, consistente in un’attività di promozione della Corporate Page e delle Challenge Aziendali 
                del Cliente su social network e/o altri canali promozionali scelti dal Cliente stesso;
            `
    },
    "tc-1-26": {
        en: `<strong>"Test"</strong> means a series of questions and answers that cover various themed areas, aimed at verifying the level of knowledge of a subject. Each Test may be associated with a Challenge/Business Challenge and may be created autonomously by the Customer (a <strong>"Customer Test"</strong>) or by Glickon (a <strong>"Glickon Test"</strong>);
       `,
        it: `
                <strong>"Test"</strong> indica una serie di domande e risposte che coprono diverse aree tematiche, 
                finalizzate a verificare il livello di conoscenza di una materia. Ciascun Test può essere associato 
                ad una Challenge/Challenge Aziendale e può essere creato autonomamente dal Cliente 
                (<strong>"Test Cliente"</strong>) o da Glickon (<strong>"Test Glickon"</strong>);
            `
    },
    "tc-1-27": {
        en: `<strong>"User"</strong> means any natural person using the Platform and its functions, whose data is supplied in accordance with the provisions of the Conditions for Use of the Platform and the Glickon Personal Data Processing Disclosure;
       `,
        it: `
                <strong>"Utente"</strong> indica qualsiasi persona fisica che utilizza la Piattaforma e le sue 
                funzionalità, i cui dati sono forniti secondo quanto previsto nelle Condizioni d’Uso della Piattaforma 
                e nell’Informativa sul trattamento dei dati personali di Glickon;
            `
    },
    "tc-1-28": {
        en: `<strong>"LIVE User"</strong> means any natural person using the Platform to take part in a LIVE Game, without having access to the other Platform functions;
        `,
        it: `
                <strong>"Utente LIVE"</strong> indica qualsiasi persona fisica che utilizza la Piattaforma per la 
                partecipazione ad un LIVE Game senza avere accesso alle altre funzionalità della Piattaforma.
            `
    },
    "tc-1-29": {
        en: `<strong>"Private User"</strong> means a User taking part in a Business Challenge in White Label Mode and who does not have access to the other Platform functions.
        `,
        it: `
                <strong>"Utente Privato"</strong> indica un Utente che partecipa ad una Challenge Aziendale in Modalità 
                White Label e che non ha accesso alle altre funzionalità della Piattaforma.
            `
    },

    "tc-2": {
        en: "Object",
        it: "Oggetto"
    },
    "tc-2-1": {
        en: `With the General Terms and Conditions, Glickon grants the Customer, in exchange for payment of a Price, the suitability of which is mutually acknowledged by the Parties, a licence guaranteeing a non-exclusive, non-transferable right to use the Platform and Services, only in the manner expressly permitted by the Platform and regulated by the same General Terms and Conditions.
        `,
        it: `
            Con i Termini e Condizioni Generali, Glickon concede al Cliente, a fronte del pagamento di un Corrispettivo 
            della cui adeguatezza le Parti si danno reciprocamente atto, una licenza che garantisce il diritto non 
            esclusivo e non trasferibile di utilizzare la Piattaforma e di usufruire dei Servizi nei soli modi 
            espressamente consentiti dalla Piattaforma e disciplinati dai medesimi Termini e Condizioni Generali.
        `
    },
    "tc-2-2": {
        en: `The Customer must respect any technical limits as may be present on the Platform, without in any way altering the Platform. It is specifically understood that Glickon reserves all rights not specifically licensed in compliance with the General Terms and Conditions.
        `,
        it: `
            Il Cliente dovrà attenersi a qualsiasi limitazione tecnica presente nella Piattaforma, senza alterare in 
            alcun modo la Piattaforma stessa. È espressamente inteso che Glickon si riserva tutti i diritti non 
            specificamente licenziati in conformità dei Termini e Condizioni Generali.
        `
    },
    "tc-2-3": {
        en: `The licence granted in accordance with these General Terms and Conditions is valid worldwide.
        `,
        it: `
            La licenza concessa ai sensi di questi Termini e Condizioni Generali è valida in tutto il mondo.
        `
    },
    "tc-2-4": {
        en: `It is agreed that the selection of candidates upon completion of a Business Challenge is the exclusive competence of the Client, which will apply any selection criteria it deems appropriate, with all contribution by Glickon in these selection processes, excluded.
        `,
        it: `
                Resta inteso che l’attività di selezione dei candidati all’esito di una Challenge Aziendale è di 
                esclusiva competenza del Cliente, che applicherà i criteri di selezione che ritiene più appropriati, 
                essendo escluso qualunque apporto di Glickon in tali processi di selezione.
            `
    },

    "tc-3": {
        en: "Intellectual property rights",
        it: "Diritti di proprietà intellettuale"
    },
    "tc-3-1": {
        en: `The Customer acknowledges that all rights and titles of intellectual property over the Platform and Services supplied by Glickon Services in accordance with these General Terms and Conditions, in particular, but not only, over the Glickon Contents, the Glickon Tests, the Results and the Reports are, and shall remain, the property of Glickon Services and/or its licensors. No rights nor interests over such are transferred to the Customer.
        `,
        it: `
            Il Cliente riconosce che tutti i diritti e i titoli di proprietà intellettuale sulla Piattaforma e sui 
            Servizi forniti da Glickon ai sensi di questi Termini e Condizioni e Generali, in particolare ma non solo 
            sul Contenuto Glickon, sui Test Glickon, sui Risultati e sulle Reportistiche, sono e rimarranno di Glickon 
            e/o dei suoi licenzianti. Nessun diritto né interesse sugli stessi viene trasferito al Cliente.
        `
    },
    "tc-3-2": {
        en: `In signing the General Terms and Conditions, the Customer acquires the perpetual, non-transferable right to use the Results and Reports of its Business Challenges, depending on the Service chosen each time in each Order. The Customer acquires no implicit licence to copy or reconstruct any part of the intellectual property of Glickon. Glickon specifically reserves the right to suspend the supply of the Service and access to its products each time there is even only a suspicion that, on behalf of the Customer or third parties with the consent of the Customer, the Services are being activated and/or used for any purpose relating to the monitoring, copying or testing of the Glickon Services or comparative analysis, not commissioned by Glickon itself, including, merely by way of example, reverse engineering or other similar actions in connection with any product or Service supplied by Glickon in accordance with these General Terms and Conditions, including the Platform, Glickon Contents and Glickon Tests.
        `,
        it: `
            Con la sottoscrizione dei Termini e Condizioni Generali, il Cliente acquisisce il diritto perpetuo, non 
            trasferibile, di utilizzare i Risultati e le Reportistiche delle proprie Challenge Aziendali, a seconda del 
            Servizio scelto di volta in volta in ciascun Ordine. Il Cliente non acquisisce alcuna licenza implicita di 
            copiare o ricostruire qualsiasi parte della proprietà intellettuale di Glickon. Glickon si riserva 
            espressamente il diritto di sospendere l’erogazione del Servizio e l’accesso ai propri prodotti 
            ogniqualvolta vi sia anche solo il sospetto che si verifichi per conto del Cliente, o di terzi con il 
            consenso del Cliente, l’attivazione e/o l’utilizzo dei Servizi per qualsiasi finalità di monitoraggio, 
            copia o test dei Servizi di Glickon o analisi comparative non commissionate da Glickon stessa, incluse a 
            titolo esemplificativo attività di reverse engineering o altre azioni analoghe in relazione a qualsiasi 
            prodotto o Servizio fornito da Glickon ai sensi di questi Termini e Condizioni e Generali, inclusi la 
            Piattaforma, il Contenuto Glickon e i Test Glickon.
        `
    },
    "tc-3-3": {
        en: `In the event of Customer Contents or contents used in the LIVE Game, the Customer declares and warrants that it has all intellectual property rights over such Contents, in complete compliance with Italian and European legislation. The Customer will therefore be directly, exclusively and personally liable for any breach of third-party rights and will relieve Glickon and indemnify it from all liability for any direct or indirect damages deriving from the use of the Customer Contents.
        `,
        it: `
            In caso di Contenuto Cliente, o contenuto usato nel LIVE game, il Cliente dichiara e garantisce di 
            possedere tutti i diritti di proprietà intellettuale su detto Contenuto, nel pieno rispetto della 
            normativa italiana ed europea. Il Cliente sarà pertanto direttamente, esclusivamente e personalmente 
            responsabile per qualsiasi violazione di diritti di terzi e terrà Glickon manlevata e indenne in relazione 
            a tutti i danni diretti ed indiretti derivanti dall’uso del Contenuto Cliente.
        `
    },
    "tc-3-4": {
        en: `In signing the General Terms and Conditions, Glickon acquires the non-exclusive, non-transferable right to use the Customer’s trademarks and/or logos, the Customer Contents and Customer Tests, exclusively in order to provide its Services.
       `,
        it: `
            Con la sottoscrizione dei Termini e Condizioni Generali, Glickon acquisisce il diritto, non esclusivo e non 
            trasferibile, di utilizzare i marchi e/o loghi del Cliente, il Contenuto Cliente e i Test Cliente al fine 
            esclusivo di fornire i propri Servizi.
        `
    },
    "tc-3-5": {
        en: `In signing the General Terms and Conditions, the Customer authorises Glickon to include the Customer as reference (logo, Customer name, case history and screenshots) in its works portfolio (brochures, technical documentation, website), in relation to the project carried out in the fulfilment of one or more Orders, after sharing the contents with the Customer.
        `,
        it: `
            Con la sottoscrizione dei Termini e Condizioni Generali, il Cliente autorizza Glickon ad inserire nel 
            proprio portfolio lavori (brochure, documentazione tecnica, sito web) la referenza del Cliente (logo, nome 
            del Cliente, case history e screenshot) relativa al progetto svolto in esecuzione di uno o più Ordini, 
            previa condivisione dei contenuti con il Cliente.
        `
    },
    "tc-3-6": {
        en: `Glickon’s use of the trademarks, names, symbols and logos of the Customer under the scope of the publication and distribution of the Business Challenge through the Target Audience Service, is intended as approved by the Customer in signing the General Terms and Conditions.
        `,
        it: `
            L’utilizzo da parte di Glickon di marchi, denominazioni, simboli e segni distintivi identificativi del 
            Cliente nell’ambito della pubblicazione e distribuzione della Challenge Aziendale attraverso il Servizio 
            Target Audience si intende approvato da parte del Cliente con la sottoscrizione dei Termini e Condizioni Generali.
        `
    },
    "tc-3-7": {
        en: `In any case, authorisation to use the trademarks, names, symbols and logos is not definitive and can therefore always be revoked, with the Customer having the right to immediately remove any reproduction of such, on digital storage device/the web, as well as any reference as may be made to the Customer in the promotional, advertising and portfolio material, at no extra expense or burden to the Customer or Glickon.
        `,
        it: `
            In qualsiasi caso, l’autorizzazione all’utilizzo di marchi, denominazioni, simboli e segni distintivi non è 
            da intendersi definitiva, e quindi sempre revocabile, con diritto del Cliente di richiedere la rimozione 
            immediata di qualsiasi riproduzione degli stessi, su supporto digitale/web, nonché di ogni riferimento che 
            dovesse essere fatto al Cliente all’interno del materiale promozionale, pubblicitario e di portfolio, senza 
            nessuna ulteriore spesa o aggravio per il Cliente né per Glickon.
        `
    },

    "tc-4": {
        en: "Personal data protection and ownership",
        it: "Protezione e proprietà dati personali"
    },
    "tc-4-1": {
        en: `In the event of the White Label Mode, in derogation to Article 3.1, Glickon will not be the owner of any intellectual property rights or title over the Results and Reports on the activities of Private Users; these shall therefore remain with the Customer.
        `,
        it: `
            In caso di Modalità White Label, in deroga all’Articolo 3.1 Glickon non sarà titolare dei diritti e titoli 
            di proprietà intellettuale sui Risultati e sulle Reportistiche dell’attività degli Utenti Privati, che 
            rimarranno dunque del Cliente.
        `
    },
    "tc-4-2": {
        en: `In the event of the White Label Mode, the Customer is the sole Controller of the personal data of the Private User processed through the use of the Platform, while Glickon is Data Processor: the Controller appoints the Processor by separate deed, which constitutes an integral and substantive part of these General Terms and Conditions.
        `,
        it: `
            In caso di Modalità White Label il Cliente è Titolare unico del trattamento di dati personali dell’Utente 
            Privato effettuato tramite utilizzo della Piattaforma, mentre Glickon ricopre il ruolo di Responsabile del 
            trattamento: il Titolare nomina il Responsabile del trattamento con atto separato che costituisce parte 
            integrante e sostanziale del presente Termini e Condizioni Generali.
        `
    },
    "tc-4-3": {
        en: `In the event of a LIVE Game service, in derogation to Article 3.1, Glickon will not be the owner of any intellectual property rights or title over the LIVE Game Results of the LIVE Users; these shall therefore remain with the Customer.
        `,
        it: `
            In caso di servizio LIVE game, in deroga all’Articolo 3.1 Glickon non sarà titolare dei diritti e titoli di 
            proprietà intellettuale sui Risultati LIVE game degli Utenti LIVE, che rimarranno dunque del Cliente.
        `
    },
    "tc-4-4": {
        en: `In the event of the White Label Mode, the Customer undertakes to provide Glickon with a disclosure on the processing of personal data to be published on the Platform, which must be displayed and, if necessary, accepted by the Private User before accessing said Platform. For lack of the text of the personal data processing disclosure, Glickon reserves the right to block access to the Platform by the Users.
        `,
        it: `
            In caso di modalità White Label, il Cliente si impegna a fornire a Glickon l’informativa sul trattamento dei 
            dati personali da pubblicare sulla Piattaforma che dovrà essere visionata e, se del caso, accettata dall’
            Utente Privato prima dell’accesso alla Piattaforma medesima. In mancanza del testo di informativa sul 
            trattamento dei dati personali, Glickon si riserva il diritto di bloccare l’accesso alla Piattaforma da 
            parte degli Utenti.
        `
    },
    "tc-4-5": {
        en: `In the event of White Label Business Challenge Mode, the Customer is the sole Controller of the personal data of the User processed through the use of the Platform, while Glickon is Data Processor: the Controller appoints the Processor by separate deed, which constitutes an integral and substantive part of these General Terms and Conditions.
        `,
        it: `
            In caso di modalità Challenge aziendale White Label il Cliente è Titolare unico del trattamento di dati 
            personali dell’Utente effettuato tramite utilizzo della Piattaforma, mentre Glickon ricopre il ruolo di 
            Responsabile del trattamento: il Titolare nomina il Responsabile del trattamento con atto separato che 
            costituisce parte integrante e sostanziale dei presenti Termini e Condizioni Generali.
        `
    },
    "tc-4-6": {
        en: `In signing the General Terms and Conditions, the Customer declares and warrants that it complies with the application of all provisions of personal data protection laws, aiming to guarantee the data subject lawful, correct, transparent processing, with specific reference to the collection and processing of personal data carried out by means of the use of the Platform: the Customer will therefore be directly, exclusively and personally liable for any breach of third party rights and will relieve Glickon and indemnify it from all and any liability in respect of all direct and indirect damages deriving from the processing of personal data in connection with use of the Platform.
        `,
        it: `
            Con la sottoscrizione dei Termini e Condizioni Generali Il Cliente dichiara e garantisce di rispettare 
            l’applicazione di tutte le disposizioni di legge in materia di protezione dei dati personali, atte a 
            garantire un trattamento lecito, corretto e trasparente verso l’interessato, con specifico riferimento 
            alla raccolta ed al trattamento dei dati personali effettuato tramite utilizzo della Piattaforma: il 
            Cliente sarà pertanto direttamente, esclusivamente e personalmente responsabile per qualsiasi violazione 
            di diritti di terzi e terrà Glickon manlevata e indenne in relazione a tutti i danni diretti ed indiretti 
            derivanti dai trattamenti di dati personali connessi all’uso della Piattaforma.
        `
    },
    "tc-4-7": {
        en: `The Customer hereby authorises Glickon to proceed with the anonymisation and/or aggregation of the data of Private Users and to use such in this way, anonymous and/or aggregated, for purposes of analysing the function and improving the service (e.g. statistics, data on use) and to develop and supply other products and services to its customers and/or the market: in the event of proposals to the customer, its data may also only be in aggregate form; in all other cases, it must be made anonymous.
        `,
        it: `
           Il Cliente autorizza sin d’ora Glickon a procedere ad anonimizzare e/o aggregare i dati degli Utenti Privati 
           e ad utilizzarli in tale modalità, anonima e/o aggregata, per scopi di analisi della funzionalità e 
           miglioramento del servizio (es. statistiche, dati di utilizzo) nonché per sviluppare e fornire altri 
           prodotti e servizi alla propria clientela e/o al mercato: in caso di proposte allo stesso cliente i dati 
           dello stesso potranno essere anche solo aggregati, in ogni altro caso dovranno essere anonimizzati.
        `
    },

    "tc-5": {
        en: "Account",
        it: "Account"
    },
    "tc-5-1": {
        en: `Depending on the agreed Service and Price, Glickon will issue the Customer with a variable number of Accounts. The credentials of each Account thus issued will be e-mailed to the Reference Person, who will then be responsible for any distribution within the Customer’s structure.
        `,
        it: `
            A seconda del Servizio e del Corrispettivo concordati, Glickon rilascerà al Cliente un numero variabile di 
            Account. Le credenziali di ciascun Account così rilasciato saranno inviate via e-mail al Referente, che sarà 
            poi responsabile dell’eventuale distribuzione all’interno della struttura del Cliente.
        `
    },
    "tc-5-2": {
        en: `The Customer acknowledges that in order to be able to use the Platform, the Account will need to be validated; this will take place through using a username and password to be issued to the Reference Person after placing the Order.
        `,
        it: `
            Il Cliente riconosce che per poter utilizzare la Piattaforma sarà necessaria una convalida dell’Account, 
            che avverrà tramite l’uso di un nome utente e di una password che saranno rilasciati al Referente 
            successivamente alla sottoscrizione dell’Ordine.
        `
    },
    "tc-5-3": {
        en: `The Customer undertakes not to publish and/or distribute to any third parties the usernames and passwords supplied by Glickon to access the Platform.
        `,
        it: `
            Il Cliente si impegna a non pubblicare e/o distribuire a terzi i nomi utenti e password forniti da Glickon 
            per l’accesso alla Piattaforma.
        `
    },
    "tc-5-4": {
        en: `Responsibility for the activities carried out on the Dashboard (merely by way of example the uploading and/or amendment of the Contents), by the Customer’s Accounts, shall lie exclusively with the Customer.
        `,
        it: `
            La responsabilità delle attività svolte sulla Dashboard (a titolo esemplificativo e non esaustivo, il 
            caricamento e/o la modifica di Contenuto) dagli Account del Cliente ricadrà unicamente sul Cliente medesimo.
        `
    },
    "tc-5-5": {
        en: `The Customer must inform Glickon if it becomes aware of any access to or use of the Platform by any unauthorised third party, as soon as it discovers the breach.
        `,
        it: `
            Il Cliente dovrà informare Glickon qualora venga a conoscenza di un accesso alla Piattaforma o dell’uso 
            dello stesso da parte di un terzo non autorizzato non appena scoperta la violazione.
        `
    },
    "tc-5-6": {
        en: `The Customer hereby authorises Glickon (and its employees) to access the Accounts assigned to the Customer in order to create, amend or view the Contents and/or Text or what may be considered necessary in order to supply technical IT support to the Customer in use of the Platform.
        `,
        it: `
            Il Cliente autorizza sin d’ora Glickon (e i suoi dipendenti) ad accedere agli Account assegnati al Cliente 
            medesimo al fine di creare, modificare o visionare Contenuto e/o Test o quanto ritenuto necessario per 
            fornire supporto tecnico informatico al Cliente nell’utilizzo della Piattaforma.
        `
    },

    "tc-6": {
        en: "Target Audience",
        it: "Target audience"
    },
    "tc-6-1": {
        en: `If the Customer should decide to avail itself of the Candidate Experience Service, Glickon may share the related Contents, uploaded to the Platform using any communication channel, including, by way of example, its community, social channels and job board partners.
        `,
        it: `
            Laddove il Cliente decida di avvalersi del Servizio Candidate Experience, Glickon potrà condividere il 
            relativo Contenuto caricato in Piattaforma mediante qualsiasi canale di comunicazione, inclusi a titolo 
            esemplificativo la propria community, i propri canali social e i job board partner.
        `
    },
    "tc-6-2": {
        en: `If the Customer should have acquired the Target Audience Service, Glickon will e-mail the Customer the social networks and any other chosen promotional channels on which the promotional campaign is to be run.
        `,
        it: `
            Nel caso in cui il Cliente abbia acquistato il Servizio di Target Audience, Glickon comunicherà via e-mail 
            al Cliente i social network e gli eventuali altri canali promozionali prescelti su cui sarà veicolata la 
            campagna di promozione.
        `
    },
    "tc-6-3": {
        en: `The duration of the Target Audience Service will be decided by Glickon on the basis of the Customer’s specific demands.
        `,
        it: `
            La durata del Servizio di Target Audience sarà decisa da Glickon in base alle specifiche esigenze del Cliente.
        `
    },
    "tc-6-4": {
        en: `Glickon will not in any case be liable for any inefficiencies and/or interruptions to the Target Audience activity for reasons for which it is not responsible.
        `,
        it: `
            Glickon non sarà comunque responsabile di eventuali inefficienze e/o interruzioni dell’attività di Target 
            Audience per ragioni a questa non direttamente imputabili.
        `
    },

    "tc-7": {
        en: "Prices: payment terms and conditions",
        it: "Corrispettivi: termini e modalità di pagamento"
    },
    "tc-7-1": {
        en: `The Prices due to Glickon for the concession of the licence to use the Platform and the supply of the Services are negotiated by the Parties and defined in each Order.
        `,
        it: `
            I Corrispettivi dovuti a Glickon per la concessione della licenza d’uso della Piattaforma e per la fornitura 
            dei Servizi sono negoziati tra le parti e definiti in ciascun Ordine.
        `
    },
    "tc-7-2": {
        en: `Unless otherwise negotiated by the Parties, the Prices will be invoiced upon placing the Order.
        `,
        it: `
            Salvo diversa negoziazione tra le Parti, i Corrispettivi saranno fatturati alla sottoscrizione dell’Ordine.
        `
    },
    "tc-7-3": {
        en: `Unless otherwise negotiated by the Parties, the terms for making payment of the Prices are established as 30 days from the date of issue of the invoice.
        `,
        it: `
            Salvo diversa negoziazione tra le Parti, i termini di pagamento dei Corrispettivi sono stabiliti in 30 
            giorni dalla data di emissione della fattura.
        `
    },
    "tc-7-4": {
        en: `The Prices will be paid by bank transfer to the bank account, details of which are given on the Order.
        `,
        it: `
            Il pagamento dei Corrispettivi avverrà a mezzo bonifico bancario alle coordinate bancarie indicate 
            nell’Ordine.
        `
    },
    "tc-7-5": {
        en: `In the event of a delay of any payment due by virtue of any Order and these General Terms and Conditions, the Customer shall pay Glickon late payment interest in the amount of the legal rate pursuant to Art. 1284 of the Italian Civil Code.
        `,
        it: `
            In caso di ritardo in qualunque pagamento dovuto in forza di qualsiasi Ordine e di questi Termini e 
            Condizioni Generali, il Cliente corrisponderà a Glickon interessi moratori in misura pari al tasso legale di 
            cui all’articolo 1284 c.c..
        `
    },

    "tc-8": {
        en: "Duration",
        it: "Durata"
    },
    "tc-8-1": {
        en: `The duration of the licence to use the Platform and for the supply of the Services is as stated in each Order.
        `,
        it: `
            La durata della licenza della Piattaforma e della fornitura dei Servizi è quella prevista in ciascun Ordine.
        `
    },
    "tc-8-2": {
        en: `Said duration, in terms of the application of the General Terms and Conditions shall run from when both Parties sign each Order. Unless the Order has naturally expired or been terminated early in compliance with Article 14 of these General Terms and Conditions, the latter shall remain in effect until the expiry or termination of the last Order stipulated by and between the Parties.
        `,
        it: `
            Detta durata, ai fini dell’applicabilità dei Termini e Condizioni Generali, decorre dalla sottoscrizione di 
            entrambe le Parti di ciascun Ordine. Salvo il caso in cui l’Ordine sia scaduto naturalmente o risolto 
            anticipatamente in conformità con l’articolo 14 di questi Termini e Condizioni Generali, questi ultimi 
            avranno efficacia fino alla scadenza o alla risoluzione dell’ultimo Ordine stipulato tra le Parti.
        `
    },

    "tc-9": {
        en: "Guarantees",
        it: "Garanzie"
    },
    "tc-9-1": {
        en: `Within the maximum limits permitted by applicable law, Glickon shall not provide any guarantee in relation to the fitness of the Platform for a specific aim (including a guarantee of correct production function in accordance with Art. 1512 of the Italian Civil Code) nor indeed of the compatibility of the Platform with other programs and/or systems used by the Customer.
        `,
        it: `
            Nei limiti massimi consentiti dalla legge applicabile, Glickon non fornisce alcuna garanzia relativa alla 
            rispondenza della Piattaforma a uno specifico scopo (inclusa la garanzia di buon funzionamento dei prodotti 
            ai sensi dell’art. 1512 c.c.) né alla compatibilità della Piattaforma con altri programmi e/o sistemi 
            utilizzati dal Cliente.
        `
    },
    "tc-9-2": {
        en: `Within the limits permitted by applicable law, Glickon does not guarantee that the Platform will function uninterruptedly nor without error.
        `,
        it: `
            Nei limiti consentiti dalla legge applicabile, Glickon non garantisce che il funzionamento della 
            Piattaforma sia ininterrotto né privo di errori.
        `
    },
    "tc-9-3": {
        en: `This Article shall survive any cessation of these General Terms and Conditions.
        `,
        it: `
            Questo articolo sopravvivrà alla cessazione, a qualunque titolo, dei presenti Termini e Condizioni Generali.
        `
    },

    "tc-10": {
        en: "Limit of liability",
        it: "Limitazione di responsabilità"
    },
    "tc-10-1": {
        en: `Glickon’s liability is strictly limited to the obligations pursuant to the General Terms and Conditions and Orders and, in any case, to the direct, foreseeable damages caused to the Customer, with the sole exception of neglect or wilful negligence on the part of Glickon.
        `,
        it: `
            La responsabilità di Glickon è strettamente limitata alle obbligazioni di cui ai Termini e Condizioni 
            Generali e agli Ordini e, in ogni caso, ai danni diretti e prevedibili cagionati al Cliente, fatta 
            eccezione per il solo dolo o colpa grave di Glickon.
        `
    },
    "tc-10-2": {
        en: `Within the maximum limit permitted by applicable law, Glickon’s liability is excluded in regard to the Customer and third parties for any damage (including loss of data, business, profit, goodwill or other financial losses) deriving or relating to the Services supplied by Glickon and any cost as may be incurred by the Customer to procure replacement services. Glickon also refuses all liability in regard to the Customer for services supplied by third parties and any damages deriving from the improper or negligent use or function of the Platform.
        `,
        it: `
            Nella misura massima consentita dalla legge applicabile, è esclusa la responsabilità di Glickon nei 
            confronti del Cliente e di terzi per qualsiasi danno (inclusi la perdita di dati, di attività, di profitti, 
            di avviamento o altre perdite economiche) che derivi o sia correlato ai Servizi erogati da Glickon nonché a 
            qualsiasi costo eventualmente sostenuto dal Cliente per procurarsi servizi sostitutivi. Glickon non avrà 
            inoltre alcuna responsabilità nei confronti del Cliente per servizi forniti da terze parti, né per 
            qualsiasi danno derivante da un uso o da un funzionamento impropri o negligenti della Piattaforma.
        `
    },
    "tc-10-3": {
        en: `The Customer undertakes to defend, relieve from liability and indemnify Glickon and its directors, employees and agents from any claim (merely by way of example damages, criminal, interest and legal costs) deriving directly or indirectly from the threatened or ascertained breach of third party rights in connection with any service supplied by the Customer in connection with the supply of the Services.
        `,
        it: `
            Il Cliente si impegna a difendere, manlevare e tenere indenne Glickon e i suoi amministratori, dipendenti e 
            agenti da qualsiasi azione (a titolo esemplificativo ma non esaustivo, danni, penali, interessi e spese 
            legali) derivanti, direttamente o indirettamente, dalla violazione minacciata o accertata di diritti di 
            terzi in relazione a qualsiasi servizio erogato dal Cliente connesso alla fornitura dei Servizi.
        `
    },
    "tc-10-4": {
        en: `In economic terms, Glickon shall be liable up to the amounts effectively paid by the Customer in fulfilment of the Order, where Glickon should be held liable, in accordance with the limits set out under point 10.2 above. This same limit shall apply in the event of the liability of employees or collaborators of Glickon. This Article shall survive any cessation of these General Terms and Conditions.
        `,
        it: `
            Sotto il profilo economico Glickon sarà responsabile nei limiti delle somme effettivamente corrisposte dal 
            Cliente in esecuzione di quell’Ordine, laddove Glickon dovesse essere ritenuta responsabile, nei limiti di 
            quanto previsto al precedente punto 10.2. La stessa limitazione varrà in caso di responsabilità di 
            dipendenti o collaboratori di Glickon. Il presente articolo sopravvivrà alla cessazione, a qualunque 
            titolo, dei presenti Termini e Condizioni Generali.
        `
    },

    "tc-11": {
        en: "Service availability",
        it: "Disponibilità del servizio"
    },
    "tc-11-1": {
        en: `Glickon establishes an uptime of 99.8% per year, and in any case no greater than that envisaged by the service levels of its suppliers for the server storage, hosting and carrier services necessary to the provision of the Services by Glickon. Glickon shall not in any way guarantee that the Services will be supplied uninterruptedly and with no discontinuation.
        `,
        it: `
            Glickon stabilisce un uptime pari al 99.8% su base annuale, e comunque non superiore a quanto previsto dai 
            livelli di servizio dei propri fornitori per i servizi di server storage, hosting e carrier necessari all’
            erogazione dei Servizi da parte di Glickon. Glickon non garantisce in alcun modo che i Servizi verranno 
            forniti senza interruzione o discontinuità nell’erogazione.
        `
    },
    "tc-11-2": {
        en: `If the Customer should note a malfunction in the Services, it shall inform Glickon of such promptly, e-mailing <a href="mailto:support@glickon.com" target="_blank">support@glickon.com</a>; this service is operative during office hours (Mon/Fri 09:00-18:00).
        `,
        it: `
            Nell’ipotesi in cui il Cliente riscontri un malfunzionamento dei Servizi, lo stesso dovrà prontamente 
            segnalarlo a Glickon, dandone comunicazione a mezzo e-mail all’indirizzo: <a href="mailto:support@glickon.com" target="_blank">support@glickon.com</a>, servizio attivo 
            in orario di ufficio (lun/ven 9:00-18:00).
        `
    },
    "tc-11-3": {
        en: `Glickon guarantees that the problem will be processed within 120 minutes of its being reported by the Customer in compliance with the provisions of the point above.
        `,
        it: `
            Glickon garantisce la presa in carico del problema entro 120 minuti dalla segnalazione effettuata dal 
            Cliente conformemente a quanto previsto al punto precedente.
        `
    },
    "tc-11-4": {
        en: `The report made by the Customer will be assessed by Glickon staff, who will respond appropriately to the Customer by telephone or e-mail.
        `,
        it: `
            La segnalazione effettuata dal Cliente verrà valutata dal personale di Glickon, che provvederà a 
            dare risposta adeguata al Cliente via telefono o e-mail.
        `
    },
    "tc-11-5": {
        en: `If Glickon staff agree that the responsibility for the Service malfunction lies with Glickon, the downtime (time during which the service is not supplied) will be calculated as follows: (i) downtime of less than 15 minutes is excluded from the total calculation; (ii) downtime caused by failures or problems external to the network of the hosting service supplier or causes of force majeure or Act of God (merely by way of example: failures of the internet connection used by the Customer to connect to the server or failures in the carriers supplying connectivity) shall be excluded from the total calculation; (iii) routine maintenance works are generally scheduled from 00:00 to 06:00 on Saturdays and Sundays. Additional routine maintenance works entailing machine downtime in excess of 120 minutes are notified to the Customer at least 24 hours in advance of said downtime. However, without prejudice to the rule established in this Article, in the sole event that Glickon should not make said notification of service downtime, the period for which the Services are not supplied will be included in the calculation of total downtime.
        `,
        it: `
            Nell’ipotesi in cui il personale di Glickon convenga che le responsabilità del malfunzionamento del 
            Servizio siano da attribuire a Glickon, il periodo di downtime (mancata erogazione del servizio) verrà 
            conteggiato in base alle seguenti modalità: (i) sono esclusi dal conteggio totale i downtime inferiori ai 
            15 minuti, (ii) sono esclusi dal conteggio totale le interruzioni causate da guasti o problemi esterni alla 
            rete del fornitore del servizio di hosting o da cause di forza maggiore o caso fortuito (a titolo 
            esemplificativo ma non esaustivo: guasti al collegamento internet utilizzato dal Cliente per connettersi al 
            server, oppure guasti dei carrier che forniscono la connettività), (iii) gli interventi di manutenzione 
            ordinaria sono generalmente pianificati a partire dalle 00.00 alle 06.00 di sabato e di domenica. 
            Ulteriori interventi di manutenzione ordinaria che comportano un fermo della macchina superiore ai 120 
            minuti sono comunicati al Cliente con un preavviso di almeno 24 ore dal fermo medesimo. Tuttavia, senza 
            pregiudizio per la regola stabilita nel presente articolo, nella sola ipotesi in cui Glickon non effettui 
            la suddetta comunicazione di fermo del servizio, il periodo di mancata erogazione dei Servizi verrà incluso 
            nel conteggio totale di downtime.
        `
    },
    "tc-11-6": {
        en: `Glickon guarantees the availability of the Services on the internet within the above limits; it shall not guarantee that the Customer is always able to connect to Glickon. Glickon will not be held in any way liable in regard to the Customer for failure to use the Services when such is impossible for any reason for which Glickon is not directly responsible (merely by way of example reasons relating to the Customer's hardware and software configuration, reasons linked to the connectivity offered by the Customer’s suppliers).
        `,
        it: `
            Glickon garantisce la disponibilità dei Servizi sulla rete Internet nei limiti sopra indicati, ma non 
            assicura che il Cliente sia sempre in grado di connettersi a Glickon. Glickon non sarà in alcun modo 
            responsabile nei confronti del Cliente per la mancata fruizione dei Servizi quando tale impossibilità sia 
            determinata da qualsiasi motivo non direttamente imputabili a Glickon (a titolo esemplificativo e non 
            esaustivo: motivi dovuti alla configurazione hardware e software del Cliente, motivi dovuti alla 
            connettività offerta dai fornitori del Cliente).
        `
    },

    "tc-12": {
        en: "Data integrity",
        it: "Integrità dei dati"
    },
    "tc-12-1": {
        en: `From a physical point of view, Glickon’s servers are in housing at the company Heroku Inc., whilst the static files reside on Amazon’s servers. The Customer acknowledges that through Heroku Inc., Glickon adopts the standards necessary to protect the Customer’s data, including, but not only, guardian systems, fire-fighting measures, continuity of current and firewalls to protect against attempted hacking.
        `,
        it: `
            Da un punto di vista fisico, i server di Glickon sono in housing presso la società Heroku Inc. mentre i 
            file statici risiedono presso i server di Amazon. Il Cliente riconosce che Glickon, tramite Heroku Inc., 
            adotta gli standard necessari alla protezione dei dati del Cliente, tra gli altri ma non solo in termini di 
            sistemi di guardia, misure antincendio, continuità di corrente, firewall contro tentativi di hackeraggio.
        `
    },
    "tc-12-2": {
        en: `Glickon reserves the right to transfer its servers to other companies that are not Heroku Inc., after duly notifying the Customer to this end.
        `,
        it: `
            Glickon si riserva il diritto di trasferire i suoi server presso altre società che non siano Heroku Inc. 
            previa comunicazione al Cliente.
        `
    },
    "tc-12-3": {
        en: `In technological terms, the Customer data acknowledges that Glickon adopts the measures necessary to protect the Customer’s data present in the system. This acknowledgement is based on the documentation as may have been supplied by Glickon to the Customer and any informative meetings between Glickon technicians and the technicians of the Customer.
        `,
        it: `
            Da un punto di vista tecnologico, il Cliente riconosce che Glickon adotta le misure necessarie per la 
            protezione e la tutela dei dati del Cliente presenti nel sistema. Questo riconoscimento si basa sulla 
            documentazione eventualmente fornita da Glickon al Cliente e da eventuali incontri informativi tra 
            tecnici Glickon e tecnici del Cliente.
        `
    },
    "tc-12-4": {
        en: `The security measures adopted in processing the Customer’s data do not make Glickon liable for such. Glickon shall not be held in any way liable for any loss of data present in the Glickon system or any direct or indirect damages deriving from the loss of the Customer’s database, without prejudice to cases of misconduct or gross negligence on the part of Glickon.
        `,
        it: `
            Le misure di sicurezza adottate nel trattamento dei dati del Cliente non rendono Glickon responsabile degli 
            stessi. Glickon non sarà in alcun modo responsabile per la perdita dei dati presenti nel sistema Glickon o 
            di eventuali danni, diretti o indiretti, derivanti dalla perdita della base dati del Cliente, fatti salvi 
            i casi di dolo o colpa grave imputabili a Glickon.
        `
    },
    "tc-12-5": {
        en: `The above specifications listed for the data security copies may be changed without notice as long as such changes constitute a raising of the security standards.
        `,
        it: `
            Le specifiche sopra elencate per le copie di sicurezza dei dati possono essere variate senza alcun preavviso 
            a condizione che rappresentino un innalzamento dello standard di sicurezza.
        `
    },
    "tc-12-6": {
        en: `Moreover, Glickon shall never be held liable for data integrity or confidentiality and specifically if the Glickon systems should be subject to functioning anomalies or hacking attempts.
        `,
        it: `
            Inoltre, Glickon non è mai responsabile né dell'integrità dei dati né della loro riservatezza e 
            specificamente nel caso in cui i sistemi di Glickon siano soggetti ad anomalie di funzionamento o 
            tentativi di hackeraggio.
        `
    },
    "tc-12-7": {
        en: `In any case, the Customer may only claim an amount equal to the Price paid, proportionally to the data lost, without prejudice to cases of misconduct or gross negligence on the part of Glickon.
        `,
        it: `
            In qualsiasi caso il Cliente potrà rivalersi solamente per l'ammontare pari al Corrispettivo versato, in 
            proporzione ai dati persi, fatti salvi i casi di dolo o colpa grave imputabili a Glickon.
        `
    },

    "tc-13": {
        en: "Support and additional services",
        it: "Servizi di supporto e supplementari"
    },
    "tc-13-1": {
        en: `Unless otherwise agreed by the Parties, the supply of technical services by Glickon is not envisaged, apart from the guarantee or normal use and function of the Platform concerned by the General Terms and Conditions.
        `,
        it: `
            Salvo che sia diversamente concordato tra le Parti, non è prevista la fornitura di servizi di supporto 
            tecnico da parte di Glickon, ad eccezione della garanzia di normale fruizione e funzionamento della 
            Piattaforma oggetto dei Termini e Condizioni Generali.
        `
    },
    "tc-13-2": {
        en: `At the request of the Customer, Glickon will provide, on a one-off basis, an introductory service (for a total of 2 hours) of on-line training on how to use the Platform, Dashboard, the change to the Company Page, the creation of the Business Challenge and the Contents. The Price for any additional hours of training over and above the first two as may be requested by the Customer will be negotiated by the Parties.
        `,
        it: `
            Su richiesta del Cliente, Glickon fornirà, una tantum, un servizio introduttivo (per complessive 2 ore) di 
            formazione online sull’uso della Piattaforma, della Dashboard, sulla modifica della Company Page, sulla 
            creazione delle Challenge Aziendale e del Contenuto. Il Corrispettivo per le eventuali ore di formazione 
            addizionali rispetto alle prime 2, eventualmente richieste dal Cliente, sarà oggetto di negoziazione tra le Parti.
        `
    },

    "tc-14": {
        en: "Termination",
        it: "Risoluzione"
    },
    "tc-14-1": {
        en: `<strong>Termination due to breach.</strong> Each Party may terminate an Order if the other Party commits serious breach of any provision of the General Terms and Conditions and fails to remedy said breach within 30 days of notice by the other Party describing said breach in a reasonably detailed manner.
        `,
        it: `
            <strong>Risoluzione per inadempimento.</strong> Ciascuna Parte può risolvere un Ordine se l’altra Parte 
            commette un grave inadempimento di qualsiasi disposizione dei Termini e Condizioni Generali e non sani tale 
            grave inadempimento entro 30 giorni dalla diffida dell’altra Parte che descriva tale inadempimento in 
            maniera ragionevolmente dettagliata.
        `
    },
    "tc-14-2": {
        en: `<strong>Express termination clause.</strong> Glickon may terminate an Order at any time by sending written notice to the Customer if the latter should breach any of the obligations envisaged by Articles 2, 3 and 4 above.
        `,
        it: `
            <strong>Clausola risolutiva espressa.</strong> Glickon potrà in ogni momento risolvere un Ordine inviando 
            una comunicazione scritta al Cliente nel caso in cui quest’ultimo violi uno qualsiasi degli obblighi 
            previsti dagli articoli 2, 3, 4 che precedono.
        `
    },
    "tc-14-3": {
        en: `<strong>Effects of termination.</strong> Following termination of an Order, these General Terms and Conditions shall also be understood as terminated. Without prejudice to where otherwise specifically envisaged, all the rights and obligations pursuant to the Order and General Terms and Conditions thus terminated will immediately cease all effect. This is in any case without prejudice to the right of Glickon to withhold the Price paid and demand compensation for any greater damages. Termination shall not prevent either Party from pursuing other remedies available.
        `,
        it: `
            <strong>Effetti della risoluzione.</strong> A seguito della risoluzione di un Ordine si intenderanno 
            risolti anche questi Termini e Condizioni Generali. Salve le diverse previsioni espresse, tutti i 
            diritti e gli obblighi di cui all’Ordine e ai Termini e Condizioni Generali così risolti cesseranno 
            immediatamente di avere effetto. Resterà in ogni caso salvo il diritto di Glickon di trattenere il 
            Corrispettivo pagato, nonché di richiedere ogni eventuale maggior danno. La risoluzione non limiterà 
            nessuna Parte dall’esperire altri rimedi disponibili.
        `
    },

    "tc-15": {
        en: "Non-competition",
        it: "Non concorrenza"
    },
    "tc-15-1": {
        en:
            "Without prejudice to where expressly permitted by the General Terms and Conditions, the Customer undertakes specifically not to directly or indirectly go about, as developer, supplier, contractor or other, the same or similar activities as those carried out by Glickon under the scope of the supply of the Services. By way of example, the Customer may not: (i) use the Platform to supply or make operative marketing services, outsourcing, consultancy or any other commercial services; (ii) use the Platform to develop a product in competition with the Platform; (iii) allow third parties to access or use the Platform, except where envisaged in the General Terms and Conditions and/or Order; nor (iv) make any unauthorised use of usernames and passwords.",
        it: `
            Salvo quanto espressamente consentito dai Termini e Condizioni Generali, il Cliente si impegna 
            espressamente a non intraprendere, direttamente o indirettamente, né in qualità di sviluppatore, 
            fornitore, appaltatore o altro, le stesse o analoghe attività svolte da Glickon nell’ambito dell’
            erogazione dei Servizi. A titolo esemplificativo, il Cliente non potrà: (i) usare la Piattaforma per 
            fornire o rendere operativi servizi di marketing, outsourcing, consulenza, o qualsiasi altro servizio 
            commerciale; (ii) usare la Piattaforma per sviluppare un prodotto che è in concorrenza con la 
            Piattaforma; (iii) consentire a terzi l’accesso o l’utilizzo della Piattaforma, salvo quanto 
            eventualmente previsto nei Termini e Condizioni Generali e/o nell’Ordine; né (iv) usare nomi 
            utente e password non autorizzati.
        `
    },

    "tc-16": {
        en: "Miscellaneous",
        it: "Miscellanea"
    },
    "tc-16-1": {
        en:
            "<strong>Applicable law and competent court.</strong> Any dispute relating to the interpretation and/or execution of the General Terms and Conditions and Orders referring to such will be subject to the exclusive application of Italian law, excluding any conflict of law; the Court of Milan shall have exclusive jurisdiction.",
        it: `
            <strong>Legge Applicabile e Foro Competente.</strong> Per qualsiasi controversia inerente l’interpretazione 
            e/o l’esecuzione dei Termini e Condizioni Generali e degli Ordini che ad essi facciano riferimento sarà 
            esclusivamente applicabile la legge Italiana, escluso ogni conflitto di legge e sarà competente in via 
            esclusiva il Foro di Milano.
        `
    },
    "tc-16-2": {
        en: `<strong>Supplements and amendments.</strong> The General Terms and Conditions, the Description of Services and the Orders referring to such constitute the entire agreement between the Parties and replace any previous or simultaneous condition, agreement or declaration, whether verbal or written, in relation to the same subject matter. The General Terms and Conditions and the Description of Services may be amended by Glickon at any time, without notice or acceptance by the Customer. Any changes made to the General Terms and Conditions will be effective between the Parties from when such are notified (even electronically) by Glickon; this is without prejudice to the right of withdrawal by the Customer within 15 days of said notification. For Orders subject to automatic renewal, such changes shall only take effect from the first automatic renewal after the change made, in time to allow the Customer to provide notice of termination within the contractual terms, should it so wish. On its part, the Customer undertakes to verify the existence of any changes to the General Terms and Conditions in time to give notice within the contractual terms, should it so wish.
        `,
        it: `
            <strong>Integrazioni e Modifiche.</strong> I Termini e Condizioni Generali, la Descrizione dei Servizi e 
            gli Ordini che ad essi facciano riferimento costituiscono l’intero accordo tra le Parti e sostituiscono 
            qualsiasi precedente o contemporanea condizione, accordo o dichiarazione, sia verbale che scritta, 
            relativa alla materia oggetto dei medesimi. I Termini e Condizioni Generali e la Descrizione dei Servizi 
            potranno essere modificati da Glickon in qualsiasi momento, senza preavviso o accettazione da parte del 
            Cliente. Le modifiche apportate ai Termini e Condizioni Generali saranno efficaci tra le Parti a partire 
            dalla comunicazione delle medesime (anche in via elettronica) da parte di Glickon, salvo il diritto di 
            recesso del Cliente entro 15 giorni da detta comunicazione. In caso di Ordini che prevedano il tacito 
            rinnovo, tali modifiche diverranno effettive solo a partire dal primo tacito rinnovo successivo alla 
            modifica apportata in tempo utile, per consentire al Cliente la disdetta nei tempi contrattualmente 
            previsti. Da parte sua, il Cliente si impegna a verificare l’esistenza di eventuali modifiche ai 
            Termini e Condizioni Generali in tempo utile per eventualmente disdettarlo nei termini contrattualmente previsti.
        `
    },
    "tc-16-3": {
        en: `<strong>Transfer.</strong> The General Terms and Conditions and the Orders referring to such and any right and obligation connected to such cannot be sold by the Customer without the prior written consent of Glickon.
        `,
        it: `
            <strong>Cessione.</strong> I Termini e Condizioni Generali e gli Ordini che ad essi facciano riferimento e 
            qualsiasi diritto e obbligazione ad essi collegati non potranno essere ceduti dal Cliente senza il previo 
            consenso scritto di Glickon.
        `
    },
    "tc-16-4": {
        en: `<strong>Preservation of the Terms and Conditions.</strong> If any provision of the General Terms and Conditions should be declared unlawful, null or ineffective, said provision shall be amended so as to make it effective, or alternatively eliminated, and this shall not affect the validity and effectiveness of the remaining provisions.
        `,
        it: `
            <strong>Conservazione dei Termini e Condizioni.</strong> Qualora una qualsiasi disposizione dei Termini e 
            Condizioni Generali sia dichiarata illecita, nulla o inefficace, tale disposizione sarà modificata in modo 
            da renderla efficace o altrimenti eliminata, e ciò non inficerà la validità e l’efficacia delle restanti 
            disposizioni.
        `
    },
    "tc-16-5": {
        en: `<strong>Force Majeure.</strong> No Party shall be considered as defaulting in accordance with the Terms and Conditions in the event of delay or non-fulfilment of an obligation when said default is caused by an event beyond the reasonable control of said Party (an <strong>"Event of Force Majeure"</strong>). This as long as the defaulting Party provides the other Party with notice of the Event of Force Majeure in as timely a manner as possible and makes every reasonable effort to reduce the delay or avoid the default.
        `,
        it: `
            <strong>Forza maggiore.</strong> Nessuna Parte sarà considerata inadempiente ai sensi dei Termini e 
            Condizioni in caso di ritardo o mancata esecuzione di una sua obbligazione quando tale inadempimento 
            risulti da un evento al di fuori del ragionevole controllo di tale Parte (un "Evento di Forza Maggiore"). 
            Ciò a condizione che in modo ragionevolmente tempestivo la Parte inadempiente dia comunicazione all’altra 
            Parte dell’Evento di Forza Maggiore e compia ragionevoli sforzi per ridurre il ritardo o evitare l’inadempimento.
        `
    },
    "tc-16-6": {
        en: `<strong>Communication.</strong> Any administrative type communication relating to the General Terms and Conditions and Orders referring to such will be made by means of personal delivery, express courier or letter sent recorded delivery with advice of receipt, or using electronic tools (fax or scanned and sent by certified e-mail) to the Parties’ addresses, as specified on the Order.
        `,
        it: `
            <strong>Comunicazioni.</strong> Qualsiasi comunicazione di tipo amministrativo relativa ai Termini e 
            Condizioni Generali e agli Ordini che ad essi facciano riferimento sarà effettuata tramite consegna 
            personale, corriere espresso o posta raccomandata con ricevuta di ritorno, ovvero mediante strumenti 
            elettronici (via fax o mediante scannerizzazione ed invio tramite PEC) agli indirizzi delle Parti, 
            come individuati nell’Ordine.
        `
    }
}

export default translations
