import React from "react"
import {NavLinkProps} from "react-router-dom"
import {A, RouterLink} from "./styles"
import {
  generateLocalizedPath,
  isExternalLink,
  getParametrizedPath,
  appendToQueryString
} from "../../utils"
import useTranslation from "../../hooks/useTranslation"
import {Omit} from "../../types"

type LinkPropsType = Omit<NavLinkProps, "to">

type PropsType = LinkPropsType & {
  locale?: string
  params?: {key: string; value: string}[]
  extraParams?: string
  queryStringToAppend?: string
  children?: React.ReactNode
  to: any
}

const Link: React.FC<PropsType> = (props: PropsType) => {
  const [, currentLocale] = useTranslation()
  const {
    to,
    children,
    locale = currentLocale,
    queryStringToAppend,
    params,
    onClick,
    extraParams = "",
    ...otherProps
  } = props
  const link: string = generateLocalizedPath(to.toString(), locale)
  const parametrizedLink = getParametrizedPath(link, params)

  const destinationIsEqualSource =
    `${parametrizedLink}${extraParams}` === `${window.location.pathname}`

  const clickHandler = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation()

    if (destinationIsEqualSource) {
      event.preventDefault()
    }
    if (typeof onClick === "function") {
      onClick(event)
    }
  }

  if (isExternalLink(parametrizedLink)) {
    return (
      //@ts-ignore
      <A
        {...otherProps}
        className=""
        style={{}}
        href={appendToQueryString(parametrizedLink, queryStringToAppend)}
        target="_blank"
        rel="noopener noreferrer"
        onClick={clickHandler}
      >
        {children}
      </A>
    )
  } else {
    return (
      <RouterLink
        to={appendToQueryString(
          `${parametrizedLink}${extraParams}`,
          queryStringToAppend
        )}
        onClick={clickHandler}
        {...otherProps}
      >
        {children}
      </RouterLink>
    )
  }
}

export default Link
