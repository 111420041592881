import humps from "humps"
import {AxiosError, AxiosResponse} from "axios"
import {getAxiosInstance} from "../utils"

export async function resetPasswordCandidate<T extends object, R>(
  data: T,
  successFn: (res: AxiosResponse<R>) => void,
  errorFn: (err: AxiosError) => void
) {
  try {
    const values: any = humps.decamelizeKeys(data)
    const response: AxiosResponse<any> = await getAxiosInstance().post(
      `${process.env.REACT_APP_API_URL}/api/identity/v3/rpc/candidate/mails/password-reset`,
      values
    )
    successFn(humps.camelizeKeys(response) as AxiosResponse<R>)
  } catch (error) {
    errorFn((error as unknown) as AxiosError)
  }
}

export async function loginCandidate<T extends object, R>(
  data: T,
  successFn: (res: AxiosResponse<R>) => void,
  errorFn: (err: AxiosError) => void
) {
  try {
    const values: any = humps.decamelizeKeys(data)
    const payload = {
      username: values.email,
      password: values.password,
    }
    const response: AxiosResponse<any> = await getAxiosInstance().post(
      `${process.env.REACT_APP_API_URL}/api/identity/v3/candidate/session?careers_name=${values.owner}`,
        payload
    )
    localStorage.setItem("tenant", "candidate")
    successFn(humps.camelizeKeys(response) as AxiosResponse<R>)
  } catch (error) {
    errorFn((error as unknown) as AxiosError)
  }
}

export async function resendEmailCandidate<T extends object, R>(
  data: T,
  successFn: (res: AxiosResponse<R>) => void,
  errorFn: (err: AxiosError) => void
) {
  try {
    const values: any = humps.decamelizeKeys(data)
    const response: AxiosResponse<any> = await getAxiosInstance().post(
      `${process.env.REACT_APP_API_URL}/api/identity/v3/rpc/candidate/mails/verify-email`,
      values
    )
    successFn(humps.camelizeKeys(response) as AxiosResponse<R>)
  } catch (error) {
    errorFn((error as unknown) as AxiosError)
  }
}

export async function registerCandidate<T extends object, R>(
  data: T,
  successFn: (res: AxiosResponse<R>) => void,
  errorFn: (err: AxiosError) => void
) {
  try {
    const values: any = humps.decamelizeKeys(data)
    const tmp = {
      ...values,
      given_name: values.first_name,
      family_name: values.last_name
    }
    const today = new Date().toISOString()
    const payload = {
      "given_name": values.first_name,
      "family_name": values.last_name,
      "email": values.email,
      "password": values.password,
      "user_metadata": {
        "t_c_accepted_time": today,
        "p_p_accepted_time": today
      }
    }

    const response: AxiosResponse<any> = await getAxiosInstance().post(
      `${
        process.env.REACT_APP_API_URL
      }/api/identity/v3/candidate/user?careers_name=${values.owner}${
        values.redirect_url
          ? `&next_url=${values.redirect_url.replaceAll("//", "/")}`
          : ""
      }`,
      payload
    )
    localStorage.setItem("tenant", "candidate")
    successFn(humps.camelizeKeys(response) as AxiosResponse<R>)
  } catch (error) {
    errorFn((error as unknown) as AxiosError)
  }
}

export async function acceptTCCandidate<T extends object, R>(
  data: T,
  successFn: (res: AxiosResponse<R>) => void,
  errorFn: (err: AxiosError) => void
) {
  try {
    const values: any = humps.decamelizeKeys(data)
    const response: AxiosResponse<any> = await getAxiosInstance().post(
      `${process.env.REACT_APP_API_URL}/api/candidate/latest/accept_terms_and_privacy`,
      values
    )
    successFn(humps.camelizeKeys(response) as AxiosResponse<R>)
  } catch (error) {
    errorFn((error as unknown) as AxiosError)
  }
}
