import React from "react"
import { Action } from "./styles"
import classnames from "classnames"

const sizes = {
  small: "sm",
  medium: "md",
  large: "lg",
  extraLarge: "xlg"
}

const variants = {
  dark: "dark",
  ghostDark: "ghost-dark",
  ghostDarkHoverableBlueGlickon: "ghost-dark hoverable-blue-glickon",
  ghostDarkHoverableLightblueGlickon: "ghost-dark hoverable-lightblue-glickon",
  ghostDarkHoverableDark: "ghost-dark hoverable-dark",
  hoverableDark: "dark hoverable-blue-glickon",
  hoverableBlack: "glickon-black hoverable-glickon-light-black",
  white: "white",
  ghostWhite: "ghost-white",
  blueGlickon: "blue-glickon",
  blueGlickonNoHover: "blue-glickon no-hover",
  blackGlickon: "black-glickon",
  lightblueGlickon: "lightblue-glickon",
  gradientGlickon: "gradient-glickon",
  newGradientGlickon: "new-gradient",
  goodFeedback: "good-feedback",
  badFeedback: "bad-feedback"
}

type PropsType = React.ButtonHTMLAttributes<HTMLDivElement> & {
  size: string
  variant: string
  fullWidth?: boolean
  doublePadding?: boolean
}

type VariantsType = typeof variants
type SizesType = typeof sizes

const SimpleAction: React.FC<PropsType> & {
  variants: VariantsType
  sizes: SizesType
} = (props: PropsType) => {
  const {
    children,
    size,
    variant,
    disabled = false,
    onClick,
    fullWidth = false,
    doublePadding = false,
    className,
    ...otherProperties
  } = props

  const classes = classnames(className, size, variant)

  const clickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    if (disabled) {
      event.preventDefault()
      return
    }
    if (typeof onClick === "function") {
      onClick(event)
    }
  }

  return (
    <Action
      fullWidth={fullWidth}
      doublePadding={doublePadding}
      disabled={disabled}
      onClick={clickHandler}
      className={classes}
      {...otherProperties}
    >
      {children}
    </Action>
  )
}

SimpleAction.variants = variants
SimpleAction.sizes = sizes

export default SimpleAction
