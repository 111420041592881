import React from "react"
import {TranslateStateType} from "./types"

const Context = React.createContext<TranslateStateType>({
  locale: "it",
  dictionary: {},
  setLocale: () => {}
})

export default Context
