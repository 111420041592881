/* eslint-disable space-before-function-paren */
import {AxiosError} from "axios"
import {Formik, FormikHelpers, FormikProps} from "formik"
import React from "react"
import * as Yup from "yup"
import useTranslation from "../../hooks/useTranslation"
import {TranslateFunctionType} from "../../types"
import {AccessUserType} from "../../types/accessUser"
import {RequestFunction} from "../../types/requestFunction"
import {getErrorText} from "../../utils"
import SimpleAction from "../action"
import HeaderLoginSignup from "../header-login-signup"
import TextInput from "../input"
import NegativeFeedback from "../negative-feedback"
import Translate from "../translate"
import {ActionButton, Container, Description, Title} from "./style"
import {
  candidatePlatformUrl,
  corporatePlatformUrl,
  PASSWORD_REGEX
} from "../../constants/global"
import {homepageKey} from "../../constants/pathKeys"

const passwordId = "password"
const confirmPasswordId = "confirmPassword"

const initialValues = {
  [passwordId]: "",
  [confirmPasswordId]: ""
}

interface ResetPasswordValuesType {
  [passwordId]: string | undefined
  [confirmPasswordId]: string | undefined
}

const getValidationSchema = (
  translate: TranslateFunctionType
): Yup.AnySchema<ResetPasswordValuesType> =>
  Yup.object().shape({
    [passwordId]: Yup.string()
      .ensure()
      .trim()
      .matches(PASSWORD_REGEX, translate("global-error-password-validation"))
      .required(translate("global-error-field-mandatory")),
    [confirmPasswordId]: Yup.string().oneOf(
      [Yup.ref(passwordId)],
      translate("form-reset-confirm-password-matching-error")
    )
  })
interface PropsType {
  userType: AccessUserType
  changePasswordFunction: RequestFunction
  logoUrl?: string
  backgroundUrl?: string
  userId: string
  resetPasswordToken: string
}

const AccessResetPassword: React.FC<PropsType> = ({
  userType,
  changePasswordFunction,
  logoUrl,
  backgroundUrl,
  userId,
  resetPasswordToken
}: PropsType) => {
  const [translate] = useTranslation()
  const [errorText, setErrorText] = React.useState<string>("")

  const [currentValues, setValues] = React.useState<ResetPasswordValuesType>(
    initialValues
  )

  const submitHandler = async (
    values: ResetPasswordValuesType,
    {setSubmitting}: FormikHelpers<ResetPasswordValuesType>
  ) => {
    // reset error on every submit
    setErrorText("")

    const tenant = localStorage.getItem("tenant") || "employee"

    interface ChangePasswordRequest {
      userId: string
      token: string
      password: string
      confirmPassword: string
    }

    const requestData: ChangePasswordRequest = {
      userId,
      token: resetPasswordToken,
      password: values[passwordId] as string,
      confirmPassword: values[confirmPasswordId] as string
    }

    changePasswordFunction<ChangePasswordRequest, any>(
      requestData,
      () => {
        
        // if (userType === AccessUserType.Corporate) {
          window.location.href = window.location.href.replace(/\/[^\/]*$/, '/login')
        // } else {
        //   window.location.href = candidatePlatformUrl
        // }
      },
      (e: AxiosError) => {
        setSubmitting(false)

        const errorText = getErrorText(e)
        setErrorText(errorText || translate("global-generic-error"))
      }
    )
  }

  return (
    <Container userType={userType} backgroundUrl={backgroundUrl}>
      <HeaderLoginSignup logoUrl={logoUrl} logoLinkKey={homepageKey} />
      <div className="row">
        <div className="column mobile-12 desktop-6">
          <div className="row">
            <div className="column mobile-12 desktop-8">
              <Title>
                <Translate label={"form-reset-password-title"} />
              </Title>

              <Description>
                <Translate label={"form-reset-password-description"} />
              </Description>

              <ResetPasswordForm
                userType={userType}
                initialValues={currentValues}
                validationSchema={getValidationSchema(translate)}
                submitHandler={submitHandler}
                onChange={setValues}
                errorText={errorText}
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

interface FormPropsType {
  userType: AccessUserType
  initialValues: ResetPasswordValuesType
  validationSchema: Yup.AnySchema<ResetPasswordValuesType>
  submitHandler: (
    values: ResetPasswordValuesType,
    {setSubmitting}: FormikHelpers<ResetPasswordValuesType>
  ) => Promise<void>
  onChange?: (values: ResetPasswordValuesType) => void
  errorText: string
}

const getFormSubmitability = (values: ResetPasswordValuesType): boolean =>
  !!(values[passwordId] && values[confirmPasswordId])

const ResetPasswordForm: React.FC<FormPropsType> = ({
  userType,
  initialValues,
  validationSchema,
  submitHandler,
  onChange = () => {},
  errorText
}: FormPropsType) => (
  <Formik
    validationSchema={validationSchema}
    initialValues={initialValues}
    onSubmit={submitHandler}
  >
    {({
      handleSubmit,
      handleChange,
      values,
      submitCount,
      isSubmitting,
      errors
    }: FormikProps<ResetPasswordValuesType>) => {
      const hasAlreadySubmitted = submitCount > 0
      const isFormSubmitable = getFormSubmitability(values)
      // Everytime the form re-render with new values we notify the interested parent
      onChange(values)

      return (
        <form onSubmit={handleSubmit} noValidate>
          {errorText && (
            <NegativeFeedback id="reset-password-form-negative-feedback">
              {errorText}
            </NegativeFeedback>
          )}

          <TextInput
            type="password"
            minLength={8}
            id={passwordId}
            value={values[passwordId]}
            error={hasAlreadySubmitted && errors[passwordId]}
            placeholder="form-reset-password-label"
            onChange={handleChange}
          />

          <TextInput
            type="password"
            minLength={8}
            id={confirmPasswordId}
            value={values[confirmPasswordId]}
            error={hasAlreadySubmitted && errors[confirmPasswordId]}
            placeholder="form-reset-confirm-password-label"
            onChange={handleChange}
          />

          <ActionButton
            disabled={isSubmitting || !isFormSubmitable}
            className={"button-unstyled full-width"}
            type="submit"
          >
            <SimpleAction
              disabled={isSubmitting || !isFormSubmitable}
              fullWidth
              size={SimpleAction.sizes.large}
              variant={
                userType === AccessUserType.Candidate
                  ? SimpleAction.variants.blueGlickonNoHover
                  : userType === AccessUserType.Employee
                  ? SimpleAction.variants.lightblueGlickon
                  : SimpleAction.variants.dark
              }
            >
              <Translate label={"form-reset-confirm-password-cta"} />
            </SimpleAction>
          </ActionButton>
        </form>
      )
    }}
  </Formik>
)

export default AccessResetPassword
