import styled from "styled-components"
import styleConstants from "../../../../constants/styleConstants"
import {raisable} from "../../../../mixins"

// eslint-disable-next-line no-unexpected-multiline
export const CompanyChallengeItemCard = styled.div<{
  customChallengeColor: string | undefined
  customBorderColor: string | undefined
  topColor: string | undefined
}>`
  cursor: pointer;
  position: relative;
  height: 12.3125rem;
  padding: 1.125rem;
  background-color: ${({customChallengeColor}) =>
    customChallengeColor || styleConstants.white};
  border-radius: ${styleConstants.defaultBorderRadius};
  overflow: hidden;
  border: 0.0625rem solid
    ${({customBorderColor}) => customBorderColor || styleConstants.cardBorder};
  margin-top: 1rem;
  margin-bottom: 1rem;

  &:before {
    content: "";
    background-color: ${({topColor}) => topColor};
    height: 0.5rem;
    left: 0;
    top: 0;
    right: 0;
    position: absolute;
  }

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    height: 15.625rem;
    padding: 1.5rem 1.75rem;
  }

  ${raisable}
`

// eslint-disable-next-line no-unexpected-multiline
export const CompanyChallengeItemTitle = styled.div<{
  customColor: string | undefined
}>`
  color: ${({customColor}) => customColor || styleConstants.blue00};
  font-size: ${styleConstants.font.size20};
  font-family: ${styleConstants.galanoGrotesqueSemibold};
  width: 100%;
  line-height: 1.15;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    padding-top: 0.5rem;
  }
`
// eslint-disable-next-line no-unexpected-multiline
export const CompanyChallengeItemDescription = styled.div<{
  customColor: string | undefined
}>`
  padding-top: 1rem;
  color: ${({customColor}) => customColor || styleConstants.dark01};
  font-family: ${styleConstants.calibre};
  font-size: ${styleConstants.font.size18};

  p {
    margin-top: 0;
  }

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    font-size: ${styleConstants.font.size16};
  }
`

// eslint-disable-next-line no-unexpected-multiline
export const CompanyChallengeItemTimeAndQuestions = styled.div<{
  customColor: string | undefined
}>`
  color: ${({customColor}) => customColor || styleConstants.dark02};
  font-size: ${styleConstants.font.size14};
  font-family: ${styleConstants.calibre};
  padding-top: 0.5rem;
  position: absolute;
  bottom: 1.5rem;
  left: 1.5rem;
  display: flex;
  align-items: center;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    font-size: ${styleConstants.font.size16};
    left: 1.5rem;
  }
`

export const CompanyChallengeItemIcon = styled.i`
  font-size: ${styleConstants.font.size16};
`
