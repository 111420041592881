import React from "react"
import useIsBelowScroll from "../../hooks/useIsBelowScroll"

interface PropsType extends React.HTMLProps<HTMLElement> {
  triggerOnce?: boolean
  effect?: string
  renderAs?: React.ComponentType<any> | string
  animationClass?: string
  offset?: number
  children: React.ReactNode
}

const AnimateOnScroll: React.FC<PropsType> = (props: PropsType) => {
  const {
    children,
    effect = "",
    offset = 0,
    animationClass = "animated",
    renderAs = "div",
    triggerOnce = true,
    ...otherProps
  } = props

  const [ref, isBelowScroll] = useIsBelowScroll(offset)

  if (ref.current) {
    if (isBelowScroll) {
      ref.current.classList.add(animationClass)
    } else if (!triggerOnce) {
      ref.current.classList.remove(animationClass)
    }
  }

  return React.createElement(
    renderAs,
    {
      ref,
      "data-effect": effect,
      ...otherProps
    },
    children
  )
}

export default AnimateOnScroll
