export const notFoundKey = "not-found"
export const errorKey = "error"
export const loginKey = "login"
export const twoFactorAuthenticationSetPhoneNumberKey = "tfa-set-phone-number"
export const twoFactorAuthenticationInsertCodeKeySMS = "tfa-insert-code-sms"
export const twoFactorAuthenticationInsertCodeKeyEmail = "tfa-insert-code-email"
export const signupKey = "signup"
export const candidatePlatformKey = "candidate-platform"
export const candidatePlatformSignupKey = "candidate-platform-signup"
export const candidatePlatformResetPasswordKey =
  "candidate-platform-reset-password"
export const candidatePlatformCompanyKey = "candidate-platform-company"
export const candidatePlatformCompanySignupKey =
  "candidate-platform-company-signup"
export const candidatePlatformCompanyResetPasswordKey =
  "candidate-platform-company-reset-password"
export const employeePlatformKey = "employee-platform"
export const employeePlatformSignupKey = "employee-platform-signup"
export const employeePlatformCompanyKey = "employee-platform-company"
export const employeePlatformCompanySignupKey =
  "employee-platform-company-signup"
export const employeePlatformCompanyResetPasswordKey =
  "employee-platform-company-reset-password"
export const homepageKey = "homepage"
export const requestADemoKey = "request-a-demo"
export const aDayWithPageKey = "adaywith"
export const candidateExperienceJourneyKey = "adaywith-candidate"
export const employeeExperienceJourneyKey = "adaywith-employee"
export const HRJourneyKey = "adaywith-hr"

export const productsKey = "products"
export const candidateExperienceKey = "candidate-experience"
export const employeeExperienceKey = "employee-experience"
export const peopleAnalyticsKey = "people-analytics"
export const liveKey = "live"
export const blogKey = "blog"
export const mediumBlogKey = "medium"

export const aboutUsKey = "about-us"
export const teamKey = "team"
export const jobKey = "job"
export const mailKey = "mail"
export const contactUsMailKey = "contactUsMail"
export const eventMailKey = "eventMail"
export const oneToOneMailKey = "oneToOneMail"
export const demoMailKey = "demoMail"

// platform URLS
export const platformTermsAndConditionsKey = "platform-terms-and-conditions"
export const platformCXTermsAndConditionsKey = "platform-terms-and-conditions-cx"
export const platformEXTermsAndConditionsKey = "platform-terms-and-conditions-ex"
export const platformPrivacyKey = "privacy"
export const platformCookieKey = "cookie"
export const platformSecurityKey = "security"

// document URLS
export const documentGeneralTermsAndConditionsKey = "general-terms-and-conditions"
export const documentMSTeamsTermsAndConditionsKey = "msteams-terms-and-conditions"
export const documentTermsAndConditionsKey = "document-terms-and-conditions"
export const termsOfServicesKey = "terms-of-services"
export const liveTermsAndConditionsKey = "live-terms-and-conditions"
export const livePrivacyKey = "live-privacy"
export const slaveryPreventionKey = "slavery-prevention-key"

export const resourcesKey = "resources"
export const resourcesEmployeeExperienceKey = "resources-employee-experience"
export const resourcesCandidateExperienceKey = "resources-candidate-experience"
export const resourcesPeopleAnalyticsKey = "resources-people-analytics"

export const caseStudiesKey = "case-studies"
export const caseStudyKey = "case-study"

export const linkedinKey = "social-linkedin"
export const twitterKey = "social-twitter"
export const facebookKey = "social-facebook"
export const mediumKey = "social-medium"
export const youtubeKey = "social-youtube"

export const landingAwardKey = "landing-award"

export const companyChallengeKey = "company-challenge"
export const companyKey = "company"
export const companySectionKey = "company-section"
export const contactPageKey = "contact-us"
