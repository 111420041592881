import { TranslationType } from "../types"

const translations: TranslationType = {
  "slavery-c-title": {
    en: "Glickon Declaration on the Prevention of Slavery and Human Trafficking",
    it: "Dichiarazione di Glickon sulla prevenzione della schiavitù e del traffico di esseri umani"
  },
  "slavery-c-pre-list-text": {
    en: `
      This statement, which draws on the provisions of Ch. 54 of the UK's Modern Slavery Act 2015, enshrines Glickon Ltd's ongoing commitment to recognizing all potential risks of modern slavery related to its business and implementing measures to reduce that risk.
    `,
    it: `
            Questa dichiarazione, che si rifà al disposto del cap. 54 della legge britannica contro la schiavitù moderna
            (Modern Slavery Act) del 2015, sancisce il costante impegno di Glickon S.p.A nel riconoscere tutti i potenziali
            <b>rischi</b> della schiavitù moderna correlati alla propria attività e nel mettere in atto misure volte a ridurre
            tale rischio.
        `
  },
  "slavery-c-1": {
    en: "Our activity",
    it: "La nostra attività"
  },
  "slavery-c-1-1": {
    en: `
      Glickon's mission is to liberate the potential of the modern workforce. We strive every day to equip the world with products, solutions and services that enable everyone to do their best work - anytime, anywhere, and everywhere.
    `,
    it: `
            La mission di Glickon è liberare il potenziale della forza lavoro moderna. Ci impegniamo ogni giorno per dotare il mondo
            di prodotti, soluzioni e servizi che consentano a chiunque di svolgere al meglio il proprio lavoro - sempre, ovunque e comunque.
        `
  },
  "slavery-c-2": {
    en: "Our commitment to human rights",
    it: "Il nostro impegno nei confronti dei diritti umani"
  },
  "slavery-c-2-1": {
    en: `
      Modern slavery and human trafficking can occur in many forms, from forced labour to child labour, domestic slavery, sex trafficking and all related forms of workplace abuse. In this Statement, we use the term "modern slavery and human trafficking" to include any form of slavery, servitude and forced or compulsory labour and human trafficking. Glickon is committed to maintaining and improving systems and procedures to prevent all forms of human rights violations in our operations. Glickon's Code of Ethics, applicable to all employees, defines our commitment to human rights. It prohibits any form of forced, involuntary or child labour.
    `,
    it: `
            Schiavitù moderna e tratta di esseri umani possono verificarsi in molte forme, dal lavoro forzato al lavoro minorile,
            schiavitù domestica, traffico a scopo sessuale e tutte le relative forme di abuso sul luogo di lavoro.
            In questa Dichiarazione utilizziamo l’espressione “schiavitù moderna e tratta di esseri umani” per includere qualsiasi
            forma di schiavitù, asservimento e lavoro forzato od obbligatorio e la tratta di esseri umani.
            Glickon è impegnata a mantenere e migliorare sistemi e procedure atte a prevenire qualsiasi forma di violazione dei diritti
            umani nelle nostre operazioni. Il Codice Etico di Glickon, applicabile a tutti i dipendenti, definisce il nostro impegno
            nei confronti dei diritti umani. Vieta assolutamente qualsiasi forma di lavoro forzato, non volontario o minorile.
        `
  },
  "slavery-c-3": {
    en: "Relations with Suppliers",
    it: "Rapporti con i fornitori"
  },
  "slavery-c-3-1": {
    en: `
      We are committed to ensuring the absence of any form of slavery or human trafficking in any part of our business. As a software development company, given the nature of our business and approach to governance, in our opinion, the risk of slavery and human trafficking is very low. Glickon does not use local or international suppliers that would pose a real risk of modern slavery and human trafficking. Our Modern Slavery Statement enshrines our commitment to act following principles of ethics and integrity in all our business dealings and to implement and enforce effective systems and controls to ensure that there is no modern slavery and human trafficking. However, we aim to monitor the effectiveness of the relevant guidelines and procedures we have in place. We do not have any general performance indicators concerning slavery or human trafficking as each case is expected to be a violation of the law, our supplier standards, or our internal directives.
    `,
    it: `
            Siamo impegnati a garantire l'assenza di qualunque forma di schiavitù o traffico di esseri umani in ogni parte della
            nostra attività. Essendo un'azienda di sviluppo software, data la natura della nostra attività e l'approccio alla
            governance, secondo noi il rischio di schiavitù e di traffico di esseri umani è molto basso. Glickon non si avvale di
            fornitori locali o internazionali che rappresenterebbero un rischio reale di schiavitù moderna e di traffico di esseri
            umani. La nostra dichiarazione sulla schiavitù moderna sancisce il nostro impegno ad agire secondo principi di etica e
            integrità in tutti i nostri rapporti commerciali e a implementare e applicare sistemi e controlli efficaci al fine di
            garantire che non vi sia alcuna forma di schiavitù moderna e di traffico di esseri umani. Tuttavia, ci proponiamo di
            verificare l'efficacia delle relative direttive e procedure che abbiamo in essere. Non abbiamo indicatori prestazionali
            generali rispetto alla schiavitù o al traffico di essere umani in quanto si prevede che ogni caso costituisca una
            violazione delle norme di legge, dei nostri standard per i fornitori o delle nostre direttive interne.
        `
  },
  "slavery-c-4": {
    en: "Glickon Directives and Training",
    it: "Direttive e formazione di Glickon"
  },
  "slavery-c-4-1": {
    en: `
      Glickon's Code of Ethics sets forth the principles of conduct to be adhered to by all those working for or on behalf of Glickon and is reviewed and updated periodically to ensure a high level of awareness of the risks of modern slavery and human trafficking in our business.
    `,
    it: `
            Il Codice Etico di Glickon sancisce i principi di condotta che devono rispettare tutti coloro che lavorano per o per
            conto di Glickon e viene rivisto e aggiornato periodicamente allo scopo di assicurare un elevato livello di conoscenza dei
            rischi di schiavitù moderna e di traffico di esseri umani nella nostra attività.
        `
  },
  "slavery-c-4-2": {
    en: `
      Glickon is committed to ensuring a safe and non-violent work environment. Therefore, we have adopted a zero-tolerance policy towards violence, threats of violence or any other unlawful activity in the workplace. Glickon has mechanisms in place through which its employees can ask questions or raise concerns about possible violations of the Code, internal policies, and legal requirements, including any questions or concerns related to slavery or human trafficking. Our Code of Ethics outlines the procedures and contact information for raising such concerns and clearly states that retaliation will not be tolerated.
    `,
    it: `
            Glickon è impegnata ad assicurare un ambiente di lavoro sicuro e non violento. Pertanto, abbiamo adottato una politica di
            tolleranza zero verso la violenza, le minacce di violenza o qualunque altra attività illecita sul posto di lavoro.
            Glickon ha attivato dei meccanismi attraverso i quali i suoi dipendenti possono porre domande o segnalare dubbi su
            possibili violazioni del Codice, delle direttive interne e delle norme di legge, inclusi eventuali domande o dubbi
            relativamente alla schiavitù o al traffico di esseri umani. Il nostro Codice Etico definisce le procedure e i referenti
            cui rivolgersi per sollevare tali questioni e prescrive chiaramente che la ritorsione non sarà tollerata.
        `
  }
}

export default translations
