import {
  createStore,
  Action,
  action,
  createTypedHooks,
  Computed,
  computed
} from "easy-peasy"
import {homepageKey} from "../constants/pathKeys"

export interface AuthState {
  logged: boolean
  platform: "" | "admin" | "candidate" | "corporate"
  scope: "" | "candidate" | "employee"
  owner: string
  firstName: string
  lastName: string
}

export interface AuthStateModel extends AuthState {
  update: Action<AuthStateModel, Partial<AuthState>>
  isAdmin: Computed<AuthStateModel, boolean>
  isCandidate: Computed<AuthStateModel, boolean>
  isCorporate: Computed<AuthStateModel, boolean>
}

export interface RoutingState {
  currentPathKey: string
}

export interface RoutingStateModel extends RoutingState {
  update: Action<RoutingStateModel, Partial<RoutingState>>
}

export interface StoreModel {
  auth: AuthStateModel
  routing: RoutingStateModel
}

const authStateModel: AuthStateModel = {
  logged: false,
  platform: "",
  scope: "",
  owner: "",
  firstName: "",
  lastName: "",

  isAdmin: computed(({logged, platform}) => logged && platform === "admin"),

  isCorporate: computed(
    ({logged, platform}) => logged && platform === "corporate"
  ),

  isCandidate: computed(
    ({logged, platform}) => logged && platform === "candidate"
  ),

  update: action((state, payload) => {
    Object.assign(state, payload)
  })
}

const routingStateModel: RoutingStateModel = {
  currentPathKey: homepageKey,

  update: action((state, payload) => {
    Object.assign(state, payload)
  })
}

const storeModel: StoreModel = {
  auth: authStateModel,
  routing: routingStateModel
}

const typedHooks = createTypedHooks<StoreModel>()

export const store = createStore(storeModel)
export const useStoreActions = typedHooks.useStoreActions
export const useStoreData = typedHooks.useStoreState
