import React from "react"
import {Facebook, Linkedin, Gmail, Wrapper} from "./styles"
import Link from "../link"
import Translate from "../translate"
import {appendToQueryString, getParameterByName, getImageUrl} from "../../utils"
import {socialLoginAfterTCConfirmationNextUrlStorageKey} from "../../constants/global"

interface PropsType {
  urlSuffix: string | undefined
  directionColumn?: boolean
  isLogin?: boolean
  isSignup?: boolean
  ssoCOnfig?: any
}

const SocialLogin: React.FC<PropsType> = ({
  urlSuffix,
  directionColumn,
  isLogin,
  ssoCOnfig
}: PropsType) => {
  React.useEffect(prepareStorageNextUrl(urlSuffix), [urlSuffix])
  const isLargeButton = !!directionColumn

  const getSSOLink = (
    sso_type: "Facebook" | "Linkedin" | "Google" | "MSGraph"
  ) => {
    if (ssoCOnfig) {
      localStorage.setItem("tenant", "candidate")
      const config = ssoCOnfig.find(
        (config: any) => config.provider === sso_type
      )
      if (config) {
        let finalUrl = `${config.base_url}?`
        const params = Object.keys(config.query_pars)
        params.forEach((param, index) => {
            finalUrl = `${finalUrl}${param}=${config.query_pars[param]}${
            index === params.length - 1 ? "" : "&"
            }`
        })
        finalUrl = finalUrl.replaceAll("#", "%23")
        return encodeURI(finalUrl).toString()
        } else {
            return ""
        }
    } else {
      return ""
    }
  }

  return (
    // @ts-ignore
    <Wrapper
      directionColumn={!!directionColumn}
      // @ts-ignore
      className={!directionColumn && "row"}
    >
      <div className="column auto link-wrapper">
        <Link
          className="gmail-access-link"
          to={getSSOLink("Google")}
          target="_blank"
        >
          <Gmail noMargin={!!directionColumn} large={isLargeButton}>
            <img src={getImageUrl("logos/google.png")} alt="gmail" />
            {isLargeButton && (
              <Translate
                label={isLogin ? "login-with-google" : "signup-with-google"}
              />
            )}
          </Gmail>
        </Link>
      </div>
      <div className="column auto link-wrapper">
        <Link
          className="linkedin-access-link"
          to={getSSOLink("Linkedin")}
          target="_self"
        >
          <Linkedin noMargin={!!directionColumn} large={isLargeButton}>
            <i className="icon-linkedin-negative" />
            {isLargeButton && (
              <Translate
                label={isLogin ? "login-with-linkedin" : "signup-with-linkedin"}
              />
            )}
          </Linkedin>
        </Link>
      </div>
      <div className="column auto link-wrapper">
        <Link
          className="facebook-access-link"
          to={getSSOLink("Facebook")}
          target="_self"
        >
          <Facebook noMargin={!!directionColumn} large={isLargeButton}>
            <img src={getImageUrl("logos/facebook.png")} alt="facebook" />
            {isLargeButton && (
              <Translate
                label={isLogin ? "login-with-facebook" : "signup-with-facebook"}
              />
            )}
          </Facebook>
        </Link>
      </div>
    </Wrapper>
  )
}

export default SocialLogin

function prepareStorageNextUrl(
  urlSuffix: string | undefined
): React.EffectCallback {
  return () => {
    const rawSocialLoginAfterTCConfirmationNextUrl = urlSuffix
      ? getParameterByName("next_url", `?${urlSuffix.replaceAll("//", "/")}`)
      : ""

    const socialLoginAfterTCConfirmationNextUrl =
      rawSocialLoginAfterTCConfirmationNextUrl &&
      `/${rawSocialLoginAfterTCConfirmationNextUrl.replace("--", "/")}`

    try {
      window.localStorage.setItem(
        socialLoginAfterTCConfirmationNextUrlStorageKey,
        socialLoginAfterTCConfirmationNextUrl
      )
    } catch {}
  }
}
