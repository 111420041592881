import styled from "styled-components"
import styleConstants from "../../constants/styleConstants"
import { getImageUrl } from "../../utils"

const placeholderStyle = `
    color: ${styleConstants.dark02};
    opacity: 1;
`

export const StyledFormTitle = styled.h2`
  margin: 0;
  text-transform: uppercase;
  font-weight: normal;
  font-family: ${styleConstants.calibreSemibold};
  font-size: ${styleConstants.font.size14};
  color: ${styleConstants.blue00};
  padding-bottom: 0.5rem;
`

export const StyledCheckboxLabel = styled.span`
  width: calc(100% - 1.5rem);
  font-size: ${styleConstants.font.size14};
  color: ${styleConstants.dark01};
  margin-left: auto;
  cursor: pointer;
`

export const StyledCheckboxInput = styled.input`
  outline: none;
  border: none;
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    display: block;
    margin-top: -0.5rem;
    width: 1rem;
    height: 1rem;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url('${getImageUrl("common/checkbox-unchecked.png")}');
    border-radius: 4px;
  }

  &:checked {
    &:after {
      background-image: url('${getImageUrl("common/checkbox-checked.png")}');
    }
  }
  
  &.error {
    + ${StyledCheckboxLabel} {
      color: ${styleConstants.badFeedback}
    }
  }
`

export const StyledCheckbox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  position: relative;
`

export const StyledInput = styled.input`
  width: 100%;
  font-family: ${styleConstants.calibre};
  font-size: ${styleConstants.font.size20};
  background-color: ${styleConstants.white};
  height: 3rem;
  border-radius: 0.1875rem;
  padding: 0 1rem;
  border: 0.0625rem solid ${styleConstants.cardBorder};
  margin-bottom: 1rem;
  outline: none;
  color: ${styleConstants.dark01};

  &[disabled] {
    color: ${styleConstants.cardBorder};
    cursor: not-allowed;
  }

  &.readonly {
    cursor: not-allowed;
    background-color: ${styleConstants.dark04};
  }

  &::-ms-clear {
    display: none;
  }

  &::-webkit-input-placeholder {
    ${placeholderStyle};
  }

  &::-moz-placeholder {
    ${placeholderStyle};
  }

  &:-ms-input-placeholder {
    ${placeholderStyle};
  }

  &.error {
    padding: 0 calc(1rem - 0.0625rem);
    border: 0.125rem solid ${styleConstants.badFeedback};
  }
`

export const StyledTextarea = styled.textarea`
  resize: vertical;
  width: 100%;
  min-height: 6.5rem;
  font-family: ${styleConstants.calibre};
  font-size: ${styleConstants.font.size20};
  background-color: ${styleConstants.white};
  border-radius: 0.1875rem;
  padding: 1rem;
  border: 0.0625rem solid ${styleConstants.cardBorder};
  margin-bottom: 1rem;
  outline: none;
  color: ${styleConstants.dark01};

  &[disabled] {
    cursor: not-allowed;
    color: ${styleConstants.cardBorder};
  }

  &::-ms-clear {
    display: none;
  }

  &::-webkit-input-placeholder {
    ${placeholderStyle};
  }

  &::-moz-placeholder {
    ${placeholderStyle};
  }

  &:-ms-input-placeholder {
    ${placeholderStyle};
  }

  &.error {
    padding: 1rem calc(1rem - 0.0625rem);
    border: 0.125rem solid ${styleConstants.badFeedback};
  }
`

export const StyledLabel = styled.label`
  cursor: pointer;
  text-transform: uppercase;
  font-family: ${styleConstants.calibreSemibold};
  font-size: ${styleConstants.font.size14};
  color: ${styleConstants.blue00};
  padding-bottom: 0.5rem;
`

export const AboveInputWrapper = styled.label`
  display: flex;
`
export const BellowInputWrapper = styled.div``

interface StyledErrorFeedbackPropsType {
  left?: boolean
}

export const StyledErrorFeedback = styled.div<StyledErrorFeedbackPropsType>`
  padding-bottom: 0.5rem;
  margin-left: ${props => (props.left ? 0 : "auto")};
  font-family: ${styleConstants.calibre};
  font-size: ${styleConstants.font.size14};
  color: ${styleConstants.badFeedback};
`
