import { AxiosError, AxiosResponse } from "axios"
import humps from "humps"
import { getAxiosInstance } from "../utils"

export async function confirmResetPassword<T extends object, R>(
  data: T,
  successFn: (res: AxiosResponse<R>) => void,
  errorFn: (err: AxiosError) => void
) {
    let tenant
    if (
        window.location.href.includes("employees") ||
        window.location.href.includes("dipendenti")
      ) {
        tenant = "employee"
      } else {
        tenant = "candidate"
      }
      localStorage.setItem("tenant", tenant)
  try {
    const values: any = humps.decamelizeKeys(data)
    const response: AxiosResponse<any> = await getAxiosInstance().patch(
      `${process.env.REACT_APP_API_URL}/api/identity/v3/${tenant}/user`,
      values
    )
    successFn(humps.camelizeKeys(response) as AxiosResponse<R>)
  } catch (error) {
    errorFn(error as unknown as AxiosError)
  }
}
