import React from "react"
import localeState from "../localeContext"
import {Dictionary, TranslateStateType, TranslateFunctionType} from "../types"

type HookReturnType = [TranslateFunctionType, string, (locale: string) => void]

const useTranslation = (): HookReturnType => {
  const {locale, dictionary, setLocale} = React.useContext<TranslateStateType>(
    localeState
  )

  const translate = React.useCallback(
    (label: string, replacements: Dictionary<string | number> = {}): string => {
      try {
        let translated = dictionary[label][locale] || label // if translation is "" use the label instead
        Object.entries(replacements).forEach(
          ([key, value]: [string, string | number]) => {
            translated = translated.replace(
              new RegExp(`{{${key}}}`, "g"),
              `${value}`
            )
          }
        )
        return translated.trim()
      } catch (e) {
        return label
      }
    },
    [dictionary, locale]
  )

  return [translate, locale, setLocale]
}

export default useTranslation
