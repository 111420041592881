import React from "react"
import {Dictionary} from "../../../../types"
import {isMobileCheck, getCandidateImageUrl} from "../../../../utils"
import {
  CompanyChallengeShareIcon as Icon,
  CompanyChallengeShareLink as Link,
  CompanyChallengeShareList as List
} from "./style"

const providersNames = [
  "linkedin",
  "facebook",
  "twitter",
  "whatsapp",
  "telegram",
  "email"
]

const popupSizeMap: Dictionary<{width: number; height: number}> = {
  linkedin: {
    width: 760,
    height: 650
  },
  facebook: {
    width: 558,
    height: 760
  },
  twitter: {
    width: 670,
    height: 253
  },
  whatsapp: {
    width: 558,
    height: 350
  },
  telegram: {
    width: 558,
    height: 360
  },
  email: {
    width: 0,
    height: 0
  }
}

interface Props {
  id: string
  className: string
  challengeName: string
  companyName: string
  hash: string
  size?: number
}

const CompanyChalengeShareLinks: React.FC<Props> = ({
  id,
  className,
  challengeName,
  companyName,
  hash,
  size = 2
}: Props) => {
  const title = `${companyName} - ${challengeName}`

  const baseUrlMap: Dictionary<string> = {
    linkedin: "https://www.linkedin.com/shareArticle?mini=true&url=",
    facebook: "https://www.facebook.com/sharer.php?u=",
    twitter: `https://twitter.com/intent/tweet?hashtags=glickon&text=${title}&url=`,
    whatsapp: "https://api.whatsapp.com/send?text=",
    telegram: "https://t.me/share/url?url=",
    email: `mailto:?subject=${title}&body=`
  }

  const isMobile = isMobileCheck()

  const linkTargetMap: Dictionary<string> = {
    linkedin: isMobile ? "_self" : "_blank",
    facebook: "_blank",
    twitter: "_blank",
    whatsapp: isMobile ? "_self" : "_blank",
    telegram: isMobile ? "_self" : "_blank",
    email: "_self"
  }

  const linkUrl = `${process.env.REACT_APP_WWW_URL}/candidate/company-challenges/${hash}`

  const openSharePopup = (providerName: string) => {
    // Fixes dual-screen position - Most browsers use screenLeft/screenTop, Firefox use screenX/screenY
    const dualScreenLeft: number = window.screenLeft
      ? window.screenLeft
      : window.screenX

    const dualScreenTop: number = window.screenTop
      ? window.screenTop
      : window.screenY

    // documentElement could be undefined if the code is running in no browser container (ie: Node)
    const screenWidth: number = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : window.screen.width

    const screenHeight: number = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : window.screen.height

    const systemZoom: number = screenWidth / window.screen.availWidth

    const width = popupSizeMap[providerName].width
    const height = popupSizeMap[providerName].height

    const left: number = (screenWidth - width) / 2 / systemZoom + dualScreenLeft

    const top: number = (screenHeight - height) / 2 / systemZoom + dualScreenTop

    const popup = window.open(
      baseUrlMap[providerName] + linkUrl,
      linkTargetMap[providerName],
      `width=${width / systemZoom},height=${height /
        systemZoom},top=${top},left=${left},scrollbars=yes`
    )

    // Because noopener wont work in some browsers
    popup && (popup.opener = null)

    // Puts focus on the new window
    popup && popup.focus && popup.focus()
  }

  return (
    <List>
      {providersNames.map(providerName => (
        <Link
          id={`${id}-${providerName}`}
          key={`${id}-${providerName}`}
          className={className}
          onClick={() => {
            openSharePopup(providerName)
          }}
        >
          <Icon
            localize={false}
            size={size}
            src={getCandidateImageUrl(`share/icn-share-${providerName}.png`)}
          />
        </Link>
      ))}
    </List>
  )
}

export default CompanyChalengeShareLinks
