import styled from "styled-components"
import styleConstants from "../../constants/styleConstants"
import {hexToRgb} from "../../utils"

// eslint-disable-next-line no-unexpected-multiline
export const ExternalPageShellWrapper = styled.div<{
  customBodyBackgroundColor: string | undefined
}>`
  min-height: 100vh; /*fallback*/
  min-height: calc((var(--realVh, 1vh) * 100));
  display: flex;
  flex-direction: column;
  background-color: ${({customBodyBackgroundColor}) =>
    customBodyBackgroundColor || styleConstants.dark04};
`

export const ExternalPageFooter = styled.div<{customColor?: string}>`
  flex-shrink: 0;
  background-color: ${({customColor}) => customColor || styleConstants.glickonBlack};
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.5rem;
`
export const ExternalPageFooterButton = styled.a<{customColor?: string}>`
  display: block;
  text-decoration: none;
  height: 2.25rem;
  padding: 0.375rem 1.5rem;
  border-radius: 0.2rem;
  background-color: ${({customColor}) => customColor || "#3b5263"};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ExternalPageFooterText = styled.div<{bold?: boolean}>`
  font-family: ${styleConstants.calibre};
  font-size: 1rem;
  font-weight: ${({bold}) => (bold ? 600 : "normal")};
  color: #ffffff;
`

// eslint-disable-next-line no-unexpected-multiline
export const ExternalPageContentWrapper = styled.div<{
  afterTopbar: boolean
  customBodyBackgroundColor: string | undefined
}>`
  background-color: ${({customBodyBackgroundColor}) =>
    customBodyBackgroundColor || styleConstants.blue02};
  position: relative;
  flex: 1 0 auto;
  padding-top: ${({afterTopbar}) =>
    afterTopbar ? styleConstants.externalPageTopBarMobileHeight : 0};

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: ${({afterTopbar}) =>
      afterTopbar ? styleConstants.externalPageTopBarMobileHeight : 0};
  }

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    padding-top: ${({afterTopbar}) =>
      afterTopbar ? styleConstants.topBarHeight : 0};

    &:before {
      height: ${({afterTopbar}) =>
        afterTopbar ? styleConstants.topBarHeight : 0};
    }
  }
`

export const ExternalPageHeaderBackground = styled.div`
  position: relative;
  height: 12rem;
  @media (min-width: ${styleConstants.desktopMinWidth}) {
    height: 18rem;
  }

  .swiper-container {
    height: 100%;
  }
  
  .swiper-wrapper {
    height: 100%;
  }
`

// eslint-disable-next-line no-unexpected-multiline
export const ExternalPageHeaderSlide = styled.div<{
  url: string
}>`
  background-size: cover;
  background-position: center;
  background-image: url(${({url}) => url});

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(
      to bottom,
      rgba(${hexToRgb(styleConstants.black)}, 0),
      rgba(${hexToRgb(styleConstants.black)}, 0.7)
    );
`
