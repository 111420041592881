import { TranslationType } from "../types"

const translations: TranslationType = {
  // LOGIN
  "form-candidate-login-welcome-title": {
    en: "Welcome back!",
    it: "Bentornato!"
  },
  "form-candidate-login-welcome-description": {
    en: "Highlight your talent! <br/> <b>Sign up</b> or <b>Log in</b> to your account",
    it: "Metti in luce il tuo talento! <br/> <b>Registrati</b> o <b>Accedi</b> se hai già un account "
  },
  "form-candidate-forgotten-password": {
    en: "Forgot your password?",
    it: "Hai dimenticato la password?"
  },
  "form-candidate-signup-now": {
    en: "Sign up now",
    it: "Registrati ora"
  },
  "form-candidate-get-started": {
    en: "Sign Up",
    it: "Registrati"
  },
  "form-candidate-no-account": {
    en: "Don't have an account?",
    it: "Non hai un profilo?"
  },
  "form-candidate-resend-email-title": {
    en: "Re-send confirmation email",
    it: "Invia email di conferma"
  },
  "form-candidate-resend-email-description": {
    en:
      "We'll send you to confirm your email address and login with your account",
    it: "Una volta confermata l'email potrai accedere alla tua pagina personale"
  },
  "form-candidate-resend-email-action": {
    en: "Send",
    it: "Invia"
  },
  "form-candidate-resend-email-1": {
    en: "Didn't receive confirmation email?",
    it: "Non hai ricevuto email di conferma?"
  },
  "form-candidate-resend-email-2": {
    en: "Re-send email",
    it: "Invia email"
  },
  "form-candidate-resend-email-done-description": {
    en:
      "In a few seconds you will receive an email containing a confirmation link",
    it: "Tra pochi secondi riceverai un'email contenente un link di conferma"
  },

  // REGISTER
  "form-candidate-register-welcome-title": {
    en: "Hi there!",
    it: "Ciao!"
  },
  "form-candidate-register-welcome-description": {
    en: "Highlight your talent! <br/> <b>Sign up</b> or <b>Log in</b> to your account",
    it: "Metti in luce il tuo talento! <br/> <b>Registrati</b> o <b>Accedi</b> se hai già un account "
  },
  "form-candidate-terms": {
    en: `
            I acknowledge that I have read carefully, understood and accepted the content of the 
            <a target="_blank" tabindex="-1" href="/en/platform/terms-and-conditions-cx">Terms and Conditions of Use</a>
            
        `,
    it: `
            Dichiaro di aver letto attentamente, di aver compreso e di accettare il contenuto dei 
            <a target="_blank" tabindex="-1" href="/it/piattaforma/termini-e-condizioni-cx">Termini e Condizioni d'Uso</a>
        `
  },
  "form-candidate-custom-privacy-policy": {
    en: ` 
   <a target="_blank" tabindex="-1" href="{{privacyUrl}}">I acknowledge the I have read the Information on the processing of personal data</a>
    `,
    it: `
    <a target="_blank" tabindex="-1" href="{{privacyUrl}}">Dichiaro di aver preso visione dell’Informativa sul trattamento dei dati personali</a>
    `
    },
  "form-custom-privacy-policy-itas": {
    en: ` 
   <a target="_blank" tabindex="-1" href="{{privacyUrl}}">I declare that I have read the Privacy Notice and consent to the processing of any special personal data contained in my resume for the purposes related to personnel selection, as described in point 2 of the same Notice.</a>
    `,
    it: `
    <a target="_blank" tabindex="-1" href="{{privacyUrl}}">Dichiaro di aver preso visione dell'Informativa e di acconsentire al trattamento degli eventuali dati personali di natura particolare contenuti nel mio curriculum, per le finalità inerenti alla selezione del personale, così come descritte al punto 2 dell'Informativa stessa</a>
    `
    },
  "form-candidate-privacy-premise": {
    en: ` 
    Having read the <a target="_blank" tabindex="-1" href="/en/platform/privacy">Information on the processing of personal data</a>
    `,
    it: `
    Presa visione dell’<a target="_blank" tabindex="-1" href="/it/piattaforma/privacy">Informativa sul trattamento dei dati personali</a>
    `
  },
  "form-candidate-whitelabel-privacy-premise": {
    en: ` 
    Having read the <a target="_blank" tabindex="-1" href="{{href-placeholder}}">Information on the processing of personal data</a>
    `,
    it: `
    Presa visione dell’<a target="_blank" tabindex="-1" href="{{href-placeholder}}">Informativa sul trattamento dei dati personali</a>
    `
  },
  "form-candidate-privacy": {
    en: `
            I consent to the communication of personal data to the subjects referred to in paragraph 8 of the 
            Information Section (in order to increase the likelihood of being contacted by third parties for personnel 
            selection purposes)
        `,
    it: `
            Acconsento alla comunicazione dei dati personali ai soggetti di cui al paragrafo 8 dell’Informativa 
            (così da aumentare le probabilità di essere contattato da terzi per finalità di selezione del 
            personale)
        `
  },
  "form-candidate-already-registered": {
    en: "Have an account?",
    it: "Hai già un profilo?"
  },
  "form-candidate-login": {
    en: "Log in",
    it: "Accedi"
  },

  // LOST PASSWORD
  "form-candidate-lost-password-title": {
    en: "Reset password",
    it: "Reimposta password"
  },
  "form-candidate-lost-password-description": {
    en:
      "Enter your email address and we'll send you instructions to reset your password",
    it:
      "Inserisci il tuo indirizzo email e ti invieremo le istruzioni per reimpostare la password"
  },
  "form-candidate-lost-password-back-to-login-1": {
    en: "Back to",
    it: "Torna alla"
  },
  "form-candidate-lost-password-back-to-login-2": {
    en: "Log in",
    it: "Log in"
  },
  "form-candidate-reset-password": {
    en: "Reset",
    it: "Reimposta"
  },

  // LOST PASSWORD DONE
  "form-candidate-lost-password-done-description": {
    en:
      "In a few seconds you should receive a link: open it to reset the password.",
    it:
      "Tra pochi secondi dovresti ricevere un link: aprilo per reimpostare la password."
  },
  "form-candidate-back-to-login": {
    en: "Back to Login",
    it: "Torna alla Login"
  },

  // REGISTER DONE
  "form-candidate-register-done-description": {
    en:
      "We have sent you an email. Click on the link you received to verify your account.",
    it:
      "Ti abbiamo inviato una email. Clicca sul link che hai ricevuto per verificare il tuo account."
  },
  "form-candidate-register-done-not-received": {
    en: "Didn't receive the email?",
    it: "Non hai ricevuto l'email?"
  },
  "form-candidate-register-done-resend-description": {
    en: "Click here",
    it: "Clicca qui"
  }
}

export default translations
