import React from "react"
import {Container, Language, Languages, Menu, MenuItem, Toggle} from "./styles"
import Link from "../link"
import {availableLocales, locales} from "../../constants/global"
import useTranslation from "../../hooks/useTranslation"
import {generateLocalizedPath} from "../../utils"

interface PropsType {
  pathKey: string
}

const LanguageSwitcher: React.FC<PropsType> = (props: PropsType) => {
  const {pathKey} = props
  const [, /*translate*/ currentLocale] = useTranslation()

  const currentLocation = window.location.pathname
  const currentLocalizedLocation = generateLocalizedPath(pathKey, currentLocale)
  const [, extraParams = ""] = currentLocation.split(currentLocalizedLocation)

  const hasMoreThanOneLocale: boolean = availableLocales.length > 1

  return (
    <div>
      <Container hoverable={hasMoreThanOneLocale}>
        <i className="icon-website" />
        <Languages>
          {availableLocales.map(
            ([destLocale, destLanguage]: [string, string]): React.ReactNode => {
              return (
                <Language
                  key={destLocale}
                  selected={destLocale === currentLocale}
                >
                  <Link
                    locale={destLocale}
                    to={pathKey}
                    extraParams={extraParams}
                  >
                    {destLanguage}
                  </Link>
                </Language>
              )
            }
          )}
        </Languages>
        <Toggle>
          {locales[currentLocale]}
          {hasMoreThanOneLocale && (
            <Menu>
              {availableLocales.map(
                ([destLocale, destLanguage]: [
                  string,
                  string
                ]): React.ReactNode => {
                  return (
                    <MenuItem key={destLocale}>
                      <Link
                        locale={destLocale}
                        to={pathKey}
                        extraParams={extraParams}
                      >
                        {destLanguage}
                      </Link>
                    </MenuItem>
                  )
                }
              )}
            </Menu>
          )}
        </Toggle>
      </Container>
    </div>
  )
}

export default LanguageSwitcher
