import {createContext} from "react"

const Context = createContext<{
  messageLabel: string | undefined
  setMessageLabel:(label: string | undefined) => void
}>({
  messageLabel: undefined,
  setMessageLabel: (_label: string | undefined) => {}
})

export default Context
