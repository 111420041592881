import React from "react"
import {RouteComponentProps, withRouter, Redirect} from "react-router"
import useCompanySectionExternalPage from "../../../hooks/useCompanySectionExternalPage"
import useTranslation from "../../../hooks/useTranslation"
import LoaderFullScreen from "../../../commonComponents/loader-full-screen"
import {generateLocalizedPath} from "../../../utils"
import {notFoundKey} from "../../../constants/pathKeys"
import {getCustomStyle, getEmptyCustomStyle} from "../../../customStyle"
import {
  CompanySectionDescription as Description,
  CompanySectionDescriptionCard as DescriptionCard,
  CompanySectionDescriptionCardWrapper as DescriptionCardWrapper
} from "./style"
import ExternalPageCompanyShell, {
  ExternalPageCompanyLikeType
} from "../../../commonComponents/external-page-company-shell"
import TopInformations from "../components/company-section-informations"

interface Params {
  id: string
}

interface Props extends RouteComponentProps<Params> {}

const CompanySection: React.FC<Props> = ({
  match: {
    params: {id}
  }
}) => {
  const idNumber = parseInt(id)

  const {
    companySection,
    hasError: redirectToNotFound,
    redirectPath,
    corePath
  } = useCompanySectionExternalPage(idNumber)

  const [, locale] = useTranslation()

  if (redirectPath) return <Redirect to={redirectPath} />

  if (redirectToNotFound) {
    return <Redirect to={`${generateLocalizedPath(notFoundKey, locale)}`} />
  }

  if (!companySection) return <LoaderFullScreen />

  const {
    backgroundUrl,
    challenges,
    color,
    description,
    isPublic,
    companyCareersName
  } = companySection

  const {
    customDescriptionColor,
    customPrimaryTextColor,
    customBackgroundColor,
    customBorderColor,
    customButtonColor,
    customButtonHoverColor,
    customButtonTextColor
  } = isPublic ? getEmptyCustomStyle() : getCustomStyle(companyCareersName)

  const processedDescription = description.replace(/<[^>]+>/g, "")

  const descriptionRawComponent =
    processedDescription.length > 0 ? (
      <Description
        dangerouslySetInnerHTML={{__html: description}}
        customColor={customDescriptionColor}
      ></Description>
    ) : null

  const descriptionComponent = descriptionRawComponent ? (
    <DescriptionCardWrapper
      className="row section-description"
      id="discover-more"
    >
      <DescriptionCard
        customColor={customBackgroundColor}
        customBorderColor={customBorderColor}
      >
        {descriptionRawComponent}
      </DescriptionCard>
    </DescriptionCardWrapper>
  ) : null

  return (
    <ExternalPageCompanyShell
      nextUrl={corePath}
      isPublic={isPublic}
      carouselUrls={[backgroundUrl]}
      careersName={companyCareersName}
      challenges={challenges}
      companyChallengesListDefaultColor={color}
      sections={[]} // Since we are a section detail, we don't pass a section list
      type={ExternalPageCompanyLikeType.section}
    >
      {{
        topInformations: (
          <TopInformations
            section={companySection}
            customDescriptionColor={customDescriptionColor}
            customTitleColor={customPrimaryTextColor}
            customButtonColor={customButtonColor}
            customButtonHoverColor={customButtonHoverColor}
            customButtonTextColor={customButtonTextColor}
            careersName={companyCareersName}
          ></TopInformations>
        ),
        bottomInformationsList: [descriptionComponent]
      }}
    </ExternalPageCompanyShell>
  )
}

export default withRouter<Props, React.FC<Props>>(CompanySection)
