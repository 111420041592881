import styled from "styled-components"
import styleConstants from "../../../../constants/styleConstants"

export const CompanyChallengesListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0.8rem;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    padding: 0;
    flex-direction: row;
    justify-content: space-between;
  }
`

// eslint-disable-next-line no-unexpected-multiline
export const CompanyChallengesListChallengeWrapper = styled.div<{
  hideOnMobile?: boolean
}>`
  display: ${({hideOnMobile}) => (hideOnMobile ? "none" : "block")};

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    display: block;
    width: calc(25% - 1.4rem);
  }
`
