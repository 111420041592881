import React from "react"
import {CompanySection} from "../../../../types/companySection"
import {truncate, smoothScrollTo, getCandidateImageUrl} from "../../../../utils"
import {
  CompanySectionInformationsWrapper as Wrapper,
  CompanySectionInformationsContent as Content,
  CompanySectionInformationsDescription as Description,
  CompanySectionInformationsLogo as Logo,
  CompanySectionInformationsName as Name,
  CompanySectionInformationsShowMoreButton as ShowMoreButton
} from "./style"
import Translate from "../../../../commonComponents/translate"
import SimpleAction from "../../../../commonComponents/action"

const GENERALI_CAREERS_NAME = "generali"

interface Props {
  section: CompanySection
  customTitleColor: string | undefined
  customDescriptionColor: string | undefined
  customButtonColor: string | undefined
  customButtonHoverColor: string | undefined
  customButtonTextColor: string | undefined
  careersName: string | undefined
}

const CompanySectionInformations: React.FC<Props> = ({
  section: {iconUrl, description, shortDescription, name},
  customDescriptionColor,
  customTitleColor,
  customButtonColor,
  customButtonHoverColor,
  customButtonTextColor,
  careersName
}) => {
  const MAX_CHAR_NUMBER = 100

  const descriptionText = truncate(
    description,
    shortDescription,
    MAX_CHAR_NUMBER
  )

  return (
    <Wrapper>
      <Logo
        src={
          iconUrl ||
          getCandidateImageUrl("placeholders/placeholder-icon-company.png")
        }
        localize={false}
      />
      <Content>
        <Name
          customColor={customTitleColor}
          dangerouslySetInnerHTML={{__html: name}}
        />
        <Description
          customColor={customDescriptionColor}
          dangerouslySetInnerHTML={{__html: descriptionText}}
        />
        {careersName === GENERALI_CAREERS_NAME ? null : <div>
          <ShowMoreButton
            className={`company-section-informations-show-more-button${
              customButtonColor ? " custom" : ""
            }`}
            size={SimpleAction.sizes.medium}
            variant={SimpleAction.variants.hoverableBlack}
            customColor={customButtonColor}
            customHoverColor={customButtonHoverColor}
            customTextColor={customButtonTextColor}
            onClick={() => smoothScrollTo("discover-more")}
          >
            <Translate label={"global-discover-more"} />
          </ShowMoreButton>
        </div>}
      </Content>
    </Wrapper>
  )
}

export default CompanySectionInformations
