import React from "react"
import {RouteComponentProps, withRouter, Redirect} from "react-router"
import {
  registerCandidate,
  resendEmailCandidate
} from "../../../requests/candidatePlatform"
import {AccessUserType} from "../../../types/accessUser"
import AccessSignup from "../../../commonComponents/access-signup"
import useCompanyInfo from "../../../hooks/useCompanyInfo"
import {generateLocalizedPath} from "../../../utils"
import {notFoundKey} from "../../../constants/pathKeys"
import useTranslation from "../../../hooks/useTranslation"
import LoaderFullScreen from "../../../commonComponents/loader-full-screen"
import {EmailFloatingButton} from "../../../commonComponents/email-floating-button/EmailFloatingButton"

interface ParamsType {
  companyName?: string
}

type PropsType = RouteComponentProps<ParamsType> & {
  inChallengeSignup?: boolean
  redirectUrl?: string
  owner?: string
  companyCareersName?: string
  customTitle?: string
  customDescription?: string
  customBodyBackgroundColor?: string
  customPrimaryTextColor?: string
  customButtonColor?: string
  customButtonHoverColor?: string
  customButtonTextColor?: string
}
const CandidatePlatformSignup: React.FC<PropsType> = ({
  match: {
    params: {companyName: queryCompanyName}
  },
  inChallengeSignup,
  redirectUrl,
  owner,
  companyCareersName,
  customTitle,
  customDescription,
  customBodyBackgroundColor,
  customPrimaryTextColor,
  customButtonColor,
  customButtonHoverColor,
  customButtonTextColor
}: PropsType) => {
  const companyName = queryCompanyName || owner
  const {
    companyInfo,
    companyInfoRetrieved: readyToRender,
    requiredCompanyInfoNotAvailable
  } = useCompanyInfo("candidates", companyName)

  const [, locale] = useTranslation()

  if (!readyToRender && !inChallengeSignup) return <LoaderFullScreen />

  const hasCustomBackground = !!companyInfo.landingPageBackgroundUrl || false
  const backgroundUrl = companyName
    ? companyInfo.landingPageBackgroundUrl
    : inChallengeSignup
    ? ""
    : undefined
  return requiredCompanyInfoNotAvailable ? (
    <Redirect to={`${generateLocalizedPath(notFoundKey, locale)}`} />
  ) : (
    <React.Fragment>
      <AccessSignup
        userType={AccessUserType.Candidate}
        companyName={companyName}
        companyCareersName={companyCareersName}
        registerFunction={registerCandidate}
        resendEmailFunction={resendEmailCandidate}
        privacyUrl={companyInfo.privacyUrl}
        privacyPolicyConsentRequired={companyInfo.privacyPolicyConsentRequired}
        privacyPolicyCustomMessage={
          companyInfo.privacyPolicyConsentFormCustomMessage
        }
        hasCustomBackground={hasCustomBackground}
        inChallengeSignup={!!inChallengeSignup}
        redirectUrl={redirectUrl}
        backgroundUrl={backgroundUrl}
        logoUrl={companyInfo.logoUrl}
        customTitle={customTitle}
        customDescription={customDescription}
        customBodyBackgroundColor={customBodyBackgroundColor}
        customPrimaryTextColor={customPrimaryTextColor}
        customButtonColor={customButtonColor}
        customButtonHoverColor={customButtonHoverColor}
        customButtonTextColor={customButtonTextColor}
      />

      <EmailFloatingButton />
    </React.Fragment>
  )
}

export default withRouter<PropsType, React.FC<PropsType>>(
  CandidatePlatformSignup
)
