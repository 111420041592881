import {TranslationType} from "../types"

const translations: TranslationType = {
  // header
  "award-header-title": {
    en: "Glickon Experience 2019",
    it: "Glickon Experience 2019"
  },
  "award-header-description": {
    en: "October 10, 2019 I Bianca Maria Palace Hotel, Milan",
    it: "10 ottobre 2019 I Bianca Maria Palace Hotel, Milano"
  },

  // banner-bottom
  "award-banner-bottom-title": {
    en: "Join us!",
    it: "Iscriviti subito a GlickonExperience 2019!"
  },
  "award-banner-bottom-description": {
    en:
      "Join us and discover the power of Employee Experience to reimagine your business",
    it:
      "Non mancare l’appuntamento dell’anno con i leader del settore e scopri il potere della Employee Experience e People Analyitcs."
  },

  "award-action-label": {
    en: "REGISTER NOW",
    it: "REGISTRATI"
  },

  "award-awards-title": {
    en: "Glickon Experience Awards 2019",
    it: "Glickon Experience Awards 2019"
  },

  "award-awards-subtitle": {
    en:
      "Celebrating the best in crafting people experiences and analytics programs.",
    it:
      "Premiamo e celebriamo i migliori player italiani che si sono distinti in iniziative di Employee Experience e People Analytics nel 2019."
  },

  // subtitle-module-1
  "award-1st-subtitle-title": {
    en: "Employee Experience is here: are you ready for it?",
    it: "E’ arrivata l’era della Employee Experience: sei pront*?"
  },
  "award-1st-subtitle-description": {
    en: `
            Managing employee experience is imperative because companies that deliver great employee experiences deliver better business
            results. They realize higher employee productivity, lower turnover, and higher customer satisfaction.
            If you want to improve your employee experience what should you do? Where do you start?
            Come learn strategies & tips to help you.
        `,
    it: `
            Viviamo nell’economia dell’esperienza ed è ormai indispensabile gestire l’esperienza delle persone che lavorano nelle nostre
            organizzazioni. Le aziende che offrono esperienze eccellenti ai propri dipendenti sono capaci di attrarre talenti,
            ridurre il turnover, aumentare la soddisfazione dei propri dipendenti e quella dei clienti e raggiungono migliori risultati
            economici. Vuoi migliorare l'esperienza dei tuoi dipendenti ma non sai da dove iniziare?
            Vieni a scoprire come i migliori brand creano esperienze indimenticabili e riescono a vincere la sfida dello
            sviluppo del business e delle persone.
        `
  },

  // subtitle-module-2
  "award-2nd-subtitle-description": {
    en: `
            Learn how industry leaders shape a sustainable Employee Experience that attract, develop and retain great people and
            deliver better business results. Build your network and connect with your peers in a way you’ve never experienced before.
        `,
    it: `
            Anche quest’anno Glickon riunisce i migliori HR leader per apprendere dalle sfide di oggi e trarre ispirazione per le
            soluzioni di domani. Non perdere quest’opportunità di confronto con gli innovatori che stanno cambiando le regole del gioco:
            scopri come l’executive leadership delle migliori aziende italiane ha trasformato il proprio approccio alla
            Employee Experience e ascolta le loro esperienze di maggior successo.
        `
  },

  // subtitle-module-3
  "award-3rd-subtitle-title": {
    en: "There’s even more to learn",
    it: "Scopri di più su Glickon"
  },
  "award-3rd-subtitle-description": {
    en: `
            Spend one-on-one time with our experts and take advantage of this unique opportunity to share your challenges and discover
            the power of our Employee Experience and People Analytics platform.
        `,
    it: `
            Cogli l’opportunità di confrontarti in un meeting one-on-one con uno dei nostri esperti, condividire le tue sfide e
            scoprire quali siano gli strumenti necessari per implementare strategie e soluzioni di
            Employee Experience e People Analytics nella tua azienda.
        `
  },

  "award-agenda-title": {
    en: "Agenda",
    it: "Agenda"
  },
  "award-agenda-location": {
    en: `
            October 10, 2019 <br/>
            Bianca Maria Palace Hotel <br/>
            Viale Bianca Maria, 4, 20129 Milan
        `,
    it: `
            10 ottobre 2019 <br/>
            Bianca Maria Palace Hotel <br/>
            Viale Bianca Maria, 4, 20129 Milano
        `
  },
  "award-agenda-description": {
    en: `
            9.00&nbsp&nbsp&nbsp <b>Registration</b> <br/>
            9.30&nbsp&nbsp&nbsp <b>Welcome Keynote</b> <br/>
            &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp Filippo Negri, Co-founder – Glickon <br/> <br/>
            10.00&nbsp&nbsp <b>Roundtable Panel</b>: A discussion with leading visionaries who are promoting innovation to improve the employee <br/>
            &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp experience and reach sustainable business in the short as in the long term. <br/> <br/>
            &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp <u>Guest speakers</u>: <br/>
            &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp • Samuele Marconcini, Head of HR and Organizational Development – Cattolica Assicurazioni <br/>
            &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp • Alessandro Montanari, Chief People & Change Management Officer – Successori Reda <br/>
            &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp • Nicola Pelà – Heidrick & Struggles London <br/>
            &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp • Elena Santambrogio, VP People & Culture – Discovery Inc. <br/>
            &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp • Fabio Tentori, Head of Innovation Hubs & Startups Initiatives – Enel<br/><br/>
            &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp <u>Moderator</u>: <br/>
            &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp Francesca Parviero, Digital HR & Learning Experience Designer <br/> <br/>
            11.00&nbsp&nbsp&nbsp <b>Breakout Session</b> <br/> <br/>
            11.30&nbsp&nbsp&nbsp <b>Coffee Break</b> <br/> <br/>
            11.50&nbsp&nbsp&nbsp <b>GlickonExperience2019 Awards and Stories</b>: Glickon customers pioneering Candidate Experience, Employee <br/>
            &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp Experience, Live, People Analytics. <br/> <br/>
            &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp • Deloitte <br/>
            &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp • Gruppo Iren <br/>
            &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp • GiGroup <br/>
            &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp • Acea <br/> <br/>
            13.00&nbsp&nbsp&nbsp <b>Networking Lunch and Demo Corner</b>
        `,
    it: `
            9.00&nbsp&nbsp&nbsp <b>Registrazione</b> <br/>
            9.30&nbsp&nbsp&nbsp <b>Keynote e benvenuto</b> <br/>
            &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp Filippo Negri, Co-founder – Glickon <br/> <br/>
            10.00&nbsp&nbsp&nbsp<b>Tavola rotonda con esperti e relatori indipendenti sul tema Innovability:</b> <br/>
            &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp combinare innovazione e sostenibilità nello sviluppo del business e delle <br/>
            &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp persone. <br/> <br/>
            &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp <u>Relatori:</u> <br/>
            &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp • Samuele Marconcini, Head of HR and Organizational Development – Cattolica Assicurazioni <br/>
            &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp • Alessandro Montanari, Chief People & Change Management Officer – Successori Reda <br/>
            &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp • Nicola Pelà – Heidrick & Struggles London <br/>
            &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp • Elena Santambrogio, VP People & Culture – Discovery Inc. <br/>
            &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp • Fabio Tentori, Head of Innovation Hubs & Startups Initiatives – Enel<br/><br/>
            &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp <u>Modera:</u> <br/>
            &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp Francesca Parviero, Digital HR & Learning Experience Designer <br/> <br/>
            11.00&nbsp&nbsp&nbsp <b>Sessioni breakout</b> <br/> <br/>
            11.30&nbsp&nbsp&nbsp <b>Coffee Break</b> <br/> <br/>
            11.50&nbsp&nbsp&nbsp <b>GlickonExperience2019 Awards</b>: cerimonia di premiazione e esposizione  <br/>
            &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp dei casi di studio dei clienti Glickon in ambito Candidate Experience, <br/>
            &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp Employee Experience, Live, People Analytics. <br/> <br/>
            &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp • Deloitte <br/>
            &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp • Gruppo Iren <br/>
            &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp • GiGroup <br/>
            &nbsp &nbsp &nbsp &nbsp &nbsp &nbsp • Acea <br/> <br/>
            13.00&nbsp&nbsp&nbsp <b>Pranzo di networking e Demo Corner</b>
        `
  },
  "award-agenda-description-mobile": {
    en: `
            9.00 <b>Registration</b> <br/>
            9.30 <b>Welcome Keynote</b> <br/>
            Filippo Negri, Co-founder – Glickon <br/> <br/>
            10.00 <b>Roundtable Panel</b>: A discussion with leading visionaries who are promoting innovation to improve the employee
            experience and reach sustainable business in the short as in the long term. <br/> <br/>
            <u>Guest speakers</u>: <br/>
            • Samuele Marconcini, Head of HR and Organizational Development – Cattolica Assicurazioni <br/> <br/>
            • Alessandro Montanari, Chief People & Change Management Officer – Successori Reda <br/> <br/>
            • Nicola Pelà – Heidrick & Struggles London <br/> <br/>
            • Elena Santambrogio, VP People & Culture – Discovery Inc. <br/> <br/>
            • Fabio Tentori, Head of Innovation Hubs & Startups Initiatives – Enel<br/><br/>
            <u>Moderator</u>: <br/>
            Francesca Parviero, Digital HR & Learning Experience Designer <br/> <br/>
            11.00 <b>Breakout Session</b> <br/> <br/>
            11.30 <b>Coffee Break</b> <br/> <br/>
            11.50 <b>GlickonExperience2019 Awards and Stories</b>: Glickon customers pioneering Candidate Experience, Employee
            Experience, Live, People Analytics. <br/> <br/>
            • Deloitte <br/>
            • Gruppo Iren <br/>
            • GiGroup <br/>
            • Acea <br/> <br/>
            13.00 <b>Networking Lunch and Demo Corner</b>
        `,
    it: `
            9.00 <b>Registrazione</b> <br/>
            9.30 <b>Keynote e benvenuto</b> <br/>
            Filippo Negri, Co-founder – Glickon <br/> <br/>
            10.00 <b>Tavola rotonda con esperti e relatori indipendenti sul tema Innovability:</b> <br/>
            combinare innovazione e sostenibilità nello sviluppo del business e delle persone. <br/> <br/>
            <u>Relatori:</u> <br/>
            • Samuele Marconcini, Head of HR and Organizational Development – Cattolica Assicurazioni <br/> <br/>
            • Alessandro Montanari, Chief People & Change Management Officer – Successori Reda <br/> <br/>
            • Nicola Pelà – Heidrick & Struggles London <br/> <br/>
            • Elena Santambrogio, VP People & Culture – Discovery Inc. <br/> <br/>
            • Fabio Tentori, Head of Innovation Hubs & Startups Initiatives – Enel<br/><br/>
            <u>Modera:</u> <br/>
            Francesca Parviero, Digital HR & Learning Experience Designer <br/> <br/>
            11.00 <b>Sessioni breakout</b> <br/> <br/>
            11.30 <b>Coffee Break</b> <br/> <br/>
            11.50 <b>GlickonExperience2019 Awards</b>: cerimonia di premiazione e esposizione
            dei casi di studio dei clienti Glickon in ambito Candidate Experience,
            Employee Experience, Live, People Analytics. <br/> <br/>
            • Deloitte <br/>
            • Gruppo Iren <br/>
            • GiGroup <br/>
            • Acea <br/> <br/>
            13.00 <b>Pranzo di networking e Demo Corner</b>
        `
  },
  "award-agenda-note": {
    en:
      "This event is by invitation only and free to attend for leaders in HR.",
    it:
      "La partecipazione all'evento è gratuita e solo su invito per gli HR leaders."
  },

  "award-one-to-one-label": {
    en: "RICHIEDI ONE-TO-ONE",
    it: "RICHIEDI ONE-TO-ONE"
  },

  "award-demo-label": {
    en: "PRENOTA LIVE DEMO",
    it: "PRENOTA LIVE DEMO"
  },

  // company awards
  "award-1st-company-award-title": {
    en: "Candidate Experience",
    it: "Candidate Experience"
  },
  "award-1st-company-award-subtitle": {
    en:
      "This award recognises the best candidate experience on Glickon that drive brand awareness and elective recruiting.",
    it: `Questo premio è dedicato a ai recruiters che hanno saputo costruire una strategia di talent acquisition
            efficace per attrarre e selezionare candidati offrendo loro un’esperienza positiva.`
  },
  "award-1st-company-award-description": {
    en: "MIGLIOR CANDIDATE EXPERIENCE 2019",
    it: "MIGLIOR CANDIDATE EXPERIENCE 2019"
  },

  "award-2nd-company-award-title": {
    en: "Employee Experience",
    it: "Employee Experience"
  },
  "award-2nd-company-award-subtitle": {
    en:
      "This award recognises the best Employee Experience on Glickon that drive engagement, continuos change management and upskilling.",
    it: `Questo premio è dedicato agli HR che hanno saputo aumentare l’engagement e favorire il
            reskilling dei collaboratori imposti dalla trasformazione delle professionalità.`
  },
  "award-2nd-company-award-description": {
    en: "MIGLIOR EMPLOYEE EXPERIENCE 2019",
    it: "MIGLIOR EMPLOYEE EXPERIENCE 2019"
  },

  "award-3rd-company-award-title": {
    en: "People Analytics",
    it: "People Analytics"
  },
  "award-3rd-company-award-subtitle": {
    en:
      "This award recognises the best People Analytics initative on Glickon that drive intelligent actions plan based on people data.",
    it: `Questo premio è dedicato ai change leader che hanno sviluppato una cultura HR data-driven
            per prendere decisioni migliori sulle persone.`
  },
  "award-3rd-company-award-description": {
    en: "MIGLIOR PEOPLE ANALYTICS 2019",
    it: "MIGLIOR PEOPLE ANALYTICS 2019"
  },

  "award-4th-company-award-title": {
    en: "Live Experience",
    it: "Live Experience"
  },
  "award-4th-company-award-subtitle": {
    en:
      "This award recognises the best Live Experience on Glickon that drive audience engagement and make events stand out.",
    it: `Questo premio è dedicato a chi ha saputo trasformare momenti di incontro con i propri candidati o dipendenti in
            eventi indimenticabili mettendo al centro l’esperienza delle persone.`
  },
  "award-4th-company-award-description": {
    en: "MIGLIOR LIVE EXPERIENCE 2019",
    it: "MIGLIOR LIVE EXPERIENCE 2019"
  },

  // double box
  "award-1st-box-title": {
    en: "Expert One-on-one Meeting",
    it: "Expert One-on-one Meeting"
  },
  "award-1st-box-description": {
    en: `Spend one-on-one time with our experts and take advantage of this unique opportunity to share your challenges and
            learn strategies & tips to help you.`,
    it: `
            Scopri con uno dei nostri esperti come possiamo aiutarti ad allineare l’organizzazione e le decisioni relative alle persone
            con gli obiettivi strategici e finanziari attraverso una gestione eccellente della Employee Experience.
        `
  },
  "award-2nd-box-title": {
    en: "Live Demo",
    it: "Live Demo"
  },
  "award-2nd-box-description": {
    en: `Discover the power of our Employee Experience and People Analytics platform and browse our platform for
            15 min with our Customer Success Team.`,
    it: `
            Potrai confrontarti con il nostro team di Customer Success Management e navigare nelle nostre piattaforme di
            Employee Experience e People Analytics per una demo di 15 minuti.
        `
  }
}

export default translations
