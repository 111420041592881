import {TranslationType} from "../types"

const translations: TranslationType = {
  // LOGIN
  "form-employee-login-welcome-title": {
    en: "Welcome back!",
    it: "Bentornato!"
  },
  "form-employee-login-welcome-description": {
    en: "Unleash talent potential with <b>Glow</b> <br/> <b>Sign up</b> or <b>Log in</b> to your account",
    it: "Fai brillare il talento con <b>Glow</b> <br/> <b>Registrati</b> o <b>Accedi</b> se hai già un account"
  },
  "form-employee-forgotten-password": {
    en: "Forgot your password?",
    it: "Hai dimenticato la password?"
  },
  "form-employee-signup-now": {
    en: "Sign up now",
    it: "Registrati ora"
  },
  "form-employee-get-started": {
    en: "Get started",
    it: "Registrati"
  },
  "form-employee-preregister-action": {
    en: "Continue",
    it: "Continua"
  },
  "form-employee-no-account": {
    en: "Don't have an account?",
    it: "Non hai un profilo aziendale?"
  },
  "form-employee-resend-email-title": {
    en: "Re-send confirmation email",
    it: "Invia di nuovo l'email di verifica"
  },
  "form-employee-resend-email-description": {
    en:
      "We'll send you to confirm your email address and login with your account",
    it: "Una volta confermata l'email potrai accedere alla tua pagina personale"
  },
  "form-employee-resend-email-action": {
    en: "Send",
    it: "Invia"
  },
  "form-employee-resend-email-1": {
    en: "Didn't receive confirmation email?",
    it: "Non hai ricevuto email di conferma?"
  },
  "form-employee-resend-email-2": {
    en: "Re-send email",
    it: "Invia email"
  },
  "form-employee-resend-email-done-description": {
    en:
      "In a few seconds you will receive an email containing a confirmation link",
    it: "Tra pochi secondi riceverai un'email contenente un link di conferma"
  },

  // LOST PASSWORD
  "form-employee-lost-password-title": {
    en: "Reset password",
    it: "Reimposta password"
  },
  "form-employee-lost-password-description": {
    en:
      "Enter your email address and we'll send you instructions to reset your password",
    it:
      "Inserisci il tuo indirizzo email e ti invieremo le istruzioni per reimpostare la password"
  },
  "form-employee-lost-password-back-to-login-1": {
    en: "Back to",
    it: "Torna alla"
  },
  "form-employee-lost-password-back-to-login-2": {
    en: "Log in",
    it: "Log in"
  },
  "form-employee-reset-password": {
    en: "Reset",
    it: "Reimposta"
  },

  // LOST PASSWORD DONE
  "form-employee-lost-password-done-description": {
    en:
      "In a few seconds you should receive a link: open it to reset the password.",
    it:
      "Tra pochi secondi dovresti ricevere un link: aprilo per reimpostare la password."
  },
  "form-employee-back-to-login": {
    en: "Back to Login",
    it: "Torna alla Login"
  },

  // REGISTER
  "form-employee-register-welcome-title": {
    en: "Welcome!",
    it: "Ciao!"
  },
  "form-corporate-register-welcome-title": {
    en: "Hi there!",
    it: "Ciao!"
  },
  "form-employee-register-welcome-description": {
    en: "Unleash talent potential with <b>Glow</b> <br/> <b>Sign up</b> or <b>Log in</b> to your account",
    it: "Fai brillare il talento con <b>Glow</b> <br/> <b>Registrati</b> o <b>Accedi</b> se hai già un account"
  },
  "form-employee-terms": {
    en: `
            I acknowledge that I have read carefully, understood and accepted the content of the 
            <a target="_blank" tabindex="-1" href="/en/platform/terms-and-conditions-ex">Terms and Conditions of Use</a>
            
        `,
    it: `
            Dichiaro di aver letto attentamente, di aver compreso e di accettare il contenuto dei 
            <a target="_blank" tabindex="-1" href="/it/piattaforma/termini-e-condizioni-ex">Termini e Condizioni d'Uso</a>
        `
  },
  "form-employee-custom-privacy-policy": {
    en: ` 
   <a target="_blank" tabindex="-1" href="{{privacyUrl}}">I acknowledge the I have read the Information on the processing of personal data</a>
    `,
    //   it: "Dichiaro di aver preso visione dell’Informativa sul trattamento dei dati personali"
    it: `
    <a target="_blank" tabindex="-1" href="{{privacyUrl}}">Dichiaro di aver preso visione dell’Informativa sul trattamento dei dati personali</a>
    `
    },
  "form-employee-privacy-premise": {
    en: ` 
    Having read the <a target="_blank" tabindex="-1" href="/en/platform/privacy">Information on the processing of personal data</a>
    `,
    it: `
    Presa visione dell’<a target="_blank" tabindex="-1" href="/it/piattaforma/privacy">Informativa sul trattamento dei dati personali</a>
    `
  },
  "form-employee-whitelabel-privacy-premise": {
    en: ` 
    Having read the <a target="_blank" tabindex="-1" href="{{href-placeholder}}">Information on the processing of personal data</a>
    `,
    it: `
    Presa visione dell’ <a target="_blank" tabindex="-1" href="{{href-placeholder}}">Informativa sul trattamento dei dati personali</a>
    `
  },
  "form-employee-privacy": {
    en: `
            I consent to the communication of personal data to the subjects referred to in paragraph 8 of the 
            Information Section (in order to increase the likelihood of being contacted by third parties for personnel 
            selection purposes)
        `,
    it: `
            Acconsento alla comunicazione dei dati personali ai soggetti di cui al paragrafo 8 dell’Informativa 
            (così da aumentare le probabilità di essere contattato da terzi per finalità di selezione del 
            personale)
        `
  },
  "form-employee-already-registered": {
    en: "Already have a company profile?",
    it: "Hai già un profilo aziendale?"
  },
  "form-employee-login": {
    en: "Login",
    it: "Accedi"
  },

  // REGISTER DONE
  "form-employee-register-done-description": {
    en:
      "We have sent you an email. Click on the link you received to verify your account.",
    it:
      "Ti abbiamo inviato una email. Clicca sul link che hai ricevuto per verificare il tuo account."
  },
  "form-employee-register-done-not-received": {
    en: "Didn't receive the email?",
    it: "Non hai ricevuto l'email?"
  },
  "form-employee-register-done-resend-description": {
    en: "Click here",
    it: "Clicca qui"
  }
}

export default translations
