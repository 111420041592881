import {TranslationType} from "../types"

const translations: TranslationType = {
  "live-privacy-title": {
    en: "",
    it: "Informativa privacy"
  },
  "live-privacy-pre-list-text": {
    en: "",
    it: `
            Informativa sul trattamento dei dati personali (secondo quanto previsto dal General Data Protection 
            Regulation - Regolamento UE 2016/679)
        `
  },
  "live-privacy-last-modified": {
    en: "",
    it: "Milano, 31 Marzo 2019"
  },

  "live-privacy-1": {
    en: "",
    it: "Informazioni generali"
  },
  "live-privacy-1-1": {
    en: "",
    it: `
                Il presente documento espone le modalità attraverso le quali la società Glickon S.r.l. (di seguito anche 
                <strong>"Glickon"</strong>) utilizza i dati personali forniti dagli utenti (<strong>"Utenti Live"</strong>) 
                del servizio Live Game (<strong>"Servizio"</strong>).
            `
  },
  "live-privacy-1-2": {
    en: "",
    it: `
                Il Servizio consiste nella partecipazione volontaria a giochi a quiz, promossi da società che hanno 
                stretto accordi commerciali con Glickon (<strong>"Promotori"</strong>), al fine di svolgere attività di 
                coinvolgimento dei partecipanti ad un evento durante le seguenti attività in ambito risorse umane, a 
                titolo esemplificativo ma non esaustivo: incontri con studenti, attività di employer branding, colloqui 
                di gruppo, onboarding di neoassunti, meeting e presentazioni, aule formative ed eventi aziendali.
            `
  },
  "live-privacy-1-3": {
    en: "",
    it: `
                Il Servizio è disponibile in versione desktop/mobile tramite applicazione web accessibile attraverso i 
                più comuni web-browser.
            `
  },
  "live-privacy-1-4": {
    en: "",
    it: `
                Per Dato Personale (di seguito anche solo <strong>"Dato"</strong>) si intende qualsiasi informazione 
                riguardante una persona fisica identificata o identificabile (<strong>"Interessato"</strong>). Si 
                considera identificabile la persona fisica che può essere identificata, direttamente o indirettamente, 
                con particolare riferimento ad un elemento identificativo come: nome, numero identificazione, ubicazione, 
                identificativo on-line, elementi relativi all’identità fisica, genetica, politica (ecc.).
            `
  },
  "live-privacy-1-5": {
    en: "",
    it: `
                I Dati Personali forniti dagli Utenti Live/Interessati saranno oggetto di trattamento in conformità 
                alla normativa applicabile in materia di protezione dei dati personali e, in particolare, nel rispetto 
                dei principi di liceità, correttezza, trasparenza nei confronti dell’Interessato, nonché secondo i 
                criteri di: limitazione delle finalità, minimizzazione, esattezza, conservazione limitata, integrità e 
                riservatezza.
            `
  },
  "live-privacy-1-6": {
    en: "",
    it: `
                Per le definizioni non espressamente contenute nel presente documento, si rimanda espressamente alle 
                definizioni richiamate nelle Condizioni Generali del Servizio.
            `
  },

  "live-privacy-2": {
    en: "",
    it: "Titolare e responsabile del trattamento"
  },
  "live-privacy-2-1": {
    en: "",
    it: `
                Nel caso di partecipazione ad attività ludiche promosse da Glickon, il Titolare del trattamento dei 
                Dati Personali è Glickon S.r.l., società di diritto italiano con sede legale in Milano, 20129, via Nino 
                Bixio, 7, codice fiscale/numero di iscrizione al registro delle imprese di Milano 08573820969, 
                <a href="mailto:privacy@glickon.com" target="_blank">privacy@glickon.com</a>.
            `
  },
  "live-privacy-2-2": {
    en: "",
    it: `
                Nel caso di partecipazione ad attività promosse da Promotori, Titolare del trattamento dei dati è la 
                singola azienda promotrice, la cui ragione sociale è riportata nella login page della applicazione web 
                - che garantisce idonea informativa in relazione al trattamento dei dati personali, alla quale si rinvia 
                - mentre Glickon è Responsabile del trattamento per l’attività di gestione e messa a disposizione del 
                Servizio, nominata come tale dal Promotore (art. 28 GDPR).
            `
  },
  "live-privacy-2-3": {
    en: "",
    it: `
                Glickon si avvale di fornitori qualificati per il funzionamento del Servizio che, qualora Glickon sia 
                Titolare del trattamento dei dati sono inquadrabili come Responsabili del trattamento, qualora invece 
                Glickon sia Responsabile del trattamento (per conto di Promotori) sono inquadrabili come 
                Sub-Responsabili del trattamento (art. 28 paragrafo 2 GDPR).
            `
  },
  "live-privacy-2-4": {
    en: "",
    it: `
                In ogni caso i dati trattati tramite il Servizio sono localizzati in ambito UE.
            `
  },

  "live-privacy-3": {
    en: "",
    it: "Finalità e base giuridica"
  },
  "live-privacy-3-1": {
    en: "",
    it: `
            L’accesso al Servizio, che avviene esclusivamente su base volontaria, consente all’Utente Live di mettere 
            alla prova le proprie competenze (conoscenze) in determinate materie d’interesse a scopo ludico: ciò 
            avviene attraverso la partecipazione a giochi a quiz, da giocare in competizione con altri soggetti.
        `
  },
  "live-privacy-3-2": {
    en: "",
    it: `
            La finalità del trattamento è quella di consentire all’Utente Live di giocare ai giochi proposti dal 
            Promotore e di essere ingaggiato nelle attività proposte da quest’ultimo. In tal caso i Dati Personali 
            dell’Utente Live saranno resi accessibili al Promotore e potranno essere da quest’ultimo trattati.
        `
  },
  "live-privacy-3-3": {
    en: "",
    it: `
            I Dati Personali richiesti all’atto dell’accesso al Servizio saranno quindi trattati ai fini dell’
            utilizzo del Servizio stesso e, in particolare, per consentire agli utenti di essere ingaggiati ed 
            entrare in contatto con il Promotore. 
        `
  },
  "live-privacy-3-4": {
    en: "",
    it: `
            Il conferimento dei Dati Personali richiesti dell’accesso al Servizio è necessario ai fini dell’utilizzo 
            dello stesso: in caso di mancato conferimento dei dati e/o in mancanza del consenso dell’Utente Live al 
            trattamento dei Dati Personali l’accesso al Servizio risulterà impossibile.
        `
  },
  "live-privacy-3-5": {
    en: "",
    it: `
            Il Promotore potrà inviare comunicazioni e altre informazioni inerenti alla propria attività via e-mail 
            agli Utenti Live, alla casella da questi indicata in sede di accesso. Resta inteso, tuttavia, che l’Utente 
            Live potrà chiedere di non ricevere più queste comunicazioni in qualsiasi momento, attraverso il link 
            posto all’interno di ciascuna comunicazione, oppure inviando una richiesta espressa al Promotore 
            e al seguente indirizzo di posta elettronica: <a href="mailto:privacy@glickon.com" target="_blank">privacy@glickon.com</a>.
        `
  },
  "live-privacy-3-6": {
    en: "",
    it: `
            Il trattamento viene effettuato sulla base di espresso consenso specifico da parte dell’interessato, per 
            tutte le seguenti attività: i) per il trattamento dei Dati Personali raccolti nell’esecuzione del Servizio 
            da parte di Glickon e il trasferimento di tali dati al Promotore.
        `
  },
  "live-privacy-3-7": {
    en: "",
    it: `
            Per le finalità sopra indicate i dati dell’Utente Live non saranno oggetto di trasferimento extra-UE.
        `
  },

  "live-privacy-4": {
    en: "",
    it: "Tipologia di dati trattati"
  },
  "live-privacy-4-1": {
    en: "",
    it: `
            Glickon raccoglie i dati personali forniti specificamente e volontariamente dagli utenti del Servizio. 
            I Dati Personali raccolti sono esclusivamente quelli forniti dagli utenti e riguardano (a titolo non 
            esaustivo): nome e cognome, nickname, indirizzo di residenza, indirizzo e-mail, numero di telefono, 
            formazione, professione ed immagini personali.
        `
  },
  "live-privacy-4-2": {
    en: "",
    it: `
            Per Dato Personale, inoltre, deve intendersi anche ogni informazione relativa alla partecipazione degli 
            Utenti Live ai giochi presenti sull’applicazione web, nonché i dati connessi alla navigazione (oggetto di 
            uno specifico paragrafo della presente informativa), ivi compresi browser e tipologia di device, 
            nonché giorno e ora di accesso al Servizio.
        `
  },
  "live-privacy-4-3": {
    en: "",
    it: `
            I dati raccolti non contengono dati relativi a "categorie particolari" (art. 9 GDPR) ferma restando la 
            possibilità di identificare l’origina razziale od etnica sulla base dei dati comuni conferiti (es. nome e 
            cognome) o di desumere dati relativi, ad esempio, allo stato di salute dalle immagini caricate dall’Utente 
            Live.
        `
  },
  "live-privacy-4-4": {
    en: "",
    it: `
            Glickon invita in ogni caso gli utenti a non conferire dati di natura particolare, se non strettamente necessario.
        `
  },

  "live-privacy-5": {
    en: "",
    it: "Dati di navigazione"
  },
  "live-privacy-5-1": {
    en: "",
    it: `
            I sistemi informatici preposti al funzionamento del Servizio acquisiscono, nel corso del loro normale esercizio, 
            alcuni dati personali la cui trasmissione è implicita nell’uso dei protocolli di comunicazione di Internet 
            (ad esempio, indirizzi IP dei dispositivi impiegati dagli Utenti Live, l’orario della richiesta e altri 
            parametri relativi al sistema operativo dell’Utente Live). Tali informazioni non sono raccolte per essere 
            associate a soggetti identificati, ma per loro stessa natura potrebbero, attraverso elaborazioni e 
            associazioni con ulteriori dati, permettere di identificare gli utenti. Questi dati vengono utilizzati 
            al solo fine di ricavare informazioni statistiche anonime sull’utilizzo del Servizio e per controllarne 
            il corretto funzionamento e vengono cancellati immediatamente dopo l’elaborazione. Tali dati potrebbero 
            essere utilizzati per l’accertamento di responsabilità in caso di eventuali reati informatici ai danni di 
            Glickon, suoi aventi causa o terze parti.
        `
  },

  "live-privacy-6": {
    en: "",
    it: "Uso di cookies"
  },
  "live-privacy-6-1": {
    en: "",
    it: `
            Il "cookie" è un file di testo che può essere conservato in uno spazio dedicato sul disco rigido del 
            dispositivo utilizzato dall’Utente Live (ad es. computer, tablet, smartphone, etc.) nel momento in cui l’Utente 
            live visita accede al servizio attraverso il proprio browser, e può essere soggetto al consenso dell’Utente Live. 
            Il cookie permette di identificare il dispositivo in cui è conservato il cookie stesso per tutto il 
            periodo di validità o registrazione del cookie. Durante le visite del Sito o l’accesso al Servizio, 
            le informazioni relative ai dati di navigazione ricevute dal dispositivo dell’Utente Live potrebbero essere 
            archiviate in cookies installati su tale dispositivo. Al momento della prima visita, l’Utente live sarà 
            informato in merito all’utilizzo dei cookies con una informativa contenuta in un banner. Con la prosecuzione 
            della navigazione dopo la visualizzazione del banner, l’Utente live acconsente all’uso dei cookies. Tuttavia, è 
            possibile modificare in ogni momento le impostazioni relative ai cookies. Per maggiori informazioni sull’ 
            utilizzo dei cookies, si prega di consultare la <a href="/it/piattaforma/cookies" target="_blank">Cookie Policy del Sito.</a>
        `
  },

  "live-privacy-7": {
    en: "",
    it: "Modalità di trattamento e conservazione"
  },
  "live-privacy-7-1": {
    en: "",
    it: `
            Il trattamento dei dati personali avverrà con il supporto di mezzi informatici o telematici, nel 
            rispetto della normativa vigente, in modo da garantirne la sicurezza e riservatezza. Il trattamento 
            con mezzi cartacei potrà avvenire solo in via residuale.
        `
  },
  "live-privacy-7-2": {
    en: "",
    it: `
            I dati saranno trattati per il periodo di mantenimento dell’iscrizione alla Piattaforma da parte dell’
            utente e comunque per un periodo non superiore a 3 anni dalla data delle ultime attività svolte dall’utente 
            in Piattaforma: a seguire il profilo utente sarà disattivato e i dati relativi saranno resi anonimi per 
            essere conservati a fini statistici e/o di miglioramento del servizio.
        `
  },
  "live-privacy-7-3": {
    en: "",
    it: `
            In prossimità dello scadere dei 3 anni dalle ultime attività svolte in Piattaforma, l’Utente Live sarà avvisato 
            dal sistema della prossima scadenza e sarà invitato a confermare la propria iscrizione al Servizio.
        `
  },

  "live-privacy-8": {
    en: "",
    it: "Destinatari"
  },
  "live-privacy-8-1": {
    en: "",
    it: `
            Glickon comunicherà i dati conferiti dall’Utente Live, ivi compresi i suoi Dati Personali e i risultati 
            conseguiti nei giochi al Promotore. Resta inteso, inoltre, che accedendo al Servizio e solo in caso di 
            interesse da parte del Promotore, l’Utente Live acconsente a poter essere contattato da quest’ultimo per 
            finalità relative ad attività in ambito risorse umane quali, a titolo esemplificativo ma non esaustivo: 
            incontri con studenti, attività di employer branding, colloqui di gruppo, onboarding di neoassunti, meeting 
            e presentazioni, aule formative ed eventi aziendali.
        `
  },
  "live-privacy-8-2": {
    en: "",
    it: `
            I Dati Personali saranno trasferiti e saranno trattati, limitatamente al rispettivo ambito di competenza, 
            ai soggetti nominati Responsabili del trattamento o Sub-Responsabili del trattamento (art. 28 GDPR). 
            L’elenco dei Responsabili nominati da Glickon è reperibile tramite una semplice richiesta alla casella di 
            posta elettronica: <a href="mailto:privacy@glickon.com" target="_blank">privacy@glickon.com</a>.
        `
  },
  "live-privacy-8-3": {
    en: "",
    it: `
            In relazione ai soggetti autorizzati al trattamento da parte di Glickon, il personale di Glickon accede 
            alla piattaforma del Servizio: i) [Utenti Live] per le attività di supporto e miglioramento del Servizio; 
            ii) [Promotori] esclusivamente per consentire il funzionamento del Servizio e/o per fornire supporto al 
            Promotore nel suo utilizzo e/o per migliorare il servizio offerto.
        `
  },

  "live-privacy-9": {
    en: "",
    it: "Protezione dei dati personali"
  },
  "live-privacy-9-1": {
    en: "",
    it: `
            I Dati Personali conferiti dagli Utenti Live saranno trattati nel rispetto di quanto previsto dalle vigenti 
            normative in materia di protezione dati personali e, in particolare, in linea con quanto stabilito dal GDPR 
            679/2016 più volte citato. Con particolare riguardo agli aspetti di sicurezza i dati saranno trattati con 
            adeguate misure tecniche ed organizzative, onde garantirne la riservatezza e prevenire trattamenti non 
            autorizzati o illeciti, nonché la perdita, la distruzione o il danno accidentali [art. 5, paragrafo 1 
            lettera f) e art. 32 e seguenti GDPR].
        `
  },

  "live-privacy-10": {
    en: "",
    it: "Diritti dell’interessato"
  },
  "live-privacy-10-1": {
    en: "",
    it: `
            L’Utente Live (Interessato) potrà esercitare i diritti di cui agli artt. 15 e seguenti del GDPR, 
            tra cui, in sintesi: i) ottenere la conferma che sia o meno in corso un trattamento di dati personali che 
            lo riguardano; ii) ottenere l'accesso ai suoi dati personali ed alle informazioni indicate all’art. 15 del 
            GDPR; iii) ottenere la rettifica dei dati personali inesatti che lo riguardano senza ingiustificato ritardo 
            o l'integrazione dei dati personali incompleti; iv) ottenere la cancellazione dei dati personali che lo 
            riguardano senza ingiustificato ritardo; v) ottenere la limitazione del trattamento dei dati personali che 
            lo riguardano; vi) essere informato delle eventuali rettifiche o cancellazioni o limitazioni del 
            trattamento effettuate in relazione ai dati personali che lo riguardano; vii) ricevere in un formato 
            strutturato, di uso comune e leggibile da dispositivo automatico i dati personali che lo riguardano. 
            L’Utente Live potrà esercitare i propri diritti mediante semplice richiesta inoltrata al Titolare del 
            trattamento (Glickon) al seguente indirizzo: <a href="mailto:privacy@glickon.com" target="_blank">privacy@glickon.com</a>.
        `
  },

  "live-privacy-11": {
    en: "",
    it: "Reclamo"
  },
  "live-privacy-11-1": {
    en: "",
    it: `
            Fatto salvo ogni altro ricorso amministrativo o giurisdizionale, qualora l’interessato ritenga che il 
            trattamento che lo riguarda violi le disposizioni di cui alla normativa vigente in materia di protezione 
            dati personali, viene riconosciuto il diritto di proporre reclamo avverso il trattamento effettuato dal 
            Titolare, come descritto nella presente informativa, all’Autorità Garante per la Protezione dei 
            Dati Personali (www.garanteprivacy.it).
        `
  }
}

export default translations
