import styleConstants from "./constants/styleConstants"
import {hexToRgb} from "./utils"

export const absoluteCentered = () => `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const raisable = () => `
@media (min-width: ${styleConstants.desktopMinWidth}) {
  transition: all 250ms cubic-bezier(.02, .01, .47, 1);

  &:hover {
    transform: translateY(-0.5rem);
    
    box-shadow: 0 0.9375rem 0.9375rem 0
      rgba(${hexToRgb(styleConstants.black)}, 0.1);
  }
}
`

export const notificationShadow = () => `
  box-shadow: 0 0.125rem 0.375rem 0 rgba(${hexToRgb(
    styleConstants.blue00
  )}, 0.1);
`
