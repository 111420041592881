import styled from "styled-components"
import styleConstants from "../../../../constants/styleConstants"
import SimpleImage from "../../../../commonComponents/image"
import SimpleAction from "../../../../commonComponents/action"

export const CompanyInformationsCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    position: relative;
    flex-direction: row;
  }
`

export const CompanyInformationsLogo = styled(SimpleImage).attrs({
  backgroundCover: true
})`
  height: 5rem;
  width: 5rem;
  min-width: 5rem;
  max-width: 5rem;
  margin-top: -3.5rem;
  border: 1px solid ${styleConstants.cardBorder};

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    border: none;
    height: 8rem;
    width: 8rem;
    min-width: 8rem;
    max-width: 8rem;
    margin: 0;
    border: none;
  }
`

export const CompanyInformationsContent = styled.div`
  line-height: 1.5;
  padding-top: 0.5rem;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 0;
    padding-left: 2.5rem;
  }
`

// eslint-disable-next-line no-unexpected-multiline
export const CompanyInformationsName = styled.div<{
  customColor: string | undefined
}>`
  padding-top: 0.2rem;
  font-family: ${styleConstants.galanoGrotesqueSemibold};
  font-size: ${styleConstants.font.size18};
  color: ${({customColor}) => customColor || styleConstants.blue00};

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    font-size: ${styleConstants.font.size24};
  }
`

// eslint-disable-next-line no-unexpected-multiline
export const CompanyInformationsShowMoreButton = styled(SimpleAction)<{
  customColor: string | undefined
  customTextColor: string | undefined
  customHoverColor: string | undefined
}>`
  &.company-informations-show-more-button  {
    font-size: ${styleConstants.font.size16};
  }

  margin-top: 3.5rem;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    margin-top: 0;
  }

  &.custom {
    ${({customColor, customTextColor, customHoverColor}) =>
      `
          border: none;
          background: none;
          background-color: ${customColor};
          color: ${customTextColor};

          &:hover:not([disabled]) {
            background: none !important;
            background-color: ${customHoverColor} !important;
            color: ${customTextColor} !important; 
            border: none !important;
          }
        `}
  }
`
