//@ts-nocheck
import React, {useEffect} from "react"
import TopNavigation from "../top-navigation"
import Footer from "../footer"
import Helmet from "react-helmet"
import useTranslation from "../../hooks/useTranslation"
import titles from "../../constants/titles"
import {defaultTitle, htmlTitleTemplate, locales} from "../../constants/global"
import {generateLocalizedPath} from "../../utils"
import metaDescriptions from "../../constants/metaDescriptions"
import h1s from "../../constants/h1s"
import {landingAwardKey} from "../../constants/pathKeys"
import BottomNotification from "../bottom-notification"
import {useStoreActions} from "../../store/store"

interface LocalizedSeoType {
  pageTitle: string
  metaDescription: string
  h1: string
}

interface PropsType {
  children: React.ReactNode
  pathKey: string
  withFooter?: boolean
  withNavigation?: boolean
}

const Page: React.FC<PropsType> = (props: PropsType) => {
  const {children, withFooter = true, withNavigation = true, pathKey} = props
  const [, locale] = useTranslation()
  const updateRouting = useStoreActions(actions => actions.routing.update)

  useEffect(() => {
    updateRouting({currentPathKey: pathKey})
  }, [pathKey, updateRouting])

  const {
    pageTitle = "",
    metaDescription = "",
    h1 = ""
  }: LocalizedSeoType = React.useMemo<LocalizedSeoType>(() => {
    const pageTitle =
      titles[pathKey] && titles[pathKey][locale] ? titles[pathKey][locale] : ""
    const metaDescription =
      metaDescriptions[pathKey] && metaDescriptions[pathKey][locale]
        ? metaDescriptions[pathKey][locale]
        : ""
    const h1 = h1s[pathKey] && h1s[pathKey][locale] ? h1s[pathKey][locale] : ""
    return {
      pageTitle,
      metaDescription,
      h1
    }
  }, [locale, pathKey])

  const titleTemplate =
    pathKey === landingAwardKey ? undefined : htmlTitleTemplate
  return (
    <>
      <Helmet titleTemplate={titleTemplate} defaultTitle={defaultTitle}>
        <html lang={locale} />
        <title>{pageTitle}</title>
        <meta name="description" content={metaDescription} />
        {Object.keys(locales)
          .filter((destLocale: string): boolean => destLocale !== locale)
          .map(
            (destLocale: string): React.ReactNode => {
              return (
                <link
                  rel="alternate"
                  href={generateLocalizedPath(pathKey, destLocale)}
                  hrefLang={destLocale}
                  key={destLocale}
                />
              )
            }
          )}
      </Helmet>
      {withNavigation && <TopNavigation pathKey={pathKey} />}
      <main>
        {h1 && <h1 className="hide-from-mobile-up">{h1}</h1>}
        {children}
      </main>
      {withFooter && <Footer pathKey={pathKey} />}
      <BottomNotification />
    </>
  )
}

export default Page
