import React from "react"
import { Media } from "../../../../types/media"
import { Wrapper } from "./styles"
import VideosList from "../../../../commonComponents/videos-list"
import ImagesList from "../../../../commonComponents/images-list"
import Carousel from "../../../../commonComponents/media-carousel"

type PropsType = {
    companyName: string,
    images?: Media[],
    videos?: Media[],
    carousel?: Media[]
    careersName: string | undefined
}

const GENERALI_CAREERS_NAME = "generali"

const CompanyMedia: React.FC<PropsType> = (props: PropsType) => {
    const {
        companyName,
        images = [],
        videos = [],
        carousel = [],
        careersName
    } = props

    const hasVideo = videos.length > 0

    const imagesWithoutText = images.filter((item: Media) => (!item.description && !item.title))
    const imagesWithText = images.filter((item: Media) => (!!item.description || !!item.title))

    const carouselImages = careersName === GENERALI_CAREERS_NAME ? imagesWithoutText : carousel.concat(imagesWithoutText)
    const hasCarousel = carouselImages.length > 0
    const hasImages = imagesWithText.length > 0

    const imageCarousel = !hasCarousel ? null : (
        <Carousel id={`${companyName}-carousel`} images={carouselImages} />
    )
    const videoList = !hasVideo ? null : (
        <VideosList id={`${companyName}-videos-list`} videos={videos} />
    )
    const imageList = !hasImages ? null : (
        <ImagesList id={`${companyName}-images-list`} images={imagesWithText} />
    )

    return (
        <Wrapper className="row">
            {videoList}
            {imageCarousel}
            {imageList}
        </Wrapper>
    )
}

export default CompanyMedia
