import { TranslationType } from "../types"

const translations: TranslationType = {
    "cx-j-headline-title": {
        en: "A day with...<br/>Candidate",
        it: "A day with...<br/>Candidate"
    },
    "cx-j-headline-text": {
        en: "Design the best experience for your candidates, check their skills through a game with many simple and customizable challenges, discover how many and which are the most capable candidates. And hire them. The selection process is not a game, but we make it fun, engaging and productive. Just like work should be, daily.",
        it: "Insieme a una parola chiave. Experience. Perché l’esperienza è il primo step che mette in evidenza l’appeal del tuo brand, attirando i candidati con un reale interesse verso la tua cultura aziendale. E da qui che generiamo dati per evidenziare il fit index tra il candidato e le competenze richieste, accelerando così la sua operatività e produttività nel successivo periodo di onboarding."
    },
    "cx-j-footer-headline": {
        en: "Accept the challenge, design your new Candidate Experience!",
        it: "Accetta la sfida, progetta la tua Candidate Experience!"
    },
    "cx-j-footer-caption": {
        en: "We build it together, but then you will be the one bringing new talents to the company",
        it: "La costruiamo insieme, ma sarai tu a portare nuovi talenti in azienda"
    },
    "cx-j-script-01":{
        en: "<strong>9.00<br/>‍</strong>It might seem like any day, starting with a sip of coffee and the desire to find something new.",
        it: "<strong>9.00<br/></strong>Potrebbe sembrare un qualsiasi giorno, a cominciare da unsorso di caffè e con la voglia di trovare qualcosa di nuovo."
    },
    "cx-j-script-02":{
        en: "<strong>9.30<br/></strong>Or maybe someone with the right skills for the mission?",
        it: "<strong>9.30<br/></strong>O forse qualcuno con le giuste competenze per la missione?"
    },
    "cx-j-script-03":{
        en: "<strong>10.30<br/></strong>Good news: there’s a solution out there, that can also avoid the boredom of the usual application process.",
        it: "<strong>10.30<br/></strong>Buona notizia: c'è una soluzione là fuori, che può anche evitare la noia del solito processo di candidatura."
    },
    "cx-j-script-04":{
        en: "Nothing is better than knowing exactly who you’re looking for. Or which positions you are suited for!",
        it: "Niente è meglio che sapere esattamente chi stai cercando o per quali posizioni sei adatto!"
    },
    "cx-j-script-05":{
        en: "<strong>11.30<br/></strong>Every second, a challenge is entering the network. The one that could change a life.",
        it: "<strong>11.30<br/></strong>Entra in rete una sfida ogni secondo. Quella che potrebbe cambiare una vita."
    },
    "cx-j-script-06":{
        en: "<strong>13.00<br/></strong>Oh, heading out for lunch break? Good idea, fresh air is the bearer of inspiration.",
        it: "<strong>13.00<br/></strong>Oh, stai uscendo per la pausa pranzo? Buona idea, l'aria fresca è portatrice di ispirazione."
    },
    "cx-j-script-07":{
        en: "<strong>14.30<br/></strong>And now, let’s get ready for the game to begin: that chance is out there!",
        it: "<strong>14.30<br/></strong>E ora, prepariamoci per l'inizio della partita: quella possibilità è là fuori!"
    },
    "cx-j-script-08":{
        en: "<strong>15.00<br/></strong>This is a unique experience, that eases the research for everyone involved. There is no getting those feedbacks in real life!",
        it: "<strong>15.00<br/></strong>Questa è un'esperienza unica, che facilita la ricerca per tutti i soggetti coinvolti. Non è possibile ottenere questi feedback nella vita reale!"
    },
    "cx-j-script-09":{
        en: "<strong>15.30<br/></strong>It’s just a matter of looking around and finding the right person for the mission.",
        it: "<strong>15.30<br/></strong>È solo questione di guardarsi intorno e trovare la persona giusta per la missione."
    },
    "cx-j-script-10":{
        en: "<strong>16.30<br/></strong>And before you know it, it happens.",
        it: "<strong>16.30<br/></strong>E prima che tu te ne accorga, succede."
    },
    "cx-j-script-11":{
        en: "<strong>18.00<br/></strong>The right solution can lead you – and your team – to victory!",
        it: "<strong>18.00<br/></strong>La giusta soluzione può portare te - e la tua squadra - alla vittoria!"
    },
    "cx-j-script-12":{
        en: "An engaging experience for everyone on board, ready to find what they are looking for.",
        it: "Un'esperienza coinvolgente per tutti a bordo, pronti a trovare quello che cercano."
    },
    "cx-j-script-13":{
        en: "<strong>18.30<br/></strong>Congratulations, it’s time to celebrate!",
        it: "<strong>18.30<br/></strong>Congratulazioni, è ora di festeggiare!"
    },
}

export default translations
