import {TranslationType} from "../types"

const translations: TranslationType = {
  // header-product
  "resources-top-title": {
    en: "Welcome to Glickon Experience’s manual",
    it: "Benvenut* sul manuale Glickon Experience"
  },
  "resources-top-description": {
    en:
      "Numbers, statistics, answers in order to understand that experience is an excellent investment.",
    it:
      "Numeri, statistiche, risposte per capire che l’esperienza è un ottimo investimento"
  },

  // cards
  "resources-card-1-title": {
    en: "Candidate Experience,<br/>what is it?",
    it: "Candidate Experience,<br/>che cos’è?"
  },

  "resources-card-1-description": {
    en: `
            It is the sum of the key steps in a person’s candidacy, preselection and selection with respect to a job 
            opening. The combination of these interactions generates a decisive experience for both the candidate and 
            the company.
        `,
    it: `
            È la somma dei passaggi chiave che identificano candidatura, preselezione e selezione di una persona
            rispetto a un’offerta di lavoro. L’insieme di queste interazioni genera un’esperienza determinante sia per
            il candidato sia per l’azienda.
        `
  },
  "resources-card-2-title": {
    en: "Employee Experience,<br/>what is it?",
    it: "Employee Experience,<br/>che cos’è?"
  },

  "resources-card-2-description": {
    en: `
            Employee experience refers to any interaction, from the first contact as a potential resource 
            (the selection process) to the last action performed even after the end of the employment relationship 
            (termination of contract or retirement)...
        `,
    it: `
            Per employee experience si intende qualsiasi interazione, dal primo contatto come potenziale risorsa 
            (selezione) fino all’ultima azione compiuta anche dopo la fine del rapporto di lavoro (licenziamento 
            o pensionamento)...
        `
  },
  "resources-card-3-title": {
    en: "People Analytics,<br/>what is it?",
    it: "People Analytics,<br/>che cos’è?"
  },

  "resources-card-3-description": {
    en: `
            People Analytics is the HR strategy that extrapolates and analyzes the data generated by the people in your
            company. It allows you to apply quantitative measurements to the work that employees perform during the day.
        `,
    it: `
            People Analytics è la strategia HR che estrapola e analizza i dati generati dalle persone della tua azienda.
            Permette di applicare misurazioni quantitative al lavoro che i dipendenti svolgono durante la giornata.
        `
  },

  // banner-bottom
  "resources-banner-bottom-title": {
    en: "Now it’s your turn, design your Glickon Experience!",
    it: "Ora tocca a te, disegna la tua Glickon Experience!"
  },
  "resources-banner-bottom-description": {
    en:
      "We put design and data science, you are the architect who designs talent, ideas and business",
    it:
      "Noi ci mettiamo design e data science, tu sei l’architetto che progetta talento, idee e business"
  }
}

export default translations
