import * as React from "react"
import {Container} from "./styles"
import Loader from "../loader"

interface PropsType {}

const LoaderFullScreen: React.FC<PropsType> = (props: PropsType) => {
  return (
    <Container>
      <Loader colored />
    </Container>
  )
}

export default LoaderFullScreen
