import ReactTooltip from "react-tooltip"
import styled from "styled-components"
import styleConstants from "../../constants/styleConstants"

export const Tooltip = styled(ReactTooltip as any)`
  background-color: ${styleConstants.orange} !important;
  color: ${styleConstants.white} !important;
  font-family: ${styleConstants.calibreSemibold} !important;
  font-size: ${styleConstants.font.size14} !important;
  opacity: 0 !important;
  line-height: ${styleConstants.font.size14} !important;
  text-transform: lowercase;

  &.place-top::after {
    border-top-color: ${styleConstants.orange} !important;
  }

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    opacity: 1 !important;
  }
`
