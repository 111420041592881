import {TranslationType} from "../types"

const translations: TranslationType = {
  "form-reset-password-title": {
    en: "Reset password",
    it: "Reimposta password"
  },
  "form-reset-password-description": {
    en: "Enter a new password",
    it: "Inserisci una nuova password"
  },
  "form-reset-password-label": {
    en: "New password",
    it: "Nuova password"
  },
  "form-reset-confirm-password-label": {
    en: "Repeat password",
    it: "Ripeti password"
  },
  "form-reset-confirm-password-cta": {
    en: "Reset",
    it: "Reimposta"
  },
  "form-reset-confirm-password-matching-error": {
    en: "Password must match",
    it: "Le password devono coincidere"
  }
}

export default translations
