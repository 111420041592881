import React from "react"
import {Container} from "./styles"

interface PropsType {
  children?: React.ReactNode
  id?: string
}

const NegativeFeedback: React.FC<PropsType> = (props: PropsType) => {
  const {children, id} = props

  return id ? (
    <Container id={id}>{children}</Container>
  ) : (
    <Container>{children}</Container>
  )
}

export default NegativeFeedback
