import React from "react"
import classnames from "classnames"
import {
  AboveInputWrapper,
  StyledErrorFeedback,
  StyledInput,
  StyledLabel,
  StyledTextarea,
  StyledCheckboxInput,
  StyledCheckboxLabel,
  StyledCheckbox,
  BellowInputWrapper
} from "./styles"
import useTranslation from "../../hooks/useTranslation"
import Translate from "../translate"
import { Dictionary } from "../../types"

type PropsType = React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> & {
  displayLabel?: boolean
  children?: React.ReactNode
  label?: string
  labelReplacements?: Dictionary<string | number>
  error?: string | false
}

const FormInput = (props: PropsType) => {
  const [translate] = useTranslation()

  const {
    id,
    type,
    label,
    labelReplacements,
    displayLabel = true,
    error = "",
    placeholder = "global-generic-input-placeholder",
    className,
    ...otherProps
  } = props
  const { required } = otherProps

  const inputClasses: string = classnames(className, { error: !!error })
  const showLabel: boolean = type === "checkbox" ? displayLabel : false
  const showBellowError: boolean = type === "password" ? displayLabel : false

  let placeholderText: string = translate(placeholder)
  if (required) {
    placeholderText += "*"
  }
  const generateFormElement = (): React.ReactNode => {
    switch (type) {
      case "textarea":
        return (
          <StyledTextarea
            id={id}
            name={id}
            className={inputClasses}
            placeholder={placeholderText}
            autoComplete="off"
            {...otherProps}
          />
        )
      case "checkbox":
        return (
          <StyledCheckbox>
            <StyledCheckboxInput
              id={id}
              type={type}
              className={inputClasses}
              name={id}
              {...otherProps} />
            <StyledCheckboxLabel>
              {label && (<Translate label={label} replacements={labelReplacements} />)}
            </StyledCheckboxLabel>
          </StyledCheckbox>
        )
      case "tel":
      case "text":
      case "email":
      case "password":
      default:
        return (
          <StyledInput
            id={id}
            name={id}
            className={inputClasses}
            type={type}
            placeholder={placeholderText}
            autoComplete="off"
            {...otherProps}
          />
        )
    }
  }

  return (
    <>
      <AboveInputWrapper>
        {showLabel && (
          <StyledLabel htmlFor={id} className={classnames({ error: !!error })}>
            {label && (<Translate label={label} />)}
            {required && "*"}
          </StyledLabel>

        )}
        {(showLabel && error) && (
          <StyledErrorFeedback left={!label}>{error}</StyledErrorFeedback>
        )}
      </AboveInputWrapper>
      {generateFormElement()}
      <BellowInputWrapper>
        {(showBellowError && error) && (
          <StyledErrorFeedback>{error}</StyledErrorFeedback>
        )}
      </BellowInputWrapper>
    </>
  )
}

export default FormInput
