//@ts-nocheck
import "react-app-polyfill/ie11"
import "core-js/stable"
import "regenerator-runtime/runtime"
import "intersection-observer"
import "unfetch/polyfill"
import bugsnag from "@bugsnag/js"
import bugsnagReact from "@bugsnag/plugin-react"
import React, {useState} from "react"
import ReactDOM from "react-dom"
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom"
import {generateLocalizedPath, passiveEvents} from "./utils"
import {acceptedLocales, defaultLocale} from "./constants/global"
import {homepageKey} from "./constants/pathKeys"
import GlobalState from "./globalStateContext"
import useGlobalState from "./hooks/useGlobalState"
import useViewportFix from "./hooks/useViewportFix"
import Routes from "./routes"
import LoaderFullScreen from "./commonComponents/loader-full-screen"
import {AxiosError, AxiosResponse} from "axios"
import {SiteDataType} from "./types"
import {getAuthInfo, getCSRFToken, getSiteData} from "./requests/init"
import BottomNotificationContext from "./bottomNotificationContext"
import {StoreProvider} from "easy-peasy"
import {store, AuthState, useStoreActions} from "./store/store"
import Modal from "react-modal"
import {Helmet} from "react-helmet"

passiveEvents.update()
Modal.setAppElement("#root")

interface PropsType {}

const App: React.FC<PropsType> = (props: PropsType) => {
  useViewportFix()

  const updateAuth = useStoreActions(actions => actions.auth.update)
  const [globalState, updateGlobalState] = useGlobalState()
  const [
    bottomNotificationMessageLabel,
    setBottomNotificationMessageLabel
  ] = useState<string | undefined>(undefined)

  React.useEffect(() => {
    getCSRFToken(
      async() => {
        let tenant = ""
        let section = {}
        if (
          window.location.href.includes("sezioni") ||
          window.location.href.includes("sections")
        ) {
          console.log("fetching section")
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/candidate/latest/sections/${
              window.location.href.includes("sezioni/")
                ? window.location.href.split("sezioni/")[1]
                : window.location.href.split("sections/")[1]
            }?from_www=true`
          )
          const section_response = await response.json()
          section = section_response
        }

        if (
          window.location.href.includes("employees") ||
          window.location.href.includes("dipendenti")
        ) {
          tenant = "employee"
        } else {
          tenant = "candidate"
        }
        console.log(
          "fetching section tenant",
          section && section.for_employees ? "employee" : tenant
        )
        await getAuthInfo<AuthState>(
          section && section.for_employees ? "employee" : tenant,
          (res: AxiosResponse<AuthState>) => {
            if (
              window.location.href.includes("sezioni") ||
              window.location.href.includes("sections")
            ) {
              const redirect = `/candidate/${
                window.location.href.includes("sezioni/")
                  ? "sezioni"
                  : "sections"
              }/${
                window.location.href.includes("sezioni/")
                  ? window.location.href.split("sezioni/")[1]
                  : window.location.href.split("sections/")[1]
              }`
              console.log("redirecting to section ", redirect)
              window.location.href = redirect
            } else if (window.location.href.includes("reset-password") || window.location.href.includes("login")) {
                return
            } else {
              console.log("redirecting to candidate")
              window.location.href = "/candidate"
            }
          },
          (error: AxiosError) => {
            console && console.log(error)
          }
        )
        await getSiteData<SiteDataType>(
          (res: AxiosResponse<SiteDataType>) => {
            updateGlobalState({
              ...res.data,
              loading: false
            })
          },
          (error: AxiosError) => {
            console && console.log(error)
            updateGlobalState({
              dictionary: {},
              caseStudies: [],
              press: [],
              loading: false
            })
          }
        )
      },
      (error: AxiosError) => {
        console && console.log(error)
      }
    )
  }, [updateAuth, updateGlobalState])

  if (globalState.loading) {
    return <LoaderFullScreen />
  }

  return (
    <GlobalState.Provider value={globalState}>
      <Helmet>
        <link
          rel="icon"
          type="image/png"
          href="https://storage.googleapis.com/glickon-dev-assets/assets/global/img/favicon.ico"
        />
      </Helmet>
      <BottomNotificationContext.Provider
        value={{
          messageLabel: bottomNotificationMessageLabel,
          setMessageLabel: setBottomNotificationMessageLabel
        }}
      >
        <Router>
          <Switch>
            <Route path={`/:locale(${acceptedLocales})`} component={Routes} />
            <Redirect
              to={`${generateLocalizedPath(homepageKey, defaultLocale)}`}
            />
          </Switch>
        </Router>
      </BottomNotificationContext.Provider>
    </GlobalState.Provider>
  )
}

const bugsnagClient = bugsnag(process.env.REACT_APP_BUGSNAG_ID || "xxx")
bugsnagClient.use(bugsnagReact, React)
const ErrorBoundary = bugsnagClient.getPlugin("react")

ReactDOM.render(
  <ErrorBoundary>
    <StoreProvider store={store}>
      <App />
    </StoreProvider>
  </ErrorBoundary>,
  document.getElementById("root")
)
