import React, {useEffect, useContext, useState} from "react"
import {Container} from "./styles"
import Translate from "../translate"
import bottomNotificationContext from "../../bottomNotificationContext"

const BottomNotification: React.FC = () => {
  const {messageLabel, setMessageLabel} = useContext(bottomNotificationContext)
  const [isAnimating, setIsAnimating] = useState<boolean>()

  const messageDurationMilliseconds = 10000

  useEffect(() => {
    if (!messageLabel) return

    setTimeout(() => setIsAnimating(true), messageDurationMilliseconds - 1000)
    setTimeout(() => setMessageLabel(undefined), messageDurationMilliseconds)
  }, [messageLabel, setMessageLabel])

  return messageLabel ? (
    <Container haveOpacity={!isAnimating}>
      <div className="row">
        <div className="column auto">
          <div>
            <Translate label={messageLabel} />
          </div>
        </div>
      </div>
    </Container>
  ) : null
}

export default BottomNotification
