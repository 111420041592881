import React from "react"
import styled from "styled-components"
import styleConstants from "../../constants/styleConstants"
import {notificationShadow, absoluteCentered} from "../../mixins"
import SimpleImage from "../image"

export const Flag = ({
  src,
  onClick,
  invisible,
  inList,
  isFirst,
  isLast
}: {
  src: string
  onClick: () => void
  invisible?: boolean
  inList?: boolean
  isFirst?: boolean
  isLast?: boolean
}) => (
  <FlagWrapper
    onClick={onClick}
    inList={inList}
    isFirst={isFirst}
    isLast={isLast}
    style={{visibility: invisible ? "hidden" : "visible"}}
  >
    <FlagInner src={src} localize={false} />
  </FlagWrapper>
)

export const LanguageSelectorContainer = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    top: 3.75rem;
    right: calc(50% + 3.75rem);
  }
`

export const FlagListContainer = styled.div`
  ${flagContainer}
  ${notificationShadow}
  position: absolute;
  background: ${styleConstants.white};
  right: 0;
`

// eslint-disable-next-line no-unexpected-multiline
const FlagWrapper = styled.div<{
  inList?: boolean
  isFirst?: boolean
  isLast?: boolean
}>`
  margin-left: ${({inList}) => (inList ? "0" : "1.5rem")};
  ${flagContainer}
  position: relative;
  background: ${({inList}) =>
    inList ? "none" : styleConstants.languageSelectorColor};
  height: 2.25rem;
  display: block;

  &:hover {
    background: ${styleConstants.languageSelectorColor};
  }

  ${({inList, isFirst}) =>
    inList && !isFirst
      ? "border-top-left-radius: 0; border-top-right-radius:0;"
      : ""}

  ${({inList, isLast}) =>
    inList && !isLast
      ? "border-bottom-left-radius: 0; border-bottom-right-radius:0;"
      : ""}
`

const FlagInner = styled(SimpleImage)`
  height: 1.25rem;
  width: 2rem;
  ${absoluteCentered}
`

function flagContainer() {
  return `
    cursor: pointer;
    border-radius: 4px;
    width: 3.5rem;
  `
}
