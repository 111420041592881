import styled, {css} from "styled-components"
import styleConstants from "../../constants/styleConstants"
import {AccessUserType} from "../../types/accessUser"

const getAccessBackground = (
  hideBackground?: boolean,
  backgroundUrl?: string
): string => {
  return !!hideBackground
    ? "none"
    : !backgroundUrl
    ? "linear-gradient(to right, #15b3f3, #5b47c7 32%, #d54286 67%, #fa6345 100%)"
    : `url('${backgroundUrl}')`
}

// eslint-disable-next-line no-unexpected-multiline
export const Container = styled.div<{
  userType: AccessUserType
  backgroundUrl?: string
  hasCustomBackground?: boolean
  hideBackground?: boolean
  hideBottomPadding: boolean
  customBodyBackgroundColor?: string
}>`
  position: relative;
  min-height: 100vh; /*fallback*/
  min-height: ${props =>
    props.hideBottomPadding ? 0 : "calc((var(--realVh, 1vh) * 100))"};
  padding: 0.7rem 0 3rem 0;

  &::before {
    z-index: -1;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${props =>
      props.customBodyBackgroundColor
        ? props.customBodyBackgroundColor
        : styleConstants.white};

    @media (min-width: ${styleConstants.desktopMinWidth}) {
      right: 50%;
    }
  }

  &::after {
    display: none;
    z-index: -1;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background: ${props =>
      getAccessBackground(props.hideBackground, props.backgroundUrl)};

    @media (min-width: ${styleConstants.desktopMinWidth}) {
      display: block;
      left: 50%;
    }
  }
`

export const Title = styled.div<{
  alignCenter: boolean
  customColor?: string
}>`
  font-family: ${styleConstants.galanoGrotesqueMedium};
  color: ${props =>
    props.customColor ? props.customColor : styleConstants.blue00};
  font-size: ${styleConstants.font.size26};
  padding-bottom: 0.625rem;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    padding-bottom: 0.5rem;
    font-size: ${styleConstants.font.size32};
  }
  ${props =>
    props.alignCenter
      ? css`
          text-align: center;
        `
      : ""};
`

export const Description = styled.div<{
  alignCenter: boolean
  customColor?: string
}>`
  font-family: ${styleConstants.calibre};
  font-size: ${styleConstants.font.size18};
  color: ${props =>
    props.customColor ? props.customColor : styleConstants.dark01};
  margin-bottom: 2rem;
  @media (min-width: ${styleConstants.desktopMinWidth}) {
    font-size: ${styleConstants.font.size20};
  }
  ${props =>
    props.alignCenter
      ? css`
          text-align: center;
          @media (max-width: ${styleConstants.desktopMinWidth}) {
            margin-bottom: 1rem;
          }
        `
      : ""};
`

export const ChangeForm = styled.div`
  text-align: center;
  font-family: ${styleConstants.calibre};
  font-size: ${styleConstants.font.size16};
  color: ${styleConstants.dark02};
  margin-top: 1.5rem;

  .action {
    cursor: pointer;
    font-family: ${styleConstants.calibreSemibold};
    color: ${styleConstants.dark01};
  }
`

export const Email = styled.div`
  font-family: ${styleConstants.calibre};
  font-size: ${styleConstants.font.size20};
  color: ${styleConstants.dark01};
  margin-bottom: 2rem;
  padding: 0 1rem;
`
export const FormWrapper = styled.div<{
  directionRow?: boolean
  customTextColor?: string
}>`
  ${props =>
    props.directionRow
      ? css`
          display: flex;
          justify-content: space-between;
          @media (max-width: ${styleConstants.desktopMinWidth}) {
            flex-direction: column;
          }
          #social-login {
            width: 100%;
            @media (min-width: ${styleConstants.desktopMinWidth}) {
              width: calc(50% - 2rem);
            }
            #social-subtitle {
              margin-bottom: 1rem;
              font-family: Calibre;
              font-size: 20px;
              color: ${styleConstants.dark01};
              @media (max-width: ${styleConstants.desktopMinWidth}) {
                display: none;
              }
            }
          }
          #acces-form {
            width: 100%;
            @media (min-width: ${styleConstants.desktopMinWidth}) {
              width: calc(50% - 2rem);
            }
            #access-subtitle {
              margin-bottom: 1rem;
              font-family: Calibre;
              font-size: 20px;
              color: ${styleConstants.dark01};
              @media (max-width: ${styleConstants.desktopMinWidth}) {
                display: none;
              }
            }
          }
          #lost-password-form,
          #lost-password-done,
          #resend-email,
          #resend-email-done {
            width: 100%;
          }
        `
      : ""};
`
export const Divider = styled.div`
  @media (min-width: ${styleConstants.desktopMinWidth}) {
    width: 1px;
    height: 100%;
    background-image: linear-gradient(to top, #ffffff, #dedede);
  }
`

export const MSSSOWrapper = styled.div`
  .divider-wrapper {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
    align-items: center;
  }

  .divider-side {
    width: 40%;
    background-color: #eaecf0;
    height: 1px;
  }

  .divider-mid {
    width: 20%;
    text-align: center;
    font-family: "Calibre", sans-serif;
  }

  .sso-button {
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 4px;
    border: 1px solid #d0d5dd;
    border-radius: 8px;

    &:hover {
      cursor: pointer;
    }
  }

  .sso-text {
    font-weight: normal;
    color: #344054;
    font-family: "Calibre", sans-serif;
    margin-bottom: 10px;
    margin-top: 10px;
  }
`
