import React from "react"
import {passiveEvents} from "../utils"

type HookReturnType = [React.RefObject<HTMLElement>, boolean]

const useIsBelowScroll = (offset: number = 0): HookReturnType => {
  const [isBelowScroll, setIsBelowScroll] = React.useState<boolean>(false)
  const ref: React.RefObject<HTMLElement> = React.useRef<HTMLElement>(null)

  const handler = React.useCallback(() => {
    if (ref.current) {
      const documentHeight: number =
        window.innerHeight || document.documentElement.clientHeight
      const elementTop: number = ref.current.getBoundingClientRect().top
      setIsBelowScroll(elementTop - offset <= documentHeight)
    }
  }, [offset, ref])

  React.useLayoutEffect(() => {
    const options = passiveEvents.hasSupport
      ? {
          capture: false,
          passive: true
        }
      : false
    handler()
    window.addEventListener("scroll", handler, options)
    window.addEventListener("resize", handler, options)
    return () => {
      window.removeEventListener("scroll", handler, options)
      window.removeEventListener("resize", handler, options)
    }
  }, [handler, ref])

  return [ref, isBelowScroll]
}

export default useIsBelowScroll
