import styled from "styled-components"
import styleConstants from "../../../constants/styleConstants"

// eslint-disable-next-line no-unexpected-multiline
export const CompanySectionDescription = styled.div<{
  customColor: string | undefined
}>`
  font-family: ${styleConstants.calibre};
  font-size: ${styleConstants.font.size18};
  color: ${({customColor}) =>
    customColor || styleConstants.sectionDescriptionGray};

  p {
    margin-top: 0;
  }

  p {
    line-height: normal;
    margin-top: 0;
    font-size: ${styleConstants.font.size18} !important;
  }

  p span {
    line-height: normal;
    font-size: ${styleConstants.font.size18} !important;
  }
`

// eslint-disable-next-line no-unexpected-multiline
export const CompanySectionDescriptionCard = styled.div<{
  customColor: string | undefined
  customBorderColor: string | undefined
}>`
  box-sizing: border-box;
  padding: 1.75rem 1.125rem;
  border-radius: ${styleConstants.defaultBorderRadius};
  border: 0.0625rem solid
    ${({customBorderColor}) => customBorderColor || styleConstants.cardBorder};
  background-color: ${({customColor}) => customColor || styleConstants.white};
  width: 100%;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    padding: 2rem 1.75rem;
  }
`

export const CompanySectionDescriptionCardWrapper = styled.div`
  transform: translateY(-1rem);

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    transform: translateY(-3rem);
  }
`
