import React from "react"
import {CompanyChallenge} from "../../../../types/companyChallenge"
import {
  CompanyChallengeInformationsCard as Card,
  CompanyChallengeInformationsLogo as Logo,
  CompanyChallengeInformationsContent as Content,
  CompanyChallengeInformationsCompanyInfo as CompanyInfo,
  CompanyChallengeInformationsName as Name,
  CompanyChallengeInformationsDescription as Description,
  CompanyChallengeInformationsActions as Actions,
  CompanyChallengeInformationsShareLinksWrapper as ShareLinksWrapper
} from "./style"
import Translate from "../../../../commonComponents/translate"
import ShareLinks from "../company-challenge-share-links"
import {getCandidateImageUrl} from "../../../../utils"

interface Props {
  companyChallenge: CompanyChallenge
  customTitleColor: string | undefined
  customSubtitleColor: string | undefined
  isPublic: boolean
}

const CompanyChallengeInformations: React.FC<Props> = ({
  companyChallenge: {logoUrl, companyName, name, location, hash},
  customSubtitleColor,
  customTitleColor,
  isPublic
}: Props) => (
  <Card>
    <Logo
      rounded
      src={
        logoUrl ||
        getCandidateImageUrl("placeholders/placeholder-icon-company.png")
      }
      localize={false}
    />
    <Content>
      <CompanyInfo customColor={customSubtitleColor}>{companyName}</CompanyInfo>
      <Name
        customColor={customTitleColor}
        dangerouslySetInnerHTML={{__html: name}}
      />
      <Description dangerouslySetInnerHTML={{__html: location || ""}} />

      <Actions>
        {isPublic && (
          <ShareLinksWrapper>
            <div>
              <Translate label={"global-share"}></Translate>:
            </div>
            <ShareLinks
              id={`companyChallenge-${hash}-share-links`}
              className="companyChallenge-share-links"
              hash={hash}
              companyName={companyName}
              challengeName={name}
            />
          </ShareLinksWrapper>
        )}
      </Actions>
    </Content>
  </Card>
)

export default CompanyChallengeInformations
