import styled from "styled-components"
import styleConstants from "../../../constants/styleConstants"

export const ExternalPageTopBarWrapper = styled.div<{isPublic: boolean}>`
  height: ${styleConstants.externalPageTopBarMobileHeight};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${({isPublic}) =>
    isPublic ? styleConstants.glickonBlack : styleConstants.white};
  width: 100%;
  z-index: 100; // over social facebook connect;
  transition: height 0.3s ease;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    height: ${styleConstants.topBarHeight};
  }
`

export const ExternalPageTopBarInnerWrapper = styled.div`
  height: 100%;

  @media (max-width: ${styleConstants.desktopMinWidth}) {
    padding: 0 calc((100% / 15) / 2);
  }
`

export const ExternalPageTopBarLeftWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 0%;
  height: 100%;
`

export const ExternalPageTopBarLogoWrapper = styled.a`
  line-height: 1.5;
`

export const ExternalPageTopBarRightWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const ExternalPageTopBarLogo = styled.img`
  flex-shrink: 0; // reset shrink because of ie11 bug
  max-width: 100%;
  max-height: 2rem;
  vertical-align: middle;
`

// eslint-disable-next-line no-unexpected-multiline
export const ExternalPageTopBarLoginButton = styled.div<{
  isPublic: boolean
  transparent?: boolean
  hideOnMobile?: boolean
}>`
  width: 9rem;
  height: 2.5rem;
  border-radius: 0.25rem;
  background-color: ${({transparent, isPublic}) =>
    transparent
      ? "transparent"
      : isPublic
      ? styleConstants.white
      : styleConstants.blue00};
  display: ${({hideOnMobile}) => (hideOnMobile ? "none" : "flex")};
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    display: flex;
  }
`

// eslint-disable-next-line no-unexpected-multiline
export const ExternalPageTopBarLoginButtonText = styled.div<{
  isPublic: boolean
  transparent?: boolean
}>`
  font-family: ${styleConstants.galanoGrotesqueSemibold};
  font-size: ${styleConstants.font.size14};
  letter-spacing: 1px;
  color: ${({transparent, isPublic}) =>
    (transparent && isPublic) || (!transparent && !isPublic)
      ? styleConstants.white
      : styleConstants.blue00};
  text-transform: uppercase;
`
