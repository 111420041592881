import React from "react"
import {Email} from "../access-login/style"
import SimpleAction from "../action"
import Translate from "../translate"
import {AccessUserType} from "../../types/accessUser"

interface PropsType {
  userType: AccessUserType
  email: string
  showLoginForm: () => void
}

const AccessResendEmailDone: React.FC<PropsType> = ({
  showLoginForm,
  email,
  userType
}: PropsType) => {
  return (
    <div>
      <Email>{email}</Email>
      <button onClick={showLoginForm} className="button-unstyled full-width">
        <SimpleAction
          fullWidth
          size={SimpleAction.sizes.large}
          variant={
            userType === AccessUserType.Candidate
              ? SimpleAction.variants.blueGlickonNoHover
              : userType === AccessUserType.Employee
              ? SimpleAction.variants.lightblueGlickon
              : SimpleAction.variants.dark
          }
        >
          <Translate label={`form-${userType}-back-to-login`} />
        </SimpleAction>
      </button>
    </div>
  )
}
export default AccessResendEmailDone
