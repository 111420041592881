import React from "react"
import {CompanySectionInfo} from "../../../../types/companySectionInfo"
import {
  CompanySectionsListWrapper as SectionsWrapper,
  CompanySectionsListItemWrapper as SectionWrapper
} from "./style"
import Section from "../company-section-item"

interface Props {
  sections: CompanySectionInfo[]
}

const CompanySectionsList: React.FC<Props> = ({sections}: Props) => (
  <SectionsWrapper id="company-details-sections" className="row">
    {sections.map(section => (
      <SectionWrapper key={section.id}>
        <Section {...section}></Section>
      </SectionWrapper>
    ))}

    {/* Empty Wrapper to have a layout without holes */}
    {Array.from({length: sections.length % 3}).map((_, i) => (
      <SectionWrapper
        hideOnMobile
        key={`company-details-section-empty-${i}`}
      ></SectionWrapper>
    ))}
  </SectionsWrapper>
)

export default CompanySectionsList
