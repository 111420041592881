import { TranslationType } from "../types"

const translations: TranslationType = {
    "general-tos-title": {
        en: "General Terms and Conditions for Glickon services",
        it: "Termini e Condizioni generali per i servizi Glickon"
    },
    "general-tos-last-modified": {
        en: "Milan, 26th November 2020",
        it: "Milano, 26 Novembre 2020"
    },
    "general-tos-pre-list-text": {
        en: "These general terms and conditions (<strong>‘T&C’</strong>) govern the provision of products or services by Glickon Ltd (<strong>‘Glickon’</strong>) to each client (<strong>‘Client’</strong>) who has signed a specific purchase order (<strong>‘Order’</strong>). These T&C are stipulated between Glickon and the Client (individually a <strong>‘Party’</strong> and together the <strong>‘Parties’</strong>), as identified below, and their full acceptance is a necessary condition in order to use the Glickon Platform and the Services provided by Glickon, as better defined below. These T&C form an integral and substantial part of each Order, where they are expressly referred to. For this reason, the signing of any Order for the provision of Glickon services determines the unconditional and full acceptance of these T&C by the Client.",
        it: "I presenti termini e condizioni generali (<strong>‘Condizioni Generali’</strong>) disciplinano la fornitura di prodotti o servizi da parte di Glickon S.r.l. (<strong>‘Glickon’</strong>) a ciascun cliente (<strong>‘Cliente’</strong>) che abbia sottoscritto apposito ordine di acquisto (<strong>‘Ordine’</strong>). Queste Condizioni Generali sono stipulate tra Glickon e il Cliente (ciascuna una <strong>‘Parte’</strong> e, insieme, le <strong>‘Parti’</strong>), come più avanti identificati, e la loro integrale accettazione è condizione necessaria per l’utilizzo della Piattaforma Glickon e dei Servizi forniti da Glickon, come meglio definiti di seguito. Le presenti Condizioni Generali costituiscono parte integrante e sostanziale di ciascun Ordine, in cui sono espressamente richiamate. Ne consegue che la sottoscrizione di qualsiasi Ordine per la fornitura di servizi Glickon determina accettazione incondizionata ed integrale delle presenti Condizioni Generali da parte del Cliente."
    },
    "general-tos-1": {
        en: "Definitions",
        it: "Definizioni"
    },
    "general-tos-1-1": {
        en: "Terms used in these T&C with initial capital letter, including those in the Order unless otherwise defined, shall have the following meanings, which shall apply both in the singular and plural:",
        it: "I termini utilizzati nelle presenti Condizioni Generali con iniziale maiuscola, compresi quelli riportati nell’Ordine se non diversamente definiti, avranno il significato qui di seguito indicato, valido sia al singolare che al plurale:"
    },
    "general-tos-1-2": {
        en: "<strong>Account</strong> means one or more holders of personalized credentials to log in to the Dashboard;",
        it: "<strong>Account</strong> indica uno o più titolari di credenziali personalizzate per l’accesso alla Dashboard;"
    },
    "general-tos-1-3": {
        en: "<strong>API Integration</strong> means a set of instructions and programming standards for accessing and integrating the Glickon Platform and the Dashboard into the Client’s management system/database;",
        it: "<strong>Challenge</strong> indica la sfida, basata su Test Glickon, inserita sulla Piattaforma direttamente da Glickon, alla quale possono partecipare tutti gli Utenti, da soli o in competizione tra di loro, volta a valutare la conoscenza di una determinata materia; "
    },
    "general-tos-1-4": {
        en: "<strong>Challenge</strong> means a challenge, based on Glickon Tests, posted on the Platform directly by Glickon, in which all Clients may participate, alone or in competition with each other, aimed at assessing the knowledge of a specific subject;",
        it: "<strong>Challenge Aziendale</strong> indica una sezione della Piattaforma organizzata dal Cliente e che può includere Contenuto e Test, al fine di ingaggiare gli Utenti in sfide in relazione ad uno Scopo Aziendale; "
    },
    "general-tos-1-5": {
        en: "<strong>Client</strong> means the company or other legal entity who accepts these T&C when subscribing the Order;",
        it: "<strong>Cliente</strong> indica la società o altra persona giuridica che accetta le presenti Condizioni Generali al momento della sottoscrizione dell’Ordine; "
    },
    "general-tos-1-6": {
        en: "<strong>Company Challenge</strong> means a section of the Platform, organized by the Client, which may include Content and/or Tests, in order to engage Users in challenges related to a Company Purpose;",
        it: "<strong>Company Page</strong> indica la pagina profilo del Cliente in cui lo stesso può inserire il Contenuto finalizzato a realizzare lo Scopo Aziendale sulla Piattaforma; "
    },
    "general-tos-1-7": {
        en: "<strong>Company Page</strong> means the Client profile page in which the Client can place Content aimed at achieving the Company Purpose on the Platform;",
        it: "<strong>Condizioni d’Uso della Piattaforma</strong> indicano i termini e condizioni per l’utilizzo della Piattaforma da parte dell’Utente, così come disponibili e di volta in volta aggiornati ad esclusiva discrezione di Glickon al seguente <a href=\"https://www.glickon.com/it/piattaforma/termini-e-condizioni\" target=\"_blank\" rel=\"noreferrer noopener\">link</a> (per il servizio Candidate Experience) e al seguente <a href=\"https://www.glickon.com/it/piattaforma/termini-e-condizioni\" target=\"_blank\" rel=\"noreferrer noopener\">link</a> (per il servizio Employee Experience); "
    },
    "general-tos-1-8": {
        en: "<strong>Company Purpose</strong> means the activities the Platform can be used for by the Client, including but not limited to employer branding, talent acquisition, onboarding, training & development, intern communication, events;",
        it: "<strong>Contenuto</strong> indica, a titolo esemplificativo e non esaustivo, testo, immagini, grafiche, video, audio caricati sulla Piattaforma ed utilizzati dal Cliente per ingaggiare gli Utenti e/o valutarne le competenze in esecuzione di uno Scopo Aziendale. Detto Contenuto può essere creato dal Cliente (<strong>‘Contenuto Cliente’</strong>) o da Glickon (<strong>‘Contenuto Glickon’</strong>); "
    },
    "general-tos-1-9": {
        en: "<strong>Contact Person</strong> means the physical person identified by the Client in the Order for the purpose of receiving Client’s Account login credentials and to whom all official communications will be addressed; ",
        it: "<strong>Corrispettivo</strong> indica l’importo pagato dal Cliente a Glickon per accedere alla Piattaforma e usufruire dei Servizi forniti da Glickon ai sensi delle presenti Condizioni Generali e dell’Ordine; "
    },
    "general-tos-1-10": {
        en: "<strong>Content</strong> means, but is not restricted to, text, images, graphics, video, audio and any other information or data uploaded to the Platform and used by the Client in order to engage Users and/or evaluate their skills in connection with the Company Purpose. This Content may be created by the Client (<strong>‘Client Content’</strong>) or by Glickon (<strong>‘Glickon Content’</strong>);",
        it: "<strong>Dashboard</strong> indica la piattaforma gestionale che permette al Cliente la modifica della Company Page, la creazione/inserimento di Contenuto in autonomia e la visualizzazione dei Risultati; "
    },
    "general-tos-1-11": {
        en: "<strong>Dashboard</strong> means the management platform that allows the Client to edit the Company Page, create/insert Content independently and view the Results;",
        it: "<strong>Glickon</strong> indica Glickon S.r.l., società di diritto italiano con sede legale in Milano, via Nino Bixio 7, 20129 Milano, P.IVA/C.F./n. di iscrizione al registro delle imprese di Milano 08573820969;"
    },
    "general-tos-1-12": {
        en: "<strong>Fee</strong> means the amount paid by the Client to Glickon to log in to the Platform and use the Services provided by Glickon pursuant to these T&C and the Order;",
        it: "<strong>Integrazione API</strong> indica un set di istruzioni e standard di programmazione per l’accesso e l’integrazione della Piattaforma Glickon e della Dashboard nel sistema gestionale/database del Cliente;"
    },
    "general-tos-1-13": {
        en: "<strong>Glickon</strong> means Glickon S.r.l., a company incorporated under the laws of Italy with registered office in Milan, via Nino Bixio 7, tax code/VAT number/registration number in Milan’s commercial register 0857382096;",
        it: "<strong>LIVE Game</strong> indica il gioco a quiz a cui l’Utente LIVE è invitato a partecipare utilizzando la Piattaforma al seguente URL: live.glickon.com;"
    },
    "general-tos-1-14": {
        en: "<strong>Glickon Services</strong> means Glickon's services and products described in a separate document, as available and time to time updated at Glickon's sole discretion at the following link (<strong>‘Glickon Services Description’</strong>). The Services Description, together with these T&C, forms an integral part of each Order;",
        it: "<strong>\"Modalità White Label\"</strong> indica la possibilità per il Cliente di usufruire di Servizi Glickon attraverso una versione riservata della Piattaforma e, pertanto, destinati esclusivamente ad Utenti Privati;"
    },
    "general-tos-1-15": {
        en: "<strong>LIVE game</strong> means the quiz game the LIVE User is invited to participate in, by using the Platform at the following URL: live.glickon.com;",
        it: "<strong>Nickname</strong> nome fittizio scelto da un Utente LIVE che ne permette la partecipazione al LIVE Game senza dover fornire dati personali;"
    },
    "general-tos-1-16": {
        en: "<strong>LIVE Game Results</strong> means the game information and any personal data collected from each LIVE User including, but not limited to, first name, last name, email, phone number;",
        it: "<strong>Ordine</strong> indica qualsiasi modulo d’ordine per la fornitura di Servizi Glickon sottoscritto dalle Parti. Ciascun Ordine costituisce l’accordo commerciale e vincolante tra Glickon e il Cliente e stabilisce, a titolo esemplificativo: il tipo di Servizi, la durata e il Corrispettivo oggetto della relativa fornitura, nonché tutte le condizioni particolari eventualmente connesse alla medesima. Ciascun Ordine incorpora le Condizioni Generali ed è soggetto alle disposizioni ivi contenute; "
    },
    "general-tos-1-17": {
        en: "<strong>LIVE User</strong> means any physical person who uses the Platform in order to participate in a LIVE Game without accessing the other Platform functionalities;",
        it: "<strong>Piattaforma</strong> indica la piattaforma proprietaria di Glickon, accessibile su www.glickon.com, attraverso cui Utenti / Utenti LIVE vengono ingaggiati dal Cliente, o direttamente da Glickon per conto di quest’ultimo, su uno specifico Contenuto o Challenge Aziendale. Gli Utenti possono svolgere Test per puro divertimento o per partecipare a Challenge Aziendali lanciate dal Cliente. La Piattaforma è disponibile in versione desktop accessibile attraverso i più comuni web-browser (Google Chrome v24 o superiore, Safari v9 o superiore, Firefox v23 o superiore, Internet Explorer v11 o superiore);"
    },
    "general-tos-1-18": {
        en: "<strong>Nickname</strong> is a fake name chosen by a LIVE User that allows the User to participate in the LIVE Game without having to provide personal data;",
        it: "<strong>Privacy Policy</strong> indica la privacy policy di Glickon, come disponibile e di volta in volta aggiornata ad esclusiva discrezione di Glickon al seguente <a href=\"https://www.glickon.com/it/piattaforma/privacy\" target=\"_blank\" rel=\"noreferrer noopener\">link</a>;"
    },
    "general-tos-1-19": {
        en: "<strong>Order</strong> means any order form for the provision of Glickon’s Services signed by the Parties. Each Order represents the commercial and binding agreement between Glickon and the Client and establishes, by way of example: the type of Services, the duration and the Fee related to the relevant supply, as well as any special conditions that may be attached thereto. Each Order integrates the T&C and is subject to the provisions contained therein; ",
        it: "<strong>Referente</strong> indica la persona fisica identificata dal Cliente nell’Ordine al fine di ricevere le credenziali di accesso degli Account del Cliente stesso e a cui saranno indirizzate tutte le comunicazioni ufficiali;"
    },
    "general-tos-1-20": {
        en: "<strong>Platform</strong> means Glickon’s platform, accessible at the URL www.glickon.com, on which Users/LIVE Users are engaged by the Client, or by Glickon on behalf of the latter, in relation to a specific Content or Company Challenges. The Users may conduct a Test for fun or in order to participate in Company Challenges launched by the Client. The Platform is available in a desktop version accessible through the most common web-browsers (Google Chrome v24 or higher; Safari v9 or higher; Firefox v23 or higher, Internet Explorer v11 or higher);",
        it: "<strong>Reportistiche</strong> indica l’output delle attività di analisi, gestione ed archiviazione dati svolte da Glickon e finalizzate alla descrizione e predizione di competenze specifiche sulla base delle informazioni raccolte in connessione alle attività e alle interazioni eseguite dagli Utenti all’interno della Piattaforma;"
    },
    "general-tos-1-21": {
        en: "<strong>Platform Terms of Use</strong> means the terms and conditions for the use of the Platform by the User, as available and as updated from time to time at Glickon’s sole discretion at the following <a href=\"https://www.glickon.com/en/platform/terms-and-conditions-cx\" target=\"_blank\" rel=\"noopener noreferrer\">link</a> (for the Candidate Experience service) and at the following <a href=\"https://www.glickon.com/en/platform/terms-and-conditions-ex\" target=\"_blank\" rel=\"noopener noreferrer\">link</a> (for the Employee Experience service);",
        it: "<strong>Risultati</strong> indica le seguenti informazioni relative a ciascun Utente, nella misura in cui siano fornite da quest’ultimo: punteggio ottenuto nelle Challenge/Challenge Aziendale, documenti elettronici, file audio/video e informazioni personali quali, a titolo esemplificativo, nome, cognome, data di nascita, luogo di residenza, titolo di studio, e-mail, numero di telefono e <i>curriculum vitae</i>;"
    },
    "general-tos-1-22": {
        en: "<strong>Privacy Policy</strong> means Glickon’s privacy policy, as available and from time to time upgraded at Glickon’s sole discretion at the following <a href=\"https://www.glickon.com/en/platform/privacy\" target=\"_blank\" rel=\"noopener noreferrer\">link</a>;",
        it: "<strong>Risultati LIVE Game</strong> indica le informazioni relative al gioco e i dati personali eventualmente raccolti da ciascun Utente LIVE tra cui, nome, cognome, e-mail, numero di telefono; "
    },
    "general-tos-1-23": {
        en: "<strong>Private User</strong> means a User who participates in a Company Challenge in White Label Mode and who does not have access to the other Platform functionalities. ",
        it: "<strong>Scopo Aziendale</strong> indica le attività per cui la Piattaforma può essere utilizzata dal Cliente, tra cui, a titolo esemplificativo e non esaustivo, <i>employer branding, talent acquisition, onboarding, training & development</i>, comunicazione interna, eventi;"
    },
    "general-tos-1-24": {
        en: "<strong>Reports</strong> means the output of the analysis, management and data storage activities performed by Glickon and aimed at describing and predicting specific skills on the basis of  information collected in connection with the activities and the interactions performed by Users within the Platform;",
        it: "<strong>Servizi</strong> indica solo quei Servizi Glickon che siano specificamente inclusi in ciascun Ordine di un Cliente; "
    },
    "general-tos-1-25": {
        en: "<strong>Results</strong> means the following information related to each User, as provided by this one: score achieved in Company Challenges/Challenges, electronic documents, audio/video files, and personal information such as, but not limited to, first name, last name, date of birth, residency, education, e-mail, phone number, and resume;",
        it: "<strong>Servizi Glickon</strong>: indica i servizi e prodotti proprietari di Glickon descritti in apposito documento, come disponibile e tempo per tempo aggiornato ad esclusiva discrezione di Glickon al seguente <a href=\"https://www.glickon.com/it/documenti/termini-dei-servizi\" target=\"_blank\" rel=\"noopener noreferrer\">link</a> (<strong>‘Descrizione dei Servizi Glickon’</strong>). La Descrizione dei Servizi, insieme a queste Condizioni Generali, costituisce parte integrante di ciascun Ordine;"
    },
    "general-tos-1-26": {
        en: "<strong>Services</strong> means only those Glickon’s Services that are specifically included in each Client’s Order;",
        it: "<strong>Target Audience</strong> indica un servizio offerto e gestito da Glickon, a richiesta del Cliente, consistente in un’attività di promozione della Corporate Page e delle Challenge Aziendali del Cliente su social network e/o altri canali promozionali scelti dal Cliente stesso; "
    },
    "general-tos-1-27": {
        en: "<strong>Target Audience</strong> means a service offered and managed by Glickon, under Client's request, consisting in a promotional activity of the Corporate Page and Client's Company Challenges on social networks and/or other promotional channels chosen by the Client himself;",
        it: "<strong>Test</strong> indica una serie di domande e risposte riguardanti diverse aree tematiche, finalizzate a verificare il livello di conoscenza di una specifica tematica o materia. Ciascun Test può essere associato ad una Challenge/Challenge Aziendale e può essere creato autonomamente dal Cliente (<strong>‘Test Cliente’</strong>) o da Glickon (<strong>‘Test Glickon’</strong>);"
    },
    "general-tos-1-28": {
        en: "<strong>Test</strong> means a series of questions and answers regarding different subject areas, aimed at verifying the level of knowledge of a specific subject or matter. Each Test can be associated to a Company Challenges/Challenges and can be created independently by the Client (<strong>‘Client Test’</strong>) or by Glickon on its behalf (<strong>‘Glickon Test’</strong>);",
        it: "<strong>Utente</strong> indica qualsiasi persona fisica che utilizza la Piattaforma e le sue funzionalità, i cui dati sono forniti secondo quanto previsto nelle Condizioni d’Uso della Piattaforma e nell’Informativa sul trattamento dei dati personali di Glickon;"
    },
    "general-tos-1-29": {
        en: "<strong>User</strong> means any physical person who uses the Platform and its functionalities, whose data are provided in accordance with the Platform Terms of Use and the Glickon’s Privacy Notice.",
        it: "<strong>Utente LIVE</strong> indica qualsiasi persona fisica che utilizza la Piattaforma per la partecipazione ad un LIVE Game senza avere accesso alle altre funzionalità della Piattaforma stessa;"
    },
    "general-tos-1-30": {
        en: "<strong>White Label Mode</strong> means the possibility for the Client to use Glickon Services through a reserved version of the Platform and, therefore, intended exclusively for Private Users;",
        it: "<strong>Utente Privato</strong> indica un Utente che partecipa ad una Challenge Aziendale in Modalità White Label e che non ha accesso alle altre funzionalità della Piattaforma."
    },
    "general-tos-2": {
        en: "Object",
        it: "Oggetto"
    },
    "general-tos-2-1": {
        en: "In consideration of the signing of an Order and the following acceptance of these T&C by the Client, as well as the payment of a Fee, whose adequacy the Parties mutually acknowledge, Glickon grants the Client a licence that guarantees the non-exclusive and non-transferable right to use the Platform and to make use of the Services only as expressly permitted and regulated by these T&C.",
        it: "A fronte della sottoscrizione di un Ordine e della conseguente accettazione delle presenti Condizioni Generali da parte del Cliente, oltre che del pagamento di un Corrispettivo della cui adeguatezza le Parti si danno reciprocamente atto, Glickon concede al Cliente una licenza che garantisce il diritto non esclusivo e non trasferibile di utilizzare la Piattaforma e di usufruire dei Servizi nei soli modi espressamente dagli stessi consentiti e disciplinati da queste Condizioni Generali. "
    },
    "general-tos-2-2": {
        en: "The Client shall comply with any technical limitations on the Platform, without modifying this one and its processes or functions in any way. It is expressly understood that Glickon remains the sole and only owner of all rights not specifically licensed in accordance with the T&C.",
        it: "Il Cliente deve attenersi a qualsiasi limitazione tecnica presente nella Piattaforma, senza alterare la stessa e i suoi processi o funzioni in alcun modo. Resta espressamente inteso che Glickon resta unico e solo titolare di tutti i diritti non specificamente licenziati in conformità alle Condizioni Generali."
    },
    "general-tos-2-3": {
        en: "The license granted under these T&C is valid all over the world.",
        it: "La licenza concessa ai sensi delle presenti Condizioni Generali è valida in tutto il mondo."
    },
    "general-tos-3": {
        en: "Intellectual Property Rights",
        it: "Diritti di proprietà intellettuale"
    },
    "general-tos-3-1": {
        en: "The Client acknowledges and agrees that all intellectual property rights and titles connected to the Platform and Glickon Services, with particular but not limited reference to Glickon Content, Glickon Tests, Results and Reports, are and shall remain an exclusive property of Glickon and/or its licensors or assignees. No intellectual property rights are transferred to the Client, either directly or indirectly, through an Order or due to the use of the Platform or Services.",
        it: "Il Cliente riconosce e conviene che tutti i diritti e i titoli di proprietà intellettuale sulla Piattaforma e sui Servizi Glickon, con particolare ma non limitato riferimento al Contenuto Glickon, ai Test Glickon, ai Risultati e alle Reportistiche, sono e rimarranno di esclusiva titolarità di Glickon e/o dei suoi licenzianti o aventi causa. Nessun diritto di proprietà intellettuale viene trasferito al Cliente, né direttamente né indirettamente, attraverso un Ordine o in ragione dell’utilizzo della Piattaforma o dei Servizi."
    },
    "general-tos-3-2": {
        en: "By accepting these T&C, the Client acquires a perpetual, non-transferable right to use Results and Reports of its Company Challenges performed under the Services selected from time to time in each Order. It is expressly understood between the Parties that Client acquires no implied license to copy or reconstruct in whole or in part any services, features, or materials of Glickon’s exclusive intellectual property.",
        it: "Mediante accettazione delle Condizioni Generali, il Cliente acquisisce il diritto perpetuo non trasferibile di utilizzare i Risultati e le Reportistiche delle proprie Challenge Aziendali svolte nell’ambito dei Servizi di volta in volta scelti in ciascun Ordine. Resta espressamente inteso tra le Parti che il Cliente non acquisisce alcuna licenza implicita di copiare o ricostruire in tutto o in parte qualsiasi servizio, funzionalità o materiale ricadente sotto l’esclusiva proprietà intellettuale di Glickon."
    },
    "general-tos-3-3": {
        en: "Glickon expressly reserves the right to suspend the provision of the Service and the access to its products whenever it has a well-founded suspicion that the activation and/or use of the Services for any purpose of monitoring Users is being carried out by or on behalf of the Client, or a third party with the consent of the Client, as well as copying or testing Glickon Services, illicit operations or comparative analysis not commissioned by Glickon itself, including but not limited to reverse engineering or other similar actions in connection with any product or Service provided by Glickon, including the Platform, Glickon Content and Glickon Tests.",
        it: "Glickon si riserva espressamente il diritto di sospendere l’erogazione del Servizio e l’accesso ai propri prodotti ogniqualvolta nutra un fondato sospetto che stiano avvenendo, da parte o per conto del Cliente, o di terzi con il consenso del Cliente, l’attivazione e/o l’utilizzo dei Servizi per qualsiasi finalità di monitoraggio degli Utenti, copia o test dei Servizi Glickon, operazioni illecite o analisi comparative non commissionate da Glickon stessa, incluse a titolo esemplificativo e non esaustivo attività di <i>reverse engineering</i> o altre azioni analoghe in relazione a qualsiasi prodotto o Servizio fornito da Glickon, inclusi la Piattaforma, il Contenuto Glickon e i Test Glickon."
    },
    "general-tos-3-4": {
        en: "Notwithstanding the provisions of Articles 3.1 and 3.2, Glickon shall not be the owner of the intellectual property rights, in case of provision of Services in White Label Mode, regarding the Results and Reports of the activity of Private Users and, in case of LIVE game, the LIVE Game Results of LIVE Users, which in both cases shall remain therefore the Client's property.",
        it: "In deroga a quanto stabilito agli Articoli 3.1 e 3.2, Glickon non sarà titolare dei diritti di proprietà intellettuale, in caso di erogazione dei Servizi in Modalità White Label, sui Risultati e sulle Reportistiche dell’attività degli Utenti Privati e, in caso di LIVE game, sui Risultati LIVE Game degli Utenti LIVE, che in entrambe le ipotesi rimarranno dunque del Cliente. "
    },
    "general-tos-3-5": {
        en: "Regarding any Client Contents, or contents used in the LIVE Game, the Client states and guarantees to own all intellectual property rights to such Content, in full compliance with applicable laws. The Client shall therefore be directly and exclusively liable for any infringement of rights, including intellectual property rights, of third parties connected with or arising from such Content, and therefore expressly agrees to fully indemnify and hold Glickon and its administrators, representatives, directors and collaborators harmless from and against any and all liabilities, damages, claims, complaints, legal actions and costs incurred (including legal fees) arising from infringements related to the use of the Client Content.",
        it: "In riferimento a qualsiasi Contenuto Cliente, o contenuto usato nel LIVE Game, il Cliente dichiara e garantisce di possedere tutti i diritti di proprietà intellettuale su detto Contenuto, nel pieno rispetto della vigente normativa. Il Cliente sarà pertanto direttamente ed esclusivamente responsabile per qualsiasi violazione di diritti, anche di proprietà intellettuale, di terzi connessi o comunque derivanti da tale Contenuto e pertanto espressamente accetta di manlevare e tenere completamente indenne Glickon e i suoi amministratori, rappresentanti, direttori e collaboratori da e contro ogni e qualsiasi responsabilità, danno, richiesta di risarcimento, reclamo, azione legale e costo sostenuto (incluse le spese legali) derivante da violazioni connesse all’uso del Contenuto Cliente."
    },
    "general-tos-3-6": {
        en: "By accepting these T&C, the Client grants Glickon without reservation the non-exclusive and non-transferable right to use the Client's trademarks and/or logos, the Client Content and the Client Tests for the exclusive purpose of providing the Services, and expressly authorizes Glickon to include in its own portfolio of works (brochures, technical documentation, website) the Client's references (logo, Client name, case history and screenshots) relating to the project carried out in execution of one or more Orders, prior sharing contents with the Client.",
        it: "Accettando le Condizioni Generali, il Cliente concede senza riserve a Glickon il diritto, non esclusivo e non trasferibile, di utilizzare i marchi e/o loghi del Cliente, il Contenuto Cliente e i Test Cliente al fine esclusivo di fornire i Servizi, ed autorizza espressamente Glickon ad inserire nel proprio portfolio lavori (brochure, documentazione tecnica, sito web) le referenze del Cliente (logo, nome del Cliente, case history e screenshot) relativa al progetto svolto in esecuzione di uno o più Ordini, previa condivisione dei contenuti con il Cliente. "
    },
    "general-tos-3-7": {
        en: "The use by Glickon of trademarks, names, symbols and distinctive identifying signs of the Client, including those protected by intellectual property rights, in the context of the publication and distribution of a Company Challenge through the Target Audience Service, is understood to be unconditionally and expressly approved by the Client by signing the T&C.",
        it: "L’utilizzo da parte di Glickon di marchi, denominazioni, simboli e segni distintivi identificativi del Cliente, anche protetti da diritti di proprietà intellettuale, nell’ambito della pubblicazione e distribuzione di una Challenge Aziendale attraverso il Servizio Target Audience, si intende incondizionatamente ed espressamente approvato da parte del Cliente con la sottoscrizione delle Condizioni Generali."
    },
    "general-tos-3-8": {
        en: "In any case, the Client's authorization to use its trademarks, names, symbols and distinctive signs by Glickon is not definitive and is always revocable, with the consequent Client’s right to request the immediate removal of any reproduction of the same, on digital/web support, as well as of any reference to the Client that may be included by Glickon in promotional, advertising and portfolio material, without any further cost or other burden for neither of the Parties.",
        it: "In qualsiasi caso, l’autorizzazione del Cliente all’utilizzo dei propri marchi, denominazioni, simboli e segni distintivi da parte di Glickon non è definitiva ed è sempre revocabile, con conseguente diritto del Cliente di richiedere la rimozione immediata di qualsiasi riproduzione degli stessi, su supporto digitale/web, oltre che di ogni riferimento al Cliente eventualmente inserito da Glickon all’interno del materiale promozionale, pubblicitario e di portfolio, senza alcuna ulteriore spesa o aggravio di altro genere per nessuna delle Parti."
    },
    "general-tos-4": {
        en: "Privacy",
        it: "Protezione dei dati personali"
    },
    "general-tos-4-1": {
        en: "When using the Services in White Label Mode, the Client acts, pursuant to Regulation (EU) 2016/679 (<strong>‘GDPR’</strong>), as data controller in relation to the processing of Private Users’ personal data carried out through the Platform. Except for the Platform registration data of the Users, including Private Users, with respect to which Glickon acts as autonomous data controller, for any other processing related to the provision of the Services in White Label Mode, Glickon acts as data processor, within the limits defined in a separate agreement stipulated with the Client pursuant to Article 28 of the GDPR, to be considered as an integral and substantial part of these T&C.",
        it: "In caso di utilizzo dei Servizi in Modalità White Label, il Cliente agisce, ai sensi del Regolamento (UE) 2016/679 (<strong>‘GDPR’</strong>), quale titolare rispetto ai trattamenti dei dati personali degli Utenti Privati svolti tramite la Piattaforma. Tranne che per i dati di registrazione degli Utenti alla Piattaforma, compresi gli Utenti Privati, rispetto ai quali Glickon agisce quale autonomo titolare, per qualsiasi altro trattamento connesso all’erogazione dei Servizi in Modalità White Label, Glickon opera in qualità di responsabile, nei limiti definiti in separato accordo stipulato con il Cliente ai sensi dell’Articolo 28 del GDPR, da considerarsi quale parte integrante e sostanziale delle presenti Condizioni Generali."
    },
    "general-tos-4-2": {
        en: "As data controller, the Client undertakes to provide Glickon with his own privacy notice to be given to Private Users, by means of publication on the Platform, before their access to the private areas of the Platform. In the event of failure by the Customer to comply with this obligation, and therefore in the event of failure to provide Glickon with his own privacy notice in relation to Company Challenges, Glickon reserves the right to block the access to the Platform for Client's Private Users.",
        it: "Nella propria qualità di titolare del trattamento, il Cliente si impegna a trasmettere a Glickon la propria informativa sul trattamento dei dati personali da fornire agli Utenti Privati, mediante pubblicazione sulla Piattaforma, prima dell’accesso degli stessi alle aree private della stessa. In caso di inadempimento di tale obbligo da parte del Cliente e dunque in mancanza di trasmissione a Glickon della propria informativa sul trattamento dei dati personali in riferimento a Challenge Aziendali, Glickon si riserva il diritto di bloccare l’accesso alla Piattaforma da parte degli Utenti Privati del Cliente. "
    },
    "general-tos-4-3": {
        en: "By accepting these T&C, the Client declares and guarantees to respect, in relation to any Service used, all the obligations set forth by the European and national applicable privacy legislation, with particular but not limited reference to the fulfilment of the requirements necessary to ensure the processing takes place in a lawful, correct and transparent manner towards the data subject, also in relation to the verification of the existence of a suitable legal basis in relation to each purpose of the processing pursued by the Client through the Services, as autonomous controller. Similarly, the Client guarantees to have performed all analysis and implemented all technical and organizational measures imposed by applicable law for the purpose of performing, when required, an adequate assessment of the risks that the processing to be performed may entail for the rights and freedoms of the Private Users concerned. Similarly, the Client guarantees to have adequately assessed the risks that each processing may determine for the rights and freedoms of the Private Users concerned and to have adopted the most appropriate technical and organizational measures to limit and minimize the related impacts.",
        it: "Mediante accettazione delle presenti Condizioni Generali il Cliente dichiara e garantisce di rispettare, in riferimento a qualsiasi Servizio utilizzato, tutti gli obblighi sanciti dalla normativa europea e nazionale vigente in materia di protezione dei dati personali, con particolare ma non limitato riferimento all’adempimento dei requisiti necessari ad assicurare che i trattamenti avvengano in maniera lecita, corretta e trasparente verso l’interessato, anche rispetto all’accertamento dell’esistenza di idonea base giuridica in relazione a ciascuna finalità del trattamento perseguita da parte del Cliente, quale autonomo titolare, tramite i Servizi. Allo stesso modo, il Cliente garantisce di aver svolto tutte le analisi e di aver messo in piedi tutte le misure tecniche ed organizzative imposte dalla legge vigente ai fini dell’esecuzione, quando richiesto, di un’adeguata valutazione dei rischi che il trattamento che intende svolgere può comportare per i diritti e le libertà degli Utenti Privati interessati. Allo stesso modo, il Cliente garantisce di aver adeguatamente valutato i rischi che ciascun trattamento può determinare per i diritti e le libertà degli Utenti Privati interessati e di aver adottato le misure tecniche ed organizzative più idonee a limitare e minimizzare i relativi impatti."
    },
    "general-tos-4-4": {
        en: "The Client therefore declares to be aware of being directly and exclusively responsible for any violation of the applicable privacy legislation arising from, or in any case connected to, the processing carried out by the Client as data controller on the Platform, undertaking to fully indemnify and hold Glickon and its administrators, representatives, directors and collaborators, harmless from and against any damages, claims, requests for compensation, legal actions, costs incurred (including legal fees) and more generally any liabilities that may arise directly or indirectly from this violation.",
        it: "Il Cliente si dichiara pertanto consapevole di essere direttamente ed esclusivamente responsabile per qualsiasi violazione delle norme applicabili in materia di protezione dei dati personali che derivi da o sia comunque connessa ai trattamenti che il Cliente svolge, in qualità di titolare, sulla Piattaforma, obbligandosi a manlevare e tenere completamente indenne Glickon e i suoi amministratori, rappresentanti, direttori e collaboratori, da e contro qualsiasi danno, reclamo, richiesta di risarcimento, azione legale, costo sostenuto (incluse le spese legali) e più in generale responsabilità che ne dovesse direttamente o indirettamente scaturire."
    },
    "general-tos-4-5": {
        en: "The Client authorizes from now on Glickon to anonymize and/or aggregate Private Users’ personal data and to use them in this way, which is no longer suitable for the identification of the Users, as autonomous data controller, for purposes of analysing the functionality and improving Glickon Services and the Platform (e.g. statistics, usage data), as well as developing and providing other products and services.",
        it: "Il Cliente autorizza sin d’ora Glickon ad anonimizzare e/o aggregare i dati personali degli Utenti Privati e ad utilizzarli in tale modalità, non più idonea all’identificazione di questi ultimi, in qualità di autonomo titolare del trattamento, per scopi di analisi della funzionalità e miglioramento dei Servizi Glickon e della Piattaforma (es. statistiche, dati di utilizzo), nonché per sviluppare e fornire altri prodotti e servizi."
    },
    "general-tos-5": {
        en: "Account",
        it: "Account"
    },
    "general-tos-5-1": {
        en: "In accordance with the Service and the Fee agreed, Glickon undertakes to provide the Client with a variable number of Accounts, each of which shall be validated, before the Platform can be used, by means of specific credentials (username and password) that will be issued by Glickon, following the signing of the Order by the Client, by sending an e-mail to the Contact Person. The latter will then be the one and only responsible for any distribution of these credentials within the Client’s structure.",
        it: "In base al Servizio e al Corrispettivo concordati, Glickon si impegna a fornire al Cliente un numero variabile di Account, ciascuno dei quali dovrà essere convalidato, prima che la Piattaforma possa essere utilizzata, mediante apposite credenziali (nome utente e password) che saranno rilasciate da Glickon, successivamente alla sottoscrizione dell’Ordine da parte del Cliente, mediante invio via e-mail al Referente. Quest’ultimo sarà poi l’unico e solo responsabile dell’eventuale distribuzione di tali credenziali all’interno della struttura del Cliente."
    },
    "general-tos-5-2": {
        en: "The Client undertakes to maintain secrecy and not to publish, disseminate or in any other way make known to third parties the credentials provided by Glickon in order to log in to the Platform.",
        it: "Il Cliente si impegna a mantenere la segretezza e a non pubblicare, diffondere o rendere in altro modo conoscibili a terzi le credenziali fornite da Glickon per l’accesso alla Piattaforma."
    },
    "general-tos-5-3": {
        en: "Subject to the provision of Article 3.4, the Client shall be solely responsible for any activities performed on the Dashboard (such as, but not limited to, uploading and/or editing Content) through Client’s Accounts. ",
        it: "Fermo quanto stabilito all’Articolo 3.4, la responsabilità per qualsiasi attività svolta sulla Dashboard (a titolo esemplificativo e non esaustivo, il caricamento e/o la modifica di Contenuto) dagli Account del Cliente ricadrà unicamente sul Cliente medesimo."
    },
    "general-tos-5-4": {
        en: "Without prejudice to any further obligations arising from applicable privacy legislation, the Client shall inform Glickon without any undue delay whenever it becomes aware of the access to, or use of, the Platform or Services by an unauthorized third party. ",
        it: "Fatto salvo ogni ulteriore obbligo derivante dalla normativa in materia di protezione dei dati personali, il Cliente dovrà informare Glickon senza alcun ingiustificato ritardo ogniqualvolta venga a conoscenza di un accesso alla Piattaforma, o dell’uso della stessa o dei Servizi, da parte di un terzo non autorizzato."
    },
    "general-tos-5-5": {
        en: "The Client undertakes to use the Accounts and therefore the Services associated exclusively within his own business organization, standing expressly forbidden, unless otherwise agreed in writing with Glickon, to extend the license by allowing third parties to access the Accounts activated in accordance with the provisions of Article 5.1 above, even when they belong to the same Client's group or are linked to the latter by bonds of control or connection pursuant to Article 2359 of the Italian Civil Code. Glickon reserves the right to carry out any appropriate technical verification, within the limits of the law, on the Client's compliance with this last prohibition.",
        it: "Il Cliente si impegna ad utilizzare gli Account e dunque i Servizi ad essi abbinati esclusivamente nell’ambito della propria organizzazione aziendale, restando espressamente vietato, salvo diverso accordo scritto con Glickon, estendere la licenza consentendo a terze parti di accedere agli Account attivati secondo quanto previsto al precedente Articolo 5.1, anche quando le stesse appartengano al medesimo gruppo del Cliente o siano legate a quest’ultimo da vincoli di controllo o collegamento ai sensi dell’Articolo 2359 c.c.. Glickon si riserva il diritto di svolgere ogni più opportuna verifica tecnica, nei limiti di legge, circa il rispetto di tale ultimo divieto da parte del Cliente."
    },
    "general-tos-5-6": {
        en: "The Client authorizes from now on Glickon and its employees, duly trained in accordance with Article 29 of the GDPR, to access the Accounts assigned to the Client for the purpose of creating, editing or viewing Content and/or Tests, or doing whatever is deemed necessary to provide technical support to the Client for the use of the Platform.",
        it: "Il Cliente autorizza sin d’ora Glickon e i suoi dipendenti, debitamente istruiti ai sensi dell’Articolo 29 del GDPR, ad accedere agli Account assegnati al Cliente medesimo al fine di creare, modificare o visionare Contenuto e/o Test, o fare quanto ritenuto necessario per fornire supporto tecnico al Cliente nell’utilizzo della Piattaforma."
    },
    "general-tos-6": {
        en: "Target audience",
        it: "Target audience"
    },
    "general-tos-6-1": {
        en: "In the event of use of the Candidate Experience Service, the Client declares to be aware and accepts that Glickon will share the relevant Content uploaded to the Platform through any communication channel, including by way of example the community, social channels and Glickon's partner job boards.",
        it: "In ipotesi di utilizzo del Servizio di Candidate Experience, il Cliente si dichiara consapevole ed accetta che Glickon condividerà il relativo Contenuto caricato in Piattaforma mediante qualsiasi canale di comunicazione, inclusi a titolo esemplificativo la community, i canali social e i job board partner di Glickon."
    },
    "general-tos-6-2": {
        en: "In the event that Client has purchased the Target Audience Service, Glickon will communicate to the Client via e-mail the social networks and any other promotional channels selected where the social recruiting campaign will be conveyed.",
        it: "Nel caso in cui il Cliente abbia acquistato il Servizio di Target Audience, Glickon comunicherà via e-mail al Cliente i social network e gli eventuali altri canali promozionali prescelti su cui sarà veicolata la campagna di social recruiting."
    },
    "general-tos-6-3": {
        en: "Target Audience Service’s duration will be decided by Glickon according to Client’s specific needs.",
        it: "La durata del Servizio di Target Audience sarà decisa da Glickon in base alle specifiche esigenze del Cliente."
    },
    "general-tos-6-4": {
        en: "Anyway, Glickon shall not be responsible for any inefficiency and/or interruption of Target Audience's activity due to reasons that are not directly chargeable to Glickon itself.",
        it: "Glickon non sarà comunque responsabile di eventuali inefficienze e/o interruzioni dell’attività di Target Audience per ragioni che non siano direttamente imputabili a Glickon stessa."
    },
    "general-tos-7": {
        en: "Users Documents Upload",
        it: "Upload documenti di utenti"
    },
    "general-tos-7-1": {
        en: "In the event that the Client activates, within the scope of the Services, the documents Upload additional function, through which Users can upload any kind of files directly to the Platform, also as a response to Client's requests (by way of example, but not limited to, a CV, a PDF of their residency’s certificate or a PPT in relation to a training course), the Client declares to be aware and agrees that the User is solely responsible for any content uploaded to the Platform. ",
        it: "In caso di attivazione da parte del Cliente, nell’ambito dei Servizi, della funzione aggiuntiva di Upload documenti, grazie a cui agli Utenti possono caricare <i>files</i> di ogni genere direttamente sulla Piattaforma, anche in riscontro a richieste del Cliente stesso (a titolo esemplificativo e non esaustivo un CV, il PDF del proprio certificato di residenza o un PPT in relazione ad un percorso di <i>training</i>), il Cliente si dichiara consapevole e conviene che l’Utente è il solo ed unico responsabile di qualsiasi contenuto caricato in Piattaforma."
    },
    "general-tos-7-2": {
        en: "It is therefore expressly understood between the Parties that Glickon does not carry out, nor has any concrete possibility of carrying out, any preventive verification or subsequent control function, except in the case of specific notification, on the files uploaded by the User and their contents and consequently that Glickon shall not be in any case responsible for any violation of laws and rights, including those of third parties, or damage, attributable to them.",
        it: "Resta pertanto espressamente inteso tra le Parti che Glickon non svolge, né ha concretamente la possibilità di svolgere, alcuna funzione di verifica preventiva o di controllo successivo, tranne in caso di specifica segnalazione, dei <i>files</i> caricati dall’Utente e dei loro contenuti e conseguentemente che Glickon non sarà in alcun caso responsabile di qualsiasi violazione di norme di legge e di diritti, anche di terzi, o danno, ad essi riconducibili."
    },
    "general-tos-7-3": {
        en: "By requesting the activation of this function, the Client therefore undertakes to fully indemnify and hold Glickon harmless from and against any liabilities, damages, claims, actions and costs incurred (including legal fees) directly or indirectly arising from violations related to materials and contents uploaded by the User to the Platform within the scope of the Services used by the Client.",
        it: "Mediante richiesta di attivazione della funzione in esame, il Cliente si impegna dunque ad esonerare e mantenere completamente indenne Glickon da e contro qualsiasi responsabilità, danno, richiesta di risarcimento, reclamo, azione legale e costo sostenuto (incluse le spese legali) direttamente o indirettamente derivante da violazioni connesse ai materiali e ai contenuti caricati dall’Utente sulla Piattaforma nell’ambito dei Servizi utilizzati dal Cliente."
    },
    "general-tos-8": {
        en: "Fees",
        it: "Corrispettivi"
    },
    "general-tos-8-1": {
        en: "The Fee due to Glickon by the Client for the license to use the Platform and for the provision of the Services is negotiated between the Parties and defined in each Order. It is understood that no Service that is not expressly included in a validly signed Order shall be due by Glickon to the Client.",
        it: "Il Corrispettivo dovuto dal Cliente a Glickon per la concessione della licenza d’uso della Piattaforma e per la fornitura dei Servizi viene negoziato tra le Parti e definito in ciascun Ordine. Resta inteso che nessun Servizio che non sia espressamente ricompreso in un Ordine validamente sottoscritto sarà dovuto da parte di Glickon in favore del Cliente."
    },
    "general-tos-8-2": {
        en: "Unless otherwise agreed between the Parties, the Fee will be invoiced upon subscription of the Order and the relevant payment terms established in 30 days from the date of issue of the invoice.",
        it: "Salvo diversa pattuizione tra le Parti, il Corrispettivo sarà fatturato alla sottoscrizione dell’Ordine ed i relativi termini di pagamento stabiliti in 30 giorni dalla data di emissione della fattura."
    },
    "general-tos-8-3": {
        en: "Payment of the Fee will be made by bank transfer to the coordinates indicated in the Order.",
        it: "Il pagamento del Corrispettivo avverrà a mezzo bonifico bancario alle coordinate indicate nell’Ordine."
    },
    "general-tos-8-4": {
        en: "Glickon reserves the right to suspend the provision of Services and the access to the Platform for the Client, if the latter, even following the sending of a specific written request by Glickon at the end of the 30 days established in Article 8.2 above, fail to fulfil his obligation to pay the Fee. It is understood that, in the event of suspension, none of the Client's data or Content will be deleted from the Platform. The Parties expressly agree that, if the non-fulfilment continues for more than 90 days from the date of issue of the invoice and therefore for more than 60 days from the sending of a specific written reminder by Glickon, the latter shall be entitled to terminate these T&C pursuant to Article 1456 of the Italian Civil Code.",
        it: "Glickon si riserva il diritto di sospendere la fornitura dei Servizi e gli accessi alla Piattaforma nei confronti del Cliente stesso, qualora quest’ultimo, anche a seguito dell’invio da parte di Glickon di apposita richiesta scritta allo scadere dei 30 giorni stabiliti al precedente Articolo 8.2, manchi di adempiere al proprio obbligo di pagamento del Corrispettivo. Resta inteso che, in caso di sospensione, nessun dato o Contenuto del Cliente sarà eliminato dalla Piattaforma. Le Parti convengono espressamente che, qualora l’inadempimento dovesse protrarsi per più 90 giorni dalla data di emissione della fattura e quindi per oltre 60 giorni dall’invio di specifico sollecito scritto da parte di Glickon, quest’ultima avrà il diritto di risolvere le presenti Condizioni Generali ai sensi dell’Articolo 1456 c.c."
    },
    "general-tos-8-5": {
        en: "Without prejudice to the provisions of Article 8.4, in the event of delay in the execution of any payment due under an Order pursuant to these T&C, the Client shall pay Glickon default interest at the rate set forth in Article 1284 of the Italian Civil Code.",
        it: "Fermo quanto stabilito all’Articolo 8.4, in caso di ritardo nell’esecuzione di qualunque pagamento dovuto in forza di un Ordine ai sensi delle presenti Condizioni Generali, il Cliente corrisponderà a Glickon interessi moratori in misura pari al tasso di cui all’Articolo 1284 c.c.. "
    },
    "general-tos-9": {
        en: "License Term",
        it: "Durata della licenza"
    },
    "general-tos-9-1": {
        en: "The term of the license to use the Platform and that of the provision of Services is established in each Order. Also for the purposes of the applicability of these T&C, this term starts from the date on which each Order has been signed by both Parties.",
        it: "La durata della licenza d’uso della Piattaforma e della fornitura dei Servizi è quella stabilita in ciascun Ordine. Anche ai fini dell’applicabilità delle presenti Condizioni Generali, tale durata decorre dalla data in cui un ciascun Ordine è stato sottoscritto da entrambe le Parti."
    },
    "general-tos-9-2": {
        en: "Unless the Order has expired naturally or has been terminated prematurely by one of the Parties in the cases established by these T&C, the latter shall remain valid and effective until the expiration or termination of the last Order stipulated between the Parties.",
        it: "Tranne quando l’Ordine sia scaduto naturalmente, o sia stato risolto anticipatamente da una delle Parti nei casi stabiliti dalle presenti Condizioni Generali, le stesse resteranno valide ed efficaci fino alla scadenza o alla risoluzione dell’ultimo Ordine stipulato tra le Parti. "
    },
    "general-tos-9-3": {
        en: "The license to use the Platform and Services granted by Glickon in favour of the Customer, following the signing of an Order, in accordance with these T&C, shall cease to produce its effects upon the natural expiry of the duration established therein, or when one of the Parties obtains early termination of the Order in the cases established in these T&C.",
        it: "La licenza d’uso della Piattaforma e dei Servizi concessa da Glickon in favore del Cliente, a seguito della sottoscrizione di un Ordine, ai sensi delle presenti Condizioni Generali, cessa di produrre i propri effetti alla scadenza naturale della durata in esso stabilita, o quando una delle Parti ottenga la risoluzione anticipata dell’Ordine nei casi stabiliti dalle Condizioni Generali. "
    },
    "general-tos-9-4": {
        en: "Unless otherwise expressly agreed between the Parties, after the expiry of the license, Glickon will grant the Client a period of 10 working days during which the latter may continue to enter his Accounts on the Platform with the sole purpose of downloading, autonomously and without the possibility of mass downloading, the Results of his Company Challenges and the CVs of the Private Users who have participated in connection with the Candidate Experience Service. At the end of this 10-day period, Glickon will deactivate any Client's Account on the Platform. After 30 days from the date on which the license has expired or the Order has been terminated, or sooner if the Client expressly requests it, unless otherwise agreed between the Parties and unless otherwise required by applicable laws, Glickon will delete from the Platform any Contents, information and data referable to the Client and his Accounts.",
        it: "Salvo diverso espresso accordo tra le Parti, a seguito della scadenza della licenza, Glickon concederà al Cliente un periodo di 10 giorni lavorativi nel corso del quale quest’ultimo potrà continuare ad accedere ai propri Account sulla Piattaforma al solo fine di scaricare, in autonomia e senza possibilità di effettuare <i>download</i> massivi, i Risultati delle proprie Challenge Aziendali e i CV degli Utenti Privati che vi abbiano preso parte in connessione al Servizio di Candidate Experience. All’esito di tale periodo di 10 giorni, Glickon disattiverà ogni Account del Cliente sulla Piattaforma. Dopo 30 giorni dalla data in cui è scaduta la licenza o è avvenuta la risoluzione dell’Ordine, o prima qualora il Cliente lo richieda espressamente, tranne diversa pattuizione tra le Parti e salvo che non sia diversamente disposto da norme di legge applicabili, Glickon cancellerà dalla Piattaforma ogni Contenuto, informazione e dato riferibile al Cliente e ai suoi Account."
    },
    "general-tos-9-5": {
        en: "During the negotiation of an Order, or at any time thereafter until the expiry of the same, the Client may request Glickon to provide an additional assistance service, whose content and cost will be negotiated separately between the Parties, including the massive extraction and delivery directly by Glickon to the Client of all data connected to the latter's now-deactivated Accounts.",
        it: "In fase di negoziazione di un Ordine, o in qualunque momento successivo fino alla scadenza dello stesso, il Cliente potrà richiedere a Glickon la fornitura di un servizio aggiuntivo di assistenza, i cui contenuti e costi saranno separatamente negoziati tra le Parti, avente anche ad oggetto l’estrazione massiva e la consegna direttamente da parte di Glickon al Cliente di tutti i dati collegati agli Account ormai disattivati di quest’ultimo."
    },
    "general-tos-10": {
        en: "Warranties",
        it: "Garanzie"
    },
    "general-tos-10-1": {
        en: "Glickon does not provide any warranty, as far as permitted by applicable law, regarding the suitability of the Platform for a specific purpose, nor the compatibility of the Platform with other programs and/or systems used by the Client, nor the Platform will work without errors or interruptions. ",
        it: "Glickon non fornisce alcuna garanzia, nei limiti consentiti dalla legge applicabile, relativamente alla rispondenza della Piattaforma ad uno specifico scopo, né alla compatibilità della Piattaforma con altri programmi e/o sistemi utilizzati dal Cliente o al fatto che il funzionamento della stessa sia ininterrotto e privo di errori. "
    },
    "general-tos-10-2": {
        en: "The effects of this Article 10 shall survive the termination, for whatever reason, of these T&C.",
        it: "Gli effetti derivanti da questo Articolo 10 sopravvivono alla cessazione, a qualunque titolo, delle presenti Condizioni Generali. "
    },
    "general-tos-11": {
        en: "Limitation of liability",
        it: "Limitazione di responsabilità"
    },
    "general-tos-11-1": {
        en: `
        Any Glickon’s liability, except in the event of intent or gross negligence, is excluded:
        <ol>
        <li>for any kind of consequence that may arise or be in any case exclusively connected with the failure of the Client to fulfil his obligations under these T&C, the law and third parties’ rights, in connection with the use of the Platform or the Services;</li>
        <li>for any damages, including but not limited to loss of data, profit, goodwill or other economic loss, arising out from, or in any way related to, the Client’s independent use of the Services provided by Glickon and the purposes pursued through them, as well as any cost eventually incurred by the Client in procuring substitute services;</li>
        <li>for any damage or liability arising from, or related to, improper or negligent use of the Platform or the Services, or the Client’s failure to comply with the technical instructions provided in this regard by Glickon, or with any of these T&C;</li>
        <li>for services provided by third parties, involved in any capacity by Glickon or the Client, in connection with or in conjunction with the Services provided by Glickon through the Platform;</li>
        <li>without prejudice to the provisions of Article 4.3, for any consequences arising from the lack of truthfulness, correctness or updating of the data independently uploaded by the Client to the Platform, also in connection with Content or Tests, for any Company Purpose.</li>
        </ol>
        `,
        it: `
        Deve considerarsi esclusa ogni responsabilità di Glickon, tranne in caso di dolo e colpa grave:
        <ol>
        <li>per qualsiasi genere di conseguenza possa derivare o essere comunque connessa all’inadempimento esclusivamente da parte del Cliente dei propri obblighi ai sensi delle presenti Condizioni Generali, di norme di legge e di diritti di terzi, in connessione all’utilizzo della Piattaforma o dei Servizi;</li>
        <li>per qualsiasi danno, ivi inclusa a titolo esemplificativo ma non esaustivo la perdita di dati, di profitti, di avviamento o altre perdite economiche, che derivi o sia comunque correlato all’utilizzo autonomamente effettuato da parte del Cliente dei Servizi erogati da Glickon e alle finalità perseguite attraverso di essi, oltre che a qualsiasi costo eventualmente sostenuto dal Cliente per procurarsi servizi sostitutivi;</li>
        <li>per qualsiasi danno o responsabilità derivante o collegata ad un uso improprio o negligente della Piattaforma o dei Servizi, o al mancato rispetto delle istruzioni tecniche fornite a riguardo da Glickon, o di qualsiasi delle presenti Condizioni Generali, da parte del Cliente;</li>
        <li>per servizi forniti da terze parti, coinvolte a qualsiasi titolo da Glickon o dal Cliente, in collegamento o abbinamento ai Servizi erogati da Glickon attraverso la Piattaforma;</li>
        <li>fermo quanto disposto all’Articolo 4.3, per qualsivoglia conseguenza che derivi dalla mancata veridicità, correttezza o aggiornamento dei dati autonomamente caricati dal Cliente in Piattaforma, anche in connessione a Contenuto o Test, per qualsiasi Scopo Aziendale. </li>
        </ol>
    ` },
    "general-tos-11-2": {
        en: "In all cases set forth above, Client undertakes to defend, indemnify and hold totally harmless Glickon and its directors, representatives, employees and assignees, from and against any consequences, liabilities and violations, such as but not limited to fines, damages, penalties, claims for interests or compensations, actions and legal fees, arising from or in any way connected with Client’s actions or omissions.",
        it: "<u>In tutti i casi sopra stabiliti, il Cliente si obbliga a difendere, manlevare e tenere totalmente indenne Glickon e i suoi amministratori, rappresentanti, dipendenti e aventi causa, da e contro qualsiasi conseguenza, responsabilità e violazione, quali a titolo esemplificativo ma non esaustivo, sanzioni, danni, penali, richieste di interessi o risarcimenti, azioni e spese legali, che derivi da o sia comunque connessa ad azioni o omissioni del Cliente.</u>"
    },
    "general-tos-11-3": {
        en: "Any liability attributable to Glickon, as well as to its directors, representatives, employees and collaborators, shall be limited to the value of the sums actually paid by the Client in execution of the Order under which such liability arose.",
        it: "Ogni eventuale responsabilità imputabile a Glickon, oltre che ai suoi amministratori, rappresentanti, dipendenti e collaboratori, è limitata al valore delle somme effettivamente corrisposte dal Cliente in esecuzione dell’Ordine nell’ambito del quale tale responsabilità è emersa."
    },
    "general-tos-11-4": {
        en: "It is expressly understood that whatever the Company Purpose for which the Client uses the Services provided by Glickon, the lawfulness of this purpose and the compliance with the regulations in force, with particular but not limited reference to those applicable to the protection of personal data, of the activities and processing operations carried out independently by the Client for the achievement of the same, also through the Services governed by these T&C, fall under the sole and exclusive responsibility of the Client. For the avoidance of doubt, this refers to the lawfulness of the Client Content and the Client Tests and, more generally, of the Company Purposes pursued, as well as the processing of personal data deriving therefrom, by way of example but not limited thereto, in the context of the activity of selecting candidates or evaluating employees carried out by the Client through a Company Challenge, any contribution by Glickon in any of these processes being excluded. ",
        it: "Resta espressamente inteso che qualsiasi sia lo Scopo Aziendale per cui il Cliente utilizzi i Servizi erogati da Glickon, la liceità di tale finalità e la conformità alla normativa vigente, con particolare ma non limitato riferimento a quella applicabile in materia di protezione dei dati personali,  delle attività e delle operazioni di trattamento autonomamente svolte dal Cliente per la realizzazione della stessa, anche grazie ai Servizi disciplinati dalle presenti Condizioni generali, ricadono sotto la sola ed esclusiva responsabilità del Cliente stesso. A scanso di equivoci, ci si riferisce alla liceità dei Contenuti Cliente e dei Test Cliente e, più in generale, degli Scopi Aziendali perseguiti, oltre che dei trattamenti di dati personali che ne derivano, a titolo esemplificativo e non esaustivo, nell’ambito dell’attività di selezione dei candidati, o di valutazione dei dipendenti eseguita dal Cliente tramite una Challenge Aziendale, restando escluso qualunque apporto di Glickon in qualsivoglia di tali processi. "
    },
    "general-tos-11-5": {
        en: "This Article shall survive the termination, for whatever reason, of these T&C.",
        it: "Il presente Articolo sopravvive alla cessazione, a qualunque titolo, delle presenti Condizioni Generali."
    },
    "general-tos-12": {
        en: "Service availability",
        it: "Disponibilità del servizio"
    },
    "general-tos-12-1": {
        en: "Glickon guarantees an uptime of 99.8% on annual basis and in any event not greater than those provided by the service levels of its suppliers for server storage, hosting and carrier services necessary to provide the Services. Glickon does not guarantee in any way that the Services will be provided without interruption or discontinuity.",
        it: "Glickon garantisce un uptime pari al 99.8% su base annuale e comunque non superiore a quello previsto dai livelli di servizio dei propri fornitori per i servizi di server storage, hosting e carrier necessari all’erogazione dei Servizi. Glickon non garantisce in alcun modo che i Servizi verranno forniti senza interruzione o discontinuità nell’erogazione. "
    },
    "general-tos-12-2": {
        en: "In case of a Service malfunction, the Client shall promptly report it to Glickon, by sending a written notification to: <a href=\"mailto:support@glickon.com\">support@glickon.com</a>, service available during office hours (Mon/Fri 9:00-18:00).",
        it: "Nell’ipotesi in cui il Cliente riscontri un malfunzionamento dei Servizi, dovrà prontamente segnalarlo a Glickon, dandone comunicazione a mezzo e-mail all’indirizzo: <a href=\"mailto:support@glickon.com\">support@glickon.com</a>, servizio attivo in orario di ufficio (lun/ven 9:00- 18:00)."
    },
    "general-tos-12-3": {
        en: "Glickon guarantees to take charge of the problem within 120 minutes from the notification made by the Client in accordance with the point above.",
        it: "Glickon garantisce la presa in carico del problema entro 120 minuti dalla segnalazione effettuata dal Cliente conformemente a quanto previsto al punto precedente"
    },
    "general-tos-12-4": {
        en: "The notification made by the Client will be evaluated by Glickon’s personnel, who will provide an appropriate response to the Client via telephone or e-mail.",
        it: "La segnalazione effettuata dal Cliente verrà valutata dal personale di Glickon, che provvederà a dare risposta adeguata al Cliente via telefono o e-mail."
    },
    "general-tos-12-5": {
        en: "In the event that Glickon’s personnel determines, at its sole discretion, that Glickon is responsible for the Service malfunction, the downtime period (failure to provide services) will be counted as follows (i) downtimes under 15 minutes are excluded from the total count, (ii) interruptions caused by failures or problems external to the hosting provider's network or by force majeure or fortuitous event (by way of example but not limited to: failures of the Internet connection used by the Client to connect to the server, or failures of the carriers providing connectivity) are excluded from the total count, (iii) routine maintenance interventions are generally scheduled from 00:00 to 06:00 on Saturdays and Sundays. Additional routine maintenance operations involving a Platform downtime more than 120 minutes long are communicated to the Client at least 24 hours before the downtime. However, without prejudice to the foregoing, in the sole event that Glickon does not give the above notice of downtime, the period of non-provision of Services will be included in the total downtime count.",
        it: "Nell’ipotesi in cui il personale di Glickon stabilisca, a propria esclusiva discrezione, che le responsabilità del malfunzionamento del Servizio siano da attribuirsi a Glickon stessa, il periodo di downtime (mancata erogazione del servizio) verrà conteggiato in base alle seguenti modalità: (i) sono esclusi dal conteggio totale i downtime inferiori ai 15 minuti, (ii) sono esclusi dal conteggio totale le interruzioni causate da guasti o problemi esterni alla rete del fornitore del servizio di hosting o da cause di forza maggiore o caso fortuito (a titolo esemplificativo ma non esaustivo: guasti al collegamento internet utilizzato dal Cliente per connettersi al server, oppure guasti dei carrier che forniscono la connettività), (iii) gli interventi di manutenzione ordinaria sono generalmente pianificati a partire dalle 00.00 alle 06.00 di sabato e di domenica. Ulteriori interventi di manutenzione ordinaria che comportano un fermo della Piattaforma superiore ai 120 minuti, sono comunicati al Cliente con un preavviso di almeno 24 ore dal fermo stesso. Tuttavia, fermo quanto stabilito sopra, nella sola ipotesi in cui Glickon non effettui la suddetta comunicazione di fermo del servizio, il periodo di mancata erogazione dei Servizi verrà incluso nel conteggio totale di downtime."
    },
    "general-tos-12-6": {
        en: "Glickon guarantees the availability of the Services on the Internet within the limits set out above, but does not guarantee the Client will always be able to connect to Glickon. Glickon shall in no way be liable to the Client for the inability to use the Services when such inability is determined by any reason not directly attributable to Glickon (by way of example but not limited to: reasons due to the Client's hardware and software configuration, reasons due to the connectivity offered by the Client's providers).",
        it: "Glickon garantisce la disponibilità dei Servizi sulla rete Internet nei limiti sopra indicati, ma non assicura che il Cliente sia sempre in grado di connettersi a Glickon. Glickon non sarà in alcun modo responsabile nei confronti del Cliente per la mancata fruizione dei Servizi quando tale impossibilità sia determinata da qualsiasi motivo non direttamente imputabili a Glickon (a titolo esemplificativo e non esaustivo: motivi dovuti alla configurazione hardware e software del Cliente, motivi dovuti alla connettività offerta dai fornitori del Cliente). "
    },
    "general-tos-13": {
        en: "Support and supplementary services",
        it: "Servizi di supporto e supplementari"
    },
    "general-tos-13-1": {
        en: "Without prejudice to the provisions of Article 9.5 and unless otherwise agreed between the Parties, no additional services or technical support is provided by Glickon to the Client, except as may be required under applicable law.",
        it: "Fermo quanto stabilito all’Articolo 9.5 e salvo diverso accordo tra le Parti, non è prevista la fornitura di servizi aggiuntivi o di supporto tecnico da parte di Glickon nei confronti del Cliente, tranne quanto eventualmente imposto ai sensi di norme di legge applicabile."
    },
    "general-tos-13-2": {
        en: "Under Client's written request, Glickon will provide, on a one-time basis, an introduction service consisting of a total of 2 hours of remote training on the use of the Platform, the Dashboard, editing Company Page and creating Company Challenges and Client Content. The Fee for any additional training hours beyond the first 2 hours, if requested by the Client, will be negotiated between the Parties.",
        it: "Su richiesta scritta del Cliente, Glickon fornirà, <i>una tantum</i>, un servizio introduttivo consistente in 2 ore complessive di formazione da remoto sull’uso della Piattaforma, della Dashboard, sulla modifica della Company Page e sulla creazione di Challenge Aziendali e Contenuto Cliente. Il Corrispettivo per le eventuali ore di formazione aggiuntive rispetto alle prime 2, eventualmente richieste dal Cliente, sarà oggetto di negoziazione tra le Parti. "
    },
    "general-tos-14": {
        en: "Non Competition",
        it: "Non Concorrenza"
    },
    "general-tos-14-1": {
        en: "14.1.	Except as expressly permitted by these T&C, or except with the prior written authorization of Glickon, the Client shall not undertake, directly or indirectly, as a developer, provider, consultant, contractor or any other, even on behalf of third parties, any activities or services corresponding or even only similar to the Services performed by Glickon through the Platform. By way of example and without limitation, the Client may not, among other things: (i) use the Platform to provide or operate marketing, outsourcing, consulting, or any other commercial service; (ii) use the Platform to develop a product that competes with the Platform itself; (iii) allow third parties to access or use the Platform, except as may be provided for in these T&C and/or in the Order; (iv) use unauthorized usernames and passwords.",
        it: "Salvo quanto espressamente consentito dalle presenti Condizioni Generali o tranne che in presenza di preventiva autorizzazione scritta di Glickon, il Cliente non dovrà intraprendere, direttamente o indirettamente, in qualità di sviluppatore, fornitore, consulente, appaltatore o altro, anche per conto di terze parti, attività o servizi corrispondenti o anche solo analoghi ai Servizi svolti da Glickon attraverso la Piattaforma. A titolo meramente esemplificativo e non esaustivo, il Cliente non potrà tra l’altro: (i) usare la Piattaforma per fornire o rendere operativi servizi di marketing, outsourcing, consulenza, o qualsiasi altro servizio commerciale; (ii) usare la Piattaforma per sviluppare un prodotto che è in concorrenza con la Piattaforma stessa; (iii) consentire a terzi l’accesso o l’utilizzo della Piattaforma, salvo quanto eventualmente previsto nelle presenti Condizioni Generali e/o nell’Ordine; (iv) usare nomi utente e password non autorizzati."
    },
    "general-tos-15": {
        en: "Termination",
        it: "Risoluzione"
    },
    "general-tos-15-1": {
        en: "Each Party may terminate an Order in the event of a serious non-fulfilment of any of its obligations under these T&C by the other Party, if the latter fails to remedy such serious violation within 30 days from the receipt of a specific written warning by the fulfilling Party, without prejudice to compensation for greater damages.",
        it: "Ciascuna Parte può risolvere un Ordine in presenza di un grave inadempimento dell’altra Parte di uno qualsiasi dei propri obblighi di cui alle presenti Condizioni Generali, qualora quest’ultima non provveda a sanare tale grave inosservanza entro 30 giorni dal ricevimento di apposita diffida scritta dalla Parte adempiente, salvo in ogni caso il risarcimento del maggior danno."
    },
    "general-tos-15-2": {
        en: "Glickon may at any time request the termination of an Order, pursuant to Article 1456 of the Italian Civil Code, by sending written notice to the Client, in the event that the latter violates any of the obligations set forth in Articles 2.1, 2.2, 3.2, 4.3, 5.4, 5.5 and 14 above.",
        it: "Glickon può in ogni momento chiedere la risoluzione di un Ordine, ai sensi dell’Articolo 1456 c.c., inviando apposita comunicazione scritta al Cliente, nel caso in cui quest’ultimo violi uno qualsiasi degli obblighi previsti dagli Articoli 2.1, 2.2, 3.2, 4.3, 5.4, 5.5 e 14 che precedono."
    },
    "general-tos-15-3": {
        en: "It is understood that following the termination of an Order, these T&C shall also be automatically terminated. Unless otherwise expressly agreed between the Parties, all rights and obligations arising from the Order and in particular from the T&C shall immediately cease to have effect. In any case, Glickon has the right to retain the Fee already paid by the Client or to request the Fee accrued and due in relation to Services provided before the termination took place, as well as the right to request any greater damages.",
        it: "Resta inteso che a seguito della risoluzione di un Ordine si intenderanno automaticamente risolte anche le presenti Condizioni Generali. Salva diversa pattuizione espressa tra le Parti, tutti i diritti e gli obblighi derivanti dall’Ordine ed in particolare dalle Condizioni Generali cesseranno immediatamente di avere effetto. Resta in ogni caso salvo il diritto di Glickon di trattenere il Corrispettivo già pagato dal Cliente o di chiedere quello maturato e dovuto in ragione di Servizi forniti prima che avesse luogo la risoluzione, nonché il diritto di richiedere ogni eventuale maggior danno."
    },
    "general-tos-15-4": {
        en: "Termination shall in no way limit the right of either Party to pursue any other remedy available under the law.",
        it: "La risoluzione non limiterà in alcun modo il diritto di ciascuna Parte di esperire ogni altro rimedio disponibile ai sensi di legge. "
    },
    "general-tos-16": {
        en: "Miscellaneous",
        it: "Miscellanea"
    },
    "general-tos-16-1": {
        en: "<strong>Entire agreement</strong><br/>These T&C, the Description of Glickon’s Services and the Orders referring thereto constitute the entire agreement between the Parties and replace any and all prior or contemporaneous conditions, agreements or representations, whether oral or written, relating to the same matter.",
        it: "<strong>Intero accordo</strong><br/>Le presenti Condizioni Generali, la Descrizione dei Servizi di Glickon e gli Ordini che ad essi facciano riferimento costituiscono l’intero accordo tra le Parti e sostituiscono qualsiasi precedente o contemporanea condizione, accordo o dichiarazione, sia verbale che scritta, relativa alla materia oggetto dei medesimi."
    },
    "general-tos-16-2": {
        en: "<strong>Additions and modifications</strong><br/>The Description of Glickon’s Services may be modified by Glickon at its sole discretion at any time, without prior notice or need for acceptance by the Client. The T&C may be modified at any time by Glickon and shall be effective between the Parties, in the renewed version, unless the Client’s termination requests in the cases established in Article 15 above, only following their communication to the latter by Glickon, also electronically, without prejudice to the need for specific approval by the Client, to be carried out within 15 days of such communication, of new clauses that may fall within the scope of Article 1341 of the Italian Civil Code.",
        it: "<strong>Integrazioni e modifiche</strong><br/>La Descrizione dei Servizi di Glickon potrà essere modificata da Glickon a propria esclusiva discrezione in qualsiasi momento, senza preavviso o necessità di accettazione da parte del Cliente. Le Condizioni Generali potranno essere modificate in qualsiasi momento da parte di Glickon e saranno efficaci tra le Parti, nella versione rinnovata, salvo richieste di risoluzione da parte del Cliente nei casi stabiliti dal precedente Articolo 15, solo a seguito della loro comunicazione nei confronti di quest’ultimo da parte di Glickon, anche in via elettronica, salva la necessità di approvazione specifica da parte del Cliente, da effettuarsi entro 15 giorni da detta comunicazione, di nuove clausole eventualmente ricadenti nell’ambito di applicazione dell’Articolo 1341 c.c."
    },
    "general-tos-16-3": {
        en: "<strong>Assignment</strong><br/>The T&C and the Orders referred to therein and any rights and obligations related to them shall not be assigned by the Client without the prior written and signed authorization of Glickon.",
        it: "<strong>Cessione</strong><br/>Le Condizioni Generali e gli Ordini che ad esse facciano riferimento e qualsiasi diritto e obbligazione ad essi collegati non potranno essere ceduti dal Cliente senza previa autorizzazione scritta e firmata di Glickon. "
    },
    "general-tos-16-4": {
        en: "<strong>Maintenance of the effects</strong><br/>If any provision of these T&C is found or declared to be unlawful, void or ineffective, as permitted by applicable law such provision shall be modified by agreement between the Parties so as to make it effective or otherwise remove it, without affecting the validity and enforceability of the remaining provisions.",
        it: "<strong>Conservazione degli effetti</strong><br/>Qualora una qualsiasi disposizione delle presenti Condizioni Generali risulti o sia dichiarata illecita, nulla o inefficace, nei limiti consentiti dalla legge applicabile tale disposizione sarà modificata in accordo tra le Parti in modo da renderla efficace o altrimenti eliminata, senza che ciò possa in alcun modo inficiare la validità e l’efficacia delle restanti disposizioni. "
    },
    "general-tos-16-5": {
        en: "<strong>Force Majeure</strong><br/>No Party shall be considered in default and liable for any delay or failure to fulfill any of its obligations under these T&C when such failure results from or is due to an event beyond the reasonable control of this Party, such as but not limited to war, acts of terrorism, rebellions, sabotage, epidemics, natural disasters, explosions, fires, strikes and prolonged suspension of transportation, telecommunications or power, provided that in a reasonably timely manner the Party suffering such event gives written notice to the other Party and makes reasonable efforts to reduce the delay or avoid the default.",
        it: "<strong>Forza maggiore</strong><br/>Nessuna Parte sarà considerata inadempiente e responsabile in caso di ritardata o mancata esecuzione di uno qualsiasi dei propri obblighi ai sensi delle presenti Condizioni Generali, quando tale inadempimento risulti o sia causato da un evento al di fuori del ragionevole controllo di tale Parte, quali a titolo meramente esemplificativo e non esaustivo guerre, atti di terrorismo, ribellioni, sabotaggi, epidemie, catastrofi naturali, esplosioni, incendi, scioperi e sospensione prolungata dei trasporti, telecomunicazioni o energia, a condizione che in modo ragionevolmente tempestivo la Parte che ha subito tale evento ne dia comunicazione scritta all’altra Parte e compia ragionevoli sforzi per ridurre il ritardo o evitare l’inadempimento. "
    },
    "general-tos-16-6": {
        en: "<strong>Communications</strong><br/>Any communication relating to the T&C and the related Orders must be made by personal delivery, express courier or registered mail with return receipt, or by electronic means (by fax or certified email) to the addresses of the Parties, as identified in the Order.",
        it: "<strong>Comunicazioni</strong><br/>Qualsiasi comunicazione relativa alle Condizioni Generali e agli Ordini ad esse collegati deve essere effettuata tramite consegna di persona, corriere espresso o posta raccomandata con ricevuta di ritorno, ovvero mediante strumenti elettronici (via fax o tramite PEC) agli indirizzi delle Parti, come individuati nell’Ordine."
    },
    "general-tos-17": {
        en: "Applicable Law and Competent Court",
        it: "Legge applicabile e foro competente"
    },
    "general-tos-17-1": {
        en: "These T&C, as well as any further clauses contained in each Order signed by the Parties, are governed by Italian law.",
        it: "Le presenti Condizioni Generali, così come le eventuali ulteriori clausole contenute in ciascun Ordine sottoscritto dalle Parti, sono disciplinate dalla legge italiana."
    },
    "general-tos-17-2": {
        en: "Any litigation relating to the interpretation, validity, execution and termination of the T&C and the Orders made under them will be exclusively settled by the Court of Milan.",
        it: "Per qualsiasi controversia relativa all’interpretazione, alla validità, all’esecuzione e alla risoluzione delle Condizioni Generali e degli Ordini che ad esse sarà competente in via esclusiva il Foro di Milano."
    },
}

export default translations
