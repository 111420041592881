import { TranslationType } from "../types"
import global from "./global"
import home from "./home"
import login from "./login"
import signup from "./signup"
import employeeExperience from "./employee-experience"
import candidateExperience from "./candidate-experience"
import live from "./live"
import caseStudy from "./case-study"
import peopleAnalytics from "./people-analytics"
import platformTermsAndConditions from "./platform-terms-and-conditions"
import msTeamsTermsAndConditions from "./ms-teams-terms-and-conditions"
import generalTermsAndConditions from "./general-terms-and-conditions"
import CXTermsAndConditions from "./cx-terms-and-conditions"
import EXTermsAndConditions from "./ex-terms-and-conditions"
import termsOfServices from "./terms-of-services"
import privacy from "./privacy"
import livePrivacy from "./live-privacy"
import liveTermsAndConditions from "./live-terms-and-conditions"
import termsAndConditions from "./terms-and-conditions"
import cookie from "./cookie"
import requestADemo from "./request-a-demo"
import formsCandidate from "./forms-candidate"
import formsCorporate from "./forms-corporate"
import formsEmployee from "./forms-employee"
import formsResetPassword from "./forms-reset-password"
import resources from "./resources"
import resourcesEmployeeExperience from "./resources-employee-experience"
import resourcesCandidateExperience from "./resources-candidate-experience"
import resourcesPeopleAnalytics from "./resources-people-analytics"
import aboutUs from "./about-us"
import team from "./team"
import twoFactorAuthentication from "./two-factor-authentication"
import award from "./award"
import slaveryPrevention from "./slavery-prevention"
import ADayWithEmployee from "./adaywith-employee"
import ADayWithCandidate from "./adaywith-candidate"
import ADayWithHR from "./adaywith-hr"

const translations: TranslationType = Object.assign(
  {},
  global,
  home,
  login,
  signup,
  employeeExperience,
  candidateExperience,
  peopleAnalytics,
  live,
  caseStudy,
  platformTermsAndConditions,
  generalTermsAndConditions,
  msTeamsTermsAndConditions,
  CXTermsAndConditions,
  EXTermsAndConditions,
  liveTermsAndConditions,
  termsAndConditions,
  termsOfServices,
  privacy,
  livePrivacy,
  cookie,
  requestADemo,
  formsCandidate,
  formsCorporate,
  formsEmployee,
  formsResetPassword,
  resources,
  resourcesEmployeeExperience,
  resourcesCandidateExperience,
  resourcesPeopleAnalytics,
  aboutUs,
  team,
  twoFactorAuthentication,
  award,
  slaveryPrevention,
  ADayWithEmployee,
  ADayWithCandidate,
  ADayWithHR
)

export default translations
