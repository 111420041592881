import React from "react"
import {Company} from "../../../../types/company"
import {
  CompanyWhoWeAreSectionDescription as SectionDescription,
  CompanyWhoWeAreSectionTitle as SectionTitle,
  CompanyWhoWeAreSectionWrapper as SectionWrapper,
  CompanyWhoWeAreWebSiteUrl as WebSiteUrl,
  CompanyWhoWeAreSocialLinks as SocialLinks,
  CompanyWhoWeAreSocialIcon as SocialIcon,
  CompanyWhoWeAreCard as Card,
  CompanyWhoWeAreTitle as Title,
  CompanyWhoWeAreContent as Content,
  CompanyWhoWeAreLeftContent as LeftContent,
  CompanyWhoWeAreContentDescription as ContentDescription,
  CompanyWhoWeAreContentDivider as ContentDivider,
  CompanyWhoWeAreRightContent as RightContent,
  CompanyWhoWeAreWrapper as WhoWeAreWrapper
} from "./style"
import useTranslation from "../../../../hooks/useTranslation"
import {getCandidateImageUrl} from "../../../../utils"

interface SocialData {
  url: string
  imageUrl: string
  type: string
}

const socialPNGs = {
  facebook: "social/icn-company-fb.png",
  linkedin: "social/icn-company-in.png",
  twitter: "social/icn-company-tw.png",
  instagram: "social/icn-company-ig.png",
  youtube: "social/icn-company-yt.png"
}

interface Props {
  company: Company
}

const CompanyWhoWeAre: React.FC<Props> = ({
  company: {
    description,
    numberOfEmployees,
    websiteUrl,
    location,
    linkedinAccountUrl,
    twitterAccountUrl,
    instagramAccountUrl,
    facebookAccountUrl,
    youtubeAccountUrl
  }
}) => {
  const [translate] = useTranslation()

  const socialData: SocialData[] = [
    {url: facebookAccountUrl, imageUrl: socialPNGs.facebook, type: "facebook"},
    {url: linkedinAccountUrl, imageUrl: socialPNGs.linkedin, type: "linkedin"},
    {url: twitterAccountUrl, imageUrl: socialPNGs.twitter, type: "twitter"},
    {url: youtubeAccountUrl, imageUrl: socialPNGs.youtube, type: "youtube"},
    {
      url: instagramAccountUrl,
      imageUrl: socialPNGs.instagram,
      type: "instagram"
    }
  ].filter(element => element.url)

  const websiteUrlComponent = websiteUrl ? (
    <Section title={translate("global-company-details-website-url")}>
      <WebSiteUrl
        href={websiteUrl.match(/^http/) ? websiteUrl : `http://${websiteUrl}`}
        target="_blank"
      >
        {websiteUrl}
      </WebSiteUrl>
    </Section>
  ) : null

  const numberOfEmployeesComponent = numberOfEmployees ? (
    <Section title={translate("global-company-details-employees")}>
      {translate("global-company-details-employees-with-number", {
        numberOfEmployees
      })}
    </Section>
  ) : null

  const locationComponent = location ? (
    <Section title={translate("global-company-details-headquarter")}>
      {location.locality}, {location.country}
    </Section>
  ) : null

  const socialComponent =
    socialData && socialData.length > 0 ? (
      <Section title={translate("global-company-details-social-network")}>
        <SocialLinks>
          {socialData.map(({type, url, imageUrl}) => (
            <li key={type}>
              <a href={url} target="_blank" rel="noopener noreferrer">
                <SocialIcon
                  src={getCandidateImageUrl(imageUrl)}
                  localize={false}
                />
              </a>
            </li>
          ))}
        </SocialLinks>
      </Section>
    ) : null

  const hasInfoToShow = !!(
    websiteUrlComponent ||
    numberOfEmployeesComponent ||
    locationComponent ||
    socialComponent
  )

  return (
    <WhoWeAreWrapper className="row" id="discover-more">
      <Card>
        <Title>{translate("global-company-details-who-we-are")}</Title>
        <Content className="row company-who-we-are-content">
          <LeftContent
            className="company-who-we-are-left-content"
            fullWidth={!hasInfoToShow}
          >
            <ContentDescription
              dangerouslySetInnerHTML={{__html: description}}
            />
          </LeftContent>

          {/* used as array cause IE11 React.Fragment bug*/}
          {hasInfoToShow && [
            <ContentDivider key="company-who-we-aredivider" />,
            <RightContent
              className="company-who-we-are-right-content"
              key="company-who-we-are-right-content"
            >
              {websiteUrlComponent}
              {numberOfEmployeesComponent}
              {locationComponent}
              {socialComponent}
            </RightContent>
          ]}
        </Content>
      </Card>
    </WhoWeAreWrapper>
  )
}

const Section: React.FC<{title: string}> = ({title, children}) => (
  <SectionWrapper>
    <SectionTitle>{title}</SectionTitle>
    <SectionDescription>{children}</SectionDescription>
  </SectionWrapper>
)

export default CompanyWhoWeAre
