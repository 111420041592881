import { TranslationType } from "../types"
import {
  aboutUsKey,
  candidateExperienceKey,
  candidatePlatformKey,
  caseStudiesKey,
  caseStudyKey,
  platformCookieKey,
  documentGeneralTermsAndConditionsKey,
  employeeExperienceKey,
  homepageKey,
  jobKey,
  liveKey,
  livePrivacyKey,
  liveTermsAndConditionsKey,
  notFoundKey,
  peopleAnalyticsKey,
  platformPrivacyKey,
  requestADemoKey,
  resourcesCandidateExperienceKey,
  resourcesEmployeeExperienceKey,
  resourcesKey,
  resourcesPeopleAnalyticsKey,
  platformSecurityKey,
  teamKey,
  platformTermsAndConditionsKey,
  termsOfServicesKey
} from "./pathKeys"

const h1s: TranslationType = {
  [candidatePlatformKey]: {
    en: "",
    it: "trova nuove offerte di lavoro"
  },

  // home
  [homepageKey]: {
    en: "",
    it: "employee e candidate experience"
  },

  // request a demo
  [requestADemoKey]: {
    en: "",
    it: "piattaforma employee experience"
  },

  // products
  [candidateExperienceKey]: {
    en: "candidate experience",
    it: "candidate experience"
  },
  [employeeExperienceKey]: {
    en: "employee experience",
    it: "employee experience"
  },
  [peopleAnalyticsKey]: {
    en: "people analytics",
    it: "people analytics"
  },
  [liveKey]: {
    en: "hr gamification",
    it: "hr gamification"
  },

  // about
  [aboutUsKey]: {
    en: "",
    it: "piattaforma people analytics"
  },
  [teamKey]: {
    en: "",
    it: "piattaforma employee experience"
  },
  [jobKey]: {
    en: "",
    it: ""
  },

  // resources
  [resourcesKey]: {
    en: "",
    it: "candidate experience, employee experience, people analytics"
  },
  [resourcesEmployeeExperienceKey]: {
    en: "",
    it: "employee experience"
  },
  [resourcesCandidateExperienceKey]: {
    en: "",
    it: "candidate experience"
  },
  [resourcesPeopleAnalyticsKey]: {
    en: "",
    it: "people analytics"
  },

  // case-study
  [caseStudiesKey]: {
    en: "",
    it: "employee experience: casi di successo"
  },
  [caseStudyKey]: {
    en: "",
    it:
      "employee experience, candidate experience, people analytics: casi di successo"
  },

  // cms pages
  [platformCookieKey]: {
    en: "",
    it: ""
  },
  [documentGeneralTermsAndConditionsKey]: {
    en: "",
    it: ""
  },
  [livePrivacyKey]: {
    en: "",
    it: ""
  },
  [liveTermsAndConditionsKey]: {
    en: "",
    it: ""
  },
  [platformPrivacyKey]: {
    en: "",
    it: ""
  },
  [platformTermsAndConditionsKey]: {
    en: "",
    it: ""
  },
  [termsOfServicesKey]: {
    en: "",
    it: ""
  },
  [platformSecurityKey]: {
    en: "",
    it: ""
  },

  [notFoundKey]: {
    en: "",
    it: ""
  }
}

export default h1s
