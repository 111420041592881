import styled from "styled-components"
import styleConstants from "../../constants/styleConstants"

export const Toggle = styled.div`
  font-family: ${styleConstants.calibre};
  display: none;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    margin-left: 0.5rem;
    display: block;
  }
`

export const Languages = styled.div`
  margin-left: 0.5rem;
  display: block;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    display: none;
  }
`

interface LanguagePropsType {
  selected: boolean
}

export const Language = styled.div<LanguagePropsType>`
  opacity: ${props => (props.selected ? 1 : 0.5)};
`

export const Menu = styled.div`
  display: none;
  flex-direction: column;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: ${styleConstants.white};
  border-radius: ${styleConstants.defaultBorderRadius};
  position: absolute;
  left: 1.5rem;
  bottom: calc(100% - 0.5rem);

  &::before {
    content: "";
    position: absolute;
    left: 1rem;
    bottom: -0.35rem;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0.4rem 0.4rem 0 0.4rem;
    border-bottom-color: transparent;
    border-right-color: transparent;
    border-left-color: transparent;
    border-top-color: ${styleConstants.white};
    z-index: 999999;
  }
`

export const MenuItem = styled.div`
  font-family: ${styleConstants.calibre};
  font-size: ${styleConstants.font.size18};
  color: ${styleConstants.blue00};
  padding-bottom: 0.5rem;
  padding-right: 2rem;
  padding-left: 1rem;

  &:hover {
    padding-right: 1.75rem;
    font-family: ${styleConstants.calibreSemibold};
    color: ${styleConstants.blueGlickon};
  }
`

interface ContainerPropsType {
  hoverable: boolean
}

export const Container = styled.div<ContainerPropsType>`
  font-size: ${styleConstants.font.size18};
  display: flex;
  position: relative;
  padding: 1rem 0 3rem 0;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    margin: -1rem;
    padding: 1rem;
    cursor: ${props => (props.hoverable ? "pointer" : "default")};

    &:hover {
      ${Menu} {
        display: flex;
      }
    }
  }
`
