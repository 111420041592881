import React from "react"
import {CompanySectionInfo} from "../../../../types/companySectionInfo"
import {
  CompanySectionItemCard as Card,
  CompanySectionItemTop as Top,
  CompantSectionItemName as Name,
  CompanySectionItemDescription as Description,
  CompanySectionItemBottom as Bottom,
  CompanySectionItemImage as SectionImage
} from "./style"
import {truncate, getCandidateImageUrl} from "../../../../utils"
import Link from "../../../link"
import {companySectionKey} from "../../../../constants/pathKeys"

interface Props extends CompanySectionInfo {}

const CompanySectionItem: React.FC<Props> = ({
  name,
  shortDescription,
  description,
  id,
  backgroundUrl
}: Props) => {
  const MAX_CHAR_NUMBER = 80
  const descriptionText = truncate(
    description,
    shortDescription,
    MAX_CHAR_NUMBER
  )

  return (
    <Link to={companySectionKey} params={[{key: "id", value: id.toString()}]}>
      <Card
        id={`company-details-section-${id}`}
        className="company-details-section"
      >
        <Top>
          <Name>{name}</Name>
          <Description dangerouslySetInnerHTML={{__html: descriptionText}} />
        </Top>
        <Bottom>
          <SectionImage
            src={getCandidateImageUrl(
              backgroundUrl || "placeholders/placeholder-card-section.png"
            )}
            localize={false}
          />
        </Bottom>
      </Card>
    </Link>
  )
}

export default CompanySectionItem
