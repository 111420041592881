import styled from "styled-components"
import styleConstants from "../../constants/styleConstants"

export const FullHeight = styled.div`
  height: 100%;
`

interface BackgroundPropsType {
  shrink?: boolean
}

export const Background = styled.nav<BackgroundPropsType>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: ${styleConstants.blue00};
  height: ${styleConstants.topBarHeightMobile};
  transition: height 0.3s ease;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    height: ${props =>
      props.shrink
        ? styleConstants.topBarSmallHeight
        : styleConstants.topBarHeight};
  }

  @media print {
    display: none;
  }
`
