import styled, {css} from "styled-components"
import styleConstants from "../../../../constants/styleConstants"

export const ViewWrapper = styled.div`
    width: 100%;
    padding: 0 1rem;
    @media (min-width: ${styleConstants.desktopMinWidth}) {
        padding: 1.5rem 6rem 0;
    }
`
export const NavBar = styled.div<{
    firstChildSelected: boolean,
    customBodyBackgroundColor?: string
}>`
    width: 100%;
    height: 3rem;
    display: flex;
    background-color: ${props => props.customBodyBackgroundColor ? "#1D1D1D" : "#f4f4f4"};
    ${props => props.firstChildSelected ? css`
        border-top-left-radius: 4px;
    ` : css`
        border-top-right-radius: 4px;
    `};
`
export const NavBarItem = styled.div<{
    selected: boolean,
    customBodyBackgroundColor?: string,
    customPrimaryTextColor?: string
}>`
    width: 50%;
    background-color: ${props => props.selected ? (
        props.customBodyBackgroundColor ? props.customBodyBackgroundColor : styleConstants.white) : "transparent"
    };
    color: ${props => props.customPrimaryTextColor ? props.customPrimaryTextColor : styleConstants.blue00};
    font-family: ${styleConstants.galanoGrotesqueSemibold};
    font-size: 16px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${props => props.selected ? "default" : "pointer"};
    ${props => props.selected ? css`
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    ` : ""};
`
