import { TranslationType } from "../types"

const translations: TranslationType = {
  "privacy-title": {
    en: "Privacy Policy<br/>(pursuant to Art. 13 of the General Data Protection Regulation)",
    it: "Informativa sul trattamento dei dati personali<br/>(ai sensi dell’Art. 13 del Regolamento Generale sulla Protezione dei Dati)"
  },
  "privacy-pre-list-text": {
    en: "",
    it: ""
  },
  "privacy-last-modified": {
    en: "Milan, 7th April 2021",
    it: "Milano, 7 Aprile 2021"
  },
  "privacy-1": {
    en: "1. Introduction",
    it: "1. Introduzione"
  },
  "privacy-1-1": {
    en: "If you'd like to take just a few minutes, we'll explain in a simple way what we do with your personal data that we collect when you access and use our service platform, or any of our related mobile Apps (collectively, the <strong>'Platform'</strong>), accessible from <a href=\"http://www.glickon.com\" target=\"_blank\" rel=\"noopener noreferrer\">www.glickon.com</a> (the <strong>'Site'</strong> and, together with the Platform, the <strong>'Services'</strong>).",
    it: "Se vorrai dedicarci solo qualche minuto, ti spiegheremo in maniera semplice cosa facciamo con i tuoi dati personali che raccogliamo quando accedi ed utilizzi la nostra piattaforma di servizi, o eventuali nostre App mobili ad essa collegate (nel complesso la <strong>‘Piattaforma’</strong>), accessibile dal sito <a href=\"http://www.glickon.com\" target=\"_blank\" rel=\"noopener noreferrer\">www.glickon.com</a> (il <strong>‘Sito’</strong> e, insieme alla Piattaforma, i <strong>‘Servizi’</strong>)."
  },
  "privacy-1-2": {
    en: "The applicable legislation, namely mainly the EU Regulation No. 2016/679 (<i>General Data Protection Regulation</i>, <strong>'GDPR'</strong>), the Legislative Decree. 196/2003, as subsequently amended and supplemented (the <strong>'Privacy Code'</strong>) and the relevant provisions, decisions and guidelines issued by the Italian Data Protection Authority (collectively, the <strong>'Privacy Law'</strong>), identify various figures who must - or sometimes simply can - enter the scene, taking part in data processing operations, with different roles and responsibilities based on the degree of involvement and the discretionary power they may exercise with respect to how and why your data may be processed.",
    it: "La normativa applicabile, vale a dire principalmente il Regolamento (UE) n. 2016/679 (<i>Regolamento Generale sulla Protezione dei Dati</i>, <strong>‘GDPR’</strong>), il D.lgs. 196/2003, come successivamente modificato ed integrato (il <strong>‘Codice Privacy’</strong>) ed i provvedimenti, le decisione e le linea guida rilevanti emanate dall’Autorità Garante per la protezione dei dati personali (nel complesso, la <strong>‘Normativa Privacy’</strong>), identificano varie figure che devono – o a volte semplicemente possono – entrare in scena, prendendo parte alle operazioni di trattamento di dati, con ruoli e responsabilità differenti in base al grado di coinvolgimento e al potere discrezionale che possono esercitare rispetto al come e al perché possono essere trattati i tuoi dati. "
  },
  "privacy-1-3": {
    en: `
      <strong>Data Controller</strong>
    `,
    it: `
      <strong>Titolare del trattamento</strong>
    `
  },
  "privacy-1-3-1": {
    en: `
      The main actor is called 'controller' and freely and autonomously determines the purposes, methods and tools of the processing, therefore having to ensure the security of your data and the protection of all your rights in accordance with the Privacy Law.<br/><br/>
      <u>As developer and administrator of the Platform, the controller of the processing described in this information notice is Glickon S.r.l., a company under Italian law with registered office in Milan (20129), Via Nino Bixio 7, VAT number, tax code and registration number with the Register of Companies of Milan 08573820969 (<strong>'Glickon'</strong>, the <strong>'Controller'</strong> or <strong>'we/us'</strong>)</u>.
    `,
    it: `
      L’attore protagonista è denominato ‘titolare’ e determina in maniera libera ed autonoma le finalità, le modalità e gli strumenti del trattamento, dovendo pertanto garantire la sicurezza dei tuoi dati e la tutela di tutti i tuoi diritti conformemente alla Normativa Privacy.<br/><br/>
      <u>In veste di sviluppatore e gestore della Piattaforma, il titolare dei trattamenti descritti nella presente informativa è Glickon S.r.l., società di diritto italiano con sede legale a Milano (20129), in Via Nino Bixio 7, partita IVA, codice fiscale e numero di iscrizione al Registro delle Imprese di Milano 08573820969 (<strong>‘Glickon’</strong>, il <strong>‘Titolare’</strong> o <strong>‘noi/ci’</strong>).</u>
    `
  },
  "privacy-1-4": {
    en: "Other actors may also come into play, but they will not have a leading role, but a supporting - albeit fundamental - role towards the data controller. More precisely, the Privacy Law establishes that one or more specific processing activities can be delegated to external service providers, as long as they are reliable, called <u>'data processors'</u>, who will be able to act exclusively on behalf of and under written instruction - and therefore as an operational <i>longa manus</i> - of the data controller. ",
    it: "Possono entrare in gioco anche altri attori che, tuttavia, non avranno un ruolo da protagonisti, ma di supporto – seppur fondamentale – nei confronti del titolare del trattamento. Più precisamente, la Normativa Privacy stabilisce che una o più specifiche attività di trattamento possano essere delegate a fornitori esterni di servizi, purché affidabili, denominati <u>‘responsabili del trattamento’</u>, i quali potranno agire esclusivamente per conto e su istruzione scritta – e dunque come <i>longa manus</i> operativa – del titolare."
  },
  "privacy-1-5": {
    en: "Please note that whenever we speak of <u>'processing'</u> of data, we will be referring to any operation or set of operations carried out, by automated means or otherwise, on or through personal data, such as the collection, recording, organization, storage, adaptation or modification, extraction, consultation, use, communication by transmission, dissemination or any other form of making available, comparison, erasure or destruction of data. ",
    it: "Tieni presente che, ogni volta in cui parleremo di <u>‘trattamento’</u> di dati, si farà riferimento a qualsiasi operazione o insieme di operazioni svolte, in maniera automatizzata o meno, su dati personali o attraverso di essi, come ad esempio la raccolta, la registrazione, l’organizzazione, la conservazione, l’adattamento o la modifica, l’estrazione, la consultazione, l’uso, la comunicazione mediante trasmissione, diffusione o qualsiasi altra forma di messa a disposizione, il raffronto, la cancellazione o la distruzione di dati. "
  },
  "privacy-1-6": {
    en: "Having said that, it is worth pointing out that <u>'personal data'</u> are not at all only those immediately referable to you (e.g. name and last name), but also those which, in combination with other information available to us or to any other party, would allow you to be identified in any case (e.g. your tax code or the IP address of the device with which you access the Platform).",
    it: "Ciò detto, bisogna precisare che <u>‘dati personali’</u> non sono affatto solo quelli immediatamente riferibili a te (es. nome e cognome), ma anche quelli che, in combinazione con altre informazioni a disposizione nostra o di qualunque altra parte, permetterebbero comunque id identificarti (es. il tuo codice fiscale o l’indirizzo IP del dispositivo con cui accedi alla Piattaforma)."
  },
  "privacy-1-7": {
    en: "The processing operations carried out in connection with the Services will take place exclusively in Italy, in full compliance with the current Privacy Law.",
    it: "Le operazioni di trattamento svolte in collegamento ai Servizi avverranno esclusivamente in Italia, in piena conformità con la vigente Normativa Privacy."
  },
  "privacy-2": {
    en: "2. Your privacy in the context of our services",
    it: "2. La tua Privacy nell'ambito dei nostri servizi"
  },
  "privacy-2-1": {
    en: "Both the Platform and the Website have been designed and created in such a way as to reduce the collection and use of personal data as much as possible, excluding the processing in all cases where it is possible to achieve the purposes of such Services, both by Glickon and by our client active on the Platform (<strong>'Partner Companies'</strong>), in ways that are less invasive of your privacy.",
    it: "Sia la Piattaforma che il Sito sono stati studiati e realizzati in maniera tale da ridurre quanto più possibile la raccolta e l’utilizzo di dati personali, escludendo il trattamento in tutti i casi in cui sia possibile raggiungere le finalità di tali Servizi, sia da parte di Glickon che delle società nostre clienti attive nell’ambito della Piattaforma (<strong>‘Aziende Partner’</strong>), con modalità meno invasive della tua privacy."
  },
  "privacy-2-2": {
    en: `
      The Platform allows the execution of numerous services, but its main objective is to allow Partner Companies to use it to submit their users to gaming activities:<br/>
      <ul>
      <li>directly or by giving specific instructions to Glickon, consisting in 'challenges' - i.e. challenges based on skill and knowledge tests - aimed at assessing the User's skills and competencies in certain subjects. The User may also participate just for fun, to train and develop his knowledge in specific areas, without the involvement of any Partner Company; and/or</li>
      <li>in the HR field, for a variety of purposes at the discretion of the Partner Company involved from time to time (e.g. <i>onboarding</i>, <i>training</i>, internal communication, <i>skill mapping</i>, surveys).</li>
      </ul>
    `,
    it: `
      La Piattaforma, in particolare, consente lo svolgimento di numerosi servizi, ma l’obiettivo principale della stessa è consentire alle Aziende Partner di utilizzare sottoporre i propri utenti ad attività di gioco:<br/>
      <ul>
      <li>direttamente o dando specifico incarico a Glickon, consistenti in <i>‘challenge’</i> – vale a dire sfide basate su test di abilità e conoscenza – volte a valutare le competenze degli utenti riguardo a determinate materie. L’Utente potrà partecipare anche solo per puro divertimento, per allenare e sviluppare le proprie conoscenze in ambiti specifici, senza coinvolgimento di alcuna Azienda Partner; e/o</li>
      <li>in ambito HR, per le più svariate finalità a discrezione dell’Azienda Partner di volta in volta coinvolta (es. attività di <i>onboarding</i>, di <i>training</i>, di comunicazione interna, di <i>skill assessment</i>, sondaggi).</li>
      </ul>
    `
  },
  "privacy-2-3": {
    en: "<u>This policy refers only to the processing operations carried out through the Platform and does not apply in any case to websites, applications and platforms of third parties, including any social networks, which may be accessible from or through it.</u> We therefore invite you to carefully read their <i>Privacy policies</i>, before accessing or using their services, in order to understand exactly how their managers will treat your data.",
    it: "<u>Questa informativa è riferita unicamente alle operazioni di trattamento svolte attraverso la Piattaforma e non si applica in alcun caso a siti web, applicazioni e piattaforme di terze parti, inclusi eventuali social network, eventualmente accessibili da o per mezzo di essa.</u> Ti invitiamo quindi a leggere con attenzione le rispettive Privacy policy, prima di accedere o utilizzare i loro servizi, per comprendere con esattezza come i relativi gestori tratteranno i tuoi dati."
  },
  "privacy-3": {
    en: "3. What personal data we process",
    it: "3. Quali dati personali trattiamo"
  },
  "privacy-3-1": {
    en: "The data about you that we will collect and process as data controller, for the purposes described in Par. 4, are as follows:",
    it: "I dati che ti riguardano che raccoglieremo e tratteremo in qualità di titolare del trattamento, per le finalità descritte al Par. 4, sono i seguenti:"
  },
  "privacy-3-2": {
    en: `
    <strong>A.	Navigation Data</strong><br/>
    The computer systems used for the correct functioning of our Services collect, in the course of their normal operation, some data necessary for the use of Internet network protocols (e.g. the IP address of the device you use to access and navigate on it). In the absence of additional information, this type of data does not allow us to identify you, but only to enable your access, your navigation and your correct use of our Services.    
    `,
    it: `
    <strong>A. Dati di navigazione</strong><br/>
    I sistemi informatici utilizzati per il corretto funzionamento dei nostri Servizi raccolgono, nel corso del loro normale esercizio, alcuni dati necessari per l’utilizzo dei protocolli di rete Internet (es. l’indirizzo IP del dispositivo che utilizzi per accedere e navigare su di essa). In assenza di informazioni aggiuntive, questa tipologia di dati non ci consente di identificarti, ma solo di abilitare il tuo accesso, la tua navigazione ed il tuo corretto utilizzo dei nostri Servizi.
   `
  },
  "privacy-3-3": {
    en: `
    <strong>B.	Data collected through cookies</strong><br/>
    We use technical and functional cookies to make your navigation on the Website and the Platform easier and more pleasant. For further information on these cookies and, in particular, on the analytical and profiling cookies which, eventually, if you allow us to do so, we will install for your better use of our Services, please refer to our <a href="https://www.glickon.com/en/platform/cookies" target="_blank" rel="noopener noreferrer">Cookie policy</a>.
    `,
    it: `
    <strong>B. Dati raccolti attraverso i cookies</strong><br/>
    Utilizziamo cookie tecnici e funzionali per rendere più semplice e piacevole la tua navigazione sia sul Sito che nella Piattaforma. Per ricevere maggiori chiarimenti su questi cookies e, in particolare, su quelli analitici e di profilazione che, se ce lo consentirai, installeremo per una tua migliore fruizione dei nostri Servizi, fai riferimento alla nostra <a href="https://www.glickon.com/it/piattaforma/cookies" target="_blank" rel="noopener noreferrer">Cookie policy</a>.
   `
  },
  "privacy-3-4": {
    en: `
    <strong>C.	Contact Information Related to Your Requests</strong><br/>
    If you write to us to receive information or assistance about our Services, we will inevitably need to collect and process data that you choose to provide to us in connection with and in the context of your requests. In particular:<br/>
    <ul>
      <li><u>general information</u>: if you write to <a href="mailto:support@glickon.com">support@glickon.com</a> (including from 'Contact Us'), we will receive your email address, as well as any other personal data you choose to share with us in the subject line, content and any attachments to your communication. You do not need to be registered to send requests to our email address;</li>
      <li><u>request a demo</u>: we have created a form through which you can get a demo of the Platform. We will ask you for your name, last name, company, job position, business email and telephone, in order to be able to respond in the most efficient and specific way to your request;</li>
      <li><u>request for assistance</u>: only if you have registered with the Platform (see point E below), you will be able to request our support at any time by simply clicking on the appropriate icon at the bottom right. We will ask for your name and e-mail address, so that we can contact you again to provide you with all the necessary assistance.</li>
    </ul>
    `,
    it: `
    <strong>C. Dati di contatto connessi alle tue richieste</strong><br/>
    Se ci scriverai per ricevere informazioni o assistenza sui nostri Servizi, dovremo inevitabilmente raccogliere e trattare i dati che deciderai di fornirci in relazione e nel contesto delle tue richieste. In particolare:<br/>
    <ul>
      <li><u>informazioni generali</u>: se scriverai a <a href="mailto:support@glickon.com">support@glickon.com</a> (anche dal ‘Contattaci’), riceveremo il tuo indirizzo e-mail, oltre ad ogni altro dato personale che deciderai di condividere con noi nell’oggetto, nel contenuto ed in qualunque allegato della tua comunicazione. Non è necessario essere registrati per inviare richieste al nostro indirizzo di posta elettronica;</li>
      <li><u>richiesta di una demo</u>: abbiamo creato un modulo attraverso cui potrai ottenere una <i>demo</i> della Piattaforma. Ti chiederemo nome, cognome, azienda, posizione, e-mail aziendale e telefono, per poter rispondere nella maniera più efficiente e specifica alla tua richiesta;</li>
      <li><u>richiesta di assistenza</u>: solo se ti sarai registrato alla Piattaforma (vedi il successivo punto E), potrai in ogni momento richiedere il nostro supporto semplicemente cliccando sull’apposita icona in basso a destra. Ti chiederemo nome ed e-mail, così da poterti ricontattare per fornirti tutta l’assistenza necessaria.</li>
    </ul>
   `
  },
  "privacy-3-5": {
    en: "We do not need any other data than those just mentioned. Therefore, <u>we invite you not to send us, through any attached documents or in the free fields available in the appropriate forms (which we have included only to allow you to describe more precisely your needs), personal data other than those strictly necessary to allow us to follow up your request.</u> In most cases, we will immediately delete this additional data, unless there is a legal basis under Article 6 of the GDPR that justifies its processing by us.",
    it: "Non abbiamo bisogno di altri dati rispetto a quelli appena indicati. Pertanto, <u>ti invitiamo a non inviarci, tramite eventuali documenti allegati o nei campi liberi disponibili negli appositi moduli (che abbiamo inserito solo per permetterti di descrivere con maggior precisione le tue esigenze), dati personali che non siano quelli strettamente necessari a permetterci di dare seguito alla tua richiesta.</u> Nella maggior parte dei casi provvederemo alla immediata cancellazione di questi dati aggiuntivi, a meno che non esista una base giuridica che, ai sensi dell’art. 6 del GDPR, ne giustifichi il trattamento da parte nostra."
  },
  "privacy-3-6": {
    en: `
    <strong>D.	Registration data</strong><br/>
    Navigation on the Site is completely free. Access to the Platform, however, requires your registration. This can be done in two ways:<br/>
    <ul>
      <li>
        <strong>New Registration</strong><br/>
        To create your personal account on the Platform, we will only ask you for your first name, last name and email address. Of course, you will have to create your own password, which you will have to keep secret and safe.
      </li>
      <li>
        <strong><i>Social log-in</i></strong><br/>
        By Social Login we mean the technology that allows you to share in an automated way with the administrators of websites or web platforms - in this case us - some personal data linked to your account on any social network. In short, if you decide to log in with your Google, Facebook or Linkedin credentials (at the moment we have activated the link with these third party sites), their operators can send us the data necessary for your registration on the Platform, without you having to enter them manually. In this case, therefore, some fields of the registration or log-in form will be preloaded, drawing on the information made available by the third parties involved based on the preferences you set on their platforms. For more detailed information regarding the personal data that may be shared, we invite you to carefully read the Privacy Policy of the third party operators involved (<u>Facebook, Linkedin, Google</u>).<br/><br/>
        <u>It is understood that Glickon will never be able to take possession of your private credentials, as these will remain under your exclusive control and that of the third party responsible for them.</u><br/><br/>
        <u>Obviously, you can disconnect your social login from the Platform at any time, it being understood that this will prevent your access to the Platform until you re-register in one of the two ways described above.</u>
      </li>
    </ul>
    `,
    it: `
    <strong>D. Dati di registrazione</strong><br/>
    La navigazione sul Sito è completamente libera. L’accesso alla Piattaforma, invece, presuppone la tua registrazione. La stessa potrà avvenire in due modalità:<br/>
    <ul>
        <li>
            <strong>Nuova registrazione</strong><br/>
            Per creare il tuo <i>account</i> personale nella Piattaforma, ti chiederemo solamente nome, cognome ed e-mail. Dovrai ovviamente creare una tua <i>password</i>, da tenere segreta e al sicuro.
        </li>
        <li>
            <strong><i>Social log-in</i></strong><br/>
            Con <i>Social Login</i> si intende quella tecnologia che ti permette di condividere in modalità automatizzata con i gestori di siti o di piattaforme <i>web</i> – in questo caso noi – alcuni dati personali collegati al tuo account su un qualsiasi <i>social network</i>. In breve, laddove decidessi di effettuare il <i>login</i> con le tue credenziali di Google, Facebook o di Linkedin (al momento abbiamo attivato il collegamento con questi siti terzi), i relativi gestori potranno inviarci i dati necessari alla tua registrazione sulla Piattaforma, senza bisogno che tu li inserisca manualmente. In questo caso, quindi, alcuni campi del <i>form</i> di registrazione o di <i>log-in</i> saranno precaricati, attingendo alle informazioni rese disponibili dalle terze parti coinvolte sulla base delle preferenze da te impostate sulle loro piattaforme. Per informazioni più dettagliate riguardo ai dati personali che potranno essere condivisi, ti invitiamo a leggere con attenzione le Privacy Policy dei terzi gestori coinvolti (<u>Facebook, Linkedin, Google</u>).<br/><br/>
            <u>Resta inteso che Glickon non potrà mai entrare in possesso delle tue credenziali private, dato che le stesse resteranno sotto l’esclusivo controllo tuo e della terza parte di volta in volta competente.</u><br/><br/>
            <u>Ovviamente potrai disconnettere il tuo Social login dalla Piattaforma in qualunque momento, fermo restando che ciò impedirà il tuo accesso alla stessa fino a quando non ti sarai nuovamente registrato in uno dei due modi appena descritti.</u>
        </li>
    </ul>
   `
  },
  "privacy-3-7": {
    en: `
      <strong>E.	Data related to your personal profile in the Platform</strong><br/>
      The only data required to access the Platform, by creating your personal account, are those indicated at letter D above (first name, last name and e-mail or, in case of social login, your access credentials to the third party platform connected to ours). <br/><br/>
      <u>Once you have registered to the Platform, however, you can decide at any time, spontaneously <strong>(we will never ask you to do so, but eventually only directly by the Partner Companies)</strong>, to enrich your profile with more detailed information both at the personal level and regarding your education, experience and skills, providing us with one or more of the following additional data</u>: date of birth; gender; nationality; short biography (free field); telephone number; home address; level of studies (eg. (e.g. diploma, bachelor's degree, master's degree, doctorate); university (if any); degree and, if obtained, year and grade; company (if any) and role; <i>curriculum vitae</i> (to be attached).<br/><br/>
      <u>Never send sensitive data, such as those concerning your health status or your political opinions or religious beliefs, not even in attached documents, unless this kind of information is strictly necessary to allow Partner Companies that use the Platform for recruitment purposes, under their sole responsibility as data controllers, to evaluate your application for specific job positions, pursuant to Art. 9.2, letter b) of the GDPR.</u>
    `,
    it: `
      <strong>E. Dati relativi al tuo profilo personale sulla Piattaforma</strong><br/>
      Gli unici e soli dati necessari per accedere alla Piattaforma, creando il tuo <i>account</i> personale, sono quelli indicati alla precedente lettera D (nome, cognome ed e-mail oppure, in caso di <i>social login</i>, le tue credenziali di accesso alla piattaforma terza collegata alla nostra). <br/><br/>
      <u>Una volta effettuata la registrazione alla Piattaforma, tuttavia, potrai in qualsiasi momento decidere, del tutto spontaneamente (<strong>non saremo mai noi a richiedertelo, ma eventualmente solo direttamente le Aziende Partner</strong>), di arricchire il tuo profilo con informazioni più dettagliate sia a livello anagrafico che riguardanti la tua formazione, esperienza e competenze</u>, fornendoci uno o più dei seguenti ulteriori dati: data di nascita; genere; nazionalità; breve biografia (campo libero); numero di telefono; indirizzo di residenza; livello di studi (es. diploma, laurea breve, laurea specialistica, master, dottorato); università eventualmente frequentata; laurea e, se conseguita, anno e voto; eventuale azienda di appartenenza e ruolo; <i>curriculum vitae</i> (da allegarsi).<br/><br/>
      <u>Non inviare mai dati sensibili, come quelli riguardanti il tuo stato di salute o le tue opinioni politiche o convinzioni religiose, neanche in documenti allegati, a meno che questo genere di informazioni non sia strettamente necessaria a permettere alle Aziende Partner che utilizzano la Piattaforma per finalità di <i>recruitment</i>, sotto la loro esclusiva responsabilità in quanto titolari del trattamento, di valutare la tua candidatura per specifiche posizioni lavorative, ai sensi dell’Art. 9.2, lett. b) del GDPR.</u>
   `
  },
  "privacy-3-8": {
    en: `
      <strong>F.	Newsletter</strong><br/>
      If you want to subscribe to our Newsletter and keep up to date with news about Glickon and our services and events, we will only ask you for your first name, last name and e-mail.    
    `,
    it: `
      <strong>F. Newsletter</strong><br/>
      Per iscriverti alla nostra <i>Newsletter</i> e restare sempre aggiornato sulle novità riguardanti Glickon e i nostri servizi ed eventi, ti chiederemo solamente nome, cognome ed e-mail.
    `
  },
  "privacy-3-9": {
    en: `
      <strong>G.	Data resulting from your participation in Glickon Challenges</strong><br/>
      <u>All the content (e.g. text, images, graphics, video, audio and any other information) present in the private pages of the Partner Companies within the Platform, as well as any processing of personal data following your access to these pages and their content, including the Challenges, are the exclusive responsibility of the Partner Company that has uploaded them and manages them independently.</u><br/><br/>
      <strong>Pursuant to the Privacy Law in force, therefore, each of the Partner Companies is configured as the sole and only controller, while Glickon is the processor on behalf of each of them, in accordance with the provisions of Art. 28 of the GDPR, with reference to any processing operation subsequent to your registration to a Challenge of a Partner Company.</strong><br/><br/>
      <u><strong>Glickon is the controller only of the processing related to the Challenges organized on its behalf, accessible in the public pages of the Platform</strong> (for which your registration is still required).</u><br/><br/>
      In the latter case, we will collect and process only those personal data that may be contained in the answers you provide to the tests carried out, processing them, as explained below, to assess your level of competence with respect to the specific subject matter of the test.  
    `,
    it: `
      <strong>G. Dati derivanti dalla tua partecipazione alle <i>Challenges</i> di Glickon</strong><br/>
      <u>Tutti i contenuti (es. testi, immagini, grafiche, video, audio e qualsiasi altra informazione) presenti nelle pagine private delle Aziende Partner all’interno della Piattaforma, così come ogni trattamento di dati personali successivo al tuo accesso a tali pagine e ai relativi contenuti, ivi ovviamente incluse le Challenges, ricadono sotto l’esclusiva responsabilità dell’Azienda Partner che li ha caricati e li gestisce in autonomia.</u><br/><br/>
      <strong>Ai sensi della vigente Normativa Privacy, pertanto, ciascuna delle Azienda Partner si configura quale unico e solo titolare, mentre Glickon quale responsabile per conto di ognuna di esse, secondo quanto stabilito dall’Art. 28 del GDPR, in riferimento a qualsiasi operazione di trattamento successiva alla tua iscrizione ad una Challenge di un’Azienda Partner.</strong><br/><br/>
      <u><strong>Glickon è titolare unicamente dei trattamenti connessi alle Challenges organizzate per proprio conto, accessibili nelle pagine pubbliche della Piattaforma</strong> (per cui è comunque necessaria la tua registrazione)</u>.<br/><br/>
      In quest’ultimo caso, raccoglieremo e tratteremo solamente quei dati personali che dovessero essere contenuti nelle risposte da te fornite ai test svolti, elaborandoli, come spiegato meglio più avanti, per valutare il tuo grado di competenza rispetto alla materia specifica oggetto del test.  
    `
  },
  "privacy-4": {
    en: "4. Purpose and legal basis of the processing",
    it: "4. Finalità e basi giuridiche del trattamento"
  },
  "privacy-4-1": {
    en: "The control over what is done with your data is and must remain solely in your hands. For this reason, it is our duty to provide you with every detail necessary to understand the processings that we may carry out through the Platform and, for each of them, to indicate the legal basis that allows us - or would allow us, if the processing is based on your consent - to carry out such activities.",
    it: "Il controllo su ciò che viene fatto con i tuoi dati è e deve restare unicamente nelle tue mani. Per questo motivo, è nostro dovere fornirti ogni dettaglio necessario a comprendere i trattamenti che potremo svolgere attraverso la Piattaforma e, per ciascuno di essi, indicarti fondamento legale che ci permette – o permetterebbe, qualora il trattamento sia basato sul tuo consenso – di eseguire tali attività."
  },
  "privacy-4-2": {
    en: `
      <strong><i>Navigating the Services</i></strong><br/>
      Based on our legitimate interest (Art. 6.1, f), of the GDPR), we will collect and process only those few technical data that are necessary to allow you to easily access and navigate seamlessly on the Platform and the Website, thus using our Services without slowdowns or impediments resulting from the operation of Internet protocols. We emphasize that this information does not allow us to identify you, except after associating and comparing it with specific and separate personal data.    
    `,
    it: `
      <strong><i>Navigazione dei Servizi</i></strong><br/>
      In base al nostro legittimo interesse (Art. 6.1, f), del GDPR), raccoglieremo e tratteremo solo quei pochi dati tecnici che sono necessari per consentirti di accedere facilmente e navigare senza problemi sulla Piattaforma e sul Sito, utilizzando quindi i nostri Servizi senza rallentamenti o impedimenti derivanti dal funzionamento dei protocolli Internet. Sottolineiamo che queste informazioni non ci consentono di identificarti, se non dopo averle associate e confrontate con specifici e separati dati personali.
    `
  },
  "privacy-4-3": {
    en: `
      <strong><i>Improve your experience of using the Services through the use of cookie</i></strong><br/>
      For more detailed information regarding the use of technical, functional, analytical and profiling cookies on the Website and Platform, please read our <a href="https://www.glickon.com/en/platform/cookies" target="_blank" rel="noopener noreferrer">Cookie policy</a>.
    `,
    it: `
      <strong><i>Migliorare la tua esperienza d’uso dei Servizi grazie ai cookies</i></strong><br/>
      Per informazioni più dettagliate riguardo all’uso di cookie tecnici, funzionali, analitici e di profilazione sul Sito e sulla Piattaforma, ti preghiamo di leggere la nostra <a href="https://www.glickon.com/it/piattaforma/cookies" target="_blank" rel="noopener noreferrer">Cookie policy</a>.
    `
  },
  "privacy-4-4": {
    en: `
      <strong><i>Response to your contact and support request</i></strong><br/>
      If you decide to contact us by email, or in any of the other available ways, for any reason such as, for example, to ask us for information or assistance regarding the Services, we will only process your data in order to answer and follow up on your request (Art. 6.1, b), of the GDPR). <u>Without your data, we will not be able to provide you with any feedback.</u>
    `,
    it: `
      <strong><i>Riscontro alle tue richieste di contatto e di supporto</i></strong><br/>
      Se deciderai di contattarci via e-mail, o in un’altra qualunque delle modalità disponibili, per qualsiasi ragione come, ad esempio, chiederci informazioni o assistenza in merito ai Servizi, tratteremo i tuoi dati solo per risponderti e dare seguito alla tua richiesta (Art. 6.1, b), del GDPR). <u>In assenza dei tuoi dati, non saremo in grado fornirti alcun riscontro.</u>
    `
  },
  "privacy-4-5": {
    en: `
      <strong><i>Manage your Platform registration and personal account</i></strong><br/>
      In order to execute your request for access to the Platform (Art. 6.1, b) of the GDPR), we will ask you in advance to register, providing us with only those data (<u>identified above</u>) that are strictly necessary for the creation of your personal account. <u>If you prefer not to register and not to provide us with any data for this purpose, you may continue to freely browse the Website, but not enter the Platform.</u><br/>
      We may also use your registration data, with particular reference to your email, to send you service communications, i.e. strictly related to the management of your personal profile and the operation of the Platform and its services, without any promotional or commercial purpose.<br/>
      If, after creating your account, you decide to enrich it by spontaneously sharing more detailed information (<u>as specified above</u>) both of a personal nature and regarding your educational and professional experiences, we will use this additional data of yours, based on our legitimate interest (Art. 6.1, f) of the GDPR), exclusively to make it available to the Partner Companies that request it and only in the case that you decide to participate in one of their Challenges, to avoid being asked again during the registration phase. <u>You may object at any time to the processing of this specific category of data by us.</u>
    `,
    it: `
      <strong><i>Gestione della tua registrazione alla Piattaforma e del tuo account personale</i></strong><br/>
      Per dare esecuzione alla tua richiesta di accesso alla Piattaforma (Art. 6.1, b) del GDPR), ti chiederemo preventivamente di registrarti, fornendoci solo quei dati (<u>sopra identificati</u>) strettamente necessari per la creazione del tuo account personale. <u>Se preferisci non registrarti e non fornirci alcun dato per questa finalità, potrai continuare a navigare liberamente sul Sito, ma non entrare nella Piattaforma</u>.<br/><br/>
      Potremo utilizzare i tuoi dati di registrazione, con particolare riferimento all’e-mail, anche per inviarti comunicazioni di servizio, vale a dire strettamente legate alla gestione del tuo profilo personale e al funzionamento della Piattaforma e dei suoi servizi, senza alcuno scopo promozionale o commerciale.<br/><br/>
      Se dopo aver creato il tuo <i>account</i>, deciderai anche di arricchirlo, condividendo spontaneamente informazioni più dettagliate (<u>sopra specificate</u>) sia di natura anagrafica che riguardanti le tue esperienze formative e professionali, useremo questi tuoi ulteriori dati, in base al nostro legittimo interesse (Art. 6.1, f) del GDPR), esclusivamente per metterli a disposizione delle Aziende Partner che dovessero richiederli e nel solo caso in cui tu decidessi di partecipare ad una delle loro <i>Challenges</i>, per evitare che ti vengano chiesti nuovamente nella fase di relativa iscrizione. <i>Potrai opporti in qualsiasi momento al trattamento da parte nostra di questa specifica categoria di dati.</i>
    `
  },
  "privacy-4-6": {
    en: `
      <strong><i>Sending newsletter</i></strong><br/>
      Only if you provide us with your specific consent to receive marketing communications (art. 6.1, a) of the GDPR), including by subscribing to our Newsletter, we may send you messages and emails of a commercial and promotional nature regarding our Services. <u>It is understood that you may revoke your consent at any time, without affecting the lawfulness of the processing carried out by us up to that moment.</u>
    `,
    it: `
      <strong><i>Invio di newsletter</i></strong><br/>
      Solo se ci fornirai il tuo specifico consenso al ricevimento di comunicazioni di marketing (art. 6.1, a) del GDPR), anche iscrivendoti alla nostra <i>Newsletter</i>, potremo inviarti messaggi ed e-mail di natura commerciale e promozionale riguardanti i nostri Servizi. <u>Resta inteso che potrai revocare il tuo consenso in qualsiasi momento, senza che ciò pregiudichi la liceità dei trattamenti da noi svolti fino a quel momento.</u>
    `
  },
  "privacy-4-7": {
    en: `
      <strong><i>Managing the outcomes of our Challenges - 'Skills' section of the Platform</i></strong><br/>
      It is fundamental to clarify that we are referring here only and exclusively to the Challenges included in the <strong><i>'Competences'</i></strong> section of the Platform, i.e. those that Glickon organises and manages autonomously, as holder of the data, without any mandate from the Partner Companies. For the Challenges organised by the latter, in fact, you will receive a separate privacy notice, before confirmation of your enrolment in each of them, directly from the Partner Company involved from time to time and under its exclusive responsibility, as autonomous data controller, as already mentioned.<br/>
      The data connected to the results of the Challenges in which you have taken part will be processed by us solely <strong>for the purpose of examining your level of competence and knowledge of the subject matter of the test performed</strong>, including by means of <strong>the elaboration of a score on the basis of three factors consisting of (i) the time taken to answer; (ii) the number of correct answers provided by you with respect to the total; (iii) the pre-assigned value of the specific answer you have chosen, based on its importance and difficulty.</strong><br/>
      <u>The legal basis applicable to this processing is our legitimate interest (art. 6.1, a) of the GDPR) to carry out as efficiently as possible those evaluation activities that are the basis of this specific service - among other things, the only one that Glickon provides autonomously through its Platform - of analysis of your level of preparation and experience in specific areas. You may oppose at any time, for your own personal reasons, the carrying out by us of this type of processing, without this affecting in any way what has already been done up to that moment.</u><br/>
      Among other things, you will be free to decide, through appropriate settings of your personal account, whether to make visible and possibly to whom some information about your profile and your skills.    
    `,
    it: `
      <strong><i>Gestione dei risultati delle nostre Challenges – Sezione ‘Competenze’ della Piattaforma</i></strong><br/>
      È fondamentale precisare che qui si fa riferimento solo ed esclusivamente alle Challenges incluse nella sezione <strong>‘Competenze’</strong> della Piattaforma, vale a dire a quelle che Glickon organizza e gestisce autonomamente, quale titolare del trattamento, senza alcun incarico dalle Aziende Partner. Per le Challenges organizzate da queste ultime, infatti, riceverai una separata informativa, prima della conferma della tua iscrizione a ciascuna di esse, direttamente dall’Azienda Partner di volta in volta coinvolta e sotto la sua esclusiva responsabilità, quale autonomo titolare del trattamento, come già evidenziato.<br/><br/>
      I dati connessi ai risultati delle Challenges a cui avrai preso parte saranno trattati da parte nostra unicamente <strong>per esaminare il tuo livello di competenza e conoscenza della materia oggetto del test eseguito</strong>, anche mediante <strong>l’elaborazione di un punteggio sulla base di tre fattori consistenti (i) nei tempi di risposta; (ii) nel numero di risposte corrette da te fornite rispetto al totale; (iii) nel valore preassegnato alla specifica risposta che avrai scelto, in base all’importanza e alla difficoltà della stessa.</strong><br/><br/>
      <u>La base giuridica applicabile a questo trattamento è il nostro legittimo interesse (art. 6.1, a) del GDPR) a svolgere nella maniera più efficiente possibile quelle attività valutative che sono alla base di questo specifico servizio – tra l’altro l’unico che Glickon eroga autonomamente mediante la propria Piattaforma – di analisi del tuo livello di preparazione ed esperienza in specifici settori. Potrai opporti in qualsiasi momento, per tuoi motivi personali prevalenti, allo svolgimento da parte nostra di questo genere di trattamenti, senza che ciò infici in alcun modo quanto già fatto fino a quel momento.</u><br/><br/>
      Sarai tra l’altro libero di decidere, tramite apposite impostazioni del tuo account personale, se rendere visibili ed eventualmente a chi alcune informazioni riguardanti il tuo profilo e le tue competenze.
    `
  },
  "privacy-5": {
    en: "5. Who may process your personal data",
    it: "5. Chi può trattare i tuoi dati personali"
  },
  "privacy-5-1": {
    en: "",
    it: "L’accesso ai tuoi dati sarà consentito esclusivamente a nostri dipendenti, debitamente formati sulle necessarie misure di tutela della tua privacy e della sicurezza dei tuoi dati ed autorizzato per iscritto a trattare solo i dati necessari per svolgere le rispettive mansioni lavorative."
  },
  "privacy-5-2": {
    en: "Should we entrust to third parties specific processing activities of your data related to the operation of the Platform and/or the provision of the Services, <u>each supplier involved</u>, subject to our assessment of their level of competence and reliability, <u>will be required to apply at least the same levels of protection of your data as we do</u>, by signing an agreement with Glickon pursuant to Art. 28 of the GDPR and thus acting as our data processor.",
    it: "Qualora dovessimo affidare a terzi specifiche attività di trattamento dei tuoi dati relative al funzionamento della Piattaforma e/o all’erogazione dei Servizi, <u>ogni singolo fornitore coinvolto</u>, previo accertamento da parte nostra del relativo livello di competenza ed affidabilità, <u>sarà tenuto ad applicare almeno gli stessi livelli di protezione dei tuoi dati garantiti da noi</u>, sottoscrivendo con Glickon apposito accordo ai sensi dell’Art. 28 del GDPR ed agendo quindi quale nostro responsabile del trattamento."
  },
  "privacy-5-3": {
    en: "Your data will not be disclosed in any way, nor communicated to third parties, with the exception of the Partner Companies that activate the services of the Platform and that, consequently, instruct us in writing to process your data on their behalf, or when we have to comply with orders or provisions issued by the competent authorities.",
    it: "I tuoi dati non saranno in alcun modo diffusi, né comunicati a terzi, ad eccezione delle Aziende Partner che attiveranno i servizi della Piattaforma e che, di conseguenza, ci incaricheranno per iscritto di trattare i tuoi dati per loro conto, oppure quando dovremo ottemperare a ordini o disposizioni emanate dalle autorità competenti."
  },
  "privacy-5-4": {
    en: "In any case, we have adopted a series of technical and organizational measures to ensure adequate levels of security in order to prevent all reasonably foreseeable risks that may affect your personal data, with particular but not limited reference to their destruction, loss, modification or unauthorized disclosure or access, accidental or illegal, to the same.",
    it: "In ogni caso, abbiamo adottato una serie di misure tecniche e organizzative per garantire adeguati livelli di sicurezza, in modo da prevenire tutti i rischi ragionevolmente prevedibili che possono riguardare i tuoi dati personali, con particolare ma non limitato riferimento alla loro distruzione, perdita, modifica o divulgazione non autorizzata o all’accesso, in modo accidentale o illegale, agli stessi."
  },
  "privacy-6": {
    en: "6. Transfer of data to Third Countries",
    it: "6. Trasferimento di dati verso Paesi Terzi"
  },
  "privacy-6-1": {
    en: "<u>The data processing operations on our part will always take place within the European Economic Area (<strong>‘EEA’</strong>).</u>",
    it: "<u>Le operazioni di trattamento dei dati da parte nostra avverranno sempre all’interno dello Spazio Economico Europeo (<strong>‘SEE’</strong>).</u>"
  },
  "privacy-6-2": {
    en: "If for any reason we need to transfer your data to countries outside the EEA which do not provide levels of protection and security that are at least equivalent to those required by the Privacy Policy, we will firstly notify you immediately of such a need and secondly, we will impose the same obligations on the entity to which the data is to be transferred to protect and secure your data as we do, as described in this notice (e.g. by entering into Standard Contractual Clauses with the recipient of the data and carrying out, where required, a risk assessment of the transfer).",
    it: "Se per qualsiasi ragione dovesse emergere l’esigenza di trasferire i tuoi dati verso paesi al di fuori del SEE che non garantiscono livelli di protezione e sicurezza almeno equivalenti a quelli richiesti dalla Normativa Privacy, sarà nostro dovere in primo luogo avvisarti immediatamente di tale necessità e, in secondo luogo, imporre nei confronti del soggetto a cui devono essere trasferiti i dati gli stessi obblighi di protezione e sicurezza dei tuoi dati garantiti da noi, come descritti in questa informativa (es. sottoscrivendo delle Clausole Contrattuali Standard con il destinatario dei dati e svolgendo, quando richiesto, una valutazione sui rischi connessi al trasferimento)."
  },
  "privacy-7": {
    en: "7. How long we keep your data",
    it: "7. Per quanto tempo conserviamo i tuoi dati"
  },
  "privacy-7-1": {
    en: "We will retain your personal data only for as long as is necessary to achieve the purposes <u>described above.</u><br/>This means that the data collected:",
    it: "Conserveremo i tuoi dati personali solo per il tempo necessario al raggiungimento delle finalità <u>sopra descritte.</u><br/>Ciò significa che i dati raccolti:"
  },
  "privacy-7-2": {
    en: "in order to allow you to browse the Platform, will only be stored <u>for as long as you remain on the Platform, or until you decide to delete them</u> (by deleting technical and functional cookies - see next point);",
    it: "per consentire la tua navigazione sulla Piattaforma saranno conservati <u>per il solo tempo della tua permanenza nella stessa, oppure fino a quando non deciderai di cancellarli</u> (eliminando i cookie tecnici e funzionali - vedi punto successivo);"
  },
  "privacy-7-3": {
    en: "cookies installed on the Platform will only be processed <u>for the duration - i.e. until the expiry date - of each specific cookie</u> (technical, functional, analytics and advertising/profiling) to which they are associated, as specified in our <a href=\"https://www.glickon.com/en/platform/cookies\" target=\"_blank\" rel=\"noopener noreferrer\">Cookie policy;</a>",
    it: "mediante i cookie installati sulla Piattaforma verranno trattati <u>solo per la durata - ossia fino alla data di scadenza - di ogni specifico cookie</u> (tecnico, funzionale, <i>analytics</i> e pubblicitario/di profilazione) a cui sono associati, come specificato nella nostra <a href=\"https://www.glickon.com/it/piattaforma/cookies\" target=\"_blank\" rel=\"noopener noreferrer\">Cookie policy;</a>"
  },
  "privacy-7-4": {
    en: "in relation to requests for contact or support that you decide to send us, they will be processed only <u>for the time necessary to provide you with the information and/or assistance you need</u>, thus properly following up on your request. <strong>One year after the last answer we will have sent you</strong>, the data will be deleted;",
    it: "in relazione alle richieste di contatto o supporto che deciderai di inviarci saranno trattati unicamente <u>per il tempo necessario a fornirti le informazioni e/o l’assistenza di cui hai bisogno</u>, dando quindi adeguatamente seguito alla tua richiesta. <strong>Un anno dopo l’ultima risposta che ti avremo inviato</strong>, i dati verranno cancellati;"
  },
  "privacy-7-5": {
    en: "in order to enable and manage your registration to the Platform, as well as to send you communications related to the services connected to it (contractual communications), will be processed and stored <u>until you decide to close your personal account on the Platform. The same applies to any additional data that you decide to share in order to enrich your personal profile, but in this case you can opt out of their processing at any time, with the consequent cessation of any further activity on our part;</u>",
    it: "per consentire e gestire la tua registrazione alla Piattaforma, oltre che per inviarti comunicazioni relative ai servizi ad essa collegati (comunicazioni contrattuali), saranno trattati e conservati <u>fino a quando non deciderai di chiudere il tuo <i>account</i> personale sulla Piattaforma. Lo stesso vale anche per gli ulteriori che deciderai di condividere per arricchire il tuo profilo personale, ma in questo caso potrai opporti in ogni momento al loro trattamento, con conseguente cessazione di ogni ulteriore attività in tal senso da parte nostra;</u>"
  },
  "privacy-7-6": {
    en: "as part of your subscription to our newsletter they will be processed and stored <u>until you decide to revoke your consent or request their cancellation;</u>",
    it: "nell’ambito della tua iscrizione alla nostra <i>newsletter</i> saranno trattati e conservati <u>fino a quando non deciderai di revocare il tuo consenso o non ne chiederai la cancellazione;</u>"
  },
  "privacy-7-7": {
    en: "as a consequence of and within the scope of the Challenges organized by Glickon in which you have registered, your personal data will be processed and <u>kept for 24 months from the moment of its collection</u>, unless you decide to object to its processing before the end of this period.",
    it: "in conseguenza e nell’ambito delle <i>Challenges</i> organizzate da Glickon a cui ti sarai iscritto, saranno trattati e conservati <u>per 24 mesi dal momento della relativa raccolta</u>, a meno che tu non decida di opporti al loro trattamento prima della decorrenza di tale periodo."
  },
  "privacy-7-8": {
    en: "<strong>After the expiry of the terms indicated above, we may further retain your data only for purposes expressly permitted, or even imposed, by the rules of law in force from time to time</strong> (eg to ascertain, exercise or defend our rights before the Judicial Authority).",
    it: "<strong>Alla scadenza dei termini sopra indicati, potremo conservare ulteriormente i tuoi dati unicamente per finalità espressamente consentite, o addirittura imposte, dalle norme di legge tempo per tempo vigenti</strong> (es. per accertare, esercitare o difendere un nostro diritto dinanzi all’Autorità Giudiziaria)."
  },
  "privacy-8": {
    en: "8. Your rights",
    it: "8. I tuoi diritti"
  },
  "privacy-8-1": {
    en: "You may at any time exercise the rights granted to you by the Privacy Policy, namely:",
    it: "Puoi esercitare in ogni momento i diritti che ti sono riconosciuti dalla Normativa Privacy, vale a dire:"
  },
  "privacy-8-2": {
    en: "a.	<strong><i>Access:</i></strong> to know whether or not your data is being processed and, if so, to receive evidence - among other things - of the purposes of processing by us, the categories of data involved, the recipients to whom the data may be disclosed, the applicable storage period, the existence of an automated decision-making process;",
    it: "a. <strong><i>Accesso:</i></strong> per sapere se sia in corso o meno un trattamento di tuoi dati e, in caso positivo, ricevere evidenza – tra l’altro - delle finalità del trattamento da parte nostra, delle categorie di dati coinvolti, dei destinatari a cui i dati possono essere comunicati, del periodo di conservazione applicabile, dell’esistenza di un processo decisionale automatizzato;"
  },
  "privacy-8-3": {
    en: "b.	<strong><i>Rectification:</i></strong> obtaining without any unjustified reason the modification and updating of any inaccurate data concerning you;",
    it: "b. <strong><i>Rettifica:</i></strong> ottenendo senza alcun ingiustificato la modifica e l’aggiornamento dei dati eventualmente inesatti che ti riguardano;"
  },
  "privacy-8-4": {
    en: "c.	<strong><i>Deletion:</i></strong> obtaining the deletion of your data, except in express cases of derogation, when the same (i) are no longer necessary in relation to the purposes for which they were collected or otherwise processed; or (ii) have been processed unlawfully; or (iii) are subject to processing to which you objected for your own personal reasons, as provided for in paragraph f. below, and there is no overriding legitimate reason that allows us to continue processing; or (iv) must be deleted in compliance with a legal obligation to which we are subject;",
    it: "c. <strong><i>Cancellazione:</i></strong> ottenendo la cancellazione dei tuoi dati, salvo casi espressi di deroga, quando gli stessi (i) non sono più necessari rispetto alle finalità per le quali sono stati raccolti o altrimenti trattati; o (ii) sono stati trattati illecitamente; o (iii) sono oggetto di un trattamento a cui ti sei opposto per tue ragioni personali, secondo quanto previsto al successivo punto f., e non esiste alcun motivo legittimo prevalente che ci consenta di proseguire il trattamento; o (iv) devono essere cancellati in adempimento di un obbligo di legge a cui siamo soggetti;"
  },
  "privacy-8-5": {
    en: "d.	<strong><i>Limitation of processing:</i></strong> obtaining from us the suspension of the processing of your data when: (a) you dispute their accuracy, for the period necessary to allow us to carry out the necessary verifications; (b) the processing is unlawful and you decide to object to the deletion of your data, requesting instead that their use be limited; c) the same are no longer necessary for us in relation to the purposes identified above, but you need them for the purposes of ascertaining, exercising and/or defending one of your rights in court; d) you have exercised your right to object, pending verification by us of the existence of prevailing legitimate reasons that allow us to continue to process your data;",
    it: "d. <strong><i>Limitazione del trattamento:</i></strong> ottenendo da parte nostra la sospensione del trattamento dei tuoi dati quando: a) ne contesti l’esattezza, per il periodo necessario a consentirci di effettuare le verifiche del caso; b) il trattamento è illecito e decidi di opporti alla cancellazione dei tuoi dati, chiedendo invece che ne sia limitato l’utilizzo; c) gli stessi non sono per noi più necessari in relazione alle finalità sopra identificate, ma tu ne hai bisogno ai fini dell’accertamento, dell’esercizio e/o della difesa di un tuo diritto in sede giudiziaria; d) hai esercitato il tuo diritto di opposizione, in attesa della verifica da parte nostra in merito all’esistenza di motivi legittimi prevalenti che ci permettano di continuare a trattare i tuoi dati;"
  },
  "privacy-8-6": {
    en: "e.	<strong><i>Portability:</i></strong> requesting to receive in a structured, commonly used and machine-readable format the personal data you have provided to us and/or to transmit them directly to another data controller;",
    it: "e. <strong><i>Portabilità:</i></strong> chiedendo di ricevere in un formato strutturato, di uso comune e leggibile da dispositivo automatico i dati personali che ci hai fornito e/o di trasmetterli direttamente ad un altro titolare;"
  },
  "privacy-8-7": {
    en: "f.	<strong><i>Opposition:</i></strong> objecting, on grounds related to your personal situation, to the processing operations (for the sending of our newsletter, in addition to those of your personal profile enrichment data and those related to the results of our Challenges) carried out on the basis of our legitimate interest, obtaining their termination, except when (i) there are one or more compelling legitimate reasons, on our part, that prevail over your interests, rights and freedoms and that allow us to continue the processing; (ii) your data is necessary to enable us to ascertain, exercise or defend a right of ours in a court of law;",
    it: "f. <strong><i>Opposizione:</i></strong> opponendoti, per motivi connessi alla tua situazione personale, ai trattamenti (per l’invio della nostra newsletter, oltre a quelli dei tuoi dati di arricchimento del profilo personale e di quelli connessi ai risultati delle nostre Challenges) svolti sulla base del nostro legittimo interesse, ottenendone la cessazione, tranne quando (i) non esistano uno o più motivi legittimi cogenti, da parte nostra, che prevalgano sui tuoi interessi, diritti e libertà e che ci permettano di proseguire il trattamento; (ii) i tuoi dati siano necessari per consentirci l’accertamento, l’esercizio o la difesa di un nostro diritto in sede giudiziaria;"
  },
  "privacy-8-8": {
    en: "g.	<strong><i>Withdrawal of consent:</i></strong> by withdrawing the consent you previously gave to receive our newsletter, it being understood that the processing we will have carried out up to that moment will remain fully lawful;",
    it: "g. <strong><i>Revoca del consenso:</i></strong> ritirando il consenso da te precedentemente fornito al ricevimento della nostra newsletter, fermo restando che i trattamenti che avremo svolto fino a quel momento resteranno pienamente leciti;"
  },
  "privacy-8-9": {
    en: "h.	<strong><i>Complaint:</i></strong> by submitting a complaint to the competent supervisory authorities - in Italy the <a href=\"https://www.garanteprivacy.it/modulistica-e-servizi-online/reclamo\" target=\"_blank\" rel=\"noopener noreferrer\">Italian Data Protection Authority</a> - if you believe that one or more treatments of your data are carried out by us in violation of the current Privacy Law. This does not affect your right to lodge an administrative or jurisdictional appeal.",
    it: "h. <strong><i>Reclamo:</i></strong> presentando apposito reclamo alle autorità di controllo competenti – <a href=\"https://www.garanteprivacy.it/modulistica-e-servizi-online/reclamo\" target=\"_blank\" rel=\"noopener noreferrer\">in Italia l’Autorità Garante per la protezione dei dati personali</a> – qualora ritenessi che uno o più trattamenti dei tuoi dati siano da noi svolti in violazione della vigente Normativa Privacy. Resta fermo il tuo diritto a presentare ricorso in sede amministrativa o giurisdizionale."
  },
  "privacy-8-10": {
    en: "In order to exercise your rights and to ask for any information or clarification regarding the processing of your data as described in this privacy policy, you may send us a written request at any time, by mail to Glikon S.r.l., Via Nino Bixio 7, Milan (20129), or by e-mail to <a href=\"mailto:privacy@glickon.com\">privacy@glickon.com</a>.",
    it: "Per esercitare i tuoi diritti e per chiedere qualsiasi informazione o chiarimento riguardo ai trattamenti dei tuoi dati descritti nella presente informativa, puoi inviarci in qualsiasi momento una richiesta scritta, via posta cartacea, presso Glikon S.r.l., in Via Nino Bixio 7, Milano (20129), oppure via e-mail a <a href=\"mailto:privacy@glickon.com\">privacy@glickon.com</a>."
  }
}

export default translations
