import styled from "styled-components"
import styleConstants from "../../../../constants/styleConstants"

export const ActionButton = styled.button`
  &.extra-margin-top {
    margin-top: 1rem;
  }
`

export const ResendContainer = styled.div`
  text-align: center;
  font-family: ${styleConstants.calibre};
  font-size: ${styleConstants.font.size16};
  color: ${styleConstants.dark02};
  margin-top: 1.5rem;

  .action {
    cursor: pointer;
    font-family: ${styleConstants.calibreSemibold};
    color: ${styleConstants.dark01};
  }
`
