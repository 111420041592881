import React from "react"
import useTranslation from "../../hooks/useTranslation"
import { Dictionary } from "../../types"
import Tooltip from "../customTooltip"
import { Output, TranslatedText } from "./styles"
import { useStoreData } from "../../store/store"

interface TranslatePropsType {
  label: string
  replacements?: Dictionary<string | number>
}

const Translate: React.FC<TranslatePropsType> = (props: TranslatePropsType) => {
  const [translate] = useTranslation()
  const authState = useStoreData(state => state.auth)
  const { label, replacements = {} } = props

  const text = `${label}<br/>${translate("global-click-to-edit")}`
  const idFor = `${label.replace(/\n|\t/g, "")}-info`

  const onClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    if (event.ctrlKey) {
      event.preventDefault()
      event.stopPropagation()
      const a: HTMLAnchorElement = document.createElement("a")
      a.href = `http://www.google.it?${label}`
      a.target = "_blank"
      a.rel = "noopener noreferrer"
      a.click()
    }
  }

  return (
    <>
      {authState.isAdmin ? (
        <>
          <Tooltip id={idFor} />
          <Output
            onClick={onClick}
            data-tip={text}
            data-for={idFor}
            data-multiline={true}
            dangerouslySetInnerHTML={{ __html: translate(label, replacements) }}
          />
        </>
      ) : (
          <TranslatedText
            dangerouslySetInnerHTML={{ __html: translate(label, replacements) }}
          />
        )}
    </>
  )
}

export default Translate
