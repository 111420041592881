import {TranslationType} from "../types"

const translations: TranslationType = {
  "live-t-c-title": {
    en: "",
    it: "Termini e condizioni generali del servizio \"Live Game\""
  },
  "live-t-c-pre-list-text": {
    en: "",
    it: `
            Il presente documento definisce i termini e condizioni del servizio
            (<strong>"Condizioni Generali"</strong>) che disciplinano la fruizione del servizio "Live
            Game" edita da Glickon S.p.A (<strong>"Servizio"</strong>), da parte dei relativi utenti
            (<strong>"Utenti Live"</strong>).
            <br/><br/>
            Il Servizio è fornito tramite applicazione web ed è ideato da Glickon
            S.p.A con sede in via Nino Bixio 7, 20129, Milano (<strong>"Glickon"</strong>).
            <br/><br/>
            Il Servizio è promosso agli utenti da parte di un’azienda promotrice
            (<strong>"Promotore"</strong>) che può essere Glickon o una società che ha stretto
            accordi commerciali con Glickon stessa per l’utilizzo di Live Game
            verso un pubblico di utenti.
            <br/><br/>
            Il nome del Promotore è indicato nella login page della applicazione
            web. La ragione sociale del Promotore è visualizzabile tramite tooltip. 
        `
  },
  "live-t-c-last-modified": {
    en: "",
    it: "Milano, 31 Marzo 2019"
  },

  "live-t-c-1": {
    en: "",
    it: "Il Servizio"
  },
  "live-t-c-1-1": {
    en: "",
    it: `
                Il Servizio offre all'Utente Live la possibilità di partecipare a
                giochi in modalità quiz o survey, creati dal Promotore, per
                coinvolgere i partecipanti di un evento durante le seguenti
                attività in ambito risorse umane quali, a titolo esemplificativo
                ma non esaustivo: incontri con studenti, attività di employer
                branding, colloqui di gruppo, onboarding di neoassunti,
                meeting e presentazioni, aule formative ed eventi aziendali. 
            `
  },
  "live-t-c-1-2": {
    en: "",
    it: `
                Il Promotore avrà accesso ai risultati dei giochi, a titolo
                esemplificativo ma non esaustivo nome, cognome, e-mail,
                numero di telefono, punteggi e classifiche che potranno essere
                utilizzati dal Promotore in accordo con la finalità indicate nel
                documento di Informativa sul trattamento dei dati personali. 
            `
  },
  "live-t-c-1-3": {
    en: "",
    it: `
                Il Promotore può, a propria discrezione, proporre l’utilizzo di
                Live Game ad un gruppo ristretto di Utenti Live che abbiano
                ottenuto i migliori punteggi o che siano in possesso di profili
                professionali o di competenze specifiche di interesse per il
                Promotore stesso.
            `
  },
  "live-t-c-1-4": {
    en: "",
    it: `
                Glickon non è in alcun modo responsabile per eventuali
                interruzioni nella fornitura del Servizio dovute a cause ad essa
                non direttamente imputabili e/o che sfuggano al proprio
                controllo tecnico, quali disfunzioni della rete telematica o
                telefonica.
            `
  },
  "live-t-c-1-5": {
    en: "",
    it: `
                Glickon si riserva il diritto di bloccare o sospendere, in qualsiasi
                momento e senza preavviso, la fornitura del Servizio agli Utenti
                Live che dovessero infrangere i termini previsti nelle presenti
                Condizioni Generali, riservandosi inoltre il diritto di disattivare
                o cancellare definitivamente l'attivazione dell'Utente Live e
                tutte le informazioni correlate e/o bloccare ogni suo eventuale
                accesso futuro ai Servizi.
            `
  },
  "live-t-c-1-6": {
    en: "",
    it: `
                I device che consentono l'accesso al Servizio, nonché le spese
                di telecomunicazione necessarie alla fruizione di tale servizio,
                sono a carico esclusivo dell'Utente Live. Glickon informa
                espressamente l'Utente Live che, qualora un aggiornamento
                del Servizio richieda cambiamenti nel software o nella
                strumentazione dell'Utente Live, questi dovranno essere
                integralmente effettuati a spese dell'Utente Live. 

            `
  },

  "live-t-c-2": {
    en: "",
    it: "Funzionamento del Servizio"
  },
  "live-t-c-2-1": {
    en: "",
    it: `
            Il Servizio permette a ciascun Utente Live di partecipare ad un
            gioco a quiz a tempo, a risposte multiple, che si svolge in
            diretta contro altri Utenti Live. 
        `
  },
  "live-t-c-2-2": {
    en: "",
    it: `
            Per partecipare a Live Game occorre collegarsi al link fornito
            dal Promotore, inserire il gamepin per accedere al gioco,
            inserire lo pseudonimo prescelto (<strong>"Nickname"</strong>) e, se richiesto,
            indicare i propri dati personali quali a titolo esemplificativo e
            non esaustivo: nome, cognome, e-mail, recapito telefonico
            (<strong>"Dati Personali"</strong>).
        `
  },
  "live-t-c-2-3": {
    en: "",
    it: `
            Live Game verrà svolto con modalità "in diretta" e, pertanto, le
            domande verranno mostrate in contemporanea su tutti i
            dispositivi degli Utenti Live che parteciperanno alla medesima
            partita. Ciascun quesito prevedrà la possibilità di scegliere tra
            diverse risposte precompilate, una sola delle quali sarà quella
            corretta secondo il Promotore.
        `
  },
  "live-t-c-2-4": {
    en: "",
    it: `
            Ciascun concorrente dovrà selezionare mediante "click" una
            delle possibili risposte entro il tempo prestabilito dalla loro
            prima visualizzazione sul dispositivo. In caso di mancata
            risposta entro il detto termine la risposta si intenderà errata.
            La risposta selezionata mediante "click" sarà considerata
            definitiva ed il concorrente non potrà più modificarla.
        `
  },
  "live-t-c-2-5": {
    en: "",
    it: `
            Il concorrente o i concorrenti che risponderanno
            correttamente al maggior numero di domande, risulteranno
            vincitori del quiz. In caso due o più Utenti Live abbiano risposto
            correttamente a tutte le risposte, vincerà il giocatore che ha
            impiegato il minor tempo.
        `
  },
  "live-t-c-2-6": {
    en: "",
    it: `
            Al termine del gioco verrà visualizzata e/o resa pubblica dal
            Promotore la classifica dei partecipanti con il punteggio
            ottenuto in base al numero di risposte corrette e al tempo
            impiegato. 
        `
  },

  "live-t-c-3": {
    en: "",
    it: "Modalità Survey"
  },
  "live-t-c-3-1": {
    en: "",
    it: `
            Il Servizio svolto in modalità survey presenta le seguenti peculiarità:
            <br/>
            <ul>
                <li>è fruito dall’Utente Live in modalità anonima;</li>
                <li>i risultati del gioco sono presentati in modalità aggregata e non esiste una classifica;</li>
                <li>Il gioco non prevede una risposta corretta.</li>
            </ul>
        `
  },
  "live-t-c-3-2": {
    en: "",
    it: `
            Con la sottoscrizione dei Termini e Condizioni Generali, il Cliente acquisisce il diritto perpetuo, non 
            trasferibile, di utilizzare i Risultati e le Reportistiche delle proprie Challenge Aziendali, a seconda del 
            Servizio scelto di volta in volta in ciascun Ordine. Il Cliente non acquisisce alcuna licenza implicita di 
            copiare o ricostruire qualsiasi parte della proprietà intellettuale di Glickon. Glickon si riserva 
            espressamente il diritto di sospendere l’erogazione del Servizio e l’accesso ai propri prodotti 
            ogniqualvolta vi sia anche solo il sospetto che si verifichi per conto del Cliente, o di terzi con il 
            consenso del Cliente, l’attivazione e/o l’utilizzo dei Servizi per qualsiasi finalità di monitoraggio, 
            copia o test dei Servizi di Glickon o analisi comparative non commissionate da Glickon stessa, incluse a 
            titolo esemplificativo attività di reverse engineering o altre azioni analoghe in relazione a qualsiasi 
            prodotto o Servizio fornito da Glickon ai sensi di questi Termini e Condizioni e Generali, inclusi la 
            Piattaforma, il Contenuto Glickon e i Test Glickon.
        `
  },

  "live-t-c-4": {
    en: "",
    it: "Dati personali"
  },
  "live-t-c-4-1": {
    en: "",
    it: `
            Durante la fase di login all’Utente Live può essere richiesto di
            fornire i propri Dati Personali che saranno trattati da Glickon e
            dal Promotore secondo le modalità ed i termini specificati
            nell’Informativa sul trattamento dei dati personali.
        `
  },
  "live-t-c-4-2": {
    en: "",
    it: `
            Il Nickname ha la funzione di pseudonimo per cui l’Utente Live
            che non volesse essere riconosciuto dagli altri Utenti Live e/o
            dal Promotore è invitato ad utilizzare un nome di fantasia non
            riconducibile a sé stesso. Nel caso in cui un Utente Live utilizzi
            il proprio nome, cognome o indirizzo e-mail al posto del
            Nickname, tale condotta potrà essere equiparata al consenso
            esplicito al trattamento dei dati personali.
        `
  },

  "live-t-c-5": {
    en: "",
    it: "Diritti di proprietà intellettuale dei Servizi"
  },
  "live-t-c-5-1": {
    en: "",
    it: `
            L'applicazione web, i marchi, i loghi, la grafica, le fotografie, le
            animazioni, i video, i testi e qualsiasi altro contenuto del
            Servizio sono proprietà intellettuale di Glickon o delle terze
            parti aventi diritto e non possono essere riprodotti, usati o
            rappresentati senza l'esplicita autorizzazione degli aventi
            diritto. I diritti d'uso concessi da Glickon all'Utente Live sono
            rigorosamente limitati all'accesso, alla richiesta, e ricezione sul
            proprio dispositivo dei contenuti pubblicati e promossi a tale
            scopo e all'uso di tali contenuti a scopo privato e personale e
            senza alcun fine di condivisione, diffusione, comunicazione o
            profitto al di fuori degli ambiti concessi da Glickon. Qualsiasi
            altro uso da parte dell'Utente Live, senza l'autorizzazione di
            Glickon, è vietato. In particolare, l'Utente Live non può
            modificare, copiare, riprodurre, scaricare, diffondere,
            trasmettere, sfruttare commercialmente e/o distribuire in
            qualsiasi modo il Servizio. 
        `
  },

  "live-t-c-6": {
    en: "",
    it:
      "Responsabilità dell'Utente Live per l'uso del Servizio e facoltà concesse a Glickon"
  },
  "live-t-c-6-1": {
    en: "",
    it: `
            L'Utente Live è autorizzato a fruire del Servizio esclusivamente
            a uso personale, senza scopo di lucro e si assume la piena
            responsabilità per ogni uso non autorizzato.
        `
  },
  "live-t-c-6-2": {
    en: "",
    it: `
            L'Utente Live deve essere maggiore di 16 anni per poter
            utilizzare il Servizio. Nel caso in cui non sia maggiore di 16 anni,
            l'Utente Live si dovrà astenere dalla fruizione del Servizio.
            Glickon non potrà pertanto essere considerata responsabile 
            per eventuali danni che possano derivare agli Utenti Live
            dall'uso delle informazioni e dei contenuti inclusi nel Servizio.
        `
  },
  "live-t-c-6-3": {
    en: "",
    it: `
            La partecipazione a Live Game e l’inserimento dei propri Dati
            Personali è consentita solo a persone che, secondo la
            normativa applicabile, siano legalmente capaci di agire.
        `
  },
  "live-t-c-6-4": {
    en: "",
    it: `
            L'Utente Live dichiara e garantisce sotto la propria esclusiva
            responsabilità che tutte le informazioni fornite in sede di
            accesso al Servizio sono veritiere, corrette ed aggiornate. Resta
            inteso che Glickon non potrà essere considerata in alcun modo
            responsabile della veridicità, correttezza e aggiornamento
            delle predette informazioni.
        `
  },
  "live-t-c-6-5": {
    en: "",
    it: `
            Ciascun Utente Live, utilizzando il Servizio, si impegna a:<br/>
            <ul>
                <li>
                    agire in conformità alla normativa applicabile;
                </li>
                <li>
                    fornire informazioni esatte, aggiornate, non confidenziali e non lesive dei diritti di proprietà 
                    intellettuale o di altri diritti di terzi;
                </li>
                <li>
                    non inserire informazioni e dati personali che siano contrari alla legge, all’ordine pubblico o 
                    al buon costume, minacce, contenuti sconvenienti, messaggi pubblicitari;
                </li>
                <li>
                    non inserire Nickname che possano ricondurre ad altri Utenti Live e/o terze parti.
                </li> 
            </ul>
        `
  },

  "live-t-c-7": {
    en: "",
    it: "Garanzie e manleva"
  },
  "live-t-c-7-1": {
    en: "",
    it: `
            Da parte dell’Utente Live<br/>
            <ul>
                <li>
                    L’Utente riconosce e accetta espressamente che il Servizio
                    e i suoi contenuti sono forniti "come sono" e "come
                    disponibili" al momento della loro fruizione;
                </li>
                <li>
                    L'Utente Live è il solo ed esclusivo responsabile per l'uso e
                    la sicurezza della propria posta elettronica e dei propri
                    account, anche per le attività svolte attraverso il loro
                    utilizzo;
                </li>
                <li>
                    L'Utente Live è pienamente ed esclusivamente
                    responsabile per eventuali danni che potrebbero
                    insorgere a carico di Glickon o terzi, a seguito del non
                    corretto utilizzo, la perdita, il furto e/o compromissione
                    della riservatezza dell'account di posta elettronica e degli
                    altri account utilizzati;
                </li>
                <li>
                    L'Utente è il solo e diretto responsabile dei propri dati
                    personali e delle informazioni caricate e rese pubbliche
                    attraverso il Servizio. L’Utente Live ha l’obbligo di
                    verificare che le informazioni fornite non violino, in tutto
                    o in parte, i diritti di terzi o la normativa applicabile e si
                    impegna espressamente a manlevare e tenere indenne
                    Glickon da tutti i danni e le spese (ivi incluse le prevedibili
                    spese legali) che dovessero essere poste a carico di Glickon
                    in conseguenza di azioni di terze parti o di autorità
                    amministrative o giurisdizionali per violazione di diritti di
                    terzi o della normativa applicabile da parte dell’Utente
                    Live. 
                </li>
            </ul>
        `
  },
  "live-t-c-7-2": {
    en: "",
    it: `
            Da parte di Glickon<br/>
            <ul>
                <li>
                    Glickon non esegue le funzioni di una agenzia per il lavoro
                    o di selezione del personale, ma agisce come hosting
                    provider ai sensi della normativa applicabile fornendo il
                    Servizio attraverso il quale gli Utenti Live possono
                    partecipare a giochi a quiz / survey ed entrare in contatto
                    con i Promotori.
                </li>
                <li>
                    Nell’accedere al Servizio, l’Utente Live non agisce in alcun
                    modo sotto l’autorità o il controllo di Glickon. Pertanto,
                    Glickon non è in alcun modo responsabile delle azioni, dei
                    contenuti, delle informazioni fornite, pubblicate o
                    condivise dagli Utenti Live. Nulla di quanto stabilito nelle
                    Condizioni Generali implica in alcun modo che Glickon si
                    obblighi né possa essere considerata tenuta allo
                    svolgimento di un’attività di sorveglianza preventiva sulle
                    informazioni che trasmette, né a ricercare attivamente
                    fatti o circostanze che indichino la presenza di attività
                    legalmente o contrattualmente illecite. Sono fatti
                    comunque salvi i diritti e le facoltà riconosciute a Glickon
                    ai sensi della legislazione comunitaria e nazionale
                    applicabile.                
                </li>
                <li>
                    Glickon non è in alcun modo responsabile per qualsiasi
                    rapporto che si dovesse instaurare tra l'Utente Live e il
                    Promotore, essendo relazioni che sono private e riservate
                    su cui Glickon non può esercitare alcun controllo. Glickon
                    non è inoltre in alcun modo responsabile, né può dare
                    alcuna garanzia, su eventuali comunicazioni, formazione,
                    riunioni, trattative, accordi contrattuali o rapporti di
                    lavoro che dovessero insorgere tra l’Utente Live e i
                    Promotori.
                </li>
                <li>
                    Glickon non riconosce alcuna garanzia al di là di quanto
                    concesso con le presenti Condizioni Generali: resta inteso
                    che l'Utente Live utilizzerà il Servizio sotto la sua piena ed
                    esclusiva responsabilità e che l'utilizzo del Servizio dovrà
                    rispettare tutte le istruzioni fornite dal Promotore.
                </li>
                <li>
                    L'uso e il corretto funzionamento del Servizio possono, a
                    volte, essere limitati a causa di funzionalità, blocchi o
                    problemi legati al mercato di riferimento o alle tariffe del
                    piano dati di ciascun Utente Live, cause per le quali Glickon
                    non è in alcun modo responsabile.
                </li>
            </ul>
        `
  },

  "live-t-c-8": {
    en: "",
    it: "Clausole generali"
  },
  "live-t-c-8-1": {
    en: "",
    it: `
            Legge Applicabile e Foro Competente. Per qualsiasi
            controversia inerente all’interpretazione o l’esecuzione delle
            Condizioni Generali, sarà applicabile esclusivamente la legge
            Italiana, escluso ogni conflitto di legge. Nel caso di controversie
            con gli Utenti Live, qualunque controversia derivante dalla
            interpretazione, validità, esecuzione delle presenti Condizioni
            Generali o comunque inerente a o derivante da esse, sarà
            soggetta alla competenza esclusiva ed inderogabile del Foro di
            Milano
        `
  },
  "live-t-c-8-2": {
    en: "",
    it: `
            Integrazioni e Modifiche. Le presenti Condizioni Generali
            costituiscono l’intero accordo tra le l’Utente Live e Glickon e
            sostituiscono qualsiasi precedente o contemporanea
            condizione, accordo o dichiarazione, sia verbale che scritta,
            relativa alla materia oggetto delle Condizioni Generali. Glickon
            si riserva il diritto di modificare il Servizio, l’applicazione web e
            tutti i dati in esso contenuti e le Condizioni Generali in qualsiasi
            momento. L'Utente Live sarà soggetto all’Informativa sul
            trattamento dei dati personali e alle Condizioni Generali in
            vigore al momento, consultabili cliccando sui collegamenti
            ipertestuali situati nella landing/login page dell’Applicazione
            Web.
        `
  },
  "live-t-c-8-3": {
    en: "",
    it: `
            Conservazione. Qualora una qualsiasi disposizione delle
            Condizioni Generali sia dichiarata illecita, nulla, o inefficace,
            tale disposizione sarà modificata in modo da renderla efficace
            o altrimenti eliminata, e non inficerà la validità e l’efficacia
            delle restanti disposizioni
        `
  },
  "live-t-c-8-4": {
    en: "",
    it: `
            Comunicazioni. Qualsiasi comunicazione di tipo
            amministrativo relativamente alle Condizioni Generali sarà
            effettuata nella landing/login page dell’Applicazione Web. 
        `
  }
}

export default translations
