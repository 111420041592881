/* eslint-disable space-before-function-paren */
import React from "react"
import * as Yup from "yup"
import {AxiosError} from "axios"
import {AccessUserType} from "../../types/accessUser"
import useTranslation from "../../hooks/useTranslation"
import AccessForm, {
  AccessFormValuesType,
  AccessFormInputIds,
  AccessFormType
} from "../access-form"
import {RequestFunction} from "../../types/requestFunction"
import {TranslateFunctionType, Nullable, GlobalStateType} from "../../types"
import HeaderLoginSignup from "../header-login-signup"
import {Title, Container, Description} from "./style"
import Translate from "../translate"
import {
  getErrorText,
  generateLocalizedPath,
  getParametrizedPath
} from "../../utils"
import {Redirect} from "react-router"
import {
  employeePlatformCompanySignupKey,
  homepageKey
} from "../../constants/pathKeys"
import globalStateContext from "../../globalStateContext"
import {EmailFloatingButton} from "../email-floating-button/EmailFloatingButton"
import {FormikHelpers} from "formik"
import {isValidMail} from "../../utils"

// At the moment it's used only by public employee signup,
// as a way to check the email domain and redirect to the whitelabel

const initialValues: AccessFormValuesType = {
  [AccessFormInputIds.email]: ""
}

const getValidationSchema = (
  translate: TranslateFunctionType
): Yup.AnySchema<AccessFormValuesType> =>
  Yup.object().shape({
    [AccessFormInputIds.email]: Yup.string()
      .trim()
      .email(translate("global-error-field-invalid-email"))
      .test(
        AccessFormInputIds.email,
        translate("global-error-field-invalid-email"),
        (value: Nullable<string> | undefined): boolean => {
          if (!value) {
            // this case is handled by required
            return true
          }
          return isValidMail(value)
        }
      )
      .required(translate("global-error-field-mandatory"))
  })

interface PropsType {
  registerCheckEmailFunction: RequestFunction
}

const AccessPreSignup: React.FC<PropsType> = ({
  registerCheckEmailFunction
}: PropsType) => {
  const [translate] = useTranslation()
  const [errorText, setErrorText] = React.useState<string>("")
  const [currentValues, setValues] = React.useState<AccessFormValuesType>(
    initialValues
  )
  const [, locale] = useTranslation()
  const [redirectCompanyName, setRedirectCompanyName] = React.useState<string>(
    ""
  )
  const context = React.useContext<GlobalStateType>(globalStateContext)
  context.preregisterEmail = "" // Everytime we reach this page we reset it

  const submitHandler = async (
    values: AccessFormValuesType,
    {setSubmitting}: FormikHelpers<AccessFormValuesType>
  ) => {
    // reset error on every submit
    setErrorText("")

    registerCheckEmailFunction<AccessFormValuesType, any>(
      values,
      response => {
        setRedirectCompanyName(response.data.owner)
        context.preregisterEmail = response.data.email
      },
      (e: AxiosError) => {
        setSubmitting(false)

        const errorText = getErrorText(e)
        setErrorText(errorText || translate("global-generic-error"))
      }
    )
  }

  return redirectCompanyName ? (
    <Redirect
      to={getParametrizedPath(
        generateLocalizedPath(employeePlatformCompanySignupKey, locale),
        [{key: "companyName", value: redirectCompanyName}]
      )}
    />
  ) : (
    <React.Fragment>
      <Container userType={AccessUserType.Employee}>
        <HeaderLoginSignup logoLinkKey={homepageKey} />
        <div className="row">
          <div className="column mobile-12 desktop-6">
            <div className="row">
              <div className="column mobile-12 desktop-8">
                <Title>
                  <Translate label={"form-employee-register-welcome-title"} />
                </Title>

                <Description>
                  <Translate
                    label={"form-employee-register-welcome-description"}
                  />
                </Description>

                <AccessForm
                  type={AccessFormType.Register}
                  isPreRegister={true}
                  userType={AccessUserType.Employee}
                  initialValues={currentValues}
                  validationSchema={getValidationSchema(translate)}
                  submitHandler={submitHandler}
                  onChange={setValues}
                  errorText={errorText}
                />
              </div>
            </div>
          </div>
        </div>
      </Container>

      <EmailFloatingButton />
    </React.Fragment>
  )
}

export default AccessPreSignup
