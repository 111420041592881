import React from "react"
import { RouteComponentProps, withRouter, Redirect } from "react-router"
import { AccessUserType } from "../../../types/accessUser"
import AccessSignup from "../../../commonComponents/access-signup"
import useCompanyInfo from "../../../hooks/useCompanyInfo"
import { generateLocalizedPath } from "../../../utils"
import { notFoundKey } from "../../../constants/pathKeys"
import useTranslation from "../../../hooks/useTranslation"
import {
  registerEmployee,
  resendEmailEmployee
} from "../../../requests/employeePlatform"
import LoaderFullScreen from "../../../commonComponents/loader-full-screen"
import { EmailFloatingButton } from "../../../commonComponents/email-floating-button/EmailFloatingButton"

interface ParamsType {
  companyName?: string
}

type PropsType = RouteComponentProps<ParamsType> & {}
const EmployeePlatformSignup: React.FC<PropsType> = ({
  match: {
    params: { companyName }
  }
}: PropsType) => {
  const {
    companyInfo,
    companyInfoRetrieved: readyToRender,
    requiredCompanyInfoNotAvailable
  } = useCompanyInfo("employees", companyName)

  const [, locale] = useTranslation()

  if (!readyToRender) return <LoaderFullScreen />

  const hasCustomBackground = !!companyInfo.landingPageBackgroundUrl || false
  const authenticationMethods = companyInfo.supportedAuthenticationMethods || []

  return requiredCompanyInfoNotAvailable ? (
    <Redirect to={`${generateLocalizedPath(notFoundKey, locale)}`} />
  ) : (
      <React.Fragment>
        <AccessSignup
          userType={AccessUserType.Employee}
          companyName={companyName}
          loginPrompts={authenticationMethods}
          privacyUrl={companyInfo.privacyUrl}
          privacyPolicyConsentRequired={companyInfo.privacyPolicyConsentRequired}
          privacyPolicyCustomMessage={companyInfo.privacyPolicyConsentFormCustomMessage}
          registerFunction={registerEmployee}
          resendEmailFunction={resendEmailEmployee}
          hasCustomBackground={hasCustomBackground}
          backgroundUrl={
            companyName
              ? companyInfo.landingPageBackgroundUrl
              : undefined
          }
          logoUrl={companyInfo.logoUrl}
        />

        <EmailFloatingButton />
      </React.Fragment>
    )
}

export default withRouter<PropsType, React.FC<PropsType>>(
  EmployeePlatformSignup
)
