import styled from "styled-components"
import styleConstants from "../../constants/styleConstants"

// eslint-disable-next-line no-unexpected-multiline
export const ExternalPageLayoutDetailsWrapper = styled.div<{
  customColor: string | undefined
}>`
  padding: 0;
  background-color: ${({customColor}) => customColor || styleConstants.blue02};

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    padding: 0 1rem;
  }
`

// eslint-disable-next-line no-unexpected-multiline
export const ExternalPageLayoutInnerWrapper = styled.div<{
  customColor: string | undefined
}>`
  .row,
  .column {
    ${({customColor}) =>
      customColor ? `background-color: ${customColor};` : ""}
    // padding: 0 0.8rem;

    @media (min-width: ${styleConstants.desktopMinWidth}) {
      padding: 0;
      justify-content: center;
    }
  }

  .column {
    flex-grow: 1;

    /* needed for IE */
    max-width: 100%;
    width: 100%;
  }
`

// eslint-disable-next-line no-unexpected-multiline
export const ExternalPageLayoutDetailsTopContent = styled.div<{
  customColor: string | undefined
  customBorderColor: string | undefined
}>`
  position: relative;
  margin-top: -5rem;
  margin-bottom: -2rem;
  background-color: ${({customColor}) => customColor || styleConstants.white};

  border: solid 0.0625rem
    ${({customBorderColor}) => customBorderColor || styleConstants.cardBorder};

  border-radius: ${styleConstants.defaultBorderRadius};
  padding: 1rem 1.125rem;
  z-index: 99;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    padding: 2rem 1.75rem;
    margin-top: -9rem;
  }
`
