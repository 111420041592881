import {useInView} from "react-intersection-observer"
import React from "react"

interface PropsType extends React.HTMLProps<HTMLElement> {
  renderAs?: React.ComponentType<any> | string
  observe?: boolean
  triggerOnce?: boolean
  children: React.ReactNode
}

const Observer: React.FC<PropsType> = (props: PropsType) => {
  const {
    children,
    observe = false,
    triggerOnce = true,
    renderAs = "div",
    ...otherProps
  } = props

  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce
  })

  return React.createElement(renderAs, {
    ref,
    ...otherProps,
    children: !observe || (observe && inView) ? children : null
  })
}

export default Observer
