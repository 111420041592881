import { platformCXTermsAndConditionsKey, platformEXTermsAndConditionsKey } from "./../pathKeys"
import { FooterLink } from "../../types"
import {
  aboutUsKey,
  candidateExperienceKey,
  caseStudiesKey,
  platformCookieKey,
  employeeExperienceKey,
  facebookKey,
  jobKey,
  linkedinKey,
  liveKey,
  mailKey,
  mediumKey,
  peopleAnalyticsKey,
  platformPrivacyKey,
  requestADemoKey,
  resourcesKey,
  // teamKey,
  platformTermsAndConditionsKey,
  twitterKey,
  youtubeKey
} from "../pathKeys"

export const footerLinks: FooterLink[] = [
  {
    id: "data-1",
    label: "global-navigation-products",
    links: [
      {
        id: "data-1-item-1",
        label: "Candidate Experience",
        to: candidateExperienceKey
      },
      {
        id: "data-1-item-2",
        label: "Employee Experience",
        to: employeeExperienceKey
      },
      {
        id: "data-1-item-3",
        label: "People Analytics",
        to: peopleAnalyticsKey
      },
      {
        id: "data-1-item-4",
        label: "Live",
        to: liveKey
      },
      {
        id: "data-1-item-5",
        label: "global-navigation-resources",
        to: resourcesKey
      }
    ]
  },
  {
    id: "data-2",
    label: "global-navigation-about",
    links: [
      {
        id: "data-2-item-1",
        label: "global-navigation-company",
        to: aboutUsKey
      },
      // NOTICE - IT WILL BE BACK SOON
      // {
      //   id: "data-2-item-2",
      //   label: "global-navigation-team",
      //   to: teamKey
      // },
      {
        id: "data-2-item-3",
        label: "global-navigation-job",
        to: jobKey
      },
      {
        id: "data-2-item-4",
        label: "global-navigation-case-study",
        to: caseStudiesKey
      },
      {
        id: "data-2-item-5",
        label: "global-navigation-contact-us",
        to: mailKey
      }
    ]
  },
  {
    id: "data-3",
    label: "global-navigation-support",
    links: [
      {
        id: "data-3-item-1",
        label: "global-request-a-demo",
        to: requestADemoKey
      },
      {
        id: "data-3-item-2",
        label: "global-navigation-terms-and-conditions",
        to: platformTermsAndConditionsKey,
        links: [{
          id: "data-3-item-2-1",
          label: "global-navigation-terms-and-conditions-cx",
          to: platformCXTermsAndConditionsKey
        }, {
          id: "data-3-item-2-2",
          label: "global-navigation-terms-and-conditions-ex",
          to: platformEXTermsAndConditionsKey
        }]
      },
      {
        id: "data-3-item-3",
        label: "global-navigation-privacy",
        to: platformPrivacyKey
      },
      {
        id: "data-3-item-4",
        label: "global-navigation-cookie",
        to: platformCookieKey
      } /*,
            {
                id: "data-3-item-5",
                label: "global-navigation-security",
                to: platformSecurityKey
            }*/
    ]
  },
  {
    id: "data-4",
    label: "global-navigation-follow-us",
    links: [
      {
        id: "data-4-item-1",
        label: "Linkedin",
        to: linkedinKey
      },
      {
        id: "data-4-item-2",
        label: "Facebook",
        to: facebookKey
      },
      {
        id: "data-4-item-3",
        label: "Twitter",
        to: twitterKey
      },
      {
        id: "data-4-item-4",
        label: "Youtube",
        to: youtubeKey
      },
      {
        id: "data-4-item-5",
        label: "Medium",
        to: mediumKey
      }
    ]
  }
]
