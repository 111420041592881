import {
  Actions,
  Backdrop,
  Logo,
  Navigation,
  NavigationWrapper,
  NavItem,
  RightNavigationItem,
  SubNav,
  SubNavItem,
  SubNavNavigation,
  SubNavTitle,
  ThumbnailDescription,
  ThumbnailTitle
} from "./styles"
import React from "react"
import SimpleAction from "../../action"
import SimpleImage from "../../image"
import Link from "../../link"
import { FullHeight } from "../styles"
import {
  aboutUsKey,
  blogKey,
  mediumBlogKey,
  candidateExperienceKey,
  caseStudiesKey,
  caseStudyKey,
  employeeExperienceKey,
  homepageKey,
  liveKey,
  peopleAnalyticsKey,
  productsKey,
  requestADemoKey,
  resourcesCandidateExperienceKey,
  resourcesEmployeeExperienceKey,
  resourcesKey,
  resourcesPeopleAnalyticsKey,
  loginKey,
  signupKey,
  jobKey,
  aDayWithPageKey,
  candidateExperienceJourneyKey,
  HRJourneyKey,
  employeeExperienceJourneyKey,
} from "../../../constants/pathKeys"
import Observer from "../../observer"
import Translate from "../../translate"

const SubMenu: React.FC = () => {
  return (
    <SubNav className="subnav">
      <div className="row between">
        <div className="column desktop-5">
          <SubNavTitle>glickon experience</SubNavTitle>
          <SubNavNavigation>
            <SubNavItem>
              <Link to={candidateExperienceKey}>Candidate Experience</Link>
            </SubNavItem>
            <SubNavItem>
              <Link to={employeeExperienceKey}>Employee Experience</Link>
            </SubNavItem>
            <SubNavItem>
              <Link to={peopleAnalyticsKey}>People Analytics</Link>
            </SubNavItem>
            <SubNavItem last>
              <Link to={liveKey}>Live</Link>
            </SubNavItem>
          </SubNavNavigation>
        </div>
        <Observer observe className="column desktop-6">
          <Link to={requestADemoKey}>
            <SimpleImage localize={false} src="menu/thumb-menu-demo.jpg" />
            <ThumbnailTitle>
              <Translate label={"global-navigation-submenu-title"} />
            </ThumbnailTitle>
            <ThumbnailDescription>
              <Translate label={"global-navigation-submenu-description"} />
            </ThumbnailDescription>
          </Link>
        </Observer>
      </div>
    </SubNav>
  )
}

interface PropsType {
  shrink: boolean
  isActive: (pathKeys: string[]) => boolean
  isLoginPage: boolean
  isSignupPage: boolean
}

const Desktop: React.FC<PropsType> = (props: PropsType) => {
  const { shrink, isActive, isLoginPage, isSignupPage } = props
  const logo: string = shrink
    ? "logos/logo-glickon-compress.png"
    : "logos/logo-glickon-white.png"

  return (
    <FullHeight className="row hide-for-mobile-only">
      <FullHeight className="column desktop-12">
        <NavigationWrapper className="row middle">
          <Link to={homepageKey}>
            <Logo localize={false} shrink={shrink} src={logo} />
          </Link>
          <Navigation shrink={shrink}>
            <NavItem
              withSubmenu
              isActive={isActive([
                productsKey,
                candidateExperienceKey,
                employeeExperienceKey,
                peopleAnalyticsKey,
                liveKey
              ])}
            >
              <Translate label={"global-navigation-products"} />
              <SubMenu />
            </NavItem>
            <NavItem
              isActive={isActive([
                aDayWithPageKey,
                HRJourneyKey,
                candidateExperienceJourneyKey,
                employeeExperienceJourneyKey
              ])}>
              <Link to={aDayWithPageKey}>
                A day with
              </Link>
            </NavItem>
            <NavItem isActive={isActive([aboutUsKey])}>
              <Link to={aboutUsKey}>
                <Translate label={"global-navigation-about"} />
              </Link>
            </NavItem>
            <NavItem isActive={isActive([caseStudiesKey, caseStudyKey])}>
              <Link to={caseStudiesKey}>
                <Translate label={"global-navigation-case-study"} />
              </Link>
            </NavItem>
            <NavItem
              isActive={isActive([
                resourcesKey,
                resourcesPeopleAnalyticsKey,
                resourcesCandidateExperienceKey,
                resourcesEmployeeExperienceKey
              ])}
            >
              <Link to={resourcesKey}>
                <Translate label={"global-navigation-resources"} />
              </Link>
            </NavItem>
            <NavItem>
              <Link to={blogKey}>Blog</Link>
            </NavItem>
            <NavItem>
              <Link to={mediumBlogKey}>
                <Translate label={"global-navigation-medium"} />
              </Link>
            </NavItem>
            <NavItem isWorkWithUs={true} shrink={shrink}>
              <Link to={jobKey}>
                <Translate label={"global-navigation-join-us"} />
              </Link>
            </NavItem>
            <Backdrop shrink={shrink} />
          </Navigation>
          <Actions>
            {isLoginPage && (
              <li id="global-navigation-get-started-desktop">
                <Link to={signupKey}>
                  <SimpleAction
                    size={SimpleAction.sizes.medium}
                    variant={SimpleAction.variants.white}
                  >
                    <Translate label={"global-navigation-get-started"} />
                  </SimpleAction>
                </Link>
              </li>
            )}

            {isSignupPage && (
              <li id="global-navigation-signin-desktop">
                <Link to={loginKey}>
                  <SimpleAction
                    size={SimpleAction.sizes.medium}
                    variant={SimpleAction.variants.white}
                  >
                    <Translate label={"global-navigation-signin"} />
                  </SimpleAction>
                </Link>
              </li>
            )}

            {!isLoginPage && !isSignupPage && (
              <>
                <RightNavigationItem id="global-navigation-signin-desktop">
                  <Link to={loginKey}>
                    <Translate label={"global-navigation-signin"} />
                  </Link>
                </RightNavigationItem>

                <li id="global-navigation-get-started-desktop">
                  <Link to={signupKey}>
                    <SimpleAction
                      size={SimpleAction.sizes.medium}
                      variant={SimpleAction.variants.white}
                    >
                      <Translate label={"global-navigation-get-started"} />
                    </SimpleAction>
                  </Link>
                </li>
              </>
            )}
          </Actions>
        </NavigationWrapper>
      </FullHeight>
    </FullHeight>
  )
}

export default Desktop
