import {PressType} from "../../types"

export const pressList: PressType[] = [
  {
    id: "press-1",
    imageUrl: "home/press-01.png",
    quote: `
                    Il turnover è sempre elevato tra gli studi legali d’affari, almeno per quel che riguarda le figure 
                    più giovani. Gli ingressi sono continui e la selezione è molto dura, alla ricerca dei migliori 
                    talenti da fidelizzare e far crescere. È in questo contesto che si inserisce Glickon, la startup 
                    milanese che ha realizzato il primo “trivia game” dedicato al mondo legal.
            `,
    source: `
                GLICKON, UN GIOCO PER ENTRARE IN UN GRANDE STUDIO LEGALE 
            `,
    externalLink:
      "https://www.repubblica.it/economia/affari-e-finanza/2017/02/13/news/glickon_un_gioco_per_entrare_in_un_grande_studio_legale-158265804/"
  },
  {
    id: "press-2",
    imageUrl: "home/press-02.png",
    quote: `
                    Glickon è una piattaforma online che, grazie a un algoritmo specifico, riesce a testare e validare 
                    le skill dei candidati e dipendenti diminuendo il time-to-hire e aumentando l’accuratezza 
                    della selezione.
            `,
    source: `
                ANCHE CERCARE LAVORO SARÀ DIVERSO, GRAZIE ALLE STARTUP 
            `,
    externalLink: "http://siliconvalley.corriere.it/2016/07/03/openjobmetis-2/"
  },
  {
    id: "press-3",
    imageUrl: "home/press-03.png",
    quote: `
                    Glickon, una piattaforma di gioco per candidati e dipendenti; un trivia-game dove sfidare amici, 
                    colleghi ed altre persone per mostrare le proprie competenze e passioni ed entrare in contatto con 
                    nuove opportunità di lavoro o sviluppare la propria carriera.
            `,
    source: `
                GAMIFICATION E LAVORO: I PUNTI DI FORZA 
            `,
    externalLink:
      "https://www.panorama.it/economia/carriere-e-lavoro/gamification-e-lavoro-punti-di-forza/"
  },
  {
    id: "press-4",
    imageUrl: "home/press-04.png",
    quote: `
                    Glickon, la prima piattaforma di employee e candidate experience, dedicata ai professionisti delle 
                    risorse umane (HR)impegnati a ingaggiare, assumere e far emergere i talenti in azienda e a 
                    promuovere una reale meritocrazia, per rendere le imprese sempre più competitive in un mercato 
                    del lavoro in rapida evoluzione
                    
            `,
    source: `
                GLICKON, LA PIATTAFORMA PER I PROFESSIONISTI DELLE RISORSE UMANE (HR). 
            `,
    externalLink:
      "http://www.scenaridigitali.info/2019/02/20/glickon-la-piattaforma-per-i-professionisti-delle-risorse-umane-hr-gamification-e-people-analytics-rinnovano-l-employee-experience/"
  }
]
