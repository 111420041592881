export enum RedirectedPageType {
  Login = "login",
  Signup = "signup",
  LoggedDashboard = "logged_dashboard"
}

// there are already other similar enums `AccessCardType` and `AccessUserType`, but their attrs values are not plural
export enum Platform {
  Candidates = "candidates",
  Employees = "employees",
  Corporate = "corporate"
}

export interface RedirectedPageMetadata {
  pageType: RedirectedPageType,
  platform?: Platform,
  companyCareersName?: string
}

export interface RedirectMetadata {
  forcedLogout?: boolean
  redirectedPage?: RedirectedPageMetadata
}
