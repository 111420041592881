import React, { useState, useEffect } from "react"
import { Formik, FormikProps } from "formik"
import { ActionButton, ResendContainer } from "./style"
import useTranslation from "../../../../hooks/useTranslation"
import NegativeFeedback from "../../../../commonComponents/negative-feedback"
import TextInput from "../../../../commonComponents/input"
import SimpleAction from "../../../../commonComponents/action"
import Translate from "../../../../commonComponents/translate"

const codeLenght = 6
const delayResendCode = 10000 // milliseconds

const getFormSubmitability = (values: any, _: any): boolean => {
  return values.code.length === codeLenght
}

const validateForm = (values: any, invalidCodeText: string) => {
  if (values.code.length > 0 && !/^[0-9]+$/i.test(values.code)) {
    return { code: invalidCodeText }
  } else {
    return {}
  }
}

const InsertCodeForm: React.FC<any> = ({
  submitHandler,
  errorText,
  resendCode
}: any) => {
  // if is false will show a confirmation message
  const [showResend, setShowResend] = useState<boolean | null>(null)
  const [translate] = useTranslation()
  const invalidCodeText = translate("tfa-invalid-phone-number")

  // Resend CTA will be available only after a certain delay and only 1 time
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowResend(true)
    }, delayResendCode)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [])

  return (
    <Formik
      initialValues={{ code: "" }}
      validate={values => validateForm(values, invalidCodeText)}
      onSubmit={submitHandler}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        isSubmitting,
        errors
      }: FormikProps<any>) => {
        const disabled = isSubmitting || !getFormSubmitability(values, errors)

        return (
          <>
            <form onSubmit={handleSubmit}>
              {errorText && <NegativeFeedback>{errorText}</NegativeFeedback>}

              {errors.code && (
                <NegativeFeedback>{errors.code}</NegativeFeedback>
              )}

              <TextInput
                id="code"
                value={values["code"]}
                placeholder="tfa-insert-code-sms-input-placeholder"
                onChange={handleChange}
              />

              <ActionButton
                disabled={disabled}
                className="button-unstyled full-width"
                type="submit"
              >
                <SimpleAction
                  disabled={disabled}
                  fullWidth
                  size={SimpleAction.sizes.large}
                  variant={SimpleAction.variants.dark}
                >
                  <Translate label="tfa-insert-code-sms-form-action" />
                </SimpleAction>
              </ActionButton>
            </form>

            {showResend && (
              <ResendContainer>
                <Translate label="tfa-did-not-receive-sms" />{" "}
                <span
                  className="action"
                  onClick={() => {
                    setShowResend(false)
                    resendCode()
                  }}
                >
                  <Translate label="tfa-resend-code" />
                </span>
              </ResendContainer>
            )}

            {showResend === false && (
              <ResendContainer>
                <Translate label="tfa-code-resent" />
              </ResendContainer>
            )}
          </>
        )
      }}
    </Formik>
  )
}

export default InsertCodeForm
