import {Nullable} from "./types"
import urls from "./constants/urls"
import axios, {AxiosInstance, AxiosRequestConfig, AxiosError} from "axios"
import freeEmailProviders from "./constants/freeEmailProviders.json"
// @ts-ignore
import MailChecker from "mailchecker"
import MoveTo from "moveto"

declare const STATIC_URL: string

const invalidEmailProvidersList: string[] = [
  ...MailChecker.blacklist(),
  ...freeEmailProviders
]

export function isValidMail(email: string): boolean {
  return MailChecker.isValid(email)
}

export function getCookie(name: string): Nullable<string> {
  let cookieValue: Nullable<string> = null
  if (document.cookie && document.cookie !== "") {
    const cookies: string[] = document.cookie.split(";")
    for (let i = 0; i < cookies.length; i++) {
      const cookie: string = cookies[i].trim()
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === `${name}=`) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1))
        break
      }
    }
  }
  return cookieValue
}

export function setCookie(
  name: string,
  value: number | string,
  seconds?: number
) {
  let expires = ""
  if (seconds) {
    const date: Date = new Date()
    date.setTime(date.getTime() + seconds * 1000)
    expires = `; expires=${date.toUTCString()}`
  }
  document.cookie = `${name}=${value}${expires}; path=/`
}

export function getImageUrl(image: string): string {
  if (!image) {
    return ""
  }

  return image.startsWith("http") || image.startsWith("www")
    ? image
    : `${STATIC_URL}www/internal/img/${image}`
}

export function getAssetUrl(asset: string): string {
  if (!asset) {
    return ""
  }

  return asset.startsWith("http") || asset.startsWith("www")
    ? asset
    : `${STATIC_URL}static/assets/www/internal/${asset}`
}

export function preloadImages(images: string[], callback?: () => void) {
  const promises: Promise<any>[] = images.map(
    (image: string): Promise<any> => {
      return new Promise(resolve => {
        const img: HTMLImageElement = new Image()
        img.onload = resolve
        img.src = getImageUrl(image)
      })
    }
  )
  Promise.all(promises).then((/* results */) => {
    if (typeof callback === "function") {
      callback()
    }
  })
}

export function hexToRgb(hex: string): Nullable<string> {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  const fullRegex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i

  // Expand shorthand form to full form ("03F" => "0033FF")
  hex = hex.replace(
    shorthandRegex,
    (marker: string, red: string, green: string, blue: string): string => {
      return `${red}${red}${green}${green}${blue}${blue}`
    }
  )

  const result: Nullable<string[]> = fullRegex.exec(hex)
  if (Array.isArray(result)) {
    const [, /*#*/ red, green, blue] = result
    return `${parseInt(red, 16)}, ${parseInt(green, 16)}, ${parseInt(blue, 16)}`
  }
  return null
}

export function fix100Vh() {
  // 1vh is 1% of the window height, in this case we will calculate 1vh based on visible viewport
  // Useful to use vh for mobile, since often the original one takes in consideration also overlay elements
  // such as the android bottom bar, resulting, for example, in having a 100vh that scrolls
  // (intead of being exactly the viewport height).
  // It is suggested to always give a fallback when using a CSS var, so that IE works correctly, e.g.:
  // min-height: 100vh; /*fallback*/
  // min-height: calc((var(--realVh, 1vh) * 100));

  const trueVh: number = window.innerHeight * 0.01
  document.documentElement.style.setProperty("--realVh", `${trueVh}px`)
}

export function isExternalLink(link: string) {
  return link.startsWith("http") || link.startsWith("mailto")
}

export function generateLocalizedPath(pathKey: string, locale: string) {
  if (isExternalLink(pathKey)) {
    return pathKey
  }

  try {
    const link: string = urls[pathKey][locale]
    if (isExternalLink(link)) {
      return `${link}`
    }
    return `/${locale}${link}`
  } catch (e) {
    return pathKey
  }
}

export function getParametrizedPath(
  path: string,
  params: {key: string; value: string}[] = []
): string {
  return params.reduce(
    (accumulator, currentParam) =>
      currentParam.key && currentParam.value
        ? accumulator.replace(`/:${currentParam.key}`, `/${currentParam.value}`)
        : accumulator,
    path
  )
}

export function truncate(
  longText: string,
  shortText: Nullable<string>,
  charNumber: number
): string {
  const descriptionText: string = shortText ? shortText : longText
  return descriptionText.length > charNumber
    ? `${descriptionText.substring(0, charNumber).trim()}...`
    : descriptionText
}

export function jsonToQueryString<T>(json: T): string {
  //@ts-ignore
  const keys: string[] = Object.keys(json)
  if (keys.length > 0) {
    const result: string = keys
      .filter((key: string): boolean => !!json[key])
      .map(
        (key: string): string =>
          `${encodeURIComponent(key)}=${encodeURIComponent(String(json[key]))}`
      )
      .join("&")
    return `?${result}`
  }
  return ""
}

export function getAxiosInstance(withCSRF: boolean = true): AxiosInstance {
  const options: AxiosRequestConfig = {
    withCredentials: true
  }
  if (withCSRF) {
    options.headers = {
      "X-CSRFToken": getCookie("glickoncsrftoken") as string
    }
  }
  return axios.create(options)
}

export const passiveEvents = {
  hasSupport: false,
  update() {
    const noop = () => {}
    let passive = false
    const options = Object.defineProperty({}, "passive", {
      get() {
        passive = true
        return true
      }
    })
    window.addEventListener("testPassiveEventSupport", noop, options)
    window.removeEventListener("testPassiveEventSupport", noop, options)
    passiveEvents.hasSupport = passive
  }
}

export const isCorporateAndValidEmail = (email: Nullable<string>): boolean => {
  if (!email) {
    return true // this case is handled by required
  }
  const [, provider] = email.split("@")
  if (!provider) {
    return false
  }
  return !invalidEmailProvidersList.includes(provider)
}

export function chunk<T>(array: T[], size: number): T[][] {
  const result: T[][] = []
  const finalSize: number = Math.ceil(array.length / size)

  for (let i = 0; i < finalSize; i++) {
    const start: number = i * size
    const end: number = start + size
    result.push(array.slice(start, end))
  }
  return result
}

// Will take first retrieved error description or return and empty string
export const getErrorText = (e: AxiosError): string => {
  if (!e.response || !e.response.data || !e.response.data.errors) {
    return ""
  }

  const errors = e.response.data.errors
  if (typeof errors !== "object") {
    return ""
  }
  const errorsKeys = Object.keys(errors)
  if (errorsKeys.length < 1) {
    return ""
  }

  // E.g. -> {"errors":{"non_field_errors":["Wrong email or password"]}}
  return errors[errorsKeys[0]][0] || ""
}

export function getParameterByName(name: string, url: string): string {
  // eslint-disable-next-line
  name = name.replace(/[\[\]]/g, "\\$&")
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`)
  const results = regex.exec(url)

  if (!results || !results[2]) return ""

  return decodeURIComponent(results[2].replace(/\+/g, " "))
}

export function getCandidateImageUrl(image: string): string {
  return image.match(/^http/) || image.match(/^www/)
    ? image
    : `${STATIC_URL}candidate/internal/img/${image}`
}

export function isMobileCheck(): boolean {
  return !!(
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  )
}

export function stripTags(text: string) {
  if (typeof text !== "string") return text

  const div = document.createElement("div")
  div.innerHTML = text

  return div.textContent || div.innerText || ""
}

function easeInOutCubic(t: any, b: any, c: any, d: any) {
  t /= d / 2
  if (t < 1) return (c / 2) * t * t * t + b
  t -= 2
  return (c / 2) * (t * t * t + 2) + b
}

const moveTo = new MoveTo(
  {
    duration: 1000,
    easing: "easeInOutCubic",
    tolerance: 85
  },
  {easeInOutCubic}
)

export function smoothScrollTo(elementId: string) {
  const target = document.getElementById(elementId)
  if (!target) return

  moveTo.move(target)
}

export function getPathToLoginOrSignupByKey({
  key,
  locale,
  companyCareersName
}: {
  key: string
  locale: string
  companyCareersName: string | undefined
}): string {
  // If param key is not present it will return the same value as generateLocalizedPath
  return getParametrizedPath(generateLocalizedPath(key, locale), [
    {key: "companyName", value: companyCareersName || ""}
  ])
}

export function navigateToLoginOrSignupByKey({
  key,
  locale,
  companyCareersName,
  nextUrl
}: {
  key: string
  locale: string
  companyCareersName: string | undefined
  nextUrl?: string
}): void {
  const baseRedirectUrl = getPathToLoginOrSignupByKey({
    key,
    locale,
    companyCareersName
  })

  const redirectUrl = nextUrl
    ? appendToQueryString(
        baseRedirectUrl,
        `next_url=/${nextUrl.replaceAll("//", "/")}`
      )
    : baseRedirectUrl

  window.location.href = redirectUrl
}

export function appendToQueryString(
  url: string,
  appendString?: string
): string {
  if (!url || !appendString) return url

  const hasAlreadyQueryString = url.indexOf("?") >= 0

  return `${url}${hasAlreadyQueryString ? "&" : "?"}${appendString}`
}

export function calculateColumnWidth(
  minColumn: number,
  elementsNumber: number
): number {
  return Math.min(minColumn, Math.floor(12 / elementsNumber))
}

export function loadScript(
  scriptId: string,
  scriptSrc: string
): Promise<HTMLScriptElement> {
  return new Promise((resolve, reject) => {
    const existingTag: HTMLScriptElement = document.getElementById(
      scriptId
    ) as HTMLScriptElement
    if (!!existingTag) {
      resolve(existingTag)
    } else {
      const s: HTMLScriptElement = document.createElement("script")
      s.id = scriptId
      s.crossOrigin = "anonymous"
      s.type = "application/javascript"
      s.onload = () => resolve(s)
      s.onerror = e => reject(e)
      s.src = scriptSrc

      document.head.appendChild(s)
    }
  })
}

export function loadStyle(
  styleId: string,
  styleHref: string
): Promise<HTMLLinkElement> {
  return new Promise(resolve => {
    const existingTag: HTMLLinkElement = document.getElementById(
      styleId
    ) as HTMLLinkElement
    if (!!existingTag) {
      resolve(existingTag)
    } else {
      const s: HTMLLinkElement = document.createElement("link")
      s.id = styleId
      s.crossOrigin = "anonymous"
      s.rel = "stylesheet"
      s.onload = () => resolve(s)
      s.href = styleHref

      document.head.appendChild(s)
    }
  })
}

export const getCurrentQueryString = (): string =>
  window.location.href.split("?")[1]

// On social login we need to convert something like
// /company-challenges/ACME01
// to
// company-challenges--ACME01
// for the server to be able to process it correctly
export const getSocialNextUrlFromBasic = (
  redirectUrlSuffix: string | undefined
): string | undefined =>
  redirectUrlSuffix &&
  redirectUrlSuffix.replace(/^\/|\/$/g, "").replace("/", "--")
