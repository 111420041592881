import { Dictionary } from "../types"
import { getAssetUrl } from "../utils"

export const pardotRequestFormUrl =
  "https://go.pardot.com/l/702643/2019-03-28/2d6h"
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?!.* )(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,15}$)/
export const cookieLawAcceptedName = "glickon-cookie"

export const defaultLocale = "it"

export const LANGUAGES = {
  italian: "it",
  english: "en"
}

export enum LanguageEnum {
  Italian = "it",
  English = "en"
}

export enum ProductPagesEnum {
  CANDIDATE = "CANDIDATE_EXPERIENCE",
  EMPLOYEE = "EMPLOYEE_EXPERIENCE",
  PEOPLE_ANALYTICS = "PEOPLE_ANALYTICS",
  REQUEST_DEMO = "REQUEST_A_DEMO"
}

export const locales: Dictionary<string> = {
  [LANGUAGES.english]: "English",
  [LANGUAGES.italian]: "Italiano"
}
export const acceptedLocales: string = Object.keys(locales).join("|")
export const availableLocales: [string, string][] = Object.entries(locales)

export const FLAGS_MAP = {
  [LANGUAGES.italian]: "flags/italy.png",
  [LANGUAGES.english]: "flags/uk.png"
}

export const defaultTitle = "Glickon"
export const htmlTitleTemplate = `${defaultTitle} - %s`

export const coreBaseUrl = process.env.REACT_APP_API_URL
export const platformByUserScope = `${process.env.REACT_APP_API_URL}/redirect_to_platform_by_user_scope`
export const corporatePlatformUrl = `${process.env.REACT_APP_API_URL}/corporate/new`
export const candidatePlatformUrl = `${process.env.REACT_APP_API_URL}/candidate`

export const socialLoginAfterTCConfirmationNextUrlStorageKey =
  "socialLoginAfterTCConfirmationNextUrl"

export const privacyPolicyKey = getAssetUrl("docs/privacy-policy.pdf")
