//@ts-ignore
import React from "react"
//@ts-ignore
import {RouteComponentProps, withRouter, Redirect} from "react-router"
import {AccessUserType} from "../../types/accessUser"
import useCompanyInfo from "../../hooks/useCompanyInfo"
import {generateLocalizedPath, getImageUrl} from "../../utils"
import {notFoundKey} from "../../constants/pathKeys"
import useTranslation from "../../hooks/useTranslation"
import LoaderFullScreen from "../../commonComponents/loader-full-screen"
import AccessResetPassword from "../../commonComponents/access-reset-password"
import {confirmResetPassword} from "../../requests/confirmResetPassword"
import {EmailFloatingButton} from "../../commonComponents/email-floating-button/EmailFloatingButton"

interface ParamsType {
  companyName?: string
  userId: string
  token: string
}

type PropsType = RouteComponentProps<ParamsType> & {
  userType: AccessUserType
}

const ResetPasswordContainer: React.FC<PropsType> = ({
  match: {
    params: {companyName, userId, token}
  },
  userType
}: PropsType) => {
  const {
    companyInfo,
    companyInfoRetrieved: readyToRender,
    requiredCompanyInfoNotAvailable
  } = useCompanyInfo(
    // Won't matter for user type corporate, since he won't have a company name
    userType === AccessUserType.Employee ? "employees" : "candidates",
    companyName
  )

  const [, locale] = useTranslation()

  if (!readyToRender) return <LoaderFullScreen />

  return requiredCompanyInfoNotAvailable ? (
    <Redirect to={`${generateLocalizedPath(notFoundKey, locale)}`} />
  ) : (
    <React.Fragment>
      <AccessResetPassword
        userId={userId}
        resetPasswordToken={token}
        userType={userType}
        changePasswordFunction={confirmResetPassword}
        backgroundUrl={
          companyName
            ? companyInfo.landingPageBackgroundUrl
            : getImageUrl(`access/banner-${userType}.png`)
        }
        logoUrl={companyInfo.logoUrl}
      />
      <EmailFloatingButton />
    </React.Fragment>
  )
}

export default withRouter<PropsType, React.FC<PropsType>>(
  ResetPasswordContainer
)
