import { TranslationType } from "../types"
import {
  aboutUsKey,
  candidateExperienceKey,
  candidatePlatformKey,
  caseStudiesKey,
  caseStudyKey,
  platformCookieKey,
  employeeExperienceKey,
  homepageKey,
  jobKey,
  liveKey,
  livePrivacyKey,
  liveTermsAndConditionsKey,
  notFoundKey,
  peopleAnalyticsKey,
  platformPrivacyKey,
  requestADemoKey,
  resourcesCandidateExperienceKey,
  resourcesEmployeeExperienceKey,
  resourcesKey,
  resourcesPeopleAnalyticsKey,
  platformSecurityKey,
  teamKey,
  platformTermsAndConditionsKey,
  termsOfServicesKey,
  landingAwardKey,
  loginKey,
  signupKey,
  candidatePlatformSignupKey,
  candidatePlatformCompanySignupKey,
  candidatePlatformCompanyKey,
  employeePlatformKey,
  employeePlatformSignupKey,
  employeePlatformCompanyKey,
  employeePlatformCompanySignupKey,
  documentGeneralTermsAndConditionsKey
} from "./pathKeys"

const titles: TranslationType = {

  [candidatePlatformKey]: {
    en: "Glickon - Candidate experience - Login",
    it: "Glickon - Candidate experience - Login"
  },
  [candidatePlatformCompanyKey]: {
    en: "Glickon - Candidate experience - Login",
    it: "Glickon - Candidate experience - Login"
  },

  [candidatePlatformSignupKey]: {
    en: "Glickon - Candidate experience - Signup",
    it: "Glickon - Candidate experience - Signup"
  },
  [candidatePlatformCompanySignupKey]: {
    en: "Glickon - Candidate experience - Signup",
    it: "Glickon - Candidate experience - Signup"
  },

  [employeePlatformKey]: {
    en: "Glickon - Employee experience - Login",
    it: "Glickon - Employee experience - Login"
  },
  [employeePlatformCompanyKey]: {
    en: "Glickon - Employee experience - Login",
    it: "Glickon - Employee experience - Login"
  },

  [employeePlatformSignupKey]: {
    en: "Glickon - Employee experience - Signup",
    it: "Glickon - Employee experience - Signup"
  },
  [employeePlatformCompanySignupKey]: {
    en: "Glickon - Employee experience - Signup",
    it: "Glickon - Employee experience - Signup"
  },

  // home
  [homepageKey]: {
    en: "Employee experience platform",
    it: "Piattaforma employee experience"
  },

  // login
  [loginKey]: {
    en: "Employee experience platform",
    it: "Piattaforma employee experience"
  },

  // signup
  [signupKey]: {
    en: "Employee experience platform",
    it: "Piattaforma employee experience"
  },

  // request a demo
  [requestADemoKey]: {
    en: "Book a demo",
    it: "Richiedi una demo"
  },
  [landingAwardKey]: {
    en: "Glickon Experience Awards 2019",
    it: "Glickon Experience Awards 2019"
  },
  // products
  [candidateExperienceKey]: {
    en: "Candidate experience",
    it: "Candidate experience"
  },
  [employeeExperienceKey]: {
    en: "Employee experience",
    it: "Employee experience"
  },
  [peopleAnalyticsKey]: {
    en: "People analytics",
    it: "People analytics"
  },
  [liveKey]: {
    en: "HR gamification",
    it: "HR gamification"
  },

  // about
  [aboutUsKey]: {
    en: "",
    it: "Employee experience e Candidate experience"
  },
  [teamKey]: {
    en: "",
    it: "Glickon experience"
  },
  [jobKey]: {
    en: "",
    it: ""
  },

  // resources
  [resourcesKey]: {
    en: "",
    it:
      "Roi candidate experience, roi employee experience, roi people analytics"
  },
  [resourcesEmployeeExperienceKey]: {
    en: "",
    it: "Roi employee experience"
  },
  [resourcesCandidateExperienceKey]: {
    en: "",
    it: "Roi candidate experience"
  },
  [resourcesPeopleAnalyticsKey]: {
    en: "",
    it: "Roi people analytics"
  },

  // case-study
  [caseStudiesKey]: {
    en: "",
    it: "Employee e candidate experience: casi di successo"
  },
  [caseStudyKey]: {
    en: "",
    it: "Glickon partner experience"
  },

  // cms pages
  [platformCookieKey]: {
    en: "",
    it: ""
  },
  [documentGeneralTermsAndConditionsKey]: {
    en: "",
    it: ""
  },
  [livePrivacyKey]: {
    en: "",
    it: ""
  },
  [liveTermsAndConditionsKey]: {
    en: "",
    it: ""
  },
  [platformPrivacyKey]: {
    en: "",
    it: ""
  },
  [platformTermsAndConditionsKey]: {
    en: "",
    it: ""
  },
  [termsOfServicesKey]: {
    en: "",
    it: ""
  },
  [platformSecurityKey]: {
    en: "",
    it: ""
  },

  [notFoundKey]: {
    en: "",
    it: ""
  }
}

export default titles
