import React from "react"
import throttle from "lodash/throttle"
import { passiveEvents } from "../utils"

interface HookReturnType {
  x: number
  y: number
}

const useScrollPosition = (throttleMs: number = 100): HookReturnType => {
  const [scroll, setScroll] = React.useState<HookReturnType>({
    x: window.pageXOffset,
    y: window.pageYOffset
  })

  const handler = throttle(() => {
    setScroll({
      x: window.pageXOffset,
      y: window.pageYOffset
    })
  }, throttleMs)

  React.useLayoutEffect(() => {
    const options = passiveEvents.hasSupport
      ? {
        capture: false,
        passive: true
      }
      : false
    window.addEventListener("scroll", handler, options)
    return () => {
      window.removeEventListener("scroll", handler, options)
      handler.cancel()
    }
  }, [handler])

  return scroll
}

export default useScrollPosition
