import humps from "humps"
import { AxiosError, AxiosResponse } from "axios"
import { getAxiosInstance } from "../utils"
import { CompanyInfo } from "../types/companyInfo"

export async function getCompanyInfo(
  companyName: string,
  scope: "candidates" | "employees",
  successFn: (res: AxiosResponse<CompanyInfo>) => void,
  errorFn: (err: AxiosError) => void
) {
  try {
    const response: AxiosResponse<any> = await getAxiosInstance().get(
      `${process.env.REACT_APP_API_URL}/api/candidate/latest/companies/${companyName}?lite_login=true&scope=${scope}`
    )
    successFn(humps.camelizeKeys(response) as AxiosResponse<CompanyInfo>)
  } catch (error) {
    errorFn(error as unknown as AxiosError)
  }
}
