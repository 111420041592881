import {CompanySection} from "../types/companySection"
import {useGetCompanySectionRequest} from "./useRequest"
import useTranslation from "./useTranslation"
import {useEffect, useContext, useState} from "react"
import {
  handleExternalPageRedirect,
  getCorePath,
  getIsAboutToRedirect
} from "./hooksUtils"
import BottomNotificationContext from "../bottomNotificationContext"
import {useStoreActions} from "../store/store"

// Meant to be used for external pages
const useCompanySectionExternalPage = (
  id: number
): {
  companySection?: CompanySection
  hasError: boolean
  redirectPath?: string
  corePath: string
} => {
  const {data, error} = useGetCompanySectionRequest(id)
  const [, locale] = useTranslation()
  const {setMessageLabel} = useContext(BottomNotificationContext)
  const [redirectPath, setRedirectPath] = useState<string>()
  const updateAuth = useStoreActions(actions => actions.auth.update)

  // Used either to redirect immediatly or later as next url
  // as result of a user action
  const corePath = getCorePath({sectionId: id})

  useEffect(() => {
    // Both error and data may contain the redirect metadata, we give priority to error
    const redirectMetadata =
      (typeof error !== "string" ? error : undefined) || data

    const {
      notificationMessageLabel,
      redirectPath: epRedirectPath,
      forcedLogout
    } = handleExternalPageRedirect(redirectMetadata, locale, {
      sectionId: id,
      corePath
    })

    if (forcedLogout) {
      updateAuth({logged: false, platform: undefined})
    }

    if (notificationMessageLabel) setMessageLabel(notificationMessageLabel)

    // setTimeout 0 put this at the end of the event loop and avoids
    // "Can't perform a React state update on an unmounted component" error
    if (epRedirectPath) setTimeout(() => setRedirectPath(epRedirectPath), 0)
  }, [id, data, locale, setMessageLabel, corePath, error, updateAuth])

  // Used to still show loading when we are about to redirect
  const aboutToRedirect = getIsAboutToRedirect(data, error)

  return {
    hasError: !!error && !aboutToRedirect,
    companySection: aboutToRedirect ? undefined : data,
    redirectPath,
    corePath
  }
}

export default useCompanySectionExternalPage
