import {TranslationType} from "../types"

const translations: TranslationType = {
  // header-product
  "employee-experience-top-title": {
    en: "Employee Experience",
    it: "Employee Experience"
  },
  "employee-experience-top-description": {
    en:
      "Focus on your employees. And create for them an unforgettable experience",
    it:
      "Metti al centro i tuoi dipendenti. E disegna intorno a loro un’esperienza indimenticabile"
  },
  "employee-experience-header-paragraph-1-title": {
    en: "Listen",
    it: "Listen"
  },
  "employee-experience-header-paragraph-1-text": {
    en: "Collect actionable data about your employees with simple and quick surveys.",
    it: "Raccogli dati azionabili sulla tua popolazione aziendale, con survey semplici e immediate."
  },
  "employee-experience-header-paragraph-2-title": {
    en: "Measure",
    it: "Measure"
  },
  "employee-experience-header-paragraph-2-text": {
    en: "Leverage the insights collected through on a user friendly dashboard and design targeted onboarding, training and development and internal communication experiences.",
    it: "Sfrutta gli insight raccolti grazie a una semplice dashboard e progetta esperienze mirate di onboarding, training & development, comunicazione interna."
  },
  "employee-experience-header-paragraph-3-title": {
    en: "Experience",
    it: "Experience"
  },
  "employee-experience-header-paragraph-3-text": {
    en: "Improve engagement and well being, reduce attrition and optimize productivity.",
    it: "Costruisci un’esperienza di candidatura memorabile e un employer branding unico."
  },

  // subtitle-module-1
  "employee-experience-1st-subtitle-title": {
    en: "how it works",
    it: "come funziona"
  },
  "employee-experience-1st-subtitle-description": {
    en: `
            Talent wants for challenges, growth, awareness, and seeks space in a corporate culture that rewards
            expectations. We provide you with a platform to measure and understand the experience of people working in
            the company. A modular solution, immediate and accessible from any device. A dashboard from which you can
            activate employees’ engagement, access results in real time and build action plans. Your goal? A performance
            analysis that outlines the shape of your workforce to make it more productive.
        `,
    it: `
            Il talento chiede stimoli, crescita, consapevolezza, cerca
            spazio in una cultura aziendale che premia le sue
            aspettative. Noi ti forniamo una piattaforma per misurare e
            comprendere l’esperienza delle persone che lavorano in
            azienda. Una soluzione modulabile, immediata e
            accessibile da qualsiasi device. Una dashboard dalla quale
            attivare l’engagement dei dipendenti, accedere in tempo
            reale ai risultati e costruire piani d’azione. Il tuo obiettivo?
            Un’analisi delle performance che delinea la forma della tua
            forza lavoro per renderla più produttiva.
        `
  },

  // widget-3-columns
  "employee-experience-widget-3-columns-1st-title": {
    en: "Capture the experience",
    it: "Fotografa l’esperienza"
  },
  "employee-experience-widget-3-columns-1st-description": {
    en: `
            Snap pictures for each work team or single employee, on the various touchpoints in the areas of your 
            interest. And measure the experience.        
        `,
    it: `
            Scatta tanti ritratti, per team di lavoro o singolo dipendente,
            sui vari touchpoint nelle aree di tuo interesse. E misura
            l’esperienza.
        `
  },
  "employee-experience-widget-3-columns-2nd-title": {
    en: "Analyze your survey",
    it: "Analizza la tua indagine"
  },
  "employee-experience-widget-3-columns-2nd-description": {
    en: `
            Now look at the company through the eyes of your resources. The data describes skills, engagement, 
            performance, as analyzed by Glickon’s algorithms.
        `,
    it: `
            Ora vedi l’azienda con gli occhi delle tue risorse. I dati
            descrivono competenze, engagement, performance,
            analizzati dagli algoritmi di Glickon.
        `
  },
  "employee-experience-widget-3-columns-3rd-title": {
    en: "Make targeted decisions",
    it: "Prendi decisioni mirate"
  },
  "employee-experience-widget-3-columns-3rd-description": {
    en: `
            You have a clear image available to you from the dashboard. Insights allow you to improve the experience 
            and create the best teams.
        `,
    it: `
            Hai di fronte un’immagine definita visibile dalla dashboard.
            Gli insight ti permettono di migliorare l’esperienza e creare i
            migliori team.
        `
  },

  // full-width-img-text
  "employee-experience-1st-horizontal-title": {
    en: "1. Onboarding",
    it: "1. Onboarding"
  },
  "employee-experience-1st-horizontal-description": {
    en: `
            It is the first touchpoint of the employee experience understanding culture, values and objectives of the
            company and of the employee. Made up of HR processes, tools and equipment, connections with colleagues,
            ethical codes of conduct, a formal checklist that is as necessary as it is branched out and dispersive.
            <br/><br/>
            With the Glickon platform you can outline your path, give access to documentation and operating procedures,
            immediately make new resources independent by calibrating the onboarding path with simple but effective
            gamification strategies, together with the analysis of specific KPIs. Glickon is the fast track that speeds
            up the operational optimization process of new hires.
        `,
    it: `
            È il primo touchpoint della employee experience per
            comprendere cultura, valori e obiettivi dell’azienda e del
            dipendente. Fatto di processi HR, strumenti e attrezzature,
            connessioni con i colleghi, codici di condotta etica, una
            checklist formale tanto necessaria quanto ramificata e
            dispersiva.
            <br/><br/>
            Con la piattaforma Glickon puoi delineare il tuo percorso,
            dare accesso a documentazione e procedure operative,
            rendere da subito autonome le nuove risorse misurando il
            percorso di onboarding con strategie di gamification
            semplici ma efficaci, insieme all’analisi di KPIs specifici.
            Glickon è la corsia preferenziale che accorcia i tempi di
            ottimizzazione operativa dei neoassunti.
        `
  },
  "employee-experience-2nd-horizontal-title": {
    en: "2. Learning and Development",
    it: "2. Formazione e Sviluppo"
  },
  "employee-experience-2nd-horizontal-description": {
    en: `
        Do you want to know your employees’ skills and levels? Measure the effectiveness of your training and their learning ability and monitor employee performance in certain areas. Access a database with the profiling of skills and find the most strategic ones to which a retention policy should be applied. Adopt an interactive training program in various locations. Identify the missing skills and build up-skilling and re-skilling plans. 
        `,
    it: `
            Vuoi scoprire quali competenze hanno i tuoi dipendenti e a
            quale livello? Misura l’efficacia dei tuoi interventi formativi e
            la loro capacità di apprendimento, monitora le performance
            dei dipendenti in determinate aree. Accedi a un database
            con la profilazione delle competenze e trova quelle più
            strategiche a cui applicare una politica di retention. Adotta
            un programma di formazione interattiva dislocato in varie
            sedi. Individua le competenze mancanti e costruisci piani di
            up-skilling e re-skilling.
            <br/><br/>  
            In Glickon creiamo dashboard personalizzate per
            l’apprendimento orientato ai risultati, soluzioni di
            gamification per la formazione guidata e autonoma al fine di
            minimizzare il decorso della forgetting curve. Azioni HR che
            si trasformano in repository organizzati per il monitoraggio
            continuo delle performance.
        `
  },
  "employee-experience-3rd-horizontal-title": {
    en: "3. Internal communication",
    it: "3. Comunicazione Interna"
  },
  "employee-experience-3rd-horizontal-description": {
    en: `
          Value your employees with an exclusive platform and cultivate a winning corporate culture. Open a dialogue to raise awareness of your mission and values, welfare services, and exclusive offers. Don’t forget, this is also part of employee experience. 
        `,
    it: `
            Valorizza i dipendenti con una piattaforma esclusiva e
            coltiva una cultura aziendale vincente. Apri un dialogo per
            far conoscere mission e valori, servizi di welfare e offerte
            esclusive. Anche questa è employee experience.
            <br/><br/>
            Ad attivare un canale privilegiato in grado di garantire il
            massimo livello di esperienza godibile in azienda ci
            pensiamo noi. Tu avrai a disposizione dati in tempo reale
            per monitorare accessi, risultati e feedback.
        `
  },

  // products
  "employee-experience-products-title": {
    en: "Key features",
    it: "Funzionalità principali"
  },
  "employee-experience-products-1st-name": {
    en: "A single<br/>communication platform",
    it: "Un’unica piattaforma<br/>di comunicazione"
  },
  "employee-experience-products-1st-description": {
    en: `
            Open and manage a dialogue with employees, providing a dashboard to convey any kind of information.
        `,
    it: `
            Apri e gestisci un dialogo con i dipendenti, mettendo a
            disposizione una dashboard su cui veicolare qualsiasi tipo di informazione.
        `
  },
  "employee-experience-products-2nd-name": {
    en: "Formative independence<br/>for new resources",
    it: "Autonomia formativa<br/>per le nuove risorse"
  },
  "employee-experience-products-2nd-description": {
    en: `
            This is a self service platform. New employees can move forward with their onboarding process by becoming 
            operational and productive as quickly as possible.
        `,
    it: `
            È una piattaforma self service. I neoassunti possono
            intraprendere il percorso di onboarding diventando operativi
            e produttivi nel minor tempo possibile.
        `
  },
  "employee-experience-products-3rd-name": {
    en: "Collect and analyze<br/>the performance indexes",
    it: "Raccogli e analizza<br/>gli indici di performance"
  },
  "employee-experience-products-3rd-description": {
    en: `
            Keep your performance under control; from the dashboard you can track and analyze the learning level of 
            each individual employee.
        `,
    it: `
            Tieni sotto controllo le prestazioni, dalla dashboard puoi
            tracciare e analizzare il livello di apprendimento del singolo
            dipendente.
        `
  },

  // bottom-full-width
  "employee-experience-bottom-title": {
    en: "Designing the experience? Ask the right questions",
    it: "Progettare l’esperienza? Fai le domande giuste"
  },
  "employee-experience-bottom-description": {
    en: `
            From Human Resources to Human Revolution, the HR challenge is open from talent acquisition to talent 
            retention. And there is only one solution: design and implement gratifying, inclusive, rewarding 
            work experiences. 
            <br/><br/>
            Why? You will have a company made up of people with high performance levels. This is the main return 
            on such investment.
            <br/><br/>
            How? All it takes is asking the right questions with a simple engagement strategy, to test the necessary 
            skills to better understand the most important metric: the level of work satisfaction (whether good or bad) 
            based on daily experience. 
        `,
    it: `
            Da Human Resource a Human Revolution, la sfida HR è
            aperta passando da talent acquisition a talent retention. E
            la scelta è obbligata: disegnare e implementare esperienze
            di lavoro gratificanti, inclusive, premianti. 
            <br/><br/>
            Perché? Avrai un’azienda fatta di persone con un alto
            rendimento nelle loro attività. È questo il principale ritorno
            dell’investimento.
            <br/><br/>
            Come? Basta porre le domande giuste con una semplice
            strategia di engagement, con una verifica delle competenze
            per comprendere la metrica più importante: il livello di
            soddisfazione lavorativa (positivo o negativo) basato sulla
            loro esperienza quotidiana. 
        `
  },

  // banner-bottom
  "employee-experience-banner-bottom-title": {
    en: "Now it's your turn, design your Employee Experience!",
    it: "Ora tocca a te, disegna la tua Employee Experience!"
  },
  "employee-experience-banner-bottom-description": {
    en:
      "We put in the design work and data science, but you are the architect drafting talent, ideas and business",
    it:
      "Noi ci mettiamo design e data science, tu sei l’architetto che progetta talento, idee e business"
  }
}

export default translations
