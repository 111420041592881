import {useEffect} from "react"
import {MutableRefObject} from "react"

const useOutsideClick = <T extends HTMLElement>(
  ref: MutableRefObject<T | null>,
  onOutsideClick: () => void
) => {
  const handleClick = (e: any) => {
    if (!ref.current || ref.current.contains(e.target)) return
    onOutsideClick()
  }

  useEffect(() => {
    document.addEventListener("click", handleClick)
    return () => document.removeEventListener("click", handleClick)
  })
}

export default useOutsideClick
