import React from "react"
import {RouteComponentProps, withRouter, Redirect} from "react-router"
import {
  loginCandidate,
  resetPasswordCandidate
} from "../../../requests/candidatePlatform"
import {candidatePlatformUrl} from "../../../constants/global"
import AccessLogin from "../../../commonComponents/access-login"
import {AccessUserType} from "../../../types/accessUser"
import useCompanyInfo from "../../../hooks/useCompanyInfo"
import {generateLocalizedPath} from "../../../utils"
import {notFoundKey} from "../../../constants/pathKeys"
import useTranslation from "../../../hooks/useTranslation"
import LoaderFullScreen from "../../../commonComponents/loader-full-screen"
import {EmailFloatingButton} from "../../../commonComponents/email-floating-button/EmailFloatingButton"

interface ParamsType {
  companyName?: string
}

type PropsType = RouteComponentProps<ParamsType> & {
  inChallengeLogin?: boolean
  redirectUrl?: string
  owner?: string
  customTitle?: string
  customDescription?: string
  customBodyBackgroundColor?: string
  customPrimaryTextColor?: string
  customButtonColor?: string
  customButtonHoverColor?: string
  customButtonTextColor?: string
}
const CandidatePlatformLogin: React.FC<PropsType> = ({
  match: {
    params: {companyName: queryCompanyName}
  },
  inChallengeLogin,
  redirectUrl,
  owner,
  customTitle,
  customDescription,
  customBodyBackgroundColor,
  customPrimaryTextColor,
  customButtonColor,
  customButtonHoverColor,
  customButtonTextColor
}: PropsType) => {
  const companyName = queryCompanyName || owner
  const {
    companyInfo,
    companyInfoRetrieved: readyToRender,
    requiredCompanyInfoNotAvailable
  } = useCompanyInfo("candidates", companyName)
  const [, locale] = useTranslation()

  if (!readyToRender && !inChallengeLogin) return <LoaderFullScreen />
  const hasCustomBackground = !!companyInfo.landingPageBackgroundUrl || false

  return requiredCompanyInfoNotAvailable ? (
    <Redirect to={`${generateLocalizedPath(notFoundKey, locale)}`} />
  ) : (
    <React.Fragment>
      <AccessLogin
        userType={AccessUserType.Candidate}
        companyName={companyName}
        loginFunction={loginCandidate}
        loginRedirectUrl={candidatePlatformUrl}
        resetPasswordFunction={resetPasswordCandidate}
        hasCustomBackground={hasCustomBackground}
        customBodyBackgroundColor={customBodyBackgroundColor}
        customPrimaryTextColor={customPrimaryTextColor}
        customButtonColor={customButtonColor}
        customButtonHoverColor={customButtonHoverColor}
        customButtonTextColor={customButtonTextColor}
        inChallengeLogin={inChallengeLogin}
        redirectUrl={redirectUrl}
        backgroundUrl={
          companyName ? companyInfo.landingPageBackgroundUrl : undefined
        }
        logoUrl={companyInfo.logoUrl}
        customTitle={customTitle}
        customDescription={customDescription}
      />

      <EmailFloatingButton />
    </React.Fragment>
  )
}

export default withRouter<PropsType, React.FC<PropsType>>(
  CandidatePlatformLogin
)
