import styled from "styled-components"
import styleConstants from "../../../../constants/styleConstants"

const getBackgroundPropertyValue = (backgroundUrl?: string): string =>
  `${styleConstants.blue00} ${backgroundUrl ? `url("${backgroundUrl}")` : ""}`

// eslint-disable-next-line no-unexpected-multiline
export const Container = styled.div<{
  backgroundUrl?: string
}>`
  position: relative;
  min-height: 100vh; /*fallback*/
  min-height: calc((var(--realVh, 1vh) * 100));
  padding: 0.7rem 0 3rem 0;

  &::before {
    z-index: -1;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${styleConstants.white};

    @media (min-width: ${styleConstants.desktopMinWidth}) {
      right: 50%;
    }
  }

  &::after {
    display: none;
    z-index: -1;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${({backgroundUrl}) =>
      getBackgroundPropertyValue(backgroundUrl)};
    background-position-x: center;
    background-size: cover;

    @media (min-width: ${styleConstants.desktopMinWidth}) {
      display: block;
      left: 50%;
    }
  }
`

export const Title = styled.div`
  font-family: ${styleConstants.galanoGrotesqueMedium};
  color: ${styleConstants.blue00};
  font-size: ${styleConstants.font.size26};
  padding-bottom: 0.625rem;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    padding-bottom: 0.875rem;
    font-size: ${styleConstants.font.size32};
  }
`

export const Description = styled.div<{withBottomMargin?: boolean}>`
  font-family: ${styleConstants.calibre};
  font-size: ${styleConstants.font.size18};
  color: ${styleConstants.dark01};
  margin-bottom: ${({withBottomMargin}) => (withBottomMargin ? "2rem" : "0")};

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    font-size: ${styleConstants.font.size20};
  }
`

export const ChangeForm = styled.div`
  text-align: center;
  font-family: ${styleConstants.calibre};
  font-size: ${styleConstants.font.size16};
  color: ${styleConstants.dark02};
  margin-top: 1.5rem;

  .action {
    cursor: pointer;
    font-family: ${styleConstants.calibreSemibold};
    color: ${styleConstants.dark01};
  }
`

export const TfaDurationInfo = styled.div`
  font-size: ${styleConstants.font.size14};
  color: ${styleConstants.dark02};
  margin-top: 1rem;
  margin-bottom: 0.25rem;
`
