import { TranslationType } from "../types"

const translations: TranslationType = {
  // header-slides
  "home-header-slide-special-title": {
    en: "Meet linkbiz! Your business in one link.",
    it: "Passa a linkbiz, tutto il tuo business in un link!"
  },
  "home-header-slide-special-actionlabel": {
    en: "Find out more",
    it: "Scopri di più"
  },
  "home-header-slide-special-subtitle": {
    en: "Linkbiz",
    it: "Linkbiz"
  },
  "home-header-slide-special-description": {
    en: "Easy as a link, customizable like a homepage and measurable through analytics. A new service powered by Glickon to simplify your workday.",
    it: "Semplice come un link. Personalizzabile come una homepage. Misurabile con gli analytics. Il nuovo servizio powered by Glickon. Il tuo lavoro, semplicemente."
  },
  "home-header-slide-1st-title": {
    en: "Hire only the best.<br/>The talent challenge<br/>can't be lost.",
    it:
      "Assumi solo i migliori.<br/>Il talento è una sfida che non puoi perdere."
  },
  "home-header-slide-1st-subtitle": {
    en: "candidate experience",
    it: "candidate experience"
  },
  "home-header-slide-1st-description": {
    en: `
            Measure engagement and skills
            Bring the best talent to the company.
            The perfect candidate experience
            starts with a game.
        `,
    it: `
            Misura engagement e competenze. Porta in azienda i migliori talenti. La perfetta candidate experience inizia 
            con un gioco.
        `
  },
  "home-header-slide-2nd-title": {
    en:
      "Give and take the best to and from your people.<br/>Trust us, it's worth it.",
    it: "Dai e prendi il meglio<br/>dalle tue persone.<br/>Fidati, è un affare."
  },
  "home-header-slide-2nd-subtitle": {
    en: "employee experience",
    it: "employee experience"
  },
  "home-header-slide-2nd-description": {
    en: `
            Listen and improve the experience
            of your employees by creating strong relationships.
            Growth and productivity start from positive experience.
        `,
    it: `
            Misura e migliora le esperienze dei tuoi dipendenti creando relazioni forti. Crescita e produttività 
            partono da una employee experience positiva.
        `
  },
  "home-header-slide-3rd-title": {
    en: "Let the data guide you. Gather the key insights<br/>to your business.",
    it:
      "Lasciati guidare dai dati. Ottieni le informazioni chiave per il tuo business."
  },
  "home-header-slide-3rd-subtitle": {
    en: "people analytics",
    it: "people analytics"
  },
  "home-header-slide-3rd-description": {
    en: `
            Form a data-driven HR culture.
            Discover the effectiveness of people analytics.
            Collect data and insights to make
            people based decisions.
        `,
    it: `
            Coltiva una cultura HR data-driven. Scopri l’efficacia della people analytics. Raccogli dati e insight per 
            prendere decisioni sulle persone.
        `
  },

  // subtitle-module-1
  "home-1st-subtitle-title": {
    en: "how it works",
    it: "come funziona"
  },
  "home-1st-subtitle-description": {
    en: `
            The Glickon platform gives you an opportunity: to get candidates and employees involved in a gaming 
            experience that generates useful data to hire new talent and increases the performance of the resources 
            already on board. And one goal: to lead your company to the highest levels of profitability and productivity.
        `,
    it: `
            Con la piattaforma Glickon hai un’opportunità: coinvolgere candidati e dipendenti in un’esperienza di gioco 
            che genera dati utili per assumere nuovi talenti e aumentare le performance delle risorse già acquisite. 
            E un obiettivo: portare la tua azienda al più alto livello di redditività e produttività. 
        `
  },

  // widget-3-columns
  "home-widget-3-columns-1st-title": {
    en: "Design your experience",
    it: "Disegna la tua esperienza"
  },
  "home-widget-3-columns-1st-description": {
    en: `
            Create the best challenges for future candidates and improve the experience of the talent you already have. 
            However and whenever you want.
        `,
    it: `
            Crea le sfide più adatte per i futuri candidati e migliora l’esperienza dei talenti che hai già assunto. 
            Come e quando vuoi tu.
        `
  },
  "home-widget-3-columns-2nd-title": {
    en: "Create engagement through games",
    it: "Crea engagement con il gioco"
  },
  "home-widget-3-columns-2nd-description": {
    en: `
            Quizzes, trivia games, open questions. Gamification involves and collects candidate and employee data on 
            the skills and topics that you choose.
        `,
    it: `
            Quiz, trivia game, domande aperte. La gamification coinvolge e raccoglie dati di candidati e dipendenti 
            su competenze e argomenti che preferisci.
        `
  },
  "home-widget-3-columns-3rd-title": {
    en: "Look at the data to improve productivity",
    it: "Migliora la produttività grazie ai dati"
  },
  "home-widget-3-columns-3rd-description": {
    en: `
            Glickon’s algorithms organize and analyze data. They make HR teams agile and help you make facts based 
            strategic decisions.
        `,
    it: `
            Gli algoritmi Glickon organizzano e analizzano i dati. Rendono agili i team HR e ti aiutano a prendere 
            decisioni strategiche.
        `
  },

  // products-module
  "home-products-action": {
    en: "explore the platform",
    it: "esplora la piattaforma"
  },
  "home-products-1st-description": {
    en: `
            Find and select new resources with a simple, intuitive and customizable gaming platform, and hire the best 
            talents, speeding up the work of the recruiting team.
        `,
    it: `
           Trova e seleziona nuove risorse con una piattaforma di gioco semplice, intuitiva, personalizzabile. E assumi 
           i talenti migliori velocizzando il lavoro del team recruiting. 
        `
  },
  "home-products-2nd-description": {
    en: `
            Measures the skills and engagement and performance levels of employees. We give you a tool through which 
            you can listen to their experience; you can value it to build action plans and improve performance 
            and productivity.
        `,
    it: `
            Misura competenze, engagement e performance dei dipendenti. Noi ti diamo un tool per ascoltare la loro 
            esperienza. Tu puoi valorizzarla per costruire piani d’azione e migliorare prestazioni e produttività.
        `
  },
  "home-products-3rd-description": {
    en: `
            An in depth analysis of your company structure: with GlickonX you can collect and analyze your data, 
            complete the missing information with predictive algorithms and get the insights you need to make 
            decisions about people.
        `,
    it: `
            Analizza in profondità la struttura della tua organizzazione, con GlickonX puoi raccogliere e analizzare i
            tuoi dati, completare le informazioni mancanti con algoritmi predittivi e ottenere gli insight di cui hai 
            bisogno per prendere decisioni sulle persone. 
        `
  },

  // banner
  "home-banner-title": {
    en: "Your audience, in an interactive game show",
    it: "La tua audience, dentro un game-show interattivo"
  },
  "home-banner-description": {
    en: `
            Live is the gaming platform suitable for any time and any content and that engages people with feedback and 
            insight in real time, available to your audience from any mobile device or browser.
        `,
    it: `
            Live è la piattaforma di gioco adatta per qualsiasi momento e contenuto, crea engagement con feedback e 
            insight in tempo reale coinvolgendo il tuo pubblico da qualsiasi dispositivo mobile o desktop.
        `
  },
  "home-banner-name": {
    en: "Live",
    it: "Live"
  },

  // subtitle-module-2
  "home-2nd-subtitle-title": {
    en: "Companies around the world are using Glickon",
    it: "La parola a chi ha già scelto Glickon"
  },
  "home-2nd-subtitle-description": {
    en: `
            Now it's up to them, the brands and the people who work every day in HR. Those who turned to us in search 
            of concrete answers to achieve various objectives. Those that today we’re very proud to be able to quote.
        `,
    it: `
            Ora tocca a loro, ai brand e alle persone che lavorano ogni giorno in HR. A chi si è rivolto a noi in cerca 
            di risposte concrete per centrare vari obiettivi. Quelle che oggi (con grande soddisfazione) trovate qui sotto tra le virgolette.
        `
  },

  // banner-bottom
  "home-banner-bottom-title": {
    en: "Now it’s your turn, design your Glickon Experience!",
    it: "Ora tocca a te, disegna la tua Glickon Experience!"
  },
  "home-banner-bottom-description": {
    en:
      "We put design and data science, you are the architect who designs talent, ideas and business",
    it:
      "Noi ci mettiamo design e data science, tu sei l’architetto che progetta talento, idee e business"
  }
}

export default translations
