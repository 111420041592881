import {Dictionary} from "./types"

export interface CustomStyle {
  //background
  customBackgroundColor?: string
  customBorderColor?: string
  customBodyBackgroundColor?: string
  customLightBackgroundColor?: string

  // text
  customPrimaryTextColor?: string
  customSubtitleColor?: string
  customDescriptionColor?: string

  // buttons
  customButtonColor?: string
  customButtonHoverColor?: string
  customButtonTextColor?: string
  customFooterButtonColor?: string
  customSecondaryButtonColor?: string
  customSecondaryButtonTextColor?: string
}

const customStyleMap: Dictionary<CustomStyle> = {
  technogym: {
    //background
    customBackgroundColor: "#000000",
    customBorderColor: "#27292b",
    customBodyBackgroundColor: "#000000",
    customLightBackgroundColor: "#27292b",

    // text
    customPrimaryTextColor: "#ffffff",
    customSubtitleColor: "#53565a",
    customDescriptionColor: "#ffffff",

    // buttons
    customButtonColor: "#f4e116",
    customButtonHoverColor: "#C3B410",
    customButtonTextColor: "#000000",
    customFooterButtonColor: "#27292b",
    customSecondaryButtonColor: "#27292b",
    customSecondaryButtonTextColor: "#ffffff"
  }
}

export const getCustomStyle = (companyName: string): CustomStyle =>
  customStyleMap[companyName] || {}

export const getEmptyCustomStyle = (): CustomStyle => ({})

export const hasCustomStyle = (companyName: string): boolean =>
  !!customStyleMap[companyName]
