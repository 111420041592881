import {TranslationType} from "../types"

const translations: TranslationType = {
  "resources-people-analytics-header-title": {
    en: "People Analytics,<br/>what is it?",
    it: "People Analytics,<br/>che cos’è?"
  },
  "resources-people-analytics-header-description": {
    en: `
            People Analytics is the HR strategy that extrapolates and analyzes the data generated by the people in your
            company. It allows you to apply quantitative measurements to the work that employees perform during the day.
            To obtain information on the status of your organization’s network and its productivity, creating predictive
            models useful for making correct decisions for personnel management, improving the quality of working life,
            performance and business.
        `,
    it: `
            People Analytics è la strategia HR che estrapola e analizza i dati generati dalle persone della tua azienda.
            Permette di applicare misurazioni quantitative al lavoro che i dipendenti svolgono durante la giornata. Per
            ottenere informazioni sullo status del network della tua organizzazione e sulla produttività, creando
            modelli predittivi utili a prendere decisioni corrette per la gestione del personale, migliorare la qualità
            dello stile di vita lavorativa, le prestazioni e il business.
        `
  },

  "resources-people-analytics-large-text-1": {
    en: "Today's data indicates how productive you'll be tomorrow",
    it: "I dati di oggi ti dicono quanto sarai produttivo domani"
  },
  "resources-people-analytics-medium-text-1": {
    en: `
            Let's start with some data acquired from a research projects: Bersin/Deloitte’s analysts verified the degree
            and efficiency of the interaction with technology (collaborative tool) that companies use to manage and
            communicate internally.
            This is the situation. We spend 35% of the day reading and responding to emails. Most companies use an
            average of seven different communication systems and 70% of executives say they will soon add more.
            Just to confirm this trend: Slack has more than eight million users, more than 200,000 companies use
            Microsoft Teams, 30,000 are those with a Facebook Workplace account, 1.2 billion users manage mail on the
            Gmail platform.
            <br/><br/>
            This all creates hyperconnected workplaces with the aim of improving the circulation of information in order
            to benefit productivity. And yet, what often happens is quite the opposite: we check the various
            collaboration and information tools (as well as those of private messaging like WhatsApp) about every 6
            minutes, which means that we can't concentrate on a task without interruptions for even 30 minutes. What
            does this translate to? Billions ($42 billion in the US market only) burned due to low productivity.
            <br/><br/>
            The good news is that we are faced with a huge amount of data that can be tracked and analyzed with a People
            Analytics strategy. GlickonX is a software that is integrated via APIs (or through the import of CSV files)
            to the various tools that a company uses, the algorithms analyze the acquired data and transform them into
            metrics that help the HR teams to understand in depth how productive the employees are.
        `,
    it: `
            Partiamo da alcuni dati acquisiti da una ricerca: gli analisti Bersin/Deloitte hanno verificato il grado e
            l’efficienza dell’interazione con la tecnologia (collaborative tool) che le aziende usano per gestire e
            comunicare internamente il flusso di lavoro.
            Questa è la situazione. Impieghiamo il 35% della giornata per leggere e rispondere alle email. La maggior
            parte delle aziende utilizza una media di sette diversi sistemi di comunicazione e il 70% degli executive
            afferma che presto ne aggiungerà altri.
            Giusto per dare conferma a questa tendenza: Slack ha più di otto milioni di utenti, più di 200mila aziende
            utilizzano Microsoft Teams, 30mila quelle con un account su Facebook Workplace, 1,2 miliardi di user
            gestiscono la posta sulla piattaforma Gmail.
            <br/><br/>
            Luoghi di lavoro iperconnessi con l’obiettivo di migliorare la circolazione di informazioni a vantaggio
            della produttività. Eppure, quello che spesso avviene è il contrario: controlliamo i vari tool di
            collaborazione e informazione (oltre a quelli di messaggistica privata come WhatsApp) circa ogni 6 minuti
            con il risultato che non riusciamo a concentrarci su un compito senza interruzioni per nemmeno 30 minuti.
            Che cosa significa? Miliardi (42 solo nel mercato Usa) bruciati in scarsa produttività.
            <br/><br/>
            La notizia positiva è che siamo di fronte a una mole di dati che è possibile tracciare e analizzare con una
            strategia di People Analytics. GlickonX è un software che si integra via APIs (o importa i file CSV) ai vari
            tool che l’azienda utilizza, gli algoritmi analizzano i dati acquisiti e li trasformano in metriche che
            aiutano i team HR a comprendere in profondità quanto sono produttivi i dipendenti.
        `
  },
  "resources-people-analytics-quote-text-1": {
    en: `
            «I am convinced that nothing we do is more important than hiring and developing people. 
            At the end of the day you bet on people, not on strategies».
        `,
    it: `
            «Niente tra le decisioni che dobbiamo prendere è più importante di far crescere le persone che rendono
            grande la nostra azienda. La vera scommessa è su di loro, non sulle strategie».
        `
  },
  "resources-people-analytics-quote-source-1": {
    en: "Lawrence Bossidy, General Electric",
    it: "Lawrence Bossidy, General Electric"
  },
  "resources-people-analytics-large-text-2": {
    en: "Sentiment & Semantic: listen to your employee",
    it: "Sentiment & Semantic: ascolta le parole dei tuoi dipendenti"
  },
  "resources-people-analytics-medium-text-2": {
    en: `
            A certain lifestyle, with little focus on the tasks to be completed and with execution times that extend
            beyond office hours, also highlights stress levels that probably increase the possibility of leaving one’s
            workplace (thus, on the company’s end, increasing turnover) in search of more wellbeing-oriented solutions.
            In the United States, 40% of employees claim that it is very difficult to find a balance between work and
            family life. From a Harvard Business Review study, in 32% of cases there is an excessive workload that leads
            to voluntary dismissal, a motivation followed by too much overtime (32%).
            <br/><br/>
            As we have already seen in the part dedicated to Employee Experience, the economic effort generated by the 
            search for new personnel, selection, training and achievement of optimal performance targets is very high.
            <br/><br/>
            With the GlickonX technology and the application of a semantic analysis of text in the message flow of
            collaboration tools, you can intercept the level of tension in the conversations of employees (without
            violating privacy and anonymity regulations) and identify the ones at risk of burnout .
            <br/><br/>
            GlickonX is also able to perform a sentiment analysis in an even more accurate text mining phase. The
            algorithms, by tracing the keywords starting from a dictionary that includes the terminology most used in
            the company, assign a score (positive, neutral, negative) that coincides with the emotions expressed, for
            example, in the text of an email. In this way, HR is able to highlight the various levels of satisfaction
            related to employee experience, to step in an effective and timely manner by implementing the most
            appropriate retention policy, or to make decisions to improve relationships within the company and
            workforce.
        `,
    it: `
            Un determinato stile di vita, poco focalizzato sui task da portare a termine e con tempi di esecuzione che
            proseguono fuori dall’orario d’ufficio, evidenzia anche livelli di stress che influenzano con buona
            probabilità il rischio di abbandono del posto (aumentando quindi il turnover) in cerca di soluzioni più
            orientate al wellbeing. Negli States il 40% dei dipendenti sostiene infatti che è molto difficile trovare un
            equilibrio tra lavoro e vita familiare. Da una ricerca Harvard Business Review, nel 32% dei casi è un
            eccessivo carico di lavoro che porta al licenziamento volontario, una motivazione seguita dal troppo
            overtime (32%).
            <br/><br/>
            Come abbiamo già visto nella parte dedicata alla Employee Experience, lo sforzo economico generato da
            ricerca di nuovo personale, selezione, formazione e raggiungimento di target di performance ottimali sono
            molto alti.
            <br/><br/>
            Con la tecnologia GlickonX e l’applicazione di un’analisi semantica dei messaggi di testo presenti nel
            flusso dei tool di collaborazione, puoi far emergere il livello di tensione nelle conversazioni dei
            dipendenti (senza intaccare privacy e anonimato) e individuare quali sono a rischio burnout.
            <br/><br/>
            GlickonX è anche in grado di effettuare un’analisi di sentiment in una fase ancora più accurata di text
            mining. Gli algoritmi, tracciando le parole chiave sulla base di un dizionario che include la terminologia
            più utilizzata in azienda, assegnano un punteggio (positivo, neutro, negativo) che coincide con le emozioni
            espresse, per esempio, nel testo di una email. In questo modo, i colleghi HR sono in grado di mettere in
            luce i vari livelli di soddisfazione legati all’employee experience, per prevenire e intervenire in tempo
            attuando la politica di retention più adeguata. O per prendere decisioni volte a migliorare il clima di
            relazioni all’interno dell’organizzazione.
        `
  },
  "resources-people-analytics-large-text-3": {
    en: "D&I: diversity is in live with productivity... So let’s measure it!",
    it: "D&I: diversità è sinonimo di produttività. E allora, misurala!"
  },
  "resources-people-analytics-medium-text-3": {
    en: `
            A very inclusive company, one that is able to evaluate all the available talent and include them in an
            equitable manner within its organization, must not do so simply because diversity and inclusion is trendy at
            the moment. Looking at gender diversity, according to the latest Global Leadership Forecast survey, having
            female employees in leadership positions increases the level of profitability by 1.4 times. A McKinsey study
            looking instead at ethnic and cultural diversity within a company, points out that performance can even grow
            by 33%. In short, a company whose objective is to create profit, cannot fail to widen its workplace
            circumference in order to discover the best talent.
            <br/><br/>
            GlickonX, through a People Analytics’ strategy shared with HR teams, analyzes the balance of the network and
            highlights the relationships between the sexes (together with roles and hierarchical positions) and the
            links between resources coming from different cultural and territorial experiences. Having these metrics at
            hand allows you to make targeted decisions even when searching for and selecting new staff.
        `,
    it: `
            Un’azienda capace di includere, che valuta tutta l’offerta di talenti disponibile e la inserisce in maniera
            equa all’interno della sua organizzazione, non lo deve fare perché, sintetizzando, l’argomento diversity &
            inclusion va di moda. Parlando di diversità di genere, stando all’ultimo sondaggio Global Leadership
            Forecast, avere dipendenti donne in posizioni di leadership aumenta di 1,4 volte il livello di
            profittabilità. Uno studio di McKinsey, che affronta invece il tema della diversità dal punto di vista
            etnico e culturale, fa invece notare come le performance possono crescere anche del 33%. Insomma, un’azienda
            il cui obiettivo è creare profitto, non può non allargare al massimo la circonferenza dell’insieme di forza
            lavoro al fine di scoprire i migliori talenti.
            <br/><br/>
            GlickonX, attraverso una strategia condivisa di People Analytics con i team HR, analizza il bilanciamento
            del network e mette in evidenza le relazioni tra i sessi (insieme a ruoli e posizione gerarchica) e i legami
            tra le risorse provenienti da esperienze culturali e territoriali diverse. Avere in mano queste metriche
            permette di prendere decisioni mirate anche in fase di ricerca e selezione di nuovo personale.
        `
  },
  "resources-people-analytics-quote-text-2": {
    en: `
            «Be clear about the goals you set for yourself. And include data in the path that will take you to 
            the end, they are the only way to measure success».
        `,
    it: `
            «Siate chiari sugli obiettivi che vi prefissate. E includete i dati nel percorso che vi porterà al
            traguardo, sono l’unico modo per misurare il successo».
        `
  },

  "resources-people-analytics-quote-source-2": {
    en: "John Bersin, HR analyst expert in talent & learning management",
    it: "John Bersin, analista HR esperto di talent & learning management"
  },
  "resources-people-analytics-large-text-4": {
    en:
      "People, but also Talent Analytics. Because you can identify who will lead to your company growth",
    it:
      "People, ma anche Talent Analytics. Perché puoi individuare chi farà crescere la tua azienda"
  },
  "resources-people-analytics-medium-text-4": {
    en: `
            A company grows if it does so along with its workforce. Thus, it is crucial to know the resources with the
            highest probability of climbing the hierarchy of the various degrees of leadership. What follows is a
            network of HIPOs (high-potential employees) in strategic positions that will impact the productivity of the
            entire organization over time. According to a Gartner research, HR teams that are able to put into practice
            an effective High Potential Employees strategy will have 70% more chance of making the company competitive
            on the market: the potential of these resources is quantified in a performance of 21% higher compared to the
            others.
            <br/><br/>
            In this case, GlickonX offers a specific talent analytics solution. The algorithms photograph the metrics
            that highlight, for example, those who have a frequent exchange of messages with senior colleagues, those
            who communicate with a rapid and effective style, those who are acquiring key information even when holding
            a not such important position within the network. It offers HR teams a snapshot of who can be valued even
            more, thus designing the future of the company.
        `,
    it: `
            Un’azienda cresce se lo fa insieme alla sua forza lavoro. Conoscere quindi quali sono le risorse con la più
            alta probabilità di scalare la gerarchia dei vari gradi di leadership è fondamentale. Ciò che ne consegue è
            una rete di HIPOs (dipendenti ad alto potenziale) in posizioni strategiche che impatteranno nel tempo sulla
            produttività di tutta l’organizzazione. Secondo una ricerca Gartner, HR capaci di mettere in pratica
            un’efficace strategia di High Potential Employees avranno il 70% di probabilità in più di rendere l’azienda
            competitiva sul mercato: il potenziale di queste risorse viene quantificato in una performance del 21%
            superiore rispetto alle altre.
            <br/><br/>
            In questo caso, GlickonX offre una soluzione specifica di talent analytics. Gli algoritmi fotografano le
            metriche che evidenziano, per esempio, chi ha un interscambio frequente di messaggi con i colleghi senior,
            chi comunica con uno stile rapido ed efficace, chi sta acquisendo informazioni chiave rispetto a una
            posizione non ancora così privilegiata all’interno del network. Offre ai team di HR un ritratto di chi può
            essere valorizzato ancora di più, disegnando di fatto anche il futuro dell’azienda.
        `
  },
  "resources-people-analytics-large-text-5": {
    en: "ONA. Discover the unexpressed potential of your network",
    it: "ONA. Scopri le potenziali inespresse del tuo network"
  },
  "resources-people-analytics-medium-text-5": {
    en: `
             You definitely know what the company organization chart is, but are you sure you know the relationships
            within it well enough to fully exploit the potential? The answer is that there often is little connection
            between formal job titles and who actually does a certain jobs.
            The organizational network analysis serves precisely to understand in more detail if and how your workplace
            organization is working, and if necessary to redesign the structure and arrange the employees in positions
            where they can maximize their productivity. You can then identify, for example, if there is a redundancy of
            roles within the network, freeing up and integrating the talent who are able to perform better within
            another activity.
            <br/><br/>
            The GlickonX algorithms are activated on ONA, identifying the network of formal and informal relationships
            of high-potential employees. They allow you to identify those employees who, while not holding an official
            position, are able to influence decision-making processes (informal leaders). It brings to the surface
            people who have useful skills for your goals but cannot express their full potential since they are part of
            teams dedicated to other missions (peripherical people). It allows you to discover the potential of
            resources with a natural aptitude for facilitating workflows, communication and culture.
            <br/><br/>
            GlickonX takes an X-ray picture of formal and informal relationship networks to give HR teams a chance to
            reshape corporate governance.
        `,
    it: `
            Sai qual è l’organigramma dell’azienda, ma sei sicuro di conoscerne bene le relazioni per sfruttarne a pieno
            le sue potenzialità? La risposta è che spesso non c’è corrispondenza tra i job title formali e chi
            effettivamente svolge un determinato lavoro.
            La organizational network analysis serve proprio a comprendere più in dettaglio se e come sta funzionando la
            tua organizzazione di lavoro, per riprogettare la struttura e riposizionare i dipendenti in posizioni in cui
            possano massimizzare la loro produttività. Puoi quindi identificare, per esempio, se c’è una ridondanza di
            ruoli all’interno del network, liberando e integrando quei talenti che sono in grado di dare maggiori
            performance in un’altra attività.
            <br/><br/>
            Gli algoritmi di GlickonX si attivano sulla ONA, individuando la rete di relazioni formali e informali dei
            dipendenti ad alto potenziale. Ti permettono di individuare quei dipendenti che pur non ricoprendo una
            posizione ufficiale sono capaci di condizionare i processi decisionali (informal leader). Porta in
            superficie le persone che sì, hanno capacità utili per i tuoi obiettivi, ma che non possono sfruttare perché
            fanno parte di team dedicati ad altre missioni (peripherical people). Fa emergere il potenziale delle
            risorse con un’attitudine naturale nell’agevolare flussi di lavoro, comunicazione e cultura.
            <br/><br/>
            Quello che fa GlickonX è quindi una radiografia delle reti di relazioni formali e informali per dare ai team
            HR la possibilità di rimodellare la governance dell’azienda.
        `
  },
  "resources-people-analytics-large-text-6": {
    en: "ROI. $1 invested, more than $13 capitalized",
    it: "ROI. 1 dollaro investito, più di 13 capitalizzati"
  },
  "resources-people-analytics-medium-text-6": {
    en: `
            An important figure stands out from a Nucleos Research report: for every dollar invested in People Analytics
            strategies, the return is $13.66.
            A considerable ROI, but one that is easy to analyze. The use of data to learn more about the workforce
            allows you to activate targeted actions. Which actions are these?
            <br/><br/>
            All those aimed at creating a satisfying, effective and valuable employee experience. One that is always
            positive.
            Understanding that an employee could take on a different role that would make him or her happier will surely
            have an impact on his or her performance and therefore on the productivity of the company.
            <br/><br/>
            Developing a good balance of D&I means raising the brand’s reputation by attracting many talented people who
            care a great deal that a company believes in inclusion. This means attracting resources who come from
            experiences in other countries, with different points of view, perspectives and knowledge that enrich the
            culture of an organization.
            Knowing the hidden structure of the network makes it possible to value high-potential employees, who are
            already very productive and who will certainly be the driving force for others.
            <br/><br/>
            Identifying critical issues such as excessive workload, inadequate compensation or too much time spent on
            specific activities, makes it possible to foresee and solve problems to avoid or control turnover, with huge
            HR budgetary savings.
            In essence, the strength of People Analytics lies precisely in knowledge, in the possibility of being able
            to take action for the benefit of the company instead of constantly ignoring them.
        `,
    it: `
            Da un report Nucleos Research esce fuori un numero importante. Per ogni dollaro investito in strategie di
            People Analytics, il ritorno è pari a 13,66 dollari.
            Un ROI non indifferente ma facile da analizzare. L’utilizzo dei dati per conoscere in maniera approfondita
            la forza lavoro permette di attivare azioni mirate. Quali azioni?
            <br/><br/>
            Tutte quelle volte a creare una employee esperience appagante, efficace, di valore. In ogni caso, sempre
            positiva.
            Scoprire che un dipendente potrebbe svolgere un’altra mansione rendendolo più felice, avrà sicuramente un
            impatto anche sulle sue performance. E sulla produttività dell’azienda.
            <br/><br/>
            Sviluppare un buon bilanciamento di D&I significa elevare la brand reputation, attraendo tanti talenti
            attenti a una policy aziendale che crede nell’inclusione. Quindi, risorse provenienti anche da esperienze in
            altri Paesi con visioni e capacità che arricchiscono la cultura dell’organizzazione.
            Conoscere la struttura nascosta del network fa in modo di valorizzare i dipendenti ad alto potenziale, che
            già sono molto produttivi e che sicuramente faranno da traino per gli altri.
            <br/><br/>
            Individuare criticità come carichi di lavoro, compensi non adeguati o troppo tempo dedicato in specifiche
            attività, fa sì che si possa prevedere e porre rimedio al turnover. Con risparmi ingenti sui budget HR e sui
            bilanci.
            In sintesi, la forza della People Analytics sta proprio nella conoscenza, nella possibilità di poter
            prendere azioni a vantaggio dell’azienda invece di ignorarle costantemente.
        `
  },

  // banner bottom
  "resources-people-analytics-banner-bottom-title": {
    en: "How much are your people worth? Find it out.",
    it: "Quanto valgono le tue persone? Scoprilo."
  },
  "resources-people-analytics-banner-bottom-description": {
    en:
      "Data and analysis are enough to measure the current and future impact of your business. This is People Analytics. This is GlickonX",
    it:
      "Bastano dati e analisi per misurare l'impatto attuale e futuro del tuo business. Questa è People Analytics. Questo è GlickonX"
  }
}

export default translations
