import styled from "styled-components"
import styleConstants from "../../../../constants/styleConstants"
import SimpleImage from "../../../../commonComponents/image"
import SimpleAction from "../../../../commonComponents/action"

export const CompanySectionInformationsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    position: relative;
    flex-direction: row;
  }
`

export const CompanySectionInformationsLogo = styled(SimpleImage).attrs({
  backgroundCover: true
})`
  border: 1px solid ${styleConstants.cardBorder};
  height: 5rem;
  width: 5rem;
  margin-top: -3.5rem;
  border-radius: ${styleConstants.defaultBorderRadius};

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    border: none;
    display: block;
    height: 8rem;
    width: 8rem;
    margin: 0;
  }
`

export const CompanySectionInformationsContent = styled.div`
  line-height: 1.2;
  padding-top: 0.5rem;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 0;
    padding-left: 2.5rem;
  }
`

// eslint-disable-next-line no-unexpected-multiline
export const CompanySectionInformationsName = styled.div<{
  customColor: string | undefined
}>`
  padding-top: 0.2rem;
  font-size: ${styleConstants.font.size18};
  font-family: ${styleConstants.galanoGrotesqueSemibold};
  color: ${({customColor}) => customColor || styleConstants.blue00};

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    font-size: ${styleConstants.font.size24};
  }
`

// eslint-disable-next-line no-unexpected-multiline
export const CompanySectionInformationsShowMoreButton = styled(SimpleAction)<{
  customColor: string | undefined
  customTextColor: string | undefined
  customHoverColor: string | undefined
}>`
  &.company-section-informations-show-more-button  {
    font-size: ${styleConstants.font.size16};
  }

  margin-top: 0.5rem;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    margin-top: 0;
  }

  &.custom {
    ${({customColor, customTextColor, customHoverColor}) =>
      `
          border: none;
          background: none;
          background-color: ${customColor};
          color: ${customTextColor};

          &:hover:not([disabled]) {
            background: none !important;
            background-color: ${customHoverColor} !important;
            color: ${customTextColor} !important;
            border: none !important;
          }
        `}
  }
`

// eslint-disable-next-line no-unexpected-multiline
export const CompanySectionInformationsDescription = styled.div<{
  customColor: string | undefined
}>`
  padding-top: 0.5rem;
  font-family: ${styleConstants.calibre};
  font-size: ${styleConstants.font.size16};
  color: ${({customColor}) => customColor || styleConstants.dark01};

  p {
    margin-top: 0;
  }

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    font-size: ${styleConstants.font.size18};
    padding-top: 0;
    margin-top: -1.5rem;
  }

  @media (max-width: ${styleConstants.desktopMinWidth}) {
    overflow: hidden;
    text-overflow: ellipsis;
    height: 3rem;
  }
`
