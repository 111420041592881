import styled from "styled-components"
import styleConstants from "../../constants/styleConstants"

export const Container = styled.div`
    .intl-tel-input {
        width: 100%;
        margin-bottom: 1rem;
        input {
            width: 100%;
            padding-top: 1rem;
            padding-bottom: 1rem;
            border-radius: ${styleConstants.defaultBorderRadius};
            border: 1px solid #ccc;
        }
    }
`
