/* eslint-disable space-before-function-paren */
import React, {useEffect, useState} from "react"
import * as Yup from "yup"
import {FormikHelpers} from "formik"
import {AxiosError} from "axios"
import {AccessUserType} from "../../types/accessUser"
import useTranslation from "../../hooks/useTranslation"
import AccessForm, {
  AccessFormValuesType,
  AccessFormInputIds,
  AccessFormType
} from "../access-form"
import {RequestFunction} from "../../types/requestFunction"
import {TranslateFunctionType, Nullable, GlobalStateType} from "../../types"
import HeaderLoginSignup from "../header-login-signup"
import {Title, Container, Description, FormWrapper, Divider} from "./style"
import Translate from "../translate"
import SocialLogin from "../social-login"
import AccessRegisterDone from "../access-register-done"
import {
  getErrorText,
  getParameterByName,
  getSocialNextUrlFromBasic,
  isValidMail
} from "../../utils"
import {candidatePlatformUrl, PASSWORD_REGEX} from "../../constants/global"
import globalStateContext from "../../globalStateContext"
import {homepageKey} from "../../constants/pathKeys"
import {LoginPromptEntry} from "../../types/companyInfo"
import {getOauthConfig} from "../../requests/init"
import {MSSSOWrapper} from "../access-login/style"

enum ViewType {
  Register,
  RegisterDone
}

const initialValues: AccessFormValuesType = {
  [AccessFormInputIds.email]: "",
  [AccessFormInputIds.password]: "",
  [AccessFormInputIds.firstName]: "",
  [AccessFormInputIds.lastName]: "",
  [AccessFormInputIds.hasAcceptedTermsAndConditions]: false,
  [AccessFormInputIds.hasAcceptedPrivacyPolicy]: false
}

const getDescriptionKey = (
  viewType: ViewType,
  userType: AccessUserType
): string => {
  switch (viewType) {
    case ViewType.Register:
      return `form-${userType}-register-welcome-description`
    case ViewType.RegisterDone:
      return `form-${userType}-register-done-description`

    default:
      throw new Error(
        `${userType} Signup Form - viewType: ${viewType} is unknown`
      )
  }
}

const getValidationSchema = (
  translate: TranslateFunctionType,
  privacyPolicyConsentRequired?: boolean
): Yup.AnySchema<AccessFormValuesType> =>
  Yup.object().shape({
    [AccessFormInputIds.firstName]: Yup.string()
      .trim()
      .required(translate("global-error-field-mandatory")),
    [AccessFormInputIds.lastName]: Yup.string()
      .trim()
      .required(translate("global-error-field-mandatory")),
    [AccessFormInputIds.password]: Yup.string()
      .ensure()
      .trim()
      .matches(PASSWORD_REGEX, translate("global-error-password-validation"))
      .required(translate("global-error-field-mandatory")),
    [AccessFormInputIds.email]: Yup.string()
      .trim()
      .email(translate("global-error-field-invalid-email"))
      .test(
        AccessFormInputIds.email,
        translate("global-error-field-invalid-email"),
        (value: undefined | Nullable<string>): boolean => {
          if (!value) {
            // this case is handled by required
            return true
          }
          return isValidMail(value)
        }
      )
      .required(translate("global-error-field-mandatory")),
    [AccessFormInputIds.hasAcceptedTermsAndConditions]: Yup.bool().test(
      AccessFormInputIds.hasAcceptedTermsAndConditions,
      translate("global-error-field-terms-and-conditions-mandatory"),
      (value: undefined | boolean): boolean => {
        return !!value
      }
    ),
    [AccessFormInputIds.hasAcceptedPrivacyPolicy]: Yup.bool().test(
      AccessFormInputIds.hasAcceptedPrivacyPolicy,
      translate("global-error-field-custom-privacy-mandatory"),
      (value: undefined | boolean): boolean => {
        return !!privacyPolicyConsentRequired ? !!value : true
      }
    )
  })

interface PropsType {
  userType: AccessUserType
  companyName?: string // Comes from routing (indicating a whitelabel/private page)
  companyCareersName?: string
  privacyUrl?: string
  registerFunction: RequestFunction
  resendEmailFunction: RequestFunction
  logoUrl?: string
  hasCustomBackground?: boolean
  backgroundUrl?: string
  inChallengeSignup?: boolean
  redirectUrl?: string
  customTitle?: string
  loginPrompts?: LoginPromptEntry[]
  customDescription?: string
  customBodyBackgroundColor?: string
  customPrimaryTextColor?: string
  customButtonColor?: string
  customButtonHoverColor?: string
  customButtonTextColor?: string
  privacyPolicyConsentRequired?: boolean
  privacyPolicyCustomMessage?: {en: string | null; it: string | null}
}

const AccessSignup: React.FC<PropsType> = ({
  userType,
  companyName,
  companyCareersName,
  privacyUrl,
  registerFunction,
  resendEmailFunction,
  logoUrl,
  backgroundUrl,
  hasCustomBackground,
  inChallengeSignup,
  redirectUrl,
  loginPrompts = [],
  customTitle,
  customDescription,
  customBodyBackgroundColor,
  customPrimaryTextColor,
  customButtonColor,
  customButtonHoverColor,
  customButtonTextColor,
  privacyPolicyConsentRequired,
  privacyPolicyCustomMessage
}: PropsType) => {
  const context = React.useContext<GlobalStateType>(globalStateContext)
  if (userType !== AccessUserType.Employee || !companyName) {
    context.preregisterEmail = "" // If it's not a WL Employee signup we reset it
  }
  const [translate] = useTranslation()
  const [errorText, setErrorText] = React.useState<string>("")
  const [currentViewType, setViewType] = React.useState<ViewType>(
    ViewType.Register
  )
  const [currentValues, setValues] = React.useState<AccessFormValuesType>(
    context.preregisterEmail
      ? {...initialValues, email: context.preregisterEmail}
      : initialValues
  )
  const [ssoCOnfig, setSSOConfig] = useState<any>(null)

  const basicNextUrl = redirectUrl
    ? redirectUrl
    : getParameterByName("next_url", window.location.href.replaceAll("//", "/"))

  const socialNextUrl = basicNextUrl
    ? `next_url=${getSocialNextUrlFromBasic(basicNextUrl)?.replaceAll("//", "/")}`
    : ""

  const socialOwnerQueryString = companyName
    ? `owner=${companyName}`
    : undefined

  const socialQueryString =
    socialNextUrl && socialOwnerQueryString
      ? `${socialNextUrl}&${socialOwnerQueryString}`
      : socialNextUrl
      ? socialNextUrl
      : socialOwnerQueryString

  const submitHandler = async (
    values: AccessFormValuesType,
    {setSubmitting}: FormikHelpers<AccessFormValuesType>
  ) => {
    // reset error on every submit
    setErrorText("")

    const dataWithOwner = companyName ? {...values, owner: companyName} : values

    const dataWithOwnerAndRedirectUrl = basicNextUrl
      ? {
          ...dataWithOwner,
          redirectUrl: basicNextUrl.replace(/^\/+/, "")
        }
      : dataWithOwner

    registerFunction<AccessFormValuesType | {owner?: string}, any>(
      dataWithOwnerAndRedirectUrl,
      response => {
        // Autoconfirm setup, will redirect as if user logged,
        // atm can be only candidate or employee (so candidatePlatformUrl is correct),
        // since corporate cannot register from www
        if (response.data && response.data.isVerified) {
          window.location.href = candidatePlatformUrl + basicNextUrl
        } else {
          setViewType(ViewType.RegisterDone)
          context.preregisterEmail = ""
        }
      },
      (e: AxiosError) => {
        setSubmitting(false)

        const errorText = getErrorText(e)
        setErrorText(errorText || translate("global-generic-error"))
      }
    )
  }
  const horizontalForm = !!inChallengeSignup
  const customTitleValue = !!inChallengeSignup && customTitle
  const customDescriptionValue = !!inChallengeSignup && customDescription
  const hideBottomPadding = !!inChallengeSignup

  const title = (
    <Title alignCenter={horizontalForm} customColor={customPrimaryTextColor}>
      {customTitleValue || (
        <Translate label={`form-${userType}-register-welcome-title`} />
      )}
    </Title>
  )

  const description = !window.location.pathname.includes("unifgtalentspace") ? (
    <Description
      alignCenter={horizontalForm}
      id={getDescriptionKey(currentViewType, userType)}
      customColor={customPrimaryTextColor}
    >
      {customDescriptionValue ? (
        <div dangerouslySetInnerHTML={{__html: customDescriptionValue}} />
      ) : (
        <Translate label={getDescriptionKey(currentViewType, userType)} />
      )}
    </Description>
  ) : (
    <Description
      alignCenter={horizontalForm}
      customColor={customPrimaryTextColor}
    >
      <div>
        <Translate label={"unifg-description-1"} />
      </div>
      <div style={{color: "red", marginTop: "0.5rem"}}>
        <Translate label={"unifg-description-2"} />
      </div>
    </Description>
  )

  const getSSOConfig = async () => {
    const res = await getOauthConfig(
      userType === AccessUserType.Employee
        ? AccessUserType.Employee
        : AccessUserType.Candidate,
      companyName || "",
      socialNextUrl.split("--")[1]
        ? `/candidate/company-challenges/${socialNextUrl.split("--")[1]}`
        : ""
    )
    console.log("SSO CONFIG", res.data)
    setSSOConfig(res.data)
  }

  const loginWithMSSSO = () => {
    const config = ssoCOnfig.find(
      (config: any) => config.provider === "MSGraph"
    )
    if (config) {
        let finalUrl = `${config.base_url}?`
        const params = Object.keys(config.query_pars)
        params.forEach((param, index) => {
        finalUrl = `${finalUrl}${param}=${config.query_pars[param]}${
            index === params.length - 1 ? "" : "&"
        }`
        })
        finalUrl = finalUrl.replaceAll("#", "%23")
        window.open(encodeURI(finalUrl).toString(), "_self")
    }
  }

  const hasConfig = (
    sso_type: "Facebook" | "Linkedin" | "Google" | "MSGraph"
  ) => {
    return ssoCOnfig.find((config: any) => config.provider === sso_type)
  }

  useEffect(() => {
    getSSOConfig()
  }, [userType])

  return (
    <Container
      userType={userType}
      backgroundUrl={backgroundUrl}
      hideBackground={horizontalForm}
      hasCustomBackground={hasCustomBackground}
      hideBottomPadding={hideBottomPadding}
      customBodyBackgroundColor={customBodyBackgroundColor}
    >
      {horizontalForm ? null : (
        <HeaderLoginSignup logoUrl={logoUrl} logoLinkKey={homepageKey} />
      )}
      <div className="row">
        <div className="column mobile-12 desktop-6">
          <div className="row">
            <div
              className="column mobile-12 desktop-8"
              style={{backgroundColor: customBodyBackgroundColor}}
            >
              {title}
              {description}
              <FormWrapper
                directionRow={
                  horizontalForm && userType === AccessUserType.Candidate
                }
              >
                {currentViewType === ViewType.Register &&
                  userType === AccessUserType.Candidate &&
                  !window.location.pathname.includes("unifgtalentspace") && (
                    <div id="social-login">
                      {horizontalForm && (
                        <div
                          id="social-subtitle"
                          style={{color: customPrimaryTextColor}}
                        >
                          <Translate label="login-social-subtitle" />
                        </div>
                      )}
                      <SocialLogin
                        urlSuffix={socialQueryString}
                        directionColumn={horizontalForm}
                        ssoCOnfig={ssoCOnfig}
                        isSignup
                      />
                    </div>
                  )}
                {currentViewType === ViewType.Register && horizontalForm && (
                  <Divider />
                )}
                {currentViewType === ViewType.Register && (
                  <>
                    <div id="acces-form">
                      {horizontalForm && (
                        <div
                          id="access-subtitle"
                          style={{color: customPrimaryTextColor}}
                        >
                          <Translate label="signup-form-subtitle" />
                        </div>
                      )}
                      <AccessForm
                        type={AccessFormType.Register}
                        loginPrompts={loginPrompts}
                        loginNextUrl={basicNextUrl}
                        isEmailReadonly={!!context.preregisterEmail}
                        companyName={companyName}
                        companyCareersName={companyCareersName}
                        privacyUrl={privacyUrl}
                        privacyPolicyConsentRequired={
                          privacyPolicyConsentRequired
                        }
                        privacyPolicyCustomMessage={privacyPolicyCustomMessage}
                        userType={userType}
                        initialValues={currentValues}
                        validationSchema={getValidationSchema(
                          translate,
                          privacyPolicyConsentRequired
                        )}
                        submitHandler={submitHandler}
                        onChange={setValues}
                        errorText={errorText}
                        hideChangeForm={horizontalForm}
                        customPrimaryTextColor={customPrimaryTextColor}
                        customButtonColor={customButtonColor}
                        customButtonHoverColor={customButtonHoverColor}
                        customButtonTextColor={customButtonTextColor}
                      />
                    </div>
                    {userType === AccessUserType.Employee &&
                    ssoCOnfig &&
                    hasConfig("MSGraph") ? (
                      <MSSSOWrapper>
                        <div className="divider-wrapper">
                          <div className="divider-side">&nbsp;</div>
                          <div className="divider-mid">Or</div>
                          <div className="divider-side">&nbsp;</div>
                        </div>
                        <button
                          onClick={() => {
                            loginWithMSSSO()
                          }}
                          className="sso-button"
                        >
                          <div
                            style={{
                              marginRight: "15px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center"
                            }}
                          >
                            <svg
                              width="23"
                              height="22"
                              viewBox="0 0 23 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="0.500244"
                                width="10.4762"
                                height="10.4762"
                                fill="#F35325"
                              />
                              <rect
                                x="12.0237"
                                width="10.4762"
                                height="10.4762"
                                fill="#81BC06"
                              />
                              <rect
                                x="0.500244"
                                y="11.5239"
                                width="10.4762"
                                height="10.4762"
                                fill="#05A6F0"
                              />
                              <rect
                                x="12.0237"
                                y="11.5239"
                                width="10.4762"
                                height="10.4762"
                                fill="#FFBA08"
                              />
                            </svg>
                          </div>
                          <p className="sso-text">{translate("sso-ms")}</p>
                        </button>
                      </MSSSOWrapper>
                    ) : null}
                  </>
                )}
              </FormWrapper>
              {currentViewType === ViewType.RegisterDone && (
                <div id="register-done">
                  <AccessRegisterDone
                    resendEmailFunction={resendEmailFunction}
                    userType={userType}
                    companyName={companyName}
                    email={currentValues.email as string}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default AccessSignup
