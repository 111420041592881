import { TranslationType } from "../types"

const translations: TranslationType = {
    "ex-j-headline-title": {
        en: "A day with...<br/>Employee",
        it: "A day with...<br/>Employee"
    },
    "ex-j-headline-text": {
        en: "Talent wants for challenges, growth, awareness, and seeks space in a corporate culture that rewards expectations. We provide you with a platform to measure and understand the experience of people working in the company. A modular solution, immediate and accessible from any device. A dashboard from which you can activate employees’ engagement, access results in real time and build action plans.",
        it: "Insieme a una parola chiave. Experience. Perché l’esperienza è il primo step che mette in evidenza l’appeal del tuo brand, attirando i candidati con un reale interesse verso la tua cultura aziendale. E da qui che generiamo dati per evidenziare il fit index tra il candidato e le competenze richieste, accelerando così la sua operatività e produttività nel successivo periodo di onboarding."
    },
    "ex-j-footer-headline": {
        en: "Now it's your turn, design your Employee Experience!",
        it: "Accetta la sfida, progetta la tua Employee Experience!"
    },
    "ex-j-footer-caption": {
        en: "We put in the design work and data science, but you are the architect drafting talent, ideas and business",
        it: "La costruiamo insieme, ma sarai tu a portare nuovi talenti in azienda"
    },
    "ex-j-script-01": {
        en: "<strong>9.00<br />‍</strong>Lack of enthusiasm? Well, hopefully, something is here to help you. And it’s not coffee!",
        it: "<strong>9.00<br/></strong>Mancanza di entusiasmo? Bene, si spera che qualcosa sia qui per aiutarti e non è un caffè!"
    },
    "ex-j-script-02": {
        en: "<strong>9.30<br /></strong>Indeed, it’s time to take action: your picks will become someone else’s journey, for the day.",
        it: "<strong>9.30<br/></strong>Effettivamente, è ora di agire: le tue scelte diventeranno il viaggio di qualcun altro per la giornata."
    },
    "ex-j-script-03": {
        en: "<strong>10.30<br /></strong>What’s better than starting with an engaging, evolving mission? Being motivated to complete it!",
        it: "<strong>10.30<br/></strong>Cosa c'è di meglio che iniziare con una missione coinvolgente e in continua evoluzione? Essere motivati a completarla!"
    },
    "ex-j-script-04": {
        en: "<strong>11.30<br /></strong>Designing an agile system is priceless. Also, it’s a great chance to reach– and check – record performances.",
        it: "<strong>11.30<br/></strong>Disegnare un sistema agile non ha prezzo. Inoltre, è una grande opportunità per raggiungere e controllare le performance dei record."
    },
    "ex-j-script-05": {
        en: "<strong>13.00<br />‍</strong>It goes without saying: being part of an engaging organization can highly improve your lunch break.",
        it: "<strong>13.00<br/></strong>Inutile dire che far parte di un'organizzazione coinvolgente può migliorare notevolmente la tua pausa pranzo."
    },
    "ex-j-script-06": {
        en: "<strong>14.30<br /></strong>With so many fresh challenges it’s easy to grow skills, motivation and relationships.Every step of the way.",
        it: "<strong>14.30<br/></strong>Con così tante nuove challenges è facile aumentare le skills, motivazione e relazioni. Ogni passo del cammino."
    },
    "ex-j-script-07": {
        en: "<strong>15.30<br /></strong>In order to focus on the right positions and related skills, every piece of data matters.",
        it: "<strong>15.30<br/></strong>Per concentrarsi sulle posizioni giuste e sulle relative competenze, ogni dato è importante."
    },
    "ex-j-script-08": {
        en: "<strong>16.30<br /></strong>Likewise, every feedback matters, making everyone feel part of the mission and put in the effort to give its best.",
        it: "<strong>16.30<br/></strong>Allo stesso modo, ogni feedback è importante, facendo sentire tutti parte della missione e impegnandosi per dare il meglio."
    },
    "ex-j-script-09": {
        en: "<strong>17.30<br /></strong>This means a better experience and productivity – even more, the ability to engage people every day consolidates the feeling of being in the best possible place.",
        it: "<strong>17.30<br/></strong>Ciò significa una migliore esperienza e produttività - ancora di più, la capacità di coinvolgere le persone così da consolidare la sensazione di trovarsi nel miglior posto possibileogni giorno."
    },
    "ex-j-script-10": {
        en: "<strong>18.00<br /></strong>In this process all performances are equally important and everyone is committed, aiding the growth of the organization.",
        it: "<strong>18.00<br/></strong>In questo processo tutte le performance sono ugualmente importanti e tutti sono impegnati, favorendo la crescita dell'organizzazione."
    },
    "ex-j-script-11": {
        en: "<strong>18.30<br /></strong>Inside and outside the office, of course. Would you care to join the party?",
        it: "<strong>18.30<br/></strong>Ovviamente dentro e fuori l'ufficio. Ti andrebbe di unirti alla festa?"
    },
    "ex-j-script-12": {
        en: "<strong>21.00</strong><br />A win-win scenario, where everyone is fulfilled and eager to start all over again, the next day.",
        it: "<strong>21.00<br/></strong>Uno scenario vincente, in cui tutti sono soddisfatti e desiderosi di ricominciare tutto da capo, il giorno successivo."
    },
}

export default translations
