import React, { useState } from "react"
import {
  AutoColumn,
  Bottom,
  Container,
  Copy,
  Info,
  List,
  ListItem,
  SubList,
  SubListItem,
  Logo,
  Panel,
  PanelBody,
  PanelHeader,
  PanelToggle
} from "./styles"
import Spacer from "../spacer"
import Link from "../link"
import AnimateOnScroll from "../animate-on-scroll"
import { footerLinks } from "../../constants/data/footer"
import { FooterLink, Nullable } from "../../types"
import LanguageSwitcher from "../language-switcher"
import { mailKey } from "../../constants/pathKeys"
import Translate from "../translate"

interface PropsType {
  pathKey: string
}

type ItemPropsType = {
  selectedId: Nullable<string>,
  item: FooterLink
}

const FooterLinkItem: React.FC<ItemPropsType> = (props: ItemPropsType) => {
  const [isOpened, setIsOpened] = useState<boolean>(false)

  const {
    item,
    selectedId,
  } = props

  const {
    id, to, label, links = []
  } = item

  const isSelected = selectedId === id
  const toggleIconClass = !isOpened ? "icon icon-add" : "icon icon-close"

  const toggleOpening = (e: React.MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()
    setIsOpened(!isOpened)
  }

  if (links.length) {
    return (
      <List selected={isSelected}>
        <ListItem key={id}>
          <Link to={to} onClick={toggleOpening}>
            <Translate label={label} />
            <i className={toggleIconClass} />
          </Link>
        </ListItem>
        <SubList opened={isOpened} selected={false}>
          {links.map(
            (link: FooterLink): React.ReactNode => {
              const { label, to = "", id } = link
              return (
                <SubListItem key={id}>
                  <Link to={to}>
                    <Translate label={label} />
                  </Link>
                </SubListItem>
              )
            }
          )}
        </SubList>
      </List>
    )
  }

  return (
    <ListItem key={id}>
      <Link to={to}>
        <Translate label={label} />
      </Link>
    </ListItem>
  )
}

const Footer: React.FC<PropsType> = (props: PropsType) => {
  const { pathKey } = props
  const [selected, setSelected] = React.useState<Nullable<string>>(null)

  const select = (id: string) => {
    setSelected(id === selected ? null : id)
  }

  return (
    <Container>
      <Spacer space={3} spaceDesktop={5.1875} />
      <AnimateOnScroll effect="fade" offset={-50}>
        <div className="row">
          <AutoColumn className="column mobile-12">
            <div>
              <Logo localize={false} src="logos/logo-glickon-white.png" />
            </div>
            <Info>
              <Translate label={"global-company-address"} />
            </Info>
            <Info>
              <div>
                <Translate label={"global-company-phone"} />
              </div>
              <Link to={mailKey}>
                <Translate label={"global-company-email"} />
              </Link>
            </Info>
          </AutoColumn>
          {footerLinks.map(
            (link: FooterLink): React.ReactNode => {
              const { links, label, id } = link
              const isSelected: boolean = selected === id
              return (
                <Panel className="column mobile-12" key={id}>
                  <PanelHeader onClick={() => select(id)}>
                    <Translate label={label} />
                    <PanelToggle
                      selected={isSelected}
                      className="icon icon-add"
                    />
                  </PanelHeader>
                  <PanelBody selected={isSelected}>
                    {links && links.length > 0 ? (
                      <List selected={isSelected}>
                        {links.map(
                          (link: FooterLink): React.ReactNode => (
                            <FooterLinkItem selectedId={selected} item={link} />
                          )
                        )}
                      </List>
                    ) : null}
                  </PanelBody>
                </Panel>
              )
            }
          )}
        </div>
        <Bottom>
          <div className="row">
            <div className="column mobile-12 desktop-3">
              <LanguageSwitcher pathKey={pathKey} />
            </div>
            <div className="column mobile-12 desktop-9">
              <Copy>
                <Translate label={"global-company-copy"} />
              </Copy>
            </div>
          </div>
        </Bottom>
      </AnimateOnScroll>
      <Spacer space={3.125} spaceDesktop={3.125} />
    </Container>
  )
}

export default Footer
