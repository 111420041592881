import React, {useState, useRef} from "react"
import {useStoreData} from "../../store/store"
import useTranslation from "../../hooks/useTranslation"
import {FLAGS_MAP} from "../../constants/global"
import {FlagListContainer, Flag, LanguageSelectorContainer} from "./style"
import {
  getCandidateImageUrl,
  generateLocalizedPath,
  getCurrentQueryString
} from "../../utils"
import Link from "../../commonComponents/link"
import {useParams} from "react-router-dom"
import useOutsideClick from "../../hooks/useOutsideClick"

interface Props {}

const AccessLanguageSelector: React.FC<Props> = () => {
  const [, currentLocale] = useTranslation()

  const [isExpanded, setIsExpanded] = useState(false)
  const toggleExpanded = () => setIsExpanded(!isExpanded)

  return (
    <LanguageSelectorContainer>
      {isExpanded && (
        <FlagList
          toggleExpanded={toggleExpanded}
          selectedLanguage={currentLocale}
        ></FlagList>
      )}

      <Flag
        src={getCandidateImageUrl(FLAGS_MAP[currentLocale])}
        onClick={toggleExpanded}
        invisible={isExpanded}
      ></Flag>
    </LanguageSelectorContainer>
  )
}

interface FlagListProps {
  toggleExpanded: () => void
  selectedLanguage: string
}

const FlagList: React.FC<FlagListProps> = ({
  toggleExpanded,
  selectedLanguage
}) => {
  const [, , setLocale] = useTranslation()
  const ref = useRef<HTMLDivElement>(null)
  useOutsideClick(ref, toggleExpanded)

  const {currentPathKey} = useStoreData(state => state.routing)
  const currentLocation = window.location.pathname
  const currentLocalizedLocation = generateLocalizedPath(
    currentPathKey,
    selectedLanguage
  )
  const params = useParams()
  const linkParams = Object.keys(params).map(key => ({key, value: params[key]}))
  const [, extraParams = ""] = currentLocation.split(currentLocalizedLocation)

  const unselectedLanguages = Object.keys(FLAGS_MAP).filter(
    language => language !== selectedLanguage
  )

  const languages = [selectedLanguage].concat(unselectedLanguages)

  return (
    <FlagListContainer ref={ref}>
      {languages.map((language, index) => (
        <Link
          key={language}
          locale={language}
          to={currentPathKey}
          params={linkParams}
          extraParams={extraParams}
          queryStringToAppend={getCurrentQueryString()}
          onClick={() => setLocale(language)}
        >
          <Flag
            src={getCandidateImageUrl(FLAGS_MAP[language])}
            inList={true}
            onClick={toggleExpanded}
            isFirst={index === 0}
            isLast={index === languages.length - 1}
          ></Flag>
        </Link>
      ))}
    </FlagListContainer>
  )
}

export default AccessLanguageSelector
