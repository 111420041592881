import styled from "styled-components"
import styleConstants from "../../constants/styleConstants"

export const Title = styled.div`
  padding: 1rem 0;
  font-family: ${styleConstants.galanoGrotesqueSemibold};
  font-size: ${styleConstants.font.size20};
  color: #0b273d;
  letter-spacing: normal;
`

export const Description = styled.div`
  font-family: ${styleConstants.calibre};
  font-size: ${styleConstants.font.size18};
  color: #444444;

  p {
    margin-top: 0;
  }
`

export const Card = styled.div`
  width: 100%;
`

export const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 1.75rem 1.125rem;
  border: 0.0625rem solid ${styleConstants.cardBorder};
  background-color: ${styleConstants.white};
  width: 100%;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    padding: 2rem 1.75rem;
  }

  ${Card} + ${Card} {
    margin-top: 1rem;
  }
`
