import styled from "styled-components"
import styleConstants from "../../constants/styleConstants"

interface StyledImagePropsType {
  rounded?: boolean
  src: string
  backgroundCoverColor?: string
}

export const StyledImage = styled.img<StyledImagePropsType>`
  flex-shrink: 0; /*reset shrink cause ie11 bug*/
  max-width: 100%;
  max-height: 100%;
  border-radius: ${props =>
    props.rounded ? styleConstants.defaultBorderRadius : 0};
`

export const StyledBackgroundImage = styled.div<StyledImagePropsType>`
    background: ${({backgroundCoverColor = ""}) =>
      backgroundCoverColor} url("${props => props.src}") no-repeat center;
    background-size: cover;
    height: 100%;
    width: 100%;
    border-radius: ${props =>
      props.rounded ? styleConstants.defaultBorderRadius : 0};
`
