import * as React from "react"
import Swiper from "react-id-swiper"
import { CarouselWrapper, Image, Wrapper } from "./styles"
import { Media } from "../../types/media"

type PropsType = {
  id: string
  images: Media[]
}

function Carousel(props: PropsType) {
  const { images, id } = props
  const params: any = {
    allowTouchMove: true,
    spaceBetween: 16,
    slidesPerView: 1,
    resistance: true,
    resistanceRatio: 0,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    scrollbar: {
      el: ".swiper-scrollbar",
      hide: false,
    },
    breakpointsInverse: true,
    breakpoints: {
      1024: {
        spaceBetween: 32,
        slidesPerView: 2,
      },
    },
  }
  return (
    <CarouselWrapper className="column desktop-12 mobile-12" id={id}>
      <Swiper {...params}>
        {images.map((media: Media, index: number) => {
          const { url } = media
          return (
            // eslint-disable-next-line react/no-array-index-key
            <Wrapper key={`${id}-image-${index}`}>
              <Image
                id={`${id}-image-${index}`}
                className={`${id}-image`}
                src={url}
                fallbackSrc="placeholders/placeholder-image.png"
              />
            </Wrapper>
          )
        })}
      </Swiper>
    </CarouselWrapper>
  )
}

export default Carousel
