import React from "react"
import {CompanyChallengeInfo} from "../../../../types/companyChallengeInfo"
import {
  CompanyChallengesListWrapper as ChallengesWrapper,
  CompanyChallengesListChallengeWrapper as ChallengeWrapper
} from "./style"
import Challenge from "../company-challenge-item"

interface Props {
  defaultColor: string
  companyChallenges: CompanyChallengeInfo[]
  customChallengeColor: string | undefined
  customTitleColor: string | undefined
  customDescriptionColor: string | undefined
  customTimeColor: string | undefined
  customBorderColor: string | undefined
}

const CompanyChallengesList: React.FC<Props> = ({
  companyChallenges,
  customBorderColor,
  customChallengeColor,
  customDescriptionColor,
  customTimeColor,
  customTitleColor,
  defaultColor
}) => {
  return (
    <ChallengesWrapper id={"company-details-companyChallenges"} className="row">
      {companyChallenges.map(challenge => (
        <ChallengeWrapper
          key={challenge.hash}
          className="company-details-companyChallenge"
        >
          <Challenge
            companyChallenge={challenge}
            defaultColor={defaultColor}
            customBorderColor={customBorderColor}
            customChallengeColor={customChallengeColor}
            customDescriptionColor={customDescriptionColor}
            customTimeColor={customTimeColor}
            customTitleColor={customTitleColor}
          ></Challenge>
        </ChallengeWrapper>
      ))}

      {/* Empty Wrapper to have a layout without holes */}
      {Array.from({length: companyChallenges.length % 4}).map((_, i) => (
        <ChallengeWrapper
          hideOnMobile
          key={`company-details-companyChallenges-empty-${i}`}
        ></ChallengeWrapper>
      ))}
    </ChallengesWrapper>
  )
}

export default CompanyChallengesList
