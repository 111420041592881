import React from "react"
import {
  WebmailButton,
  WebmailButtonsContainer,
  WebmailIcon,
  WebmailButtonsDescription,
  WebmailButtonsOuterContainer
} from "./styles"
import {getImageUrl} from "../../utils"
import Translate from "../translate"

const OpenWebmailButtons: React.FC<{withoutDescription?: boolean}> = ({
  withoutDescription
}) => (
  <WebmailButtonsOuterContainer>
    <WebmailButtonsContainer>
      <WebmailButton
        target="_blank"
        href="https://mail.google.com/mail/u/0/#search/from%3A%40glickon.com+in%3Aanywhere"
      >
        <WebmailIcon src={getImageUrl("access/icn-gmail.svg")} />
        <Translate label="global-open-gmail" />
      </WebmailButton>

      <WebmailButton
        target="_blank"
        href="https://outlook.live.com/mail/0/inbox"
      >
        <WebmailIcon src={getImageUrl("access/icn-outlook.svg")} />
        <Translate label="global-open-outlook" />
      </WebmailButton>
    </WebmailButtonsContainer>

    {!withoutDescription && (
      <WebmailButtonsDescription>
        <Translate label="global-mail-not-found" />
      </WebmailButtonsDescription>
    )}
  </WebmailButtonsOuterContainer>
)

export default OpenWebmailButtons
