import humps from "humps"
import {AxiosError, AxiosResponse} from "axios"
import {getAxiosInstance} from "../utils"

export async function resetPasswordEmployee<T, R>(
  data: T,
  successFn: (res: AxiosResponse<R>) => void,
  errorFn: (err: AxiosError) => void
) {
  try {
    const values: any = humps.decamelizeKeys({...data, scope: "employees"})
    const payload = {
      email: values.email,
      next_url: values.next_url
    }
    const response: AxiosResponse<any> = await getAxiosInstance().post(
      `${process.env.REACT_APP_API_URL}/api/identity/v3/rpc/employee/mails/password-reset`,
      payload
    )
    successFn(humps.camelizeKeys(response) as AxiosResponse<R>)
  } catch (error) {
    errorFn((error as unknown) as AxiosError)
  }
}

export async function loginEmployee<T, R>(
  data: T,
  successFn: (res: AxiosResponse<R>) => void,
  errorFn: (err: AxiosError) => void
) {
  try {
    const values: any = humps.decamelizeKeys({...data, scope: "employees"})
    const payload = {
      username: values.email,
      password: values.password,
    }
    const response: AxiosResponse<any> = await getAxiosInstance().post(
      `${process.env.REACT_APP_API_URL}/api/identity/v3/employee/session?careers_name=${values.owner}`,
      payload
    )
    localStorage.setItem("tenant", "employee")
    successFn(humps.camelizeKeys(response) as AxiosResponse<R>)
  } catch (error) {
    errorFn((error as unknown) as AxiosError)
  }
}

export async function resendEmailEmployee<T, R>(
  data: T,
  successFn: (res: AxiosResponse<R>) => void,
  errorFn: (err: AxiosError) => void
) {
  try {
    const values: any = humps.decamelizeKeys({...data, scope: "employees"})
    const payload = {
      email: values.email
    }
    const response: AxiosResponse<any> = await getAxiosInstance().post(
      `${process.env.REACT_APP_API_URL}/api/identity/v3/rpc/employee/mails/verify-email`,
      payload
    )
    successFn(humps.camelizeKeys(response) as AxiosResponse<R>)
  } catch (error) {
    errorFn((error as unknown) as AxiosError)
  }
}

export async function checkRegisterEmailEmployee<T, R>(
  data: T,
  successFn: (res: AxiosResponse<R>) => void,
  errorFn: (err: AxiosError) => void
) {
  try {
    const response: AxiosResponse<any> = await getAxiosInstance().post(
      `${process.env.REACT_APP_API_URL}/api/candidate/latest/check_employee_signup_email`,
      data
    )
    successFn(humps.camelizeKeys(response) as AxiosResponse<R>)
  } catch (error) {
    errorFn((error as unknown) as AxiosError)
  }
}

export async function registerEmployee<T, R>(
  data: T,
  successFn: (res: AxiosResponse<R>) => void,
  errorFn: (err: AxiosError) => void
) {
  try {
    const values: any = humps.decamelizeKeys({...data, scope: "employees"})
    const payload = {
      given_name: values.first_name,
      family_name: values.last_name,
      password: values.password,
      email: values.email,
      user_metadata: {
        t_c_accepted_time: values.has_accepted_terms_and_conditions
          ? new Date().toISOString()
          : false
      }
    }
    const response: AxiosResponse<any> = await getAxiosInstance().post(
      `${process.env.REACT_APP_API_URL}/api/identity/v3/employee/user?careers_name=${values.owner}`,
      payload
    )
    localStorage.setItem("tenant", "employee")
    successFn(humps.camelizeKeys(response) as AxiosResponse<R>)
  } catch (error) {
    errorFn((error as unknown) as AxiosError)
  }
}
