import React from "react"
import useTranslation from "../../hooks/useTranslation"
import NegativeFeedback from "../negative-feedback"
import Link from "../link"
import SimpleAction from "../action"
import {ChangeForm} from "../access-form/styles"
import Translate from "../translate"
import {
  candidatePlatformKey,
  employeePlatformKey,
  candidatePlatformCompanyKey,
  employeePlatformCompanyKey
} from "../../constants/pathKeys"
import {AccessUserType} from "../../types/accessUser"
import {RequestFunction} from "../../types/requestFunction"
import {AxiosError} from "axios"
import {getErrorText} from "../../utils"
import OpenWebmailButtons from "../open-webmail-buttons"

const TIME_BEFORE_SHOW_RESEND_BUTTON = 5000

interface PropsType {
  email: string
  userType: AccessUserType
  companyName?: string // Comes from routing (indicating a whitelabel/private page)
  resendEmailFunction: RequestFunction
}

const getChangeFormLinkKey = (
  userType: AccessUserType,
  companyName?: string
): string => {
  const candidateKey = companyName
    ? candidatePlatformCompanyKey
    : candidatePlatformKey
  const employeeKey = companyName
    ? employeePlatformCompanyKey
    : employeePlatformKey

  if (userType === AccessUserType.Candidate) return candidateKey
  if (userType === AccessUserType.Employee) return employeeKey

  throw new Error(
    `Access Form - Cannot find next form link key with userType: ${userType}`
  )
}

const AccessRegisterDone: React.FC<PropsType> = ({
  email: usedEmail,
  userType,
  companyName,
  resendEmailFunction
}: PropsType) => {
  const [showResend, setShowResend] = React.useState<boolean>(false)
  const [translate] = useTranslation()
  const [errorText, setErrorText] = React.useState<string>("")
  const ref = React.useRef<HTMLDivElement>(null)

  const resend = async() => {
    // reset error on every submit
    setErrorText("")

    resendEmailFunction<{email: string; owner?: string}, any>(
      companyName ? {email: usedEmail, owner: companyName} : {email: usedEmail},
      () => {
        if (ref.current) {
          setShowResend(false)
        }
      },
      (e: AxiosError) => {
        const errorText = getErrorText(e)
        setErrorText(errorText || translate("global-generic-error"))
      }
    )
  }

  React.useEffect(() => {
    const timeoutID: number = window.setTimeout(() => {
      if (ref.current) {
        setShowResend(true)
      }
    }, TIME_BEFORE_SHOW_RESEND_BUTTON)
    return () => clearTimeout(timeoutID)
  }, [])

  return (
    <div ref={ref}>
      {errorText && <NegativeFeedback>{errorText}</NegativeFeedback>}
      <Link
        to={getChangeFormLinkKey(userType, companyName)}
        params={[{key: "companyName", value: companyName as string}]}
      >
        <button className="button-unstyled full-width">
          <SimpleAction
            fullWidth
            size={SimpleAction.sizes.large}
            variant={
              userType === AccessUserType.Candidate
                ? SimpleAction.variants.blueGlickonNoHover
                : userType === AccessUserType.Employee
                ? SimpleAction.variants.lightblueGlickon
                : SimpleAction.variants.dark
            }
          >
            <Translate label={`form-${userType}-back-to-login`} />
          </SimpleAction>
        </button>
      </Link>

      <OpenWebmailButtons />

      {showResend && (
        <ChangeForm>
          <Translate label={`form-${userType}-register-done-not-received`} />{" "}
          <span className="action" onClick={resend}>
            <Translate
              label={`form-${userType}-register-done-resend-description`}
            />
          </span>
        </ChangeForm>
      )}
    </div>
  )
}
export default AccessRegisterDone
