import styled from "styled-components"
import styleConstants from "../../constants/styleConstants"

// eslint-disable-next-line no-unexpected-multiline
export const CompanySectionsWrapper = styled.div<{
  extraPadding?: boolean
}>`
  padding-top: 2rem;
  padding-bottom: ${({extraPadding}) => (extraPadding ? "2rem" : 0)};
  margin-top: 0rem;
  width: 100%;
  min-height: 19rem;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    padding-bottom: ${({extraPadding}) => (extraPadding ? "2.5rem" : "1rem")};
    padding-top: 2.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
`

// eslint-disable-next-line no-unexpected-multiline
export const CompanyChallengesWrapper = styled.div<{
  extraPadding: boolean | undefined
  customColor: string | undefined
}>`
  padding-top: 1rem;
  padding-bottom: 2rem;
  padding-top: ${({extraPadding}) => (extraPadding ? "2.75rem" : 0)};
  background-color: ${({customColor}) => customColor || styleConstants.blue02};

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    padding-bottom: 4rem;
    padding-top: ${({extraPadding}) => (extraPadding ? "2rem" : 0)};
    padding-left: 1rem;
    padding-right: 1rem;
  }
`

// eslint-disable-next-line no-unexpected-multiline
export const CompanyInfoWrapper = styled.div<{
  extraPadding: boolean | undefined
  customColor: string | undefined
}>`
  background-color: ${({customColor}) => customColor || styleConstants.dark04}
  padding-top: ${({extraPadding}) => (extraPadding ? "11rem" : 0)};
  padding-bottom: 3rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: ${({extraPadding}) => (extraPadding ? "7rem" : 0)};
  }
`
