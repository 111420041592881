import { TranslationType } from "../types"

const translations: TranslationType = {
  // header-product
  "people-analytics-top-title": {
    en: "People Analytics",
    it: "People Analytics"
  },
  "people-analytics-top-description": {
    en: `
            Your people are your biggest competitive advantage. With People Analytics you can collect the information 
            you have about them, called big data, and use them to make useful decisions
        `,
    it: `
            Le persone sono il tuo più grande vantaggio competitivo.
            Con la People Analytics raccogli le informazioni che hai su
            di loro, chiamate big data, e le usi per prendere decisioni
            utili
        `
  },
  "people-analytics-header-paragraph-1-title": {
    en: "Organizational Network Analysis",
    it: "Organizational Network Analysis"
  },
  "people-analytics-header-paragraph-1-text": {
    en: "Analyses informal and spontaneous communication from which skills and ideas arise which in turn allows HR teams to take decisions and outline strategies to support growth.",
    it: "Analizza le connessioni informali e spontanee da cui nascono idee, competenze e decisioni in azienda, permettendo al team HR di delineare strategie a supporto della crescita"
  },
  "people-analytics-header-paragraph-2-title": {
    en: "Sentiment Analysis",
    it: "Sentiment Analysis"
  },
  "people-analytics-header-paragraph-2-text": {
    en: "Track and monitor the climate in different clusters within the company by mapping sentiment through natural language processing algorithms - All anonymized and respectful of privacy.",
    it: "Mantieni monitorato il clima in azienda o nei diversi cluster mappando il sentiment delle comunicazioni con algoritmi di Natural Language Processing. Il tutto anonimizzato e rispettoso della privacy"
  },
  "people-analytics-header-paragraph-3-title": {
    en: "High Potential Employees",
    it: "High Potential Employees"
  },
  "people-analytics-header-paragraph-3-text": {
    en: "Get a clear idea of high impact employee metrics - those with a larger network, with greater ability to influence colleagues and foster cross team collaboration.",
    it: "Ottieni una fotografia delle principali metriche riferite ai dipendenti ad alto impatto: quelli con un network più ampio, con la maggiore capacità di influenzare i colleghi e di favorire la collaborazione cross-team"
  },

  // subtitle-module-1
  "people-analytics-1st-subtitle-title": {
    en: "how it works",
    it: "come funziona"
  },
  "people-analytics-1st-subtitle-description": {
    en: `
            GlickonX is a People Analytics software that analyzes a company’s data such as those coming from 
            communication and internal collaboration tools (email, calendar, chat...). It completes missing information 
            and simulates scenarios using predictive algorithms. It broadens the perspective by confirming or 
            modifying action plans and strategic choices that make a difference to the company’s success.
        `,
    it: `
            GlickonX è un software di People Analytics che analizza i
            dati aziendali come quelli provenienti da strumenti di
            comunicazione e collaborazione interna (email, calendario,
            chat...). Completa le informazioni mancanti e simula scenari
            con l’utilizzo di algoritmi predittivi. Allarga la prospettiva
            confermando o modificando piani d’azione e scelte
            strategiche che fanno la differenza per il successo
            dell’azienda.
        `
  },

  // widget-3-columns
  "people-analytics-widget-3-columns-1st-title": {
    en: "Connect your tools",
    it: "Connetti i tuoi strumenti"
  },
  "people-analytics-widget-3-columns-1st-description": {
    en: `
            Connect your collaboration 
            and communication tools via APIs or export
            CSV files from administration panels.
        `,
    it: `
            Collega i tuoi tool di collaborazione
            e comunicazione via APIs o esporta
            i file CSV dai pannelli di amministrazione.
        `
  },
  "people-analytics-widget-3-columns-2nd-title": {
    en: "Analyze the data",
    it: "Analizza i dati"
  },
  "people-analytics-widget-3-columns-2nd-description": {
    en: `
            Glickon’s algorithms analyze data in real time
            and offer metrics, information and insights
            to understand your organization.
        `,
    it: `
            Gli algoritmi di Glickon analizzano in tempo reale
            i dati e offrono metriche, informazioni e insight
            per comprendere la tua organizzazione.
        `
  },
  "people-analytics-widget-3-columns-3rd-title": {
    en: "Make decisions",
    it: "Prendi decisioni"
  },
  "people-analytics-widget-3-columns-3rd-description": {
    en: `
            Access information about your organization,
            simulate the impact of your strategies, understand
            and finalize any decision concerning your people.
        `,
    it: `
            Accedi alle informazioni sulla tua organizzazione,
            simula l’impatto delle tue strategie, comprendi
            e finalizza qualsiasi decisione relativa alle persone.
        `
  },

  // full-width-img-text
  "people-analytics-1st-horizontal-title": {
    en: "Organizational Network Analysis",
    it: "Organizational Network Analysis"
  },
  "people-analytics-1st-horizontal-description": {
    en: `
          Ideas, knowledge and skills, a value that emerges from the formal and informal relationships built by people (often spontaneously).
          GlickonX allows to analyze these connections and carry out an Organizational Network Analysis, giving HR teams the possibility to outline strategies to support growth. 
        `,
    it: `
          Idee, conoscenze e competenze, un valore che emerge dalle relazioni formali e informali (spesso spontanee) costruite dalle persone. 
          GlickonX permette di analizzare queste connessioni e svolgere una Organizational Network Analysis, dando ai team HR la possibilità di delineare strategie a supporto della crescita. 
        `
  },
  "people-analytics-2nd-horizontal-title": {
    en: "Sentiment Analysis",
    it: "Sentiment Analysis"
  },
  "people-analytics-2nd-horizontal-description": {
    en: `
          Text messages exchanged within the collaboration and communication tools say something about your organization. Together with the HR teams, Glickon chooses the terminology to analyze based on the company's vocabulary, and Natural Language Processing algorithms attribute a polarity that corresponds to three values on the scale of perceived emotions: Positive, Neutral or Negative.
          <br/><br/>
          A sentiment analysis that also translates into a tension analysis: expressions of anger or denoting a misalignment with respect to the corporate culture can point to the risk of abandonment by employees of a team or episodes that would put the cohesion within company at risk.
        `,
    it: `
          I messaggi di testo scambiati all’interno dei tool di collaborazione e comunicazione parlano della tua organizzazione.
          Glickon sceglie insieme ai team HR la terminologia da analizzare sulla base di un vocabolario aziendale ed algoritmi di Natural Language Processing attribuiscono una polarità che corrisponde a tre valori sulla scala di emozioni percepite: Positivo, Neutro e Negativo.
          <br/><br/>
          Un’analisi del sentiment che si traduce anche in analisi della tensione: espressioni di rabbia o che denotano un disallineamento rispetto alla cultura aziendale possono evidenziare il rischio di abbandono da parte di dipendenti di un team o episodi che mettono a rischio la coesione in azienda.
        `
  },
  "people-analytics-3rd-horizontal-title": {
    en: "High Potential Employees",
    it: "High Potential Employees"
  },
  "people-analytics-3rd-horizontal-description": {
    en: `
          GlickonX is able to get a clear picture of the main metrics related to high-potential employees. Profiles that are easier to identify because they have a greater number of relationships with senior colleagues, cross-functional connections and a faster and more effective communication style.
          <br/><br/>
          <ul>
          <li>Network immersion and strenght</li>
          <li>Network reach</li>
          <li>Ability to influence</li>
          <li>Collaboration</li>
          </ul>    
        `,
    it: `
          Glickon è in grado di fotografare le principali metriche riferite ai dipendenti ad alto potenziale.
          Profili più facili da individuare perché hanno un numero maggiore di relazioni con colleghi senior, connessioni cross-funzionali e uno stile comunicativo più veloce ed efficace. 
          <ul>
          <li>Network immersion and strenght</li>
          <li>Network reach</li>
          <li>Ability to influence</li>
          <li>Collaboration</li>
          </ul>
        `
  },
  "people-analytics-4th-horizontal-title": {
    en: "Retention",
    it: "Retention"
  },
  "people-analytics-4th-horizontal-description": {
    en: `
            <strong>Objective</strong><br>
            To identify people at risk of abandonment, to reduce turnover and increase retention. GlickonX’s analysis 
            creates a predictive score of attrition.
            <br/><br/> 
            <strong>Stress</strong><br>
            The analysis of communication and internal collaboration tools identifies people with stressful behaviors: 
            for instance, many email exchanges outside of office hours or frequent interruptions during work.
            <br/><br/>
            With GlickonX you can be aware of the negative patterns that highlight the level of employee satisfaction. 
            And integrate them with datasets and turnover’s statistics already collected by HR teams to maximize 
            the predictive input.
        `,
    it: `
            <strong>Obiettivo</strong><br>
            Individuare le persone a rischio abbandono,
            ridurre il turnover e aumentare la retention. L’analisi di
            GlickonX crea un punteggio predittivo di attrition.
            <br/><br/> 
            <strong>Stress</strong><br>
            L’analisi degli strumenti di comunicazione e
            collaborazione interna individua le persone con
            comportamenti carichi di stress: per esempio molti scambi
            di email fuori dall’orario d’ufficio o frequenti interruzioni nel
            lavoro.
            <br/><br/>
            Con GlickonX puoi conoscere i pattern negativi che fanno
            emergere il livello di employee satisfaction. E integrarli con
            dataset e statistiche sul turnover già raccolte dai team HR
            per massimizzare l’input predittivo.
        `
  },
  "people-analytics-5th-horizontal-title": {
    en: "Sentiment Analysis",
    it: "Sentiment Analysis"
  },
  "people-analytics-5th-horizontal-description": {
    en: `
            Text messages exchanged within the collaboration and communication tools say something about your
            organization. Together with the HR teams, Glickon chooses the terminology to analyze based on the company's
            vocabulary, and Natural Language Processing algorithms attribute a polarity that corresponds to three values 
            on the scale of perceived emotions:
            <br/><br/>
            <strong style="color: #ffffff">Positive</strong><br/>
            <strong style="color: #19a7d2">Neutral</strong><br/>
            <strong style="color: #27d7c9">Negative</strong><br/>
            <br/><br/>
            A sentiment analysis that also translates into a tension analysis: expressions of anger or denoting a
            misalignment with respect to the corporate culture can point to the risk of abandonment by employees of a
            team or episodes that would put the cohesion within company at risk. 
        `,
    it: `
            I messaggi di testo scambiati all’interno dei tool di
            collaborazione e comunicazione parlano della tua
            organizzazione. Glickon sceglie insieme ai team HR la
            terminologia da analizzare sulla base di un vocabolario
            aziendale ed algoritmi di Natural Language Processing
            attribuiscono una polarità che corrisponde a tre valori sulla
            scala di emozioni percepite:
            <br/><br/>
            <strong style="color: #ffffff">Positivo</strong><br/>
            <strong style="color: #19a7d2">Neutro</strong><br/>
            <strong style="color: #27d7c9">Negativo</strong><br/>
            <br/><br/>
            Un’analisi del sentiment che si traduce anche in analisi
            della tensione: espressioni di rabbia o che denotano un
            disallineamento rispetto alla cultura aziendale possono
            evidenziare il rischio di abbandono da parte di dipendenti di
            un team o episodi che mettono a rischio la coesione in
            azienda. 
        `
  },
  "people-analytics-6th-horizontal-title": {
    en: "Productivity",
    it: "Productivity"
  },
  "people-analytics-6th-horizontal-description": {
    en: `
            Rethinking and simplifying the workflow and the organization of work: once again the starting point is in 
            listening to the collaboration and communication tools.
            <br/><br/>
            <ul>
                <li>Time spent sending external emails?</li><br/>
                <li>Email sent to colleagues in the team or in other teams?</li><br/>
                <li>Attitude towards corporate collaboration tools?</li><br/>
                <li>Level of satisfaction for the current workflow?</li><br/>
                <li>Quick access to files and documents in order to perform a task?</li><br/>
                <li>How many meetings were held and how long did they last?</li><br/>
            </ul>
        `,
    it: `
            Ripensare e semplificare il flusso e l’organizzazione del
            lavoro parte ancora una volta da una fase di ascolto dei tool
            di collaborazione e comunicazione.
            <br/><br/>
            <ul>
                <li>Tempo dedicato alle email inviate all’esterno?</li><br/>
                <li>Email inviate ai colleghi del team o di altre divisioni?</li><br/>
                <li>Attitudine verso strumenti di collaborazione aziendale?</li><br/>
                <li>Livello di soddisfazione del workflow attuale?</li><br/>
                <li>Rapidità di accesso a file e documenti per svolgere un task?</li><br/>
                <li>Quanti meeting effettuati e di quale durata?</li><br/>
            </ul>
        `
  }, /*    "people-analytics-7th-horizontal-title": {        en: "",
        it: "Funzionalità GlickonX"
    },
    "people-analytics-7th-horizontal-description": {
        en: "",
        it: `
            GlickonX entra in gioco con quattro step di analisi integrata a supporto delle HR.
            <br/><br/>
            <ol>
                <li>People analytics challenge: fase di gamification.</li><br/>
                <li>Data acquisition: Slack, email, dropbox, meeting, quiz, etc.</li><br/>
                <li>Organizational Analysis survey.</li><br/>
                <li>Prospetto di analisi puntuale e predittiva organizzata su dashboard.</li><br/>
            </ol>
        `
    },*/

  // products
  "people-analytics-products-title": {
    en: "Key features",
    it: "Funzionalità principali"
  },
  "people-analytics-products-1st-name": {
    en: "Discover the potential<br/>of your employees",
    it: "Scopri il potenziale<br/>dei tuoi dipendenti"
  },
  "people-analytics-products-1st-description": {
    en: `
            Monitor everyday tools to learn about relationships within the network, communication fluenty,
            task execution times... With GlickonX you can understand which are the high potential resources.
        `,
    it: `
            Monitora gli strumenti di utilizzo quotidiano per conoscere
            le relazioni all’interno del network, l’agilità nella
            comunicazione,
            i tempi di esecuzione di un task... Con GlickonX puoi capire
            quali sono le risorse ad alto potenziale.
        `
  },
  "people-analytics-products-2nd-name": {
    en: "Reduce the risk<br/>of turnover",
    it: "Riduci il rischio<br/>turnover"
  },
  "people-analytics-products-2nd-description": {
    en: `
            From the analysis of data, identify the behaviors of those employees with higher burnout risk. GlickonX 
            assigns a predictive score giving the HR teams the chance to intervene in time to avoid cases of abandonment.
        `,
    it: `
            Dall’analisi dei dati, individua i comportamenti dei dipendenti
            più a rischio burnout. GlickonX assegna un punteggio
            predittivo dando la possibilità ai team HR di intervenire in
            tempo per evitare casi di abbandono.
        `
  },
  "people-analytics-products-3rd-name": {
    en: "Explore and track<br/>D&I values",
    it: "Esplora e traccia<br/>i valori di D&I"
  },
  "people-analytics-products-3rd-description": {
    en: `
            A satisfactory Employee Experience is also given by a correct balance of diversity and inclusion within the 
            workforce. You can calculate it, obtaining positive impacts also for future selection phases.
        `,
    it: `
            Una Employee Experience soddisfacente è data anche da
            un corretto equilibrio di diversità e inclusione nella forza
            lavoro. Puoi calcolarla, ottenendo impatti positivi anche per
            le future fasi di selezione.
        `
  },

  // bottom-full-width
  "people-analytics-bottom-title": {
    en: "People, data and analysis. From HR to People Officer",
    it: "Persone, dati e analisi. Da HR a People Officer"
  },
  "people-analytics-bottom-description": {
    en: `
            The work of HR will not be replaced by data and an automated acquisition methodology to analyze them in a
            predictive manner. But a corporate data-driven culture makes all processes faster and easier: Candidate &
            Employee Experience, Talent Acquisition, Onboarding, Learning & Development. A flow that today goes from HR
            Analytics to People Analytics, from the evaluation of data acquired over time to those generated by people
            in real time. For a simple reason: listening, monitoring, analyzing responses thanks to GlickonX’s
            technology helps to make quick decisions and limits the margins of error with a sure ROI on productivity and
            business.
        `,
    it: `
            I dati e una metodologia di acquisizione automatizzata per
            analizzarli in maniera predittiva non sostituiranno il lavoro
            delle HR. Ma una cultura data-driven aziendale rende più
            facile e veloce tutti i processi: Candidate & Employee
            Experience, Talent Acquisition, Onboarding, Learning &
            Development. Un flusso che oggi passa da HR Analytics a
            People Analytics, dalla valutazione di dati acquisiti nel
            tempo a quelli generati dalle persone in real time. Per un
            motivo semplice: ascoltare, monitorare, analizzare risposte
            grazie alla tecnologia GlickonX aiuta a prendere decisioni
            rapide e limita i margini di errore con un ROI certo su
            produttività e business.
        `
  },

  // banner-bottom
  "people-analytics-banner-bottom-title": {
    en: "How much are your people worth? Find it out.",
    it: "Quanto valgono le tue persone? Scoprilo."
  },
  "people-analytics-banner-bottom-description": {
    en:
      "Data and analysis are enough to measure the current and future impact of your business. This is People Analytics. This is GlickonX",
    it:
      "Bastano dati e analisi per misurare l'impatto attuale e futuro del tuo business. Questa è People Analytics. Questo è GlickonX"
  }
}

export default translations
