import styled from "styled-components"
import styleConstants from "../../constants/styleConstants"
import { AccessUserType } from "../../types/accessUser"

const getAccessBackground = (
  backgroundUrl?: string
): string => {
  return !backgroundUrl ? "linear-gradient(to right, #15b3f3, #5b47c7 32%, #d54286 67%, #fa6345 100%)" : `url('${backgroundUrl}')`
}

// eslint-disable-next-line no-unexpected-multiline
export const Container = styled.div<{
  userType: AccessUserType
  backgroundUrl?: string
}>`
  position: relative;
  min-height: 100vh; /*fallback*/
  min-height: calc((var(--realVh, 1vh) * 100));
  padding: 0.7rem 0 3rem 0;

  &::before {
    z-index: -1;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${styleConstants.white};

    @media (min-width: ${styleConstants.desktopMinWidth}) {
      right: 50%;
    }
  }

  &::after {
    display: none;
    z-index: -1;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background: ${props => getAccessBackground(props.backgroundUrl)};

    @media (min-width: ${styleConstants.desktopMinWidth}) {
      display: block;
      left: 50%;
    }
  }
`

export const Title = styled.div`
  font-family: ${styleConstants.galanoGrotesqueMedium};
  font-size: ${styleConstants.font.size26};
  color: ${styleConstants.blue00};
  padding-bottom: 0.3rem;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    font-size: ${styleConstants.font.size32};
  }
`

export const Description = styled.div`
  font-family: ${styleConstants.calibre};
  font-size: ${styleConstants.font.size18};
  color: ${styleConstants.dark01};
  margin-bottom: 1.5rem;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    margin-bottom: 2rem;
    font-size: ${styleConstants.font.size20};
  }
`

export const ActionButton = styled.button`
  margin-top: 0.5rem;
`
