import React, {useState} from "react"
import IntlTelInput, {CountryData} from "react-intl-tel-input"
import {Field} from "formik"
import useTranslation from "../../hooks/useTranslation"
import * as S from "./styles"

type PropsType = {
  name: string
}

const FormikMobilePhoneInput: React.FC<PropsType> = (props: PropsType) => {
  const [translate] = useTranslation()

  const {name} = props

  const [telephoneValid, setTelephoneValid] = useState(true)
  //@ts-ignore
  const setValidity = valid => {
    setTelephoneValid(valid)
  }
  // process number into string with area code for submission
  //@ts-ignore
  const processNumber = (isValid, phone, country) => {
    return `+${country.dialCode} ${phone}`
  }

  return (
    <S.Container>
      <Field name={name}>
        {({
          field,
          form: {isSubmitting, setFieldTouched, setFieldValue}
        }: any) => (
          //@ts-ignore
          <IntlTelInput
            containerClassName="intl-tel-input"
            defaultValue={field.value}
            disabled={isSubmitting}
            fieldId={name}
            fieldName={name}
            defaultCountry={"it"}
            preferredCountries={["it", "gb", "kr", "cn"]}
            onPhoneNumberBlur={isValid => {
              setFieldTouched(name, true)
              setValidity(isValid)
            }}
            onPhoneNumberChange={(isValid, phone, country) => {
              setFieldValue(name, processNumber(isValid, phone, country))
            }}
          />
        )}
      </Field>
    </S.Container>
  )
}

export default FormikMobilePhoneInput
