import * as React from "react"
import { Card, Description, Title, Wrapper } from "./styles"
import Video from "../video"
import { Media } from "../../types/media"

type PropsType = {
  id: string
  videos: Media[]
}

function VideosList(props: PropsType) {
  const { videos, id } = props
  return (
    <Wrapper className="column desktop-12 mobile-12" id={id}>
      {videos.map((media: Media, index: number) => {
        const { title, description, url, id, isVideoTracked = false } = media
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Card key={`${id}-video-${index}`}>
            <div className="row">
              <div className="column mobile-12 desktop-6">
                <Video
                  id={`${id}-video-${index}`}
                  className={`${id}-video`}
                  src={url}
                  isTracked={!!isVideoTracked}
                />
              </div>
              <div className="column mobile-12 desktop-6">
                <Title>{title}</Title>
                <Description
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </div>
            </div>
          </Card>
        )
      })}
    </Wrapper>
  )
}

export default VideosList
