import React from "react"
import {RouteComponentProps, withRouter} from "react-router"
import {TwoFactorAuthenticationScopesSMSType} from "../../../types/twoFactorAuthentication"
import {SMSWrapper, EmailWrapper} from "../components/wrapper"
import {getImageUrl} from "../../../utils"

interface ParamsTypeSMS {
  userHash: string
  phoneNumberEndingDigits?: string
}

interface ParamsTypeEmail {
  userHash: string
  maskedEmail?: string
}

type PropsTypeSMS = RouteComponentProps<ParamsTypeSMS> & {
  scope: TwoFactorAuthenticationScopesSMSType
}

type PropsTypeEmail = RouteComponentProps<ParamsTypeEmail>

const TwoFactorAuthenticationSMS: React.FC<PropsTypeSMS> = ({
  match: {
    params: {userHash, phoneNumberEndingDigits}
  },
  scope
}: PropsTypeSMS) => (
  <SMSWrapper
    backgroundUrl={getImageUrl("access/banner-corporate.png")}
    userHash={userHash}
    phoneNumberEndingDigits={phoneNumberEndingDigits}
    scope={scope}/>
)

const TwoFactorAuthenticationEmail: React.FC<PropsTypeEmail> = ({
  match: {
    params: {userHash, maskedEmail}
  }
}: PropsTypeEmail) => (
  <EmailWrapper
    backgroundUrl={getImageUrl("access/banner-corporate.png")}
    userHash={userHash}
    maskedEmail={maskedEmail}/>
)

export const TwoFactorAuthenticationSMSWithRouter = withRouter<PropsTypeSMS, React.FC<PropsTypeSMS>>(TwoFactorAuthenticationSMS)
export const TwoFactorAuthenticationEmailWithRouter = withRouter<PropsTypeEmail, React.FC<PropsTypeEmail>>(TwoFactorAuthenticationEmail)
