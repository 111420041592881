import React from "react"
import { getCompanyInfo } from "../requests/companyInfo"
import { AxiosResponse, AxiosError } from "axios"
import { CompanyInfo } from "../types/companyInfo"

const useCompanyInfo = (
  scope: "candidates" | "employees",
  companyName?: string
): {
  companyInfo: CompanyInfo
  companyInfoRetrieved: boolean
  requiredCompanyInfoNotAvailable: boolean
} => {
  const [companyInfo, setCompanyInfo] = React.useState<CompanyInfo>(
    {} as CompanyInfo
  )
  const [isInfoRetrieved, setIsInfoRetrieved] = React.useState<boolean>(false)
  const [
    requiredCompanyInfoNotAvailable,
    setRequiredCompanyInfoNotAvailable
  ] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (!companyName) {
      setIsInfoRetrieved(true)
      // Please notice that here we don't set info not available,
      // because in this scenario we simply don't need the data
      return
    }

    getCompanyInfo(
      companyName,
      scope,
      (res: AxiosResponse<CompanyInfo>) => {
        setCompanyInfo(res.data)
        setIsInfoRetrieved(true)
      },
      (_: AxiosError) => {
        setRequiredCompanyInfoNotAvailable(true)
        setIsInfoRetrieved(true)
      }
    )
  }, [scope, companyName])

  return {
    companyInfo,
    companyInfoRetrieved: isInfoRetrieved,
    requiredCompanyInfoNotAvailable
  }
}

export default useCompanyInfo
