import styled from "styled-components"
import styleConstants from "../../../constants/styleConstants"

export const CompanyMissionAndValuesTitle = styled.div`
  font-family: ${styleConstants.galanoGrotesqueMedium};
  font-size: ${styleConstants.font.size18};
  color: ${styleConstants.blue00};
  padding-bottom: 1rem;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    font-size: ${styleConstants.font.size24};
  }
`

export const CompanyMissionAndValuesDescription = styled.div`
  font-family: ${styleConstants.calibre};
  font-size: ${styleConstants.font.size18};
  color: ${styleConstants.dark01};

  p {
    line-height: normal;
    margin-top: 0;
    font-size: ${styleConstants.font.size18}!important;
  }

  p span {
    line-height: normal;
    font-size: ${styleConstants.font.size18}!important;
  }
`

export const CompanyMissionAndValuesCard = styled.div`
  box-sizing: border-box;
  padding: 1.75rem 1.125rem;
  border-radius: ${styleConstants.defaultBorderRadius};
  border: 0.0625rem solid ${styleConstants.cardBorder};
  background-color: ${styleConstants.white};
  width: 100%;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    padding: 2rem 1.75rem;
  }
`
