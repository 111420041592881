import {TranslationType} from "../types"

const translations: TranslationType = {
  "tfa-invalid-phone-number": {
    en: "Invalid phone number",
    it: "Numero di telefono non valido"
  },
  "tfa-set-phone-number-title": {
    en: "Verify SMS",
    it: "Verifica SMS"
  },
  "tfa-set-phone-number-description": {
    en: "A further step is needed for your account security",
    it: "Abbiamo bisogno di un ulteriore passaggio per la sicurezza del tuo account"
  },
  "tfa-set-phone-number-input-placeholder": {
    en: "Phone number",
    it: "Numero di telefono"
  },
  "tfa-set-phone-number-form-action": {
    en: "SEND VERIFICATION CODE",
    it: "INVIA CODICE DI VERIFICA"
  },
  "tfa-insert-code-sms-title": {
    en: "Insert verification code to access",
    it: "Inserisci il codice di verifica per accedere"
  },
  "tfa-insert-code-sms-description-first-part": {
    en: "Check your phone the ends with",
    it: "Controlla il tuo numero di telefono che termina con"
  },
  "tfa-insert-code-sms-description-last-part": {
    en: "and wait for a verification message",
    it: "per un messaggio di verifica."
  },
  "tfa-insert-code-sms-input-placeholder": {
    en: "Code",
    it: "Codice"
  },
  "tfa-insert-code-sms-form-action": {
    en: "VERIFY",
    it: "VERIFICA"
  },
  "tfa-did-not-receive-sms": {
    en: "Did not receve any messages?",
    it: "Non hai ricevuto il messaggio?"
  },
  "tfa-insert-code-email-title": {
    en: "Insert verification code to access",
    it: "Inserisci il codice di verifica per accedere"
  },
  "tfa-insert-code-email-description-first-part": {
    en: "Check your email address ",
    it: "Controlla la tua casella di posta elettronica "
  },
  "tfa-insert-code-email-description-last-part": {
    en: "and wait for a verification message",
    it: "per un messaggio di verifica."
  },
  "tfa-insert-code-email-input-placeholder": {
    en: "Code",
    it: "Codice"
  },
  "tfa-insert-code-email-form-action": {
    en: "VERIFY",
    it: "VERIFICA"
  },
  "tfa-did-not-receive-email": {
    en: "Did not receve any messages?",
    it: "Non hai ricevuto il messaggio?"
  },
  "tfa-resend-code": {
    en: "Ask another code",
    it: "Richiedi codice"
  },
  "tfa-code-resent": {
    en: "Code has been resent",
    it: "Il codice è stato reinviato"
  },
  "tfa-duration-info": {
    en: "Code expire in 5 minutes",
    it: "Il codice scade tra 5 minuti"
  },
}

export default translations
