import styled from "styled-components"
import styleConstants from "../../../constants/styleConstants"

export const Container = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;

  .error-logo {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .error-wrapper {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;

    div {
      width: 50%;
      margin: auto;
      
      p:first-child {
        text-align: center;
        font-family: "Calibri", sans-serif;
        color: rgb(16, 24, 40);
        font-size: 48px;
      }

      p:nth-child(2) {
        text-align: center;
        font-family: "Calibri", sans-serif;
        color: rgb(102, 112, 133);
        font-size: 16px;
      }
    }
  }

  .error-footer {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    left: 0;
    background-color: #ffeddb;
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100vw;

    p {
      font-family: "Calibri", sans-serif;
      color: rgb(16, 24, 40);
    }
  }
`
