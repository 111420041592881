import {TranslationType} from "../types"

const translations: TranslationType = {
  "resources-employee-experience-header-title": {
    en: "Employee Experience,<br/>what is it?",
    it: "Employee Experience,<br/>che cos’è?"
  },
  "resources-employee-experience-header-description": {
    en: `
            Employee experience refers to any interaction, from the first contact as a potential resource 
            (the selection process) to the last action performed even after the end of the employment relationship 
            (termination of contract or retirement) between the company and its employee.
        `,
    it: `
            Per employee experience si intende qualsiasi interazione, dal primo contatto come potenziale risorsa 
            (selezione) fino all’ultima azione compiuta anche dopo la fine del rapporto di lavoro (licenziamento 
            o pensionamento) che un dipendente sperimenta all’interno di un’azienda.
        `
  },

  "resources-employee-experience-large-text-1": {
    en: "Satisfied employees? The data of the current scenario",
    it: "Dipendenti appagati? I dati dello scenario attuale"
  },
  "resources-employee-experience-medium-text-1": {
    en: `
            Today, 90% of companies (Gartner) focuses primarily on the customer experience. If that is the case, an 
            urgent paradigm shift is needed. Why? It is impossible to bring products, services and experiences to 
            market that meet customer expectations if a company is not able to satisfy those of its own employees.
            <br/><br/>
            Do you think you lie within the other illuminated 10% of companies? To answer that question you should turn 
            precisely to those who work for you every day, because the numbers (from a recent Gallup poll are) not 
            encouraging: 56% of respondents claim that they feel only relatively involved in corporate processes and 
            culture, while 73% claim that there is no engagement and confirmed they are looking t change jobs. 
            That means that not even one third of employees feel affected by the values of their company. 
            What’s more (and worse), other research shows that when a company gives the opportunity to its employees 
            share feedback on their experience, only in 30% of the cases does the company follow up with targeted 
            response actions to make improvements. Additionally, only 40% of the workforce is aware of ongoing 
            objectives and strategies. This highlights a lack in communication and in relations with management 
            that leads to another figure that cannot be ignored: 75% of employees who voluntarily leave their jobs 
            actually leave a leader who they do not recognize as a leader.
        `,
    it: `
            Oggi il 90% (fonte Gartner) delle aziende punta tutto sulla customer experience.
            Bene, allora quello che serve è un cambio di paradigma urgente. Perché? Non è
            possibile portare sul mercato prodotti, servizi ed esperienze che soddisfino le
            aspettative dei clienti se la tua azienda non è in grado di soddisfare quelle dei
            tuoi dipendenti.
            <br/><br/>
            Sei sicuro di rientrare nel restante 10% di imprese illuminate? Dovresti chiederlo
            proprio a chi lavora ogni giorno per te, perché i numeri di un recente sondaggio
            Gallup non sono incoraggianti: il 56% degli intervistati risponde di sentirsi
            relativamente coinvolto nei processi e nella cultura aziendale, mentre il 73 afferma
            addirittura che l’engagement è pari a zero e di essere alla ricerca di un nuovo
            lavoro. Ovvero, nemmeno un terzo si sente contaminato dai valori della propria
            azienda. Peggio ancora. Altre ricerche evidenziano che, quando viene data la
            possibilità di condividere un feedback sull’esperienza vissuta, solo nel 30% dei
            casi vengono prese decisioni mirate per migliorarla. E che soltanto il 40% della
            forza lavoro è a conoscenza di obiettivi e strategie in corso. Un gap nella
            comunicazione e nelle relazioni con il management che porta a un’altra percentuale
            difficile da ignorare: il 75% dei dipendenti che abbandona volontariamente il
            lavoro, in realtà lascia un capo che non riconosce come leader.
        `
  },
  "resources-employee-experience-quote-text-1": {
    en: `
            «The growth of the company in terms of speed and change management is closely related to how much
            organizational skills and specific skills are available to human resources».
        `,
    it: `
            «La crescita dell’impresa in termini di velocità e di gestione del cambiamento è
            strettamente correlata a quanto sono disponibili capacità organizzative e competenze
            specifiche nelle risorse umane».
        `
  },
  "resources-employee-experience-small-text-1": {
    en: `
            These are the words of the English economist Edith Penrose, from his 1959 book, Theory of the growth of the
            firm. More than 50 years later, Richard Branson, founder of the Virgin Group, said in an interview: 
            «Clients do not come first. Employees come first». Adding:
        `,
    it: `
            Sono le parole dell’economista inglese Edith Penrose, quelle che nel 1959 scriveva
            nel libro Theory of the growth of the firm. Più di 50 anni dopo Richard Branson,
            fondatore di Virgin Group, in un’intervista afferma: «Clients do not come first.
            Employees come first». Aggiungendo:
        `
  },
  "resources-employee-experience-quote-text-2": {
    en: `
            «If you take care of your employees, they will do the same with the clients. There is no magic formula to 
            create an experience and therefore a corporate culture that works, the key point is only treating your 
            staff as you would like to be treated».
        `,
    it: `
            «Se ti prendi cura dei tuoi dipendenti, loro faranno lo stesso con i clienti. Non
            c’è una formula magica per creare un’esperienza e quindi una cultura aziendale che
            funziona, il punto chiave sta solo nel trattare il tuo staff come tu stesso vorresti
            essere trattato».
        `
  },
  "resources-employee-experience-medium-text-2": {
    en: `
            It should be an obvious and evident truth. It was such before the third industrial revolution and is so 
            even more now that we have entered the years of industry 4.0, and thanks to technology and data analysis 
            even HR teams are able to create and offer a concrete employee experience.
        `,
    it: `
            Dovrebbe essere una verità scontata, evidente. Lo era prima della terza rivoluzione
            industriale, lo è ancora di più oggi che siamo entrati negli anni della industry 4.0
            e grazie alla tecnologia e all’analisi dei dati anche i team HR sono in grado di
            attivare una employee experience concreta.
        `
  },
  "resources-employee-experience-large-text-2": {
    en:
      "Enough with words, experience is made of action and training / actions and training make up the experience?",
    it: "Basta con le parole, l’esperienza è fatta di azione e formazione"
  },
  "resources-employee-experience-medium-text-3": {
    en: `
            You are dealing with a class of talented Millennials or Generation Z who have grown up in an experience 
            economy that by definition gives a lot of value to experience and the expectations that come with it. So, 
            you are right to include values such as transparency, respect, integrity, inclusion in your company's 
            manifesto... But, if on the one hand these are seen as minimum requirements in today’s market, 
            on the other hand you will have employees ready to tell you: «Ok, well then show us these values».
            <br/><br/>
            94% of employees claim that companies should invest in training to improve employee experience 
            (LinkedIn Wokforce Learning Report 2019). The request comes precisely from the younger generations, 
            who also state (25% or them) that the absence of adequate training tools represent the main cause for 
            leaving the workplace, a figure that increases to 33% when looking at new employees leaving their jobs 
            after just after just 6 months.
        `,
    it: `
            Hai a che fare con una classe di talenti Millennial o della Generazione Z, cresciuta
            in una experience economy che per definizione dà molto valore all’esperienza e alle
            aspettative che ne conseguono. Quindi, fai bene a inserire nel manifesto della tua
            azienda valori come trasparenza, rispetto, integrità, inclusione… Ma, se da una
            parte per chi oggi si confronta in qualsiasi mercato sono un minimo comune
            denominatore, dall’altra avrai dei dipendenti pronti a dirti: «Ok, allora dimostraci
            questi valori».
            <br/><br/>
            Il 94% dei dipendenti sostiene infatti che, per migliorare la employee experience,
            le aziende dovrebbero investire in formazione (LinkedIn Wokforce Learning Report
            2019). La richiesta arriva proprio dalle generazioni più giovani pronte ad affermare
            anche (25%) che l’assenza di strumenti formativi adeguati rappresenterebbe la causa
            principale di abbandono del posto di lavoro. Punti percentuali che salgono a 33
            quando a lasciare l’impiego sono i neoassunti dopo appena sei mesi.
        `
  },
  "resources-employee-experience-large-text-3": {
    en: "Whath are the most important EX touchpoints",
    it: "Quali sono i più importanti touchpoint di EX"
  },
  "resources-employee-experience-medium-text-4": {
    en: `
            The goal is to match a brilliant frontend (the experiences) with an efficient backend made up of processes 
            and data. Engagement and gamification are at the basis of Glickon’s technology and lead companies to 
            outline and implement four fundamental employee experience touchpoints:
            <br/><br/>
            <ul>
                <li>
                    Identify the most important moments of the employee‘s journey, meaning the journey and the 
                    experience that accompany the employee throughout the work life cycle within the organization.
                    <br/><br/>
                </li>
                <li>
                    Build a data-driven company using Glickon tools both to sell training and to acquire 
                    useful information.
                    <br/><br/>
                </li>
                <li>
                    Analyze the data obtained in the various experiential paths (onboarding, corporate training, 
                    internal communication, etc.) and evaluate them on a platform that helps HR teams develop 
                    new strategies.
                    <br/><br/>
                </li>
                <li>
                    Make decisions that make the work environment engaging and productive, in line with the 
                    company's mission and employee expectations.
                    <br/><br/>
                </li>
            </ul>
        `,
    it: `
            L’obiettivo è far corrispondere un brillante frontend (le esperienze) con un backend
            efficiente fatto di processi e dati. Engagement e gamification sono alla base della
            tecnologia Glickon e portano le aziende a delineare e implementare quattro
            touchpoint fondamentali di employee experience:
            <br/><br/>
            <ul>
                <li>
                    Identificare i momenti più importanti della employee journey, il viaggio e
                    l’esperienza che accompagnano il dipendente in tutto il ciclo di vita
                    lavorativa all’interno dell’organizzazione.
                    <br/><br/>
                </li>
                <li>
                    Analizzare i dati ottenuti nei vari percorsi esperienziali (onboarding,
                    formazione corporate, comunicazione interna, etc.) e valutarli su una
                    piattaforma che aiuta i team HR a sviluppare nuove strategie.
                    <br/><br/>
                </li>
                <li>
                    Prendere decisioni capaci di rendere l’ambiente di lavoro partecipativo e
                    produttivo, in linea con la mission dell’azienda e con le aspettative dei
                    dipendenti.
                    <br/><br/>
                </li>
            </ul>
        `
  },
  "resources-employee-experience-large-text-4": {
    en: "What is the ROI that certifies a successful employee experience?",
    it: "Qual è il ROI che certifica una employee experience di successo"
  },
  "resources-employee-experience-medium-text-5": {
    en: `
            Investing in intellectual capital means creating a circular experience model that will produce new 
            excellent performance cycles. The key points to understand the return on this investment are two and they 
            are closely linked:
            <br/><br/>
            <strong>Employee retention. </strong>
            Employees who have a very satisfying experience are employees who choose not to leave their jobs. Therefore, 
            ROI has a significant impact on HR budgets. The Bureau of National Affairs (BNA) quantifies the cost of 
            turnover to be around $11 billion each year. Replacing a new resource has an average incidence of 6 to 9 
            months of salary: 16% (on an annual basis) for high-turnover and low-paying jobs, 20% for mid-range 
            positions (from $30,000 $50,000) and up to 213% for management positions.
            <br/><br/>
            Why? There are a number of different factors; Deloitte highlights 6 in particular:
            <ul>
                <li>Advertising, interviews, screening and recruitment.</li>
                <li>Onboarding costs.</li>
                <li>
                    Loss of productivity. From one to two years the average of a new resource with full performance.
                </li>
                <li>
                    Loss of engagement and subsequent productivity of other employees worried by the high turnover.
                </li>
                <li>
                    Negative impact on customer service due to the inexperience of new employees.
                </li>
                <li>
                    Training costs. From 10 to 20% of salary over an average of three years.
                </li>
            </ul>
            <br/><br/>
            <strong>Financial returns. </strong>
            A Globalforce and IBM report highlights the correlations between an effective and positive Employee 
            Experience and the two standard performance metrics related to business turnover and productivity.
            <br/><br/>
            <span class="underline">Return on assets</span> (ROA).
            The ratio between net profit and activity, used to assess the profitability of a company with respect to 
            total assets. The higher the ROA, the more the organization earns investing less.
            <br/><br/>
            <span class="underline">Return on Sales</span> (ROS). Also known as operating profit margin, 
            is the financial relationship between operating income and sales. It is the profit efficiency of a 
            company with respect to its revenue; organizations with high ROS show higher levels of efficiency.
            <br/><br/>
            So the data speaks clearly in this report as well: organizations with a score of 25% higher on their 
            employee experience reported a return on assets (ROA) three times higher and more than double the 
            return on sales (ROS).
        `,
    it: `
            Investire sul capitale intellettuale significa creare un modello di circular
            experience che produrrà nuovi cicli di performance eccellenti. I punti chiave per
            comprendere il ritorno su questo investimento sono due e collegati strettamente tra
            loro:
            <br/><br/>
            <strong>Employee retention. </strong>
            Dipendenti che vivono un’esperienza molto soddisfacente sono dipendenti che scelgono
            di non abbandonare il posto di lavoro. Il ROI ha quindi un impatto significativo sui
            budget HR. Il Bureau of National Affair quantifica in 11 miliardi, ogni anno, il
            costo del turnover. Sostituire una nuova risorsa ha un’incidenza media dai 6 ai 9
            mesi di stipendio: il 16% (su base annuale) per lavori ad alto turnover e poco
            remunerativi, il 20 per le posizioni di fascia media (dai 30 ai 50mila dollari) e
            fino al 213% per le posizioni dirigenziali.
            <br/><br/>
            Perché? I fattori sono diversi, Deloitte ne evidenzia 6 in particolare:
            <ul>
                <li>Pubblicità, interviste, screening e assunzione.</li>
                <li>Costi di onboarding.</li>
                <li>
                    Perdita della produttività. Da uno ai due anni la media di una nuova risorsa
                    con performance a regime.
                </li>
                <li>
                    Perdita di engagement e relativa produttività di altri dipendenti
                    preoccupati da un alto turnover.
                </li>
                <li>
                    Impatto negativo sul customer service dovuto all’inesperienza dei nuovi
                    dipendenti.
                </li>
                <li>
                    Costi di formazione. Dal 10 al 20% dello stipendio nell’arco di una media di
                    tre anni.
                </li>
            </ul>
            <br/><br/>
            <strong>Rendimenti finanziari. </strong>
            Un rapporto di Globalforce e IBM mette in risalto le correlazioni tra una Employee
            Experience efficace e positiva e le due metriche standard di performance legate al
            fatturato e alla produttività aziendale.
            <br/><br/>
            <span class="underline">Rendimento delle attività</span> (ROA).
            Il rapporto tra utile netto e attività, utilizzato per valutare la redditività di
            un’azienda rispetto al totale delle attività. Più alto è il ROA, più
            l’organizzazione guadagna investendo di meno.
            <br/><br/>
            <span class="underline">Return on Sales</span> (ROS). Conosciuto anche come
            margine di profitto operativo, è il rapporto finanziario tra reddito operativo e
            vendite. Viene calcolata l’efficienza dei profitti di un’azienda rispetto alle sue
            entrate, le organizzazioni con ROS alto mostrano livelli più elevati di efficienza.
            <br/><br/>
            Anche in questo report i dati sono chiari: le organizzazioni con un punteggio del
            25% superiore sulla propria employee experience hanno riportato un rendimento sulle
            attività (ROA) di tre volte superiore e più del doppio sul ritorno delle vendite
            (ROS).
        `
  },

  // banner-bottom
  "resources-employee-experience-banner-bottom-title": {
    en: "Now it's your turn, design your Employee Experience!",
    it: "Ora tocca a te, disegna la tua Employee Experience!"
  },
  "resources-employee-experience-banner-bottom-description": {
    en:
      "We put in the design work and data science, but you are the architect drafting talent, ideas and business",
    it:
      "Noi ci mettiamo design e data science, tu sei l’architetto che progetta talento, idee e business"
  }
}

export default translations
