/* eslint-disable no-undef */
import useSWR from "swr"
import humps from "humps"
import { Company } from "../types/company"
import { useRef } from "react"
import { CompanyChallenge } from "../types/companyChallenge"
import { CompanySection } from "../types/companySection"
import { RedirectMetadata } from "../types/redirectMetadata"
import { getCookie } from "../utils"

/// We are using a nerfed version (by options) of SWR because it's easy
/// to achieve IE11 support (together with unfetch) and it's a great piece of software
/// TODO for the future: use retry on error?

// We added import "unfetch/polyfill" in index.tsx, so fetch is polyfilled if needed
async function fetcher<JSON = any>(
  input: RequestInfo,
  init: RequestInit = {},
  withCSRF = true
): Promise<JSON> {
  const headers = init.headers ? new Headers(init.headers) : new Headers()

  if (withCSRF) {
    headers.append("X-CSRFToken", getCookie("glickoncsrftoken") || "")
  }

  const processedInit = {
    ...init,
    credentials: "include",
    headers
  } as RequestInit

  const res = await fetch(input, processedInit)
  const body = await res.json()

  if (!res.ok) {
    throw new Error(JSON.stringify(body))
  }

  return body
}

function tryToParseError<T>(error?: any): T | string | undefined {
  if (!error) return undefined
  console.error("useRequest error: ", error)

  try {
    const errorData: any = JSON.parse(error.message)
    return humps.camelizeKeys(errorData) as unknown as T
  } catch (e) {
    return error.toString()
  }
}

export interface UseGetResponse<TData, TError> {
  data?: TData
  error?: TError | string
}

export function useGetRequest<TData, TError>(
  url: string
): UseGetResponse<TData, TError> {
  const { data: rawData, error: rawError } = useSWR<TData, string>(url, fetcher, {
    revalidateOnFocus: false
  })

  const error = tryToParseError<TError>(rawError) || rawError

  const ref = useRef({} as UseGetResponse<TData, TError>)

  if (!ref.current.data && rawData) {
    ref.current.data = humps.camelizeKeys(rawData as unknown as object) as unknown as TData
  }

  if (!ref.current.error && error) {
    ref.current.error = error
  }

  // Ref is needed to avoid in the same data resulting different
  // because of having an different object pointing to the same data
  // (referentially different but same data)
  return ref.current
}

export const useGetCompanyRequest = (
  careersName: string
): UseGetResponse<Company, RedirectMetadata> =>
  // from_www is used so that core api can understand if it needs
  // to eventually force a logout, since we should always try to logout
  // if visiting a company page from www and never if from core
  // (in the first case is coming from a user inserting the url,
  // in the second it should come from navigation inside the core app)
  useGetRequest(
    `${process.env.REACT_APP_API_URL}/api/candidate/latest/companies/${careersName}?from_www=true`
  )

export const useGetCompanyChallengeRequest = (
  hash: string
): UseGetResponse<CompanyChallenge, RedirectMetadata> =>
  useGetRequest(
    `${process.env.REACT_APP_API_URL}/api/candidate/latest/company_challenges/${hash}?from_www=true`
  )

export const useGetCompanySectionRequest = (
  id: number
): UseGetResponse<CompanySection, RedirectMetadata> =>
  useGetRequest(
    `${process.env.REACT_APP_API_URL}/api/candidate/latest/sections/${id}?from_www=true`
  )
