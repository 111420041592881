import humps from "humps"
import { AxiosError, AxiosResponse } from "axios"
import { getAxiosInstance } from "../utils"

export async function setPhoneNumber<T extends object, R>(
  data: T,
  successFn: (res: AxiosResponse<R>) => void,
  errorFn: (err: AxiosError) => void
) {
  try {
    const values: any = humps.decamelizeKeys(data)
    const response: AxiosResponse<any> = await getAxiosInstance().post(
      `${process.env.REACT_APP_API_URL}/api/corporate/latest/tfa/set_phone_number`,
      values
    )
    successFn(humps.camelizeKeys(response) as AxiosResponse<R>)
  } catch (error) {
    errorFn(error as unknown as AxiosError)
  }
}

export async function verifyCode<T extends object, R>(
  data: T,
  successFn: (res: AxiosResponse<R>) => void,
  errorFn: (err: AxiosError) => void
) {
  try {
    const values: any = humps.decamelizeKeys(data)
    const response: AxiosResponse<any> = await getAxiosInstance().post(
      `${process.env.REACT_APP_API_URL}/api/corporate/latest/tfa/verify_code`,
      values
    )
    successFn(humps.camelizeKeys(response) as AxiosResponse<R>)
  } catch (error) {
    errorFn(error as unknown as AxiosError)
  }
}

export async function resendCode<T extends object, R>(
  data: T,
  successFn: (res: AxiosResponse<R>) => void,
  errorFn: (err: AxiosError) => void
) {
  try {
    const values: any = humps.decamelizeKeys(data)
    const response: AxiosResponse<any> = await getAxiosInstance().post(
      `${process.env.REACT_APP_API_URL}/api/corporate/latest/tfa/resend_code`,
      values
    )
    successFn(humps.camelizeKeys(response) as AxiosResponse<R>)
  } catch (error) {
    errorFn(error as unknown as AxiosError)
  }
}
