import styled from "styled-components"
import styleConstants from "../../constants/styleConstants"
import SimpleImage from "../image"
import { hexToRgb } from "../../utils"

const SimpleList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

export const Container = styled.footer`
  background-color: ${styleConstants.blue00};
  color: ${styleConstants.white};
  position: relative;
`

export const Logo = styled(SimpleImage)`
  max-width: 100%;
  max-height: 2rem;
`

export const AutoColumn = styled.div`
  padding-bottom: 2rem;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    padding-bottom: 0;
    width: 20% !important;
  }
`

export const Panel = styled.div`
  @media (min-width: ${styleConstants.desktopMinWidth}) {
    width: 20% !important;
  }
`

export const Info = styled.div`
  line-height: 2rem;
  padding-top: 0.75rem;
  font-family: ${styleConstants.calibre};
  font-size: ${styleConstants.font.size20};
`

export const PanelHeader = styled.div`
  display: flex;
  flex-direction: row;
  font-family: ${styleConstants.galanoGrotesqueSemibold};
  font-size: ${styleConstants.font.size16};
  text-transform: uppercase;
  justify-content: space-between;
  margin-top: 1rem;

  &,
  a {
    color: ${styleConstants.white};
    text-decoration: none;
  }

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    margin-top: 0;
  }
`

interface PanelPropsType {
  selected: boolean
}

export const PanelToggle = styled.i<PanelPropsType>`
  display: block;
  transition: all 0.2s linear;
  transform: rotate(${props => (props.selected ? "45deg" : 0)});

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    display: none;
  }
`

export const PanelBody = styled.div<PanelPropsType>`
  overflow: hidden;
  max-height: ${props => (props.selected ? "12rem" : 0)};
  opacity: ${props => (props.selected ? 1 : 0)};
  transition: all 0.4s ease;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    max-height: none;
    opacity: 1;
    padding-top: 1.9rem;
  }
`

export const List = styled(SimpleList) <PanelPropsType>`
  transform: ${props =>
    props.selected ? "translate3d(0, 0, 0)" : "translate3d(0, -5rem, 0)"};
  padding-top: 1rem;
  transition: all 0.3s ease;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    padding-top: 0;
    transform: none;
  }
`

export const ListItem = styled.li`
  font-family: ${styleConstants.calibre};
  font-size: ${styleConstants.font.size18};
  opacity: 0.7;
  padding: 0.35rem 0;

  &,
  a {
    display: block;
    width: 100%;
    color: ${styleConstants.white};
    text-decoration: none;
  }

  &:hover {
    opacity: 1;
  }

  i {
    font-size: 0.8rem;
    margin-left: 0.5rem;
  }
`

interface SubListPropsType {
  opened: boolean
}

export const SubList = styled(List) <SubListPropsType>`
  list-style: disc;
  display: ${(props) => props.opened ? "block" : "none"};
`

export const SubListItem = styled(ListItem)`
  list-style-position: inside !important;
  display: list-item !important;
  padding-left: 0.25rem;
  a {
    display: inline;
  }
`

export const Bottom = styled.div`
  padding-top: 1rem;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    padding-top: 5rem;
  }
`

export const Copy = styled.div`
  font-family: ${styleConstants.calibre};
  font-size: ${styleConstants.font.size16};
  color: rgba(${hexToRgb(styleConstants.white)}, 0.5);
`

export const DropupMenu = styled.div`
  display: none;
  padding: 1rem 2rem 1rem 1rem;
  background-color: ${styleConstants.white};
  border-radius: ${styleConstants.defaultBorderRadius};
  position: absolute;
  left: -1rem;
  bottom: calc(100%);

  &::before {
    content: "";
    position: absolute;
    left: 1rem;
    bottom: -0.35rem;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0.4rem 0.4rem 0 0.4rem;
    border-bottom-color: transparent;
    border-right-color: transparent;
    border-left-color: transparent;
    border-top-color: ${styleConstants.white};
    z-index: 999999;
  }
`
