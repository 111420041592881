import React from "react"
import * as Yup from "yup"
import {Formik, FormikHelpers, FormikProps} from "formik"
import {ButtonMarginTop} from "./style"
import {TranslateFunctionType} from "../../types"
import useTranslation from "../../hooks/useTranslation"
import NegativeFeedback from "../negative-feedback"
import TextInput from "../input"
import SimpleAction from "../action"
import Translate from "../translate"
import {ChangeForm} from "../access-form/styles"
import {RequestFunction} from "../../types/requestFunction"
import {AccessUserType} from "../../types/accessUser"
import {AxiosError} from "axios"
import {getErrorText} from "../../utils"

const emailId = "email"

interface FormValuesType {
  [emailId]: number | string
}

const validationSchema = (
  translate: TranslateFunctionType
): Yup.AnySchema<Partial<FormValuesType>> =>
  Yup.object().shape({
    [emailId]: Yup.string()
      .trim()
      .email(translate("global-error-field-invalid-email"))
      .required(translate("global-error-field-mandatory"))
  })

const getEmailPlaceholder = (userType: AccessUserType): string =>
  userType === AccessUserType.Candidate
    ? "global-email-label"
    : `global-${userType}-email-label`

interface PropsType {
  userType: AccessUserType
  owner?: string
  showLoginForm: () => void
  showResendEmailDone: () => void
  initialValue: string
  setValue: (email: string) => void
  resendEmailFunction: RequestFunction
  customTextColor?: string
}

const AccessResendEmail: React.FC<PropsType> = ({
  userType,
  owner,
  showLoginForm,
  showResendEmailDone,
  initialValue,
  setValue,
  resendEmailFunction,
  customTextColor
}: PropsType) => {
  const [translate] = useTranslation()
  const [errorText, setErrorText] = React.useState<string>("")

  const initialValues: FormValuesType = {
    [emailId]: initialValue
  }

  const submitHandler = async (
    values: FormValuesType,
    {setSubmitting}: FormikHelpers<FormValuesType>
  ) => {
    // reset error on every submit
    setErrorText("")

    resendEmailFunction<FormValuesType | {owner?: string}, any>(
      owner ? {...values, owner} : values,
      () => {
        setSubmitting(false)
        showResendEmailDone()
      },
      (e: AxiosError) => {
        setSubmitting(false)

        const errorText = getErrorText(e)
        setErrorText(errorText || translate("global-generic-error"))
      }
    )
  }

  return (
    <Formik
      validationSchema={validationSchema(translate)}
      initialValues={initialValues}
      onSubmit={submitHandler}
    >
      {(formikProps: FormikProps<FormValuesType>) => {
        const {
          handleSubmit,
          handleChange,
          values,
          submitCount,
          isSubmitting,
          errors
        } = formikProps

        const hasAlreadySubmitted: boolean = submitCount > 0

        return (
          <form onSubmit={handleSubmit} noValidate>
            {errorText && <NegativeFeedback>{errorText}</NegativeFeedback>}

            <TextInput
              id={emailId}
              value={values[emailId]}
              type="email"
              error={hasAlreadySubmitted && errors[emailId]}
              placeholder={getEmailPlaceholder(userType)}
              onChange={e => {
                handleChange(e)
                setValue(e.target.value)
              }}
            />

            <ButtonMarginTop
              disabled={isSubmitting || !values[emailId]}
              className="button-unstyled full-width"
              type="submit"
            >
              <SimpleAction
                disabled={isSubmitting || !values[emailId]}
                fullWidth
                size={SimpleAction.sizes.large}
                variant={
                  userType === AccessUserType.Candidate
                    ? SimpleAction.variants.blueGlickonNoHover
                    : userType === AccessUserType.Employee
                    ? SimpleAction.variants.lightblueGlickon
                    : SimpleAction.variants.dark
                }
              >
                <Translate label={`form-${userType}-resend-email-action`} />
              </SimpleAction>
            </ButtonMarginTop>

            <ChangeForm customTextColor={customTextColor}>
              <Translate
                label={`form-${userType}-lost-password-back-to-login-1`}
              />{" "}
              <span className="action" onClick={showLoginForm}>
                <Translate
                  label={`form-${userType}-lost-password-back-to-login-2`}
                />
              </span>
            </ChangeForm>
          </form>
        )
      }}
    </Formik>
  )
}
export default AccessResendEmail
