import {TranslationType} from "../types"

const translations: TranslationType = {
    "msteams-tc-title": {
        en: "License Agreement",
        it: "Contratto di Licenza"
    },
    "msteams-tc-last-modified": {
        en: "",
        it: ""
    },
    "msteams-tc-pre-list-text": {
        en: "Your use of the Glickon for Microsoft Teams app and your access of the Glickon service are governed by and subject to the General Terms and Conditions for Glickon services agreed between the organization that is a Glickon customer and Glickon.",
        it: "L’utilizzo dell’app Glickon per Microsoft Teams e l’accesso al servizio Glickon sono regolati e soggetti ai Termini e Condizioni generali per i servizi Glickon sottoscritti tra l’organizzazione cliente di Glickon e Glickon."
    },
}

export default translations
