import React, { useState } from "react"
import {
  ExtendedPanel,
  FullWidthLink,
  Hamburgher,
  HamburgherLineBottom,
  HamburgherLineTop,
  Logo,
  Navigation,
  NavItem,
  SubNavItem,
  SubNavNavigation,
  TopBar,
  ProductsContainer,
  ProductsContainerToggle
} from "./styles"
import { FullHeight } from "../styles"
import Link from "../../link"
import {
  aboutUsKey,
  blogKey,
  candidateExperienceKey,
  caseStudiesKey,
  caseStudyKey,
  employeeExperienceKey,
  homepageKey,
  liveKey,
  peopleAnalyticsKey,
  productsKey,
  resourcesCandidateExperienceKey,
  resourcesEmployeeExperienceKey,
  resourcesKey,
  resourcesPeopleAnalyticsKey,
  loginKey,
  signupKey,
  requestADemoKey,
  jobKey,
  aDayWithPageKey,
  HRJourneyKey,
  employeeExperienceJourneyKey,
  candidateExperienceJourneyKey,
  mediumBlogKey
} from "../../../constants/pathKeys"
import SimpleAction from "../../action"
import Translate from "../../translate"

interface PropsType {
  pathKey: string
  isActive: (pathKeys: string[]) => boolean
  isLoginPage: boolean
  isSignupPage: boolean
  isRequestADemoPage: boolean
}

const Mobile: React.FC<PropsType> = (props: PropsType) => {
  const {
    isActive,
    pathKey,
    isLoginPage,
    isSignupPage,
    isRequestADemoPage
  } = props
  const [menuOpen, setMenuOpen] = React.useState<boolean>(false)
  const [isProductsContainerExpanded, setProductsContainerExpanded] = useState(
    false
  )

  const toggle = () => {
    setMenuOpen((open: boolean): boolean => !open)
  }

  const closeMenu = React.useCallback(() => {
    setMenuOpen(false)
  }, [])

  React.useEffect(() => {
    // prevent document scrolling while menu open
    const htmlTag: HTMLElement = document.documentElement
    if (menuOpen) {
      htmlTag.setAttribute("style", "overflow-y: hidden;")
    }
    return () => {
      htmlTag.setAttribute("style", "overflow-y: auto;")
    }
  }, [menuOpen])

  React.useEffect(() => {
    setMenuOpen(false)
  }, [pathKey])

  return (
    <>
      <TopBar className="row hide-for-desktop-only">
        <FullHeight className="column mobile-12">
          <FullHeight className="row between middle">
            <Link onClick={closeMenu} to={homepageKey}>
              <Logo localize={false} src="logos/logo-glickon-white.png" />
            </Link>
            <Hamburgher onClick={toggle}>
              <HamburgherLineTop rotation={menuOpen} />
              <HamburgherLineBottom rotation={menuOpen} />
            </Hamburgher>
          </FullHeight>
        </FullHeight>
      </TopBar>
      <ExtendedPanel className="hide-for-desktop-only" show={menuOpen}>
        <div className="row">
          <div className="column mobile-12">
            <Navigation>
              <ProductsContainer
                onClick={() =>
                  setProductsContainerExpanded(!isProductsContainerExpanded)
                }
              >
                <NavItem
                  isActive={isActive([
                    productsKey,
                    liveKey,
                    candidateExperienceKey,
                    employeeExperienceKey,
                    peopleAnalyticsKey
                  ])}
                >
                  <div>
                    <Translate label={"global-navigation-products"} />
                  </div>
                  <SubNavNavigation expanded={isProductsContainerExpanded}>
                    <SubNavItem>
                      <Link onClick={closeMenu} to={candidateExperienceKey}>
                        Candidate Experience
                      </Link>
                    </SubNavItem>
                    <SubNavItem>
                      <Link onClick={closeMenu} to={employeeExperienceKey}>
                        Employee Experience
                      </Link>
                    </SubNavItem>
                    <SubNavItem>
                      <Link onClick={closeMenu} to={peopleAnalyticsKey}>
                        People Analytics
                      </Link>
                    </SubNavItem>
                    <SubNavItem last>
                      <Link onClick={closeMenu} to={liveKey}>
                        Live
                      </Link>
                    </SubNavItem>
                  </SubNavNavigation>
                </NavItem>
                <ProductsContainerToggle
                  className="icon icon-add"
                  isExpanded={isProductsContainerExpanded}
                />
              </ProductsContainer>

              <NavItem
                isActive={isActive([
                  aDayWithPageKey,
                  HRJourneyKey,
                  candidateExperienceJourneyKey,
                  employeeExperienceJourneyKey
                ])}>
                <Link to={aDayWithPageKey}>
                  A day with
                </Link>
              </NavItem>
              <NavItem isActive={isActive([aboutUsKey])}>
                <Link onClick={closeMenu} to={aboutUsKey}>
                  <Translate label={"global-navigation-about"} />
                </Link>
              </NavItem>
              <NavItem isActive={isActive([caseStudiesKey, caseStudyKey])}>
                <Link onClick={closeMenu} to={caseStudiesKey}>
                  <Translate label={"global-navigation-case-study"} />
                </Link>
              </NavItem>

              <NavItem
                isActive={isActive([
                  resourcesKey,
                  resourcesPeopleAnalyticsKey,
                  resourcesCandidateExperienceKey,
                  resourcesEmployeeExperienceKey
                ])}
              >
                <Link onClick={closeMenu} to={resourcesKey}>
                  <Translate label={"global-navigation-resources"} />
                </Link>
              </NavItem>
              <NavItem>
                <Link onClick={closeMenu} to={blogKey}>
                  Blog
                </Link>
              </NavItem>
              <NavItem>
                <Link onClick={closeMenu} to={mediumBlogKey}>
                  <Translate label={"global-navigation-medium"} />
                </Link>
              </NavItem>
              <NavItem noBorder>
                <Link onClick={closeMenu} to={jobKey}>
                  <Translate label={"global-navigation-join-us"} />
                </Link>
              </NavItem>
            </Navigation>

            {!isLoginPage && (
              <FullWidthLink
                id="global-navigation-signin-mobile"
                onClick={closeMenu}
                to={loginKey}
              >
                <SimpleAction
                  size={SimpleAction.sizes.medium}
                  variant={SimpleAction.variants.ghostWhite}
                >
                  <Translate label={"global-navigation-signin"} />
                </SimpleAction>
              </FullWidthLink>
            )}

            {!isSignupPage && (
              <FullWidthLink
                id="global-navigation-get-started-mobile"
                onClick={closeMenu}
                to={signupKey}
              >
                <SimpleAction
                  size={SimpleAction.sizes.medium}
                  variant={SimpleAction.variants.white}
                >
                  <Translate label={"global-navigation-get-started"} />
                </SimpleAction>
              </FullWidthLink>
            )}

            {!isRequestADemoPage && (
              <FullWidthLink
                className="request-a-demo"
                onClick={closeMenu}
                to={requestADemoKey}
              >
                <SimpleAction
                  size={SimpleAction.sizes.medium}
                  variant={SimpleAction.variants.blueGlickon}
                >
                  <Translate label={"global-request-a-demo"} />
                </SimpleAction>
              </FullWidthLink>
            )}
          </div>
        </div>
      </ExtendedPanel>
    </>
  )
}

export default Mobile
