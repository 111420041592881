import React from "react"
import {getCandidateImageUrl, smoothScrollTo} from "../../../../utils"
import {
  CompanyInformationsCard as Card,
  CompanyInformationsContent as Content,
  CompanyInformationsLogo as Logo,
  CompanyInformationsName as Name,
  CompanyInformationsShowMoreButton as ShowMoreButton
} from "./style"
import SimpleAction from "../../../../commonComponents/action"
import Translate from "../../../../commonComponents/translate"

const GENERALI_CAREERS_NAME = "generali"

interface Props {
  companyName: string
  logoUrl: string
  customTitleColor: string | undefined
  customButtonColor: string | undefined
  customButtonHoverColor: string | undefined
  customButtonTextColor: string | undefined
  careersName: string | undefined
}

const CompanyInformations: React.FC<Props> = ({
  companyName,
  logoUrl,
  customTitleColor,
  customButtonColor,
  customButtonHoverColor,
  customButtonTextColor,
  careersName
}: Props) => (
  <Card>
    <Logo
      rounded
      src={
        logoUrl ||
        getCandidateImageUrl("placeholders/placeholder-icon-company.png")
      }
      localize={false}
    />
    <Content>
      <Name customColor={customTitleColor}>{companyName}</Name>
      {careersName === GENERALI_CAREERS_NAME ? null : <div>
        <ShowMoreButton
          className={`company-informations-show-more-button${
            customButtonColor ? " custom" : ""
          }`}
          size={SimpleAction.sizes.medium}
          variant={SimpleAction.variants.hoverableDark}
          customColor={customButtonColor}
          customHoverColor={customButtonHoverColor}
          customTextColor={customButtonTextColor}
          onClick={() => smoothScrollTo("discover-more")}
        >
          <Translate label={"global-discover-more"} />
        </ShowMoreButton>
      </div>}
    </Content>
  </Card>
)

export default CompanyInformations
