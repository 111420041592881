import styled from "styled-components"
import styleConstants from "../../constants/styleConstants"

export const AspectRatioBox = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
`

export const PseudoModal = styled.div<{ isOpen: boolean }>`
  @media (min-width: ${styleConstants.desktopMinWidth}) {
    ${props =>
    props.isOpen
      ? `
            background: rgba(0, 0, 0, 0.8);
            position: fixed;
            top: 3.75rem;
            left: 0;
            bottom: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 9;
            cursor: pointer;
          `
      : ""}
  }
`

export const InnerPseudoModal = styled.div<{ isOpen: boolean }>`
  @media (min-width: ${styleConstants.desktopMinWidth}) {
    ${props => (props.isOpen ? "width: 77%;" : "")}
  }
`

export const CloseModalIcon = styled.img<{ isOpen: boolean }>`
  position: fixed;
  top: 4rem;
  right: 1rem;
  width: 5rem;
  cursor: pointer;

  display: none;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    display: ${props => (props.isOpen ? "block" : "none")};
  }
`

export const FallbackVideoPlayer = styled.iframe`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
`

export const FallbackVideoPlayerWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 0;
  overflow: hidden;
  &:before {
    padding-top: 56.25%;
    display: block;
    content: "";
  }
`
