import React from "react"
import {Route, RouteComponentProps, Switch, withRouter} from "react-router-dom"
import Translate from "./localeContext"
import {generateLocalizedPath, getCookie} from "./utils"
import {Nullable} from "./types"
import {TwoFactorAuthenticationScopesSMSType} from "./types/twoFactorAuthentication"
import {cookieLawAcceptedName} from "./constants/global"
import {
  candidatePlatformKey,
  platformCookieKey,
  slaveryPreventionKey,
  livePrivacyKey,
  liveTermsAndConditionsKey,
  notFoundKey,
  platformPrivacyKey,
  requestADemoKey,
  resourcesCandidateExperienceKey,
  resourcesEmployeeExperienceKey,
  resourcesKey,
  resourcesPeopleAnalyticsKey,
  platformTermsAndConditionsKey,
  termsOfServicesKey,
  twoFactorAuthenticationSetPhoneNumberKey,
  twoFactorAuthenticationInsertCodeKeySMS,
  twoFactorAuthenticationInsertCodeKeyEmail,
  candidatePlatformSignupKey,
  employeePlatformKey,
  employeePlatformSignupKey,
  candidatePlatformCompanyKey,
  candidatePlatformCompanySignupKey,
  employeePlatformCompanyKey,
  employeePlatformCompanySignupKey,
  companyChallengeKey,
  companyKey,
  companySectionKey,
  platformSecurityKey,
  candidatePlatformResetPasswordKey,
  candidatePlatformCompanyResetPasswordKey,
  employeePlatformCompanyResetPasswordKey,
  documentGeneralTermsAndConditionsKey,
  documentMSTeamsTermsAndConditionsKey,
  errorKey
} from "./constants/pathKeys"
import Page from "./commonComponents/page"
import PageNotFound from "./views/page-not-found"
import {
  CandidatePlatformLogin,
  CandidatePlatformSignup
} from "./views/candidate-platform"
import useGlobalState from "./hooks/useGlobalState"
import {
  EmployeePlatformLogin,
  EmployeePlatformSignup,
  EmployeePlatformPreSignup
} from "./views/employee-platform"
import CompanyChallenge from "./views/company-challenge"
import Company from "./views/company"
import CompanySection from "./views/company-section"
import urls from "./constants/urls"
import {useStoreData} from "./store/store"
import ResetPasswordContainer from "./views/reset-password/resetPassword"
import {AccessUserType} from "./types/accessUser"
import {
  TwoFactorAuthenticationSMS,
  TwoFactorAuthenticationEmail
} from "./views/two-factor-authentication"
interface ParamsType {
  locale: string
}

type PropsType = RouteComponentProps<ParamsType> & {}

const Routes: React.FC<PropsType> = (props: PropsType) => {
  const {
    match: {
      params: {locale: loc}
    },
    location: {pathname}
  } = props

  const [locale, setLocale] = React.useState<string>(loc)
  const [globalState] = useGlobalState()
  const {dictionary} = globalState

  const {isCandidate} = useStoreData(state => state.auth)

  React.useEffect(() => {
    setLocale(loc)
  }, [loc])

  React.useEffect(loggedRedirectHandler({isCandidate}, locale, pathname), [
    locale,
    pathname,
    isCandidate
  ])

  React.useEffect(() => {
    const bannedRoutes = [
      "/it/candidati/login",
      "/en/candidates/login",
      "/it/candidati/signup",
      "/en/candidates/signup"
    ]
    if (bannedRoutes.some(el => pathname.includes(el))) {
      window.location.href = "www.glickon.com"
    }
    window.scrollTo(0, 0)
    const cookie: Nullable<string> = getCookie(cookieLawAcceptedName)

    /* eslint-disable no-undef */
    // @ts-ignore
    if (typeof ga === "function") {
      // @ts-ignore
      ga("send", "pageview") // google analytics
    }

    if (cookie) {
      // @ts-ignore
      if (typeof piTracker === "function") {
        // @ts-ignore
        piTracker(pathname) // pardot
      }

      // @ts-ignore
      if (window._mfq && typeof window._mfq.push === "function") {
        // @ts-ignore
        window._mfq.push(["newPageView", pathname]) // mouseflow
      }

      // @ts-ignore
      if (typeof fbq === "function") {
        // @ts-ignore
        fbq("track", "PageView") // facebook pixel
      }
      /* eslint-enable no-undef */
    }
  }, [pathname])

  return (
    <Translate.Provider value={{locale, dictionary, setLocale}}>
      <Switch>
        <Route
          exact
          path={generateLocalizedPath(candidatePlatformKey, locale)}
          render={() => (
            <Page
              withNavigation={false}
              withFooter={false}
              pathKey={candidatePlatformKey}
            >
              <CandidatePlatformLogin />
            </Page>
          )}
        />
        <Route
          exact
          path={generateLocalizedPath(candidatePlatformCompanyKey, locale)}
          render={() => (
            <Page
              withNavigation={false}
              withFooter={false}
              pathKey={candidatePlatformCompanyKey}
            >
              <CandidatePlatformLogin />
            </Page>
          )}
        />
        <Route
          exact
          path={generateLocalizedPath(candidatePlatformSignupKey, locale)}
          render={() => (
            <Page
              withNavigation={false}
              withFooter={false}
              pathKey={candidatePlatformSignupKey}
            >
              <CandidatePlatformSignup />
            </Page>
          )}
        />
        <Route
          exact
          path={generateLocalizedPath(
            candidatePlatformCompanySignupKey,
            locale
          )}
          render={() => (
            <Page
              withNavigation={false}
              withFooter={false}
              pathKey={candidatePlatformCompanySignupKey}
            >
              <CandidatePlatformSignup />
            </Page>
          )}
        />
        <Route
          exact
          path={generateLocalizedPath(
            candidatePlatformResetPasswordKey,
            locale
          )}
          render={() => (
            <Page
              withNavigation={false}
              withFooter={false}
              pathKey={candidatePlatformResetPasswordKey}
            >
              <ResetPasswordContainer userType={AccessUserType.Candidate} />
            </Page>
          )}
        />
        <Route
          exact
          path={generateLocalizedPath(
            candidatePlatformCompanyResetPasswordKey,
            locale
          )}
          render={() => (
            <Page
              withNavigation={false}
              withFooter={false}
              pathKey={candidatePlatformCompanyResetPasswordKey}
            >
              <ResetPasswordContainer userType={AccessUserType.Candidate} />
            </Page>
          )}
        />
        <Route
          exact
          path={generateLocalizedPath(employeePlatformKey, locale)}
          render={() => (
            <Page
              withNavigation={false}
              withFooter={false}
              pathKey={employeePlatformKey}
            >
              <EmployeePlatformLogin />
            </Page>
          )}
        />
        <Route
          exact
          path={generateLocalizedPath(employeePlatformCompanyKey, locale)}
          render={() => (
            <Page
              withNavigation={false}
              withFooter={false}
              pathKey={employeePlatformCompanyKey}
            >
              <EmployeePlatformLogin />
            </Page>
          )}
        />
        <Route
          exact
          path={generateLocalizedPath(employeePlatformSignupKey, locale)}
          render={() => (
            <Page
              withNavigation={false}
              withFooter={false}
              pathKey={employeePlatformSignupKey}
            >
              <EmployeePlatformPreSignup />
            </Page>
          )}
        />
        <Route
          exact
          path={generateLocalizedPath(employeePlatformCompanySignupKey, locale)}
          render={() => (
            <Page
              withNavigation={false}
              withFooter={false}
              pathKey={employeePlatformCompanySignupKey}
            >
              <EmployeePlatformSignup />
            </Page>
          )}
        />
        <Route
          exact
          path={generateLocalizedPath(
            employeePlatformCompanyResetPasswordKey,
            locale
          )}
          render={() => (
            <Page
              withNavigation={false}
              withFooter={false}
              pathKey={employeePlatformCompanyResetPasswordKey}
            >
              <ResetPasswordContainer userType={AccessUserType.Employee} />
            </Page>
          )}
        />
        <Route
          exact
          path={generateLocalizedPath(
            twoFactorAuthenticationSetPhoneNumberKey,
            locale
          )}
          render={() => (
            <Page
              withNavigation={false}
              withFooter={false}
              pathKey={twoFactorAuthenticationSetPhoneNumberKey}
            >
              <TwoFactorAuthenticationSMS
                scope={TwoFactorAuthenticationScopesSMSType.SetPhoneNumber}
              />
            </Page>
          )}
        />
        <Route
          exact
          path={generateLocalizedPath(
            twoFactorAuthenticationInsertCodeKeySMS,
            locale
          )}
          render={() => (
            <Page
              withNavigation={false}
              withFooter={false}
              pathKey={twoFactorAuthenticationInsertCodeKeySMS}
            >
              <TwoFactorAuthenticationSMS
                scope={TwoFactorAuthenticationScopesSMSType.InsertCode}
              />
            </Page>
          )}
        />
        <Route
          exact
          path={generateLocalizedPath(
            twoFactorAuthenticationInsertCodeKeyEmail,
            locale
          )}
          render={() => (
            <Page
              withNavigation={false}
              withFooter={false}
              pathKey={twoFactorAuthenticationInsertCodeKeyEmail}
            >
              <TwoFactorAuthenticationEmail />
            </Page>
          )}
        />
        {/*Company*/}
        <Route
          exact
          path={generateLocalizedPath(companyKey, locale)}
          render={() => (
            <Page
              withNavigation={false}
              withFooter={false}
              pathKey={companyKey}
            >
              <Company />
            </Page>
          )}
        />
        {/*Company Section*/}
        <Route
          exact
          path={generateLocalizedPath(companySectionKey, locale)}
          render={() => (
            <Page
              withNavigation={false}
              withFooter={false}
              pathKey={companySectionKey}
            >
              <CompanySection />
            </Page>
          )}
        />
        {/*Company Challenge*/}
        <Route
          exact
          path={generateLocalizedPath(companyChallengeKey, locale)}
          render={() => (
            <Page
              withNavigation={false}
              withFooter={false}
              pathKey={companyChallengeKey}
            >
              <CompanyChallenge />
            </Page>
          )}
        />
        <Route
          exact
          path={generateLocalizedPath(notFoundKey, locale)}
          render={() => (
            <Page
              withNavigation={false}
              withFooter={false}
              pathKey={notFoundKey}
            >
              <PageNotFound />
            </Page>
          )}
        />
        <Route
          exact
          path={"*"}
          render={() => (
            <Page
              withNavigation={false}
              withFooter={false}
              pathKey={notFoundKey}
            >
              <PageNotFound />
            </Page>
          )}
        />
      </Switch>
    </Translate.Provider>
  )
}

export default withRouter<PropsType, React.FC<PropsType>>(Routes)

// Should be one of those path, nevertheless we try to be a little generic,
// to avoid insidious bugs if changing routing
// [companyKey]: {
//   en: "/companies/:companyName",
//   it: "/aziende/:companyName"
// },
// [companyChallengeKey]: {
//   en: "/challenges/:hash",
//   it: "/challenge/:hash"
// },
// [companySectionKey]: {
//   en: "/sections/:id",
//   it: "/sezioni/:id"
// },
// --- Added more paths, here are the related keys:
const externalPagesRoutingKeys = [
  companyKey,
  companyChallengeKey,
  companySectionKey
]

const whiteListedRoutingKeys = [
  platformCookieKey,
  platformTermsAndConditionsKey,
  platformPrivacyKey,
  platformSecurityKey,
  termsOfServicesKey,
  documentGeneralTermsAndConditionsKey,
  documentMSTeamsTermsAndConditionsKey,
  slaveryPreventionKey,
  liveTermsAndConditionsKey,
  livePrivacyKey,
  requestADemoKey,
  resourcesKey,
  resourcesEmployeeExperienceKey,
  resourcesCandidateExperienceKey,
  resourcesPeopleAnalyticsKey,
  notFoundKey
]

function isWhiteListedPagePath(path: string, locale: string): boolean {
  if (!path) return false

  const processedPath = path.toLowerCase().split("?")[0]
  const splittedPath = processedPath.split("/").filter(Boolean)

  const whiteListedRoutingPaths = whiteListedRoutingKeys.map(
    routingKey => urls[routingKey][locale]
  )

  const whiteListedRoutingsStartingPaths = whiteListedRoutingPaths.map(
    path =>
      path
        .toLowerCase()
        .split("/")
        .filter(Boolean)[0]
  )

  return whiteListedRoutingsStartingPaths.some(
    startingPath => startingPath === splittedPath[1]
  )
}

function isExternalPagePath(path: string, locale: string): boolean {
  if (!path) return false

  const processedPath = path.toLowerCase().split("?")[0]
  const splittedPath = processedPath.split("/").filter(Boolean)

  // We are looking for a path like /en/sections/:id, so 2 pieces only
  if (splittedPath.length !== 3) return false

  const externalPagesRoutingPaths = externalPagesRoutingKeys.map(
    routingKey => urls[routingKey][locale]
  )

  const externalPagesRoutingsStartingPaths = externalPagesRoutingPaths.map(
    path =>
      path
        .toLowerCase()
        .split("/")
        .filter(Boolean)[0]
  )

  // Position 1 is, for example in /en/sections/:id - sections
  return externalPagesRoutingsStartingPaths.some(
    startingPath => startingPath === splittedPath[1]
  )
}

function loggedRedirectHandler(
  {
    isCandidate
  }: {
    isCandidate: boolean
  },
  locale: string,
  pathname: string
): React.EffectCallback {
  return () => {
    const shouldAvoidRedirect =
      isExternalPagePath(pathname, locale) ||
      isWhiteListedPagePath(pathname, locale)

    if (shouldAvoidRedirect) return

    const redirectUrl = ""

    if (redirectUrl) {
      window.location.href = redirectUrl
    }
  }
}
