import { TranslationType } from "../types"

const translations: TranslationType = {
  "tos-title": {
    en: "Description of the Glickon Services",
    it: "Descrizione dei servizi glickon"
  },
  "tos-pre-list-text": {
    en: `This document (<strong>"Description of Services"</strong>) regulates the method of supply and function of the Services offered by Glickon to each Customer placing an Order. The Description of Services, together with the
    <a href="terms-and-conditions" target="_blank" rel="noopener noreferrer">General Terms and Conditions</a> for the Glickon Services to which they are attached, shall constitute an integral part of each Order and be fully recalled and incorporated into such. Unless otherwise defined, all capitalised terms shall have the meaning specified hereto or, for lack thereof, that assigned in the
    <a href="terms-and-conditions" target="_blank" rel="noopener noreferrer">General Terms and Conditions</a> for the Glickon Services. The Description of Services applies to the Customer to the extent to which the specific Service is included in the Order. Glickon can, at its sole discretion, amend, supplement, update or cease any aspect or characteristic of the Services, in full or in part, as listed below. 
    <br/><br/>
    Glickon gives the Customer the option of uploading different contents to the Platform (text, images, graphic elements, audio/video files) and of creating Tests in trivia game style, which will be used to assess the competences/aptitude/interests of Users and/or of using the following Services.
    `,
    it: `
            Il presente documento (<strong>"Descrizione dei Servizi"</strong>) disciplina le modalità di erogazione e 
            funzionamento di Servizi offerti da Glickon a ciascun Cliente che ha sottoscritto un Ordine. La Descrizione 
            dei Servizi, insieme ai Termini e Condizioni Generali per i Servizi Glickon cui sono allegati, costituisce parte 
            integrante di ciascun Ordine ed è nello stesso integralmente richiamata e incorporata. I termini in 
            maiuscolo, laddove non diversamente definiti, hanno il significato qui di seguito specificato o, in 
            mancanza, quello di cui ai Termini e Condizioni Generali per i Servizi Glickon.
            La Descrizione dei Servizi si applica al Cliente nella misura in cui il Servizio specifico è incluso nell’Ordine.
            Glickon può, a sua unica discrezione, modificare, integrare, aggiornare o cessare qualsiasi aspetto o 
            caratteristica dei Servizi, in tutto o in parte, così come elencati di seguito.
            <br/><br/>
            Glickon dà al Cliente la possibilità di caricare sulla Piattaforma differenti contenuti (testo, immagini, 
            elementi grafici, file audio/video) e creare Test in stile trivia game che saranno utilizzati al fine di 
            valutare le competenze/attitudini/interessi degli Utenti e/o di usufruire dei seguenti Servizi.
        `
  },
  "tos-last-modified": {
    en: "Milan, 16th April 2021",
    it: "Milano, 16 Aprile 2021"
  },

  "tos-1": {
    en: "Candidate Experience",
    it: "Candidate Experience"
  },
  "tos-1-1": {
    en:
      "This means use of the Platform with a view to carrying out Employer Branding and Talent Acquisition, Events.",
    it: `
             Indica l’utilizzo della Piattaforma finalizzato a condurre un’attività di Employer Branding e Talent Acquisition, Eventi.
        `
  },

  "tos-2": {
    en: "Employee Experience",
    it: "Employee Experience"
  },
  "tos-2-1": {
    en:
      "This means use of the Platform with a view to carrying out Onboarding, Training & Development, Internal Communication, Events.",
    it: `
            Indica l’utilizzo della Piattaforma finalizzato a condurre un’attività Onboarding, Training & 
            Development, Comunicazione Interna, Eventi.
        `
  },
  "tos-2-2": {
    en:
      "The price of the Employee Experience is calculated according to the number of unique Private Users who had access to the Platform, regardless of their use and enjoyment of the contents uploaded by the Customer.",
    it: `
            Il corrispettivo della Employee Experience viene conteggiato in base al numero di Utenti Privati unici che 
            hanno avuto accesso alla Piattaforma, indipendentemente dal loro utilizzo e fruizione dei contenuti caricati 
            dal Cliente.
        `
  },

  "tos-3": {
    en: "White Label Mode",
    it: "Modalità White Label"
  },
  "tos-3-1": {
    en:
      "This mode allows the Customer to benefit from Glickon Services through a reserved version of the Platform, which is therefore intended for use only by Private Users.",
    it: `
            Tale modalità prevede la possibilità per il Cliente di usufruire di Servizi Glickon attraverso una 
            versione riservata della Piattaforma e pertanto destinati esclusivamente ad Utenti Privati.
        `
  },
  "tos-3-2": {
    en:
      "The White Label Platform will be graphically reviewed to suit the Customer’s specific requests. The Glickon logo will not be featured on the pages of the White Label Platform and will be replaced by the hyperlink “Powered by Glickon”.",
    it: `
            La Piattaforma White Label sarà graficamente rivista sulla base delle specifiche richieste del Cliente. 
            Il logo Glickon non sarà presente sulle pagine della Piattaforma White Label e sarà sostituito dall’
            hyperlink "Powered by Glickon".
        `
  },

  "tos-4": {
    en: "People Analytics",
    it: "People Analytics"
  },
  "tos-4-1": {
    en:
      "This means the collection and processing of data in order to create a descriptive, predictive report based on the information collected during Users’ activities on the Platform.",
    it: `
            Indica l’attività di raccolta ed elaborazione dati ai fini di creazione di una reportistica descrittiva e 
            predittiva sulla base delle informazioni raccolte durante le attività degli Utenti in Piattaforma.
        `
  },

  "tos-5": {
    en: "API Integration",
    it: "Integrazione API"
  },
  "tos-5-1": {
    en:
      "This means the possibility for the Customer to integrate the Glickon Platform using its own management software through the Application Programming Interface (API).",
    it: `
            Indica la possibilità per il Cliente di integrare la Piattaforma Glickon con il proprio software gestionale 
            attraverso le Application Programming Interface (API).
        `
  },

  "tos-6": {
    en: "Target Audience",
    it: "Target Audience"
  },
  "tos-6-1": {
    en:
      "This means the possibility for the Customer to entrust the promotion of social recruiting campaigns to Glickon, through its community, social channels and some job boards.",
    it: `
            Indica la possibilità per il Cliente di affidare a Glickon la promozione di campagne di social recruiting 
            attraverso la propria community, i canali social e alcuni job board.
        `
  },

  "tos-7": {
    en: "Video Assessment",
    it: "Video Assessment"
  },
  "tos-7-1": {
    en:
      "This means the possibility for the Customer of asking the User to upload a video file to the Platform.",
    it: `
            Indica la possibilità per il Cliente di richiedere all’Utente di fare un upload in Piattaforma di 
            un file video.
        `
  },

  "tos-8": {
    en: "Professional Services",
    it: "Servizi Professionali"
  },
  "tos-8-1": {
    en:
      "These are professional consultancy services that Glickon offers its Customers, separately from the licence to use the Platform and which can include the development and delivery of customised analysis reports. Each Professional Service is provided by way of a special project, regulated by a specific Order.",
    it: `
            Indicano servizi di consulenza professionale che Glickon offre ai propri Clienti separatamente dalla licenza 
            d’uso della Piattaforma e che possono includere lo sviluppo e la consegna di report di analisi 
            personalizzati. Ciascun Servizio Professionale viene svolto a titolo di progetto speciale, regolato da 
            uno specifico Ordine.
        `
  },

  "tos-9": {
    en: "LIVE game",
    it: "LIVE game"
  },
  "tos-9-1": {
    en:
      "This means use of the Platform accessible at the sub-domain live.glickon.com, for the creation of trivia game and the hiring of players (LIVE Users), with a view to assuring the following activities carried out autonomously by the Customer: employer branding, talent acquisition, onboarding, training & development, internal communication, events, engagement of users for commercial or survey purposes. The LIVE game service does not include the possibility of using the following services: (i) Target audience (ii) White Label Mode.",
    it: `
            Indica l’utilizzo della Piattaforma accessibile al sottodominio live.glickon.com per la creazione di trivia 
            game e l’ingaggio di giocatori (Utenti LIVE) finalizzato alle seguenti attività svolte in autonomia dal 
            Cliente: employer branding, talent acquisition, onboarding, training & development, comunicazione interna, 
            eventi, ingaggio di utenti per fini commerciali o di sondaggio. Il servizio LIVE game non prevede la possibilità di usufruire dei seguenti servizi: (i) Target 
            audience (ii) Modalità White Label.
        `
  },

  "tos-10": {
    en: "CSM - Customer Success Management",
    it: "CSM - Customer Success Management"
  },
  "tos-10-1": {
    en:
      "This means customer support offered by Glickon by means of a Customer Success Manager, a resource devoted to Platform set-up, training and onboarding of the Customer, planning of activities and monitoring of the achievement of shared objectives.",
    it: `
            Indica il supporto al cliente da parte di glickon attraverso un Customer Success Manager, risorsa che si 
            dedica alle attività di set-up della Piattaforma, training e onboarding del Cliente, pianificazione delle 
            attività e monitoraggio del raggiungimento degli obiettivi condivisi.
        `
  },
  "tos-11": {
    en: "Survey",
    it: "Survey"
  },
  "tos-11-1": {
    en: `
    Survey service for the collection of data and information through the creation and supply of multiple choice and open answer questionnaires.`,
    it: `
    Servizio di indagine per la raccolta di dati e informazioni tramite la creazione e erogazione di questionari a risposta multipla e risposta aperta.
        `
  },
  "tos-12": {
    en: "Journey Design",
    it: "Journey Design"
  },
  "tos-12-1": {
    en: `
    Advisory service performed by a dedicated Glickon consultant. The main purpose of the service is to facilitate optimal implementation and use of the platform solutions purchased, sharing know-how and best practices by supporting the customer in designing and identifying the best methods, channels, types of content and processes according to the specific needs of the Client.`,
    it: `
    Servizio di supporto consulenziale da parte di una figura dedicata di Glickon. La finalità principale del servizio consiste nel facilitare un utilizzo ottimale delle soluzioni tecnologiche acquistate, condividendo know-how e best practices e supportando il Cliente nel disegno e nell'individuazione di modalità, canali, tipologia di contenuti e processi ottimali a seconda delle specifiche esigenze del Cliente.
        `
  },
  "tos-12-2": {
    en: "Unless otherwise agreed, the Journey Design Service is intended for a total duration of 16 hours to be used in the first 4 weeks of collaboration",
    it: "Se non diversamente concordato, il Servizio di Journey Design si intende per una durata complessiva di 16 ore da utilizzare nelle prime 4 settimane di collaborazione."
  },
  "tos-13": {
    it: "Linkbiz",
    en: "Linkbiz"
  },
  "tos-13-1": {
      it: `
      Indica l'utilizzo della Piattaforma finalizzato alla creazione di una pagina accessibile al sottodominio nomeazienda.glickon.com. La finalità principale del servizio consiste nella pubblicazione in questa pagina di hyperlink caricati dal Cliente per la condivisione di pagine interne alla Piattaforma ed esterne con i propri utenti. L'accesso e l'utilizzo del Servizio Linkbiz è soggetto ai Termini e Condizioni Generali della Piattaforma e a tutte le leggi e i regolamenti applicabili.
      `,
      en: `
      This means the use of the Platform aimed at creating a page accessible from the sub-domain companyname.glickon.com. The main purpose of the service consists in publishing hyperlinks on this page uploaded by the Client for sharing internal and external pages with their Users. Access to and use of the Linkbiz Service is subject to the General Terms and Conditions of the Platform and all applicable laws and regulations.
      `
  },
  "tos-13-2": {
      it: `
      Se hai notato un particolare profilo Linkbiz che sta pubblicando contenuti fraudolenti, spam o inappropriati, puoi segnalare inviare una mail a <a href="mailto:support@glickon.com" rel="noopener noreferrer">support@glickon.com</a>. Il nostro team di supporto controllerà subito e disattiverà l'account il prima possibile.
      `,
      en: `
      If you have noticed a particular Linkbiz profile that is posting fraudulent, spam or inappropriate content, you can report it by sending an email to <a href="mailto:support@glickon.com" rel="noopener noreferrer">support@glickon.com</a>. Our support team will immediately check and deactivate the account as soon as possible.
      `
  }
}

export default translations
