import { TranslationType } from "../types"

const translations: TranslationType = {
    "platform-tc-title": {
        en: "Terms and Conditions",
        it: "Termini e Condizioni d’uso"
    },
    "platform-tc-last-modified": {
        en: "Milan, 31 March 2019",
        it: "Milano, 31 Marzo 2019"
    },

    "platform-tc-1": {
        en: "Premise",
        it: "Premessa"
    },
    "platform-tc-1-1": {
        en: `
            The following terms and conditions (as defined herein) are between Glickon and the User (as defined herein)
            and are effective from the moment the user accepts. The subject of these terms is the use of the Platform
            (as defined herein) and all its features. This agreement, as well as the policies regarding cookies and
            privacy establish the norms for access to and use of the Platform.
        `,
        it: `
            Le presenti Condizioni d’Uso (come di seguito definite) sono stipulate tra Glickon e l’Utente (come di 
            seguito definiti) ed entrano in vigore al momento dell’accettazione da parte dell’Utente. Oggetto delle 
            presenti Condizioni d’Uso è l’utilizzo della Piattaforma (come di seguito definita) e delle sue 
            funzionalità. Il presente accordo, nonché le policy sui cookie e sulla privacy stabiliscono le norme 
            per l’accesso e l’utilizzo della Piattaforma.
        `
    },
    "platform-tc-1-2": {
        en: `
            By navigating the Platform, registering as a user or using its features, the User acknowledges to have read
            and understood the terms and conditions of these Conditions of Use and to fully accept the regulations.
            Fully accepting the Conditions of Use is necessary for access to the Platform features (as defined herein).
            If the user does not intend to accept these Conditions of Use, the user must immediately cease navigating
            the Platform, avoid registering and not access, view, download or in any other way use any of the Platform
            pages, content or services.
        `,
        it: `
            Navigando sulla Piattaforma, registrandosi a essa o utilizzando le sue funzionalità, l’Utente dichiara 
            di aver letto e compreso i termini e le condizioni delle presenti Condizioni d’Uso e di accettarne 
            integralmente ogni disposizione. L’accettazione integrale delle Condizioni d’Uso è condizione 
            necessaria per l’utilizzo delle funzionalità della Piattaforma secondo quanto ivi stabilito. Se 
            l’Utente non intende accettare le presenti Condizioni d’Uso, deve interrompere immediatamente la 
            navigazione, non effettuare alcuna registrazione alla Piattaforma e non accedere, visionare, scaricare 
            o in altro modo utilizzare alcuna pagina, contenuto o servizio offerto dalla Piattaforma.
        `
    },

    "platform-tc-2": {
        en: "Definitions",
        it: "Definizioni"
    },
    "platform-tc-2-1": {
        en: `
            <strong>"Partner Company"</strong> is a company that has reached a commercial agreement with Glickon 
            regarding the creation of a Corporate Page or a test for a Corporate Challenge for the selection process;
        `,
        it: `
            <strong>"Azienda Partner"</strong> indica la società che ha stretto accordi commerciali con Glickon per l’inserimento 
            sulla Piattaforma di una Pagina Corporate o di un test per le Challenge Aziendali ai fini dello 
            svolgimento di selezioni anche attraverso la Piattaforma;
        `
    },
    "platform-tc-2-2": {
        en: `
            <strong>"Challenge"</strong> refers to challenge/s created and added to the Platform directly by Glickon, 
            which all Users can access/use;
        `,
        it: `
            <strong>"Challenge"</strong> indica la/e sfida/e inserita/e sulla Piattaforma direttamente da Glickon, 
            alla/e quale/i può/possono partecipare tutti gli Utenti;
        `
    },
    "platform-tc-2-3": {
        en: `
            <strong>"Corporate Challenge"</strong> refers to a Challenge managed exclusively by a Partner Company;
        `,
        it: `
            <strong>"Challenge aziendale"</strong> indica una Challenge organizzata esclusivamente da 
            un’Azienda Partner;
        `
    },
    "platform-tc-2-4": {
        en: `
            <strong>"Conditions of Use"</strong> refers to these Conditions of Use and any related document, indicated 
            herein or attached regarding the regulations between Glickon and the User;
        `,
        it: `
            <strong>"Condizioni d’Uso"</strong> indica le presenti condizioni d’uso e ogni altro documento ivi 
            richiamato o alle stesse allegato, regolanti il rapporto fra Glickon e l’Utente;
        `
    },
    "platform-tc-2-5": {
        en: `
            <strong>"Content"</strong> refers to the information and/or data found within the Platform.
        `,
        it: `
            <strong>"Contenuto"</strong> indica le informazioni e/o dati contenuti nella Piattaforma;
        `
    },
    "platform-tc-2-6": {
        en: `
            <strong>"User Content"</strong> means any content such as, for example, contributions, photos, data, texts (Word
            documents, Power Point Excel), software, graphic elements, sounds, images, videos or other materials
            protected by copyright or other rights, uploaded or otherwise made available by the User on the Platform;
    `,
        it: `
            <strong>"Contenuto/i dell'Utente"</strong> indica qualsiasi contenuto quali a titolo esemplificativo contributi, foto, dati,
            testi (documenti Word, Power Point, Excel), software, elementi grafici, suoni, immagini, video o altri
            materiali protetti dal diritto d’autore o altri diritti, caricati o altrimenti messi a disposizione dall’Utente
            sulla Piattaforma;
        `
    },
    "platform-tc-2-7": {
        en: `
            <strong>"Glickon"</strong> refers to Glickon Ltd. A company under Italian law with a registered office in 
            Milan, Via Nino Bixio 7, VAT number: 08573820969;
        `,
        it: `
            <strong>"Glickon"</strong> indica Glickon S.r.l., società di diritto italiano con sede legale in Milano,
             via Nino Bixio 7, codice fiscale/numero di iscrizione al registro delle imprese di Milano 08573820969;
        `
    },
    "platform-tc-2-8": {
        en: `
            <strong>"Corporate Page"</strong> refers to a profile page that a Partner Company can use to describe 
            itself, work on employer branding, publish multimedia content and Corporate Challenges;
        `,
        it: `
            <strong>"Pagina Corporate"</strong> indica una pagina profilo di una Azienda Partner in cui la stessa 
            può descrivere se stessa, fare attività di employer branding, pubblicare contenuti multimediali e 
            pubblicare Challenge Aziendali;
        `
    },
    "platform-tc-2-9": {
        en: `
            <strong>"Platform"</strong> refers to the Glickon Platform, accessible at the URL 
            <a target="_blank" href="https://www.glickon.com">www.glickon.com</a>, aimed at creating a User community 
            able to evaluate itself through Challenges created to test and develop one’s skill set through gaming 
            activities, the User will be able to take the tests either purely for entertainment purposes or in order 
            to take part in the selection process for job postings by Glickon’s partner companies, available online 
            through web application on the most widely used web browsers;
        `,
        it: `
            <strong>"Piattaforma"</strong> indica la piattaforma denominata "Glickon", accessibile all’URL
            <a target="_blank" href="https://www.glickon.com">www.glickon.com</a>, finalizzata alla creazione di 
            una comunità di Utenti che possa misurarsi attraverso Challenge, volte a valutare e sviluppare le 
            proprie competenze attraverso attività ludiche; l’Utente potrà svolgere test per puro divertimento o 
            partecipare a selezioni di personale indette da Aziende Partner di Glickon, disponibile in versione 
            desktop accessibile attraverso i più comuni web-browser;
        `
    },
    "platform-tc-2-10": {
        en: `
            <strong>"Part"</strong> refers to Glickon and the User;
        `,
        it: `
            <strong>"Parte"</strong> indica Glickon e l’Utente;
        `
    },
    "platform-tc-2-11": {
        en: `
            <strong>"User"</strong> refers to the natural person who utilizes the Platform and its features outside of 
            any possible personal entrepreneurial, commercial, artisanal or professional activity, and accepts these Terms of Use;
        `,
        it: `
            <strong>"Utente"</strong> indica la persona fisica che utilizza la Piattaforma e le sue funzionalità 
            al difuori della propria attività imprenditoriale, commerciale, artigianale o professionale 
            eventualmente svolta e accetta le presenti Condizioni d’Uso;
        `
    },
    "platform-tc-2-12": {
        en: `
            Unless the context of this Agreement otherwise clearly requires, references to the plural include the
            singular, to the singular include the plural and to the part include the whole;
        `,
        it: `
            I termini al singolare comprendono il plurale e viceversa, a seconda di quanto permesso o richiesto dal contesto.
        `
    },

    "platform-tc-3": {
        en: "Platform features",
        it: "Funzionamento della piattaforma"
    },
    "platform-tc-3-1": {
        en: `
            The Platform is aimed at bringing together Users and Partner Companies, allowing Users to test and develop
            their skills through the Challenges (both the regular Challenges and the Corporate Challenges) with Partner
            Companies having the ability look at the user results for the Corporate Challenges. The User is free to take
            part in the challenges purely for entertainment purposes or in order to be involved in activities stemming
            from employer branding, talent acquisition and communication (please see <strong>"Corporate Activities"</strong>) 
            promoted by Partner Companies.
        `,
        it: `
            La Piattaforma è finalizzata a favorire l’incontro fra Utenti e Aziende Partner, permettendo agli Utenti di 
            mettere alla prova e sviluppare le proprie competenze attraverso le Challenge (Aziendali e non) e 
            all’Azienda Partner di valutarle. L’Utente potrà svolgere Challenge per puro divertimento o al fine di 
            essere coinvolto in attività di employer branding, talent acquisition, e comunicazione (di seguito anche 
            il <strong>"Attività Aziendali"</strong>) promosse dalle Aziende Partner.
        `
    },
    "platform-tc-3-2": {
        en: `
            Each User will be free to take part in all challenges or a select number of challenges on specific skills
            and interests, choosing from either the Glickon made challenges or those created by the various Partner
            Companies and divided and classified in specific areas of interest. Users can challenge other Users on the
            platform or other individuals connected to them through their own personal social media (Facebook friends,
            LinkedIn contacts or others). These contacts will need to register on the platform in order to access the
            Challenges.
        `,
        it: `
            Ciascun Utente sarà libero di partecipare a tutte o solo alcune delle Challenge su competenze ed interessi 
            specifici, scegliendo fra quelle organizzate da Glickon o quelle delle diverse Aziende Partner e 
            classificate in apposite aree corrispondenti alle sfere d’interesse. L’Utente potrà sfidare altri Utenti 
            della Piattaforma o persone allo stesso collegate attraverso i propri profili social (amici di Facebook, 
            contatti di LinkedIn o altro) che dovranno comunque registrarsi alla Piattaforma per accedere alle Challenge.
        `
    },
    "platform-tc-3-3": {
        en: `
            Glickon and/or the Partner Company that created the Challenge can, at their own discretion, propose
            Challenges of different types to a select number of Users who received the best scores or Users with
            professional profiles in line with what the company is looking for, or with specific skills sets of interest
            to the company.
        `,
        it: `
            Glickon e/o l’Azienda Partner che ha organizzato la Challenge possono, a propria discrezione, proporre 
            iniziative di Challenge di vario genere a un gruppo ristretto di Utenti che abbiano ottenuto i migliori 
            punteggi o che siano in possesso di profili professionali o di competenze specifiche di interesse per 
            l’Azienda Partner.
        `
    },
    "platform-tc-3-4": {
        en: `
            The Corporate Challenges are started and managed completely independently by the Partner Company, in the
            same way as the selection of the participants’ group is at the discretion of the Challenge manager, who will
            apply the selection features that he/she deems most relevant.
        `,
        it: `
            Le Challenge Aziendali sono abilitate e gestite dall’Azienda Partner in piena autonomia, così come la 
            selezione del gruppo di partecipanti è a sola discrezione dell'organizzatore della Challenge, che 
            applicherà i criteri di selezione che ritiene più appropriati.
        `
    },
    "platform-tc-3-5": {
        en: `
            The use of the Platform and the registration on the same is permitted to Users who, according to the
            relevant regulations, are legally able to take action.        
        `,
        it: `
            L'utilizzo della Piattaforma e la registrazione alla stessa è consentito solo ad Utenti che, secondo la 
            normativa applicabile, siano legalmente capaci di agire.
        `
    },
    "platform-tc-3-6": {
        en: `
            Interested Users can register on the Platform, insert their personal data through the specific online forms,
            upload their résumé and start taking the Challenges.
        `,
        it: `
            Gli Utenti interessati possono registrarsi sulla Piattaforma, indicare i propri dati personali utilizzando 
            gli appositi moduli on-line, allegare il proprio curriculum vitae e partecipare alle Challenge
        `
    },
    "platform-tc-3-7": {
        en: `
            Access to the Platform might at times be temporarily suspended or limited due to maintenance operations or
            updates processes needed to add new services and products. Glickon will try to limit the frequency and
            recurrence of such service suspensions and/or limitations. Glickon cannot guarantee that the Platform will
            at all times free of viruses or other components potentially harmful to the User’s devices. The User
            therefore understands that the use of the Platform is at their own risk.
        `,
        it: `
            L'accesso alla Piattaforma potrebbe essere occasionalmente sospeso o temporaneamente limitato per 
            l’esecuzione di lavori necessari per la manutenzione, riparazione o aggiornamento con nuovi servizi e 
            prodotti. Glickon farà il possibile per ridurre al minimo la frequenza e la durata di tali sospensioni 
            e/o limitazioni. Glickon non può garantire che la Piattaforma sia esente da virus o da altre componenti 
            potenzialmente pericolosi per i dispositivi degli utenti. L’Utente accetta quindi che l'utilizzo della 
            Piattaforma è a proprio rischio.
        `
    },
    "platform-tc-3-8": {
        en: `
            The use and proper functionality of Platform features may at times by limited by functionality, blocks, 
            problems related to the market of reference or problems related to payments on specific User data plans. 
            Glickon is to be held in no way responsible of such occurrences. 
        `,
        it: `
            L'uso e il corretto funzionamento della Piattaforma possono, a volte, essere limitati a causa di 
            funzionalità, blocchi o problemi legati al mercato di riferimento o alle tariffe del piano dati di ciascun 
            Utente, cause per le quali Glickon non è in alcun modo responsabile.
        `
    },

    "platform-tc-4": {
        en: "Registration on the platform",
        it: "Registrazione alla piattaforma"
    },
    "platform-tc-4-1": {
        en: `
            To be able to use all the features of the Platform, the User must register using an email address or 
            through a third party social media Login (for example Facebook or LinkedIn). Within this procedure, 
            it is necessary for Users to fill out various sections of their personal profile. In the case of a 
            registration taking place through a third party social media login, the necessary details can be 
            automatically uploaded, during the registration process, according to the available information 
            regarding services supplied by third party entities.
        `,
        it: `
            Per poter utilizzare tutte le funzionalità della Piattaforma, l'Utente deve registrarsi utilizzando il 
            proprio indirizzo e-mail oppure tramite un social login attraverso diversi servizi di terze parti (ad 
            esempio Facebook o LinkedIn). Nell'ambito di questa procedura è necessario che l'Utente compili vari campi 
            del proprio profilo. Nel caso di registrazione attraverso social login taluni dati potranno essere 
            precaricati, in fase di registrazione, attingendo alle informazioni disponibili sui servizi forniti da 
            terze parti. In qualsiasi momento l'Utente potrà sempre integrare il proprio profilo personale e 
            professionale inserendo ulteriori informazioni relative al proprio curriculum vitae, immagini e/o 
            video personali.
        `
    },
    "platform-tc-4-2": {
        en: `
            The user, through their own profile, will have access to the statistics of their progress for the 
            Challenges and quizzes taken.
        `,
        it: `
            L'Utente, tramite il proprio profilo, è in grado di visualizzare le statistiche relative al suo progresso 
            nelle Challenge e nei quiz a cui ha partecipato.
        `
    },
    "platform-tc-4-3": {
        en: `
            The User’s email address and the accounts associated by third party services (such as Facebook or LinkedIn) 
            will be used by Glickon for all communications regarding the use of the Platform, the User’s personal 
            profile and the services offered. The User accepts the validity of such communications aimed at regulating 
            the relationship and the responsibility to check the stated communication channels.
        `,
        it: `
            L’indirizzo di posta elettronica dell'Utente e gli account associati a servizi forniti da terze parti 
            (ad esempio Facebook e LinkedIn) saranno usati da Glickon per tutte le comunicazioni inerenti l'utilizzo 
            della Piattaforma, il proprio profilo personale e i servizi offerti. L'Utente accetta la validità di tali 
            comunicazioni al fine di regolare il rapporto, così come l’obbligo di controllare periodicamente i suddetti 
            canali.
        `
    },
    "platform-tc-4-4": {
        en: `
            All activity taking place on email or on the social media accounts associated by third party services (such 
            as Facebook or LinkedIn) used by the User will automatically allocate said activity and all related aspects 
            to the user, without any exceptions.
        `,
        it: `
            Tutte le operazioni effettuate attraverso la posta elettronica e/o gli account associati a servizi forniti 
            da terze parti (ad esempio Facebook e LinkedIn) utilizzati dall'Utente garantiscono l'attribuzione 
            automatica allo stesso delle operazioni effettuate e delle relative attività, senza eccezioni di sorta
        `
    },
    "platform-tc-4-5": {
        en: `
            Users interested in registering for Glickon through their Facebook/LinkedIn profile will be able to do so by
            clicking on the specific button “Register with Facebook/LinkedIn”. By doing so they will link the personal
            Facebook/LinkedIn account to Glickon, thereby consenting to the activation of the account. Clicking the
            above state button, the User will accept the terms regarding the <a href="/en/platform/privacy" target="_blank">
            Information on the processing of personal data</a> by Glickon and these <strong>Terms of Use</strong>.
        `,
        it: `
            L'Utente che vorrà registrarsi a Glickon attraverso il proprio profilo Facebook/Linkedin lo potrà fare 
            cliccando sull’apposito bottone "Registrati con Facebook/Linkedin" tramite il quale connetterà il 
            proprio account Facebook/Linkedin a Glickon, consentendo così l’attivazione dell’account. Cliccando sul 
            predetto bottone, l'Utente accetta l’<a href="/it/piattaforma/privacy" target="_blank">Informativa sul trattamento dei dati 
            personali</a> di Glickon e le presenti <strong>Condizioni d’Uso</strong>.
        `
    },
    "platform-tc-4-6": {
        en: `
            Glickon cannot in any way gain possession of the credentials to the User’s accounts associated by third
            party services (such as Facebook or LinkedIn) as these will remain under the User’s control and
            responsibility exclusively. The Widget connecting the accounts associated by third party services (such as
            Facebook or LinkedIn) and Glickon are a product and a service supplied by interested third parties (the
            subsidiary companies) and the User will be able to unregister their personal social media account from
            Glickon at any moment. The unregistering in this way will however mean a stop in functionality for an
            account opened through such registration means.
        `,
        it: `
            Glickon non può in alcun modo entrare in possesso delle credenziali di accesso degli account associati a di 
            servizi forniti da terze parti (ad esempio Facebook e LinkedIn) dell’Utente poiché esse rimangono sotto 
            l’esclusivo controllo di quest’ultimo e sotto la sua responsabilità. Il widget di collegamento tra gli 
            account associati a servizi forniti da terze parti (ad esempio Facebook e LinkedIn)e Glickon è un 
            prodotto e servizio fornito tramite le terze parti interessate (e le società da queste controllate) e 
            l'Utente potrà in ogni momento disconnettere il proprio account social da Glickon. Questa disconnessione 
            comporterà però la non operatività dell’account così aperto
        `
    },
    "platform-tc-4-7": {
        en: `
            The User declares and pledges that all information supplied through the registration process, either
            directly or through third party services (such as Facebook or LinkedIn) is valid and up-to-date. Glickon
            will in no way be held responsible for the truthfulness and validity of said information.
        `,
        it: `
            L'Utente dichiara e garantisce sotto la propria esclusiva responsabilità che tutte le informazioni fornite 
            in sede di registrazione, direttamente attraverso la Piattaforma o tramite i servizi forniti da terze parti 
            (ad esempio Facebook e LinkedIn), sono veritiere, corrette ed aggiornate. Resta inteso che Glickon non potrà 
            essere considerata in alcun modo responsabile della veridicità, correttezza e aggiornamento delle predette 
            informazioni.
        `
    },

    "platform-tc-5": {
        en: "Confidentiality and validity of information supplied on the platform",
        it:
            "Riservatezza e veridicita’ delle informazioni fornite sulla piattaforma"
    },
    "platform-tc-5-1": {
        en: `
            After having completed the registration, the User will be able to change the personal profile setting at any
            moment and decide whether to make their profile data visible to other Users and to Partner Companies. It
            being understood that the moment a User takes part directly in a Corporate Challenge, the Partner Company
            that issued the challenge will be able to view the user profiles of all participants, including the results
            of all Challenges posted by Glickon that the user took part in after registering on Glickon. The results of
            Corporate Challenges issued by other Partner Companies will instead not be visible.
        `,
        it: `
            Dopo aver effettuato la registrazione, l'Utente potrà in qualsiasi momento modificare le impostazioni del 
            proprio profilo e decidere se rendere visibili o meno i propri dati agli altri Utenti e alle Aziende Partner. 
            Resta inteso che, nel momento in cui l’Utente partecipa direttamente ad una Challenge Aziendale, l’Azienda 
            Partner che ha organizzato la Challenge Aziendale sarà in grado di visualizzare tutti i profili dei 
            partecipanti, ivi compresi i risultati di tutte le Challenge pubblicate da Glickon che l’utente ha 
            conseguito dal momento in cui si è registrato su Glickon. Non saranno invece visibili i risultati delle 
            Challenge Aziendali pubblicate da altre Aziende Partner.
        `
    },
    "platform-tc-5-2": {
        en: `
            Each user will be able to modify their profile setting making it private if they do not wish to have their 
            results available for viewing.
        `,
        it: `
            Ogni Utente, nel caso in cui non voglia rendere visibili i propri risultati, può modificare le impostazioni 
            del proprio profilo rendendolo privato. Tale scelta non sarà efficace esclusivamente nei confronti delle 
            Aziende Partner alle quali l’Utente abbia effettuato un’application attraverso la Piattaforma: in tal caso, 
            infatti, le Aziende Partner avranno accesso ai suoi dati.
        `
    },

    "platform-tc-6": {
        en: "Use of the platform",
        it: "Utilizzo della piattaforma"
    },
    "platform-tc-6-1": {
        en: `
            Each user, by accessing and using the service agrees to:<br>
            <ul>
                <li>Act in compliance with all application normative measures;</li>
                <li>
                    To supply valid and up to date non confidential and non detrimental to intellectual property rights or other
                    types of third party rights;
                </li>
                <li>
                    Supply all information needed to verify the truthfulness of one’s own identity as may be requested by
                    Glickon or by any other judiciary or administrative authority suited to act within the appropriate legal
                    provisions;
                </li>
                <li>
                    To protect the credentials used to access one’s private account, the security and secrecy of which are
                    exclusively the responsibility of the client. If the User suspects an unauthorized access taking place, we
                    kindly ask them to contact Glickon immediately, and to change passwords as soon as possible. Glickon is by
                    no means to be held responsible for whatever harm may come about from the lack of safe keeping of the
                    security credentials for User access onto the platform;
                </li>
                <li>To publish on the platform any user content or material that in any way breaks the law, public order or common decency;</li>
                <li>To not spread, download or make available in any way viruses, threats, unfortunate content, advertisement, promotional material which are not authorized, this includes any type of spam;</li>
                <li>To not use the platform to send out unauthorized advertisements.</li>
                <li>To not damage, interfere worth or interrupt access to the Platform the various content, or to not act in a way that might alter the functionality or interfere the access or interfere with the access on the part of other users on the Platform and it’s relative content;</li>
                <li>Never use the platform in any way that is harmful at or illegal to Glickon or any other User on the platform.</li>
            </ul>
        `,
        it: `
            Ciascun Utente utilizzando il Servizio si impegna a:<br>
            <ul>
                <li>agire in conformità alla normativa applicabile;</li>
                <li>fornire informazioni esatte, aggiornate, non confidenziali e non lesive dei diritti di proprietà intellettuale o di altri diritti di terzi;</li>
                <li>fornire tutte le informazioni necessarie per verificare la veridicità della propria identità che potrebbero essere richieste da Glickon o da qualsiasi autorità giudiziaria o amministrativa competente in conformità alle norme di legge applicabili;</li>
                <li>custodire con diligenza le credenziali che utilizza per accedere al proprio account, della cui sicurezza e segretezza l’Utente è l’unico responsabile. Qualora l’Utente sospetti che sia stato effettuato un accesso non autorizzato al proprio account o profilo da parte di terzi, l’Utente è pregato di contattare immediatamente Glickon, e di modificare la propria password nel più breve tempo possibile. Glickon non assume alcuna responsabilità per qualunque danno derivante dalla non diligente custodia delle credenziali di accesso utilizzate dall’Utente per accedere alla Piattaforma;</li>
                <li>non pubblicare sulla Piattaforma Contenuti degli Utenti o materiali che siano contrari alla legge, all’ordine pubblico o al buon costume;</li>
                <li>non trasmettere, caricare, o altrimenti rendere disponibili virus, minacce, contenuti sconvenienti messaggi pubblicitari, materiali promozionali non sollecitati o non autorizzati, inclusa ogni forma di spam;</li>
                <li>non utilizzare la Piattaforma per trasmettere comunicazioni commerciali non autorizzate;</li>
                <li>non danneggiare, interferire o interrompere l’accesso alla Piattaforma e ai relativi Contenuti, o compiere azioni che potrebbero alterare la funzionalità o interferire con l’accesso di altri Utenti alla Piattaforma e ai relativi Contenuti;</li>
                <li>non utilizzare la Piattaforma in modo illegale o dannoso per Glickon, o per qualsiasi altro Utente della Piattaforma.</li>
            </ul>
        `
    },
    "platform-tc-6-2": {
        en: `
            The company may restrict, suspend, or close the account of a User in the case of a violation on their part 
            of the above states regulation. The account closure will mean the loss of all access to the Platform and 
            the relative data.
        `,
        it: `
            La Società potrà restringere, sospendere, chiudere l’account dell’Utente in caso di violazione da parte sua 
            delle regole sopra indicate. La chiusura di un account determinerà la perdita dell’accesso alla Piattaforma 
            e dei dati allo stesso connessi.
        `
    },

    "platform-tc-7": {
        en: "User content",
        it: "Contenuti dell’utente"
    },
    "platform-tc-7-1": {
        en: `
            When the User spreads, loads or otherwise makes available on the Content Platform for Users, the User
            concedes to Glickon a non exclusive license, valid at a global level, to copy, use, reproduce, modify,
            adapt, translate, publish, create other related works, give licenses or sublicenses, distribute any User
            Content, for the entire duration of protection of the same, with any shape, means or technology already
            available or to be developed.
        `,
        it: `
            Quando l’Utente trasmette, carica, o altrimenti mette a disposizione sulla Piattaforma Contenuti degli 
            Utenti, l’Utente concede a Glickon una licenza non esclusiva, valida in tutto il mondo, di copiare, 
            utilizzare, riprodurre, modificare, adattare, tradurre, pubblicare, creare opere derivate, dare in licenza 
            o sub-licenza, distribuire qualsiasi Contenuto dell’Utente, per l’intera durata di protezione del medesimo, 
            con ogni forma, mezzo o tecnologia conosciuta od ancora da svilupparsi. L’Utente prende atto che Glickon 
            potrà utilizzare i Contenuti degli Utenti senza alcun corrispettivo in denaro o di altro genere a qualunque 
            scopo, inclusi, a titolo esemplificativo ma non esaustivo, lo sviluppo, la produzione, la distribuzione o 
            la commercializzazione di prodotti o servizi.
        `
    },
    "platform-tc-7-2": {
        en: `
            The User is exclusively responsible for any User Content uploaded on the platform.
        `,
        it: `
    L’Utente è responsabile in via esclusiva di qualunque Contenuto degli Utenti caricato sulla piattaforma.
    `
    },
    "platform-tc-7-3": {
        en: `
            The User declares and guarantees that any User Content complies with the law and does not contain
            illegal material, data or information, or against morals, morality and public order, nor does it have obscene,
            defamatory, offensive or nature content discriminatory in relation to religion, race, sexual orientation,
            nationality and, more generally, content that could be offensive or that violate the intellectual property
            rights of third parties.
        `,
        it: `
            L'Utente dichiara e garantisce che qualsiasi Contenuto degli Utenti è conforme alla legge e non
            contiene materiale, dati o informazioni illeciti, o contro la morale, il buon costume e l'ordine pubblico, né
            ha contenuti osceni, diffamatori, offensivi o di natura discriminatoria in relazione alla religione, la razza,
            l’orientamento sessuale, la nazionalità e, più in generale, contenuti che potrebbero risultare offensivi o
            che violino diritti di proprietà intellettuale di terzi.
        `
    },
    "platform-tc-7-4": {
        en: `
            The User undertakes not to upload electronic files or any material that contains viruses or other codes,
            files or programs created to interrupt, destroy, limit or modify the functioning of the software, hardware or
            telecommunications systems, and of the platform, onto the Platform. by Glickon or third parties.
        `,
        it: `
            L'Utente si impegna a non caricare in Piattaforma file elettronici o qualsivoglia materiale che contenga
            virus o altri codici, file o programmi creati per interrompere, distruggere, limitare o modificare il
            funzionamento dei software, degli hardware o degli impianti di telecomunicazioni, e della piattaforma di
            Glickon o di terzi.
        `
    },
    "platform-tc-7-5": {
        en: `
            Glickon reserves the undisputed right to remove at any time User Content in case the Users are not
            considered adequate and in line with the standards of acceptability or in cases involving violated rights or
            third party entities. Glickon also reserves the right to terminate or temporary erase the account and
            personal profile of the incriminated user without notice.
        `,
        it: `
            Glickon si riserva il diritto insindacabile di rimuovere in qualsiasi momento i Contenuti dell’Utente, nel 
            caso in cui gli stessi non siano considerati adeguati e in linea con gli standard di accettabilità o in 
            caso i diritti di terzi risultino violati, così come si riserva il diritto di sospendere o cancellare senza 
            preavviso l’account e il profilo personale dell'Utente incriminato.
        `
    },

    "platform-tc-8": {
        en: "Cancellation of services and data",
        it: "Interruzione del servizio e cancellazione dei dati"
    },
    "platform-tc-8-1": {
        en: `
            At any moment the User will be able to modify their personal profile or erase their account from the Platform.
        `,
        it: `
            In ogni momento l'Utente sarà in grado di modificare il proprio profilo personale o eliminare il proprio 
            account dalla Piattaforma.
        `
    },
    "platform-tc-8-2": {
        en: `
            The User can erase their account by sending an email to <a href="mailto:privacy@glickon.com" 
            target="_blank">privacy@glickon.com</a>.
            Following the cancellation, the User will receive by email an automatic message confirming receipt of the 
            cancellation request, stating that the account will be erased within 72 hours.
        `,
        it: `
            L’Utente può eliminare il proprio account inviando una mail a <a href="mailto:privacy@glickon.com" 
            target="_blank">privacy@glickon.com</a>. Dopo l’annullamento, l’Utente riceverà per posta elettronica una 
            comunicazione automatica di conferma della avvenuta ricezione della richiesta, con la precisazione che 
            l’account verrà disattivato nelle 72 ore successive.
        `
    },
    "platform-tc-8-3": {
        en: `
            With the cancellation request Glickon guarantees the termination or all date usage as well as other related
            information that the user may have uploaded to the Platform, with the exception of the permitted courses of
            action or those required by law or by specific regulatory provisions.
        `,
        it: `
            Con l'annullamento della richiesta, Glickon garantisce l'interruzione dell'uso dei dati e delle altre 
            informazioni relative all'Utente che l'Utente può aver caricato tramite la Piattaforma, ad eccezione dei 
            trattamenti consentiti o richiesti dalla legge o da specifiche disposizioni regolamentari.
        `
    },

    "platform-tc-9": {
        en: "Warranty and indemnity",
        it: "Garanzie e manleva"
    },
    "platform-tc-9-1": {
        en: `
            By the User<br>
            <ul>
                <li>The User expressly recognizes and accepts that the Platform and its Content is supplied “as is” and “as available” at the moment of their fruition.</li>
                <li>The User is solely and exclusively responsible for the use and security of their email and personal accounts, even for the tasks carried out through the usage of the same. The User is aware of the fact that, with the goal of regulating access to services, the procedure of authentication is required exclusively for the verification of the personal email address and personal accounts used, with the exception of the expressly stated cases where by law it is required to supply personal identification data.</li>
                <li>The User is fully and exclusively responsible for their personal data, information an all User Content uploaded or made public through the Platform. The user is required to verify that all Content does not violate, wholly or in part, the rights or third parties or the applicable regulatory provisions and expressly pledges to indemnify Glickon from all harm and costs (including expected legal costs) which may arise and be charged to Glickon as a result of third party actions or administrative or judiciary authorities for the violation of third party rights or applicable provisions on the part of the User.</li>
            </ul>
        `,
        it: `
            Da parte dell’Utente<br>
            <ul>
                <li>L’Utente riconosce e accetta espressamente che la Piattaforma e i suoi Contenuti sono forniti "come sono" e "come disponibili" al momento della loro fruizione.</li>
                <li>L'Utente è il solo ed esclusivo responsabile per l'uso e la sicurezza della propria posta elettronica e dei propri account, anche per le attività svolte attraverso il loro utilizzo. L'Utente è consapevole del fatto che, al fine di regolare l'accesso al servizio, la procedura di autenticazione è richiesta esclusivamente per la verifica della posta elettronica e degli account utilizzati, salvo i casi espressamente stabiliti dalla legge per i quali è necessario fornire i propri dati identificativi.</li>
                <li>L'Utente è pienamente ed esclusivamente responsabile per eventuali danni che potrebbero insorgere a carico di Glickon o terzi, a seguito del non corretto utilizzo, la perdita, il furto e/o compromissione della riservatezza dell'account di posta elettronica e degli altri account utilizzati</li>
                <li>L'Utente è il solo e diretto responsabile dei propri dati personali, delle informazioni e di tutti i Contenuti degli Utenti caricati e resi pubblici attraverso la Piattaforma. L’Utente ha l’obbligo di verificare che i Contenuti dell’Utente non violino, in tutto o in parte, i diritti di terzi o la normativa applicabile e si impegna espressamente a manlevare e tenere indenne Glickon da tutti i danni e le spese (ivi incluse le prevedibili spese legali) che dovessero essere poste a carico di Glickon in conseguenza di azioni di terze parti o di autorità amministrative o giurisdizionali per violazione di diritti di terzi o della normativa applicabile da parte dell’Utente."</li>
            </ul>
        `
    },
    "platform-tc-9-2": {
        en: `
            By Glickon<br>
            <ul>
                <li>Glickon is not a work placement or staff selection agency, but rather it acts as a hosting provider pursuant to the applicable measures providing a Platform through which Users can compete amongst themselves, test themselves through Challenges and come into contact with Partner Companies. By accessing the Platform, the User does not in any way do so under the authority or control of Glickon. Therefore, Glickon is in no way responsible for the actions, content, or the information provided, published or otherwise shared by the Users.</li>
                <li>Nothing stated in these Terms of Use implies in any way that Glickon will take on or is considered liable to carry out a surveillance process of the information that it records and shares, nor is Glickon to be expected to actively search for facts or circumstances that indicate the presence of legally or contractually illicit activity. The rights and faculties recognized to the Company according to the applicable EU and national legislation remain without prejudice.</li>
            </ul>
        `,
        it: `
            Da parte di Glickon<br>
            <ul>
                <li>Glickon non esegue le funzioni di una agenzia per il lavoro o di selezione del personale, ma agisce come hosting provider ai sensi della normativa applicabile fornendo una Piattaforma attraverso la quale gli Utenti possono competere fra loro, misurarsi attraverso Challenge ed entrare in contatto con le Aziende Partner. Nell’accedere alla Piattaforma, l’Utente non agisce in alcun modo sotto l’autorità o il controllo di Glickon. Pertanto, Glickon non è in alcun modo responsabile delle azioni, dei contenuti, delle informazioni fornite, pubblicate o condivise dagli Utenti. Nulla di quanto stabilito nelle Condizioni d’Uso implica in alcun modo che la Società si obblighi né possa essere considerata tenuta allo svolgimento di un’attività di sorveglianza preventiva sulle informazioni che memorizza e trasmette, né a ricercare attivamente fatti o circostanze che indichino la presenza di attività legalmente o contrattualmente illecite. Sono fatti comunque salvi i diritti e le facoltà riconosciute alla Società ai sensi della legislazione comunitaria e nazionale applicabile.</li>
                <li>Glickon non è in alcun modo responsabile per qualsiasi rapporto che si dovesse instaurare tra l'Utente e le Aziende Partner che interagiscono con la Piattaforma, essendo relazioni che sono private e riservate su cui Glickon non può esercitare alcun controllo. Glickon non è inoltre in alcun modo responsabile, né può dare alcuna garanzia, su eventuali comunicazioni, formazione, riunioni, trattative, accordi contrattuali o rapporti di lavoro che dovessero insorgere tra l’Utente e le Aziende Partner"</li>
            </ul>
        `
    },

    "platform-tc-10": {
        en: "Intellectual property rights",
        it: "Diritti di proprieta’ intellettuale"
    },
    "platform-tc-10-1": {
        en: `
            Glickon retains all intellectual property rights on the Platform and on all features or any connected
            additional services. These Terms of Use do not grant Users additional or different rights aside from those
            expressly states in these Terms of Use.        
        `,
        it: `
            Glickon detiene tutti i diritti di proprietà intellettuale sulla Piattaforma e su tutte le sue funzionalità 
            o servizi supplementari connessi. Le presenti Condizioni d’Uso non concedono agli Utenti diritti diversi o 
            ulteriori rispetto a quelli espressamente riconosciuti nelle presenti Condizioni d’Uso.
        `
    },
    "platform-tc-10-2": {
        en: `
            Glickon allows access to the platform exclusively for the means for which it was created and forbids any 
            copy or modification, even in part, without expressly written consent by Glickon.
        `,
        it: `
            Glickon permette di accedere alla Piattaforma per i soli scopi per i quali è stata creata e vieta la sua 
            copia o modifica, anche in parte, senza il consenso scritto espresso da Glickon.
        `
    },
    "platform-tc-10-3": {
        en: `
            It is therefore forbidden to use the Platform (as herein described), the data the published material and 
            any other content for commercial use, wholly or in part, without expressly written consent by Glickon.
        `,
        it: `
            È pertanto vietato utilizzare per scopi commerciali, in tutto o in parte, con qualsiasi mezzo presente e 
            futuro, in qualsiasi forma e modo, la Piattaforma di cui sopra, i dati, il materiale pubblicato ed altri 
            contenuti, senza il consenso scritto da Glickon.
        `
    },

    "platform-tc-11": {
        en: "Personal data",
        it: "Dati personali"
    },
    "platform-tc-11-1": {
        en: `
            The User pledges to provide all the necessary information to ascertain the veracity of personal identity 
            that may be requested by Glickon or any other judiciary or administrative competent authority to the 
            application legal provisions.
        `,
        it: `
            L’Utente si impegna a fornire tutte le informazioni necessarie per accertare la veridicità della propria 
            identità che potrebbero essere richieste da Glickon o da qualsiasi autorità giudiziaria o amministrativa 
            competente in conformità alle norme di legge applicabili.
        `
    },
    "platform-tc-11-2": {
        en: `
            Glickon will process personal User data according to the provisions and terms specified in the 
            <a href="/en/platform/privacy" target="_blank">Information on the processing of personal data.</a>
        `,
        it: `
            La Società tratterà i dati personali dell’Utente secondo la modalità ed i termini specificati nell’
            <a href="/it/piattaforma/privacy" target="_blank">Informativa sul trattamento dei dati personali.</a>
        `
    },

    "platform-tc-12": {
        en: "General clauses",
        it: "Clausole generali"
    },
    "platform-tc-12-1": {
        en: `
            <strong>Applicable law and the competent authority.</strong> Regarding any controversy 
            inherent to the interpretation or the execution of the Terms of Use, the Italian
            Law will be exclusively applied, excluding any conflicts of law. In the case of controversies with the
            Users, any controversy stemming from the interpretation, validity, or execution of these Terms of Use o
            however inhere or derivative of these, will be subject to the exclusive and mandatory competencies of the
            court of competent jurisdiction or the place of residence of the User.
        `,
        it: `
            <strong>Legge Applicabile e Foro Competente.</strong> Per qualsiasi controversia inerente l’interpretazione 
            o l’esecuzione delle Condizioni d’Uso, sarà applicabile esclusivamente la legge Italiana, escluso ogni 
            conflitto di legge. Nel caso di controversie con gli Utenti, qualunque controversia derivante dalla 
            interpretazione, validità, esecuzione delle presenti Condizioni d’Uso o comunque inerente a o derivante 
            da esse, sarà soggetta alla competenza esclusiva ed inderogabile del tribunale del luogo di residenza o 
            domicilio dell’Utente.
        `
    },
    "platform-tc-12-2": {
        en: `
            <strong>Assimilation and alterations.</strong> These Terms of Use constitute the whole agreement between 
            the User and Glickon e take the place of any previous or simultaneous condition, agreement or declaration, 
            be it written or oral, relative to the topic of Terms of Use. Glickon reserves the right to alter the 
            Platform, the website and the data it contains, as well as the policy and the Terms of Use of the Platform 
            at any point. The User will be subject to the Information on the processing of personal data and the Terms 
            of Use in force at any given moment, available by clicking on the hypertext link "Terms of Use" located 
            within the main menu of the Platform pages.
        `,
        it: `
            <strong>Integrazioni e Modifiche.</strong> Le presenti Condizioni d’Uso costituiscono l’intero accordo tra 
            le l’Utente e Glickon e sostituiscono qualsiasi precedente o contemporanea condizione, accordo o 
            dichiarazione, sia verbale che scritta, relativa alla materia oggetto delle Condizioni d’Uso. Glickon si 
            riserva il diritto di modificare la Piattaforma, il sito e tutti i dati in esso contenuti, la policy e 
            le Condizioni d’Uso della Piattaforma in qualsiasi momento. L'Utente sarà soggetto all’Informativa sul 
            trattamento dei dati personali e alle Condizioni d’Uso in vigore al momento, consultabili cliccando sul 
            collegamento ipertestuale "Condizioni d’Uso" situato nel menu principale delle pagine della Piattaforma.
        `
    },
    "platform-tc-12-3": {
        en: `
            <strong>Conservation.</strong> In the event that a section of the Terms of Use be deemed illicit, nullified 
            or inefficient, such section will be modified so as to make it efficient or otherwise erased, and will not 
            invalidate the efficiency of the remaining sections.
        `,
        it: `
            <strong>Conservazione.</strong> Qualora una qualsiasi disposizione delle Condizioni d’Uso sia dichiarata 
            illecita, nulla, o inefficace, tale disposizione sarà modificata in modo da renderla efficace o altrimenti 
            eliminata, e non inficerà la validità e l’efficacia delle restanti disposizioni
        `
    },
    "platform-tc-12-4": {
        en: `
            <strong>Communications.</strong> Any communication of administrative nature relative to the Terms of Use 
            will be done through the Platform’s homage.
        `,
        it: `
            <strong>Comunicazioni.</strong> Qualsiasi comunicazione di tipo amministrativo relativamente alle 
            Condizioni d’Uso sarà effettuata nella homepage della Piattaforma.
        `
    }
}

export default translations
