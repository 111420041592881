import {CaseStudyWithLocaleType} from "../../types"

export const caseStudies: CaseStudyWithLocaleType[] = [
  {
    it: {
      caseId: "talentacquisition", // used in url to single case-study
      company: {
        careersName: "accenture", // used in url to single case-study
        name: "Accenture",
        logoUrl: "logos/accenture.png",
        sector: "Tecnologia",
        area: "Globale",
        business: "B2B",
        employees: "375.000",
        siteUrl: "https://accenture.com",
        siteLabel: "accenture.com",
        description: `
                    Accenture è una multinazionale di consulenza strategica (una delle più importanti al mondo per network,
                    clienti e fatturato) che eroga servizi tecnologici e di outsourcing. Il modello di business è ramificato in
                    sei aree: strategy, consulting, technology, digital, operation e security.
                `
      },
      title: "I numeri della collaborazione tra Accenture e Glickon",
      description: `
                Con Glickon, Accenture ha aumentato il numero di candidature per le sue posizioni aperte ottenendo 
                anche uno screening veloce grazie ai dati raccolti.
            `,
      sideImageUrl: "case-study/thumb-clients-accenture.jpg",
      backgroundImageUrl: "case-study/header-clients-accenture.jpg",
      dataLines: [
        {
          numbers: [
            {
              text: "209",
              description: "Candidature ricevute"
            },
            {
              text: "29 anni",
              description: "Età media (target Millennials)"
            },
            {
              text: "86%",
              description: "Rate di candidature terminate"
            },
            {
              text: "25%",
              description: "Talenti voto di laurea 110/110"
            }
          ]
        }
      ],
      fullWidthImgText: {
        title:
          "Solo candidati Millennials, STEM e preparati sulla cybersecurity",
        description: `
                    Accenture sceglie Glickon per raggiungere tre obiettivi:
                    <br/><br/>
                    <ul>
                        <li>Incrementare l’interesse del brand per i candidati Millennials con competenze STEM: scienza, tecnologia, ingegneria, matematica.</li>
                        <li>Verificare nella fase di selezione quelli con skill in materia di cybersecurity, profilando i migliori per assumerli.</li>
                        <li>Glickon declina una proposta di experience collegata a talent e employee branding e valutazione di un fit index molto specifico.</li>
                    </ul>
                `,
        bigImageUrl: "case-study/detail-clients-accenture-1.jpg"
      },
      fullWidthText: {
        title: "L’immagine del brand per attrarre solo i talenti migliori",
        description:
          "Un’azienda come Accenture è costantemente di fronte a una doppia sfida HR e marketing. Assumere i talenti con le competenze più strategiche per essere leader in un mercato molto competitivo. Rafforzare la percezione del brand verso le nuove risorse, sempre più attente non solo alla parte economica di un’offerta di lavoro ma all’appeal, alle aspettative e all’esperienza che una big company deve essere in grado di garantire."
      },
      phases: [
        {
          title: "Engagement su candidati STEM (Millennials)",
          description:
            "È la fase di prescreening in cui i team HR di Accenture propongono challenge di gamification, impostate alla ricerca di competenze sugli argomenti di scienza, tecnologia, ingegneria e matematica. Con un filtro aggiuntivo: prendere in considerazione solo i candidati nati tra il 1981 e il 1996."
        },
        {
          title: "Aumentare la percezione positiva del brand",
          description:
            "Grazie alla piattaforma di candidate experience di Glickon, Accenture riesce a far emergere ed evidenziare la sua immagine di brand attento ai nuovi lavori tech e digital oriented, posizionandosi come interlocutore attento (per target anagrafico e accademico) a una potenziale e specifica forza lavoro."
        },
        {
          title: "Analisi delle competenze sul tema cybersecurity",
          description:
            "Gli algoritmi Glickon creano un indice di compatibilità ordinando i risultati della selezione in base competenze più in linea con un’offerta di lavoro sul topic cybersecurity."
        }
      ],
      quote: `
                Con Glickon abbiamo raggiunto un duplice obiettivo. Dare maggior forza e consapevolezza del nostro brand a tutti gli stakeholder ed attrarre persone di talento che avessero competenze e conoscenze specifiche. Entrambi gli obiettivi sono stati raggiunti egregiamente
            `
    },
    en: {
      caseId: "talentacquisition",
      company: {
        careersName: "accenture",
        name: "Accenture",
        logoUrl: "logos/accenture.png",
        sector: "Technology",
        area: "Global",
        business: "B2B",
        employees: "375.000",
        siteUrl: "https://accenture.com",
        siteLabel: "accenture.com",
        description: `
                    Accenture is a multinational strategic consulting firm (one of the most important in the world in 
                    terms of network, customers and revenue) that provides technological and outsourcing services. 
                    The business model is divided into six areas: strategy, consulting, technology, digital, 
                    operation and security.
                `
      },
      title:
        "The numbers behind the collaboration between Accenture and Glickon",
      description: `
                With Glickon, Accenture increased the number of candidates in line with the required offer, also 
                achieving a fast screening process thanks to the data and insights collected.
            `,
      sideImageUrl: "case-study/thumb-clients-accenture.jpg",
      backgroundImageUrl: "case-study/header-clients-accenture.jpg",
      dataLines: [
        {
          numbers: [
            {
              text: "209",
              description: "Candidates"
            },
            {
              text: "29 years",
              description: "Average age (Millennials target)"
            },
            {
              text: "86%",
              description: "Rate of success applications"
            },
            {
              text: "25%",
              description: "Talent with a graduating grade of 4.0 GPA."
            }
          ]
        }
      ],
      fullWidthImgText: {
        title:
          "Only candidates who are Millennials, STEM and knowledgeable on cybersecurity",
        description: `
                    Accenture chooses Glickon to achieve three goals:
                    <br/><br/>
                    <ul>
                        <li>Increase brand interest for Millennial candidates with STEM skills: science, technology, engineering, mathematics.</li>
                        <li>Evaluate candidates with cybersecurity skills in the selection process, edentifying the best hiring fits.</li>
                        <li>Glickon designs an experience proposal linked to talent and employee branding along with the proposal of a very specific fit index.</li>
                    </ul>
                `,
        bigImageUrl: "case-study/detail-clients-accenture-1.jpg"
      },
      fullWidthText: {
        title: "The brand image to attract only the best talents",
        description: `
                    A company like Accenture is constantly facing a double HR and marketing challenge: hiring the talent with
                    the best strategic skills in order to be sector leader in a very competitive market, strengthening the
                    perception of the brand towards new resources that are ever more attentive not only to the economic side of
                    a job offer but also to the appeal, expectations and experiences that a big company must be able to provide.
                `
      },
      phases: [
        {
          title: "Engagement on STEM candidates (Millennials)",
          description:
            "The pre-screening phase in which Accenture HR teams propose gamification challenges targeting specific skills in science, technology, engineering and mathematics, with the added filter: looking exclusively at candidates born between 1981 and 1996."
        },
        {
          title: "Increasing the positive perception of the brand",
          description:
            "Thanks to Glickon's candidate experience platform, Accenture is able to develop and highlight a new side of its brand image that focuses on new tech and digitally oriented jobs, positioning itself as an attentive and appealing to a specific potential workforce (both an age and academic target)."
        },
        {
          title: "Analyzing cybersecurity skills",
          description:
            "Glickon’s algorithms create a compatibility index by sorting the selection results based on skills more in line with a job offer within the cybersecurity realm."
        }
      ],
      quote: `
                With Glickon we have achieved a double objective: to give all stakeholders more strength and awareness 
                of our brand and to attract talented people who have specific skills and knowledge. Both objectives 
                have been achieved admirably
            `
    }
  },
  {
    it: {
      caseId: "peopleanalytics",
      company: {
        careersName: "acea",
        name: "Acea",
        logoUrl: "logos/acea.png",
        sector: "Acqua, energia, ambiente",
        area: "Italia",
        business: "B2C",
        employees: "4.700",
        siteUrl: "https://www.acea.it/",
        siteLabel: "acea.it",
        description:
          "Acea è una società impegnata nella gestione e nello sviluppo di reti e servizi nei settori dell’acqua, dell’energia e dell’ambiente. In quello idrico integrato è il principale operatore nazionale con più di 8 milioni di utenti. In totale aggrega una forza lavoro di quasi 4.700 persone."
      },
      title: "Il traguardo di Acea. Prendere decisioni HR consapevoli",
      description:
        "Acea sceglie Glickon come partner tecnologico per la people analytics. La combinazione di analisi semantica e di sentiment, caratteristiche del prodotto GlickonX, permettono al team HR di conoscere meglio relazioni e connessioni in un network di quasi 3.000 dipendenti.",
      sideImageUrl: "case-study/thumb-clients-acea.jpg",
      backgroundImageUrl: "case-study/header-clients-acea.jpg",
      dataLines: [
        {
          numbers: [
            {
              text: "2.800",
              description: "Feedback dai dipendenti"
            },
            {
              text: "2 analisi",
              description: "Semantic & Sentiment"
            },
            {
              text: "6 criteri",
              description: "di lettura dei risultati"
            },
            {
              text: "12",
              description: "Modelli/valori HR analizzati"
            }
          ]
        }
      ],
      fullWidthImgText: {
        title: "2.800 feedback, 2 analisi (Semantic & Sentiment), 1 prodotto",
        description:
          "Acea sceglie Glickon perché numero, complessità e variabilità dei feedback richiedono un’analisi capace di seguire un metodo scientifico, veloce e strutturato per comprendere in profondità tanti specifici temi presenti nelle risposte della survey.",
        bigImageUrl: ""
      },
      fullWidthText: {
        title:
          "Da una survey a una strategia<br/>di People Analytics con Glickon",
        description:
          "A fine 2018 Acea lancia una campagna di comunicazione interna per raccogliere più di 2.800 feedback rilasciati in una survey dai manager ai collaboratori. Il primo obiettivo prefissato dal team HR è creare engagement in cerca di risposte che descrivano le relazioni all’interno dell’organizzazione del network. Ma la quantità di informazioni raccolte fa emergere un’altra necessità a Jlenia Sassi, HR People Engagement di Acea: «Quali altri insight posso ottenere con un’analisi più approfondita di così tante conversazioni e attraverso quali strumenti?»."
      },
      phases: [
        {
          title: "Semantic Analysis",
          description:
            "Attraverso il riconoscimento e l’analisi delle parole più significative nel dizionario aziendale è possibile riconoscere i temi discussi dai dipendenti. Glickon ha integrato la tecnologia GlickonX con il Codebook di Acea, il codice di lettura dell’azienda fatto di centinaia di vocaboli, sinonimi e contrari che identificano le parole chiave e il sistema di valori corporate fondamentali per creare un match con i feedback della survey iniziale."
        },
        {
          title: "Sentiment Analysis",
          description:
            "L’utilizzo degli algoritmi GlickonX applicati alle metodologie di text mining identificano, estraggono, quantificano e analizzano il sentiment e l’emozione nei più di 2.800 feedback a disposizione. Assegnando una polarità negativa, neutra o positiva per ogni testo raccolto e ordinando i risultati in cluster sulla base di caratteristiche anagrafiche e professionali dei dipendenti Acea."
        }
      ],
      quote: `
                La combinazione di analisi semantica e di sentiment, caratteristiche del prodotto GlickonX, hanno permesso al team HR di Acea di ottenere tre output importanti: 
                1. Capire lo stato di salute della nostra azienda e la capacità delle persone di dare e ricevere feedback. 
                2. Acquisire una maggiore consapevolezza rispetto al modello di leadership di Acea, che fa parte del nostro modo di interpretare i comportamenti dei dipendenti tramite espressioni che rinforzano gli atteggiamenti presidiati e sostengono quelli più deboli. 
                3. Disporre di una fotografia che restituiremo ai nostri colleghi e che riteniamo possa aiutarli a comprendere ulteriormente il valore del feedback
            `
    },
    en: {
      caseId: "peopleanalytics",
      company: {
        careersName: "acea",
        name: "Acea",
        logoUrl: "logos/acea.png",
        sector: "Water, energy, environment",
        area: "Italy",
        business: "B2C",
        employees: "4.700",
        siteUrl: "https://www.acea.it/",
        siteLabel: "acea.it",
        description:
          "Acea is a company that works in management and development of networks and services in the water, energy and environmental industries. In the integrated water sector it is the main national operator with more than 8 million users. Overall Acea boasts a workforce of almost 4,700 people."
      },
      title: "The Acea goal: making informed HR decisions",
      description:
        "Acea chooses Glickon as a technological partner for people analytics. The combination of semantic and sentiment analysis, features of the product GlickonX, allows HR team to learn more about relationships and connections within a network of nearly 3,000 employees.",
      sideImageUrl: "case-study/thumb-clients-acea.jpg",
      backgroundImageUrl: "case-study/header-clients-acea.jpg",
      dataLines: [
        {
          numbers: [
            {
              text: "2.800",
              description: "Employee feedback"
            },
            {
              text: "2 analyses",
              description: "Semantic & Sentiment"
            },
            {
              text: "6 criteria",
              description: "For reading the results"
            },
            {
              text: "12",
              description: "HR models / values ​​analyzed"
            }
          ]
        }
      ],
      fullWidthImgText: {
        title: "2,800 feedback, 2 analyses (Semantic & Sentiment), 1 product",
        description:
          "Acea chooses Glickon because the number, complexity and variability of the feedback gathered require an analysis capable of following a scientific and structured but also quick method to understand in depth the many specific themes contained in the survey’s responses.<br/>Glickon implements two operational strategies of People Analytics thanks to GlickonX technology:",
        bigImageUrl: ""
      },
      fullWidthText: {
        title: "From a survey to a People Analytics<br/>strategy with Glickon",
        description:
          "In late 2018, Acea launches an internal communications campaign to collect more than 2,800 feedback responses from the staff to a survey shared by the managers. The original goal set by the HR team is increasing engagement in search of answers able to describe the relationships that exist within the network’s organization. But to Jlenia Sassi from Acea's HR People Engagement, the data gathered actually highlights another need: «What other insights can I get with a more in-depth analysis of so many conversations, and through which tools?»."
      },
      phases: [
        {
          title: "Semantic Analysis",
          description:
            "Through the analysis of the most significant words found in the company dictionary it is possible to recognize the main topics discussed by employees. Glickon integrated GlickonX technology with the Acea Codebook – the company’s reading code made up of hundreds of words, synonyms and antonyms that identify the key words and the fundamental corporate values’ system – in order to create a match with the initial survey’s feedback ."
        },
        {
          title: "Sentiment Analysis",
          description:
            "The use of GlickonX’s algorithms applied to text mining methods identify, extract, quantify and analyze sentiment and emotion in the more than 2,800 feedback responses available. Assigning a negative, neutral or positive polarity for each text collected and ordering the results in clusters based on the personal and professional characteristics of Acea employees."
        }
      ],
      quote: `
                The combination of semantic and sentiment analysis, key GLickonX features, allowed Acea's HR team to draw three important output:
                1. Understanding our company’s health and our people’s ability to give and receive feedback.
                2. Growing awareness of Acea’s leadership model, which is part of our way of interpreting employee behavior through expressions that reinforce safe attitudes and aid weaker ones.
                3. Getting a clearer picture to help our colleagues better understand the value of the Feedback
            `
    }
  },
  {
    en: {
      caseId: "employeeengagement",
      company: {
        careersName: "ferrovie",
        name: "Ferrovie dello stato",
        logoUrl: "logos/ferrovie.png",
        sector: "Transport",
        area: "Italy",
        business: "B2B / B2C",
        employees: "81.000",
        siteUrl: "https://www.fsitaliane.it/",
        siteLabel: "fsitaliane.it",
        description:
          "Out country’s largest and most important transport company. A company that makes up a large part of contemporary italian history and serves many citizens daily. The people are once agin the key strategy for growth of a company that must garantee an efficent infrastructure and safety for all commuters."
      },
      title: "Engagement and challenges to get feedback pre/post events",
      description:
        "To engage the audience in a direct and interactive way so as to reinforce internal cohesion to bring back a clear and involved vision for the company.<br/><br/>These are the key outcomes reached by Ferrovie together with Glickon.",
      sideImageUrl: "case-study/thumb-clients-ferrovie.jpg",
      backgroundImageUrl: "case-study/header-clients-ferrovie.jpg",
      dataLines: [
        {
          numbers: [
            {
              text: "300",
              description: "Number of participants"
            },
            {
              text: "5.000",
              description: "Questions and answers"
            },
            {
              text: "10 minutes",
              description: "Average playing time"
            },
            {
              text: "20",
              description: "Questions on risk management and control"
            }
          ]
        }
      ],
      fullWidthImgText: {
        title: "Ferrovie content travels on an interactive track",
        description: `
                    Ferrovie chooses Glickon to increase interaction and engagement levels in its corporate events and conventions. The preset goal is to boost attention and memore in order to generate data to analize, using the results to create new operational processes based on employee responses.
                    <br/><br/>
                    Glickon, through quizzes, trivia games, and open ended questions helps Ferrovie add gamification to its events to engage the audience on very serious topics such as control systems and risk management.
                `,
        bigImageUrl: "case-study/detail-clients-ferrovie-1.jpg"
      },
      fullWidthText: {
        title: "Next stop?<br/>Attention, memory, event data",
        description:
          "Ferrovie, like all companies, sponsors several corporate events in the country, but is aware of the difficulty in fully captivating the participants’ attention to raise awareness on certain topics. The low engament level of the audience led to a lack of strctured feedback from these events whcih in turn hindered the company’s ability to make informed strategic decisions."
      },
      phases: [
        {
          title: "Glickon employee experience for the Ferrovie audience",
          description:
            "It just takes a few steps get the audience involved (no max number) on the games applied to the topics selected by the Ferrovie HR team."
        },
        {
          title: "Playing and interacting with the chosen topics",
          description:
            "Glickon’s gamification phase makes it possible for Ferrovie to engage participants during an education or formative event, raising attention and memory levels through simple, easy and intuitive challenges."
        },
        {
          title: "Event data analysis",
          description:
            "The HR team can analyze in real time the audience’s level of understanding and learning. Glickon organizes its data on a dashboard designed for further interaction with the audience by presenting the gamification results."
        }
      ],
      quote: `
                Creating engagement during events is always a great challenge, especially when the topics at hand are serius and difficult as is the case with topics such as control systems and risk management. For this reason we chose Glickon to turn these events into dynamic and interactive opportunities to increase engagement levels among the participants and learn more about their level of comprehension. Mission accomplished!
            `
    },
    it: {
      caseId: "employeeengagement",
      company: {
        careersName: "ferrovie",
        name: "Ferrovie dello stato",
        logoUrl: "logos/ferrovie.png",
        sector: "Trasporti",
        area: "Italia",
        business: "B2B / B2C",
        employees: "81.000",
        siteUrl: "https://www.fsitaliane.it/",
        siteLabel: "fsitaliane.it",
        description:
          "È la più importante società di trasporto ferroviario nel nostro Paese. Un’azienda che fa parte della storia contemporanea e della quotidianità degli italiani che ogni giorno si spostano in treno. Le persone sono ancora oggi la chiave strategica per la crescita e l’evoluzione di un gruppo che deve garantire un’infrastruttura efficace e sicurezza per tutti i viaggiatori."
      },
      title:
        "Engagement e challenge per ottenere feedback durante e post eventi",
      description:
        "Coinvolgere in maniera diretta e interattiva la propria audience di riferimento, rafforzando la coesione interna per restituire una visione chiara e partecipata dell’azienda.<br/><br/>Questi sono i punti chiave raggiunti da Ferrovie dello Stato insieme a Glickon.",
      sideImageUrl: "case-study/thumb-clients-ferrovie.jpg",
      backgroundImageUrl: "case-study/header-clients-ferrovie.jpg",
      dataLines: [
        {
          numbers: [
            {
              text: "300",
              description: "Numero di partecipanti"
            },
            {
              text: "5mila",
              description: "Domande con risposte"
            },
            {
              text: "10 minuti",
              description: "Tempo medio del gioco"
            },
            {
              text: "20",
              description: "Domande specifiche sul tema"
            }
          ]
        }
      ],
      fullWidthImgText: {
        title: "I contenuti di Ferrovie viaggiano su un binario interattivo",
        description: `
                    Ferrovie sceglie Glickon per aumentare l’engagement e l’interazione durante convention e eventi corporate. L’obiettivo prefissato è stimolare attenzione e memoria per ottenere dati da analizzare, utilizzando poi gli insight raccolti per sviluppare nuovi processi operativi basati sulle risposte dei dipendenti.
                    <br/><br/>  
                    Glickon con quiz, trivia game e domande aperte permette a Ferrovie di portare la gamification durante qualsiasi evento per coinvolgere il pubblico anche su argomenti molto seri come il sistema di controllo e la gestione del rischio.
                `,
        bigImageUrl: "case-study/detail-clients-ferrovie-1.jpg"
      },
      fullWidthText: {
        title:
          "Prossima stazione?<br/>Attenzione, memoria, dati durante gli eventi ",
        description:
          "Ferrovie dello Stato promuove eventi interni in tutto il territorio italiano e ha scelto Glickon per aumentare i tassi di engagement e rendere un momento di incontro tra dipendenti, il più dinamico e interattivo possibile. Come è andata? Scopriamolo insieme."
      },
      phases: [
        {
          title: "Glickon Employee Experience per l’audience di Ferrovie",
          description:
            "Bastano pochi step per far interagire il pubblico (non c’è limite numerico) sui giochi applicati ai temi che i team di HR di Ferrovie preferiscono."
        },
        {
          title: "Giocare e interegire con gli argomenti proposti",
          description:
            "La fase di gamification di Glickon consente di far applicare i partecipanti durante o dopo un intervento divulgativo o formativo. Ferrovie riesce a tenere alto il livello di attenzione e memoria grazie a challenge semplici, veloci e intuitive."
        },
        {
          title: "Analisi dei dati durante gli eventi",
          description:
            "Le risorse HR possono verificare in tempo reale il grado di comprensione o apprendimento del pubblico. Glickon organizza i dati sulla dashboard consentendo di interagire ulteriormente con il pubblico comunicando l’esito della gamification."
        }
      ],
      quote: `
                Creare engagement durante gli eventi è sempre una grande sfida, specie quanto i temi trattati sono seri e impegnativi, come può essere il tema del sistema di controllo e della gestione del rischio. Per questo motivo abbiamo scelto Glickon, per rendere dinamico ed interattivo questo momento e per incrementare l’ingaggio dei partecipanti e conoscere il loro livello di comprensione. Missione compiuta!
            `
    }
  }
]
