import {TranslationType} from "../types"

const translations: TranslationType = {
  // header-product
  "live-top-title": {
    en: "Glickon Live. Gentlemen, play your game!",
    it: "Glickon Live. Signori, fate il vostro gioco!"
  },
  "live-top-description": {
    en:
      "It's not a casino, you won’t win money. But you will come out with lots of data to learn more about your audience",
    it:
      "Non è il casinò, non si vincono soldi. Ma tanti dati per conoscere meglio il tuo pubblico"
  },

  // subtitle-module-1
  "live-1st-subtitle-description": {
    en: `
            Quiz games and polls. Turn those moments of interaction with your audience into TV-style game shows to
            increase the engagement of your audience and collect data. Live is the gaming platform dedicated to the
            events to discover potential, skills and to measure peoples’ performances. You choose who, how many, where
            are the participants are and which topics they will focus on. We provide you with a dashboard that can be
            consulted in real time to gather insights and make decisions.
        `,
    it: `
            Giochi a quiz e sondaggi. Trasforma momenti di incontro
            con la tua audience in game-show in stile televisivo per
            aumentare l’engagement del tuo pubblico e raccogliere
            dati. Live è la piattaforma di gioco dedicata agli eventi per
            scoprire potenziale, competenze e misurare la performance
            delle persone. Tu scegli chi, quanti, dove e quali sono i
            partecipanti e gli argomenti. Noi ti forniamo una dashboard
            consultabile in tempo reale per ottenere insight e prendere
            decisioni.  
        `
  },

  // products
  "live-products-title": {
    en: "Key features",
    it: "Funzionalità principali"
  },
  "live-products-1st-name": {
    en: "...a winning team...<br/>should keep playing",
    it: "Squadra che vince...<br/>falla giocare ancora"
  },
  "live-products-1st-description": {
    en: `
            Live is the perfect tool for making various teams compete, even those based in different locations, 
            motivating them on a variety of objectives through games.
        `,
    it: `
            Live è lo strumento perfetto per mettere in competizione
            vari team, anche di sedi diverse, motivandoli su vari
            obiettivi attraverso il gioco.
        `
  },
  "live-products-2nd-name": {
    en: "On air surveys<br/>in 3-2-1. Go!",
    it: "Sondaggi on air<br/>in 3-2-1. Go!"
  },
  "live-products-2nd-description": {
    en: `
            For any event, check the degree of attention and learning on specific topics with targeted questions.
        `,
    it: `
            Per qualsiasi evento, verifica il grado di attenzione e
            apprendimento su temi specifici con domande mirate.
        `
  },
  "live-products-3rd-name": {
    en: "Share yours reports<br/>real time!",
    it: "Condividi i tuoi<br/>report in diretta"
  },
  "live-products-3rd-description": {
    en: `
            Live orders the data collected as soon as the session ends. 
            In this way, you can comment on the results with your
            audience and share them with HR colleagues.
        `,
    it: `
            Live ordina i dati raccolti appena conclusa la sessione
            di gioco. Così puoi commentare i risultati con la tua
            audience e condividerli con i colleghi HR.
        `
  },

  // bottom-full-width
  "live-bottom-title": {
    en: "Simple, fun, available. What more do you want?",
    it: "Semplice, simpatico, disponibile. Che vuoi di più?"
  },
  "live-bottom-description": {
    en: `
            Gamification creates engagement and produces essential information to get to know people better. It
            stimulates learning and memorization, strengthens the corporate culture and keeps your targeted audience
            close to your brand. Do you need to reduce recruiting time? Test the skills during onboarding? Check the
            degree of understanding during a coaching event? You can fill in the rest of the questions, Glickon Live
            gives you the answers you are looking for, organized in a panel of data and insights with one goal: to
            discover the potential of your resources in order to grow your business.
        `,
    it: `
            La gamification crea engagement e produce informazioni
            essenziali per conoscere al meglio le persone. Stimola
            apprendimento e memorizzazione, fortifica la cultura
            aziendale e tiene stretta una audience di riferimento al tuo
            brand. Devi ridurre il tempo di recruiting? Testare le
            competenze durante l’onboarding? Verificare il grado di
            comprensione durante un evento di coaching? Gli altri punti
            interrogativi aggiungili tu, Glickon Live ti dà le risposte che
            cerchi organizzate in un panel di dati e insight con un
            obiettivo: scoprire il potenziale delle tue risorse per far
            crescere il tuo business.
        `
  },

  // bricks
  "live-brick-1st-title": {
    en: "Who.<br>Let’s call them players",
    it: "Chi.<br>Chiamali giocatori"
  },
  "live-brick-1st-description": {
    en: `
            Live is a flexible tool that can be applied to any audience. People who are already a resource to the
            company, new candidates, students, clients, suppliers, consultants ... We call them all players, because
            they always have lots of fun.
        `,
    it: `
            Live è un tool flessibile e applicabile a qualsiasi audience.
            Persone che sono già una risorsa per l’azienda, nuovi
            candidati, studenti, clienti, fornitori, consulenti... Noi li
            chiamiamo giocatori, perché si divertono sempre da matti.
        `
  },
  "live-brick-2nd-title": {
    en: "What.<br/>You choose",
    it: "Che cosa.<br/>Sceglilo tu"
  },
  "live-brick-2nd-description": {
    en: `
            You choose the aim of the gaming sessions. Training, onboarding, corporate events, presentations, job
            meetings ... But, if needed, we can step in to design the gaming experience for your audience.
        `,
    it: `
            Sei tu a scegliere il campo di applicazione delle sessioni di
            gioco. Formazione, onboarding, eventi aziendali,
            presentazioni e job meeting... Ma, se necessario, sarà il
            team di Glickon a disegnare l’esperienza di gioco per il tuo
            pubblico.
        `
  },
  "live-brick-3rd-title": {
    en: "Where and when.<br/>We are open 24/7",
    it: "Dove e quando.<br/>Siamo aperti 24h"
  },
  "live-brick-3rd-description": {
    en: `
            Within our Live platform the algorithms are always active and ready to process the results of a test, a
            challenge, or an interactive quiz in real time. Oh, and your players can connect to the platform from
            whatever device they prefer.
        `,
    it: `
            Se hai accesso alla piattaforma Live gli algoritmi sono
            sempre attivi per elaborare in tempo reale i risultati di un
            test, una challenge, un quiz interattivo. Ah, e i tuoi giocatori
            possono collegarsi alla piattaforma dal device che
            preferiscono.
        `
  },

  // instructions
  "live-instructions-title": {
    en: "Instruction for use",
    it: "Istruzioni per l’uso"
  },
  "live-1st-instruction-title": {
    en: "Put talent into play",
    it: "Metti in gioco il talento"
  },
  "live-1st-instruction-description": {
    en: `
            Five minutes to create a game, 30 seconds for a new question. Live does not need implementations and is 
            100% customizable.
        `,
    it: `
            Cinque minuti per creare un gioco, 30 secondi per una
            nuova domanda. Live non ha bisogno di implementazioni
            ed è 100% personalizzabile.
        `
  },
  "live-2nd-instruction-title": {
    en: "No limit",
    it: "No limit"
  },
  "live-2nd-instruction-description": {
    en: `
            There is no limit to the number of players or to how many and which topics you want to share with the 
            public... even for challenges between teams and in different locations.
        `,
    it: `
            Non c’è limite al numero di giocatori, a quanti e quali
            argomenti vuoi condividere con il pubblico. Anche per sfide
            tra team e in sedi diverse.
        `
  },
  "live-3rd-instruction-title": {
    en: "Real-time data",
    it: "Dati in tempo reale"
  },
  "live-3rd-instruction-description": {
    en: `
            Get game results immediately, with insights on skills, knowledge, opinions gathered and the ranking of your players.
        `,
    it: `
            Ottieni subito i risultati di gioco, disponibili con insight
            su competenze, conoscenze, opinioni raccolte e relativa
            classifica dei giocatori.
        `
  },

  // banner-bottom
  "live-banner-bottom-title": {
    en: "Make it fun and engaging with Glickon!",
    it: "Il lavoro non è un gioco? Fai rispondere (Live) il tuo pubblico"
  },
  "live-banner-bottom-description": {
    en: "Turn HR teams into the hosts of an interactive game show",
    it: "Trasforma i team HR in presentatori di un game-show interattivo"
  }
}

export default translations
