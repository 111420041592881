import styled from "styled-components"
import styleConstants from "../../constants/styleConstants"
import { hexToRgb } from "../../utils"

interface ActionPropsType {
  fullWidth: boolean
  doublePadding: boolean
  disabled: boolean
}

export const Action = styled.div<ActionPropsType>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: ${styleConstants.galanoGrotesqueSemibold};
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: none;
  outline: none;
  background-image: none;
  background-color: transparent;
  border: 0;
  letter-spacing: 0.0625rem;
  border-radius: ${styleConstants.defaultBorderRadius};
  transition: border-color 0.2s ease, color 0.2s ease,
  background-color 0.2s ease, background 0.2s ease;
  width: ${props => (props.fullWidth ? "100%" : "auto")};

  /* sizes */
  &.sm {
    font-size: ${styleConstants.font.size12};
    height: 1.875rem;
    padding: 0 ${props => (props.doublePadding ? "1.75rem" : "0.875rem")};
  }

  &.md {
    font-size: ${styleConstants.font.size14};
    height: 2.5rem;
    padding: 0 ${props => (props.doublePadding ? "3rem" : "1.5rem")};
  }

  &.lg {
    font-size: ${styleConstants.font.size14};
    height: 3rem;
    padding: 0 ${props => (props.doublePadding ? "3rem" : "1.5rem")};
  }

  &.xlg {
    font-size: ${styleConstants.font.size16};
    height: 3.25rem;
    padding: 0 ${props => (props.doublePadding ? "3rem" : "1.5rem")};
  }

  /* variants */
  &.dark {
    border: 0.125rem solid ${styleConstants.blue00};
    color: ${styleConstants.white};
    background-color: ${styleConstants.blue00};
  }

  &.glickon-black {
    border: 0.125rem solid ${styleConstants.glickonBlack};
    color: ${styleConstants.white};
    background-color: ${styleConstants.glickonBlack};
  }

  &.ghost-dark {
    border: 0.125rem solid ${styleConstants.dark03};
    color: ${styleConstants.blue00};
    background-color: transparent;
  }

  &.white {
    border: 0.125rem solid ${styleConstants.white};
    color: ${styleConstants.blue00};
    background-color: ${styleConstants.white};
  }

  &.ghost-white {
    border: 0.125rem solid ${styleConstants.white};
    color: ${styleConstants.white};
    background-color: transparent;

    @media (min-width: ${styleConstants.desktopMinWidth}) {
      border: 0.125rem solid rgba(${hexToRgb(styleConstants.white)}, 0.2);

      &:hover:not([disabled]) {
        border: 0.125rem solid ${styleConstants.white};
      }
    }
  }

  &.blue-glickon {
    border: 0;
    color: ${styleConstants.white};
    background: linear-gradient(
      to left,
      ${styleConstants.blueGlickon},
      ${styleConstants.blueGlickon} 49%,
      ${styleConstants.blueGlickon}
    );

    @media (min-width: ${styleConstants.desktopMinWidth}) {
      &:hover:not([disabled]):not(.no-hover) {
        background: ${styleConstants.glickonGradient};
      }
    }
  }

  &.lightblue-glickon {
    border: 0.125rem solid ${styleConstants.lightBlueGlickon};
    color: ${styleConstants.white};
    background-color: ${styleConstants.lightBlueGlickon};
  }

  &.gradient-glickon {
    border: 0;
    color: ${styleConstants.white};
    background: ${styleConstants.glickonGradient};
  }

  &.new-gradient {
    border: 0;
    color: ${styleConstants.white};
    background: ${styleConstants.newGradientColors};
  }

  &.good-feedback {
    border: 0.125rem solid ${styleConstants.goodFeedback};
    color: ${styleConstants.white};
    background: ${styleConstants.goodFeedback};
  }

  &.bad-feedback {
    border: 0.125rem solid ${styleConstants.badFeedback};
    color: ${styleConstants.white};
    background: ${styleConstants.badFeedback};
  }

  &.black-glickon {
    color: ${styleConstants.white};
    background: ${styleConstants.blackGlickon};
  }

  &[disabled] {
    border-color: ${styleConstants.backgroundMediumGray};
    background: ${styleConstants.backgroundMediumGray};
    color: ${styleConstants.white};
    cursor: not-allowed;
  }

  &.hoverable-blue-glickon:hover:not([disabled]) {
    background: ${styleConstants.blueGlickon};
    border: 0.125rem solid ${styleConstants.blueGlickon};
    color: ${styleConstants.white};
  }

  &.hoverable-lightblue-glickon:hover:not([disabled]) {
    background: ${styleConstants.lightBlueGlickon};
    border: 0.125rem solid ${styleConstants.lightBlueGlickon};
    color: ${styleConstants.white};
  }

  &.hoverable-dark:hover:not([disabled]) {
    background: ${styleConstants.blue00};
    border: 0.125rem solid ${styleConstants.blue00};
    color: ${styleConstants.white};
  }

  &.hoverable-glickon-light-black:hover:not([disapbled]) {
    background: ${styleConstants.glickonLightBlack};
    border: 0.125rem solid ${styleConstants.glickonLightBlack};
    color: ${styleConstants.white};
  }

  i {
    position: relative;
    top: 0.05rem;
  }

  & + & {
    margin-top: 1rem;
  }

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    & + & {
      margin-top: 0;
      margin-left: 1rem;
    }
  }
`
