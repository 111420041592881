import styled from "styled-components"
import {NavLink as Link} from "react-router-dom"

export const A = styled.a`
  &:link,
  &:visited,
  &:active {
    color: currentColor;
    text-decoration: none;
  }

  &.underline {
    text-decoration: underline;
  }
`

export const RouterLink = styled(Link)`
  &:link,
  &:visited,
  &:active {
    color: currentColor;
    text-decoration: none;
  }

  &.underline {
    text-decoration: underline;
  }
`
