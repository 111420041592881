import React from "react"
import { fix100Vh, passiveEvents } from "../utils"
import throttle from "lodash/throttle"

const throttledFix100Vh = throttle(fix100Vh, 100)

const useViewportFix = (): void => {
  React.useLayoutEffect(() => {
    const options = passiveEvents.hasSupport
      ? {
        capture: false,
        passive: true
      }
      : false
    throttledFix100Vh()
    window.addEventListener("resize", throttledFix100Vh, options)
    window.addEventListener("scroll", throttledFix100Vh, options)

    return () => {
      window.removeEventListener("resize", throttledFix100Vh, options)
      window.removeEventListener("scroll", throttledFix100Vh, options)
      throttledFix100Vh.cancel()
    }
  }, [])
}

export default useViewportFix
