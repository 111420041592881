import React, {useEffect} from "react"
import {RouteComponentProps, withRouter, Redirect} from "react-router"
import useCompanyChallengeExternalPage from "../../../hooks/useCompanyChallengeExternalPage"
import useTranslation from "../../../hooks/useTranslation"
import LoaderFullScreen from "../../../commonComponents/loader-full-screen"
import {generateLocalizedPath} from "../../../utils"
import {
  getCustomStyle,
  hasCustomStyle,
  getEmptyCustomStyle
} from "../../../customStyle"
import {notFoundKey} from "../../../constants/pathKeys"
import ExternalPageShell from "../../../commonComponents/external-page-shell"
import {
  CompanyChallengeDetailsDivider as DetailsDivider,
  CompanyChallengeDescriptionText as DescriptionText,
  LoginFormWrapper
} from "./style"
import {
  ExternalPageLayoutDetailsTopContent as DetailsTopContent,
  ExternalPageLayoutDetailsWrapper as DetailsWrapper,
  ExternalPageLayoutInnerWrapper as DetailsInnerWrapper
} from "../../../commonComponents/external-page-layout/style"
import {
  challengePixelMap,
  runFacebookPixel
} from "../../../constants/pixelCodes"
import Informations from "../components/company-challenge-informations"
import ChallengeLoginSignup from "../components/company-challenge-login-signup"

interface Params {
  hash: string
}

interface Props extends RouteComponentProps<Params> {}

const CompanyChallenge: React.FC<Props> = ({
  match: {
    params: {hash}
  }
}: Props) => {
  const {
    companyChallenge,
    hasError: redirectToNotFound,
    redirectPath,
    corePath
  } = useCompanyChallengeExternalPage(hash)

  const [, locale] = useTranslation()

  useEffect(() => {
    if (hash && challengePixelMap[hash]) {
      const pixelId = challengePixelMap[hash]
      runFacebookPixel(pixelId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (redirectPath) return <Redirect to={redirectPath} />

  if (redirectToNotFound) {
    return <Redirect to={`${generateLocalizedPath(notFoundKey, locale)}`} />
  }

  if (!companyChallenge) return <LoaderFullScreen />

  const {
    isPublic,
    backgroundUrl,
    description,
    nameForExternalPage,
    shortDescriptionForExternalPage,
    companyCareersName
  } = companyChallenge
  const {
    customBackgroundColor,
    customBorderColor,
    customSubtitleColor,
    customPrimaryTextColor,
    customDescriptionColor,
    customLightBackgroundColor,
    customButtonColor,
    customButtonHoverColor,
    customButtonTextColor,
    customBodyBackgroundColor,
    customFooterButtonColor
  } = isPublic ? getEmptyCustomStyle() : getCustomStyle(companyCareersName)
  console.log("companyCareersName", companyCareersName)

  return (
    <ExternalPageShell
      nextUrl={corePath}
      isPublic={isPublic}
      companyCareersName={companyCareersName}
      withTopbar={!hasCustomStyle(companyCareersName) && isPublic}
      carouselUrls={[backgroundUrl]}
      customFooterColor={customBodyBackgroundColor}
      customBodyBackgroundColor={customBodyBackgroundColor}
      hideFooter={!isPublic && hasCustomStyle(companyCareersName)}
      customFooterButtonColor={customFooterButtonColor}
      customButtonColor={customButtonColor}
      customPrimaryTextColor={customPrimaryTextColor}
    >
      <DetailsWrapper
        customColor={isPublic ? undefined : customBackgroundColor}
      >
        <DetailsInnerWrapper
          className="row"
          customColor={isPublic ? undefined : customLightBackgroundColor}
        >
          <DetailsInnerWrapper
            className="column"
            customColor={isPublic ? undefined : customLightBackgroundColor}
          >
            <DetailsTopContent
              customColor={customBackgroundColor}
              customBorderColor={customBorderColor}
            >
              <Informations
                companyChallenge={companyChallenge}
                isPublic={isPublic}
                customSubtitleColor={customSubtitleColor}
                customTitleColor={customPrimaryTextColor}
              ></Informations>

              <DetailsDivider customColor={customBorderColor} />

              <div>
                <DescriptionText
                  dangerouslySetInnerHTML={{__html: description}}
                  customColor={customDescriptionColor}
                ></DescriptionText>
              </div>
            </DetailsTopContent>
          </DetailsInnerWrapper>
        </DetailsInnerWrapper>
      </DetailsWrapper>

      <DetailsWrapper
        customColor={isPublic ? undefined : customBackgroundColor}
      >
        <DetailsInnerWrapper
          className="row"
          customColor={isPublic ? undefined : customBackgroundColor}
        >
          <LoginFormWrapper
            customBodyBackgroundColor={customBodyBackgroundColor}
          >
            <ChallengeLoginSignup
              challengePath={corePath}
              owner={isPublic ? undefined : companyCareersName}
              companyCareersName={companyCareersName}
              customTitle={
                nameForExternalPage ? nameForExternalPage : undefined
              }
              customDescription={
                shortDescriptionForExternalPage
                  ? shortDescriptionForExternalPage
                  : undefined
              }
              customBodyBackgroundColor={customBackgroundColor}
              customPrimaryTextColor={customPrimaryTextColor}
              customButtonColor={customButtonColor}
              customButtonHoverColor={customButtonHoverColor}
              customButtonTextColor={customButtonTextColor}
            />
          </LoginFormWrapper>
        </DetailsInnerWrapper>
      </DetailsWrapper>
    </ExternalPageShell>
  )
}

export default withRouter<Props, React.FC<Props>>(CompanyChallenge)
