import styled from "styled-components"
import SimpleImage from "../../image"
import styleConstants from "../../../constants/styleConstants"
import {hexToRgb, getImageUrl} from "../../../utils"
import {FullHeight} from "../styles"

const SimpleList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

export const NavigationWrapper = styled(FullHeight)`
  padding: 0 0.9rem;
`

interface LogoPropsType {
  shrink?: boolean
}

export const Logo = styled(SimpleImage)<LogoPropsType>`
  max-width: 100%;
  max-height: 2rem;
  padding-right: 1.3rem;
  margin-top: ${props => (props.shrink ? 0 : "0.3125rem")};
`

export const RightNavigationItem = styled.li`
  padding: 1rem 2.5rem;
  font-family: ${styleConstants.galanoGrotesqueSemibold};
  font-size: ${styleConstants.font.size14};
  color: ${styleConstants.white};
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
`

export const Backdrop = styled.li<LogoPropsType>`
  z-index: -1;
  pointer-events: none;
  position: fixed;
  left: 0;
  right: 0;
  top: ${props =>
    props.shrink
      ? styleConstants.topBarSmallHeight
      : styleConstants.topBarHeight};
  bottom: 0;
  opacity: 0;
  background-color: rgba(${hexToRgb(styleConstants.black)}, 0.3);
  transition: opacity 0.5s ease;
`

export const SubNav = styled.div`
  pointer-events: none;
  cursor: default;
  padding: 2rem 1.25rem;
  font-family: ${styleConstants.calibre};
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  opacity: 0;
  line-height: normal;
  background-color: ${styleConstants.white};
  border-radius: 0 0 0.25rem 0.25rem;
  color: ${styleConstants.blue00};
  transition: opacity 0.5s ease;
`

interface NavItemPropsType {
  isActive?: boolean
  withSubmenu?: boolean
  isWorkWithUs?: boolean
  shrink?: boolean
}

export const NavItem = styled.li<NavItemPropsType>`
  position: ${({isWorkWithUs}) => (isWorkWithUs ? "relative" : "static")};
  ${({isWorkWithUs}) => (isWorkWithUs ? "top: 0.0625rem;" : "")}

  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 0.85rem;
  font-family: ${({isWorkWithUs}) =>
    isWorkWithUs ? styleConstants.calibreSemibold : styleConstants.calibre};
  font-size: ${styleConstants.font.size18};
  color: rgba(
    ${hexToRgb(styleConstants.white)},
    ${({isActive, isWorkWithUs}) => (isActive || isWorkWithUs ? 1 : 0.6)}
  );

  ${({isWorkWithUs, shrink}) =>
    isWorkWithUs
      ? `&::after {
      transition: top 0.3s ease-out;
      content: "";
      position: absolute;
      top: ${shrink ? "2.55rem" : "3.55rem"};
      left: 0;
      right: 0;
      bottom: 0;
      background-image: url("${getImageUrl(
        "top-navigation/join-us-underline.svg"
      )}");
      background-repeat: no-repeat;
      background-position-x: 56%;
      background-size: 90%;
    }`
      : ""}

  a {
    cursor: pointer;
    text-decoration: none;
  }

  &:hover {
    color: ${styleConstants.white};

    .subnav {
      pointer-events: all;
      opacity: 1;
    }

    ~ ${Backdrop} {
      opacity: ${props => (props.withSubmenu ? 1 : 0)};
    }
  }
`

export const Navigation = styled(SimpleList)<LogoPropsType>`
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  height: 100%;
  flex: 1;

  /* stylelint-disable no-descending-specificity */
  ${NavItem} {
    line-height: ${props =>
      props.shrink
        ? styleConstants.topBarSmallHeight
        : styleConstants.topBarHeight};
  }
  /* stylelint-enable */
`

export const Actions = styled(Navigation)`
  flex: none;
  margin-left: auto;
`

export const SubNavTitle = styled.div`
  text-transform: uppercase;
  font-family: ${styleConstants.galanoGrotesqueSemibold};
  font-size: ${styleConstants.font.size16};
  color: ${styleConstants.blue00};
  padding-bottom: 0.5rem;
`

export const SubNavNavigation = styled(SimpleList)``

interface SubNavItemPropsType {
  last?: boolean
}

export const SubNavItem = styled.li<SubNavItemPropsType>`
  display: flex;
  align-items: center;
  padding: 0.8rem 0;
  border-bottom: ${props => (props.last ? 0 : 0.0625)}rem solid
    ${styleConstants.cardBorder};
  font-family: ${styleConstants.calibre};
  font-size: ${styleConstants.font.size18};
  color: ${styleConstants.dark02};

  a {
    cursor: pointer;

    &:hover {
      color: ${styleConstants.blueGlickon};
    }
  }
`

export const ThumbnailTitle = styled.div`
  text-transform: uppercase;
  font-family: ${styleConstants.galanoGrotesqueSemibold};
  font-size: ${styleConstants.font.size14};
  color: ${styleConstants.blue00};
  padding-top: 1rem;
  padding-bottom: 0.5rem;
`

export const ThumbnailDescription = styled.div`
  font-family: ${styleConstants.calibre};
  font-size: ${styleConstants.font.size16};
  color: ${styleConstants.dark02};
`
