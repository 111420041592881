import styled from "styled-components"
import styleConstants from "../../constants/styleConstants"
import SimpleImage from "../image"
import { getImageUrl } from "../../utils"

export const Title = styled.div`
  padding: 1rem 0;
  font-family: ${styleConstants.galanoGrotesqueSemibold};
  font-size: ${styleConstants.font.size20};
  color: #0b273d;
`

export const Description = styled.div`
  font-family: ${styleConstants.calibre};
  font-size: ${styleConstants.font.size18};
  color: #444444;

  p {
    margin-top: 0;
  }
`

export const Card = styled.div`
  width: 100%;
`

export const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 1.75rem 1.125rem;
  border: 0.0625rem solid ${styleConstants.cardBorder};
  background-color: ${styleConstants.white};
  width: 100%;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    padding: 2rem 1.75rem;
  }

  ${Card} + ${Card} {
    margin-top: 1rem;
  }
`

export const Image = styled(SimpleImage)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: calc(100vh - 3.75rem);
`

export const AspectRatioBox = styled.div<{ withHover?: boolean }>`
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;

  ${props =>
    props.withHover
      ? `
          &:after {
            pointer-events: none;
            transition: all 0.25s ease-in-out;
            content: "";
            background-color: rgba(0, 0, 0, 0.8);
            background-image: url('${getImageUrl("common/preview-icon.svg")}');
            background-repeat: no-repeat;
            background-position: center;
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
          }
        
          &:hover {
            &:after {
              opacity: 0.7;
            }
          }
        `
      : ""}
`

export const PseudoModal = styled.div<{ isOpen?: boolean }>`
  @media (min-width: ${styleConstants.desktopMinWidth}) {
    ${props =>
    props.isOpen
      ? `
            background: rgba(0, 0, 0, 0.8);
            position: fixed;
            top: 3.75rem;
            left: 0;
            bottom: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 9;
            cursor: pointer;
          `
      : ""}
  }
`

export const InnerPseudoModal = styled.div<{ isOpen?: boolean }>`
  @media (min-width: ${styleConstants.desktopMinWidth}) {
    ${props => (props.isOpen ? "width: 77%;" : "")}
  }
`
