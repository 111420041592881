import styled from "styled-components"
import SimpleImage from "../../image"
import styleConstants from "../../../constants/styleConstants"
import {FullHeight} from "../styles"
import {hexToRgb} from "../../../utils"
import CustomLink from "../../link"

export const TopBar = styled(FullHeight)`
  padding-left: 1rem;
`

export const Logo = styled(SimpleImage)`
  cursor: pointer;
  height: 2rem;
`

interface HamburgerLinePropsType {
  rotation: boolean
}

const HamburgerLine = styled.div<HamburgerLinePropsType>`
  background-color: ${styleConstants.white};
  width: 1.6rem;
  height: 0.125rem;
  position: absolute;
  left: 1rem;
  transform-origin: center center;
`

export const HamburgherLineTop = styled(HamburgerLine)`
  top: ${props => (props.rotation ? 1.7 : 1.35)}rem;
  transform: ${props => (props.rotation ? "rotateZ(45deg)" : "none")};
  transition: top ${props => (props.rotation ? "0.2s" : "0.2s 0.2s")} ease,
    transform ${props => (props.rotation ? "0.2s 0.2s" : "0.2s")} ease;
`

export const HamburgherLineBottom = styled(HamburgerLine)`
  bottom: ${props => (props.rotation ? 1.7 : 1.35)}rem;
  transform: ${props => (props.rotation ? "rotateZ(-45deg)" : "none")};
  transition: bottom ${props => (props.rotation ? "0.2s" : "0.2s 0.2s")} ease,
    transform ${props => (props.rotation ? "0.2s 0.2s" : "0.2s")} ease;
`

export const Hamburgher = styled.div`
  color: ${styleConstants.white};
  width: ${styleConstants.topBarHeightMobile};
  position: relative;
  height: 100%;
`

interface ExtendedPanelPropsType {
  show: boolean
}

export const ExtendedPanel = styled.div<ExtendedPanelPropsType>`
  position: fixed;
  left: 0;
  right: 0;
  top: ${styleConstants.topBarHeightMobile};
  background-color: ${styleConstants.blue00};
  display: flex;
  height: ${`calc(100vh - ${styleConstants.topBarHeightMobile})`}; /*fallback*/
  height: ${`calc((var(--realVh, 1vh) * 100) - ${styleConstants.topBarHeightMobile})`};
  overflow-x: hidden;
  overflow-y: auto;
  transform-origin: top;
  transform: scaleY(${props => (props.show ? 1 : 0)});
  opacity: ${props => (props.show ? 1 : 0)};
  transition: transform 0.4s ease, opacity 0.4s ease, top 0.2s ease,
    height 0.2s ease;
  padding-bottom: 1rem;
`

const SimpleList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

export const Navigation = styled(SimpleList)`
  padding: 0 1rem;
`

interface NavItemPropsType {
  isActive?: boolean
  noBorder?: boolean
}

export const ProductsContainer = styled.div`
  position: relative;
  cursor: pointer;
`

export const ProductsContainerToggle = styled.div<{isExpanded: boolean}>`
  position: absolute;
  right: 0;
  top: 1rem;
  color: #fff;
  transition: all 0.2s linear;
  transform: rotate(${({isExpanded}) => (isExpanded ? "45deg" : 0)});
`

export const NavItem = styled.li<NavItemPropsType>`
  display: flex;
  flex-direction: column;
  padding: 0.75rem 0;
  font-size: ${styleConstants.font.size18};
  color: rgba(${hexToRgb(styleConstants.white)}, 1);
  font-family: ${props =>
    props.isActive ? styleConstants.calibreSemibold : styleConstants.calibre};
  border-bottom: ${props => (props.noBorder ? 0 : 0.0625)}rem solid
    rgba(${hexToRgb(styleConstants.dark04)}, 0.2);

  a {
    display: block;
    width: 100%;
  }
`

export const SubNavNavigation = styled(SimpleList)<{expanded: boolean}>`
  padding: ${({expanded}) => (expanded ? "1rem 0 0.5rem" : 0)};
  overflow: hidden;
  max-height: ${({expanded}) => (expanded ? "12rem" : 0)};

  opacity: ${({expanded}) => (expanded ? 1 : 0)};
  transition: all 0.4s ease;
`

interface SubNavItemPropsType {
  last?: boolean
}

export const SubNavItem = styled.li<SubNavItemPropsType>`
  display: flex;
  padding-bottom: ${props => (props.last ? 0 : "1rem")};
  font-family: ${styleConstants.calibre};
  font-size: ${styleConstants.font.size18};
  color: rgba(${hexToRgb(styleConstants.white)}, 0.6);
`

export const FullWidthLink = styled(CustomLink)`
  &,
  & > * {
    width: 100%;
  }

  &:first-of-type {
    margin-top: 1.5rem;
  }

  &:nth-of-type(2n) {
    margin-top: 0.5rem;
  }

  &.request-a-demo {
    margin-top: 1rem;
  }
`

export const Info = styled.div`
  margin-top: 1.27rem;
  padding: 0 1rem;
  line-height: 1.83rem;
  font-family: ${styleConstants.calibre};
  font-size: ${styleConstants.font.size16};
  color: ${styleConstants.white};
`
