import React from "react"
import {Background} from "./styles"
import useScrollPosition from "../../hooks/useScrollPosition"
import styleConstants from "../../constants/styleConstants"
import Spacer from "../spacer"
import {RouteComponentProps, withRouter} from "react-router"
import Desktop from "./desktop"
import Mobile from "./mobile"
import {
  // homepageKey,
  loginKey,
  signupKey,
  requestADemoKey
} from "../../constants/pathKeys"

type PropsType = RouteComponentProps & {
  pathKey: string
}

const TopNavigation: React.FC<PropsType> = (props: PropsType) => {
  const {y} = useScrollPosition()

  const {pathKey} = props
  const shrink: boolean = y > styleConstants.thresholdPageYOffeset

  const isLoginPage: boolean = React.useMemo<boolean>(() => {
    return pathKey === loginKey
  }, [pathKey])

  const isSignupPage: boolean = React.useMemo<boolean>(() => {
    return pathKey === signupKey
  }, [pathKey])

  const isRequestADemoPage: boolean = React.useMemo<boolean>(() => {
    return pathKey === requestADemoKey
  }, [pathKey])

  const isActive = React.useCallback(
    (pathKeys: string[]): boolean => {
      return pathKeys.includes(pathKey)
    },
    [pathKey]
  )

  return (
    <>
      <Background shrink={shrink}>
        <Mobile
          pathKey={pathKey}
          isActive={isActive}
          isLoginPage={isLoginPage}
          isSignupPage={isSignupPage}
          isRequestADemoPage={isRequestADemoPage}
        />
        <Desktop
          isLoginPage={isLoginPage}
          isSignupPage={isSignupPage}
          isActive={isActive}
          shrink={shrink}
        />
      </Background>
      <Spacer color={styleConstants.blue00} space={3.75} spaceDesktop={5.5} />
    </>
  )
}

export default withRouter<PropsType, React.FC<PropsType>>(TopNavigation)
