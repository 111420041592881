import {TranslationType} from "../types"

const translations: TranslationType = {
  // Header
  "login-header-title": {
    en: "Make work more human and productive",
    it: "Un'esperienza unica di lavoro"
  },
  "login-header-mobile-title": {
    en: "Make work more human and productive",
    it: "Un'esperienza unica <br/> di lavoro"
  },
  "login-header-description": {
    en:
      "Glickon make it easy for anyone to show off their skills, grow in their role and <br/> live personalized and fun work experiences",
    it:
      "Glickon rende semplice e divertente per ciascuno vivere esperienze professionali in linea con le proprie competenze e interessi e essere ispirati dal proprio lavoro"
  },
  "login-with-facebook": {
    en: "Login with Facebook",
    it: "Accedi con Facebook"
  },
  "login-with-linkedin": {
    en: "Login with Linkedin",
    it: "Accedi con Linkedin"
  },
  "login-with-google": {
    en: "Login with Google",
    it: "Accedi con Google"
  },

  // Cards
  // Candidate
  "login-card-candidate-tagline": {
    en: "Candidate Experience",
    it: "Candidate Experience"
  },
  "login-card-candidate-title": {
    en: "For Candidates",
    it: "Per Candidati"
  },
  "login-card-candidate-description": {
    en: "Get matched with <br/> a job you love",
    it: "Vivi esperienze di lavoro indimenticabili"
  },
  "login-card-candidate-action-text": {
    en: "Log in",
    it: "Accedi"
  },
  "login-card-candidate-help-text": {
    en: "Don't have an account?",
    it: "Non hai un profilo?"
  },
  "login-card-candidate-help-action-text": {
    en: "Sign up now",
    it: "Registrati ora"
  },

  // Employee
  "login-card-employee-tagline": {
    en: "Employee Experience",
    it: "Employee Experience"
  },
  "login-card-employee-title": {
    en: "For Employees",
    it: "Per Dipendenti"
  },
  "login-card-employee-description": {
    en:
      "Unleash talent potential with <b>Glow</b> <br/> <b>Sign up</b> or <b>Log in</b> to your account",
    it:
      "Fai brillare il talento con <b>Glow</b> <br/> <b>Registrati</b> o <b>Accedi</b> se hai già un account"
  },
  "login-card-employee-action-text": {
    en: "Log in",
    it: "Accedi"
  },
  "login-card-employee-help-text": {
    en: "Don't have an account?",
    it: "Non hai un profilo aziendale?"
  },
  "login-card-employee-help-action-text": {
    en: "Apply now",
    it: "Richiedi accesso"
  },

  // Corporate
  "login-card-corporate-tagline": {
    en: "HR Experience",
    it: "HR Experience"
  },
  "login-card-corporate-title": {
    en: "For Employers",
    it: "Per Aziende"
  },
  "login-card-corporate-description": {
    en: "Turn People Experience into a competitive advantage",
    it: "Metti le persone al centro della tua strategia di business"
  },
  "login-card-corporate-action-text": {
    en: "Log in",
    it: "Accedi"
  },
  "login-card-corporate-help-text": {
    en: "Not registered as a company?",
    it: "Non sei registrato come azienda?"
  },
  "login-card-corporate-help-action-text": {
    en: "Request a demo",
    it: "Richiedi demo"
  },
  "login-social-subtitle": {
    en: "Connect with:",
    it: "Scegli la tua modalità preferita:"
  },
  "access-form-subtitle": {
    en: "Sign up with Email:",
    it: "Utilizza la tua email per accedere:"
  },
  "sso-ms": {
    en: "Log in with Microsoft",
    it: "Accedi con microsoft"
  }
}

export default translations
