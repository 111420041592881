import * as React from "react"
import {
  Card,
  Wrapper,
  Description,
  Image,
  Title,
  AspectRatioBox,
  PseudoModal,
  InnerPseudoModal,
} from "./styles"
import useLockBodyScroll from "../../hooks/useLockBodyScroll"
import useIsDesktopBreakpoint from "../../hooks/useIsDesktopBreakpoint"
import { Media } from "../../types/media"

type PropsType = {
  id: string
  images: Media[]
}

function ImagesList(props: PropsType) {
  const { images, id } = props

  return (
    <Wrapper className="column desktop-12 mobile-12" id={id}>
      {images.map((image: Media, index: number) => {
        const { title, description, url } = image
        return (
          <Card
            id={`${id}-image-${index}`}
            className={`${id}-image`}
            // eslint-disable-next-line react/no-array-index-key
            key={`${id}-image-${index}`}
          >
            <div className="row">
              <div className="column mobile-12 desktop-6">
                <FullScreenableImage src={url} />
              </div>
              <div className="column mobile-12 desktop-6">
                <Title>{title}</Title>
                <Description
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </div>
            </div>
          </Card>
        )
      })}
    </Wrapper>
  )
}

type FullScreenableImagePropsType = {
  src: string
}

const FullScreenableImage = (props: FullScreenableImagePropsType) => {
  const isDesktopBreakpoint = useIsDesktopBreakpoint()
  const [isPseudoModalOpen, setIsPseudoModalOpen] = React.useState(false)
  // @ts-ignore
  useLockBodyScroll(isPseudoModalOpen && isDesktopBreakpoint)

  const safeSetIsPseudoModalOpen = (value: any) => {
    if (value === isPseudoModalOpen) return
    if (value === true && !isDesktopBreakpoint) return

    setIsPseudoModalOpen(value)
  }

  const imageDynamicStyle = isDesktopBreakpoint ? { cursor: "pointer" } : {}

  return (
    <PseudoModal
      isOpen={isPseudoModalOpen}
      onClick={() => safeSetIsPseudoModalOpen(false)}
    >
      <InnerPseudoModal isOpen={isPseudoModalOpen}>
        <AspectRatioBox withHover={!isPseudoModalOpen && isDesktopBreakpoint}>
          <Image
            style={imageDynamicStyle}
            onClick={() => safeSetIsPseudoModalOpen(true)}
            src={props.src}
            fallbackSrc="placeholders/placeholder-image.png"
          />
        </AspectRatioBox>
      </InnerPseudoModal>
    </PseudoModal>
  )
}

export default ImagesList
