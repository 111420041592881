import styled from "styled-components"
import styleConstants from "../../constants/styleConstants"

export const LostFormAction = styled.div<{
  customTextColor?: string
}>`
  text-align: left;
  font-family: ${styleConstants.calibre};
  font-size: ${styleConstants.font.size16};
  color: ${props =>
    props.customTextColor ? props.customTextColor : styleConstants.dark01};
  text-decoration: underline;
  margin-bottom: 2rem;
  cursor: pointer;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    margin-bottom: 1.4rem;

    &.extra-margin-bottom {
      margin-bottom: 1.5rem;
    }
  }
`

export const ChangeForm = styled.div<{
  customTextColor?: string
}>`
  text-align: center;
  font-family: ${styleConstants.calibre};
  font-size: ${styleConstants.font.size16};
  color: ${props =>
    props.customTextColor ? props.customTextColor : styleConstants.dark02};
  margin-top: 1.5rem;

  .action {
    cursor: pointer;
    font-family: ${styleConstants.calibreSemibold};
    color: ${styleConstants.dark01};
  }
`

export const ResendEmail = styled.div<{
  customTextColor?: string
}>`
  font-family: ${styleConstants.calibre};
  font-size: ${styleConstants.font.size16};
  color: ${props =>
    props.customTextColor ? props.customTextColor : styleConstants.dark02};
  margin-top: 0.1rem;
  margin-bottom: 0.7rem;

  .action {
    cursor: pointer;
    font-family: ${styleConstants.calibreSemibold};
    color: ${styleConstants.dark01};
  }
`

export const CheckboxContainer = styled.div`
  padding-top: 0.3rem;
`

export const ActionButton = styled.button`
  &.extra-margin-top {
    margin-top: 1rem;
  }
`

export const ActionSpan = styled.span`
  &.blue-glickon {
    color: ${styleConstants.blueGlickon};
  }
`

export const ResendEmailAction = styled.div`
  display: inline;
`

export const Divider = styled.div`
  margin: 1rem 0;
  height: 0.0625rem;
  background-color: ${styleConstants.cardBorder};
`

export const StyledLabel = styled.div<{
  customTextColor?: string
}>`
  padding-top: 0.1rem;
  padding-bottom: 0.6rem;
  font-family: ${styleConstants.calibre};
  font-size: ${styleConstants.font.size14};
  color: ${props =>
    props.customTextColor ? props.customTextColor : styleConstants.dark01};

  a {
    color: ${props =>
      props.customTextColor ? props.customTextColor : styleConstants.dark01};
    text-decoration: underline;
  }
`

export const CustomAction = styled.div<{
  backgroundColor?: string
  textColor?: string
  disabled: boolean
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: ${styleConstants.galanoGrotesqueSemibold};
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: none;
  outline: none;
  background-image: none;
  background-color: ${props => props.backgroundColor};
  border: 0;
  letter-spacing: 0.0625rem;
  border-radius: ${styleConstants.defaultBorderRadius};
  transition: border-color 0.2s ease, color 0.2s ease,
    background-color 0.2s ease, background 0.2s ease;
  width: 100%;
  color: ${props => props.textColor};
  height: 3rem;
  &[disabled] {
    border-color: ${styleConstants.backgroundMediumGray};
    background: ${styleConstants.backgroundMediumGray};
    color: ${styleConstants.white};
    cursor: not-allowed;
  }
`
