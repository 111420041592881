import React, {useEffect} from "react"
import {Formik, FormikProps} from "formik"
import {ActionButton} from "./style"
import NegativeFeedback from "../../../../commonComponents/negative-feedback"
import SimpleAction from "../../../../commonComponents/action"
import Translate from "../../../../commonComponents/translate"
import FormikMobilePhoneInput from "../../../../commonComponents/formik-mobile-phone-input"

declare var STATIC_URL: string

const phoneNumberMinLenght = 9
const phoneNumberMaxLenght = 15

const getFormSubmitability = (values: any, errors: any): boolean => {
  const phoneNumber = values.phoneNumber
  return (
    phoneNumber.length >= phoneNumberMinLenght &&
    phoneNumber.length <= phoneNumberMaxLenght &&
    !errors.phoneNumber
  )
}

const SetPhoneNumberForm: React.FC<any> = ({submitHandler, errorText}: any) => {
  useEffect(() => {
    const telInputStyles = [
      {
        id: "intl-input-css",
        href: `${STATIC_URL}candidate/external/css/intl-tel-input/style.css`
      },
      {
        id: "intl-input-css-sprites",
        href: `${STATIC_URL}candidate/external/css/intl-tel-input/sprites.css`
      }
    ]

    telInputStyles.forEach(({id, href}) => {
      const styleTag = document.createElement("link")
      styleTag.rel = "stylesheet"
      styleTag.type = "text/css"
      styleTag.href = href
      styleTag.id = id

      document.head.appendChild(styleTag)
    })

    return () => {
      telInputStyles.forEach(({id}) => {
        const styleTag = document.getElementById(id)
        if (styleTag) styleTag.remove()
      })
    }
  }, [])

  return (
    <Formik initialValues={{phoneNumber: ""}} onSubmit={submitHandler}>
      {({
        handleSubmit,
        handleChange,
        values,
        isSubmitting,
        errors
      }: FormikProps<any>) => {
        const disabled = isSubmitting || !getFormSubmitability(values, errors)

        return (
          <form onSubmit={handleSubmit}>
            {errorText && <NegativeFeedback>{errorText}</NegativeFeedback>}

            {errors.phoneNumber && (
              <NegativeFeedback>{errors.phoneNumber}</NegativeFeedback>
            )}

            <FormikMobilePhoneInput name="phoneNumber" />

            <ActionButton
              disabled={disabled}
              className="button-unstyled full-width"
              type="submit"
            >
              <SimpleAction
                disabled={disabled}
                fullWidth
                size={SimpleAction.sizes.large}
                variant={SimpleAction.variants.dark}
              >
                <Translate label="tfa-set-phone-number-form-action" />
              </SimpleAction>
            </ActionButton>
          </form>
        )
      }}
    </Formik>
  )
}

export default SetPhoneNumberForm
