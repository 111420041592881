import styled from "styled-components"
import styleConstants from "../../../../constants/styleConstants"
import SimpleImage from "../../../../commonComponents/image"

export const CompanyChallengeInformationsCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    position: relative;
    flex-direction: row;
  }
`

export const CompanyChallengeInformationsLogo = styled(SimpleImage).attrs({
  backgroundCover: true
})`
  height: 5rem;
  width: 5rem;
  min-width: 5rem;
  max-width: 5rem;
  margin-top: -3.5rem;
  border: 1px solid ${styleConstants.cardBorder};

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    border: none;
    height: 8rem;
    width: 8rem;
    min-width: 8rem;
    max-width: 8rem;
    margin: 0;
    border: none;
  }
`

export const CompanyChallengeInformationsContent = styled.div`
  line-height: 1.5;
  padding-top: 1rem;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    padding-top: 0;
    padding-left: 2.5rem;
  }
`

// eslint-disable-next-line no-unexpected-multiline
export const CompanyChallengeInformationsCompanyInfo = styled.div<{
  customColor: string | undefined
}>`
  font-family: ${styleConstants.galanoGrotesqueMedium};
  font-size: ${styleConstants.font.size14};
  color: ${({customColor}) => customColor || styleConstants.dark02};

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    font-size: ${styleConstants.font.size16};
  }
`

// eslint-disable-next-line no-unexpected-multiline
export const CompanyChallengeInformationsName = styled.div<{
  customColor: string | undefined
}>`
  line-height: 1.2;
  font-family: ${styleConstants.galanoGrotesqueSemibold};
  font-size: ${styleConstants.font.size20};
  color: ${({customColor}) => customColor || styleConstants.blue00};

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    line-height: 1.5;
    font-size: ${styleConstants.font.size24};
  }
`

export const CompanyChallengeInformationsDescription = styled.div`
  font-family: ${styleConstants.calibre};
  font-size: ${styleConstants.font.size16};
  color: ${styleConstants.dark01};

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    font-size: ${styleConstants.font.size18};
  }
`

export const CompanyChallengeInformationsActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  font-family: ${styleConstants.calibreSemibold};
  font-size: ${styleConstants.font.size16};
  color: ${styleConstants.dark02};

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    flex-direction: row;
    align-items: flex-end;
    width: calc(100% - 11rem);
    font-size: ${styleConstants.font.size18};
    position: absolute;
    bottom: 0;
  }
`

export const CompanyChallengeInformationsShareLinksWrapper = styled.div`
  display: none;
  height: 2rem;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    display: flex;
    font-family: ${styleConstants.calibreSemibold};
    font-size: ${styleConstants.font.size16};
    color: ${styleConstants.dark02};
    align-items: center;
    margin-top: 0;
    font-size: ${styleConstants.font.size18};
  }
`
