/* eslint-disable space-before-function-paren */
import React, {useEffect, useState} from "react"
import * as Yup from "yup"
import {
  TranslateFunctionType,
  FullFledgedLoginType,
  LoginRequiringTwoFactorAuthenticationType
} from "../../types"
import {homepageKey} from "../../constants/pathKeys"
import AccessForm, {
  AccessFormValuesType,
  AccessFormInputIds,
  AccessFormType
} from "../access-form"
import useTranslation from "../../hooks/useTranslation"
import {
  Container,
  Title,
  Description,
  FormWrapper,
  Divider,
  MSSSOWrapper
} from "./style"
import HeaderLoginSignup from "../header-login-signup"
import Translate from "../translate"
import {AxiosError} from "axios"
import AccessLostPasswordForm from "../access-lost-password-form"
import AccessLostPasswordDone from "../access-lost-password-done"
import SocialLogin from "../social-login"
import {AccessUserType} from "../../types/accessUser"
import {RequestFunction} from "../../types/requestFunction"
import AccessResendEmailDone from "../access-resend-email-done"
import AccessResendEmail from "../access-resend-email"
import {
  getErrorText,
  getParameterByName,
  getSocialNextUrlFromBasic
} from "../../utils"
import {LoginPromptEntry} from "../../types/companyInfo"
import {FormikHelpers} from "formik"
import {getOauthConfig} from "../../requests/init"

enum ViewType {
  Login,
  LostPassword,
  LostPasswordDone,
  ResendEmail,
  ResendEmailDone
}

const getDescriptionKey = (
  userType: AccessUserType,
  viewType: number
): string => {
  switch (viewType) {
    case ViewType.Login:
      return `form-${userType}-login-welcome-description`
    case ViewType.LostPassword:
      return `form-${userType}-lost-password-description`
    case ViewType.LostPasswordDone:
      return `form-${userType}-lost-password-done-description`
    case ViewType.ResendEmail:
      return `form-${userType}-resend-email-description`
    case ViewType.ResendEmailDone:
      return `form-${userType}-resend-email-done-description`

    default:
      throw new Error(
        `${userType} Login Form - viewType: ${viewType} is unknown`
      )
  }
}

const getValidationSchema = (
  translate: TranslateFunctionType
): Yup.AnySchema<AccessFormValuesType> =>
  Yup.object().shape({
    [AccessFormInputIds.email]: Yup.string()
      .trim()
      .email(translate("global-error-field-invalid-email"))
      .required(translate("global-error-field-mandatory")),

    [AccessFormInputIds.password]: Yup.string()
      .trim()
      .required(translate("global-error-field-mandatory"))
  })

const initialValues: AccessFormValuesType = {
  [AccessFormInputIds.email]: "",
  [AccessFormInputIds.password]: ""
}

interface PropsType {
  userType: AccessUserType
  companyName?: string // Comes from routing (indicating a whitelabel/private page)
  loginFunction: RequestFunction
  resetPasswordFunction: RequestFunction
  resendEmailFunction?: RequestFunction
  loginRedirectUrl: string
  logoUrl?: string
  hasCustomBackground?: boolean
  backgroundUrl?: string
  inChallengeLogin?: boolean
  redirectUrl?: string
  loginPrompts?: LoginPromptEntry[]
  customTitle?: string
  customDescription?: string
  customBodyBackgroundColor?: string
  customPrimaryTextColor?: string
  customButtonColor?: string
  customButtonHoverColor?: string
  customButtonTextColor?: string
}

const AccessLogin: React.FC<PropsType> = ({
  loginFunction,
  loginRedirectUrl,
  userType,
  companyName: owner,
  resetPasswordFunction,
  resendEmailFunction = async () => {},
  logoUrl,
  backgroundUrl,
  hasCustomBackground,
  inChallengeLogin,
  redirectUrl,
  loginPrompts = [],
  customTitle,
  customDescription,
  customBodyBackgroundColor,
  customPrimaryTextColor,
  customButtonColor,
  customButtonHoverColor,
  customButtonTextColor
}: PropsType) => {
  const [translate, locale] = useTranslation()
  const [errorText, setErrorText] = React.useState<string>("")
  const [currentViewType, setViewType] = React.useState<ViewType>(
    ViewType.Login
  )
  const [currentValues, setValues] = React.useState<AccessFormValuesType>(
    initialValues
  )
  const setEmailValue = (email: string) =>
    setValues({...currentValues, [AccessFormInputIds.email]: email})
  const basicNextUrl = redirectUrl
    ? redirectUrl
    : getParameterByName("next_url", window.location.href.replaceAll("//", "/"))
  const socialNextUrl = basicNextUrl
    ? `next_url=${getSocialNextUrlFromBasic(basicNextUrl)?.replaceAll("//", "/")}`
    : ""

  const socialOwnerQueryString = owner ? `owner=${owner}` : undefined
  const [ssoCOnfig, setSSOConfig] = useState<any>(null)

  const socialQueryString =
    socialNextUrl && socialOwnerQueryString
      ? `${socialNextUrl}&${socialOwnerQueryString}`
      : socialNextUrl
      ? socialNextUrl
      : socialOwnerQueryString

  const submitHandler = async (
    values: AccessFormValuesType,
    {setSubmitting}: FormikHelpers<AccessFormValuesType>
  ) => {
    // reset error on every submit
    setErrorText("")

    loginFunction<
      AccessFormValuesType | {owner?: string; language: string},
      FullFledgedLoginType | LoginRequiringTwoFactorAuthenticationType
    >(
      userType !== AccessUserType.Corporate && owner
        ? {...values, owner}
        : {...values, language: locale},
      res => {
        window.location.href = loginRedirectUrl + basicNextUrl
      },
      (e: AxiosError) => {
        if (e.response?.status === 423) {
          setViewType(ViewType.ResendEmailDone)
        }
        setSubmitting(false)
        const errorText = getErrorText(e)
        setErrorText(errorText || translate("global-generic-error"))
      }
    )
  }
  const horizontalForm = !!inChallengeLogin

  const customTitleValue = !!inChallengeLogin && customTitle
  const customDescriptionValue = !!inChallengeLogin && customDescription
  const hideBottomPadding = !!inChallengeLogin

  const title = (
    <Title alignCenter={horizontalForm} customColor={customPrimaryTextColor}>
      {customTitleValue || (
        <Translate label={`form-${userType}-register-welcome-title`} />
      )}
    </Title>
  )

  const description = !window.location.pathname.includes("unifgtalentspace") ? (
    <Description
      alignCenter={horizontalForm}
      customColor={customPrimaryTextColor}
    >
      {customDescriptionValue ? (
        <div dangerouslySetInnerHTML={{__html: customDescriptionValue}} />
      ) : (
        <Translate label={getDescriptionKey(userType, currentViewType)} />
      )}
    </Description>
  ) : (
    <Description
      alignCenter={horizontalForm}
      customColor={customPrimaryTextColor}
    >
      <div>
        <Translate label={"unifg-description-1"} />
      </div>
      <div style={{color: "red", marginTop: "0.5rem"}}>
        <Translate label={"unifg-description-2"} />
      </div>
    </Description>
  )

  const getSSOConfig = async () => {
    const res = await getOauthConfig(
      userType === AccessUserType.Employee
        ? AccessUserType.Employee
        : AccessUserType.Candidate,
      owner || "",
      socialNextUrl.split("--")[1]
        ? `/candidate/company-challenges/${socialNextUrl.split("--")[1]}`
        : ""
    )
    console.log("SSO CONFIG", res.data)
    setSSOConfig(res.data)
  }

  const loginWithMSSSO = () => {
    localStorage.setItem("tenant", "employee")
    const config = ssoCOnfig.find(
      (config: any) => config.provider === "MSGraph"
    )
    if (config) {
    let finalUrl = `${config.base_url}?`
    const params = Object.keys(config.query_pars)
    params.forEach((param, index) => {
      finalUrl = `${finalUrl}${param}=${config.query_pars[param]}${
        index === params.length - 1 ? "" : "&"
      }`
    })
    finalUrl = finalUrl.replaceAll("#", "%23")
    window.open(encodeURI(finalUrl).toString(), "_self")
  }
  }

  const hasConfig = (
    sso_type: "Facebook" | "Linkedin" | "Google" | "MSGraph"
  ) => {
    return ssoCOnfig.find((config: any) => config.provider === sso_type)
  }

  useEffect(() => {
    getSSOConfig()
  }, [userType])

  return (
    <Container
      userType={userType}
      hasCustomBackground={hasCustomBackground}
      hideBackground={horizontalForm}
      hideBottomPadding={hideBottomPadding}
      backgroundUrl={backgroundUrl}
      customBodyBackgroundColor={customBodyBackgroundColor}
    >
      {horizontalForm ? null : (
        <HeaderLoginSignup logoUrl={logoUrl} logoLinkKey={homepageKey} />
      )}
      <div className="row">
        <div className="column mobile-12 desktop-6">
          <div className="row">
            <div
              className="column mobile-12 desktop-8"
              style={{backgroundColor: customBodyBackgroundColor}}
            >
              {title}
              {description}
              <FormWrapper
                directionRow={
                  horizontalForm && userType === AccessUserType.Candidate
                }
              >
                {currentViewType === ViewType.Login &&
                  userType === AccessUserType.Candidate &&
                  !window.location.pathname.includes("unifgtalentspace") && (
                    <div id="social-login">
                      {horizontalForm && (
                        <div
                          id="social-subtitle"
                          style={{color: customPrimaryTextColor}}
                        >
                          <Translate label="login-social-subtitle" />
                        </div>
                      )}
                      <SocialLogin
                        urlSuffix={socialQueryString}
                        directionColumn={horizontalForm}
                        ssoCOnfig={ssoCOnfig}
                        isLogin
                      />
                    </div>
                  )}
                {currentViewType === ViewType.Login && horizontalForm && (
                  <Divider />
                )}
                {currentViewType === ViewType.Login && (
                  <>
                    <div id="acces-form">
                      {/* @ts-ignore*/}
                      {horizontalForm && (
                        <div
                          id="access-subtitle"
                          style={{color: customPrimaryTextColor}}
                        >
                          <Translate label="access-form-subtitle" />
                        </div>
                      )}
                      <AccessForm
                        type={AccessFormType.Login}
                        loginPrompts={loginPrompts}
                        loginNextUrl={basicNextUrl}
                        userType={userType}
                        companyName={owner}
                        initialValues={currentValues}
                        onLostPassword={() =>
                          setViewType(ViewType.LostPassword)
                        }
                        onResendEmail={() => setViewType(ViewType.ResendEmail)}
                        validationSchema={getValidationSchema(translate)}
                        submitHandler={submitHandler}
                        onChange={setValues}
                        errorText={errorText}
                        hideChangeForm={horizontalForm}
                        customPrimaryTextColor={customPrimaryTextColor}
                        customButtonColor={customButtonColor}
                        customButtonHoverColor={customButtonHoverColor}
                        customButtonTextColor={customButtonTextColor}
                      />
                    </div>
                    {userType === AccessUserType.Employee &&
                    ssoCOnfig &&
                    hasConfig("MSGraph") ? (
                      <MSSSOWrapper>
                        <div className="divider-wrapper">
                          <div className="divider-side">&nbsp;</div>
                          <div className="divider-mid">Or</div>
                          <div className="divider-side">&nbsp;</div>
                        </div>
                        <button
                          onClick={() => {
                            loginWithMSSSO()
                          }}
                          className="sso-button"
                        >
                          <div
                            style={{
                              marginRight: "15px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center"
                            }}
                          >
                            <svg
                              width="23"
                              height="22"
                              viewBox="0 0 23 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="0.500244"
                                width="10.4762"
                                height="10.4762"
                                fill="#F35325"
                              />
                              <rect
                                x="12.0237"
                                width="10.4762"
                                height="10.4762"
                                fill="#81BC06"
                              />
                              <rect
                                x="0.500244"
                                y="11.5239"
                                width="10.4762"
                                height="10.4762"
                                fill="#05A6F0"
                              />
                              <rect
                                x="12.0237"
                                y="11.5239"
                                width="10.4762"
                                height="10.4762"
                                fill="#FFBA08"
                              />
                            </svg>
                          </div>
                          <p className="sso-text">{translate("sso-ms")}</p>
                        </button>
                      </MSSSOWrapper>
                    ) : null}
                  </>
                )}
              </FormWrapper>
              {currentViewType === ViewType.LostPassword && (
                <div id="lost-password-form">
                  <AccessLostPasswordForm
                    userType={userType}
                    owner={owner}
                    showLostPasswordDone={() =>
                      setViewType(ViewType.LostPasswordDone)
                    }
                    showLoginForm={() => setViewType(ViewType.Login)}
                    initialValue={currentValues.email as string}
                    setValue={setEmailValue}
                    resetPasswordFunction={resetPasswordFunction}
                    customTextColor={customPrimaryTextColor}
                  />
                </div>
              )}

              {currentViewType === ViewType.LostPasswordDone && (
                <div id="lost-password-done">
                  <AccessLostPasswordDone
                    userType={userType}
                    email={currentValues.email as string}
                    showLoginForm={() => setViewType(ViewType.Login)}
                  />
                </div>
              )}

              {currentViewType === ViewType.ResendEmail && (
                <div id="resend-email">
                  <AccessResendEmail
                    userType={userType}
                    owner={owner}
                    showResendEmailDone={() =>
                      setViewType(ViewType.ResendEmailDone)
                    }
                    showLoginForm={() => setViewType(ViewType.Login)}
                    initialValue={currentValues.email as string}
                    setValue={setEmailValue}
                    resendEmailFunction={resendEmailFunction}
                    customTextColor={customPrimaryTextColor}
                  ></AccessResendEmail>
                </div>
              )}

              {currentViewType === ViewType.ResendEmailDone && (
                <div id="resend-email-done">
                  <AccessResendEmailDone
                    userType={userType}
                    email={currentValues.email as string}
                    showLoginForm={() => setViewType(ViewType.Login)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default AccessLogin
