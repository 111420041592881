import React, {Reducer} from "react"
import {ActionType, GlobalStateType} from "../types"
import globalState from "../globalStateContext"

const actionTypes = {
  setData: "@SET_DATA"
}

type ReducerType = (
  state: GlobalStateType,
  action: ActionType
) => GlobalStateType

const reducer: ReducerType = (
  state: GlobalStateType,
  action: ActionType
): GlobalStateType => {
  const {type, payload} = action
  switch (type) {
    case actionTypes.setData: {
      return {
        ...state,
        ...payload
      }
    }
    default: {
      throw new Error(`Unexpected action type: ${type}`)
    }
  }
}

type HookReturnType = [
  GlobalStateType,
  (state: Partial<GlobalStateType>) => void
]

const useGlobalState = (): HookReturnType => {
  const initialState: GlobalStateType = React.useContext<GlobalStateType>(
    globalState
  )
  const [state, dispatch] = React.useReducer<
    Reducer<GlobalStateType, ActionType>
  >(reducer, initialState)

  const updateState = React.useCallback(
    (state: Partial<GlobalStateType>): void =>
      dispatch({
        type: actionTypes.setData,
        payload: state
      }),
    []
  )

  return [state, updateState]
}

export default useGlobalState
