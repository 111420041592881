import styled from "styled-components"
import SimpleImage from "../../../../commonComponents/image"

export const CompanyChallengeShareList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  position: relative;
  top: 0.125rem;
`

export const CompanyChallengeShareLink = styled.li`
  cursor: pointer;
  margin-left: 0.5rem;
`

export const CompanyChallengeShareIcon = styled(SimpleImage)<{size: number}>`
  width: ${({size}) => size}rem;
  height: ${({size}) => size}rem;
  min-width: ${({size}) => size}rem;
  min-height: ${({size}) => size}rem;
`
