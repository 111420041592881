import React from "react"
import { Logo, LogoContainer } from "./styles"
import AccessLanguageSelector from "../access-language-selector"

interface PropsType {
  logoLinkKey: string
  logoUrl?: string
}

const HeaderLoginSignup: React.FC<PropsType> = ({
  logoUrl
}: PropsType) => {
  return (
    <div className="row">
      <div className="column mobile-12">
        <LogoContainer isDefaultLogo={!logoUrl}>
          {/* If logoUrl is there is a private (company) page, we don't want to link to Glickon home */}
          {logoUrl ? (
            <Logo localize={false} src={logoUrl} isDefaultLogo={false} />
          ) : (
              <Logo
                localize={false}
                src={"logos/logo-gradient@2x.png"}
                isDefaultLogo={true}
              />
            )}
        </LogoContainer>
      </div>

      <AccessLanguageSelector />
    </div >
  )
}

export default HeaderLoginSignup
