import styled from "styled-components"
import styleConstants from "../../constants/styleConstants"

export const WebmailButtonsOuterContainer = styled.div`
  display: none;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    display: block;
  }
`

export const WebmailButtonsContainer = styled.div`
  display: flex;
  margin-top: 2rem;
  margin-bottom: 1rem;
  justify-content: space-between;
`

// First and last of type is needed for IE
export const WebmailButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-grow: 1;
  max-width: calc(50% - 0.5rem);
  height: 2.5rem;
  border: 0.0625rem solid #dfe1e6;
  border-radius: 0.25rem;
  text-decoration: none;
  color: ${styleConstants.dark01};
  font-family: ${styleConstants.calibre};
  font-size: 1rem;

  &:first-of-type {
    margin-right: 0.5rem;
  }

  &:last-of-type {
    margin-left: 0.5rem;
  }
`

export const WebmailIcon = styled.img`
  width: 1.3125rem;
  margin-right: 0.75rem;
`

export const WebmailButtonsDescription = styled.p`
  margin-top: 1rem;
  color: ${styleConstants.dark02};
  font-family: ${styleConstants.calibre};
  font-size: 1rem;
  text-align: center;
`
