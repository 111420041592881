import styled from "styled-components"
import styleConstants from "../../constants/styleConstants"
import SimpleImage from "../image"

export const CarouselWrapper = styled.div<{ customSliderColor?: string }>`
  box-sizing: border-box;
  padding: 1.75rem 1.125rem;
  background-color: ${styleConstants.white};
  border: 0.0625rem solid ${styleConstants.cardBorder};
  width: 100%;
  margin: 0 auto;

  .swiper-container {
    width: 100%;
    margin-bottom: 1.5rem;
    padding-bottom: 2.5rem;
  }

  .swiper-scrollbar-drag {
    background-color: ${props =>
    props.customSliderColor
      ? props.customSliderColor
      : "#0b273d"};
  }

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    padding: 2rem 1.75rem;

    .swiper-container {
      margin-bottom: 0;
      padding-bottom: 0;
    }

    .swiper-scrollbar {
      transform: scaleX(0.4);
    }
  }
`

export const Wrapper = styled.div`
  @media (min-width: ${styleConstants.desktopMinWidth}) {
    margin-bottom: 1rem;
  }
`

export const Image = styled(SimpleImage)``
