import styled from "styled-components"
import styleConstants from "../../constants/styleConstants"

export const Container = styled.div`
  font-family: ${styleConstants.calibreSemibold};
  font-size: ${styleConstants.font.size16};
  background-color: ${styleConstants.badFeedback};
  border-radius: ${styleConstants.defaultBorderRadius};
  color: ${styleConstants.white};
  margin-bottom: 1rem;
  padding: 1rem;
`
