import * as React from "react"
import {GenericSpinner} from "./styles"

interface PropsType {
  colored?: boolean
}

const Loader: React.FC<PropsType> = (props: PropsType) => {
  return (
    <GenericSpinner>
      <img
        src="https://storage.googleapis.com/glickon-dev-assets/assets/global/img/generic_loader.png"
        alt="Loading..."
        style={{width: "100%"}}
      />
    </GenericSpinner>
  )
}

export default Loader
