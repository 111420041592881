import React from "react"
import { getImageUrl } from "../../utils"
import { StyledBackgroundImage, StyledImage } from "./styles"
import useTranslation from "../../hooks/useTranslation"

type PropsType = React.ImgHTMLAttributes<HTMLImageElement> & {
  src?: string
  fallbackSrc?: string
  backgroundCover?: boolean
  backgroundCoverColor?: string
  hasRightImage?: boolean
  localize?: boolean
  rounded?: boolean
}

const SimpleImage: React.FC<PropsType> = (props: PropsType) => {
  const {
    src: externalSrc = "",
    localize,
    backgroundCover = false,
    backgroundCoverColor = "",
    ...otherProperties
  } = props

  const [, locale] = useTranslation()
  const [src, setSrc] = React.useState<string>(
    localize ? "" : getImageUrl(externalSrc)
  )

  React.useEffect((): (() => void) => {
    const [path, ext] = externalSrc.split(".")
    const localizedImageUrl: string = ext
      ? getImageUrl(`${path}-${locale}.${ext}`)
      : getImageUrl(externalSrc)
    const defaultImageUrl: string = getImageUrl(externalSrc)
    const imgElement: HTMLImageElement = new Image()

    const onSuccess = () => {
      setSrc(localizedImageUrl)
    }

    const onError = () => {
      setSrc(defaultImageUrl)
    }

    if (localize) {
      // when image is correctly loaded then set the src attribute
      imgElement.addEventListener("load", onSuccess)
      // if the image cannot be retrieved use the fallback
      imgElement.addEventListener("error", onError)
      imgElement.src = localizedImageUrl
    } else {
      setSrc(defaultImageUrl)
    }
    return () => {
      imgElement.removeEventListener("load", onSuccess)
      imgElement.removeEventListener("error", onError)
    }
  }, [locale, externalSrc, localize])

  if (backgroundCover) {
    return (
      <StyledBackgroundImage
        src={src}
        backgroundCoverColor={backgroundCoverColor}
        {...otherProperties}
      />
    )
  } else {
    return <StyledImage src={src} {...otherProperties} />
  }
}

export default SimpleImage
