import {TranslationType} from "../types"

const translations: TranslationType = {
  "cookie-title": {
    en: "Cookies Policy",
    it: "Cookie Policy"
  },
  "cookie-pre-list-text": {
    en: `
            Glickon, in order to improve the users’ browsing experience as much as possible, uses cookies.
            <br/><br/>  
            Cookies are small aggregates of text files, stored locally in the temporary memory of the user’s browser, for 
            periods of time that vary depending on the need and generally between a few hours and a few years, with the 
            exception of profiling cookies whose maximum duration is of 365 calendar days.
        `,
    it: `
            Glickon, al fine di migliorare il più possibile l’esperienza di navigazione degli utenti, utilizza dei cookies.
            <br/><br/>  
            I cookies sono piccoli aggregati di testo registrati localmente nella memoria temporanea del browser 
            dell’utente per periodi di tempo variabili in funzione dell’esigenza e generalmente compresi tra poche ore 
            e alcuni anni, con l’eccezione dei cookie di profilazione la cui durata massima è di 365 giorni solari.
        `
  },
  "cookie-last-modified": {
    en: "Milan, 31 March 2019",
    it: "Milano, 31 Marzo 2019"
  },

  "cookie-1": {
    en: "Types of cookies",
    it: "Tipologie di cookie"
  },
  "cookie-1-1": {
    en: `
            <strong>Essential technical cookies</strong><br/>
            These cookies are necessary for the site to function properly. They allow page navigation, content sharing,
            memorization of your login credentials to make site entry faster and keep your preferences active while
            browsing. Without these cookies we could not provide the services for which users access the site.
        `,
    it: `
            <strong>Cookie tecnici essenziali</strong><br/>
            Questi cookie sono necessari al corretto funzionamento del sito. Consentono la navigazione delle pagine, 
            la condivisione dei contenuti, la memorizzazione delle tue credenziali di accesso per rendere più 
            rapido l’ingresso nel sito e per mantenere attive le tue preferenze durante la navigazione. Senza 
            questi cookie non potremmo fornire i servizi per i quali gli utenti accedono al sito.
        `
  },
  "cookie-1-2": {
    en: `
            <strong>Statistical and performance cookies</strong><br/>
            These cookies allow us to know how visitors use the site, in order to be able to evaluate and improve its
            functioning. For example, they allow us to know which pages are the most and which the least visited. They
            take into account, among other things, the number of visitors, the time spent on the site by the average
            user and how they get to the site. In this way, we can know what works well and what to improve, besides
            ensuring that pages load quickly and are displayed correctly. All information collected by these cookies is
            anonymous and not linked to the user’s personal data. To perform these functions on our sites, we use
            third-party services that anonymize data, making them not traceable to individuals.
        `,
    it: `
            <strong>Cookie statistici e prestazionali</strong><br/>
            Questi cookie ci permettono di sapere in che modo i visitatori utilizzano il sito, per poterne così 
            valutare e migliorare il funzionamento. Ad esempio, consentono di sapere quali sono le pagine più e 
            meno frequentate. Tengono conto, tra le altre cose, del numero di visitatori, del tempo trascorso sul 
            sito dalla media degli utenti e delle modalità di arrivo di questi. In questo modo, possiamo sapere che 
            cosa funziona bene e che cosa migliorare, oltre ad assicurarci che le pagine si carichino velocemente e 
            siano visualizzate correttamente. Tutte le informazioni raccolte da questi cookie sono anonime e non 
            collegate ai dati personali dell’utente. Per eseguire queste funzioni nei nostri siti utilizziamo i 
            servizi di terze parti che anonimizzano i dati rendendoli non riconducibili a singoli individui.
        `
  },
  "cookie-1-3": {
    en: `
            <strong>Functional and profiling cookies and third-party advertising targeting</strong><br/>
            This category includes both cookies provided by partners of Glickon s.r.l. and known to it, and cookies
            provided by third parties not directly controlled or controllable by Glickon s.r.l. Cookies from our
            partners allow us to offer advanced features, as well as more information and personal functions. This
            includes the ability to share content through social networks. If you have an account or if you use the
            services of those subjects on other websites, they may be able to know that you have visited our site. The
            use of the data collected by these external operators through cookies is subject to the respective privacy
            policies and therefore these cookies are identified with the names of the respective subjects reported in
            the cookie management tool available in the specific section of this Policy. These include the cookies
            registered by the main social networks that allow you to share the articles of our sites and to publicly
            express the appreciation of our work. Cookies that do not come from partners of Glickon s.r.l. are cookies
            conveyed without Glickon’s control by third parties that have a way to intercept the user during their
            navigation even outside Glickon’s sites. These cookies, typically profiling, cannot be directly controlled
            by Glickon, which cannot therefore guarantee the use that third-party owners make of the information
            collected. These cookies can also be used to show the user relevant advertisements on other websites
            visited.
        `,
    it: `
            <strong>Cookie funzionali e di profilazione e targeting pubblicitario di terze parti</strong><br/>
            In questa categoria ricadono sia cookie erogati da soggetti partner di Glickon s.r.l. e ad essa noti, 
            sia cookie erogati da soggetti terzi non direttamente controllati o controllabili da Glickon s.r.l. 
            I cookie provenienti dai nostri partner consentono di offrire funzionalità avanzate, nonché maggiori 
            informazioni e funzioni personali. Ciò include la possibilità di condividere contenuti attraverso i 
            social network. Se si dispone di un account o se si utilizzano i servizi di tali soggetti su altri 
            siti Web, questi potrebbero essere in grado di sapere che l’utente ha visitato i nostri siti. 
            L’utilizzo dei dati raccolti da questi operatori esterni tramite cookie è sottoposto alle rispettive 
            politiche sulla privacy e pertanto si identificano tali cookie con i nomi dei rispettivi soggetti 
            riportati nel tool di gestione dei cookie disponibile nell’apposita sezione di questa Informativa. 
            Tra questi si annoverano i cookie registrati dai principali social network che Le consentono di 
            condividere gli articoli dei nostri siti e di manifestare pubblicamente il gradimento per il nostro 
            lavoro. I cookie non provenienti da partner di Glickon s.r.l., sono cookie veicolati senza il 
            controllo di Glickon, da soggetti terzi che hanno modo di intercettare l’utente durante la sua 
            navigazione anche al di fuori dei siti di Glickon. Questi cookie, tipicamente di profilazione, non 
            sono direttamente controllabili da Glickon che non può quindi garantire in merito all’uso che i terzi 
            titolari fanno delle informazioni raccolte. Questi cookie possono essere utilizzati anche per mostrare 
            all’utente annunci pubblicitari pertinenti su altri siti Web visitati.
        `
  },

  "cookie-2": {
    en: "Management of cookies through browser settings",
    it: "Gestione dei cookies tramite le impostazioni del browser"
  },
  "cookie-2-1": {
    en: `
            The user can set their browser so as to be warned of the presence of a cookie and then decide whether to
            accept it or not, or so that the browser automatically rejects all cookies. In some cases, however, if you
            decide to refuse cookies, it is possible that certain interactive features offered by the Site cannot be
            used, as a whole or in part. Each browser offers different ways to manage cookies and their settings. The
            browser configuration is described in the “Help” menu of the toolbar located in most browsers, where
            information is provided on how to change the settings for cookies. Below are the links containing
            instructions on how to do this in the most common browsers:
            <ul>
                <li><a target="_blank" href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-11">Internet Explorer</a></li>
                <li><a target="_blank" href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">Mozilla Firefox</a></li>
                <li><a target="_blank" href="https://support.google.com/chrome/answer/95647?hl=en">Google Chrome</a></li>
                <li><a target="_blank" href="https://support.apple.com/kb/PH19214?locale=en_US">Apple Safari</a></li>
                <li><a target="_blank" href="http://www.opera.com/help/tutorials/security/cookies/">Opera</a></li>
            </ul>
        `,
    it: `
            L’utente può impostare il proprio browser in modo tale da essere avvertito della presenza di un cookie e 
            quindi decidere se accettarlo o meno, oppure in modo che il browser rifiuti automaticamente tutti i 
            cookie. In alcuni casi, tuttavia, se si decide di rifiutare i cookie, è possibile che non possano essere 
            utilizzate, in tutto o in parte, certe funzionalità interattive offerte dal Sito. Ogni browser offre 
            diverse modalità di gestione dei cookies e delle relative impostazioni. La configurazione del browser 
            è descritta nel menu "Aiuto" (Help) della barra degli strumenti situata nella maggior parte dei browser, 
            ove sono fornite informazioni circa le modalità con cui modificare le impostazioni relative ai cookies. 
            Di seguito, si riportano i link contenenti le istruzioni su come effettuare questa operazione nei 
            browser più diffusi:
            <ul>
                <li><a target="_blank" href="https://windows.microsoft.com/it-IT/internet-explorer/delete-manage-cookies#ie=ie-11">Internet Explorer</a></li>
                <li><a target="_blank" href="https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie">Mozilla Firefox</a></li>
                <li><a target="_blank" href="https://support.google.com/chrome/answer/95647?hl=it">Google Chrome</a></li>
                <li><a target="_blank" href="https://support.apple.com/kb/PH19214?locale=it_IT">Apple Safari</a></li>
                <li><a target="_blank" href="http://www.opera.com/help/tutorials/security/cookies/">Opera</a></li>
            </ul>
        `
  },
  "cookie-2-2": {
    en: `
            For more information on how to delete or manage cookies based on the browser used and to manage third-party 
            cookie usage preferences, please visit the website http://www.youronlinechoices.com/. 
        `,
    it: `
            Per ulteriori informazioni su come poter eliminare o gestire i cookie in base al browser utilizzato e 
            per gestire le preferenze di utilizzo dei cookie di terza parte è possibile collegarsi al sito web 
            http://www.youronlinechoices.com/it/.
        `
  },

  "cookie-3": {
    en: "The rights of the user",
    it: "I diritti dell’utente"
  },
  "cookie-3-1": {
    en: `
            The data controller is Glickon SpA, with registered office in via Nino Bixio 7, 20129 Milan. The user may,
            at any time and without any formality, exercise the rights of art. 7 of Legislative Decree n. 196/2003 –
            “Code regarding the protection of personal data” (hereinafter referred to as “Privacy Code”), by sending a
            communication to the e-mail address <a href="mailto:privacy@glickon.com" target="_blank">privacy@glickon.com</a>. For the sake of completeness, the full text of art. 7
            of Privacy Code can be found below:
        `,
    it: `
            Titolare del trattamento è Glickon S.p.A., con sede legale in via Nino Bixio 7, 20129 Milano. L’utente 
            potrà, in ogni momento e senza formalità alcuna, esercitare i diritti di cui all'art. 7 del D.lgs. n. 
            196/2003 – "Codice in materia di protezione dei dati personali" (di seguito il "Codice Privacy"), inviando 
            una comunicazione all’indirizzo e-mail <a href="mailto:privacy@glickon.com" target="_blank">privacy@glickon.com</a>. Per maggiore esaustività, si riporta integralmente 
            di seguito il testo dell’art. 7 del Codice Privacy:
        `
  },
  "cookie-3-2": {
    en: `
            Art. 7 - Right to access personal data and other rights<br/>
            3.2.1 A data subject shall have the right to obtain confirmation as to whether or not personal data 
            concerning him exist, regardless of their being already recorded, and communication of such data 
            in intelligible form.
            <br/><br/>
            3.2.2 A data subject shall have the right to be informed:<br/>
            <ul>
                <li>a) of the source of the personal data;</li>
                <li>b) of the purposes and methods of the processing;</li>
                <li>c) of the logic applied to the processing, if the latter is carried out with the help of electronic means;</li>
                <li>d) of the identification data concerning data controller, data processors and the representative designated as per Section 5(2);</li>
                <li>e) of the entities or categories of entity to whom or which the personal data may be 
                communicated and who or which may get to know said data in their capacity as designated 
                representative(s) in the State’s territory, data processor(s) or person(s) in charge of 
                the processing.</li>
            </ul>
            <br/>
            3.2.3 A data subject shall have the right to obtain:<br/>
            <ul>
                <li>a) updating, rectification or, where interested therein, integration of the data;</li>
                <li>b) erasure, anonymization or blocking of data that have been processed unlawfully, including 
                data whose retention is unnecessary for the purposes for which they have been collected or 
                subsequently processed;</li>
                <li>c) certification to the effect that the operations as per letters a) and b) have been 
                notified, as also related to their contents, to the entities to whom or which the data were 
                communicated or disseminated, unless this requirement proves impossible or involves a 
                manifestly disproportionate effort compared with the right that is to be protected.</li>
            </ul>
            <br/>
            3.2.4 A data subject shall have the right to object, in whole or in part:<br/>
            <ul>
                <li>a) on legitimate grounds, to the processing of personal data concerning him/her, even 
                though they are relevant to the purpose of the collection;</li>
                <li>b) to the processing of personal data concerning him/her, where it is carried out for the 
                purpose of sending advertising materials or direct selling or else for the performance of 
                market or commercial communication surveys.</li>
            </ul>
        `,
    it: `
            Art. 7 – Diritto di accesso ai dati personali ed altri diritti<br/>
            3.2.1 L'interessato ha diritto di ottenere la conferma dell'esistenza o meno di dati personali
            che lo riguardano, anche se non ancora registrati, e la loro comunicazione in forma intelligibile.
            <br/><br/>
            3.2.2 L'interessato ha diritto di ottenere l'indicazione:<br/>
            <ul>
                <li>a) dell'origine dei dati personali;</li>
                <li>b) delle finalità e modalità del trattamento</li>
                <li>c) della logica applicata in caso di trattamento effettuato con
                l'ausilio di strumenti elettronici;</li>
                <li>d) degli estremi identificativi del titolare, dei responsabili e
                del rappresentante designato ai sensi dell'articolo 5, comma 2;</li>
                <li>e) dei soggetti o delle categorie di soggetti ai quali i dati personali possono essere
                comunicati o che possono venirne a conoscenza in qualità di rappresentante designato
                nel territorio dello Stato, di responsabili o incaricati.</li>
            </ul>
            <br/>
            3.2.3 L'interessato ha diritto di ottenere:<br/>
            <ul>
                <li>a) l'aggiornamento, la rettificazione ovvero, quando vi ha interesse, l'integrazione dei dati;</li>
                <li>b) la cancellazione, la trasformazione in forma anonima o il blocco dei dati trattati
                in violazione di legge, compresi quelli di cui non è necessaria la conservazione in
                relazione agli scopi per i quali i dati sono stati raccolti o successivamente trattati;</li>
                <li>c) l'attestazione che le operazioni di cui alle lettere a) e b) sono state portate a
                conoscenza, anche per quanto riguarda il loro contenuto, di coloro ai quali i dati
                sono stati comunicati o diffusi, eccettuato il caso in cui tale adempimento si rivela
                impossibile o comporta un impiego di mezzi manifestamente sproporzionato rispetto al
                diritto tutelato.</li>
            </ul>
            <br/>
            3.2.4 L'interessato ha diritto di opporsi, in tutto o in parte:<br/>
            <ul>
                <li>a) per motivi legittimi al trattamento dei dati personali che lo riguardano, ancorché
                pertinenti allo scopo della raccolta;</li>
                <li>b) al trattamento di dati personali che lo riguardano a fini di invio di materiale
                pubblicitario o di vendita diretta o per il compimento di ricerche di mercato o di
                comunicazione commerciale"</li>
            </ul>
        `
  }
}

export default translations
