import styled from "styled-components"
import styleConstants from "../../constants/styleConstants"

export const Container = styled.div`
  width: 100%;
  height: 100vh; /*fallback*/
  height: calc((var(--realVh, 1vh) * 100));
  background-color: ${styleConstants.blue00};
`

const containerHeight = 50
export const SpinnerContainer = styled.div`
  height: ${containerHeight}rem;
  position: relative;
`

interface SpinnerPropsType {
  colored?: boolean
}

const Spinner = styled.div<SpinnerPropsType>`
  position: absolute;
  border-width: 0.375rem;
  border-style: solid;
  border-radius: 50%;
`

const firstSpinnerSize = 7.5
export const FirstSpinner = styled(Spinner)`
  width: ${firstSpinnerSize}rem;
  height: ${firstSpinnerSize}rem;
  animation: rotate-animation 1.2s linear 0s infinite;
  top: calc(50% - ${firstSpinnerSize / 2}rem);
  left: calc(50% - ${firstSpinnerSize / 2}rem);
  border-color: ${props =>
    props.colored ? styleConstants.blueGlickon : styleConstants.cardBorder};
  border-top-color: transparent;
`

const secondSpinnerSize = 5.25
export const SecondSpinner = styled(Spinner)`
  width: ${secondSpinnerSize}rem;
  height: ${secondSpinnerSize}rem;
  animation: anti-rotate-animation 1s linear 0s infinite;
  top: calc(50% - ${secondSpinnerSize / 2}rem);
  left: calc(50% - ${secondSpinnerSize / 2}rem);
  border-color: ${props =>
    props.colored ? styleConstants.fluoBlueGlickon : styleConstants.cardBorder};
  border-top-color: transparent;
`

const thirdSpinnerSize = 3
export const ThirdSpinner = styled(Spinner)`
  width: ${thirdSpinnerSize}rem;
  height: ${thirdSpinnerSize}rem;
  animation: rotate-animation 1.2s linear 0s infinite;
  top: calc(50% - ${thirdSpinnerSize / 2}rem);
  left: calc(50% - ${thirdSpinnerSize / 2}rem);
  border-color: ${props =>
    props.colored ? styleConstants.white : styleConstants.cardBorder};
  border-top-color: transparent;
`

export const GenericSpinner = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  background-color: #fff9f3;
`
