import styled from "styled-components"
import styleConstants from "../../../../constants/styleConstants"
import SimpleImage from "../../../../commonComponents/image"

export const CompanyWhoWeAreSectionWrapper = styled.div`
  margin-bottom: 1rem;
`

export const CompanyWhoWeAreSectionTitle = styled.div`
  font-family: ${styleConstants.calibreSemibold};
  font-size: ${styleConstants.font.size14};
  text-transform: uppercase;
  color: ${styleConstants.blue00};
  padding-bottom: 0.5rem;
`

export const CompanyWhoWeAreSectionDescription = styled.div`
  font-family: ${styleConstants.calibre};
  font-size: ${styleConstants.font.size20};
  color: ${styleConstants.dark01};
`

export const CompanyWhoWeAreWebSiteUrl = styled.a`
  font-family: ${styleConstants.calibreSemibold};
  font-size: ${styleConstants.font.size20};
  color: ${styleConstants.blueGlickon};
  width: 100%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: underline;
`

export const CompanyWhoWeAreSocialLinks = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;

  li + li {
    margin-left: 1rem;
  }
`

export const CompanyWhoWeAreSocialIcon = styled(SimpleImage).attrs({
  backgroundCover: true
})`
  width: 2rem;
  height: 2rem;
  border-radius: ${styleConstants.defaultBorderRadius};
`

export const CompanyWhoWeAreCard = styled.div`
  box-sizing: border-box;
  padding: 1.75rem 1.125rem;
  border-radius: ${styleConstants.defaultBorderRadius};
  border: 0.0625rem solid ${styleConstants.cardBorder};
  background-color: ${styleConstants.white};
  width: 100%;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    padding: 2rem 1.75rem;
  }
`

export const CompanyWhoWeAreTitle = styled.div`
  font-family: ${styleConstants.galanoGrotesqueMedium};
  font-size: ${styleConstants.font.size18};
  color: ${styleConstants.blue00};

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    font-size: ${styleConstants.font.size24};
  }
`

export const CompanyWhoWeAreContent = styled.div`
  &.company-who-we-are-content {
    margin: 0;
  }

  padding-top: 1rem;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    justify-content: space-between;
  }
`

// eslint-disable-next-line no-unexpected-multiline
export const CompanyWhoWeAreLeftContent = styled.div<{
  fullWidth: boolean | undefined
}>`
  @media (min-width: ${styleConstants.desktopMinWidth}) {
    &.company-who-we-are-left-content {
      width: ${({fullWidth}) => (fullWidth ? "100%" : "73%")};
    }
  }
`

export const CompanyWhoWeAreRightContent = styled.div`
  @media (min-width: ${styleConstants.desktopMinWidth}) {
    &.company-who-we-are-right-content {
      width: 23%;
    }
  }
`

export const CompanyWhoWeAreContentDescription = styled.div`
  font-family: ${styleConstants.calibre};
  font-size: ${styleConstants.font.size18};
  color: ${styleConstants.dark01};

  p {
    line-height: normal;
    margin-top: 0;
    font-size: ${styleConstants.font.size18}!important;
  }

  p span {
    line-height: normal;
    font-size: ${styleConstants.font.size18}!important;
  }
`

export const CompanyWhoWeAreContentDivider = styled.div`
  background-image: linear-gradient(
    to top,
    ${styleConstants.white},
    ${styleConstants.cardBorder}
  );
  min-height: 0.0625rem;
  height: 0.0625rem;
  width: 100%;
  margin: 1rem;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    height: auto;
    margin: 0 1rem;
    min-width: 0.0625rem;
    width: 0.0625rem;
  }
`

export const CompanyWhoWeAreWrapper = styled.div`
  transform: translateY(-2rem);
`
