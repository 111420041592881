import humps from "humps"
import {AxiosError, AxiosResponse} from "axios"
import {getAxiosInstance} from "../utils"
import translations from "../translations"
import {caseStudies} from "../constants/data/case-studies"
import {pressList} from "../constants/data/press"

export async function getCSRFToken<R>(
  successFn: (res: AxiosResponse<R>) => void,
  errorFn: (err: AxiosError) => void
) {
  try {
    await getAxiosInstance(false).get(
      `${process.env.REACT_APP_API_URL}/ensure_csrf_cookie`
    )
  } catch (error) {
    errorFn((error as unknown) as AxiosError)
  } finally {
    successFn(humps.camelizeKeys({}) as AxiosResponse<R>)
  }
}

export async function getAuthInfo<R>(
  tenant: string,
  successFn: (res: AxiosResponse<R>) => void,
  errorFn: (err: AxiosError) => void
) {
  try {
    const response: AxiosResponse<any> = await getAxiosInstance().get(
      `${process.env.REACT_APP_API_URL}/api/identity/v3/${tenant}/session`
    )
    successFn(humps.camelizeKeys(response) as AxiosResponse<R>)
  } catch (error) {
    errorFn((error as unknown) as AxiosError)
  }
}

export async function getSiteData<R>(
  successFn: (res: AxiosResponse<R>) => void,
  errorFn: (err: AxiosError) => void
) {
  try {
    successFn(
      humps.camelizeKeys(
        {
          data: {
            dictionary: translations,
            caseStudies: caseStudies,
            press: pressList
          }
        },
        {
          process: (key, convert, options) => {
            // prevent conversion of labels in translations
            return key.includes("-") ? key : convert(key, options)
          }
        }
      ) as AxiosResponse<R>
    )
  } catch (error) {
    errorFn((error as unknown) as AxiosError)
  }
}

export async function getOauthConfig(
  tenant: string,
  org: string,
  next_url: string
) {
  return await await getAxiosInstance().get(
    `${
      process.env.REACT_APP_API_URL
    }/api/identity/v3/${tenant}/session/oauth/config?careers_name=${org}&next_url=${next_url.replaceAll(
      "//",
      "/"
    )}`
  )
}
