import {TranslationType} from "../types"

const translations: TranslationType = {
  // header
  "about-us-header-title": {
    en: "To make people and organizations better at their most important work",
    it: "To make people and organizations better at their most important work"
    // it: `
    //     Glickon si pone come missione quella di creare un mondo in cui tutte le persone e le
    //     organizzazioni possano esprimere al meglio le proprie potenzialità e contribuire a un futuro
    //     migliore.
    // `
  },
  "about-us-header-description": {
    en: `
            We help you bring the best people into the company, make them grow and motivate
            towards common goals and achieve excellent results.
        `,
    it: `
            Ti aiutiamo a portare in azienda le persone migliori, farle crescere e motivarle verso obiettivi
            comuni e raggiungere risultati eccellenti.
        `
  },

  // info 2 columns
  "about-us-info-1st-column-title": {
    en: "It is our mission.",
    it: "È la nostra missione."
  },
  "about-us-info-1st-column-description": {
    en: "Make the job better<br/>with some science and fun.",
    it: "Rendere il lavoro migliore con un po’ di scienza e divertimento."
  },
  "about-us-info-2nd-column-title": {
    en: "But it is also your goal.",
    it: "Ma è anche il tuo obiettivo."
  },
  "about-us-info-2nd-column-description": {
    en: `
            Finding and cultivating talent, making the organization alive and winning the challenge of
            developing people and business.
        `,
    it: `
            Trovare e coltivare talenti, rendere viva l’organizzazione e vincere la sfida dello sviluppo
            delle persone e del business.
        `
  },

  // phases
  "about-us-ph-1-title": {
    en: "Glickon Candidate Experience",
    it: "Glickon Candidate Experience"
  },
  "about-us-ph-1-description": {
    en: `
            It's 2014: we give shape to an idea and we design the Glickon Candidate Experience platform. We put three
            ingredients into it: engagement, gaming experience and data. The recipe works.
        `,
    it: `
            È il 2014, diamo forma a un’idea e progettiamo la piattaforma Glickon Candidate Experience. Ci mettiamo 
            dentro tre ingredienti: engagement, esperienza di gioco e dati. La ricetta funziona.
        `
  },
  "about-us-ph-2-title": {
    en: "Glickon Employee Experience",
    it: "Glickon Employee Experience"
  },
  "about-us-ph-2-description": {
    en: `
            In 2016 we still focus on talent, the one already in the company. We create Glickon Employee Experience to
            measure and optimize employee experience and performance, generating, once again, useful data to support
            important strategic decisions.
        `,
    it: `
            Nel 2016 puntiamo ancora sul talento, quello che è già in azienda. Creiamo Glickon Employee Experience per 
            misurare e ottimizzare esperienza e performance dei dipendenti. Di nuovo dati a supporto di importanti 
            decisioni strategiche.
        `
  },
  "about-us-ph-3-title": {
    en: "Glickon X - People Analytics",
    it: "Glickon X - People Analytics"
  },
  "about-us-ph-3-description": {
    en: `
            It's 2018. We are now certain the value of a company lies in its resources. With GlickonX we give HR teams
            the technology to get to know their people’s potential. It's People Analytics!
        `,
    it: `
            È il 2018. Siamo convinti, il valore di un’organizzazione sta nelle risorse a disposizione. Con GlickonX 
            diamo in mano ai team HR la tecnologia per conoscere a fondo il potenziale delle persone. 
            È la People Analytics!
        `
  },

  // widget-3-columns
  "about-us-widget-3-columns-1st-title": {
    en: "Technology",
    it: "Tecnologia"
  },
  "about-us-widget-3-columns-1st-description": {
    en: `
            We develop and test the most functional for an intuitive and flexible platform. Glickon’s technology cannot
            be seen, but its effectiveness is tangible in the results achieved.
        `,
    it: `
            Sviluppiamo e testiamo quella più funzionale a una piattaforma intuitiva e flessibile. La tecnologia 
            Glickon non si vede, ma la sua efficacia è tangibile nei risultati raggiunti.
        `
  },
  "about-us-widget-3-columns-2nd-title": {
    en: "Innovation",
    it: "Innovazione"
  },
  "about-us-widget-3-columns-2nd-description": {
    en: `
            It is our main driver. Also Glickon is a company, which we we know very well how much innovating a process
            can attract new ideas, new resources and new business.
        `,
    it: `
            È il nostro driver principale. Anche Glickon è un’azienda, sappiamo bene quanto innovare un processo 
            attragga nuove idee, nuove risorse e nuovo business.
        `
  },
  "about-us-widget-3-columns-3rd-title": {
    en: "Speed",
    it: "Velocità"
  },
  "about-us-widget-3-columns-3rd-description": {
    en: `
            The best talents never waits in line. Hiring them and training them quickly with Glickon’s solutions has a 
            positive impact on the revenue.
        `,
    it: `
            I talenti migliori non fanno mai la coda. Assumerli e formarli rapidamente con le soluzioni Glickon produce 
            un impatto positivo sul fatturato.
        `
  },
  "about-us-widget-3-columns-4th-title": {
    en: "Design",
    it: "Design"
  },
  "about-us-widget-3-columns-4th-description": {
    en: `
            We design simple and rational dashboards. Customizable and easy to use. Human-centered solutions, designed
            around the experience of candidates, employees and HR teams.
        `,
    it: `
            Progettiamo dashboard semplici e razionali. Personalizzabili e facili da usare. Soluzioni human-centered, 
            disegnate intorno all’esperienza di candidati, dipendenti e team HR.
        `
  },
  "about-us-widget-3-columns-5th-title": {
    en: "Tailor made",
    it: "Tailor made"
  },
  "about-us-widget-3-columns-5th-description": {
    en: `
            We listen to your needs and, if necessary, implement other features in addition to those already present and
            most used by the majority of the HR teams we work with.
        `,
    it: `
            Ascoltiamo le tue esigenze e, se necessario, implementiamo altre funzionalità oltre a quelle già presenti e 
            più utilizzate dalla maggior parte dei team HR con cui lavoriamo.
        `
  },
  "about-us-widget-3-columns-6th-title": {
    en: "Data",
    it: "Dati"
  },
  "about-us-widget-3-columns-6th-description": {
    en: `
            Anything that is not measured produces partial results. In Glickon we don't give out numbers, we analyze
            them. Because for us data science is passion, work...even art.
        `,
    it: `
            Tutto ciò che non viene misurato, produce risultati parziali. In Glickon non diamo i numeri, li analizziamo. 
            Perché per noi la data science è passione, lavoro e anche un po’ arte.
        `
  },

  // full-width-img-text
  "about-us-1st-horizontal-title": {
    en: "It all starts in 2013,<br/>with an idea.",
    it: "Tutto inizia nel 2013,<br/>con un’idea."
  },
  "about-us-1st-horizontal-description": {
    en: `
            Revolutionize the recruiting process for candidates. From its frustrating, long and boring reality we set
            out to make the process fun, fast and effective, basing it all of course on data analysis. A hypothetical
            challenge in which everyone would win: both the candidates and the company in search for the best skills.
            <br/><br/>
            And then? Well, then we really started getting into it, developing all other Glickon solutions in support of
            many HR teams that continue to choose us every day.
        `,
    it: `
            Rivoluzionare il percorso di recruiting dei candidati. Da frustrante, lungo e noioso lo immaginavamo così:
            divertente, rapido ed efficace. E basato sull’analisi dei dati. Una sfida in cui avrebbero vinto sia le
            persone sia l’azienda in cerca delle migliori competenze.
            <br/><br/>
            E poi? Poi ci abbiamo preso gusto, sviluppando tutte le altre soluzioni Glickon a supporto di tanti team HR
            che continuano a sceglierci ogni giorno.
        `
  },

  // bottom-full-width
  "about-us-bottom-title": {
    en: "Work is a daily challenge.",
    it: "Quella del lavoro è una sfida quotidiana."
  },
  "about-us-bottom-description": {
    en: `
            Employees who put specific skills and performances into play every day, companies that achieve many small
            goals. A game that is played in a competitive and constantly evolving scenario.
            This is why it is key to bring into play a scheme of values that
            does not allow draws, only victories.
        `,
    it: `
            Dipendenti che ogni giorno mettono in gioco competenze specifiche e prestazioni, aziende che tagliano tanti 
            piccoli traguardi. Una partita che si gioca in uno scenario competitivo e in continua evoluzione. 
            Ecco perché serve portare in campo uno schema di valori che non ammette pareggi. Ma solo vittorie.
        `
  },

  // banner-bottom
  "about-us-banner-bottom-title": {
    en: "Glickon Team",
    it: "Il team Glickon"
  },
  "about-us-banner-bottom-description": {
    en: `
            Developers, sales, customer support, marketers, designers. Here's who we are, that's who every day
            designs<br/>the perfect experience for your candidates and employees
        `,
    it: `
            Programmatori, sales, customer support, marketer, designer. Ecco chi siamo, ecco chi ogni giorno progetta<br/> 
            l’esperienza perfetta per i tuoi candidati e dipendenti
        `
  },
  "about-us-banner-bottom-action-label": {
    en: "Discover the team",
    it: "Scopri il team"
  }
}

export default translations
