import { TranslationType } from "../types"
import {
  aboutUsKey,
  blogKey,
  candidateExperienceKey,
  candidatePlatformKey,
  caseStudiesKey,
  caseStudyKey,
  platformCookieKey,
  slaveryPreventionKey,
  employeeExperienceKey,
  facebookKey,
  homepageKey,
  jobKey,
  landingAwardKey,
  linkedinKey,
  liveKey,
  livePrivacyKey,
  liveTermsAndConditionsKey,
  mailKey,
  eventMailKey,
  oneToOneMailKey,
  demoMailKey,
  mediumKey,
  notFoundKey,
  peopleAnalyticsKey,
  platformPrivacyKey,
  productsKey,
  requestADemoKey,
  resourcesCandidateExperienceKey,
  resourcesEmployeeExperienceKey,
  resourcesKey,
  resourcesPeopleAnalyticsKey,
  platformSecurityKey,
  teamKey,
  platformTermsAndConditionsKey,
  documentGeneralTermsAndConditionsKey,
  termsOfServicesKey,
  twitterKey,
  twoFactorAuthenticationSetPhoneNumberKey,
  twoFactorAuthenticationInsertCodeKeySMS,
  twoFactorAuthenticationInsertCodeKeyEmail,
  candidatePlatformSignupKey,
  employeePlatformKey,
  employeePlatformSignupKey,
  candidatePlatformCompanyKey,
  candidatePlatformCompanySignupKey,
  employeePlatformCompanyKey,
  employeePlatformCompanySignupKey,
  youtubeKey,
  companyChallengeKey,
  companyKey,
  companySectionKey,
  contactUsMailKey,
  candidatePlatformResetPasswordKey,
  candidatePlatformCompanyResetPasswordKey,
  employeePlatformCompanyResetPasswordKey,
  candidateExperienceJourneyKey,
  employeeExperienceJourneyKey,
  HRJourneyKey,
  aDayWithPageKey,
  platformCXTermsAndConditionsKey,
  platformEXTermsAndConditionsKey,
  documentMSTeamsTermsAndConditionsKey,
  documentTermsAndConditionsKey,
  contactPageKey,
  mediumBlogKey
} from "./pathKeys"

const urls: TranslationType = {
  [candidatePlatformKey]: {
    en: "/candidates/login",
    it: "/candidati/login"
  },
  [candidatePlatformSignupKey]: {
    en: "/candidates/signup",
    it: "/candidati/signup"
  },
  [candidatePlatformResetPasswordKey]: {
    en: "/candidates/reset-password/:userId/:token",
    it: "/candidati/reset-password/:userId/:token"
  },
  [candidatePlatformCompanyKey]: {
    en: "/companies/:companyName/candidates/login",
    it: "/aziende/:companyName/candidati/login"
  },
  [candidatePlatformCompanySignupKey]: {
    en: "/companies/:companyName/candidates/signup",
    it: "/aziende/:companyName/candidati/signup"
  },
  [candidatePlatformCompanyResetPasswordKey]: {
    en: "/companies/:companyName/candidates/reset-password",
    it: "/aziende/:companyName/candidati/reset-password"
  },
  [employeePlatformKey]: {
    en: "/employees/login",
    it: "/dipendenti/login"
  },
  [employeePlatformSignupKey]: {
    en: "/employees/signup",
    it: "/dipendenti/signup"
  },
  [employeePlatformCompanyKey]: {
    en: "/companies/:companyName/employees/login",
    it: "/aziende/:companyName/dipendenti/login"
  },
  [employeePlatformCompanySignupKey]: {
    en: "/companies/:companyName/employees/signup",
    it: "/aziende/:companyName/dipendenti/signup"
  },
  [employeePlatformCompanyResetPasswordKey]: {
    en: "/companies/:companyName/employees/reset-password",
    it: "/aziende/:companyName/dipendenti/reset-password"
  },

  // two factor authentication
  [twoFactorAuthenticationSetPhoneNumberKey]: {
    en: "/two-factor-authentication/set-phone-number/:userHash",
    it: "/autenticazione-a-due-fattori/imposta-numero-di-telefono/:userHash"
  },
  [twoFactorAuthenticationInsertCodeKeySMS]: {
    en: "/two-factor-authentication/insert-code-sms/:userHash/:phoneNumberEndingDigits",
    it: "/autenticazione-a-due-fattori/inserisci-codice-sms/:userHash/:phoneNumberEndingDigits"
  },
  [twoFactorAuthenticationInsertCodeKeyEmail]: {
    en: "/two-factor-authentication/insert-code-email/:userHash/:maskedEmail",
    it: "/autenticazione-a-due-fattori/inserisci-codice-email/:userHash/:maskedEmail"
  },

  // home
  [homepageKey]: {
    en: "/",
    it: "/"
  },

  // request a demo
  [requestADemoKey]: {
    en: "/book-a-demo",
    it: "/prenota-una-demo"
  },
  [contactPageKey]: {
    en: "/makeitsimple",
    it: "/fallasemplice"
  },

  // products
  [productsKey]: {
    en: "/products/",
    it: "/prodotti/"
  },
  [candidateExperienceKey]: {
    en: "/products/candidate-experience",
    it: "/prodotti/candidate-experience"
  },
  [employeeExperienceKey]: {
    en: "/products/employee-experience",
    it: "/prodotti/employee-experience"
  },
  [peopleAnalyticsKey]: {
    en: "/products/people-analytics",
    it: "/prodotti/people-analytics"
  },
  [liveKey]: {
    en: "/products/live",
    it: "/prodotti/live"
  },
  [blogKey]: {
    en: "https://blog.glickon.com/",
    it: "https://blog.glickon.com/"
  },
  [mediumBlogKey]: {
    en: "https://glickon.medium.com/",
    it: "https://glickon.medium.com/"
  },

  // adaywith
  [aDayWithPageKey]: {
    en: "/adaywith",
    it: "/adaywith"
  },
  [candidateExperienceJourneyKey]: {
    en: "/adaywith/candidate",
    it: "/adaywith/candidate"
  },
  [employeeExperienceJourneyKey]: {
    en: "/adaywith/employee",
    it: "/adaywith/employee"
  },
  [HRJourneyKey]: {
    en: "/adaywith/hr",
    it: "/adaywith/hr"
  },

  // about
  [aboutUsKey]: {
    en: "/about-us",
    it: "/chi-siamo"
  },
  [teamKey]: {
    en: "/team",
    it: "/team"
  },
  [jobKey]: {
    en: "https://www.glickon.com/en/companies/glickon",
    it: "https://www.glickon.com/it/aziende/glickon"
  },
  [mailKey]: {
    en: "mailto:team@glickon.com",
    it: "mailto:team@glickon.com"
  },
  [contactUsMailKey]: {
    en: "mailto:sales@glickon.com?subject=/:emailSubject",
    it: "mailto:sales@glickon.com?subject=/:emailSubject"
  },
  [eventMailKey]: {
    en: "mailto:events@glickon.com?subject=GlickonExperience 2019 Registration",
    it:
      "mailto:events@glickon.com?subject=Richiesta iscrizione GlickonExperience 2019"
  },

  [oneToOneMailKey]: {
    en:
      "mailto:events@glickon.com?subject=GlickonExperience 2019 One-on-One Meeting",
    it:
      "mailto:events@glickon.com?subject=Prenota Expert One-on-one Meeting - GlickonExperience 2019"
  },

  [demoMailKey]: {
    en: "mailto:events@glickon.com?subject=GlickonExperience 2019 Live Demo",
    it:
      "mailto:events@glickon.com?subject=Prenota Live Demo - GlickonExperience 2019"
  },

  // resources
  [resourcesKey]: {
    en: "/resources",
    it: "/risorse"
  },
  [resourcesEmployeeExperienceKey]: {
    en: "/resources-employee-experience-roi",
    it: "/risorse-employee-experience-roi"
  },
  [resourcesCandidateExperienceKey]: {
    en: "/resources-candidate-experience-roi",
    it: "/risorse-candidate-experience-roi"
  },
  [resourcesPeopleAnalyticsKey]: {
    en: "/resources-people-analytics-roi",
    it: "/risorse-people-analytics-roi"
  },

  // case-study
  [caseStudiesKey]: {
    en: "/case-studies",
    it: "/case-studies"
  },
  [caseStudyKey]: {
    en: "/case-study",
    it: "/case-study"
  },

  // platform pages
  [platformTermsAndConditionsKey]: {
    en: "/platform/terms-and-conditions",
    it: "/piattaforma/termini-e-condizioni"
  },
  [platformCXTermsAndConditionsKey]: {
    en: "/platform/terms-and-conditions-cx",
    it: "/piattaforma/termini-e-condizioni-cx"
  },
  [platformEXTermsAndConditionsKey]: {
    en: "/platform/terms-and-conditions-ex",
    it: "/piattaforma/termini-e-condizioni-ex"
  },
  [platformPrivacyKey]: {
    en: "/platform/privacy",
    it: "/piattaforma/privacy"
  },
  [platformCookieKey]: {
    en: "/platform/cookies",
    it: "/piattaforma/cookies"
  },
  [platformSecurityKey]: {
    en: "/platform/security",
    it: "/piattaforma/sicurezza"
  },

  // document pages
  [termsOfServicesKey]: {
    en: "/documents/terms-of-services",
    it: "/documenti/termini-dei-servizi"
  },
  [documentTermsAndConditionsKey]: {
    en: "/documents/terms-and-conditions",
    it: "/documenti/termini-e-condizioni"
  },
  [documentGeneralTermsAndConditionsKey]: {
    en: "/documents/general-terms-and-conditions",
    it: "/documenti/termini-e-condizioni-generali"
  },
  [documentMSTeamsTermsAndConditionsKey]: {
    en: "/documents/microsoft-teams",
    it: "/documenti/microsoft-teams"
  },
  [slaveryPreventionKey]: {
    en: "/documents/prevention-modern-slavery",
    it: "/documenti/prevenzione-schiavitu-moderna"
  },
  [liveTermsAndConditionsKey]: {
    en: "/documents/live/terms-and-conditions",
    it: "/documenti/live/termini-e-condizioni"
  },
  [livePrivacyKey]: {
    en: "/documents/live/privacy",
    it: "/documenti/live/privacy"
  },

  // social
  [linkedinKey]: {
    en: "https://www.linkedin.com/company/glickon-srl",
    it: "https://www.linkedin.com/company/glickon-srl"
  },
  [twitterKey]: {
    en: "https://twitter.com/glickon",
    it: "https://twitter.com/glickon"
  },
  [facebookKey]: {
    en: "https://www.facebook.com/glickon",
    it: "https://www.facebook.com/glickon"
  },
  [mediumKey]: {
    en: "https://medium.com/@Glickon",
    it: "https://medium.com/@Glickon"
  },
  [youtubeKey]: {
    en: "https://www.youtube.com/channel/UCYOvp2Hr7itiwcq8n-SKAHw",
    it: "https://www.youtube.com/channel/UCYOvp2Hr7itiwcq8n-SKAHw"
  },
  [landingAwardKey]: {
    en: "/landing-award",
    it: "/landing-award"
  },

  [companyKey]: {
    en: "/companies/:companyName",
    it: "/aziende/:companyName"
  },
  [companyChallengeKey]: {
    en: "/challenges/:hash",
    it: "/challenge/:hash"
  },
  [companySectionKey]: {
    en: "/sections/:id",
    it: "/sezioni/:id"
  },

  [notFoundKey]: {
    en: "/page-not-found",
    it: "/pagina-non-trovata"
  }
}

export default urls
