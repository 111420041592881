import React, {ReactNode, useState, useEffect} from "react"
import {
  ExternalPageShellWrapper as Wrapper,
  ExternalPageFooter as Footer,
  ExternalPageFooterButton as FooterButton,
  ExternalPageFooterText as FooterText,
  ExternalPageContentWrapper as ContentWrapper,
  ExternalPageHeaderBackground as HeaderBackground,
  ExternalPageHeaderSlide as HeaderSlide
} from "./style"
import TopBar from "./components/externalPageTopBar"
import Translate from "../translate"
import {getCandidateImageUrl, preloadImages} from "../../utils"
import Swiper from "react-id-swiper"

interface Props {
  children?: ReactNode
  companyCareersName: string
  hideFooter: boolean | undefined
  customFooterColor: string | undefined
  customFooterButtonColor: string | undefined
  customBodyBackgroundColor: string | undefined
  customButtonColor: string | undefined
  customPrimaryTextColor: string | undefined
  carouselUrls: string[]
  withTopbar: boolean
  isPublic: boolean
  nextUrl: string
}

const ExternalPageShell: React.FC<Props> = ({
  children,
  companyCareersName,
  hideFooter,
  customFooterColor,
  customFooterButtonColor,
  customBodyBackgroundColor,
  customButtonColor,
  customPrimaryTextColor,
  carouselUrls,
  withTopbar,
  isPublic,
  nextUrl
}: Props) => {
  const carouselFallbackUrl = getCandidateImageUrl(
    "placeholders/placeholder-top-carousel.png"
  )

  if (!carouselUrls || carouselUrls.length < 1) {
    carouselUrls = [carouselFallbackUrl]
  }

  const [isCarouselReady, setIsCarouselReady] = useState<boolean>(false)

  useEffect(
    () =>
      setCustomLinkCustomStyle(
        isPublic,
        customPrimaryTextColor,
        customButtonColor
      ),
    [isPublic, customPrimaryTextColor, customButtonColor]
  )

  useEffect(() => {
    if (isCarouselReady) return

    preloadImages(carouselUrls, () => setIsCarouselReady(true))

    setIsCarouselReady(true)
  }, [carouselUrls, isCarouselReady])

  const sliderParams = {
    allowTouchMove: false,
    resistance: true,
    resistanceRatio: 0,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false
    }
  }

  const carouselId = "external-page-top-carousel"

  return (
    <Wrapper
      id="external-page-shell"
      customBodyBackgroundColor={customBodyBackgroundColor}
    >
      {withTopbar && (
        <TopBar
          nextUrl={nextUrl}
          isPublic={isPublic}
          companyCareersName={companyCareersName}
        />
      )}
      <ContentWrapper
        afterTopbar={withTopbar}
        customBodyBackgroundColor={customBodyBackgroundColor}
      >
        <HeaderBackground id={carouselId}>
          {isCarouselReady && (
            <Swiper {...sliderParams}>
              {carouselUrls.map((url, index) => (
                <HeaderSlide
                  id={`${carouselId}-image-${index}`}
                  className={`${carouselId}-image`}
                  key={`${carouselId}-image-${index}`}
                  url={url || carouselFallbackUrl}
                ></HeaderSlide>
              ))}
            </Swiper>
          )}
        </HeaderBackground>

        {children}
      </ContentWrapper>

      {!hideFooter && (
        <Footer customColor={customFooterColor}>
          <FooterButton
            customColor={customFooterButtonColor}
            href="https://www.glickon.com"
          >
            <FooterText>
              <Translate label="global-powered-by"></Translate>&nbsp;
            </FooterText>
            <FooterText bold>Glickon</FooterText>
          </FooterButton>
        </Footer>
      )}
    </Wrapper>
  )
}

const setCustomLinkCustomStyle = (
  isPublic: boolean,
  aColor: string | undefined,
  aHoverColor: string | undefined
) => {
  if (isPublic || (!aColor && !aHoverColor)) return

  const aStyle = `
  a {
      color: ${aColor};
  }`

  const aHoverStyle = `
  a:hover {
      color: ${aHoverColor};
  }`

  const styleElement = document.createElement("style")
  styleElement.innerHTML = ""
  if (aColor) styleElement.innerHTML += aStyle
  if (aHoverColor) styleElement.innerHTML += aHoverStyle

  document.head.appendChild(styleElement)
}

export default ExternalPageShell
