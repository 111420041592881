import { TranslationType } from "../types"

const translations: TranslationType = {
    "hr-j-headline-title": {
        en: "A day with...<br/>HR",
        it: "A day with...<br/>HR"
    },
    "hr-j-headline-text": {
        en: "Talent wants for challenges, growth, awareness, and seeks space in a corporate culture that rewards expectations. We provide you with a platform to measure and understand the experience of people working in the company. A modular solution, immediate and accessible from any device. A dashboard from which you can activate employees’ engagement, access results in real time and build action plans.",
        it: "Insieme a una parola chiave. Experience. Perché l’esperienza è il primo step che mette in evidenza l’appeal del tuo brand, attirando i candidati con un reale interesse verso la tua cultura aziendale. E da qui che generiamo dati per evidenziare il fit index tra il candidato e le competenze richieste, accelerando così la sua operatività e produttività nel successivo periodo di onboarding."
    },
    "hr-j-footer-headline": {
        en: "Now it's your turn, design your HR platform!",
        it: "Accetta la sfida, progetta la tua piattaforma HR!"
    },
    "hr-j-footer-caption": {
        en: "We put in the design work and data science, but you are the architect drafting talent, ideas and business",
        it: "La costruiamo insieme, ma sarai tu a portare nuovi talenti in azienda"
    },
    "hr-j-script-01": {
        en: "<strong>9.00</strong> Starting the day in the best way: a comprehensive and preventive analysis of candidates and employees.After coffee, of course!",
        it: "<strong>9.00</strong> La giornata inizia al meglio: un’analisi completa degli impiegati e candidati in azienda. Ma prima, caffè!"
    },
    "hr-j-script-02": {
        en: "<strong>10.00<br></strong>With so much data, it’s easy to get an overview of the network...",
        it: "<strong>10.00<br/></strong>Con così tanti dati, non è difficile ottenere una panoramica del network aziendale…"
    },
    "hr-j-script-03": {
        en: "<strong>10.30<br></strong>...together with some insights, useful to go beyond patterns, to understand how people actually communicate and collaborate.",
        it: "<strong>10.30<br/></strong>...insieme ad alcuni insight utili per superare i soliti schemi, per capire davvero come le persone comunicano e collaborano."
    },
    "hr-j-script-04": {
        en: "<strong>11.00<br></strong>Also, it’s a great chance to figure out the essential figures – around here known as “key people”.",
        it: "<strong>11.00<br/></strong>È anche una grande opportunità di scoprire chi sono le figure essenziali – le “persone chiave”."
    },
    "hr-j-script-05": {
        en: "<strong>13.00<br>‍</strong>But wait a moment: in order to focus, a quick break is essential to regenerate body and mind.",
        it: "<strong>13.00<br/></strong>Un attimo: per concentrarsi, una breve pausa è fondamentale per rigenerare mente e corpo."
    },
    "hr-j-script-06": {
        en: "<strong>14.00<br></strong>In this organization everyone is equally important – indeed, every future member can improve the overall performance of the team.",
        it: "<strong>14.00<br/></strong>In quest’azienda ognuno ha la stessa importanza – anzi, ogni futuro collega può migliorare le performance collettive del team."
    },
    "hr-j-script-07": {
        en: "<strong>15.00<br></strong>Likewise, a high level of motivation and engagement means a better experience and productivity.",
        it: "<strong>15.00<br/></strong>Allo stesso modo, un buon livello di motivazione e ingaggio si traduce in una migliore esperienza e in maggiore produttività."
    },
    "hr-j-script-08": {
        en: "<strong>16.00<br></strong>Nothing is better than knowing exactly what are the aces up your sleeve!",
        it: "<strong>16.00<br/></strong>Non c’è sensazione migliore che conoscere i tuoi assi nella manica."
    },
    "hr-j-script-09": {
        en: "<strong>17.00<br></strong>It’s just a matter of imagining and building a more effective structure – developed around the company, around the people.",
        it: "<strong>17.00<br/></strong>Alla fine, è solo un problema di immaginare e costruire una struttura più efficace – sviluppata attorno all’azienda, attorno alle persone."
    },
    "hr-j-script-10": {
        en: "<strong>18.00<br></strong>And remember: a final debrief is essential before spreading the news with everyone on board.",
        it: "<strong>18.00<br/></strong>E ricorda: un debrief finale è essenziale prima di diffondere la novità con tutte le persone a bordo."
    },
    "hr-j-script-11": {
        en: "<strong>20.00<br></strong>See you tomorrow, now it’s time to relax!",
        it: "<strong>20.00<br/></strong>A domani, è ora di rilassarsi!"
    },
}

export default translations
