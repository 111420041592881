import {
  Platform,
  RedirectedPageMetadata,
  RedirectedPageType,
  RedirectMetadata
} from "../types/redirectMetadata"
import {
  candidatePlatformCompanyKey,
  candidatePlatformCompanySignupKey,
  candidatePlatformKey,
  candidatePlatformSignupKey,
  employeePlatformCompanyKey,
  employeePlatformCompanySignupKey,
  employeePlatformKey,
  employeePlatformSignupKey
} from "../constants/pathKeys"
import {candidatePlatformUrl} from "../constants/global"
import {
  appendToQueryString,
  generateLocalizedPath,
  getParametrizedPath
} from "../utils"

export interface ExternalPageRedirectInput {
  careersName?: string
  sectionId?: number
  challengeHash?: string
  corePath: string
}

export interface ExternalPageRedirectOutput {
  notificationMessageLabel?: string
  redirectPath?: string
  forcedLogout?: boolean
}

export const handleExternalPageRedirect = (
  redirectMetadata: RedirectMetadata | undefined,
  locale: string,
  {careersName, sectionId, challengeHash, corePath}: ExternalPageRedirectInput
): ExternalPageRedirectOutput => {
  const optionalParametersCount = assertOptionalParameters(
    careersName,
    sectionId,
    challengeHash
  )

  if (optionalParametersCount === 0) return {}

  const {forcedLogout, redirectedPage} = (redirectMetadata ||
    {}) as RedirectMetadata

  if (!forcedLogout && !redirectedPage) {
    return {}
  }

  // Possible scenarios are:
  // - Forced logout only -> no redirect, only notification + clean auth state
  // - Forced logout and redirectedPage (with pageType `Login` and `Signup`) -> notification + redirect to employee
  // - redirectedPage with pageType LoggedDashboard`` -> no notification, redirect to core, maintaining the resource identity (for example a company challenge hash)

  if (
    redirectedPage &&
    redirectedPage.pageType === RedirectedPageType.LoggedDashboard
  ) {
    window.location.href = candidatePlatformUrl + corePath
  }

  // Construct the react internal redirect to employee access
  // and the relative next_url parameter
  const redirectPath =
    redirectedPage && getInternalRedirectPath(redirectedPage, locale, corePath)

  const notificationMessageLabel = forcedLogout
    ? "global-forced-logout"
    : undefined

  return {notificationMessageLabel, redirectPath, forcedLogout}
}

export const getCorePath = ({
  careersName,
  challengeHash,
  sectionId
}: {
  careersName?: string
  challengeHash?: string
  sectionId?: number
}): string =>
  careersName
    ? `/companies/${careersName}`
    : challengeHash
    ? `/company-challenges/${challengeHash}`
    : `/sections/${sectionId}`

function assertOptionalParameters(
  careersName: string | undefined,
  sectionId: number | undefined,
  challengeHash: string | undefined
) {
  const optionalParametersCount =
    (!!careersName ? 1 : 0) + (!!sectionId ? 1 : 0) + (!!challengeHash ? 1 : 0)

  if (optionalParametersCount > 1) {
    throw new Error(
      "useExternalPageRedirect should be used with exactly one optional parameter. Zero parameters is viable but it will just skip the computation"
    )
  }

  return optionalParametersCount
}

function getInternalRedirectPath(
  redirectedPage: RedirectedPageMetadata,
  locale: string,
  nextUrl: string
): string {
  let pathKey: string

  const companyNameParam = redirectedPage.companyCareersName && {
    key: "companyName",
    value: redirectedPage.companyCareersName
  }
  const params: {
    key: string
    value: string
  }[] = redirectedPage.companyCareersName
    ? companyNameParam
      ? [companyNameParam]
      : []
    : []

  if (
    redirectedPage.pageType === RedirectedPageType.Login &&
    redirectedPage.platform === Platform.Candidates
  ) {
    pathKey = redirectedPage.companyCareersName
      ? candidatePlatformCompanyKey
      : candidatePlatformKey
  } else if (
    redirectedPage.pageType === RedirectedPageType.Login &&
    redirectedPage.platform === Platform.Employees
  ) {
    pathKey = redirectedPage.companyCareersName
      ? employeePlatformCompanyKey
      : employeePlatformKey
  } else if (
    redirectedPage.pageType === RedirectedPageType.Signup &&
    redirectedPage.platform === Platform.Candidates
  ) {
    pathKey = redirectedPage.companyCareersName
      ? candidatePlatformCompanySignupKey
      : candidatePlatformSignupKey
  } else if (
    redirectedPage.pageType === RedirectedPageType.Signup &&
    redirectedPage.platform === Platform.Employees
  ) {
    pathKey = redirectedPage.companyCareersName
      ? employeePlatformCompanySignupKey
      : employeePlatformSignupKey
  } else {
    // LoggedDashboard pageType redirect is managed by setting window.location.href
    pathKey = ""
  }

  const rawRedirectPath = getParametrizedPath(
    generateLocalizedPath(pathKey, locale),
    params
  )

  return appendToQueryString(
    rawRedirectPath,
    `next_url=/${nextUrl.replaceAll("//", "/")}`
  )
}

export const getIsAboutToRedirect = (
  data: RedirectMetadata | undefined,
  error: string | RedirectMetadata | undefined
) =>
  (data && data.redirectedPage) ||
  (error && typeof error !== "string" && error.redirectedPage)
