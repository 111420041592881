import styled from "styled-components"
import styleConstants from "../../constants/styleConstants"
import SimpleImage from "../image"

export const Logo = styled(SimpleImage)<{isDefaultLogo: boolean}>`
  max-width: 100%;
  max-height: ${({isDefaultLogo}) => (isDefaultLogo ? "2.2rem" : "5rem")};
  border-radius: ${({isDefaultLogo}) => (isDefaultLogo ? "0" : "0.25rem")};

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    max-height: ${({isDefaultLogo}) => (isDefaultLogo ? "2.2rem" : "6rem")};
  }
`

export const LogoContainer = styled.div<{isDefaultLogo: boolean}>`
  display: inline-block;
  margin-top: 0.3rem;
  margin-bottom: ${({isDefaultLogo}) => (isDefaultLogo ? "2.3rem" : "1.5rem")};

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    margin-top: 3rem;
    margin-bottom: ${({isDefaultLogo}) =>
      isDefaultLogo ? "3.75rem" : "2.5rem"};
  }
`
