import styled from "styled-components"
import styleConstants from "../../constants/styleConstants"

export const CheckSign = styled.i`
  position: absolute;
  opacity: 0;
  left: 0.125rem;
  top: 0.35rem;
  transition: opacity 0.2s ease;
  color: ${styleConstants.white};
  font-size: 0.8rem;
`

/* Create a custom checkbox */
export const Checkmark = styled.span`
  position: absolute;
  top: 0.1875rem;
  left: 0;
  height: 1.05rem;
  width: 1.05rem;
  background-color: ${styleConstants.white};
  border: 0.0625rem solid ${styleConstants.dark03};
  border-radius: ${styleConstants.defaultBorderRadius};
  transition: all 0.2s ease;
`

/* Hide the browser's default checkbox */
export const Check = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked + ${Checkmark} {
    background-color: ${styleConstants.blue00};
    border-color: ${styleConstants.blue00};
  }

  &:checked ~ ${CheckSign} {
    opacity: 1;
  }
`

export const Container = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 1.1rem;
  cursor: pointer;
  user-select: none;
`

export const StyledLabel = styled.div<{
    customColor: string
}>`
  padding-top: 0.2rem;
  padding-left: 1.75rem;
  font-family: ${styleConstants.calibre};
  font-size: ${styleConstants.font.size14};
  color: ${props => props.customColor ? props.customColor : styleConstants.dark01};

  a {
    color: ${props => props.customColor ? props.customColor : styleConstants.dark01};
    text-decoration: underline;
  }
`
