import { TranslationType } from "../types"

const translations: TranslationType = {
  // Header
  "signup-header-title": {
    en: "Make work more human and productive",
    it: "Un'esperienza unica di lavoro"
  },
  "signup-header-mobile-title": {
    en: "Make work more human and productive",
    it: "Un'esperienza unica <br/> di lavoro"
  },
  "signup-header-description": {
    en:
      "Glickon make it easy for anyone to show off their skills, grow in their role and <br/> live personalized and fun work experiences",
    it:
      "Glickon rende semplice e divertente per ciascuno vivere esperienze professionali in linea con le proprie competenze e interessi e essere ispirati dal proprio lavoro"
  },
  "signup-with-facebook": {
    en: "Sign up with Facebook",
    it: "Registrati con Facebook"
  },
  "signup-with-linkedin": {
    en: "Sign up with Linkedin",
    it: "Registrati con Linkedin"
  },
  "signup-with-google": {
    en: "Sign up with Google",
    it: "Registrati con Google"
  },

  // Cards
  // Candidate
  "signup-card-candidate-tagline": {
    en: "Candidate Experience",
    it: "Candidate Experience"
  },
  "signup-card-candidate-title": {
    en: "For Candidates",
    it: "Per Candidati"
  },
  "signup-card-candidate-description": {
    en: "Get matched with <br/> a job you love",
    it: "Vivi esperienze di lavoro indimenticabili"
  },
  "signup-card-candidate-action-text": {
    en: "Sign up",
    it: "Registrati"
  },
  "signup-card-candidate-help-text": {
    en: "Have an account?",
    it: "Hai già un profilo?"
  },
  "signup-card-candidate-help-action-text": {
    en: "Log in",
    it: "Accedi"
  },

  // Employee
  "signup-card-employee-tagline": {
    en: "Employee Experience",
    it: "Employee Experience"
  },
  "signup-card-employee-title": {
    en: "For Employees",
    it: "Per Dipendenti"
  },
  "signup-card-employee-description": {
    en: "Unleash talent potential with <b>Glow</b> <br/> <b>Sign up</b> or <b>Log in</b> to your account",
    it: "Fai brillare il talento con <b>Glow</b> <br/> <b>Registrati</b> o <b>Accedi</b> se hai già un account"
  },
  "signup-card-employee-action-text": {
    en: "Sign up",
    it: "Registrati"
  },
  "signup-card-employee-help-text": {
    en: "Have an account?",
    it: "Hai già un profilo aziendale?"
  },
  "signup-card-employee-help-action-text": {
    en: "Log in",
    it: "Accedi"
  },

  // Corporate
  "signup-card-corporate-tagline": {
    en: "HR Experience",
    it: "HR Experience"
  },
  "signup-card-corporate-title": {
    en: "For Employers",
    it: "Per Aziende"
  },
  "signup-card-corporate-description": {
    en: "Turn People Experience into a competitive advantage",
    it: "Metti le persone al centro della tua strategia di business"
  },
  "signup-card-corporate-action-text": {
    en: "Request a demo",
    it: "Richiedi una demo"
  },
  "signup-card-corporate-help-text": {
    en: "Registered as a company?",
    it: "Sei già registrato come azienda?"
  },
  "signup-card-corporate-help-action-text": {
    en: "Log in",
    it: "Accedi"
  },

  // Info Section
  // Candidate
  // "info-section-candidate-tagline": {
  //   en: "Perchè scegliere Glickon per candidarsi?",
  //   it: "Perchè scegliere Glickon per candidarsi?"
  // },
  "info-section-candidate-title-1": {
    en: "No stress",
    it: "No stress"
  },
  "info-section-candidate-description-1": {
    en:
      "No long forms to fill out, just a streamlined way to show off your skills playing trivia games.",
    it:
      "Dimentica i lunghi form da compilare e invia la tua candidatura giocando."
  },
  "info-section-candidate-title-2": {
    en: "Opportunities",
    it: "Opportunità"
  },
  "info-section-candidate-description-2": {
    en:
      "We match you with compelling opportunities at the world's most innovative companies.",
    it:
      "Entra in contatto con le migliori opportunità di lavoro in base alle tue esperienze e competenze."
  },
  "info-section-candidate-title-3": {
    en: "Community",
    it: "Community"
  },
  "info-section-candidate-description-3": {
    en:
      "Join thousands of people who’ve found their dream job using Glickon and have fun challeging them.",
    it:
      "Unisciti a migliaia di persone che hanno trovato lavoro con Glickon e divertiti a sfidarli."
  },

  // Employee
  // "info-section-employee-tagline": {
  //   en: "Cosa puoi fare nell'area aziendale?",
  //   it: "Cosa puoi fare nell'area aziendale?"
  // },
  "info-section-employee-title-1": {
    en: "Learn the skills you need to succeeed ",
    it: "Le competenze per avere successo"
  },
  "info-section-employee-description-1": {
    en:
      "Learn new skills, grow in your role and stay relevant on critical topics.",
    it:
      "Impara nuove competenze, fai carriera e rimani aggiornato per il lavoro di domani."
  },
  "info-section-employee-title-2": {
    en: "Be happy at work",
    it: "Un'esperienza unica"
  },
  "info-section-employee-description-2": {
    en:
      "Help your oganization designing an environment where you can do your best work.",
    it:
      "Aiuta la tua azienda a creare un clima di lavoro ideale perché tu possa dare il meglio."
  },
  "info-section-employee-title-3": {
    en: "Grow your community",
    it: "Sviluppa il tuo network professionale"
  },
  "info-section-employee-description-3": {
    en:
      "Join your colleagues, share your knowledge and connect with people who can help.",
    it:
      "Entra in contatto con i colleghi, condividi le tue capacità e collegati con le persone che possano aiutarti."
  },

  // Corporate
  // "info-section-corporate-tagline": {
  //   en: "Perchè scegliere Glickon per candidarsi?",
  //   it: "Perchè scegliere Glickon per candidarsi?"
  // },
  "info-section-corporate-title-1": {
    en: "Deliver next generation people experience",
    it: "Disegna esperienze indimenticabili"
  },
  "info-section-corporate-description-1": {
    en:
      "Meet the expectations of a modern workforce across the full employee lifecycle.",
    it:
      "Soddisfa le aspettative di una forza lavoro moderna lungo l'intero ciclo di vita dei dipendenti."
  },
  "info-section-corporate-title-2": {
    en: "Efficient hiring",
    it: "Migliora l'efficienza nelle selezioni"
  },
  "info-section-corporate-description-2": {
    en: "Evaluate and identify the best talent, faster.",
    it: "Valuta e assumi i migliori talenti più velocemente."
  },
  "info-section-corporate-title-3": {
    en: "Make work better and unlock productivity",
    it: "Aumenta coinvolgimento e produttività"
  },
  "info-section-corporate-description-3": {
    en:
      "Combine skills, network and experience data to create happier, more-engaged and better-developed employees.",
    it:
      "Combina dati sulle competenze, sulle reti relazionali e sull'esperienza per dare vita ad un'organizzazione più capace e motivata."
  },
  "signup-form-subtitle": {
    en: "Sign up with Email:",
    it: "Utilizza la tua email per registrarti:"
  }
}

export default translations
