import React from "react"
import {RouteComponentProps, withRouter, Redirect} from "react-router"
import {candidatePlatformUrl} from "../../../constants/global"
import AccessLogin from "../../../commonComponents/access-login"
import {AccessUserType} from "../../../types/accessUser"
import useCompanyInfo from "../../../hooks/useCompanyInfo"
import {generateLocalizedPath} from "../../../utils"
import {notFoundKey} from "../../../constants/pathKeys"
import useTranslation from "../../../hooks/useTranslation"
import {
  loginEmployee,
  resetPasswordEmployee,
  resendEmailEmployee
} from "../../../requests/employeePlatform"
import LoaderFullScreen from "../../../commonComponents/loader-full-screen"
import {EmailFloatingButton} from "../../../commonComponents/email-floating-button/EmailFloatingButton"

interface ParamsType {
  companyName?: string
}

type PropsType = RouteComponentProps<ParamsType> & {}
const EmployeePlatformLogin: React.FC<PropsType> = ({
  match: {
    params: {companyName}
  }
}: PropsType) => {
  const {
    companyInfo,
    companyInfoRetrieved: readyToRender,
    requiredCompanyInfoNotAvailable
  } = useCompanyInfo("employees", companyName)
  const [, locale] = useTranslation()

  if (!readyToRender) return <LoaderFullScreen />

  const hasCustomBackground = !!companyInfo.landingPageBackgroundUrl || false
  const authenticationMethods = companyInfo.supportedAuthenticationMethods || []

  return requiredCompanyInfoNotAvailable ? (
    <Redirect to={`${generateLocalizedPath(notFoundKey, locale)}`} />
  ) : (
    <React.Fragment>
      <AccessLogin
        userType={AccessUserType.Employee}
        companyName={companyName}
        loginPrompts={authenticationMethods}
        loginFunction={loginEmployee}
        loginRedirectUrl={candidatePlatformUrl}
        resetPasswordFunction={resetPasswordEmployee}
        resendEmailFunction={resendEmailEmployee}
        hasCustomBackground={hasCustomBackground}
        backgroundUrl={
          companyName ? companyInfo.landingPageBackgroundUrl : undefined
        }
        logoUrl={companyInfo.logoUrl}
      />

      <EmailFloatingButton />
    </React.Fragment>
  )
}

export default withRouter<PropsType, React.FC<PropsType>>(EmployeePlatformLogin)
