import styled from "styled-components"
import styleConstants from "../../constants/styleConstants"

// eslint-disable-next-line no-unexpected-multiline
export const Container = styled.div<{
  haveOpacity: boolean | undefined
}>`
  opacity: ${({haveOpacity}) => (haveOpacity ? "1" : "0")};
  transition: opacity 1s ease-out;
  padding: 1rem 0 1rem;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: ${styleConstants.black};
  color: ${styleConstants.white};
  font-size: ${styleConstants.font.size12};
  font-family: ${styleConstants.calibreSemibold};

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    padding: 1.2rem 0;
    font-size: ${styleConstants.font.size14};
  }
`
