import React from "react"
import {
  ExternalPageTopBarWrapper as Wrapper,
  ExternalPageTopBarInnerWrapper as InnerWrapper,
  ExternalPageTopBarLeftWrapper as LeftWrapper,
  ExternalPageTopBarLogo as Logo,
  ExternalPageTopBarRightWrapper as RightWrapper,
  ExternalPageTopBarLoginButton as LoginButton,
  ExternalPageTopBarLoginButtonText as LoginButtonText,
  ExternalPageTopBarLogoWrapper as LogoWrapper
} from "./style"
import {getCandidateImageUrl} from "../../../utils"
import Translate from "../../translate"
import {
  candidatePlatformKey,
  candidatePlatformCompanyKey,
  candidatePlatformSignupKey,
  candidatePlatformCompanySignupKey
} from "../../../constants/pathKeys"
import {navigateToLoginOrSignupByKey} from "../../../utils"
import useTranslation from "../../../hooks/useTranslation"

interface Props {
  isPublic: boolean
  companyCareersName: string
  nextUrl: string
}

const ExternalPageTopBar: React.FC<Props> = ({
  isPublic,
  companyCareersName,
  nextUrl
}) => {
  const [, locale] = useTranslation()

  const onAction = (isLogin: boolean) => {
    const redirectKey =
      isLogin && isPublic
        ? candidatePlatformKey
        : isLogin && !isPublic
        ? candidatePlatformCompanyKey
        : !isLogin && isPublic
        ? candidatePlatformSignupKey
        : candidatePlatformCompanySignupKey

    navigateToLoginOrSignupByKey({
      key: redirectKey,
      locale,
      companyCareersName,
      nextUrl
    })
  }

  return (
    <Wrapper isPublic={isPublic}>
      <InnerWrapper className="row align-center">
        <LeftWrapper>
          <LogoWrapper href="https://www.glickon.com">
            <Logo
              id="company-logo"
              src={getCandidateImageUrl("logos/logo-header.png")}
            />
          </LogoWrapper>
        </LeftWrapper>
        <RightWrapper>
          <LoginButton isPublic={isPublic} transparent hideOnMobile>
            <LoginButtonText
              className="external-page-login"
              isPublic={isPublic}
              transparent
              onClick={() => onAction(true)}
            >
              <Translate label={"global-navigation-signin"} />
            </LoginButtonText>
          </LoginButton>

          <LoginButton isPublic={isPublic}>
            <LoginButtonText
              className="external-page-signup"
              isPublic={isPublic}
              onClick={() => onAction(false)}
            >
              <Translate label={"global-navigation-get-started"} />
            </LoginButtonText>
          </LoginButton>
        </RightWrapper>
      </InnerWrapper>
    </Wrapper>
  )
}

export default ExternalPageTopBar
