import styled from "styled-components"
import styleConstants from "../../../constants/styleConstants"

// eslint-disable-next-line no-unexpected-multiline
export const CompanyChallengeDetailsDivider = styled.div<{
  customColor: string | undefined
}>`
  margin-top: 1.75rem;
  margin-bottom: 2rem;

  background-color: ${({customColor}) =>
    customColor || styleConstants.dividerGray};

  height: 0.0625rem;
`

// eslint-disable-next-line no-unexpected-multiline
export const CompanyChallengeDescriptionText = styled.div<{
  customColor: string | undefined
}>`
  font-family: ${styleConstants.calibre};
  font-size: ${styleConstants.font.size18};
  color: ${({customColor}) => customColor || styleConstants.dark01};
  padding-bottom: 1rem;
  line-height: normal;

  & > p {
    line-height: normal;
  }
`

// eslint-disable-next-line no-unexpected-multiline
export const CompanyChallengeApplyWrapper = styled.div<{
  customColor: string | undefined
}>`
  background-color: ${({customColor}) => customColor || styleConstants.blue02};
  padding-top: 3rem;
  padding-bottom: 3rem;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    justify-content: center;
    padding-left: 1rem;
    padding-right: 1rem;
  }
`

export const CompanyChallengeApplyRow = styled.div`
  padding: 0 0.8rem;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    padding: 0;
    justify-content: center;
  }
`

export const CompanyChallengeApplyColumn = styled.div`
  flex-grow: 1;
  padding: 0;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    justify-content: center;
  }
`

export const LoginFormWrapper = styled.div<{
    customBodyBackgroundColor?: string
}>`
    position: relative;
    margin-top: 4rem;
    margin-bottom: 3rem;
    background-color: ${props => props.customBodyBackgroundColor || styleConstants.white};
    width: 100%;
    border: solid 0.0625rem ${props => props.customBodyBackgroundColor ? "#27292b" : styleConstants.cardBorder};
    border-radius: ${styleConstants.defaultBorderRadius};
    z-index: 99;
    @media (max-width: ${styleConstants.desktopMinWidth}) {
        width: calc(100% - 2rem);
        left: 1rem;
      }
`
