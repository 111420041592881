import React from "react"
import {Container} from "./styles"

interface PropsType {
  color?: string
  space: number
  spaceDesktop?: number
}

const Spacer: React.FC<PropsType> = (props: PropsType) => {
  const {color = "transparent", space, spaceDesktop} = props

  return (
    <Container
      color={color}
      space={space}
      spaceDesktop={typeof spaceDesktop !== "undefined" ? spaceDesktop : space}
    />
  )
}

export default Spacer
