import React from "react"
import {GlobalStateType} from "./types"

const Context = React.createContext<GlobalStateType>({
  loading: true,
  dictionary: {},
  caseStudies: [],
  press: [],
  preregisterEmail: ""
})

export default Context
