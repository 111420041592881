import { TranslationType } from "../types"

const translations: TranslationType = {
  "global-click-to-edit": {
    en: "ctrl+click to edit",
    it: "ctrl+click per editare"
  },
  "global-divider": {
    en: "Or",
    it: "Oppure"
  },

  // navigation (top & footer)
  "global-navigation-products": {
    en: "Products",
    it: "Prodotti"
  },
  "global-navigation-about": {
    en: "Company",
    it: "Chi siamo"
  },
  "global-navigation-case-study": {
    en: "Customers",
    it: "Clienti"
  },
  "global-navigation-resources": {
    en: "Resources",
    it: "Risorse"
  },
  "global-navigation-medium": {
    en: "Medium",
    it: "Medium"
  },
  "global-navigation-join-us": {
    en: "We are hiring",
    it: "Lavora con noi"
  },
  "global-navigation-terms-and-conditions": {
    en: "Terms and conditions",
    it: "Termini e condizioni"
  },
  "global-navigation-terms-and-conditions-cx": {
    en: "Candidate Experience",
    it: "Candidate Experience"
  },
  "global-navigation-terms-and-conditions-ex": {
    en: "Employee Experience",
    it: "Employee Experience"
  },
  "global-navigation-privacy": {
    en: "Privacy",
    it: "Privacy"
  },
  "global-navigation-cookie": {
    en: "Cookie policy",
    it: "Cookie policy"
  },
  "global-navigation-security": {
    en: "Security",
    it: "Sicurezza"
  },
  "global-navigation-follow-us": {
    en: "Follow glickon",
    it: "Segui glickon"
  },
  "global-navigation-company": {
    en: "About us",
    it: "Azienda"
  },
  "global-navigation-team": {
    en: "Our team",
    it: "Il nostro team"
  },
  "global-navigation-job": {
    en: "Careers",
    it: "Opportunità lavorative"
  },
  "global-navigation-contact-us": {
    en: "Contact us",
    it: "Contattaci"
  },
  "global-navigation-support": {
    en: "Support",
    it: "Supporto"
  },
  "global-navigation-signin": {
    en: "Log in",
    it: "Accedi"
  },
  "global-navigation-get-started": {
    en: "Sign up",
    it: "Registrati"
  },
  "global-navigation-looking-for-job": {
    en: "Job Seeker?",
    it: "Cerchi lavoro?"
  },
  "global-navigation-go-to-candidate": {
    en: "Job offers",
    it: "Offerte di lavoro"
  },
  "global-navigation-go-to-corporate": {
    en: "Go to dashboard",
    it: "Vai alla piattaforma"
  },

  // products sub menu info
  "global-navigation-submenu-title": {
    en: "What are your HR goals?",
    it: "Quali sono i tuoi obiettivi HR?"
  },
  "global-navigation-submenu-description": {
    en: `
            Let’s establish them together to improve Candidate and Employee Experience for your company.
        `,
    it: `
            Scopriamoli insieme e miglioriamo Candidate e Employee Experience della tua organizzazione.
        `
  },

  // global links
  "global-request-a-demo": {
    en: "Request demo",
    it: "Richiedi una demo"
  },
  "global-look-at-video": {
    en: "Watch the video",
    it: "Guarda il video"
  },
  "global-discover-more": {
    en: "Discover more",
    it: "Scopri di più"
  },
  "global-subscribe": {
    en: "Relive the event",
    it: "Rivivi l'evento"
  },
  "global-discover-case-study": {
    en: "Discover the case study",
    it: "Scopri il case study"
  },

  // footer info
  "global-company-address": {
    en: "Via Nino Bixio 7 Milan, 20129 - Italy",
    it: "Via Nino Bixio 7 Milano, 20129 - Italia"
  },
  "global-company-phone": {
    en: "(+39) 02 3655 6730",
    it: "(+39) 02 3655 6730"
  },
  "global-company-email": {
    en: "team@glickon.com",
    it: "team@glickon.com"
  },
  "global-company-copy": {
    en:
      "Copyright © 2022 Glickon S.p.A. All rights reserved - VAT NUMBER: 08573820969",
    it:
      "Copyright © 2022 Glickon S.p.A Tutti i diritti riservati - P.Iva: 08573820969"
  },
  "global-cookie-bar-text": {
    en: `
            Glickon uses cookies to improve your experience. By continuing to use Glickon and / or by clicking on any 
            element of the page you accept their use. For further information 
        `,
    it: `
            Glickon utilizza cookie per migliorare la vostra esperienza. Continuando a utilizzare Glickon e/o cliccando 
            su un qualsiasi elemento della pagina si accetta il loro utilizzo. Per approfondimenti 
        `
  },
  "global-click-here": {
    en: "click here",
    it: "clicca qui"
  },
  "global-page-not-found": {
    en: "This page does not exist",
    it: "La pagina richiesta non esiste."
  },
  "global-go-home": {
    en: "go to home",
    it: "vai alla home"
  },
  "global-page-not-found-description": {
    en: `
            Please Check the URL and try again. If you think the problem is on our end we urge you to contact us at: 
            <strong><a href="mailto:support@glickon.com" target="_blank">support@glickon.com</a></strong> for more 
            information or to send a support request to our team.
        `,
    it: `
            Verifica l'URL e riprova. Pensi che il problema sia dovuto a noi? 
            Contatta <strong><a href="mailto:support@glickon.com" target="_blank">support@glickon.com</a></strong>  per ulteriori informazioni o per 
            inviare una richiesta di supporto al nostro team.
        `
  },

  // validator
  "global-generic-input-placeholder": {
    en: "Write here...",
    it: "Scrivi qui..."
  },
  "global-error-field-mandatory": {
    en: "This field is mandatory",
    it: "Questo campo è obbligatorio"
  },
  "global-error-field-minimum-length": {
    en: "At least {{minChars}} characters required",
    it: "Necessari almeno {{minChars}} caratteri"
  },
  "global-error-field-password-length": {
    en:
      "Make sure the password has between {{minChars}} and {{maxChars}} characters",
    it:
      "Assicurati che la password abbia tra {{minChars}} e {{maxChars}} caratteri"
  },
  "global-error-password-validation": {
    en: "The password must be between 8 and 15 characters, a number and special character and must contain upper and lower case",
    it: "La password deve comprendere tra gli 8 e i 15 caratteri, un numero e carattere speciale e deve contenere maiuscole e minuscole"
  },
  "global-error-field-invalid-email": {
    en: "Insert a valid email",
    it: "Email non valida"
  },
  "global-error-field-invalid-free-email": {
    en: "Insert a valid company email",
    it: "Email aziendale non valida"
  },
  "global-error-field-terms-and-conditions-mandatory": {
    en: "Accept Terms and Conditions of Use",
    it: "Accetta Termini e Condizioni d’uso"
  },
  "global-error-field-custom-privacy-mandatory": {
      en: "Accept privacy policy",
      it: "Accetta privacy policy"
  },

  // form
  "global-form-title": {
    en: "Fill all form inputs to request a demo",
    it: "Compila tutti i campi per richiedere una demo"
  },
  "global-form-required-fields": {
    en: "Marked fields must be corrected",
    it: "I campi contrassegnati devono essere corretti"
  },
  "global-firstname-label": {
    en: "First Name",
    it: "Nome"
  },
  "global-firstname-placeholder": {
    en: "First Name",
    it: "Nome"
  },
  "global-password-label": {
    en: "Password",
    it: "Password"
  },
  "global-lastname-label": {
    en: "Last Name",
    it: "Cognome"
  },
  "global-lastname-placeholder": {
    en: "Last Name",
    it: "Cognome"
  },
  "global-customsso-label-generic": {
    en: "Login with SSO",
    it: "Accedi tramite SSO"
  },
  "global-customsso-label-microsoft": {
    en: "Login with Microsoft",
    it: "Accedi con Microsoft"
  },
  "global-company-label": {
    en: "Company",
    it: "Azienda"
  },
  "global-company-placeholder": {
    en: "Company",
    it: "Azienda"
  },
  "global-email-label": {
    en: "Email",
    it: "Email"
  },
  "global-email-placeholder": {
    en: "Email",
    it: "Email"
  },
  "global-corporate-email-label": {
    en: "Company Email",
    it: "Email aziendale"
  },
  "global-corporate-email-placeholder": {
    en: "Company Email",
    it: "Email aziendale"
  },
  "global-employee-email-label": {
    en: "Company Email",
    it: "Email aziendale"
  },
  "global-employee-email-placeholder": {
    en: "Company Email",
    it: "Email aziendale"
  },
  "global-phone-label": {
    en: "Phone Number",
    it: "Telefono"
  },
  "global-phone-placeholder": {
    en: "Phone Number",
    it: "Telefono"
  },
  "global-message-label": {
    en: "Message",
    it: "Messaggio"
  },
  "global-message-placeholder": {
    en:
      "Which is the biggest HR challenge you are facing right now? Tell us more",
    it:
      "Qual è la più grande sfida HR che stai affrontando in questo momento? Raccontaci di più"
  },
  "global-role-label": {
    en: "Role",
    it: "Posizione"
  },
  "global-role-placeholder": {
    en: "Role",
    it: "Posizione"
  },
  "global-privacy-label": {
    en: "Pursuant to art. 13 GDPR 2016/679 I authorize Glickon S.r.l. to the processing of my personal data as per the <a href='{{privacyUrl}}' target='_blank' rel='noopener noreferrer'>Privacy Policy*</a>",
    it: "Ai sensi dell’art. 13 GDPR 2016/679 autorizzo Glickon S.r.l. al trattamento dei miei dati personali come da <a href='{{privacyUrl}}' target='_blank' rel='noopener noreferrer'>Informativa privacy*</a>"
  },
  "global-newsletter-label": {
    en: "Subscribe to our newsletter",
    it: "Iscriviti alla newsletter"
  },
  "global-generic-error": {
    en: "Something went wrong, please retry!",
    it: "Qualcosa è andato storto, riprova!"
  },
  "global-already-used-email-error": {
    en: "Email already used",
    it: "Email già utilizzata"
  },
  "global-wrong-email-or-password-error": {
    en: "Wrong email or password",
    it: "Email o password errata"
  },

  // External Pages
  "global-powered-by": {
    en: "Powered by",
    it: "Powered by"
  },
  "global-share": {
    en: "Share",
    it: "Condividi"
  },
  "global-want-to-apply": {
    en: "Do you want to apply for this challenge?",
    it: "Vuoi candidarti a questa challenge?"
  },
  "global-apply-description": {
    en:
      "Subscribe here to glickon platform, create your profile and send your application!",
    it:
      "Iscriviti alla piattaforma glickon per poter creare il tuo profilo e inviare la tua candidatura!"
  },
  "global-apply": {
    en: "Apply",
    it: "Candidati"
  },
  "global-number-of-questions": {
    en: "{{numberOfQuestions}} questions",
    it: "{{numberOfQuestions}} domande"
  },
  "global-completion-minutes": {
    en: "{{completionMinutes}} minutes",
    it: "{{completionMinutes}} minuti"
  },
  "global-company-details-website-url": { en: "website", it: "sito internet" },
  "global-company-details-employees": { en: "employees", it: "dipendenti" },
  "global-company-details-employees-with-number": {
    en: "{{numberOfEmployees}} employees",
    it: "{{numberOfEmployees}} dipendenti"
  },
  "global-company-details-headquarter": {
    en: "headquarter",
    it: "sede principale"
  },
  "global-company-details-social-network": {
    en: "social network",
    it: "social network"
  },
  "global-company-details-who-we-are": { en: "Who we are", it: "Chi siamo" },
  "global-company-details-mission-and-values-title": {
    en: "Mission and values",
    it: "Mission e valori"
  },
  "global-generali-details-mission-and-values-title": {
    en: "Generali Investments Cultural Principles",
    it: "Generali Investments Cultural Principles"
  },
  "global-forced-logout": {
    en:
      "Please login again with a valid account. For security reasons your login is expired. ",
    it:
      "Effettua nuovo accesso con le credenziali corrette. È stato eseguito il logout automatico per ragioni di sicurezza."
  },

  "global-products-contact-us-now": {
    en: "Contact us now",
    it: "Contattaci ora"
  },
  "global-contact-us-now-subject-suffix": {
    en: "Information request",
    it: "Richiesta informazioni"
  },
  "global-open-gmail": {
    en: "Open Gmail",
    it: "Apri Gmail"
  },
  "global-open-outlook": {
    en: "Open Outlook",
    it: "Apri Outlook"
  },
  "global-mail-not-found": {
    en: "Can’t find the email? Check your spam folder!",
    it: "Non trovi la mail? Cerca nella cartella dello Spam!"
  },
  "unifg-description-1": {
    en: "Sign up or Log in to your account.",
    it: "Registrati o Accedi se hai già un account."
  },
  "unifg-description-2": {
    en: "To access, you need to enter the UniFG student email.",
    it: "Per accedere occorre inserire l'email studenti di UniFG."
  },
}

export default translations
