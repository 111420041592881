//@ts-nocheck
import * as React from "react"
import ReactPlayer from "react-player"
import useLockBodyScroll from "../../hooks/useLockBodyScroll"
import useIsDesktopBreakpoint from "../../hooks/useIsDesktopBreakpoint"
import {
  AspectRatioBox,
  PseudoModal,
  InnerPseudoModal,
  CloseModalIcon,
  FallbackVideoPlayer,
  FallbackVideoPlayerWrapper
} from "./styles"
import {getImageUrl} from "../../utils"
import {CSSProperties} from "styled-components"

type PropsType = {
  src: string
  id?: string
  videoId?: string
  className?: string
  isTracked: boolean
}

const MATCH_URL_YOUTUBE = /(?:youtu\.be\/|youtube(?:-nocookie)?\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})|youtube\.com\/playlist\?list=|youtube\.com\/user\//
const MATCH_URL_VIMEO = /vimeo\.com\/.+/

function SimpleVideo(props: PropsType) {
  const {src, ...otherProperties} = props
  const isDesktopBreakpoint = useIsDesktopBreakpoint()
  const [isPseudoModalOpen, setIsPseudoModalOpen] = React.useState(false)
  // @ts-ignore
  useLockBodyScroll(isPseudoModalOpen && isDesktopBreakpoint)

  const safeSetIsPseudoModalOpen = (value: any) => {
    if (value === isPseudoModalOpen) return
    if (value === true && !isDesktopBreakpoint) return

    setIsPseudoModalOpen(value)
  }
  const player =
    MATCH_URL_YOUTUBE.test(src) || MATCH_URL_VIMEO.test(src) ? (
      <PseudoModal
        {...otherProperties}
        isOpen={isPseudoModalOpen}
        onClick={() => safeSetIsPseudoModalOpen(false)}
      >
        <CloseModalIcon
          src={getImageUrl("common/overlay-close.svg")}
          isOpen={isPseudoModalOpen}
        />
        <InnerPseudoModal isOpen={isPseudoModalOpen}>
          <AspectRatioBox>
            <ReactPlayer
              controls
              playing={isPseudoModalOpen}
              onPlay={() => {
                safeSetIsPseudoModalOpen(true)
              }}
              // eslint-disable-next-line @typescript-eslint/no-use-before-define
              style={playerStyle}
              width="100%"
              height="100%"
              url={src}
            />
          </AspectRatioBox>
        </InnerPseudoModal>
      </PseudoModal>
    ) : (
      <FallbackVideoPlayerWrapper>
        <FallbackVideoPlayer {...otherProperties} src={src} />
      </FallbackVideoPlayerWrapper>
    )

  return player
}

const playerStyle: CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  maxHeight: "calc(100vh - 3.75rem)"
}

export default SimpleVideo
