import {TranslationType} from "../types"

const translations: TranslationType = {
  "resources-candidate-experience-header-title": {
    en: "Candidate Experience,<br/>what is it?",
    it: "Candidate Experience,<br/>che cos’è?"
  },
  "resources-candidate-experience-header-description": {
    en: `
            It is the sum of the key steps in a person’s candidacy, preselection and selection with respect to a job 
            opening. The combination of these interactions generates a decisive experience for both the candidate and 
            the company. And it represents the point of intersection between supply and demand, highlighting 
            competences (candidate) and underlining the ability (company) to attract the best talents.
        `,
    it: `
            È la somma dei passaggi chiave che identificano candidatura, preselezione e selezione di una persona
            rispetto a un’offerta di lavoro. L’insieme di queste interazioni genera un’esperienza determinante sia per
            il candidato sia per l’azienda. E rappresenta il punto di intersezione tra domanda e offerta, evidenziando
            le competenze (candidato) e mettendo in luce la capacità (azienda) di attrarre i migliori talenti.
        `
  },

  "resources-candidate-experience-large-text-1": {
    en: "Online: the experience has room for enormous improvement",
    it: "Online: l’esperienza ha molti margini di miglioramento enormi"
  },
  "resources-candidate-experience-medium-text-1": {
    en: `
            More than 80% of candidates look for work online, a percentage destined to reach the maximum limit, given 
            that by 2025 Millennials and Generation Z will represent about 75% of employees. Yet, according to a 
            research done by Deloitte, 53% of those who complete an application online consider the pre-selection 
            process to be mediocre, a percentage that rises to 75% among those who find the candidacy experience so 
            frustrating that they do not even inquire about the final outcome.
            <br/><br/>
            Alarming: 91% of interviewees give a totally insufficient evaluation. And unfortunately bad news are not 
            over yet. The most disheartening value is that only 41% of candidates receive a feedback about their 
            application, even though this first part of the recruiting process takes place in a digital environment, 
            the most flexible ecosystem for giving quick responses both to those who are seeking a job and those 
            who are offering it.
        `,
    it: `
            Più dell'80% dei candidati cerca lavoro online, una percentuale destinata a raggiungere il tetto massimo
            visto che entro il 2025 Millennial e Generazione Z rappresenteranno circa il 75% dei dipendenti. Eppure,
            secondo una ricerca Deloitte, il 53% di chi applica una candidatura in rete ritiene mediocre il processo di
            preselezione, dato che sale al 75% tra chi valuta frustrante la candidate experience tanto da non richiedere
            nemmeno l’esito finale.
            <br/><br/>
            Attenzione, chi dà un voto del tutto insufficiente è addirittura il 91% degli intervistati. E purtroppo le
            brutte notizie non sono ancora finite. Il valore più sconfortante è che solo il 41% dei candidati riceve un
            feedback sull’application, nonostante questa prima parte del processo di recruiting avvenga in un ambiente
            digitale, l’ecosistema più flessibile per mettere in mano risposte rapide sia a chi cerca lavoro sia a chi
            lo offre.
        `
  },
  "resources-candidate-experience-quote-text-1": {
    en: `
            «We see our candidates as invited guests to a party, and we are the hosts. Therefore it’s our task to 
            improve their experience every day. After all, people go to parties to have fun ».
        `,
    it: `
            «Consideriamo i nostri candidati come invitati a una festa, dove noi siamo i padroni di casa. Il nostro
            compito è quindi quello di migliorare ogni giorno la loro esperienza. Del resto, ai party si va per
            divertirsi».
        `
  },
  "resources-candidate-experience-quote-source-1": {
    en: "Jeff Bezos, founder of Amazon",
    it: "Jeff Bezos, fondatore di Amazon"
  },
  "resources-candidate-experience-large-text-2": {
    en: "Fast, concrete, and immediate results: the new face of pre-screening",
    it:
      "Veloce, concreta, risultati immediati: la nuova faccia del pre-screening"
  },
  "resources-candidate-experience-medium-text-2": {
    en: `
            The recruiting phase must be practical, fast, effective and with instant feedback. It is a win-win system, 
            in which both sides gain in time, reputation, money, image, expectations. But this is something to be 
            spoken of at the conditional, given that from the last report compiled by Talent Board 43% of respondents 
            say that the selection process is too long: more than 30 minutes to complete an application. While 60% 
            (Lever) claims to have stopped the application due to an overly complicated process.
            <br/><br/>
            The solutions proposed by Glickon follow precisely a strategy that solves both these criticalities. 
            Through simple challenges created directly by the HR team, candidates are able to test the skills for 
            the open position in just a few minutes: indeed, time is one of the metrics that make up the final score. 
            Feedback is immediate. And the engagement experienced generates a positive perception of the 
            candidate experience.
        `,
    it: `
            La fase di recruiting deve essere pratica, veloce, efficace e con un feedback istantaneo. È un sistema
            win-win, in cui entrambe le parti guadagnano in tempo, reputazione, denaro, immagine, aspettative. Ma il
            condizionale è d’obbligo, visto che dall’ultimo report redatto da Talent Board il 43% degli intervistati
            sostiene che il percorso di selezione sia troppo lungo: più di 30 minuti per completare un’application.
            Mentre il 60% (Fonte Lever) afferma di aver interrotto l’application a causa di un processo troppo
            complicato.
            <br/><br/>
            Le soluzioni proposte da Glickon seguono proprio una strategia che va a colmare entrambe le criticità.
            Attraverso semplici challenge create direttamente dai team HR, i candidati sono in grado di verificare in
            pochi minuti le competenze per la posizione aperta: il tempo è infatti una delle metriche che compone il
            punteggio finale. Il feedback è immediato. E l’engagement prodotto genera una percezione positiva di
            candidate experience.
        `
  },
  "resources-candidate-experience-large-text-3": {
    en: "Data-driven recruitment, data and insight speed up the HR effort",
    it: "Data-driven recruitment, dati e insight accelerano lo sforzo di HR"
  },
  "resources-candidate-experience-medium-text-3": {
    en: `
            A selection system based on data acquisition and analysis makes it possible to identify the most capable 
            candidates, brings to light the best skills and, therefore, meets the needs of companies. A study carried 
            out by LinkedIn lists the positive consequences following a recruitment technique based on engagement, 
            gamification and data analysis:
            <br/><br/>
            <ul>
                <li>
                    It allows you to ask the most suitable questions
                </li>
                <li>
                    It improves and speeds up workflow
                </li>
                <li>
                    It allows you to obtain a large data pool
                </li>
                <li>
                    It analyzes the results automatically
                </li>
                <li>
                    It gives immediate access to records and metrics
                </li>
                <li>
                    It allows to coordinate and share HR work in real time
                </li>
            </ul>
        `,
    it: `
            Un sistema di selezione basato sull’acquisizione e l’analisi dei dati permette di identificare i candidati
            più capaci, porta alla luce le migliori competenze e, quindi, soddisfa le esigenze delle aziende. Lo riporta
            anche uno studio di LinkedIn che elenca le potenzialità della tecnica di recruitment fondata su engagement,
            gamification e data analysis:
            <br/><br/>
            <ul>
                <li>
                    Permette di proporre le domande più idonee
                </li>
                <li>
                    Migliora e velocizza il flusso di lavoro
                </li>
                <li>
                    Consente di ottenere un ampio pool di dati
                </li>
                <li>
                    Analizza i risultati in maniera automatica
                </li>
                <li>
                    Dà accesso immediato a record e metriche
                </li>
                <li>
                    Fa coordinare e condividere il lavoro di HR in real time
                </li>
            </ul>
        `
  },
  "resources-candidate-experience-quote-text-2": {
    en: `
            «We see future resources as ambassadors of Airbnb’s culture and image. That’s why we treat them as if 
            they were guests of a property on our platform. Because in the end that will be their next home».
        `,
    it: `
            «Vediamo le future risorse come ambassador della cultura e dell’immagine di Airbnb. Ecco perché li trattiamo
            come se fossero ospiti di una struttura in affitto sulla nostra piattaforma. Perché alla fine sarà quella la
            loro prossima casa».
        `
  },

  "resources-candidate-experience-quote-source-2": {
    en: "Airbnb HR team",
    it: "Team HR di Airbnb"
  },
  "resources-candidate-experience-large-text-4": {
    en:
      "ROI. A great experience has exponential value. Not just in terms of money",
    it:
      "ROI. Un'ottima esperienza ha un valore esponenziale. Non solo economico"
  },
  "resources-candidate-experience-medium-text-4": {
    en: `
            The return on investing on a successful candidate experience brings benefits that are both of economic 
            nature and of image and emotional value towards the brand.
            <strong>It strengthens ROR, Return On Relationship. </strong>
            And, potentially, it also creates an organic return on sales or loss management. Because you know, the best 
            ambassadors are often the newly acquired talents. But if the selection process does not correspond to an 
            experience that respects standards and expectations, the epic fail is just around the corner.
            <br/><br/>
            The case of Virgin Media, an English company active in the TV, Internet and telecommunications fields, is 
            one clear example. Every year more than 120 thousand applications received are rejected (18% of these are 
            also average customers) and a bad management in the recruiting phase in 2017 has generated a 6% loss of 
            subscriptions, quantifiable at about 5.7 million dollars.
            <br/><br/>
            <strong>It increases productivity. </strong>
            Bringing new resources into the company with the best skills obviously has an impact on the future 
            performance of the individual and therefore on the business. But acquiring people, engaging them in a 
            recruitment phase in which the human factor (amplified and not restrained by technology) communicates 
            happiness through empathy (engagement and gamification) also produces a positive ROI. This is demonstrated 
            by a study by the University of Warwick, in England: newly hired and happy employees are 12% more productive.
            <br/><br/>
            <strong>It strengthens the brand. </strong>
            Designing an experience whose foundations are made of technology, service design, engagement and immediate 
            feedback, makes candidates likely to reconsider the brand for future applications (Lever) with 400% more 
            probability, no matter what the outcome of the selection the first time may be. At the same time, 60% of 
            candidates with negative experiences share online their negative perception (in 72% of cases) with an image 
            damage for the brand that is potentially viral.
        `,
    it: `
            <strong>Rafforza il ROR, Return On Relation. </strong>
            E, potenzialmente, crea anche un ritorno organico sulle vendite o sulla
            gestione delle perdite. Perché si sa, i migliori ambassador sono spesso i nuovi talenti appena acquisiti. Ma
            se il processo di selezione non corrisponde a un’esperienza che rispetta standard e aspettative, l’epic fail
            è dietro l’angolo.
            <br/><br/>
            Un esempio su tutti, il caso di Virgin Media, società inglese attiva nel settore di Tv, Internet e
            telecomunicazioni. Ogni anno più di 120mila candidature pervenute vengono rifiutate (il 18% di queste sono
            in media anche clienti) e una cattiva gestione nella fase di recruiting nel 2017 ha generato una perdita di
            abbonamenti pari al 6%, quantificabile in circa 5,7 milioni di dollari.
            <br/><br/>
            <strong>Aumenta la produttività. </strong>
            Portare in azienda nuove risorse con le migliori competenze ha ovviamente un
            impatto sulle future prestazioni del singolo e quindi sul business. Ma acquisire persone, coinvolgendole in
            una fase di recruitment in cui il fattore umano (amplificato e non frenato dalla tecnologia) comunica
            felicità attraverso l’empatia (engagement e gamification) produce anche un ROI positivo. Lo dimostra uno
            studio dell’Università di Warwick, in Inghilterra: dipendenti appena assunti e felici sono il 12% più
            produttivi.
            <br/><br/>
            <strong>Fortifica il brand. </strong>
            Progettare un’esperienza le cui fondamenta sono fatte di tecnologia, service design,
            engagement e feedback immediati, al di là dell’esito della selezione predispone i candidati a riconsiderare
            il brand per future application (sondaggio Lever)
            con il 400% di probabilità in più. Allo stesso tempo, il
            60% di candidature con esperienze negative portano alla relativa condivisione online (nel 72% dei casi) con
            un danno d’immagine per il marchio potenzialmente virale.
        `
  },

  // banner bottom
  "resources-candidate-experience-banner-bottom-title": {
    en: "Accept the challenge, design your new Candidate Experience!",
    it: "Accetta la sfida, progetta la tua nuova Candidate Experience!"
  },
  "resources-candidate-experience-banner-bottom-description": {
    en:
      "We build it together, but then you will be the one bringing new talents to the company",
    it: "La costruiamo insieme, ma sarai tu a portare nuovi talenti in azienda"
  }
}

export default translations
