import styled from "styled-components"
import styleConstants from "../../constants/styleConstants"

interface ContainerPropsType {
  space: number
  spaceDesktop: number
}

export const Container = styled.div<ContainerPropsType>`
  display: block;
  background-color: ${props => props.color};
  height: ${props => props.space}rem;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    height: ${props => props.spaceDesktop}rem;
  }
`
