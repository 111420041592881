// Feel free to add more fields to the interface if needed (that are within the API response)
export enum LoginPromptType {
  CREDENTIALS = "credentials",
  PROVIDER_SSO = "sso",
}

export type LoginPromptOptionals = {
  targetUrlQueryParam: string,
  linkUrl: string,
  idpLabel: string,
  supportsSignUp: boolean
}

export type LoginPromptEntry = Partial<LoginPromptOptionals> & {
  type: LoginPromptType,
}

export interface CompanyInfo {
  logoUrl: string
  landingPageBackgroundUrl: string
  privacyUrl?: string,
  supportedAuthenticationMethods: LoginPromptEntry[],
  privacyPolicyConsentRequired?: boolean,
  privacyPolicyConsentFormCustomMessage?: {
      en: string | null,
      it: string | null
  }
}
