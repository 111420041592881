import {TranslationType} from "../types"

const translations: TranslationType = {
  "request-a-demo-header-title": {
    en: "Glickon. Book your Experience",
    it: "Glickon. Prenota la tua Experience"
  },
  "request-a-demo-header-description": {
    en: `
            Let's look at your HR goals, together
            <br/><br/>
            Show your hand. If you are here it’s due to some sort of a match between curiosity and need. So let's meet 
            and talk, we can't wait to show you how much we are in tune with HR teams.
            <br/><br/>
            1. Call us, or send us your contact details. We’ll reply immediately.
            <br/><br/>
            2. We’ll schedule an appointment, so you can add Glickon to your agenda.
            <br/><br/>
            3. We’ll sit down and explain to you how this works, using many practical examples from HR experience.
        `,
    it: `
            Scegliamo insieme quali sono i tuoi obiettivi HR
            <br/><br/>
            Scopriamo le carte. Se sei arrivato qui è perché c’è
            stato un match tra curiosità e necessità. E allora
            incontriamoci, non vediamo l’ora di farti vedere quanto
            siamo in sintonia con i team HR.
            <br/><br/>
            1. Chiamaci. Oppure scrivici i tuoi contatti. Ti rispondiamo subito.
            <br/><br/>
            2. Fissiamo un appuntamento, così puoi scrivere Glickon sulla tua agenda.
            <br/><br/>
            3. Ti spieghiamo come funziona. Con tanti esempi pratici di HR experience.
        `
  },
  "request-a-demo-submit": {
    en: "Contact us",
    it: "Contattaci"
  },

  // widget-3-columns
  "request-a-demo-widget-3-columns-1st-title": {
    en: "We are hungry for data",
    it: "Siamo affamati di dati"
  },
  "request-a-demo-widget-3-columns-1st-description": {
    en: `
            We apply data science and design to every HR need. We design solutions that will make the acquisition of 
            new resources faster and their daily experience within the company more productive.
        `,
    it: `
            Applichiamo data science e design alle esigenze ad
            ogni esigenza HR. Progettiamo insieme soluzioni per
            rendere più veloce e produttiva l’acquisizione di nuove
            risorse e l’esperienza che vivranno ogni giorno in
            azienda.
        `
  },
  "request-a-demo-widget-3-columns-2nd-title": {
    en: "We turn them into opportunities",
    it: "Li trasformiamo in opportunità"
  },
  "request-a-demo-widget-3-columns-2nd-description": {
    en: `
            Glickon deals with data analysis. What you get are our platforms, organized to choose and monitor the 
            talents available to you, customizing your goals.
        `,
    it: `
            All’analisi dei dati pensa Glickon. Quello che ottieni tu
            sono le nostre piattaforme organizzate per scegliere
            e monitorare i talenti che hai a disposizione,
            personalizzando i tuoi obiettivi
        `
  },
  "request-a-demo-widget-3-columns-3rd-title": {
    en: "We value your decisions",
    it: "Diamo valore alle tue decisioni"
  },
  "request-a-demo-widget-3-columns-3rd-description": {
    en: `
            We provide the insight you need to model the structure and performance of your company. Now you can make 
            informed decisions because you know how much your people are really worth.
        `,
    it: `
            Ti forniamo gli insight di cui hai bisogno per
            modellare struttura e performance della tua azienda.
            Ora puoi prendere decisioni consapevoli perché sai
            quanto davvero valgono le tue persone.
        `
  },

  // request-done
  "request-a-demo-done-title": {
    en:
      "Thank you for requesting information and booking a Product Demo, we will contact you as soon as possible",
    it:
      "Grazie per aver richiesto informazioni e prenotato una Demo del prodotto, ti contatteremo al più presto"
  },
  "request-a-demo-done-continue-title": {
    en: "Do you want to our website?",
    it: "Vuoi continuare a navigare sul nostro sito?"
  },
  "request-a-demo-done-continue-action": {
    en: "Go to the home page",
    it: "Vai alla homepage"
  },
  "request-a-demo-done-leave-title": {
    en: "Do you want to learn more about HR topics in our blog?",
    it: "Vuoi approfondire le tematiche HR nel nostro blog?"
  },
  "request-a-demo-done-leave-action": {
    en: "Go to the blog",
    it: "Vai al blog"
  }
}

export default translations
