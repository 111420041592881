import React from "react"
import { FormikHelpers } from "formik"
import { AxiosError } from "axios"
import { useHistory } from "react-router-dom"
import { generateLocalizedPath, getParametrizedPath } from "../../../../utils"
import { twoFactorAuthenticationInsertCodeKeySMS } from "../../../../constants/pathKeys"
import { platformByUserScope } from "../../../../constants/global"
import useTranslation from "../../../../hooks/useTranslation"
import { homepageKey } from "../../../../constants/pathKeys"
import {
    TwoFactorAuthenticationScopesSMSType,
    TwoFactorAuthenticationType
} from "../../../../types/twoFactorAuthentication"
import {
  setPhoneNumber,
  verifyCode,
  resendCode
} from "../../../../requests/twoFactorAuthentication"
import { Container, Title, Description, TfaDurationInfo } from "./style"
import HeaderLoginSignup from "../../../../commonComponents/header-login-signup"
import Translate from "../../../../commonComponents/translate"
import SetPhoneNumberForm from "../set-form-number-form"
import InsertCodeForm from "../insert-code-form"
import styleConstants from "../../../../constants/styleConstants"

type SMSPropsType = {
  backgroundUrl: string
  userHash: string
  phoneNumberEndingDigits?: string
  scope: TwoFactorAuthenticationScopesSMSType
}

type EmailPropsType = {
  backgroundUrl: string
  userHash: string
  maskedEmail?: string
}

export const SMSWrapper: React.FC<SMSPropsType> = ({
  backgroundUrl,
  userHash,
  scope,
  phoneNumberEndingDigits
}: any) => {
  const [translate, locale] = useTranslation()
  const [errorText, setErrorText] = React.useState<string>("")
  const history = useHistory()

  const callSetPhoneNumber = async(
    values: any,
    { setSubmitting }: FormikHelpers<any>
  ) => {
    const data = { tfaUserHash: userHash, phoneNumber: values.phoneNumber }
    // reset previous errors
    setErrorText("")
    // call api to set phone number
    setPhoneNumber<any, any>(
      data,
      response => {
        // if everything is okay redirect to inser code page
        const path = getParametrizedPath(
          generateLocalizedPath(twoFactorAuthenticationInsertCodeKeySMS, locale),
          [
            { key: "userHash", value: userHash },
            {
              key: "phoneNumberEndingDigits",
              value: response.data.phoneNumberEndingDigits
            }
          ]
        )
        history.push(path)
      },
      (_: AxiosError) => {
        setSubmitting(false)
        setErrorText(translate("global-generic-error"))
      }
    )
  }

  const callVerifyCode = async(
    values: any,
    { setSubmitting }: FormikHelpers<any>
  ) => {
    const data = { tfaUserHash: userHash, tfaCode: values.code }
    // reset previous errors
    setErrorText("")
    // call api to verify code
    verifyCode<any, any>(
      data,
      () => {
        // if everything is okay redirect to logged platform
        window.location.href = platformByUserScope
      },
      (_: AxiosError) => {
        setSubmitting(false)
        setErrorText(translate("global-generic-error"))
      }
    )
  }

  const callResendCode = async() => {
    const data = {
        tfaUserHash: userHash,
        type: TwoFactorAuthenticationType.SMS,
        language: locale
    }
    // call api to verify code
    resendCode<any, any>(
      data,
      () => { },
      (_: AxiosError) => {
        setErrorText(translate("global-generic-error"))
      }
    )
  }

  const title =
    scope === TwoFactorAuthenticationScopesSMSType.SetPhoneNumber
      ? "tfa-set-phone-number-title"
      : "tfa-insert-code-sms-title"

  const description =
    scope === TwoFactorAuthenticationScopesSMSType.SetPhoneNumber ? (
      <Translate label="tfa-set-phone-number-description" />
    ) : (
        <>
          <Translate label="tfa-insert-code-sms-description-first-part" />
          <span style={{ fontFamily: styleConstants.calibreSemibold }}>
            {" "}
            ******{phoneNumberEndingDigits}{" "}
          </span>
          <Translate label="tfa-insert-code-sms-description-last-part" />
          <TfaDurationInfo>
            <Translate label="tfa-duration-info" />
          </TfaDurationInfo>
        </>
      )

  const form =
    scope === TwoFactorAuthenticationScopesSMSType.SetPhoneNumber ? (
      <SetPhoneNumberForm
        submitHandler={callSetPhoneNumber}
        errorText={errorText}
      />
    ) : (
        <InsertCodeForm
          submitHandler={callVerifyCode}
          errorText={errorText}
          resendCode={callResendCode}
        />
      )

  return (
    <Container backgroundUrl={backgroundUrl}>
      <HeaderLoginSignup logoLinkKey={homepageKey} />
      <div className="row">
        <div className="column mobile-12 desktop-6">
          <div className="row">
            <div className="column mobile-12 desktop-8">
              <Title>
                <Translate label={title} />
              </Title>
              <Description
                withBottomMargin={
                  scope === TwoFactorAuthenticationScopesSMSType.SetPhoneNumber
                }
              >
                {description}
              </Description>
              {form}
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export const EmailWrapper: React.FC<EmailPropsType> = ({
  backgroundUrl,
  userHash,
  maskedEmail
}: any) => {
  const [translate, locale] = useTranslation()
  const [errorText, setErrorText] = React.useState<string>("")

  const callVerifyCode = async(
    values: any,
    { setSubmitting }: FormikHelpers<any>
  ) => {
    const data = { tfaUserHash: userHash, tfaCode: values.code }
    // reset previous errors
    setErrorText("")
    // call api to verify code
    verifyCode<any, any>(
      data,
      () => {
        // if everything is okay redirect to logged platform
        window.location.href = platformByUserScope
      },
      (_: AxiosError) => {
        setSubmitting(false)
        setErrorText(translate("global-generic-error"))
      }
    )
  }

  const callResendCode = async() => {
    const data = {
        tfaUserHash: userHash,
        type: TwoFactorAuthenticationType.EMAIL,
        language: locale
    }
    // call api to verify code
    resendCode<any, any>(
      data,
      () => { },
      (_: AxiosError) => {
        setErrorText(translate("global-generic-error"))
      }
    )
  }

  const description = (
    <>
      <Translate label="tfa-insert-code-email-description-first-part" />
      <span style={{ fontFamily: styleConstants.calibreSemibold }}>
        {" "}
        {maskedEmail}{" "}
      </span>
      <Translate label="tfa-insert-code-email-description-last-part" />
      <TfaDurationInfo>
        <Translate label="tfa-duration-info" />
      </TfaDurationInfo>
    </>
  )

  const form = (
    <InsertCodeForm
      submitHandler={callVerifyCode}
      errorText={errorText}
      resendCode={callResendCode}
    />
  )

  return (
    <Container backgroundUrl={backgroundUrl}>
      <HeaderLoginSignup logoLinkKey={homepageKey} />
      <div className="row">
        <div className="column mobile-12 desktop-6">
          <div className="row">
            <div className="column mobile-12 desktop-8">
              <Title>
                <Translate label={"tfa-insert-code-email-title"} />
              </Title>
              <Description withBottomMargin={false}>
                {description}
              </Description>
              {form}
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}
