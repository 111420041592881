import React from "react"
import {CompanyChallengeInfo} from "../../../../types/companyChallengeInfo"
import {truncate} from "../../../../utils"
import {
  CompanyChallengeItemCard as Card,
  CompanyChallengeItemTitle as Title,
  CompanyChallengeItemDescription as Description,
  CompanyChallengeItemTimeAndQuestions as TimeAndQuestions,
  CompanyChallengeItemIcon as Icon
} from "./style"
import Translate from "../../../translate"
import Link from "../../../link"
import {companyChallengeKey} from "../../../../constants/pathKeys"

interface Props {
  companyChallenge: CompanyChallengeInfo
  defaultColor: string | undefined
  customChallengeColor: string | undefined
  customTitleColor: string | undefined
  customDescriptionColor: string | undefined
  customTimeColor: string | undefined
  customBorderColor: string | undefined
}

const CompanyChallengeItem: React.FC<Props> = ({
  companyChallenge,
  customBorderColor,
  customChallengeColor,
  customDescriptionColor,
  customTimeColor,
  customTitleColor,
  defaultColor
}) => {
  const {
    name,
    shortDescription,
    description,
    numberOfQuestions,
    estimatedCompletionTime,
    hash,
    color
  } = companyChallenge

  const DESCRIPTION_MAX_CHAR_NUMBER = 100
  const NAME_MAX_CHAR_NUMBER = 30

  const minutes = Math.floor(estimatedCompletionTime / 60)
  const completionMinutes = minutes >= 10 ? `${minutes}` : `0${minutes}`
  const nameText = truncate(name, name, NAME_MAX_CHAR_NUMBER)

  const descriptionText = truncate(
    description,
    shortDescription,
    DESCRIPTION_MAX_CHAR_NUMBER
  )

  return (
    <Link to={companyChallengeKey} params={[{key: "hash", value: hash}]}>
      <Card
        topColor={color || defaultColor}
        customChallengeColor={customChallengeColor}
        customBorderColor={customBorderColor}
      >
        <Title
          customColor={customTitleColor}
          dangerouslySetInnerHTML={{__html: nameText}}
        />
        <Description
          customColor={customDescriptionColor}
          dangerouslySetInnerHTML={{__html: descriptionText}}
        />
        {numberOfQuestions > 0 && (
          <TimeAndQuestions customColor={customTimeColor}>
            <Icon className="icon-question" />
            <span>&nbsp;</span>
            <Translate
              label={"global-number-of-questions"}
              replacements={{numberOfQuestions}}
            />
            <span>&nbsp;&nbsp;</span>
            <Icon className="icon-minutes" />
            <span>&nbsp;</span>
            <Translate
              label={"global-completion-minutes"}
              replacements={{completionMinutes}}
            />
          </TimeAndQuestions>
        )}
      </Card>
    </Link>
  )
}

export default CompanyChallengeItem
