import React from "react"
import {Formik, useFormikContext, FormikHelpers, FormikProps} from "formik"
import {
  LostFormAction,
  ChangeForm,
  CheckboxContainer,
  ActionButton,
  ResendEmail,
  ActionSpan,
  ResendEmailAction,
  StyledLabel,
  CustomAction
} from "./styles"
import NegativeFeedback from "../negative-feedback"
import TextInput from "../input"
import Translate from "../translate"
import SimpleAction from "../action"
import Link from "../link"
import {
  candidatePlatformSignupKey,
  candidatePlatformKey,
  employeePlatformKey,
  employeePlatformSignupKey,
  candidatePlatformCompanySignupKey,
  candidatePlatformCompanyKey,
  employeePlatformCompanySignupKey,
  employeePlatformCompanyKey,
  contactPageKey
} from "../../constants/pathKeys"
import Checkbox from "../checkbox"
import {AccessUserType} from "../../types/accessUser"
import {getCurrentQueryString} from "../../utils"
import {LoginPromptEntry, LoginPromptType} from "../../types/companyInfo"
import CustomLoginPrompts from "../custom-login-prompts"
import Divider from "../divider"
import useTranslation from "../../hooks/useTranslation"
import {AnySchema} from "yup"

const emailId = "email"
const passwordId = "password"
const firstNameId = "firstName"
const lastNameId = "lastName"
const termsId = "hasAcceptedTermsAndConditions"
const privacyId = "hasAcceptedPrivacyPolicy"

interface LoginFormValuesType {
  [emailId]: string
  [passwordId]: string
}

interface RegisterFormValuesType extends LoginFormValuesType {
  [firstNameId]: string
  [lastNameId]: string
  [termsId]: boolean
  [privacyId]: boolean
}

export const AccessFormInputIds = {
  email: emailId,
  password: passwordId,
  firstName: firstNameId,
  lastName: lastNameId,
  hasAcceptedTermsAndConditions: termsId,
  hasAcceptedPrivacyPolicy: privacyId
}

// In the login form only some fields will be used
export type AccessFormValuesType = Partial<RegisterFormValuesType>

export enum AccessFormType {
  Login = "login",
  Register = "register"
}

interface PropsType {
  type: AccessFormType
  isPreRegister?: boolean
  isEmailReadonly?: boolean
  companyName?: string
  companyCareersName?: string
  privacyUrl?: string
  loginPrompts?: LoginPromptEntry[]
  loginNextUrl?: string
  userType: AccessUserType
  onLostPassword?: () => void
  onResendEmail?: () => void
  initialValues: AccessFormValuesType
  validationSchema: AnySchema<AccessFormValuesType>
  submitHandler: (
    values: AccessFormValuesType,
    {setSubmitting}: FormikHelpers<AccessFormValuesType>
  ) => Promise<void>
  onChange?: (values: AccessFormValuesType) => void
  errorText: string
  hideChangeForm?: boolean
  customPrimaryTextColor?: string
  customButtonColor?: string
  customButtonHoverColor?: string
  customButtonTextColor?: string
  privacyPolicyConsentRequired?: boolean
  privacyPolicyCustomMessage?: {en: string | null; it: string | null}
}

const getChangeFormLinkKey = (
  type: AccessFormType,
  userType: AccessUserType,
  companyName?: string
): string => {
  const candidateSignupKey = companyName
    ? candidatePlatformCompanySignupKey
    : candidatePlatformSignupKey
  const candidateKey = companyName
    ? candidatePlatformCompanyKey
    : candidatePlatformKey

  const employeeSignupKey = companyName
    ? employeePlatformCompanySignupKey
    : employeePlatformSignupKey
  const employeeKey = companyName
    ? employeePlatformCompanyKey
    : employeePlatformKey

  if (type === AccessFormType.Login && userType === AccessUserType.Candidate)
    return candidateSignupKey
  if (type === AccessFormType.Register && userType === AccessUserType.Candidate)
    return candidateKey

  if (type === AccessFormType.Login && userType === AccessUserType.Employee)
    return employeeSignupKey
  if (type === AccessFormType.Register && userType === AccessUserType.Employee)
    return employeeKey

  if (userType === AccessUserType.Corporate) return contactPageKey

  throw new Error(
    `Access Form - Cannot find next form link key with type: ${type} and userType: ${userType}`
  )
}

const getActionKey = (
  type: AccessFormType,
  isPreRegister: boolean,
  userType: AccessUserType
): string => {
  if (isPreRegister) return "form-employee-preregister-action"

  if (type === AccessFormType.Login && userType === AccessUserType.Candidate)
    return "form-candidate-login"
  if (type === AccessFormType.Register && userType === AccessUserType.Candidate)
    return "form-candidate-get-started"

  if (type === AccessFormType.Login && userType === AccessUserType.Employee)
    return "form-employee-login"
  if (type === AccessFormType.Register && userType === AccessUserType.Employee)
    return "form-employee-get-started"

  if (type === AccessFormType.Login && userType === AccessUserType.Corporate)
    return "form-corporate-login"

  throw new Error(
    `Access Form - Cannot find next form link key with type: ${type} and userType: ${userType}`
  )
}

const getChangeFormKeys = (
  type: AccessFormType,
  userType: AccessUserType
): [string, string] | [] => {
  if (type === AccessFormType.Login && userType === AccessUserType.Candidate)
    return ["form-candidate-no-account", "form-candidate-signup-now"]
  if (type === AccessFormType.Register && userType === AccessUserType.Candidate)
    return ["form-candidate-already-registered", "form-candidate-login"]

  if (type === AccessFormType.Login && userType === AccessUserType.Employee)
    return ["form-employee-no-account", "form-employee-signup-now"]
  if (type === AccessFormType.Register && userType === AccessUserType.Employee)
    return ["form-employee-already-registered", "form-employee-login"]

  if (userType === AccessUserType.Corporate) return []

  throw new Error(
    `Access Form - Cannot find change form keys with type: ${type} and userType: ${userType}`
  )
}

const getFormSubmitability = (
  type: AccessFormType,
  isPreRegister: boolean,
  values: AccessFormValuesType
): boolean => {
  if (type === AccessFormType.Login)
    return !!(values[emailId] && values[passwordId])
  if (type === AccessFormType.Register && isPreRegister)
    return !!values[emailId]

  if (type === AccessFormType.Register)
    return !!(
      values[emailId] &&
      values[passwordId] &&
      values[firstNameId] &&
      values[lastNameId]
    )

  throw new Error(
    `Access Form - Cannot find status for form with type: ${type}`
  )
}

const getEmailPlaceholder = (userType: AccessUserType): string =>
  userType === AccessUserType.Candidate
    ? "global-email-label"
    : `global-${userType}-email-label`

type CustomActionPropsType = React.ButtonHTMLAttributes<HTMLDivElement> & {
  backgroundColor?: string
  textColor?: string
  disabled: boolean
}

const CustomActionComponent: React.FC<CustomActionPropsType> = ({
  backgroundColor,
  textColor,
  children,
  disabled = false,
  onClick
}) => {
  const clickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    if (disabled) {
      event.preventDefault()
      return
    }
    if (typeof onClick === "function") {
      onClick(event)
    }
  }
  return (
    <CustomAction
      backgroundColor={backgroundColor}
      textColor={textColor}
      onClick={clickHandler}
      disabled={disabled}
    >
      {children}
    </CustomAction>
  )
}

type CredentialsLoginPropsType = {
  hasAlreadySubmitted: boolean
  accessType: AccessFormType
  userType: AccessUserType
  isEmailReadonly?: boolean
  isPreRegister?: boolean
}

const CredentialsLoginPrompt: React.FC<CredentialsLoginPropsType> = (
  props: CredentialsLoginPropsType
) => {
  const formik = useFormikContext<AccessFormValuesType>()

  const {
    isEmailReadonly,
    hasAlreadySubmitted,
    isPreRegister,
    userType,
    accessType
  } = props
  const {values, handleChange, errors} = formik

  return (
    <>
      {accessType === AccessFormType.Register && !isPreRegister && (
        <>
          <TextInput
            id={firstNameId}
            value={values[firstNameId]}
            error={hasAlreadySubmitted && errors[firstNameId]}
            placeholder="global-firstname-label"
            onChange={handleChange}
          />

          <TextInput
            id={lastNameId}
            value={values[lastNameId]}
            error={hasAlreadySubmitted && errors[lastNameId]}
            placeholder="global-lastname-label"
            onChange={handleChange}
          />
        </>
      )}
      <TextInput
        className={isEmailReadonly ? "readonly" : ""}
        id={emailId}
        value={values[emailId]}
        readOnly={isEmailReadonly}
        type="email"
        error={hasAlreadySubmitted && errors[emailId]}
        placeholder={getEmailPlaceholder(userType)}
        onChange={handleChange}
      />

      {!isPreRegister && (
        <TextInput
          type="password"
          minLength={8}
          id={passwordId}
          value={values[passwordId]}
          error={hasAlreadySubmitted && errors[passwordId]}
          placeholder="global-password-label"
          onChange={handleChange}
        />
      )}
    </>
  )
}

const AccessForm: React.FC<PropsType> = ({
  isPreRegister,
  type,
  userType,
  companyName,
  companyCareersName,
  privacyUrl,
  onLostPassword,
  onResendEmail,
  initialValues,
  validationSchema,
  submitHandler,
  isEmailReadonly,
  onChange = () => {},
  loginPrompts = [],
  loginNextUrl,
  errorText,
  hideChangeForm,
  customPrimaryTextColor,
  customButtonColor,
  customButtonTextColor,
  privacyPolicyConsentRequired,
  privacyPolicyCustomMessage
}: PropsType) => {
  const hasCustomAction = !!customButtonColor
  const hasCredentialsLogin = !loginPrompts.length
    ? true
    : loginPrompts.some((item: LoginPromptEntry) => {
        return item.type === LoginPromptType.CREDENTIALS
      })
  const hasOnlySSOLogin = !loginPrompts.length
    ? false
    : loginPrompts.every((item: LoginPromptEntry) => {
        return item.type !== LoginPromptType.CREDENTIALS
      })
  const hasAllSSOSupportingSignup = !hasOnlySSOLogin
    ? false
    : loginPrompts.every((item: LoginPromptEntry) => {
        return item.supportsSignUp === true
      })
  const customLoginPrompts = loginPrompts.filter(
    (item: LoginPromptEntry) => item.type !== LoginPromptType.CREDENTIALS
  )
  const [, locale] = useTranslation()
  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={submitHandler}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        submitCount,
        isSubmitting,
        errors
      }: FormikProps<AccessFormValuesType>) => {
        const hasAlreadySubmitted = submitCount > 0
        const isFormSubmitable = getFormSubmitability(
          type,
          !!isPreRegister,
          values
        )
        const actionKey = getActionKey(type, !!isPreRegister, userType)
        const changeFormKeys = getChangeFormKeys(type, userType)

        // Everytime the form re-render with new values we notify the interested parent
        onChange(values)
        // schiantone per Itas - don't try this at home!!!
        let privacyCheckBoxLabel = `form-${userType}-custom-privacy-policy`
        if (userType === "candidate" && companyCareersName === "itas") {
          privacyCheckBoxLabel = "form-custom-privacy-policy-itas"
        }
        return (
          <form onSubmit={handleSubmit} noValidate>
            {errorText && (
              <NegativeFeedback id="access-form-negative-feedback">
                {errorText}
              </NegativeFeedback>
            )}
            {hasCredentialsLogin && (
              <CredentialsLoginPrompt
                isPreRegister={isPreRegister}
                hasAlreadySubmitted={hasAlreadySubmitted}
                userType={userType}
                accessType={type}
                isEmailReadonly={isEmailReadonly}
              />
            )}
            {hasCredentialsLogin &&
              type === AccessFormType.Register &&
              !isPreRegister && (
                <CheckboxContainer>
                  <Checkbox
                    id={termsId}
                    errorId={`${termsId}-negative-feedback`}
                    checked={values[termsId]}
                    error={hasAlreadySubmitted && errors[termsId]}
                    onChange={handleChange}
                    label={`form-${userType}-terms`}
                    customLabelColor={customPrimaryTextColor}
                  />

                  {privacyPolicyConsentRequired ? (
                    <Checkbox
                      id={privacyId}
                      errorId={`${privacyId}-negative-feedback`}
                      checked={values[privacyId]}
                      error={hasAlreadySubmitted && errors[privacyId]}
                      onChange={handleChange}
                      label={privacyCheckBoxLabel}
                      customLabel={
                        privacyPolicyCustomMessage &&
                        (privacyPolicyCustomMessage.en ||
                          privacyPolicyCustomMessage.it)
                          ? `<a id="privacy-link" target="_blank" tabindex="-1" href=${privacyUrl}>
                                ${
                                  locale === "en"
                                    ? privacyPolicyCustomMessage.en
                                    : privacyPolicyCustomMessage.it
                                }
                            </a>`
                          : undefined
                      }
                      labelReplacements={{privacyUrl: privacyUrl!}}
                      customLabelColor={customPrimaryTextColor}
                    />
                  ) : (
                    <StyledLabel
                      id="privacy-premise"
                      customTextColor={customPrimaryTextColor}
                    >
                      {privacyUrl ? (
                        <Translate
                          label={`form-${userType}-whitelabel-privacy-premise`}
                          replacements={{"href-placeholder": privacyUrl}}
                        />
                      ) : (
                        <Translate label={`form-${userType}-privacy-premise`} />
                      )}
                    </StyledLabel>
                  )}
                </CheckboxContainer>
              )}
            {hasCredentialsLogin &&
              userType === AccessUserType.Employee &&
              type === AccessFormType.Login && (
                <ResendEmail customTextColor={customPrimaryTextColor}>
                  <Translate label="form-employee-resend-email-1" />{" "}
                  <ResendEmailAction className="action" onClick={onResendEmail}>
                    <Translate label="form-employee-resend-email-2" />
                  </ResendEmailAction>
                </ResendEmail>
              )}
            {hasCredentialsLogin && type === AccessFormType.Login && (
              <LostFormAction
                customTextColor={customPrimaryTextColor}
                className={
                  userType === AccessUserType.Corporate
                    ? "extra-margin-bottom"
                    : ""
                }
                onClick={onLostPassword}
              >
                <Translate label={`form-${userType}-forgotten-password`} />
              </LostFormAction>
            )}
            {hasCredentialsLogin && (
              <ActionButton
                disabled={isSubmitting || !isFormSubmitable}
                className={`button-unstyled full-width ${
                  type === AccessFormType.Register ? "extra-margin-top" : ""
                }`}
                type="submit"
              >
                {hasCustomAction ? (
                  <CustomActionComponent
                    backgroundColor={customButtonColor}
                    textColor={customButtonTextColor}
                    disabled={isSubmitting || !isFormSubmitable}
                  >
                    <Translate label={actionKey} />
                  </CustomActionComponent>
                ) : (
                  <SimpleAction
                    disabled={isSubmitting || !isFormSubmitable}
                    fullWidth
                    size={SimpleAction.sizes.large}
                    variant={SimpleAction.variants.blackGlickon}
                  >
                    <Translate label={actionKey} />
                  </SimpleAction>
                )}
              </ActionButton>
            )}
            {(hideChangeForm || hasAllSSOSupportingSignup) &&
            changeFormKeys.length === 2 ? null : (
              <ChangeForm>
                {changeFormKeys[0] && <Translate label={changeFormKeys[0]} />}
                {changeFormKeys[1] && (
                  <Link
                    to={getChangeFormLinkKey(type, userType, companyName)}
                    params={[
                      {key: "companyName", value: companyName as string}
                    ]}
                    queryStringToAppend={getCurrentQueryString()}
                  >
                    <ActionSpan
                      className={`action${
                        userType === AccessUserType.Corporate
                          ? " blue-glickon"
                          : ""
                      } switch-form-action`}
                    >
                      <Translate label={changeFormKeys[1]} />
                    </ActionSpan>
                  </Link>
                )}
              </ChangeForm>
            )}
          </form>
        )
      }}
    </Formik>
  )
}

export default AccessForm
