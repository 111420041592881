import styled from "styled-components"
import styleConstants from "../../../../constants/styleConstants"
import SimpleImage from "../../../../commonComponents/image"
import {raisable} from "../../../../mixins"

export const CompanySectionItemCard = styled.div`
  cursor: pointer;
  position: relative;
  height: 15.5rem;
  background-color: ${styleConstants.white};
  border-radius: ${styleConstants.defaultBorderRadius};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  border: 1px solid ${styleConstants.cardBorder};

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    height: 15.625rem;
  }

  ${raisable}
`

export const CompanySectionItemTop = styled.div`
  padding: 1.125rem;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    padding: 1.5rem 1.75rem 1rem;
  }
`

export const CompantSectionItemName = styled.div`
  color: ${styleConstants.blue00};
  font-size: ${styleConstants.font.size20};
  font-family: ${styleConstants.galanoGrotesqueMedium};
  padding-top: 0.5rem;
  font-weight: 600;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    font-size: ${styleConstants.font.size20};
  }
`

export const CompanySectionItemDescription = styled.div`
  padding-top: 0.5rem;
  color: ${styleConstants.dark01};
  font-family: ${styleConstants.calibre};
  font-size: ${styleConstants.font.size16};

  p {
    margin-top: 0;
  }
`

export const CompanySectionItemBottom = styled.div`
  margin-top: auto;
  width: 100%;
  height: 6.875rem;
`

export const CompanySectionItemImage = styled(SimpleImage).attrs({
  backgroundCover: true
})`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
  border-radius: 0;
`
