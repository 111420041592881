import ReactPixel from "react-facebook-pixel"
import { getCookie } from "../utils"

export const challengePixelMap: Record<string, string> = {
    "41QS29": "104266466726289",
    PHZVY6: "1234567890",
    "ACME02": "1234567890"
}

export function runFacebookPixel(pixelId: string) {
    ReactPixel.init(pixelId)
    const hasAcceptedCookies = !!getCookie("glickon-cookie")
    if (hasAcceptedCookies) {
        ReactPixel.grantConsent()
    }
    ReactPixel.pageView()
}
