import React from "react"
import {Check, Checkmark, CheckSign, Container, StyledLabel} from "./styles"
import {AboveInputWrapper, StyledErrorFeedback} from "../input/styles"
import Translate from "../translate"
import {Dictionary} from "../../types"

type PropsType = React.InputHTMLAttributes<HTMLInputElement> & {
  children?: React.ReactNode
  label: string
  error?: string | false
  errorId?: string,
  customLabelColor?: string,
  customLabel?: string | null,
  labelReplacements?: Dictionary<string | number>
}

const Checkbox: React.FC<PropsType> = (props: PropsType) => {
  const {
    id,
    label,
    error = "",
    errorId = "checkbox-negative-feedback",
    customLabelColor = "",
    customLabel,
    labelReplacements,
    ...otherProps
  } = props

  return (
    <>
      <AboveInputWrapper>
        {error && (
          <StyledErrorFeedback id={errorId} left>
            {error}
          </StyledErrorFeedback>
        )}
      </AboveInputWrapper>
      <Container>
        <StyledLabel customColor={customLabelColor}>
          {customLabel ? <span dangerouslySetInnerHTML={{ __html: customLabel }} /> : <Translate label={label} replacements={labelReplacements} />}
        </StyledLabel>
        <Check id={id} name={id} type="checkbox" {...otherProps} />
        <Checkmark />
        <CheckSign className="icon-correct" />
      </Container>
    </>
  )
}

export default Checkbox
