import { TranslationType } from "../types"
import {
  aboutUsKey,
  candidateExperienceKey,
  candidatePlatformKey,
  caseStudiesKey,
  caseStudyKey,
  platformCookieKey,
  documentGeneralTermsAndConditionsKey,
  employeeExperienceKey,
  homepageKey,
  jobKey,
  liveKey,
  livePrivacyKey,
  liveTermsAndConditionsKey,
  notFoundKey,
  peopleAnalyticsKey,
  platformPrivacyKey,
  requestADemoKey,
  resourcesCandidateExperienceKey,
  resourcesEmployeeExperienceKey,
  resourcesKey,
  resourcesPeopleAnalyticsKey,
  platformSecurityKey,
  teamKey,
  platformTermsAndConditionsKey,
  termsOfServicesKey,
  loginKey,
  signupKey
} from "./pathKeys"

const metaDescriptions: TranslationType = {
  [candidatePlatformKey]: {
    en:
      "Show off your skills, challenge your friends and find the right job for you.",
    it:
      "Dimostra il tuo talento, sfida i tuoi amici e trova il lavoro in linea con le tua capacità e aspettative."
  },

  // home
  [homepageKey]: {
    en:
      "Glickon is the candidate and employee experience platform that can help you engage both candidates and employees while generating data and insights to guide your strategic decisions.",
    it:
      "Glickon è la piattaforma di candidate e employee experience in grado di aiutarti a coinvolgere candidati e dipendenti, con l’obiettivo di generare dati e insights utili alle tue decisioni strategiche."
  },

  // login
  [loginKey]: {
    en:
      "Glickon is the candidate and employee experience platform that can help you engage both candidates and employees while generating data and insights to guide your strategic decisions.",
    it:
      "Glickon è la piattaforma di candidate e employee experience in grado di aiutarti a coinvolgere candidati e dipendenti, con l’obiettivo di generare dati e insights utili alle tue decisioni strategiche."
  },

  // signup
  [signupKey]: {
    en:
      "Glickon is the candidate and employee experience platform that can help you engage both candidates and employees while generating data and insights to guide your strategic decisions.",
    it:
      "Glickon è la piattaforma di candidate e employee experience in grado di aiutarti a coinvolgere candidati e dipendenti, con l’obiettivo di generare dati e insights utili alle tue decisioni strategiche."
  },

  // request a demo
  [requestADemoKey]: {
    en:
      "What are your HR goals? Let’s establish them together to improve candidate and employee experience for your company.",
    it:
      "Quali sono i tuoi obiettivi HR? Scopriamoli insieme e miglioriamo candidate e employee experience della tua organizzazione."
  },

  // products
  [candidateExperienceKey]: {
    en:
      "With Glickon Candidate Experience you can map out your candidate experience with costumized games collecting data that can prove usefull in your selection process.",
    it:
      "Con Glickon Candidate Experience puoi progettare l’esperienza dei tuoi candidati, con giochi personalizzabili e raccogliendo utili alle tue decisioni."
  },
  [employeeExperienceKey]: {
    en:
      "Glickon employee experience is a platform able to measure and understand the experience of your employees on the job.",
    it:
      "Glickon Employee Experience è una piattaforma in grado di misurare e comprendere l’esperienza delle persone che lavorano nella tua azienda."
  },
  [peopleAnalyticsKey]: {
    en:
      "GlickonX is a People Analytics software that can analyze employee data and convert it into business decisions. The system integrated with all common communication tools.",
    it:
      "GlickonX è un software di People Analytics in grado di analizzare i dati sui dipendenti e tradurli in decisioni di business. Si integra con i più classici tool di comunicazione."
  },
  [liveKey]: {
    en:
      "HR Gamification is Glickon Live Game. You can turn meetings with candidates into actual interactive game shows.",
    it:
      "L’HR Gamification è Glickon Live Game. Puoi trasformare i momenti di incontro con candidati e dipendenti in veri e propri game show interattivi."
  },

  // about
  [aboutUsKey]: {
    en: "",
    it:
      "Aiutiamo la tua azienda a migliorare la selezione di nuovi candidati e le performance dei dipendenti."
  },
  [teamKey]: {
    en: "",
    it:
      "Ecco il team di candidate e employee experience in grado di migliorare le performance della tua azienda."
  },
  [jobKey]: {
    en: "",
    it: ""
  },

  // resources
  [resourcesKey]: {
    en:
      "Employee experience, candidate experience and people analytics. What is it? what return on investment?",
    it:
      "Employee experience, candidate experience e people analytics. Di cosa si tratta? quale ritorno sull'investimento?"
  },
  [resourcesEmployeeExperienceKey]: {
    en:
      "Employee experience. What it is, what it is about, how to do it, how to get a return on investment.",
    it:
      "Employee experience. Cos'è, di cosa si tratta, come fare, come avere un ritorno sull'investimento."
  },
  [resourcesCandidateExperienceKey]: {
    en:
      "Candidate experience. What it is, what it is about, how to do it, how to get a return on investment.",
    it:
      "Candidate experience. Cos'è, di cosa si tratta, come fare, come avere un ritorno sull'investimento."
  },
  [resourcesPeopleAnalyticsKey]: {
    en:
      "People Analytics. What it is, what it is about, how to do it, how to get a return on investment.",
    it:
      "People Analytics.  Cos'è, di cosa si tratta, come fare, come avere un ritorno sull'investimento."
  },

  // case-study
  [caseStudiesKey]: {
    en:
      "Success stories related to the application of employee experience, candidate experience and people analytics.",
    it:
      "I casi di successo relativi all'applicazione di employee experience, candidate experience e people analytics."
  },
  [caseStudyKey]: {
    en:
      "Discover how to improve your HR process. Discover all the cases on candidate experience, employee experience and people analytics.",
    it:
      "Scopri migliorare il tuo processo HR. Scopri tutti i casi su candidate experience, employee experience e people analytics."
  },

  // cms pages
  [platformCookieKey]: {
    en: "",
    it: ""
  },
  [documentGeneralTermsAndConditionsKey]: {
    en: "",
    it: ""
  },
  [livePrivacyKey]: {
    en: "",
    it: ""
  },
  [liveTermsAndConditionsKey]: {
    en: "",
    it: ""
  },
  [platformPrivacyKey]: {
    en: "",
    it: ""
  },
  [platformTermsAndConditionsKey]: {
    en: "",
    it: ""
  },
  [termsOfServicesKey]: {
    en: "",
    it: ""
  },
  [platformSecurityKey]: {
    en: "",
    it: ""
  },

  [notFoundKey]: {
    en: "",
    it: ""
  }
}

export default metaDescriptions
