import React from "react"
import {Tooltip} from "./styles"
import {TooltipProps} from "react-tooltip"

interface PropsType extends TooltipProps {
  id: string
}

const CustomTooltip: React.FC<PropsType> = (props: PropsType) => {
  const {id, ...otherProps} = props
  return <Tooltip id={id} {...otherProps} />
}

export default CustomTooltip
