import React, {FC} from "react"
import styled from "styled-components"
import styleConstants from "../../constants/styleConstants"
import {absoluteCentered} from "../../mixins"
import {getImageUrl} from "../../utils"

export const EmailFloatingButton: FC = () => (
  <div />
  //   <EmailFloatingButtonLink href="mailto:support@glickon.com" target="_blank">
  //     <EmailIcon src={getImageUrl("access/icn-email.svg")} />
  //   </EmailFloatingButtonLink>
)

const EmailFloatingButtonLink = styled.a`
  position: fixed;

  box-shadow: 0 0.125rem 2rem 0 rgba(0, 0, 0, 0.15);

  border-radius: 50%;
  background: ${styleConstants.blue00};
  z-index: 9;

  bottom: 1rem;
  right: 1rem;
  width: 2.5rem;
  height: 2.5rem;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    bottom: 2rem;
    right: 2rem;
    width: 3.75rem;
    height: 3.75rem;
  }
`

const EmailIcon = styled.img`
  ${absoluteCentered}
  width: 1.25rem;
  height: 1.25rem;
  margin-top: 0.125rem;

  @media (min-width: ${styleConstants.desktopMinWidth}) {
    width: 1.5rem;
    height: 1.5rem;
  }
`
