import React, {FC, Fragment, useState} from "react"
import {ViewWrapper, NavBar, NavBarItem} from "./styles"
import {
  CandidatePlatformLogin,
  CandidatePlatformSignup
} from "../../../candidate-platform"
import Translate from "../../../../commonComponents/translate"

interface Props {
  // url to be used for redirect after successful signup/login
  challengePath: string
  owner?: string
  companyCareersName?: string
  customTitle?: string
  customDescription?: string
  customBodyBackgroundColor?: string
  customPrimaryTextColor?: string
  customButtonColor?: string
  customButtonHoverColor?: string
  customButtonTextColor?: string
}

const SIGNUP_VIEW = "signup"
const LOGIN_VIEW = "login"

const ChallengeLogin: FC<Props> = ({
  challengePath,
  owner,
  companyCareersName,
  customTitle,
  customDescription,
  customBodyBackgroundColor,
  customPrimaryTextColor,
  customButtonColor,
  customButtonHoverColor,
  customButtonTextColor
}) => {
  const [navItem, setNavItem] = useState(SIGNUP_VIEW)
  const navBar = (
    <NavBar
      firstChildSelected={navItem === SIGNUP_VIEW}
      customBodyBackgroundColor={customBodyBackgroundColor}
    >
      <NavBarItem
        selected={navItem === SIGNUP_VIEW}
        customBodyBackgroundColor={customBodyBackgroundColor}
        customPrimaryTextColor={customPrimaryTextColor}
        onClick={() => setNavItem(SIGNUP_VIEW)}
      >
        <Translate label="form-candidate-get-started" />
      </NavBarItem>
      <NavBarItem
        selected={navItem === LOGIN_VIEW}
        customBodyBackgroundColor={customBodyBackgroundColor}
        customPrimaryTextColor={customPrimaryTextColor}
        onClick={() => setNavItem(LOGIN_VIEW)}
      >
        <Translate label="form-candidate-login" />
      </NavBarItem>
    </NavBar>
  )

  const view = {
    [SIGNUP_VIEW]: (
      <CandidatePlatformSignup
        redirectUrl={challengePath}
        owner={owner}
        companyCareersName={companyCareersName}
        customTitle={customTitle}
        customDescription={customDescription}
        customBodyBackgroundColor={customBodyBackgroundColor}
        customPrimaryTextColor={customPrimaryTextColor}
        customButtonColor={customButtonColor}
        customButtonHoverColor={customButtonHoverColor}
        customButtonTextColor={customButtonTextColor}
        inChallengeSignup
      />
    ),
    [LOGIN_VIEW]: (
      <CandidatePlatformLogin
        redirectUrl={challengePath}
        owner={owner}
        customTitle={customTitle}
        customDescription={customDescription}
        customBodyBackgroundColor={customBodyBackgroundColor}
        customPrimaryTextColor={customPrimaryTextColor}
        customButtonColor={customButtonColor}
        customButtonHoverColor={customButtonHoverColor}
        customButtonTextColor={customButtonTextColor}
        inChallengeLogin
      />
    )
  }[navItem]

  return (
    <Fragment>
      {navBar}
      <ViewWrapper>{view}</ViewWrapper>
    </Fragment>
  )
}

export default ChallengeLogin
