import { TranslationType } from "../types"

const translations: TranslationType = {
  // header-product
  "candidate-experience-top-title": {
    en: "Candidate Experience",
    it: "Candidate Experience"
  },
  "candidate-experience-top-description": {
    en: "Create your community of candidates, let them compete and select talents",
    it: "Crea la tua community di candidati, falla competere e seleziona talenti"
  },
  "candidate-experience-header-paragraph-1-title": {
    en: "Growth",
    it: "Growth"
  },
  "candidate-experience-header-paragraph-1-text": {
    en: "Accelerate your screening and selection process with a fast, scalable and cost effective solution.",
    it: "Accelera la pipeline di selezione e screening con un sistema scalabile, rapido ed economico."
  },
  "candidate-experience-header-paragraph-2-title": {
    en: "Engagement",
    it: "Engagement"
  },
  "candidate-experience-header-paragraph-2-text": {
    en: "Attract the best talent through an engaging and interactive application process thanks to gamification.",
    it: "Coinvolgi i migliori talenti in un percorso di candidatura ingaggiante e interattivo grazie alla gamification."
  },
  "candidate-experience-header-paragraph-3-title": {
    en: "Experience",
    it: "Experience"
  },
  "candidate-experience-header-paragraph-3-text": {
    en: "Create a memorable application experience and a unique employer branding.",
    it: "Costruisci un’esperienza di candidatura memorabile e un employer branding unico."
  },

  // subtitle-module-1
  "candidate-experience-1st-subtitle-title": {
    en: "how it works",
    it: "come funziona"
  },
  "candidate-experience-1st-subtitle-description": {
    en: `
            Design the best experience for your candidates, check their skills through a game with many simple and
            customizable challenges, discover how many and which are the most capable candidates. And hire them. The
            selection process is not a game, but we make it fun, engaging and productive. Just like work should be,
            daily.
        `,
    it: `
            Progetta la migliore esperienza per i tuoi candidati, verifica
            le competenze attraverso un gioco con tante challenge
            semplici e personalizzabili, scopri quanti e quali sono i
            candidati più capaci. E assumili. Il processo di selezione
            non è un gioco, ma noi lo rendiamo divertente, coinvolgente
            e produttivo. Proprio come dovrebbe essere il lavoro di tutti
            i giorni. 
        `
  },

  // widget-3-columns
  "candidate-experience-widget-3-columns-1st-title": {
    en: "Put talent into play",
    it: "Metti in gioco il talento"
  },
  "candidate-experience-widget-3-columns-1st-description": {
    en: `
            Attract the candidates on Glickon’s platform in an organic way. Create your challenge and check the 
            skills with quick game sessions.
        `,
    it: `
            Attira in maniera organica i candidati sulla piattaforma
            Glickon. Crea la tua challenge e verifica le competenze con
            veloci sessioni di gioco.
        `
  },
  "candidate-experience-widget-3-columns-2nd-title": {
    en: "Create a ranking",
    it: "Crea una classifica"
  },
  "candidate-experience-widget-3-columns-2nd-description": {
    en: `
            Glickon’s technology analyzes engagement and skills and creates an ordered ranking where the score is in 
            line with the fit index.
        `,
    it: `
            La tecnologia Glickon analizza engagement e competenze.
            E crea una classifica ordinata dove il punteggio è sinonimo
            di fit index.
        `
  },
  "candidate-experience-widget-3-columns-3rd-title": {
    en: "Evaluate and hire the best",
    it: "Valuta e assumi i migliori"
  },
  "candidate-experience-widget-3-columns-3rd-description": {
    en: `
            Select the most deserving ones for assessments and subsequent interviews through a collaborative dashboard 
            shared with your HR colleagues.
        `,
    it: `
            Seleziona i più meritevoli per gli assessment e per i
            successivi colloqui attraverso una dashboard collaborativa
            e condivisa con i colleghi HR.
        `
  },

  // full-width-img-text
  "candidate-experience-1st-horizontal-title": {
    en: "1. Talent Acquisition",
    it: "1. Talent Acquisition"
  },
  "candidate-experience-1st-horizontal-description": {
    en: `
            Your goal: to bring the best candidates to your company in the shortest time possible by improving the
            pre-screening process. Glickon’s goal: to provide you with a practical and ordered platform to create your
            community, select it through an engagement and gamification process, facilitating the recruiting process and
            making it fluid and fast. 
            <br/><br/>
            Together with one keyword: Experience. Because experience is the first step that highlights the appeal of
            your brand, attracting candidates with a real interest in your corporate culture. From here we generate data
            to highlight the fit index between the candidate and the required skills, thus speeding up his productivity
            in the subsequent onboarding period. 
        `,
    it: `
            Il tuo obiettivo: portare in azienda i candidati più bravi e nel
            minor tempo possibile migliorando il pre-screening. Quello
            di Glickon: fornirti una piattaforma pratica e ordinata per
            creare la tua community, selezionarla in un percorso di
            engagement e gamification, agevolando il processo di
            recruiting e rendendolo fluido e veloce. 
            <br/><br/>
            Insieme a una parola chiave. Experience. Perché
            l’esperienza è il primo step che mette in evidenza l’appeal
            del tuo brand, attirando i candidati con un reale interesse
            verso la tua cultura aziendale. E da qui che generiamo dati
            per evidenziare il fit index tra il candidato e le competenze
            richieste, accelerando così la sua operatività e produttività
            nel successivo periodo di onboarding. 
        `
  },
  "candidate-experience-2nd-horizontal-title": {
    en: "2. Employer Branding",
    it: "2. Employer Branding"
  },
  "candidate-experience-2nd-horizontal-description": {
    en: `
            Job descriptions and economic offers are not the main aspects that describe your company. You must attract
            your audience starting from a candidate journey that highlights talent through competition. This is
            precisely where your brand promotion starts, within a structure that generates appeal and makes you say: «I
            want to work with you, even before knowing what my salary would be».
        `,
    it: `
            Job description e offerte economiche non descrivono la tua
            azienda. Devi attrarre l’audience partendo da un candidate
            journey che fa emergere il talento attraverso la
            competizione. È qui che inizi a promuovere il tuo brand,
            dentro un’architettura che genera appeal e fa dire: «Voglio
            lavorare con voi, prima ancora di sapere quale sarà il mio
            stipendio».
        `
  },
  "candidate-experience-3rd-horizontal-title": {
    en: "3. Partner Experience",
    it: "3. Partner Experience"
  },
  "candidate-experience-3rd-horizontal-description": {
    en: `
            We offer a platform where you can personalize directly the content to be in line with your recruitment 
            goals. More than 100 ready-to-use challenges are available in our library to save you time.
            <br/><br/>
            But, if necessary and in synergy with the HR team, Glickon guarantees all the support you need to draft 
            your tailor-made candidate experience strategy. 
        `,
    it: `
            Ti offriamo una piattaforma per personalizzare in autonomia
            i contenuti più in linea con il tuo obiettivo di recruiting. Nella
            nostra library sono disponibili più di 100 sfide ready-to-use
            per farti risparmiare tempo.
            <br/><br/>
            Ma, se necessario e in sinergia
            con il team HR, Glickon garantisce tutto il supporto per
            redigere la tua strategia di candidate experience su misura. 
        `
  },

  // products
  "candidate-experience-products-title": {
    en: "Key features",
    it: "Funzionalità principali"
  },
  "candidate-experience-products-1st-name": {
    en: "Let your brand<br/>Be succesfull",
    it: "Fai fare carriera<br/>al tuo brand"
  },
  "candidate-experience-products-1st-description": {
    en: `
            Glickon integrates perfectly on your career page and positions the image of your company on innovation 
            and digital values.
        `,
    it: `
            Glickon si integra alla perfezione sulla tua career page e
            posiziona l’immagine della tua azienda su valori di
            innovation e digital.
        `
  },
  "candidate-experience-products-2nd-name": {
    en: "Save time.<br/>And therefore money",
    it: "Risparmia tempo.<br/>E quindi budget"
  },
  "candidate-experience-products-2nd-description": {
    en: `
            One single database for applications, feedback forms...and many other features for a quick pre-screening
            process that dramatically reduces HR costs.
        `,
    it: `
            Unico database di candidature, feedback form ... e tante
            funzionalità per un pre-screening rapido che abbatte i costi
            HR.
        `
  },
  "candidate-experience-products-3rd-name": {
    en: "Evaluate skills<br/>in an objective way",
    it: "Valuta le competenze<br/>in maniera oggettiva"
  },
  "candidate-experience-products-3rd-description": {
    en: `
            Fit index and library allow you to create a skills map in order to choose the candidates most in line with 
            your job offers.
        `,
    it: `
            Fit index e library ti permettono di creare una mappa delle
            competenze per scegliere i candidati più in linea con le tue
            offerte di lavoro.
        `
  },

  // bottom-full-width
  "candidate-experience-bottom-title": {
    en: "Skills + Engagement + Analysis = Productivity",
    it: "Competenze + Engagement + Analisi = Produttività"
  },
  "candidate-experience-bottom-description": {
    en: `
            Creating the design of your work organization, promoting skills and testing them, hiring the best
            candidates. What’s more: measuring their potential and the probability of growth by analyzing data and
            insights produced through gamification that stimulates attention, memory capacity and speed of execution.
            Glickon helps you make quick decisions, choose the candidate most in line to deliver on performance and
            productivity. All this while bringing a positive impact on the company’s economic results.
        `,
    it: `
            Creare il design della tua organizzazione di lavoro,
            promuovere competenze e verificarle, assumere i candidati
            migliori. Di più: misurare il loro potenziale e le probabilità di
            crescita analizzando dati e insight prodotti attraverso la
            gamification che stimola attenzione, capacità di memoria e
            velocità d’esecuzione. Glickon ti aiuta a prendere decisioni
            rapide, a scegliere il candidato capace di capitalizzare
            performance e produttività. Con un impatto positivo sui
            risultati economici dell’azienda.
        `
  },

  // banner-bottom
  "candidate-experience-banner-bottom-title": {
    en: "Accept the challenge, design your new Candidate Experience!",
    it: "Accetta la sfida, progetta la tua nuova Candidate Experience!"
  },
  "candidate-experience-banner-bottom-description": {
    en:
      "We build it together, but then you will be the one bringing new talents to the company",
    it: "La costruiamo insieme, ma sarai tu a portare nuovi talenti in azienda"
  }
}

export default translations
