import React from "react"
import { RouteComponentProps, withRouter, Redirect } from "react-router"
import useTranslation from "../../../hooks/useTranslation"
import LoaderFullScreen from "../../../commonComponents/loader-full-screen"
import { generateLocalizedPath, stripTags } from "../../../utils"
import { getCustomStyle } from "../../../customStyle"
import { homepageKey } from "../../../constants/pathKeys"
import useCompanyExternalPage from "../../../hooks/useCompanyExternalPage"
import Informations from "../components/company-informations"
import {
  CompanyMissionAndValuesDescription as MissionAndValuesDescription,
  CompanyMissionAndValuesTitle as MissionAndValuesTitle,
  CompanyMissionAndValuesCard as MissionAndValuesCard
} from "./style"
import WhoWeAre from "../components/company-who-we-are"
import ExternalPageCompanyShell, {
  ExternalPageCompanyLikeType
} from "../../../commonComponents/external-page-company-shell"
import CompanyMedia from "../components/company-media"

interface Params {
  companyName: string
}

const GENERALI_CAREERS_NAME = "generali"

interface Props extends RouteComponentProps<Params> { }

const Company: React.FC<Props> = ({
  match: {
    params: { companyName }
  }
}: Props) => {
  const {
    company,
    hasError: redirectToHome,
    redirectPath,
    corePath
  } = useCompanyExternalPage(companyName)

  const [translate, locale] = useTranslation()

  if (redirectPath) return <Redirect to={redirectPath} />

  if (redirectToHome) {
    return <Redirect to={`${generateLocalizedPath(homepageKey, locale)}`} />
  }

  if (!company) return <LoaderFullScreen />

  const {
    carousel,
    challenges,
    sections,
    color,
    missionAndValues,
    squareLogoUrl,
    name,
    careersName,
  } = company

  const {
    customPrimaryTextColor,
    customButtonColor,
    customButtonHoverColor,
    customButtonTextColor
  } = getCustomStyle(careersName)

  const carouselUrls = carousel.map(media => media.url)

  const missionAndValuesRawComponent = stripTags(missionAndValues) ? (
    <div>
      <MissionAndValuesTitle>
        {translate(careersName === GENERALI_CAREERS_NAME ? "global-generali-details-mission-and-values-title" : "global-company-details-mission-and-values-title")}
      </MissionAndValuesTitle>
      <MissionAndValuesDescription
        dangerouslySetInnerHTML={{ __html: missionAndValues }}
      />
    </div>
  ) : null

  const missionAndValuesComponent = missionAndValuesRawComponent ? (
    <div className="row">
      <MissionAndValuesCard>
        {missionAndValuesRawComponent}
      </MissionAndValuesCard>
    </div>
  ) : null

  const companyMedia = (
    <CompanyMedia
      images={company.images}
      videos={company.videos}
      carousel={company.carousel}
      companyName={company.name}
      careersName={careersName} />
  )

  return (
    <ExternalPageCompanyShell
      nextUrl={corePath}
      isPublic // Company page is public by design
      carouselUrls={carouselUrls}
      careersName={careersName}
      challenges={challenges}
      sections={sections}
      companyChallengesListDefaultColor={color}
      type={ExternalPageCompanyLikeType.company}
    >
      {{
        topInformations: (
          <Informations
            companyName={name}
            logoUrl={squareLogoUrl}
            customTitleColor={customPrimaryTextColor}
            customButtonColor={customButtonColor}
            customButtonHoverColor={customButtonHoverColor}
            customButtonTextColor={customButtonTextColor}
            careersName={careersName}
          />
        ),
        bottomInformationsList: [
          <WhoWeAre company={company} />,
          missionAndValuesComponent,
          companyMedia
        ]
      }}
    </ExternalPageCompanyShell>
  )
}

export default withRouter<Props, React.FC<Props>>(Company)
