const styleConstants = {
  // font family
  calibre: "'Calibre', sans-serif",
  calibreMedium: "'CalibreMedium', sans-serif",
  calibreItalic: "'CalibreItalic', sans-serif",
  calibreSemibold: "'CalibreSemibold', sans-serif",
  calibreSemiboldItalic: "'CalibreSemiboldItalic', sans-serif",
  galanoGrotesque: "'GalanoGrotesque', sans-serif",
  galanoGrotesqueMedium: "'GalanoGrotesqueMedium', sans-serif",
  galanoGrotesqueSemibold: "'GalanoGrotesqueSemibold', sans-serif",

  // fontSizes
  font: {
    size12: "0.75rem",
    size14: "0.875rem",
    size16: "1rem",
    size17: "1.025rem",
    size18: "1.125rem",
    size20: "1.25rem",
    size22: "1.375rem",
    size24: "1.5rem",
    size26: "1.625rem",
    size28: "1.75rem",
    size30: "1.875rem",
    size32: "2rem",
    size34: "2.125rem",
    size36: "2.25rem",
    size38: "2.375rem",
    size40: "2.5rem",
    size48: "3rem",
    size50: "3.125rem",
    size56: "3.5rem",
    size60: "3.75rem",
    size64: "4rem"
  },

  // dimensions
  defaultBorderRadius: "0.25rem",
  contentMaxWidth: "1200px",
  desktopMinWidth: "1024px",
  desktopMinWidthNoPx: 1024,
  tabletMaxWidth: "786px",
  tabletMaxWidthNoPx: 786,
  phoneMaxWidth: "500px",
  phoneMaxWidthNoPx: 500,
  topBarHeight: "5.5rem", // topBar
  topBarSmallHeight: "3.75rem", // topBarSmall
  topBarHeightMobile: "3.5rem", // topBarMobile
  thresholdPageYOffeset: 200, // px threshold switch topBar to topBarSmall
  externalPageTopBarMobileHeight: "4.5rem",

  // colors
  glickonGradient: "linear-gradient(to left, #27d7c9, #19a7d2 49%, #2873ca)",
  glickonGradientVertical:
    "linear-gradient(to bottom, #27d7c9, #19a7d2 49%, #2873ca)",
  replacementGradient:
    "linear-gradient(to right, rgba(52, 69, 99, 0.06), rgba(255, 255, 255, 0.03))",

  exGradient: "linear-gradient(to left, #19a7d2, #2873ca)",
  cxGradient: "linear-gradient(to left, #27d7c9, #19a7d2)",

  newGradientColors: `linear-gradient(
    to right,
    #15b3f3,
    #5b47c7 32%,
    #d54286 67%,
    #fa6345 100%
  )`,

  glickonBlack: "#0f171d",
  glickonLightBlack: "#3a444b",

  black: "#000000",
  dark01: "#444444",
  dark02: "#777777",
  dark03: "#d0d0d0",
  dark04: "#f4f5f7",
  white: "#ffffff",

  blue00: "#0b273d",
  blue01: "#abb7bf",
  blue02: "#e8eaed",

  fluoBlueGlickon: "#27d7c9",
  lightBlueGlickon: "#19a7d2",
  blueGlickon: "#2873ca",
  anotherBlueGlickon: "#1eb9cf",
  employeeAccessBlueGlickon: "#1b9fd2",
  blackGlickon: "#0F171D",

  orange: "#F5A623",

  goodFeedback: "#12945d",
  badFeedback: "#d1132d",

  cardBorder: "#dedede",
  backgroundMediumGray: "#adb7be",
  facebookColor: "#1877f2",
  linkedinColor: "#0A66C2",
  googleBlueColor: "#4285F4",

  dividerGray: "#eeeeee",

  sectionDescriptionGray: "#666666",
  languageSelectorColor: "rgba(171,183,191, 0.5)"
}

export default styleConstants
