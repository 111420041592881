import { TranslationType } from "../types"

const translations: TranslationType = {
  "corporate-tc-title": {
    en: "Terms and conditions<br/>Candidate Experience",
    it: "Termini e condizioni d'uso<br/>Candidate Experience"
  },
  "corporate-tc-pre-list-text": {
    en: "",
    it: ""
  },
  "corporate-tc-last-modified": {
    en: "Milan, 22nd February 2021",
    it: "Milano, 22 Febbraio 2021"
  },
  "corporate-tc-1": {
    en: "Introduction",
    it: "Introduzione"
  },
  "corporate-tc-1-1": {
    it: "Le presenti condizioni d’uso <strong>(’Condizioni’)</strong> sono stipulate tra Glickon e l’Utente (singolarmente una <strong>‘Parte’</strong> e, insieme, le <strong>‘Parti’</strong>) e disciplinano l’utilizzo della Piattaforma (come di seguito definita) e delle sue funzionalità da parte di quest’ultimo, per le finalità di seguito descritte, entrando in vigore al momento dell’accettazione da parte dell’Utente. Per tale motivo, l’utilizzo e la registrazione alla Piattaforma sono consentiti solo ad Utenti che, secondo la normativa applicabile, siano legalmente capaci di agire. Le Condizioni d’uso e ogni altro documento ivi richiamato o allegato formano, unitamente all’informativa sul trattamento dei dati personali, l’intero accordo vincolante tra Glickon e l’Utente relativamente all’utilizzo della Piattaforma di seguito delineato.",
    en: "These Terms and Conditions (<strong>'T&C'</strong>) are stipulated between Glickon and the User (individually a <strong>'Party'</strong> and together the <strong>'Parties'</strong>) and govern the latter's use of the Platform (as defined below) and its functionalities for the purposes described below, coming into force at the moment of acceptance by the User. For this reason, use of and registration on the Platform is only permitted to Users who, according to the applicable legislation, are legally capable of acting. The T&C and any other document referred to or attached hereto, together with the privacy policy form the entire binding agreement between Glickon and the User regarding the use of the Platform outlined below."
  },
  "corporate-tc-1-2": {
    it: "Navigando sulla Piattaforma, registrandosi ad essa o utilizzando le sue funzionalità, l’Utente dichiara di aver letto e compreso le presenti Condizioni e di accettarne integralmente ed incondizionatamente ogni disposizione. Se l’Utente non intende accettare le presenti Condizioni, deve interrompere immediatamente la navigazione e non eﬀettuare alcuna registrazione alla Piattaforma, senza accedere, visionare, scaricare o in altro modo utilizzare alcuna pagina, contenuto o servizio oﬀerto dalla Piattaforma, mettendosi in contatto con il proprio referente presso l’Azienda Partner.",
    en: "By browsing the Platform, registering with it or using its features, the User declares that he or she has read and understood these Terms and Conditions and fully and unconditionally accepts each and every one of their provisions. If the User does not intend to accept these T&C, he or she must immediately stop browsing and not register to the Platform, nor access, view, download or otherwise use any page, content or service provided by the Platform, by contacting his or her contact person at the Partner Company."
  },
  "corporate-tc-2": {
    en: "Definitions",
    it: "Definizioni"
  },
  "corporate-tc-2-1": {
    it: `I termini utilizzati nelle presenti Condizioni con iniziale maiuscola avranno il significato qui di seguito indicato, valido sia al singolare che al plurale:
        <ul>
        <li><strong>‘Azienda Partner’</strong> indica la società che ha stretto accordi commerciali con Glickon per l’inserimento sulla Piattaforma di una Company Page o di una o più Challenge Aziendali finalizzate al raggiungimento di uno Scopo Aziendale;</li>
        <li><strong>‘Challenge’</strong> indica la sfida, basata su Test a risposta multipla o aperta, inserita sulla Piattaforma direttamente da Glickon, alla quale possono partecipare tutti gli Utenti, finalizzata a valutare la conoscenza di una determinata materia e/o il possesso di una determinata competenza;</li>
        <li><strong>‘Challenge aziendale’</strong> indica una sezione della Piattaforma, organizzata dall’Azienda Partner, che può includere Contenuto e/o Test, al fine di ingaggiare gli Utenti in sfide in relazione ad uno Scopo Aziendale;</li>
        <li><strong>‘Company Page’</strong> indica la pagina profilo di una Azienda Partner in cui la stessa può inserire il Contenuto finalizzato a realizzare lo Scopo Aziendale sulla Piattaforma;</li>
        <li><strong>‘Contenuto’</strong> indica, a titolo esemplificativo e non esaustivo, testo, immagini, grafiche, video, audio e qualsiasi altra informazione o dato caricato sulla Piattaforma dall’Azienda Partner, o da Glickon per conto della stessa, al fine di ingaggiare gli Utenti e/o fornire informazioni in connessione ad uno Scopo Aziendale;</li>
        <li><strong>‘Contenuto/i dell’Utente’</strong> indica qualsiasi Contenuto, come a titolo esemplificativo foto, dati, testi, documenti elettronici in qualsiasi formato, <i>software</i>, elementi grafici, suoni, immagini, video o altri materiali, protetti o meno dal diritto d’autore o altri diritti, caricati o comunque messi a disposizione dall’Utente sulla Piattaforma;</li>
        <li><strong>‘Glickon’</strong> indica Glickon S.r.l., società di diritto italiano con sede legale in Milano, via Nino Bixio 7, codice fiscale/partita IVA/numero di iscrizione al registro delle imprese di Milano 08573820969;</li>
        <li><strong>‘Piattaforma’</strong> indica la piattaforma proprietaria di Glickon, accessibile all’URL www.glickon.com, su cui gli Utenti vengono ingaggiati dall’Azienda Partner, o da Glickon per conto di quest’ultima, in relazione a Challenge volte a valutare le loro competenze utilizzando specifiche modalità di gioco. L’Utente potrà partecipare per puro divertimento, per allenare le proprie conoscenze/competenze o per entrare in contratto con Aziende Partner di Glickon. La Piattaforma è disponibile in versione desktop accessibile attraverso i più comuni <i>web-browser</i>;</li>
        <li><strong>‘Scopo Aziendale’</strong> indica le attività per cui la Piattaforma può essere utilizzata dall’Azienda Partner, tra cui, a titolo esemplificativo e non esaustivo, attività di <i>employer branding</i>, <i>talent engagement</i>, <i>selezione di personale</i>, <i>skill assessment</i>, <i>sentiment analysis</i> e <i>survey</i>;</li>
        <li><strong>‘Test’</strong> indica una serie di domande e risposte riguardanti diverse aree tematiche, finalizzate a verificare il livello di conoscenza di una specifica tematica o materia da parte di uno o più Utenti. Ciascun Test può essere associato ad una o più Challenge/Challenge Aziendale e può essere creato autonomamente dall’Azienda Partner o da Glickon per conto della stessa;</li>
        <li><strong>‘Utente’</strong> indica la persona fisica che accetta e si vincola alle presenti Condizioni e che utilizza la Piattaforma e le sue funzionalità al di fuori della propria attività imprenditoriale, commerciale, artigianale o professionale eventualmente svolta.</li>
        </ul>
        `,
    en: `
        Terms used in these Terms and Conditions with a capitalized initial shall have the following meanings, which shall apply both in the singular and plural:
        <ul>
        <li><strong>‘Partner Company’</strong> means a company that has entered into a commercial agreement with Glickon for the insertion of a Company Page or one or more Company Challenges hosted on the Platform, aimed at achieving a Company Objective;</li>
        <li><strong>‘Challenge’</strong> means a challenge, based on multiple choice or open-answer tests, posted on the Platform directly by Glickon, in which all Users may participate, aimed at assessing the knowledge of a specific subject and/or the possession of a specific skill;</li>
        <li><strong>‘Company Challenge’</strong> means a section of the Platform, organised by the Partner Company, which may include Content and/or Tests, in order to engage Users in challenges related to a Company Purpose;</li>
        <li><strong>‘Company Page’</strong> means the profile page in which a Partner Company can place Content aimed at achieving the Company Purpose on the Platform;</li>
        <li><strong>‘Content’</strong> means, but is not restricted to, text, images, graphics, video, audio and any other information or data uploaded on the Platform by the Partner Company, or by Glickon on its behalf, in order to engage Users and/or provide information in connection with the Company Purpose;</li>
        <li><strong>‘User Content‘</strong> means all Content, including but not limited to photos, data, text, electronic documents in any format, software, graphics, sounds, images, videos or other materials, whether or not protected by copyright or other rights, uploaded or otherwise made available by the User on the Platform;</li>
        <li><strong>‘Glickon’</strong> means Glickon S.r.l., a company incorporated under the laws of Italy with registered office in Milan, via Nino Bixio 7, tax code/VAT number/registration number in Milan’s commercial register 0857382096;</li>
        <li><strong>‘Platform’</strong> means Glickon's platform, accessible at the URL www.glickon.com, on which Users are engaged by the Partner Company or by Glickon on behalf of the latter, in relation to Challenges aimed at assessing their skills using specific game modes. The User may participate for fun, to train their knowledge/skills or to get in contact with Partner Companies. The Platform is available in a desktop version accessible through the most common web-browsers;</li>
        <li><strong>‘Company Purpose’</strong> means the activities for which the Platform can be used by the Partner Company, including but not limited to employer branding, talent engagement,  recruiting activities, skill assessment, sentiment analysis and surveys;</li>
        <li><strong>‘Test’</strong> means a series of questions and answers regarding different subject areas, aimed at verifying the level of knowledge of a specific subject or matter by one or more Users. Each Test can be associated to one or more Company Challenges/Challenges and can be created independently by the Partner Company or by Glickon on its behalf;</li>
        <li><strong>‘User’</strong> means the physical person who accepts and is bound by these T&C and who uses the Platform and its functionalities outside of his/her own business, commercial, craft or professional activity.</li>
        </ul>
        `
  },
  "corporate-tc-3": {
    en: "Operation of the platform",
    it: "Funzionamento della Piattaforma"
  },
  "corporate-tc-3-1": {
    it: "La Piattaforma è finalizzata a favorire l’incontro fra Aziende Partner ed Utenti, permettendo a questi ultimi di mettere alla prova e sviluppare le proprie competenze attraverso le Challenge (Aziendali e non) e all’Azienda Partner di valutarle. L’Utente potrà svolgere Challenge per puro divertimento o altrimenti per essere coinvolto, ad esempio, in attività di employer branding, talent acquisition e comunicazione promosse dalle Aziende Partner per il raggiungimento del proprio Scopo Aziendale.",
    en: "The Platform is designed to facilitate the interaction between Partner Companies and Users, allowing the latter to test and develop their skills through the Challenges (Company and not) and the Partner Company to evaluate them. The User may carry out Challenges purely for fun or otherwise to be involved, for example, in employer branding, talent acquisition and communication activities promoted by the Partner Companies for the achievement of their own Company Purpose."
  },
  "corporate-tc-3-2": {
    it: "Ciascun Utente sarà libero di partecipare a tutte o solo alcune delle Challenge su competenze ed interessi specifici, scegliendo fra quelle organizzate da Glickon o dalle diverse Aziende Partner, classificate all’interno della Piattaforma in apposite categorie corrispondenti alle distinte sfere d’interesse. L’Utente potrà sfidare altri Utenti della Piattaforma, o persone allo stesso collegate attraverso i profili social (amici di Facebook, contatti di LinkedIn o altro) che dovranno comunque registrarsi alla Piattaforma per accedere alle Challenge.",
    en: "Each User will be able to participate in all or only some of the Challenges on specific skills and interests, choosing from those organised by Glickon or the various Partner Companies, classified within the Platform in special categories corresponding to the distinct areas of interest. The User may challenge other Users of the Platform, or people connected to them through their social profiles (Facebook friends, LinkedIn contacts or others), who must register with the Platform in order to access the challenges."
  },
  "corporate-tc-3-3": {
    it: "Glickon e/o l’Azienda Partner possono, a propria discrezione, proporre Challenge di vario genere anche solo ad un gruppo ristretto di Utenti che abbiano ottenuto i migliori punteggi o che siano in possesso di profili professionali o di competenze specifiche di interesse per l’Azienda Partner.",
    en: "Glickon and/or the Partner Company may, at its own discretion, propose various types of Challenges to a limited group of Users who have achieved the best scores or who have professional profiles or specific skills of interest to the Partner Company."
  },
  "corporate-tc-3-4": {
    it: "Le Challenge Aziendali sono abilitate, organizzate, realizzate e gestite dall’Azienda Partner in piena autonomia. Ugualmente, la selezione e la valutazione dei singoli Utenti partecipanti alla Challenge avverranno ad esclusiva discrezione dell’Azienda Partner che l’ha promossa e che applicherà i criteri di selezione che riterrà più appropriati, sotto la propria sola responsabilità, senza alcun coinvolgimento di Glickon.",
    en: "The Company Challenges are enabled, organised, realized and managed by the Partner Company in full autonomy. Furthermore, the selection and rating of individual Users participating in the Challenge will take place at the exclusive discretion of the Partner Company promoting the Challenge, which will apply the selection criteria it deems most appropriate, under its sole responsibility, without any involvement of Glickon."
  },
  "corporate-tc-3-5": {
    it: "L’accesso alla Piattaforma potrebbe essere occasionalmente sospeso o temporaneamente limitato per l’esecuzione di lavori necessari ai fini della relativa manutenzione, riparazione o aggiornamento con nuovi servizi e prodotti. Glickon farà il possibile per ridurre al minimo la frequenza e la durata di tali sospensioni e/o limitazioni. Glickon non può garantire che la Piattaforma sia esente da virus o da altre componenti potenzialmente pericolosi per i dispositivi degli utenti. L’Utente accetta quindi che l’utilizzo della Piattaforma avviene esclusivamente a proprio rischio.",
    en: "Access to the Platform may from time to time be suspended or temporarily restricted for the performance of work necessary for the purposes of its maintenance, repair or updating with new services and products. Glickon shall endeavour to minimise the frequency and duration of such suspensions and/or restrictions. Glickon cannot guarantee that the Platform is free of viruses or other components that are potentially harmful to Users' devices. The User therefore accepts that use of the Platform takes place exclusively at their own risk."
  },
  "corporate-tc-3-6": {
    it: "L’uso e il corretto funzionamento della Piattaforma possono, a volte, essere limitati a causa di funzionalità, blocchi o problemi legati alla connettività dell’Utente, alla tipologia di device utilizzato o alle tariffe del piano dati di ciascun Utente, cause per le quali Glickon non può in alcun modo essere ritenuta responsabile.",
    en: "The use and proper functioning of the Platform may, at times, be limited due to functionalities, interruptions or problems related to the User's connectivity, the type of device used or the rates of each User's data plan, for which Glickon cannot be held responsible in any way."
  },
  "corporate-tc-4": {
    en: "Registration to the platform",
    it: "Registrazione alla piattaforma"
  },
  "corporate-tc-4-1": {
    it: "Per poter utilizzare tutte le funzionalità della Piattaforma, l’Utente deve registrarsi attraverso il proprio indirizzo e-mail, oppure tramite <i>social login</i>, utilizzando le credenziali abbinate a propri <i>account</i> su piattaforme di terze parti (ad esempio Facebook o LinkedIn). Nell’ambito di questa procedura è necessario che l’Utente compili vari campi del proprio profilo. Nel caso di registrazione attraverso <i>social login</i>, taluni dati potranno essere precaricati attingendo alle informazioni rese disponibili dalle terze parti coinvolte. Cliccando sull’apposito bottone, l’Utente fornisce il proprio consenso all’acquisizione di tali dati da parte di Glickon (per maggiori informazioni a riguardo si prega di fare riferimento alla Privacy Policy della Piattaforma).",
    en: "In order to be able to use all the features of the Platform, the User must register with her/his email address, or by social login, using the credentials associated with her/his accounts on third party platforms (e.g. Facebook, LinkedIn or Google). As part of this procedure, the User is required to fill in several boxes in his profile. In the case of registration using social logins, certain data may be preloaded by drawing on information made available by the third parties involved. By clicking on the appropriate button, the User consents to the acquisition of this data by Glickon (for further information on this please refer to the Platform's Privacy Policy)."
  },
  "corporate-tc-4-2": {
    it: "Glickon non può in alcun modo entrare in possesso delle credenziali di accesso dell’Utente ai propri <i>account</i> su piattaforme di terze parti, poiché le stesse rimangono sotto l’esclusivo controllo e responsabilità di quest’ultimo. L’Utente potrà in ogni momento disconnettere il proprio <i>social login</i> dalla Piattaforma, fermo restando che ciò comporterà la non operatività dell’<i>account</i> abbinato. ",
    en: "Glickon cannot in any way acquire the User's access credentials to their accounts on third party platforms, as these remain under the exclusive control and responsibility of the User. The User may at any time disconnect their social login from the Platform, it being understood that this will result in the non-operation of the associated account."
  },
  "corporate-tc-4-3": {
    it: "L’indirizzo di posta elettronica fornito dall’Utente in fase di registrazione e gli <i>account</i> associati a servizi forniti da terze parti (ad esempio Facebook e LinkedIn) saranno usati da Glickon per tutte le comunicazioni relative o comunque associate all’utilizzo della Piattaforma e dei servizi oﬀerti attraverso di essa. L’Utente accetta la validità di tali mezzi di comunicazioni e si impegna a controllare periodicamente i suddetti canali.",
    en: "The email address provided by the User during registration and the accounts associated with services provided by third parties (e.g. Facebook and LinkedIn) will be used by Glickon for all communications relating to or in any way associated with the use of the Platform and the services offered through it. The User accepts the validity of these means of communication and undertakes to regularly check these channels."
  },
  "corporate-tc-4-4": {
    it: "Tutte le operazioni eﬀettuate sulla Piattaforma attraverso l’<i>account</i> dell’Utente s’intenderanno svolte direttamente da parte di quest’ultimo, che ne sarà dunque esclusivamente responsabile, senza eccezioni di sorta.",
    en: "All operations carried out on the Platform through the User's account shall be deemed to be done directly by the User, who shall therefore be exclusively responsible for them, without exception."
  },
  "corporate-tc-5": {
    en: "Information associated with the user's account",
    it: "Informazioni associate all'Account dell'utente"
  },
  "corporate-tc-5-1": {
    it: "L’Utente dichiara e garantisce sotto la propria esclusiva responsabilità che tutte le informazioni fornite in sede di registrazione, direttamente attraverso la Piattaforma o tramite <i>social login</i>, sono veritiere, corrette ed aggiornate. Resta inteso che Glickon non potrà essere considerata in alcun modo responsabile della veridicità, correttezza e aggiornamento delle predette informazioni.",
    en: "The User states and guarantees under his or her own exclusive responsibility that all information submitted during registration, directly through the Platform or via social login, are true, correct and up-to-date. It is understood that Glickon cannot be held responsible in any way for the truthfulness, correctness and updating of the aforementioned information."
  },
  "corporate-tc-5-2": {
    it: "L’Utente potrà sempre integrare il proprio profilo personale inserendo ulteriori informazioni relative al proprio curriculum vitae, immagini e/o video personali o visualizzare, attraverso di esso, le statistiche relative al proprio progresso nelle Challenge e nei quiz a cui ha partecipato. Allo stesso modo, l’Utente potrà in qualsiasi momento modificare le impostazioni del proprio <i>account</i> e decidere se rendere visibili o meno i dati ad esso abbinati ad altri Utenti e alle Aziende Partner. Resta inteso che, nel momento in cui l’Utente decida di partecipare ad una Challenge Aziendale, l’Azienda Partner che ha l’ha organizzata potrà visualizzare tutti i profili dei partecipanti, ivi inclusi i risultati di tutte le Challenge pubblicate da Glickon che l’Utente ha svolto fin dal momento in cui si è registrato sulla Piattaforma. Non saranno invece in nessun caso visibili i risultati delle Challenge Aziendali pubblicate da altre Aziende Partner.",
    en: "User may at any time enhance his/her personal profile by adding further information relating to his/her curriculum vitae, personal images and/or videos or view, through it, statistics relating to his/her progress in the Challenges and tests in which he/she has participated. Moreover, the User may at any time change the settings of his/her account and decide to show or not to show his/her data to other Users and Partner Companies. It is understood that, when the User decides to participate in a Company Challenge, the Partner Company that has organised it may view all the profiles of the participants, including the results of all the Challenges published by Glickon that the User has carried out since his/her registration on the Platform. The results of the Company Challenges published by other Partner Companies will not be visible in any case."
  },
  "corporate-tc-5-3": {
    it: "Nel caso in cui preferisca non rendere visibili i propri risultati, l’Utente può modificare le impostazioni del proprio profilo, rendendolo privato per tutte le Aziende Partner tranne quelle verso cui l’Utente abbia eﬀettuato un’application attraverso la Piattaforma, che potranno continuare ad accedere ai suoi dati.",
    en: "If the User would prefer not to have his/her results visible, he/she may modify the settings of his/her profile, making it private for all Partner Companies except those to whom the User has made an application through the Platform, who shall continue to have access to his/her data."
  },
  "corporate-tc-6": {
    en: "Use of the platform",
    it: "Utilizzo della Piattaforma"
  },
  "corporate-tc-6-1": {
    it: `
    Registrandosi alla Piattaforma ed utilizzando il Servizio ciascun Utente si impegna incondizionatamente a: 
    <ul>
    <li>agire in conformità alla normativa applicabile; </li>
    <li>fornire informazioni esatte, aggiornate, non confidenziali e non lesive dei diritti di proprietà intellettuale o di altri diritti di terzi; </li>
    <li>fornire tutte le informazioni necessarie per verificare la propria identità che potrebbero essere richieste da Glickon o da qualsiasi autorità giudiziaria o amministrativa competente in conformità alle norme di legge applicabili; </li>
    <li>custodire con diligenza le credenziali di accesso al proprio <i>account</i>, fermo restando che la sicurezza e la segretezza delle stesse ricadono sotto l’esclusiva responsabilità dell’Utente. Qualora l’Utente sospetti che sia stato eﬀettuato un accesso non autorizzato al proprio <i>account</i> da parte di terzi, l’Utente dovrà contattare immediatamente Glickon e modificare la propria password nel più breve tempo possibile. Glickon non assume alcuna responsabilità per qualunque danno derivante dalla non diligente custodia delle credenziali di accesso utilizzate dall’Utente per accedere alla Piattaforma; </li>
    <li>non pubblicare sulla Piattaforma Contenuti o materiali che siano contrari alla legge, all’ordine pubblico o al buon costume, o che altrimenti siano direttamente o indirettamente lesivi di diritti di terzi; </li>
    <li>non trasmettere, caricare, o altrimenti rendere disponibili sulla Piattaforma virus, contenuti sconvenienti o minacciosi, messaggi pubblicitari, materiali promozionali non sollecitati o non autorizzati, inclusa ogni forma di <i>spam</i>; </li>
    <li>non danneggiare, interferire o interrompere in alcun modo l’accesso alla Piattaforma e ai relativi Contenuti, o compiere azioni che potrebbero alterarne la funzionalità o interferire con l’accesso di altri Utenti alla Piattaforma e ai relativi Contenuti; </li>
    <li>non utilizzare la Piattaforma in modo illegale o dannoso per Glickon, o per qualsiasi altro Utente della Piattaforma o terza parte;</li>
    <li>non svolgere o altrimenti agevolare, supportare o sollecitare lo svolgimento di attività che abbiano ad oggetto o comunque comportino l’esecuzione di una copia, di modifiche o di atti di <i>reverse engineering</i>, o la riproduzione, disassemblaggio, decompilazione, decrittazione o decostruzione del <i>software</i>, degli algoritmi o dei codici della Piattaforma (incluse le interfacce e qualsiasi altro dato incorporato in o utilizzato insieme a programmi che possano o meno essere tecnicamente considerati dei codici), oltre che dei processi, delle informazioni e dei dati ad essa collegati o in essa contenuti.</li>
    </ul>
    `,
    en: `
    By registering on the Platform and using the Service, each User unconditionally accepts to:
    <ul>
    <li>to act in accordance with the applicable legislation;</li>
    <li>to provide information that is accurate, up-to-date, non-confidential and does not violate the intellectual property rights or other rights of third parties;</li>
    <li>to provide all the information required to verify his or her identity that may be requested by Glickon or any competent judicial or administrative authority in accordance with the applicable legal regulations;</li>
    <li>diligently protect the credentials for accessing their account, it being understood that the security and secrecy of these credentials are the sole responsibility of the User. If the User suspects that his or her account has been accessed by a third party, the User must contact Glickon immediately and change his or her password as soon as possible. Glickon accepts no responsibility for any damage resulting from the non-diligent storage of the access credentials used by the User to access the Platform;</li>
    <li>not to publish any Content or material on the Platform that is contrary to law, public order or morality, or that otherwise directly or indirectly violates the rights of third parties;</li>
    <li>not to transmit, upload, or in any way make available on the Platform viruses, unsuitable or threatening content, advertisements, unsolicited or non-authorised promotional materials, including any form of spamming;</li>
    <li>not to damage, interfere with or disrupt in any way access to the Platform and its Content, or take any action that may alter its functionality or disturb the access of other Users to the Platform and its Content;</li>
    <li>not to use the Platform in a way that is unlawful or harmful to Glickon, or to any other User of the Platform or third party;</li>
    <li>not to carry out or in otherwise facilitating, supporting or soliciting the carrying out of any activity which has as its object or in any way involves the copying, modification or reverse engineering, or the reproduction, disassembly, de-compilation, decryption or deconstruction of the software, algorithms or codes of the Platform (including interfaces and any other data embedded in or used in conjunction with programs that might or might not be technically considered as codes), as well as the processes, information and data linked to or contained within it;</li>
    <ul>
    `
  },
  "corporate-tc-6-2": {
    it: "Glickon potrà in qualunque momento e senza preavviso restringere, sospendere o chiudere definitivamente l’<i>account</i> dell’Utente in caso di violazione delle regole sopra indicate. La chiusura di un <i>account</i> determinerà la perdita dell’accesso alla Piattaforma e dei dati allo stesso connessi.",
    en: "Glickon may at any time and without prior notice restrict, suspend or permanently close the User's account in the event of a breach of the above rules. The closure of an account will result in the loss of access to the Platform and the data associated with it."
  },
  "corporate-tc-7": {
    en: "User content",
    it: "Contenuti dell'Utente"
  },
  "corporate-tc-7-1": {
    it: "Trasmettendo, caricando o altrimenti mettendo a disposizione Contenuti degli Utenti attraverso la Piattaforma, l’Utente concede senza riserve all’Azienda Partner, nei limiti stabiliti dalle vigenti norme di legge, una licenza non esclusiva, valida in tutto il mondo, di copiare, utilizzare, riprodurre, modificare, adattare, tradurre, pubblicare, creare opere derivate, dare in licenza o sub-licenza e distribuire qualunque di tali Contenuti dell’Utente, per l’intera durata di mantenimento dell’<i>account</i> di quest’ultimo sulla Piattaforma, con ogni forma, mezzo o tecnologia. L’Utente prende atto ed accetta che l’Azienda Partner potrà utilizzare i Contenuti degli Utenti senza alcun corrispettivo in denaro o di altro genere a qualunque scopo, inclusi, a titolo esemplificativo ma non esaustivo, lo sviluppo, la produzione, la distribuzione o la commercializzazione di prodotti o servizi.",
    en: "By transmitting, uploading or otherwise making User Content available through the Platform, the User grants without reservation to the Partner Company, within the limits established by the laws in force, a non-exclusive, worldwide licence to copy, use, reproduce, modify, adapt, translate, publish, create derivative works from, license or sub-licence and distribute any such User Content, for the entire duration of the User's account on the Platform, in any form, medium or technology. The User acknowledges and accepts that the Partner Company may use the User Content without any monetary or other compensation for any purpose, including but not limited to the development, production, distribution or marketing of products or services."
  },
  "corporate-tc-7-2": {
    it: "L’Utente dichiara e garantisce che qualsiasi Contenuto degli Utenti è conforme alla legge e non contiene materiale, dati o informazioni illeciti, o contro la morale, il buon costume e l’ordine pubblico, né ha contenuti osceni, diﬀamatori, oﬀensivi o di natura discriminatoria in relazione alla religione, la razza, l’orientamento sessuale, la nazionalità e, più in generale, contenuti che potrebbero risultare oﬀensivi o che violino diritti di terzi, anche di proprietà intellettuale.",
    en: "The User declares and guarantees that any User Content complies with the law and does not contain any unlawful material, data or information, or any material that is against morality, decency or public order, nor does it contain obscene, defamatory, offensive content or content that is discriminatory in nature in relation to religion, race, sexual orientation, nationality and, more generally, content that could be offensive or that violates the rights of third parties, including intellectual property rights."
  },
  "corporate-tc-7-3": {
    it: "L’Utente si impegna a non caricare in Piattaforma <i>files</i> elettronici o qualsivoglia materiale che contenga virus o altri codici, file o programmi creati per interrompere, distruggere, limitare o modificare il funzionamento dei <i>software</i>, degli hardware o degli impianti di telecomunicazioni, e della piattaforma di Glickon o di terzi.",
    en: "The User undertakes not to upload to the Platform any electronic files or any material that contains viruses or other codes, files or software created to interrupt, destroy, limit or modify the operation of software, hardware or telecommunications equipment, and the Glickon platform or that of third parties."
  },
  "corporate-tc-7-4": {
    it: "L’Utente è responsabile in via esclusiva di qualunque Contenuto degli Utenti caricato sulla Piattaforma dal proprio <i>account</i> e si obbliga a manlevare e tenere completamente indenne Glickon e i suoi amministratori, rappresentanti, direttori e collaboratori da e contro qualsiasi danno, reclamo, richiesta di risarcimento, azione legale, costo sostenuto (incluse le spese legali) e più in generale responsabilità che dovesse direttamente o indirettamente derivare dal caricamento di Contenuti dell’Utente vietati ai sensi delle presenti Condizioni.",
    en: "The User is exclusively responsible for any User Content uploaded to the Platform from his or her account and undertakes to completely indemnify and hold harmless Glickon and its administrators, representatives, directors and collaborators from and against any damage, claim, action, cost incurred (including legal fees) and more generally liability that may arise directly or indirectly from the uploading of User Content that is prohibited under these T&C."
  },
  "corporate-tc-7-5": {
    it: "Glickon si riserva il diritto insindacabile di rimuovere in qualsiasi momento i Contenuti dell’Utente, nel caso in cui gli stessi non siano considerati adeguati e in linea con gli standard di accettabilità stabiliti da Glickon a propria esclusiva discrezione, o in caso di violazione di diritti di terzi.",
    en: "Glickon reserves the unquestionable right to remove User Content at any time, if the User Content is not deemed appropriate and in line with the standards of acceptability set by Glickon in its sole discretion, or if the User Content infringes the rights of third parties."
  },
  "corporate-tc-7-6": {
    it: "Glickon si riserva altresì il diritto di sospendere o revocare senza preavviso l’<i>account</i> dell’Utente, impedendo così temporaneamente o definitivamente l’accesso dello stesso alla Piattaforma e alle sue funzionalità, ogniqualvolta nutra un fondato sospetto che stiano avvenendo, da parte o per conto dell’Utente, o di terzi con il consenso o il supporto di quest’ultimo, violazioni di disposizioni delle presenti Condizioni o di norme di legge.",
    en: "Glickon also reserves the right to suspend or revoke the User's account without prior notice, thus temporarily or permanently preventing the User's access to the Platform and its functionalities, whenever it has a justified suspicion that violations of the provisions of these Terms and Conditions or of the law are being committed by or on behalf of the User, or by a third party with the consent or support of the latter."
  },
  "corporate-tc-8": {
    en: "Account Deletion",
    it: "Cancellazione dell'<i><i>account</i></i>"
  },
  "corporate-tc-8-1": {
    it: "L’Utente potrà modificare in qualunque momento il proprio profilo personale, o eliminare il proprio <i>account</i> dalla Piattaforma attraverso l’apposito bottone presente nella sezione ‘Impostazioni’ del proprio profilo. L’Utente riceverà per posta elettronica una comunicazione automatica di conferma della avvenuta ricezione della richiesta, con la precisazione che l’<i>account</i> verrà eliminato nelle 72 ore successive.",
    en: "The User may modify their personal profile at any time or delete their account from the Platform using the appropriate button in the 'Settings' section of their profile. The User will receive an automatic communication by email confirming receipt of the request, specifying that the account will be deleted within the following 72 hours."
  },
  "corporate-tc-8-2": {
    it: "Successivamente all’eliminazione dell’<i>account</i> dalla Piattaforma, Glickon comunicherà senza ritardo all’Azienda Partner competente in qualità di titolare del trattamento dei dati personali dell’Utente la sua richiesta di cancellazione, in modo che la stessa possa adempiere ai suoi obblighi in accordo con la vigente normativa in materia di protezione dei dati personali.",
    en: "Following the deletion of the account from the Platform, Glickon will inform the competent Partner Company as the owner of the User's personal data without delay of the User's request for deletion, so that it can fulfil its obligations in accordance with current data protection legislation."
  },
  "corporate-tc-9": {
    en: "<strong>Warranties and Indemnity</strong>",
    it: "<strong>Garanzie e manleva</strong>"
  },
  "corporate-tc-9-1": {
    it: `
    <strong>Da parte dell’Utente</strong>
    <ul>
    <li>L’Utente riconosce ed accetta che la Piattaforma e i suoi Contenuti sono forniti ‘come sono’ e ‘come disponibili’ al momento della loro fruizione.</li>
    <li>L’Utente è il solo ed esclusivo responsabile per l’uso e la sicurezza della propria posta elettronica e dei propri <i>account</i>, anche per le attività svolte attraverso il loro utilizzo.</li>
    <li>L’Utente è consapevole del fatto che, al fine di regolare l’accesso alla Piattaforma, la procedura di autenticazione è richiesta esclusivamente per la verifica dell’indirizzo di posta elettronica fornito e degli <i>account</i> utilizzati, salvo i casi espressamente stabiliti dalla legge per i quali è necessario fornire i propri dati identificativi.</li>
    <li>L’Utente è pienamente ed esclusivamente responsabile, coerentemente a quanto stabilito al precedente Art. 4.4, per eventuali danni che dovessero insorgere a carico di Glickon o di terzi, a seguito del non corretto utilizzo, la perdita, il furto e/o compromissione della riservatezza dell’indirizzo di posta elettronica e degli <i>account</i> utilizzati</li>
    <li>L’Utente si dichiara consapevole che, anche alla luce di quanto disposto al precedente Art. 3.4, qualsiasi genere di violazione connessa a o derivante dai Contenuti, dai Test, o da altri campi inseriti in ciascuna Challenge Aziendale, oltre che dal trattamento dei dati personali in essi eventualmente presenti, ricade sotto la diretta ed esclusiva responsabilità dell’Azienda Partner</li>
    <li>Fermo quanto stabilito al precedente Art. 7.4, l’Utente è il solo e diretto responsabile della correttezza, della veridicità e dell’aggiornamento dei propri dati personali e di tutte le altre informazioni caricate e rese pubbliche attraverso la Piattaforma dal proprio <i>account</i>. Per tale motivo, l’Utente si impegna espressamente a manlevare e tenere indenne Glickon da tutti i danni e le spese (ivi incluse le prevedibili spese legali) che dovessero derivare da azioni di terze parti o di autorità amministrative o giurisdizionali per violazione di diritti di terzi o della normativa applicabile da parte dell’Utente</li>
    </ul>
    `,
    en: `
    <strong>By the User</strong>
    <ul>
    <li>The User acknowledges and accepts that the Platform and its Content are provided ‘as is’ and ‘as available’ at the time of their use.</li>
    <li>The User is the only and exclusive responsible for the use and security of their email and accounts, including for the activities carried out through their use.</li>
    <li>The User is aware that, in order to regulate access to the Platform, the authentication procedure is required exclusively for the verification of the email address provided and the accounts used, except in cases expressly established by law for which it is necessary to provide their identification data. </li>
    <li>The User is completely and exclusively responsible, in accordance with the provisions of Article 4.4 above, for any damages that may be incurred by Glickon or third parties as a result of the incorrect use, loss, theft and/or compromise of the confidentiality of the email address and accounts used.</li>
    <li>The User declares himself/herself aware that, also in accordance with the provisions of Article 3.4 above, any kind of violation connected to or resulting from the Contents, Tests, or other fields included in each Company Challenge, as well as the processing of personal data that may be present in them, falls under the direct and exclusive responsibility of the Partner Company.</li>
    <li>Without prejudice to the provisions of Article 7.4 above, the User is the only and directly responsible for the correctness, truthfulness and updating of his/her personal data and all other information uploaded and made public through the Platform by his/her account. For this reason, the User expressly undertakes to indemnify and hold Glickon harmless from all damages and expenses (including foreseeable legal fees) that may result from actions by third parties or administrative or judicial authorities for infringement of third-party rights or applicable legislation by the User.</li>
    </ul>
    `
  },
  "corporate-tc-9-2": {
    it: `
    <strong>Da parte di Glickon</strong>
    <ul>
    <li>Glickon non è né esegue le funzioni di una agenzia per il lavoro o di selezione del personale, ma agisce come <i>service provider</i> ai sensi della normativa applicabile mettendo a disposizione una Piattaforma attraverso cui gli Utenti possono competere fra loro, misurarsi attraverso Challenge ed entrare in contatto con le Aziende Partner.</li>
    <li>Accedendo ed utilizzando la Piattaforma, l’Utente non agisce in alcun modo sotto l’autorità o il controllo di Glickon, neppure indirettamente. Pertanto, Glickon non è in alcun modo responsabile delle azioni, dei Contenuti e delle informazioni fornite, pubblicate o condivise dagli Utenti sulla Piattaforma. Nulla di quanto stabilito nelle presenti Condizioni implica che Glickon sia obbligata o possa essere considerata tenuta allo svolgimento di qualsiasi attività di sorveglianza o controllo preventivo sulle informazioni e i Contenuti memorizzati e trasmessi attraverso la Piattaforma, né a ricercare attivamente fatti o circostanze che rivelino l’esecuzione di attività legalmente o contrattualmente illecite. Sono fatti comunque pienamente salvi tutti i diritti spettanti a Glickon verso l’Utente ai sensi della legislazione comunitaria e nazionale applicabile. </li>
    <li>Glickon non è in alcun modo responsabile per qualsiasi genere di rapporto che si dovesse instaurare tra l’Utente e le Aziende Partner che interagiscono con la Piattaforma, essendo relazioni totalmente private e riservate su cui Glickon non può esercitare alcun controllo. </li>
    <li>Glickon non è inoltre in alcun modo responsabile, né può dare alcuna garanzia, riguardo ad eventuali comunicazioni, formazione, riunioni, trattative, accordi contrattuali o rapporti di lavoro che dovessero insorgere tra l’Utente e le Aziende Partner.</li>
    <li>Resta espressamente inteso che qualsiasi sia lo Scopo Aziendale per cui l’Azienda Partner utilizza la Piattaforma, la liceità di tale finalità e la conformità alla normativa vigente, con particolare ma non limitato riferimento a quella applicabile in materia di protezione dei dati personali, delle attività e delle operazioni di trattamento autonomamente svolte dall’Azienda Partner per la realizzazione della stessa, ricadono sotto la sola ed esclusiva responsabilità dell’Azienda Partner.</li>
    </ul>
    `,
    en: `
    <strong>By Glickon</strong>
    <ul>
    <li>Glickon is not and does not perform the functions of an employment agency or recruitment agency, but acts as a service provider within the meaning of the applicable legislation by making available a Platform through which Users can compete with each other, compete through Challenges and get in contact with Partner Companies.</li>
    <li>By accessing and using the Platform, the User does not act in any form under the authority or control of Glickon, not even indirectly. Therefore, Glickon is in no way responsible for the actions, Content and information provided, published or shared by Users on the Platform. Nothing in these T&C implies that Glickon is obliged or may be deemed to be obliged to carry out any surveillance or preventive control over the information and Content stored and transferred via the Platform, nor to actively search for facts or circumstances that reveal the performance of legally or contractually illegal activities. In any case, all rights of Glickon towards the User under the applicable Community and national legislation are fully maintained.</li>
    <li>Glickon is in no way responsible for any kind of relationship that may be established between the User and Partner Companies that interact with the Platform, as these are totally private and confidential relationships over which Glickon cannot exercise any control.   </li>
    <li>Furthermore, Glickon is in no way responsible, nor can it give any guarantee, regarding any communications, training, meetings, negotiations, contractual agreements or working relationships that might arise between the User and Partner Companies.</li>
    <li>It is expressly understood that whatever the Company Purpose for which the Partner Company uses the Platform, the lawfulness of this purpose and the compliance with the regulations in force, with particular but not limited reference to those applicable to the protection of personal data, of the activities and processing operations carried out independently by the Partner Company for the implementation of the same, fall under the sole and exclusive responsibility of the Partner Company.</li>
    </ul>
    `
  },
  "corporate-tc-10": {
    en: "Intellectual Property Rights",
    it: "Diritti di Proprietà intellettuale"
  },
  "corporate-tc-10-1": {
    it: "Glickon è l’unico e solo titolare di tutti i diritti di proprietà intellettuale sulla Piattaforma e su tutte le sue funzionalità o servizi supplementari ad essa connessi. Accettando le presenti Condizioni mediante registrazione alla Piattaforma, l’Utente non acquisisce diritti diversi o ulteriori rispetto a quelli ivi espressamente riconosciuti.",
    en: "Glickon is the exclusive and only owner of all intellectual property rights over the Platform and all of its features or additional services connected to it. By accepting these T&C through registration with the Platform, the User does not acquire any rights other than or in addition to those expressly recognized herein."
  },
  "corporate-tc-10-2": {
    it: "Glickon consente l’accesso e l’uso della Piattaforma per i soli scopi per i quali è stata creata, in conformità alle presenti Condizioni. All’Utente è pertanto vietato utilizzare per scopi commerciali, in tutto o in parte, con qualsiasi mezzo presente e futuro, in qualsiasi forma e modo, la Piattaforma e/o i dati, il materiale e gli altri Contenuti caricati o resi pubblici attraverso di essa, senza la previa autorizzazione scritta di Glickon.",
    en: "Glickon allows access to and use of the Platform solely for the purposes for which it was created, in accordance with these T&C. The User is therefore prohibited from using the Platform and/or the data, material and other Content uploaded or made public through it for commercial purposes, in whole or in part, by any present or future means, in any form or manner, without the prior written authorization of Glickon."
  },
  "corporate-tc-11": {
    en: "Processing of user's personal data",
    it: "Trattamento dei dati personali dell'utente"
  },
  "corporate-tc-11-1": {
    it: "Glickon tratterà i dati personali dell’Utente secondo la modalità indicate nell’apposita <a href=\"https://www.glickon.com/it/piattaforma/privacy\" target=\"_blank\">Informativa sul trattamento dei dati personali.</a>",
    en: "Glickon shall process the User's personal data in the manner indicated in <a href=\"https://www.glickon.com/en/platform/privacy\" target=\"_blank\">the privacy policy</a>"
  },
  "corporate-tc-11-2": {
    it: "In caso di partecipazione dell’Utente a Challenge promosse direttamente da Glickon, la stessa agirà in qualità di titolare del trattamento. Diversamente, ogniqualvolta l’Utente partecipi a Challenge Aziendali promosse da un’Azienda Partner, Glickon opererà in qualità di responsabile del trattamento per conto di quest’ultima, tranne che per i dati di registrazione degli Utenti alla Piattaforma, in riferimento ai quali Glickon resterà titolare.",
    en: "In the event of the User's participation in a challenge promoted directly by Glickon, Glickon will act as data controller. Otherwise, whenever the User participates in Company Challenges promoted by a Partner Company, Glickon shall act as data processor on behalf of the latter, except for the registration data of Users to the Platform, in relation to which Glickon shall remain the data controller."
  },
  "corporate-tc-12": {
    en: "General provisions",
    it: "Clausole Generali"
  },
  "corporate-tc-12-1": {
    it: "<strong>Integrazioni e modifiche</strong><br/>Le presenti Condizioni costituiscono l’intero accordo tra l’Utente e Glickon e sostituiscono qualsiasi precedente o contemporanea condizione, accordo o dichiarazione, sia verbale che scritta, avente il medesimo oggetto. Glickon si riserva il diritto di modificare la Piattaforma, il sito ad essa collegato, l’Informativa sul trattamento e le presenti Condizioni della Piattaforma in qualsiasi momento, dandone adeguata comunicazione all’Utente, quando richiesto. L’Utente sarà soggetto all’Informativa sul trattamento dei dati personali e alle Condizioni d’Uso tempo per tempo vigenti, consultabili in versione aggiornata in qualsiasi momento tramite gli appositi collegamenti disponibili in Piattaforma.",
    en: "<strong>Additions and amendments</strong><br/>These Terms and Conditions represent the entire agreement between the User and Glickon and substitute any previous or contemporaneous condition, agreement or declaration, whether verbal or written, having the same subject matter. Glickon reserves the right to modify the Platform, the website associated to it, the Privacy Policy and these T&C at any time, giving appropriate notice to the User when required. The User will be subject to the Privacy Policy and the Terms of Use in force from time to time, which can be consulted in an updated version at any time via the appropriate links available on the Platform."
  },
  "corporate-tc-12-2": {
    it: "<strong>Conservazione</strong><br/>Qualora una qualsiasi disposizione delle presenti Condizioni risulti o sia dichiarata illecita, nulla, o ineﬀicace, nei limiti consentiti dalla legge applicabile tale disposizione sarà modificata in accordo tra le Parti in modo da renderla eﬀicace o altrimenti eliminata, senza che ciò possa in alcun modo inficiare la validità e l’eﬀicacia delle restanti disposizioni",
    en: "<strong>Retention</strong><br/>If any provision of these T&C results or is declared unlawful, void, or ineffective, to the extent permitted by applicable law such provision will be modified by agreement between the Parties so as to render it effective or otherwise remove it, without in any way affecting the validity and effectiveness of the remaining provisions."
  },
  "corporate-tc-12-3": {
    it: "<strong>Comunicazioni</strong><br/>Qualsiasi comunicazione di tipo amministrativo relativa alle presenti Condizioni sarà eﬀettuata tramite apposito avviso nella <i>homepage</i> della Piattaforma.",
    en: "<strong>Notices</strong><br/>Any communication of an administrative nature relating to these Conditions shall be made by means of a specific notice on the homepage of the Platform."
  },
  "corporate-tc-12-4": {
    it: "<strong>Legge applicabile e Foro competente</strong><br/>Le presenti Condizioni sono disciplinate dalla legge italiana. Per qualsiasi controversia riguardante l’interpretazione, la validità o l’esecuzione delle presenti Condizioni, o comunque derivante dalle stesse, sarà esclusivamente competente il Tribunale del luogo di residenza o domicilio dell’Utente, tranne laddove diversamente stabilito da norme di legge.",
    en: "<strong>Applicable law and place of jurisdiction</strong><br/>These T&C are disciplined by Italian law. Any dispute concerning the interpretation, validity or execution of these T&C, or in any case deriving from them, shall be subject to the exclusive jurisdiction of the Court of the place of residence or domicile of the User, unless otherwise established by law."
  },
}

export default translations
