import React, { ReactNode } from "react"
import ExternalPageShell from "../external-page-shell"
import {
  hasCustomStyle,
  getCustomStyle,
  getEmptyCustomStyle
} from "../../customStyle"
import {
  ExternalPageLayoutDetailsTopContent as DetailsTopContent,
  ExternalPageLayoutDetailsWrapper as DetailsWrapper,
  ExternalPageLayoutInnerWrapper as DetailsInnerWrapper
} from "../external-page-layout/style"
import { CompanySectionInfo } from "../../types/companySectionInfo"
import {
  CompanySectionsWrapper as SectionsWrapper,
  CompanyChallengesWrapper as ChallengesWrapper,
  CompanyInfoWrapper as InfoWrapper
} from "./style"
import SectionsList from "./compontents/company-sections-list"
import { CompanyChallengeInfo } from "../../types/companyChallengeInfo"
import CompanyChallengesList from "./compontents/company-challenges-list"

export enum ExternalPageCompanyLikeType {
  company,
  section
}

interface Props {
  children: {
    topInformations: ReactNode
    bottomInformationsList: ReactNode[]
  }
  careersName: string
  carouselUrls: string[]
  sections: CompanySectionInfo[]
  challenges: CompanyChallengeInfo[]
  companyChallengesListDefaultColor: string
  isPublic: boolean
  type: ExternalPageCompanyLikeType
  nextUrl: string
}

const ExternalPageCompanyShell: React.FC<Props> = ({
  careersName,
  carouselUrls,
  children,
  sections,
  challenges,
  companyChallengesListDefaultColor,
  isPublic,
  type,
  nextUrl
}) => {
  const hasSections = sections && sections.length > 0
  const hasChallenges = challenges && challenges.length > 0

  const {
    customPrimaryTextColor,
    customBackgroundColor,
    customBorderColor,
    customDescriptionColor,
    customSubtitleColor,
    customBodyBackgroundColor,
    customFooterButtonColor,
    customButtonColor,
    customLightBackgroundColor
  } = isPublic ? getEmptyCustomStyle() : getCustomStyle(careersName)

  const bottomInformationsComponents = (
    (children.bottomInformationsList &&
      children.bottomInformationsList.filter(Boolean)) ||
    []
  ).map((c, i) => <div key={i}>{c}</div>)

  return (
    <ExternalPageShell
      nextUrl={nextUrl}
      isPublic={isPublic}
      companyCareersName={careersName}
      carouselUrls={carouselUrls}
      hideFooter={!isPublic && hasCustomStyle(careersName)}
      withTopbar={
        !hasCustomStyle(careersName) &&
        isPublic &&
        type !== ExternalPageCompanyLikeType.company
      }
      customBodyBackgroundColor={customBodyBackgroundColor}
      customFooterButtonColor={customFooterButtonColor}
      customFooterColor={customBodyBackgroundColor}
      customButtonColor={customButtonColor}
      customPrimaryTextColor={customPrimaryTextColor}
    >
      <DetailsWrapper customColor={customLightBackgroundColor}>
        <DetailsInnerWrapper
          className="row"
          customColor={customLightBackgroundColor}
        >
          <DetailsInnerWrapper
            className="column"
            customColor={customLightBackgroundColor}
          >
            <DetailsTopContent
              customColor={customBackgroundColor}
              customBorderColor={customBorderColor}
            >
              {children.topInformations}
            </DetailsTopContent>
          </DetailsInnerWrapper>
        </DetailsInnerWrapper>
      </DetailsWrapper>

      {hasSections && (
        <SectionsWrapper extraPadding={!hasChallenges}>
          <SectionsList sections={sections}></SectionsList>
        </SectionsWrapper>
      )}

      {hasChallenges && (
        <ChallengesWrapper
          extraPadding={!hasSections}
          customColor={customLightBackgroundColor}
        >
          <CompanyChallengesList
            companyChallenges={challenges}
            defaultColor={companyChallengesListDefaultColor}
            customChallengeColor={customBackgroundColor}
            customBorderColor={customBorderColor}
            customTitleColor={customPrimaryTextColor}
            customDescriptionColor={customDescriptionColor}
            customTimeColor={customSubtitleColor}
          />
        </ChallengesWrapper>
      )}

      {bottomInformationsComponents && bottomInformationsComponents.length > 0 && (
        <InfoWrapper
          extraPadding={!hasSections && !hasChallenges}
          customColor={customBodyBackgroundColor}
        >
          {bottomInformationsComponents}
        </InfoWrapper>
      )}
    </ExternalPageShell>
  )
}

export default ExternalPageCompanyShell
