import {TranslationType} from "../types"

const translations: TranslationType = {
  // header
  "team-header-title": {
    en: "Glickon Team",
    it: "Il team Glickon"
  },
  "team-header-description": {
    en: `
            Developers, sales, customer support, marketers, designers. Here's who we are, that's who every day
            designs the perfect experience for your candidates and employees.
        `,
    it: `
            Programmatori, sales, customer support, marketer, designer. Ecco chi siamo, ecco chi ogni giorno progetta 
            l’esperienza perfetta per i tuoi candidati e dipendenti.
        `
  },

  // bio
  "team-ceo-bio": {
    en: `
            A masters degree from Università Cattolica in International Relations Management and a lot of experience in 
            diplomatic offices around the world. Then, in 2012, while in Atlanta, the idea of born: changing the 
            approach to entering the job market that people go through every single day within companies.
            Two years later, he founds Glickon.
        `,
    it: `
            Un master alla Cattolica in International Relations Management e tanta esperienza negli uffici diplomatici 
            di mezzo mondo. Poi ad Atlanta, nel 2012, l’idea: cambiare in meglio l’accesso al mercato del lavoro e 
            l’esperienza che le persone vivono ogni giorno nelle aziende. Due anni dopo fonda Glickon.
        `
  },
  "team-cfo-bio": {
    en: `
            A degree in Business from Bocconi University followed several years in corporate finance at Ernst and Young 
            before working as a private business angel. His is Glickon’s numbers guy. If we are able to continue to 
            invest so much in HR technology and services, that means the numbers check out and he’s the one to thank.
        `,
    it: `
            Laurea in Economia alla Bocconi, un’esperienza pluriennale in corporate finance a Ernst & Young  prima e poi 
            come private business angel. È l’uomo dei numeri in Glickon, se continuiamo a investire così tanto e bene 
            in tecnologia al servizio delle HR, vuol dire che i conti tornano. E a lui dobbiamo (dovete) dire grazie.
        `
  },
  "team-cto-bio": {
    en: `
            A degree in Computer Science from the University of Milan. Years of work in software development.
            The credit for the operation and functionality of our platforms goes to Davide and his team.
        `,
    it: `
            Laurea in Computer Science all’Università di Milano. E anni di applicazione nel campo del software 
            development. Operatività e funzionalità delle piattaforme sono merito suo e del suo team.
        `
  },
  "team-data-scientist-bio": {
    en: `
            A degree in applied physics for the integration of data from the University of Bologna, then a Master in 
            Mathematics from the University of Milan and a research contract in New Yokr on physics and mathematics 
            based models to map oceanic currents. The algorithms for recruitment and HR processes - the heart and soul 
            of our platforms’ success – are fruits of his labor.
        `,
    it: `
            Una laurea in Fisica applicata all’integrazione dei dati a Bologna, poi un master in Matematica a Milano e 
            un contratto di ricerca sui modelli fisico-matematici applicati alle correnti oceaniche a New York. Gli 
            algoritmi alla base del successo delle piattaforme Glickon per il recruitment e i processi HR sono opera sua.
        `
  }
}

export default translations
