import {TranslationType} from "../types"

const translations: TranslationType = {
  // LOGIN
  "form-corporate-login-welcome-title": {
    en: "Welcome back!",
    it: "Bentornato!"
  },
  "form-corporate-login-welcome-description": {
    en: "Turn People Experience into a competitive advantage",
    it: "Metti le persone al centro della tua strategia di business"
  },
  // "form-corporate-login-welcome-description": {
  //     en: "Hire the best talent, increase the involvement and productivity of your employees.",
  //     it: "Assumi i migliori talenti, aumenta coinvolgimento e produttività dei tuoi dipendenti."
  // },
  "form-corporate-forgotten-password": {
    en: "Forgot your password?",
    it: "Hai dimenticato la password?"
  },
  "form-corporate-login": {
    en: "Login",
    it: "Accedi"
  },
  "form-corporate-no-account": {
    en: "Not registered as a company?",
    it: "Non sei registrato come azienda?"
  },
  "form-corporate-request-demo": {
    en: "Request a demo",
    it: "Richiedi una demo"
  },

  // LOST PASSWORD
  "form-corporate-lost-password-title": {
    en: "Reset password",
    it: "Reimposta password"
  },
  "form-corporate-lost-password-description": {
    en:
      "Enter your email address and we'll send you instructions to reset your password",
    it:
      "Inserisci il tuo indirizzo email e ti invieremo le istruzioni per reimpostare la password"
  },
  "form-corporate-lost-password-back-to-login-1": {
    en: "Back to",
    it: "Torna alla"
  },
  "form-corporate-lost-password-back-to-login-2": {
    en: "Log in",
    it: "Log in"
  },
  "form-corporate-reset-password": {
    en: "Reset",
    it: "Reimposta"
  },

  // LOST PASSWORD DONE
  "form-corporate-lost-password-done-description": {
    en:
      "In a few seconds you should receive a link: open it to reset the password.",
    it:
      "Tra pochi secondi dovresti ricevere un link: aprilo per reimpostare la password."
  },
  "form-corporate-back-to-login": {
    en: "Back to Login",
    it: "Torna alla Login"
  }
}

export default translations
