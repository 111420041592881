import {TranslationType} from "../types"

const translations: TranslationType = {
  // header
  "case-study-header-title": {
    en: "We tell you many beautiful stories. And all successful.",
    it: "Vi raccontiamo tante belle Storie. E tutte di successo."
  },
  "case-study-header-description": {
    en:
      "They are the case studies of our customers, but they call them Experiences",
    it: "Sono le case study dei nostri clienti, ma loro le chiamano Esperienze"
  },

  // banner-bottom
  "case-study-banner-bottom-title": {
    en: "Now it’s your turn, design your Glickon Experience!",
    it: "Ora tocca a te, disegna la tua Glickon Experience!"
  },
  "case-study-banner-bottom-description": {
    en: "Indeed, what are your needs? Let&#39;s design them together",
    it: "Anzi, quali sono le tue esigenze? Progettiamole insieme"
  },

  // info-section
  "case-study-info-sector-title": {
    en: "industry",
    it: "settore"
  },
  "case-study-info-area-title": {
    en: "area",
    it: "area"
  },
  "case-study-info-company-size-title": {
    en: "company size",
    it: "dimensione azienda"
  },
  "case-study-info-company-size-description": {
    en: "{{employees}} employees approx",
    it: "{{employees}} dipendenti ca."
  },
  "case-study-info-company-business-title": {
    en: "business type",
    it: "business"
  },
  "case-study-phase-counter": {
    en: "Phase {{count}}",
    it: "Fase {{count}}"
  }
}

export default translations
