import styled, {css} from "styled-components"
import styleConstants from "../../constants/styleConstants"

const SocialBox = styled.div`
  font-size: ${styleConstants.font.size18};
  font-weight: 500;
  border-radius: 0.125rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  color: ${styleConstants.white};
  position: relative;
  span {
      font-weight: normal;
      font-family: ${styleConstants.calibreSemibold};
  }
`

export const Facebook = styled(SocialBox)<{
    noMargin: boolean,
    large: boolean
}>`
  background-color: ${styleConstants.facebookColor};
  ${props => props.large ? css`
    height: 3rem;
  ` : ""};
  img {
    height: ${props => props.large ? "3rem" : "2.5rem"};
    position: ${props => props.large && "absolute"};
    left: 0;
    top: 0;
  }
`

export const Linkedin = styled(SocialBox)<{
    noMargin: boolean,
    large: boolean
}>`
  background-color: ${styleConstants.linkedinColor};
  ${props => props.large ? css`
    margin-bottom: 1rem;
    height: 3rem;
  ` : ""};
  i {
    height: 1.5rem;
    position: ${props => props.large && "absolute"};
    left: 0.75rem;
    top: 0.75rem;
    font-size: ${styleConstants.font.size26};
  }
`

export const Gmail = styled(SocialBox)<{
    noMargin: boolean,
    large: boolean
}>`
  background-color: #fff;
  border: 1px solid ${styleConstants.dark03};
  color: ${styleConstants.dark01};
  ${props => props.large ? css`
    margin-bottom: 1rem;
    height: 3rem;
  ` : ""};
  i {
    height: 1.5rem;
    position: ${props => props.large && "absolute"};
    left: 0.75rem;
    top: 0.75rem;
    font-size: ${styleConstants.font.size26};
  }
  img {
    height: 1.5rem;
    position: ${props => props.large && "absolute"};
    left: 0.75rem;
    top: 0.75rem;
  }
`

export const Wrapper = styled.div<{
    directionColumn?: boolean
}>`
    display: flex;
    ${({directionColumn}) => directionColumn && `
        flex-direction: column;
        .link-wrapper {
            padding: 0!important;
        }
    `}
`
