import React from "react"
import {RouteComponentProps, withRouter} from "react-router"
import AccessPreSignup from "../../../commonComponents/access-pre-signup"
import {checkRegisterEmailEmployee} from "../../../requests/employeePlatform"

type PropsType = RouteComponentProps & {}
const EmployeePlatformPreSignup: React.FC<PropsType> = () => {
  return (
    <AccessPreSignup
      registerCheckEmailFunction={checkRegisterEmailEmployee}
    ></AccessPreSignup>
  )
}

export default withRouter<PropsType, React.FC<PropsType>>(EmployeePlatformPreSignup)
